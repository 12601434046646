import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SaleTypeService } from '../_services/saleservices/sale-types.service';
import { SaleType } from '../_models/saletype';
import { ToastrService } from 'ngx-toastr';
@Injectable({
    providedIn: 'root'  // **** ADD THIS LINE ****
  })
export class SaleActiveTypeResolver implements Resolve<SaleType[]> {
    constructor(private saleTypeService: SaleTypeService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<SaleType[]> {
      //  console.log(route.params['parentGuid']);
        // tslint:disable-next-line:no-string-literal
        return this.saleTypeService.getActiveSaleTypes(route.params['parentGuid']).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving sale ative types.');
               // this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
