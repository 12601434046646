import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddRepairDto } from 'src/app/_models/DTOS/addrepairdto';
import { Pagination } from 'src/app/_models/pagination';
import { RealProperty } from 'src/app/_models/RealProperty';
import { RealPropertyRepairTypes } from 'src/app/_models/RealPropertyRepairTypes';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { ExcelService } from 'src/app/_services/exportservices/excel.service';
import { RealPropertyService } from 'src/app/_services/realpropertyservices/realpropertyservice';
import { EdithouserepairModalComponent } from '../edithouserepair-modal/edithouserepair-modal.component';
import { RealpropAddrepairComponent } from '../realprop-addrepair/realprop-addrepair.component';

@Component({
  selector: 'app-repair-list',
  templateUrl: './repair-list.component.html',
  styleUrls: ['./repair-list.component.css']
})
export class RepairListComponent implements OnInit {
 @Input() house = {} as RealProperty ;
 repair = {} as AddRepairDto;
 @Input()  repairs = [] as any[];
 @Input() repairTypes = [] as RealPropertyRepairTypes[];
 bsModalRef: any;
 pagination: Pagination;
 selectedCompany: UserAssignedToCompany;
 userParams: any;
 isAll: boolean;
  constructor(private alertify: ToastrService,
    private authService: AuthService,
    private excelService: ExcelService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private realPropertyService: RealPropertyService,
    private router: Router) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany = data; });
    this.load();
  }
  add() {
    console.log(this.repairTypes);
    const initialState = {
      selectedCompany: this.selectedCompany,
      repairTypes:  this.repairTypes
    };
    this.bsModalRef = this.modalService.show(RealpropAddrepairComponent, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.update.subscribe(data => {
      console.log(data);
      this.repair = data;
      this.repair.realPropertyId = this.house.id;
        this.realPropertyService.addRepair( this.house.id,  this.repair, this.selectedCompany.parentGuid).subscribe(
          (value) => {
            this.repairs = value.realPropertyRepairs;
            console.log(value);
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {
            this.alertify.success('Person Added');
          //  this.load();
            this.getRepairs();
          }
        );
    });
  }
  getRepairs() {
    this.realPropertyService.getLinqRepairList(this.house.id, 1, 1000).subscribe(data => {
      this.repairs = data.result;
      this.pagination = data.pagination;
    });
  }
  edit(e) {
    console.log(e);
    const initialState = {
      selectedCompany: this.selectedCompany,
      repairTypes: this.repairTypes,
      repair: e
    };
    this.bsModalRef = this.modalService.show(EdithouserepairModalComponent, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.update.subscribe(data => {
      console.log(data);
        this.realPropertyService.editRepair(this.house.id, data, this.selectedCompany.parentGuid).subscribe(
          (value) => {
            console.log(value);
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {
            this.alertify.success('Person Added');
          //  this.load();
            this.getRepairs();
          }
        );
    });
  }
  deleteRepair(e) {
    this.realPropertyService.deleteRepair(e.id, this.house.id).subscribe(data => {
      this.house = data;
    }, error => { this.alertify.error(error); }, () => { this.alertify.success('Repair Deleted');   this.getRepairs();
  });
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.load();
  }
  load() {
    this.route.data.subscribe(data => {
      this.repairs = data.repairs.result;
      this.pagination = data.repairs.pagination;
    });
  }
  printDiv() {
    const newLocal = 'print_frame';
    // tslint:disable-next-line:variable-name
    const newLocal_1 = 'print_frame';
    // tslint:disable-next-line:variable-name
    const newLocal_2 = 'print_frame';
  window.frames[newLocal].document.body.innerHTML = document.getElementById('printableTable').innerHTML;
  window.frames[newLocal_1].window.focus();
  window.frames[newLocal_2].window.print();
  }
  exportAsXLSX(): void {
    this.pagination.itemsPerPage = 1000000;
    this.route.data.subscribe(data => {
      this.repairs = data.repairs.result;
      this.pagination = data.repairs.pagination;
    });
    console.log(this.repairs);
    this.excelService.exportAsExcelFile(this.repairs, 'House Repairs');
    // this.personService.getCustomerLinqList(this.selectedCompany.parentGuid, this.pagination.currentPage,
    //   this.pagination.itemsPerPage, this.userParams)
    // .subscribe((data: PaginatedResult<PersonLinqResultToReturnDto[]>) => {
    //   this.excelService.exportAsExcelFile(data.result, 'Vehicles Advertised');
    //   this.pagination.itemsPerPage = 15;
    // }, error => { this.alertify.error(error); }, () => { this.alertify.success('Your Export is Ready'); } );
  }
  filter(e) {
    console.log(e);
    this.isAll = false;
    if (e === 'All') { this.isAll = true; }
    this.userParams.filter = e;
    this.getRepairs();
  }
}
