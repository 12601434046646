import { Component, OnInit, Input, Injectable, EventEmitter, Output } from '@angular/core';
import { CalendarDto } from 'src/app/_models/DTOS/calendarfilterdto';


@Injectable()
export  class SharedMonthDateFilterService {
  saleDates = new EventEmitter<CalendarDto>();
}
@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.css']
})
export class DateFilterComponent implements OnInit {
  @Input() dateToStart = new Date();
  @Output() calenderOutput:
  EventEmitter<CalendarDto> = new EventEmitter<CalendarDto>();

  calendarDto = {} as  CalendarDto;
  isJanuary = false;
  isFebruary = false;
  isMarch = false;
  isApril = false;
  isMay = false;
  isJune = false;
  isJuly = false;
  isAugust = false;
  isSeptember = false;
  isOctober = false;
  isNovember = false;
  isDecember = false;
  isYear = false;
  month: string;
  day: string;
  endMonth: string;
  endDay: string;
  year: string;
  isAll: boolean;
  yearSelected = 2020;
  startDate: string;
  endDate: string;
  userParams = {} as any;
  monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];
  constructor(private saleDateService: SharedMonthDateFilterService) { }

  ngOnInit() {
    if (this.dateToStart != null || this.dateToStart !== undefined) {
      this.month = this.monthNames[this.dateToStart.getMonth()];
      this.yearSelected = Number(this.dateToStart.getFullYear()) ;
    } else {
      this.dateToStart = new Date() ;
      this.month = this.monthNames[this.dateToStart.getMonth()];
      this.yearSelected = Number(this.dateToStart.getFullYear()) ;
    }
   // this.setYear(this.yearSelected);
  }
  setMonth(e) {
    this.resetIsMonths();
    this.userParams.isFullYear = false;
      this.userParams.isAll = false;
    if (e === 'Year'){
      this.isYear = true;
      this.month = '1';
      this.endDay = '31';
      this.startDate = 1 + '-1-' + this.yearSelected.toString();
      this.endDate = 12 + '-' + 31 + '-' + this.yearSelected.toString();
      this.userParams.isFullYear = true;
      this.userParams.isAll = false;
      this.userParams.monthOfYear = null;
      this.userParams.yearForDate = this.yearSelected;
    }
    if (e === 'All'){
      this.isAll = true;
      this.month = '1';
      this.endDay = '31';
      this.startDate = this.month + '-1-' + 1900;
      this.userParams.isFullYear = false;
      this.userParams.isAll = !this.userParams.isAll;
      this.endDate = this.month + '-' + this.endDay + '-' + this.yearSelected.toString();
      this.userParams.monthOfYear = 'January';
      this.userParams.yearForDate = this.yearSelected;
    }
    if (e === 'January') {
      this.isJanuary = true;
      this.month = '1';
      this.endDay = '31';
      this.startDate = this.month + '-1-' + this.yearSelected.toString();
      this.endDate = this.month + '-' + this.endDay + '-' + this.yearSelected.toString();
      this.userParams.monthOfYear = 'January';
      this.userParams.yearForDate = this.yearSelected;
    }
    if (e === 'February') {
      this.isFebruary = true;
      this.month = '2';
      this.endDay = '28';
      this.startDate = this.month + '-1-' + this.yearSelected.toString();
      this.endDate = this.month + '-' + this.endDay + '-' + this.yearSelected.toString();
      this.userParams.monthOfYear = 'February';
      this.userParams.yearForDate = this.yearSelected;
    }
    if (e === 'March') {
      this.isMarch = true; this.month = '3';
      this.endDay = '31';
      this.startDate = this.month + '-1-' + this.yearSelected.toString();
      this.endDate = this.month + '-' + this.endDay + '-' + this.yearSelected.toString();
      this.userParams.monthOfYear = 'March';
      this.userParams.yearForDate = this.yearSelected;
    }
    if (e === 'April') {
      this.isApril = true;
      this.month = '4';
       this.endDay = '30';
       this.startDate = this.month + '-1-' + this.yearSelected.toString();
      this.endDate = this.month + '-' + this.endDay + '-' + this.yearSelected.toString();
      this.userParams.monthOfYear = 'April';
      this.userParams.yearForDate = this.yearSelected;
      }
    if (e === 'May') {
      this.isMay = true;
      this.month = '5';
      this.endDay = '31';
      this.startDate = this.month + '-1-' + this.yearSelected.toString();
      this.endDate = this.month + '-' + this.endDay + '-' + this.yearSelected.toString();
      this.userParams.monthOfYear = 'May';
      this.userParams.yearForDate = this.yearSelected;
       }
    if (e === 'June') {
      this.isJune = true;
      this.month = '6';
      this.endDay = '30';
      this.startDate = this.month + '-1-' + this.yearSelected.toString();
      this.endDate = this.month + '-' + this.endDay + '-' + this.yearSelected.toString();
      this.userParams.monthOfYear = 'June';
      this.userParams.yearForDate = this.yearSelected;
    }
    if (e === 'July') {
      this.isJuly = true;
      this.month = '7';
      this.endDay = '31';   }
    if (e === 'August') { this.isAugust = true; this.month = '8'; this.endDay = '31';   }
    if (e === 'September') { this.isSeptember = true; this.month = '9'; this.endDay = '30';   }
    if (e === 'October') { this.isOctober = true; this.month = '10'; this.endDay = '31';   }
    if (e === 'November') { this.isNovember = true; this.month = '11'; this.endDay = '30';   }
    if (e === 'December') { this.isDecember = true; this.month = '12'; this.endDay = '31';   }
    if (e === 'Year') { this.isYear = true; this.month = '1'; this.endDay = '31';   }
    this.startDate = this.month + '-1-' + this.yearSelected.toString();
    this.endDate = this.month + '-' + this.endDay + '-' + this.yearSelected.toString();
    this.userParams.monthOfYear = e;
    this.userParams.yearForDate = this.yearSelected;
    this.calendarDto.endDate = this.endDate;
    this.calendarDto.startDate = this.startDate;
    this.calendarDto.month = e;
    this.calendarDto.year = this.yearSelected;
    this.calendarDto.isAll = this.userParams.isAll;
    this.calendarDto.isFullYear = this.userParams.isFullYear;
    this.saleDateService.saleDates.emit( this.calendarDto) ;
    this.calenderOutput.emit(this.calendarDto);
  }
  resetIsMonths() {
    this.isYear = false;
    this.isAll = false;
    this.isJanuary = false;
    this.isFebruary = false;
    this.isMarch = false;
    this.isApril = false;
    this.isMay = false;
    this.isJune = false;
    this.isJuly = false;
    this.isAugust = false;
    this.isSeptember = false;
    this.isOctober = false;
    this.isNovember = false;
    this.isDecember = false;
  }
  setYear(e) {
    if (this.userParams.monthOfYear == null || this.userParams.monthOfYear === undefined) {
      this.userParams.monthOfYear = 'January';
      this.isJanuary = true;
      this.month = '1';
    }
    if (e === 'plus') {
      this.userParams.yearForDate = this.yearSelected;
      this.yearSelected = this.yearSelected + 1;
      this.calendarDto.year = this.yearSelected;
    }
    if (e === 'minus') {
      this.userParams.yearForDate = this.yearSelected;
      this.yearSelected = this.yearSelected - 1;
      this.calendarDto.year = this.yearSelected;
    }
    this.setMonth(this.userParams.monthOfYear);
    this.saleDateService.saleDates.emit( this.calendarDto) ;
    this.calenderOutput.emit(this.calendarDto);
  }
}

