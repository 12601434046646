<div class="container-fluid tree-section-container">

  <div class="col-md-12">
  <div class="row">
     
  <!--NEW PACKAGE? and EXISTING PACKAGES-->
          <!--BUTTONS ADD EDIT DELETE-->
          <div class="col-md-4">
            <p>Package {{packageName}}</p>
            <button (click) = "addNewPackage()">New Sale Package</button>
            <button (click) = "removePackage()" [hidden] = "!isPackageSelected">Delete Package</button>
            <button (click) = "renamePackage()" [hidden] = "!isPackageSelected">Rename</button><br>
            <div style="overflow-y:scroll; min-height: 500px;" id="tree1">
              <br>
              <div *ngFor="let item of salePackages; let i = index;" class = "col-md-6">
                <button (click) = "packageSelected(item)" 
                style="min-width: 200px; min-height: 60px;" 
                class="cstm-btn img-blk-btn  mb-1"
                [ngClass]="{active :  selectedPdfFormPack?.packageName=== item?.packageName }" >
                  <img src="assets/images/ap-icon-6.png" />
                  <span>&nbsp; {{item.packageName}}</span>
                </button>
            </div>
            </div>
            </div>
          <!--FORMS IN A PACKAGE-->
          <!--BUTTONS ADD EDIT DELETE-->
         
     
      <div class="col-md-4">
        <p>Forms selected : {{formNameSelected}}</p>
        <button (click) = "removeFormFromPackage() " [hidden]="!ifFormSelected && !isPackageSelected">Remove From Package</button>
        <button (click) = "addFormToPackage() " [hidden]="!isReportsManagerFormSelected" >Add</button><br><br>
        <div id="tree1" style="overflow-y:scroll; min-height: 500px;">
          <br>
          <div    (click) = "formEditor(item)" style="padding-left: 20px;" 
          *ngFor = "let item of selectedPdfFormPack?.pdfFormsPacks">
            <a class="btn btn-sucess" [class.active]="item === selectedItem">{{ item?.pdfForm?.name }}</a>
          </div>
      </div>
    </div>      

    <!--END MODAL VIEWL 3 Divides-->
</div>
<ng-template #customLoadingTemplate>
  <div class="custom-class">
      <h3>
          We are preparing your PDF's with data...
      </h3>
      <!-- <button (click)="showAlert()">
          Click me!
      </button> -->
  </div>
</ng-template>
<!-- <ngx-loading [show]="loading" fullScreenBackdrop= "false" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading> -->
</div>
