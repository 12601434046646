<div class="customer-form-sec">
  <div class="container-fluid">
    <br><br>
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="misc-info" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-8">
              <div class="inner-misc">
                <div class="misc-grid">
                  <h3 class="misc-title">User Defined</h3>
                  <div class="row">
                    <div class="col-md-6 mise-left-grid">
                      <div class="rw">
                        <label>Custom Field #1</label>
                        <input type="number" class="cstm-input" name="fn1"
                        [ngModel]="sale?.fn1"
                        (ngModel)="sale.fn1 = $event"
                        (ngModelChange) = "sale.fn1 = $event; "
                        (blur) = "onChange($event)" >
                      </div>
                      <div class="rw">
                        <label>Custom Field #2</label>
                        <input type="number" class="cstm-input" name="fn2"
                        [ngModel]="sale?.fn2"
                        (ngModel)="sale.fn2 = $event"
                        (ngModelChange) = "sale.fn2 = $event; "
                        (blur) = "onChange($event)">
                      </div>
                      <div class="rw">
                        <label>Custom Field #3</label>
                        <input type="number" class="cstm-input" name="fn3"
                        [ngModel]="sale?.fn3"
                        (ngModel)="sale.fn3 = $event"
                        (ngModelChange) = "sale.fn3 = $event; "
                        (blur) = "onChange($event)" >
                      </div>
                      <div class="rw">
                        <label>Custom Field #4</label>
                        <input type="number" class="cstm-input" name="fn4"
                        [ngModel]="sale?.fn4"
                        (ngModel)="sale.fn4 = $event"
                        (ngModelChange) = "sale.fn4 = $event; "
                        (blur) = "onChange($event)" >
                      </div>
                      <div class="rw">
                        <label>Alt. Current Due</label>
                        <input type="number" class="cstm-input" name="fn5"
                        [ngModel]="sale?.fn5"
                        (ngModel)="sale.fn5 = $event"
                        (ngModelChange) = "sale.fn5 = $event; "
                        (blur) = "onChange($event)" >
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="rw">
                        <label>Dealer</label>
                        <input type="number" class="cstm-input" name="fs6"
                        [ngModel]="sale?.fs6"
                        (ngModel)="sale.fs6 = $event"
                        (ngModelChange) = "sale.fs6 = $event; "
                        (blur) = "onChange($event)" >
                      </div>
                      <div class="rw">
                        <label>Customer DBA</label>
                        <input type="number" class="cstm-input" name="fs7"
                        [ngModel]="sale?.fs7"
                        (ngModel)="sale.fs7 = $event"
                        (ngModelChange) = "sale.fs7 = $event; "
                        (blur) = "onChange($event)" >
                      </div>
                      <div class="rw">
                        <label>Discovered</label>
                        <input type="number" class="cstm-input" name="fs8"
                        [ngModel]="sale?.fs8"
                        (ngModel)="sale.fs8 = $event"
                        (ngModelChange) = "sale.fs8 = $event; "
                        (blur) = "onChange($event)" >
                      </div>
                      <div class="rw">
                        <label>Custom</label>
                        <input type="number" class="cstm-input" name="fs9"
                        [ngModel]="sale?.fs9"
                        (ngModel)="sale.fs9 = $event"
                        (ngModelChange) = "sale.fs9 = $event; "
                        (blur) = "onChange($event)" >
                      </div>
                      <div class="rw">
                        <label>Custom</label>
                        <input type="number" class="cstm-input" name="fs10"
                        [ngModel]="sale?.fs10"
                        (ngModel)="sale.fs10 = $event"
                        (ngModelChange) = "sale.fs10 = $event; "
                        (blur) = "onChange($event)" >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="misc-grid">
                  <h3 class="misc-title">Misc. Charges History</h3>
                  <div class="table-sec">
                    <table>
                      <tr>
                        <th class="date-th">Date</th>
                        <th class="des-th">Description</th>
                        <th class="charges-th">Charge</th>
                        <th *appHasRole="['Admin']"  class="charges-th">Cost</th>
                        <th *appHasRole="['Admin']"  class="charges-th">Profit</th>
                      </tr>
                      <tr (dblclick) = "editMischCharge(item)" class="td-rw" *ngFor = "let item of dollars; let i=index">
                        <td *ngIf="i != dollars.length - 1" class="date-td">{{item.dateCreated | date: 'yyyy-MM-dd'}}</td>
                        <td *ngIf="i != dollars.length - 1">{{item.description}}</td>
                        <td *ngIf="i != dollars.length - 1" class="charge-td">{{item.customerCost | currency}}</td>
                        <td *ngIf="i != dollars.length - 1"  class="charge-td">{{item.credit | currency}}</td>
                        <td *ngIf="i != dollars.length - 1" class="charge-td">{{item.profit | currency}}</td>
                      </tr>
                      <tr align="right" class="border_top" *ngFor="let item of dollars; let i=index; last as isLast">
                        <td *ngIf="isLast && i !==0" class="charge-td">SUB Totals</td>
                        <td *ngIf="isLast && i !==0" class="charge-td"></td>
                        <td *ngIf="isLast && i !==0" class="charge-td">{{item.customerCost | currency}}</td>
                        <td *ngIf="isLast && i !==0" class="charge-td">{{item.credit | currency}}</td>
                        <td *ngIf="isLast && i !==0"  class="charge-td">{{item.profit | currency}}</td>
                        </tr>
                    </table>
                  </div>
                  <div class="misc-btn-sec">
                    <button (click) = "openMiscModal()" class="cstm-btn">Add</button>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-2">
        <div class="inner-sidebar">
          <ul>
          
            <li>
              <a href="javascript:void(0)"  class="cstm-btn img-blk-btn" (click) = "update()">
                <img src="assets/images/sidebar-icon-2.jpg">
                <span>Save</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)" class="cstm-btn img-blk-btn" (click)="tabelViewClick()">
                <img src="assets/images/sidebar-icon-9.jpg">
                <span>Table View</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
