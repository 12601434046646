import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { CalculateTermDto } from 'src/app/_models/DTOS/CalculateTermDto';
import { ChartInvoiceTransactionGridTOReturnDto } from 'src/app/_models/DTOS/ChartInvoiceTransactionGridTOReturnDto';
import { Member } from 'src/app/_models/member';
import { PaginatedResult } from 'src/app/_models/pagination';
import { Sale } from 'src/app/_models/sale';
import { SaleMiscCharges } from 'src/app/_models/salemisccharges';
import { SaleNotes } from 'src/app/_models/SaleNote';
import { User } from 'src/app/_models/user';
import { UserParams } from 'src/app/_models/userParams';
import { environment } from 'src/environments/environment';

import { getPaginatedResult, getPaginationHeaders } from '../paginationHelper';

@Injectable({
  providedIn: 'root'
})
export class SaleService {
  baseUrl = environment.apiUrl;
  members: Member[] = [];
  memberCache = new Map();
  user: User;
  userParams: UserParams;
  paginatedResults: PaginatedResult<Sale[]> = new PaginatedResult<Sale[]>();
  constructor(private http: HttpClient) { }

  getSaleById(parentguid: string, id): Observable<Sale> {
    return this.http.get<Sale>(this.baseUrl + 'sale/gettingsales/' + parentguid + '/' + id);
  }

  sellVehicle(parentGuid: string, vehicleId): Observable<Sale> {
    return this.http.get<Sale>(this.baseUrl + 'sale/addnewsalevehicle/' + parentGuid + '/' + vehicleId);
  }
  getSales(parentGuid: string): Observable<Sale> {
    return this.http.get<Sale>(this.baseUrl + 'sale/getsale/' + parentGuid);
  }
getSaleInvoice2(parentGuid: string,  page?: number, itemsPerPage?: number, userParams?){
 // console.log(userParams);
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
       params = params.append('pageNumber', page.toString());
       params = params.append('pageSize', itemsPerPage.toString());
    }
      if (params != null && userParams !== undefined) {
      if (userParams.isDeleted !== undefined) {params = params.append('isDeleted', userParams.isDeleted); }
      if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
      if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
      if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
      if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
      if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
      if (userParams.saleTypeId !== undefined) { params  = params.append('saleTypeId', userParams.saleTypeId); }
      if (userParams.saleStatusId !== undefined) { params  = params.append('saleStatusId', userParams.saleStatusId); }
      if (userParams.monthOfYear !== undefined) { params  = params.append('monthOfYear', userParams.monthOfYear); }
      if (userParams.yearForDate !== undefined) { params  = params.append('yearForDate', userParams.yearForDate); }
      if (userParams.isFullYear !== undefined) { params  = params.append('isFullYear', userParams.isFullYear); }
      if (userParams.isAll !== undefined) { params  = params.append('isAll', userParams.isAll); }
    }
  return this.http.get<any[]>(this.baseUrl + 'sale/pagedsales/' + parentGuid, {observe: 'response', params}).pipe(
    map(response => {
          this.paginatedResults.result = response.body;
          if (response.headers.get('Pagination') != null) {
            this.paginatedResults.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return this.paginatedResults;
        })
  );
  // .pipe(response => {
  //   this.paginatedResults.result = response.body;
  // });
}

  getSaleInvoices(parentGuid: string,  page?, itemsPerPage?, userParams?): Observable<PaginatedResult<ChartInvoiceTransactionGridTOReturnDto[]>> {
    const paginatedResult: PaginatedResult<ChartInvoiceTransactionGridTOReturnDto[]> = new PaginatedResult<ChartInvoiceTransactionGridTOReturnDto[]>();

    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (userParams != null) {
      if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
      if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
      if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
      if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
      if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
      if (userParams.saleTypeId !== undefined) { params  = params.append('saleTypeId', userParams.saleTypeId); }
      if (userParams.saleStatusId !== undefined) { params  = params.append('saleStatusId', userParams.saleStatusId); }
      if (userParams.monthOfYear !== undefined) { params  = params.append('monthOfYear', userParams.monthOfYear); }
      if (userParams.yearForDate !== undefined) { params  = params.append('yearForDate', userParams.yearForDate); }
      if (userParams.isFullYear !== undefined) { params  = params.append('isFullYear', userParams.isFullYear); }
      if (userParams.isAll !== undefined) { params  = params.append('isAll', userParams.isAll); }
    }
    return this.http.get<any>(this.baseUrl + 'chartInvoicetransaction/getsalesinvoices/' + parentGuid, {observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  updateSaleDollars(parentGuid: string, sale: Sale) {
    return this.http.get<Sale>(this.baseUrl + 'sale/updatedollarsfordealinfo/' + parentGuid + '/' + sale.id + '/' + sale.vehiclePurchased.id);
  }
  deleteSAle(parentGuid: string, saleId: number) {
    return this.http.get<Sale>(this.baseUrl + 'sale/deletesale/' + parentGuid + '/' + saleId );
  }
  updateSale(parentGuid: string, sale: Sale) {
    return this.http.post<Sale>(this.baseUrl + 'sale/updateasale/' + parentGuid + '/' + sale.id , sale);
  }
  addSaleNote(parentGuid: string, note: SaleNotes, saleid: number) {
      return this.http.post<SaleNotes[]>(this.baseUrl + 'salenotes/addsalenote/' + parentGuid + '/' + saleid , note);
    }
    editSaleNote(parentGuid: string, note: SaleNotes, saleid: number) {
        return this.http.post<SaleNotes[]>(this.baseUrl + 'salenotes/updatesalenote/' + parentGuid + '/' + saleid , note);
      }
      getSaleNotes(saleid: number) {
        return this.http.get<SaleNotes[]>(this.baseUrl + 'salenotes/getsalenotes/'  + saleid );
      }

      addMiscCharge(parentGuid: string, entity: SaleMiscCharges, saleid: number) {
        return this.http.post<SaleMiscCharges[]>(this.baseUrl + 'salemisccharges/addmisccharge/' + parentGuid + '/' + saleid , entity);
      }
      editMiscCharge(parentGuid: string, entity: SaleMiscCharges, saleid: number) {
          return this.http.post<SaleMiscCharges[]>(this.baseUrl + 'salemisccharges/updatemisccharge/' + parentGuid + '/' + saleid , entity);
        }

        getPaymentByTerm(calculateTermDto: CalculateTermDto): Observable<CalculateTermDto>{
          return this.http.post<CalculateTermDto>(this.baseUrl + 'amoloan/calculatebyterm/', calculateTermDto);
        }
}
