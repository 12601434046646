import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RolesModalComponent } from 'src/app/modals/roles-modal/roles-modal.component';
import { UserRoleDto } from 'src/app/_models/DTOS/usersdtos/UserRoleDto';
import { User } from 'src/app/_models/user';
import { AdminService } from 'src/app/_services/admin.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  users: Partial<User[]>;
  bsModalRef: BsModalRef;
  availableRole = {} as UserRoleDto;
  availableRoles2: any[];
  availableRoles = [] as UserRoleDto[];
  constructor(private adminService: AdminService,
    private modalService: BsModalService) { }

  ngOnInit(): void {
    this.getUsersWithRoles();
  }

  getUsersWithRoles() {
    this.adminService.getUsersWithRolesByTennantId().subscribe(users => {
      this.users = users;
    //  console.log(users);
    });
  }

  openRolesModal(user: User) {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        user,
        roles:   this.getRolesArray(user)
      }
    };
    this.bsModalRef = this.modalService.show(RolesModalComponent, config);
    this.bsModalRef.content.updateSelectedRoles.subscribe(values => {
      console.log(values);
      const rolesToUpdate = {
        roles: [...values.filter(el => el.checked === true).map(el => el.name)]
      };
      if (rolesToUpdate) {
        this.adminService.updateUserRoles(user.username, rolesToUpdate.roles).subscribe(() => {
          user.roles = [...rolesToUpdate.roles];
        });
      }
    });
  }
 getRoles(user: User) {
   this.availableRoles = [] as UserRoleDto[];
   this.adminService.getRoles(user.email).subscribe(data => {
  //  this.availableRoles2 = data;
  // console.log(data);
    data.forEach((element) => {
      this.availableRole.name = element;
      this.availableRole.value = element;
      this.availableRole.checked = false;
      this.availableRoles.push(this.availableRole);
    });
    this.getRolesArray(user);
   }, error => { console.log(error); }, () => {
    this.openRolesModal(user);
   });
 }
  private getRolesArray(user) {
    const roles = [] as UserRoleDto[];
    const userRoles = user.roles;
    const availableRoles: any[] =
    // this.availableRoles2.forEach((element) => {
    //   this.availableRole.name = element;
    //   this.availableRole.value = element;
    //   this.availableRole.checked = element.checked;
    //   this.availableRoles.push(this.availableRole);
    // });
    [
      {name: 'Admin', value: 'Admin'},
      {name: 'Moderator', value: 'Moderator'},
      {name: 'Member', value: 'Member'},

      {name: 'CostReadonly', value: 'CostReadonly'},
      {name: 'VehicleCostNoView', value: 'VehicleCostNoView'},
      {name: 'Service', value: 'Service'},
      {name: 'Sales', value: 'Sales'},
      {name: 'Websites', value: 'Websites'},
      {name: 'WebDeveloper', value: 'WebDeveloper'}
    ];

    availableRoles.forEach(role => {
      let isMatch = false;
      for (const userRole of userRoles) {
        if (role.name === userRole) {
          isMatch = true;
          role.checked = true;
          roles.push(role);
          break;
        }
      }
      if (!isMatch) {
        role.checked = false;
        roles.push(role);
      }
    });
    return roles;
  }

}
