import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
@Injectable({ providedIn: 'root' })
export class SaleDataDealTaxes {
    fnz02 = (num) => num.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
        })

          fnz04 = (num) => num.toLocaleString('en-US', {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
            useGrouping: false,
            })
            fnz0 = (num) => num.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
                })

      fillPdfFieldsWithDealTaxes(sale: Sale, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
        console.log(sale);
        if (pdfFields.length > 0 ) {
        let  field = '';
      let newpdfField = {} as PdfSaleFields;
      const newPdfFields = [] as PdfSaleFields[];
        pdfFields.forEach(element => {
            field = element.field;
            const str = field;
            const matches = str.match(/\d+/g);
            let i;
            if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }
            field = field.replace(i.toString(), '');
            newpdfField.groupBy = 'company';

            switch (field) {
                case prefix + 'AmtTaxedA': {
                    newpdfField  = {
                        field: prefix + 'AmtTaxedA' + i,
                        value: sale?.amtTaxed1.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'AmtTaxedB': {
                    newpdfField  = {
                        field: prefix + 'AmtTaxedB' + i,
                        value: sale?.amtTaxed2.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'AmtTaxedC': {
                    newpdfField  = {
                        field: prefix + 'AmtTaxedC' + i,
                        value: sale?.amtTaxed3.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'AmtTaxedD': {
                    newpdfField  = {
                        field: prefix + 'AmtTaxedD' + i,
                        value: sale?.amtTaxed4.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }

                case prefix + 'TaxRateA': {
                    newpdfField  = {
                        field: prefix + 'TaxRateA' + i,
                        value: sale?.taxRate1.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TaxRateB': {
                    newpdfField  = {
                        field: prefix + 'TaxRateB' + i,
                        value: sale?.taxRate2.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TaxRateC': {
                    newpdfField  = {
                        field: prefix + 'TaxRateC' + i,
                        value: sale?.taxRate3.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TaxRateD': {
                    newpdfField  = {
                        field: prefix + 'TaxRateD' + i,
                        value: sale?.taxRate4.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }

                case prefix + 'TotalTaxA': {
                    newpdfField  = {
                        field: prefix + 'TotalTaxA' + i,
                        value: sale?.totalTax1.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TotalTaxB': {
                    newpdfField  = {
                        field: prefix + 'TotalTaxB' + i,
                        value: sale?.totalTax2.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TotalTaxC': {
                    newpdfField  = {
                        field: prefix + 'TotalTaxC' + i,
                        value: sale?.totalTax3.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TotalTaxD': {
                    newpdfField  = {
                        field: prefix + 'TotalTaxD' + i,
                        value: sale?.totalTax4.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Tax': {
                    console.log(prefix + 'Tax' + i);
                    console.log(sale?.tax);
                    newpdfField  = {
                        field: prefix + 'Tax' + i,
                        value: Number(sale?.tax).toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
            }
        });
            return of(newPdfFields);
        }
        return of(pdfFields);
       }
   }
