import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanySeed } from 'src/app/_models/SeedCompany';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SeedService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getSeeds(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/allseeds/' + parentGuid);
  }
  seedQbInvoiceDefaults(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedqbinvoicedefaults/' + parentGuid);
  }
  seedPdfTypeCategory(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedpdftypecategory/' + parentGuid);
  }
  seedPdfEntityModelType(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedentitymodeltype/' + parentGuid);
  }
  seedPdfFormPackagePrintLocation(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedpfformpackageprintlocations/' + parentGuid);
  }
  seedSalesSoldAs(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedsoldas/' + parentGuid);
  }
  seedPropertyRepairTypes(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedpropertyrepairtypes/' + parentGuid);
  }
  seedStates(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedstates/' + parentGuid);
  }
  seedProgramDefaults(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedprogramdefaults/' + parentGuid);
  }
  seeddollartypes(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seeddollartypes/' + parentGuid);
  }
  seedItemTypes(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seeditemtypes/' + parentGuid);
  }
  seedZipCodeLookups(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedzipcodelookups/' + parentGuid);
  }
  seedVehicleBodyTypes(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedvehiclebodyclasstype/' + parentGuid);
  }
  seedFormNameDealInfo(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedformnamedealinfo/' + parentGuid);
  }
  seedVehicleMileageCodes(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedvehiclemileagecodes/' + parentGuid);
  }
  seedSaleType(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedsaletype/' + parentGuid);
  }
  seedLabelsDealInfo(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seeddealinfolabel/' + parentGuid);
  }
  seedLabelsVehicleDetails(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedlabelsvehicledetails/' + parentGuid);
  }
  seedFormNameVehicleDetails(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedformnamevehicledetails/' + parentGuid);
  }
  seedTaxTemplate(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedsaletaxtemplate/' + parentGuid);
  }
  seedSaleStatus(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedsalestatus/' + parentGuid);
  }
  seedCountryList(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedcountrylist/' + parentGuid);
  }
  seedPdfFieldNames(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedpdffieldnames/' + parentGuid);
  }
  seedPdfFieldNameTypes(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedpdffieldnametypes/' + parentGuid);
  }
  seedSettings(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedsettings/' + parentGuid);
  }
  seedAmoLoanMethod(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedloanmethods/' + parentGuid);
  }
  seedAmoInstallments(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedloaninstallments/' + parentGuid);
  }
  seedPaymentType(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedpaymenttype/' + parentGuid);
  }

  seedSaleTaxDefault(parentGuid: string): Observable<CompanySeed> {
    return this.http.get<CompanySeed>(this.baseUrl + 'seedcompany/seedsaletaxdefaults/' + parentGuid);
  }
}
