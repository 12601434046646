import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WebsiteService } from 'src/app/advertising/website.service';
import { SettingsReturned } from 'src/app/_models/DTOS/websitedtos/settingsreturned';
import { Person } from 'src/app/_models/person';
import { ContactForm } from 'src/app/_models/websites/contactform';
import { PersonService } from 'src/app/_services/personinfo/person.service';
import { WebsitePublicService } from 'src/app/_services/website/website.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  model: ContactForm;
  personResult: Person;
  settings: SettingsReturned;
  declarativeFormCaptchaValue: any;
  captchaKey: any;
  isValid: boolean;
  lang: any;
  @ViewChild('registerForm') form: any;
  @ViewChild('registerForm1') form1: any;
  @ViewChild('captchaDiv')
   captchaDiv: ElementRef;
   constructor(private router: Router,
     private personService: PersonService,
     private titleService: Title,
     private website: WebsitePublicService) {
     ///  this.loadWebsiteSettings();
      }

   ngOnInit() {
     this.isValid = true;
     this.titleService.setTitle('Contact-Us AutoMaster Cocoa');
 // grecaptcha.render(this.captchaDiv.nativeElement, {sitekey :'6LfEZYwUAAAAAHHR7UBWlb3_MqujvbaNn9Ebuzas'});
   }

   register() {
     if (this.isValid) {
     this.personService.saveContact(this.form.value).subscribe(data => {
       this.personResult = data;
       localStorage.setItem('contact', JSON.stringify(data));
     }, error => {
       console.log(error);
     }, () => {
       this.router.navigateByUrl('/thankyou');
       // navigate?
     });
   }
   }
   resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response ${captchaResponse}:`);
     if (captchaResponse) {
   //    console.log(captchaResponse);
       this.isValid = true;
     }
 }
 loadWebsiteSettings() {
   this.website.getSiteVerification().subscribe(data => {
  //   console.log(JSON.stringify(data));
     this.captchaKey = data.googleCaptchaKey;
     this.settings = data;
     // working captcha
     // grecaptcha.render(this.captchaDiv.nativeElement, {
     //   sitekey: data.googleCaptchaKey,
     //   callback: (resonse) => this.resolved(resonse)
     // });
  //   console.log(data.googleCaptchaKey);
     // 6Le-sY4UAAAAAHofUrPk95CzkeyEWyCJ02I8c214
     // 6Le-sY4UAAAAAHofUrPk95CzkeyEWyCJ02I8c214 visibletheonweb.com
     // sitekey :data.googleCaptchaKey
   }, error => {
     console.log(error);
   }, () => {
   });
 }
 }
