import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MiscchargAddComponent } from 'src/app/modals/misccharg-add/misccharg-add.component';
import { MiscchargeEditComponent } from 'src/app/modals/misccharge-edit/misccharge-edit.component';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';
import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';
import { Sale } from 'src/app/_models/sale';
import { SaleMiscCharges } from 'src/app/_models/salemisccharges';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { DollarService } from 'src/app/_services/dollarservices/dollar.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';

@Component({
  selector: 'app-sale-miscinfo',
  templateUrl: './sale-miscinfo.component.html',
  styleUrls: ['./sale-miscinfo.component.css']
})
export class SaleMiscinfoComponent implements OnInit {
  @Input() sale: Sale;
  // @Input() dollars = [] as Dollars[];
  // @Input() dollarTypes = [] as DollarTypes[];
  // dollar = {} as Dollars;
  bsModalRef: any;
  dateTime = new Date();
  selectedCompany1: UserAssignedToCompany;
  constructor(private modalService: BsModalService,
    private alertify: ToastrService,
    private saleService: SaleService, private authService: AuthService,
    private dollarService: DollarService,
    private router: Router,
    private saleSerivce: SaleService,
     private format: DealInforFormat) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
  }
  onChange(e) {}
  testclick(e) {console.log(e); }

  // openMiscModal() {
  //   console.log(this.sale);
  //   const config = {
  //     class: 'modal-lg',
  //     initialState: {
  //      sale: this.sale,
  //      dollarTypes: this.dollarTypes,
  //     }
  //   };
  //   this.bsModalRef = this.modalService.show(MiscchargAddComponent, config);
  //   this.bsModalRef.content.emittedValues.subscribe(values => {
  //     this.dollarService.postNewMiscCost(this.selectedCompany1.parentGuid, values, this.sale.id, this.sale.vehiclePurchased.id).subscribe(data => {
  //       this.dollars = data.dollars;
  //     }, error => { this.alertify.error(error); }, () => {
  //       this.alertify.success('Note Saved');
  //       console.log(this.dollars);
  //     });
  //   });
  // }

  // editMischCharge(e) {
  //   console.log('reached gap');
  //   const config = {
  //     class: 'modal-dialog-centered',
  //     initialState: {
  //       saleMiscCharge: e
  //     }
  //   };
  //   this.bsModalRef = this.modalService.show(MiscchargeEditComponent, config);
  //   this.bsModalRef.content.emittedValues.subscribe(values => {
  //     this.saleService.editMiscCharge(this.selectedCompany1.parentGuid, values, this.sale.id).subscribe(data => {
  //       this.sale.saleMiscCharges = data;
  //     }, error => { this.alertify.error(error); }, () => {
  //       this.alertify.success('Note Saved');
  //     });
  //   });
  // }
  async update() {
    console.log(this.sale);
    // if(this.sale.docAuto === "false") {this.sale.docAuto = false; } else { this.sale.docAuto = true; }
    // this.sale.docAuto = 0;
    this.sale.passPmts = 0;
    if (this.sale !== null) {
      this.sale = await this.format.formatDealInforNumbers(this.sale);
      this.saleSerivce
        .updateSale(this.selectedCompany1.parentGuid, this.sale)
        .subscribe(
          (data) => {
            this.alertify.success('Sale Updated');
            this.alertify.success('Accounting will be updated in background.');
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {}
        );
    }
  }
  tabelViewClick(){
    this.router.navigate(['saledashboard/saledashboard/' + this.selectedCompany1.parentGuid]);
  }
}

