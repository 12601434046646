import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Sale } from 'src/app/_models/sale';

@Component({
  selector: 'app-gap-modal',
  templateUrl: './gap-modal.component.html',
  styleUrls: ['./gap-modal.component.css']
})
export class GapModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  sale: Sale;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  updateGap() {

    console.log(this.sale);
    this.emittedValues.emit(this.sale);
    this.bsModalRef.hide();
  }
  onChange(e) {}
}
