import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { UserCart } from 'src/app/_models/UserCart';
import { AuthService } from 'src/app/_services/auth.service';
import { UserCartService } from 'src/app/_services/userpaymentservices/User-Cart.service';
declare var paypal;
@Component({
  selector: 'app-pay-me',
  templateUrl: './pay-me.component.html',
  styleUrls: ['./pay-me.component.css']
})
export class PayMeComponent implements OnInit {
@ViewChild('paypal', {static: true}) paypalElement: ElementRef;
userCart = [] as UserCart[];
orderPlaced = {} as any;
userAssignedToCompany = {} as UserAssignedToCompany;

  orderRecieved = {
    price: 200.00,
    description: 'Monthly Charge',
    companyId: null
  };
paidFor = false;
constructor(private userCartService: UserCartService,
  private authService: AuthService) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(data => {
      this.userAssignedToCompany = data;
      this.orderRecieved.companyId = data.companyProfileId;
    });
    this.userCartService.cartItems.subscribe(data => {
    this.userCart = data;
    this.orderRecieved.price = 0;

      for (let i = 0, n = data.length; i < n; i++)
      {
        this.orderRecieved.price += data[i].amount;
      }
    });
    paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: this.orderRecieved.description,
                amount: {
                  currency_code: 'USD',
                  value: this.orderRecieved.price
                }
              }
            ]
          });
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.paidFor = true;
      //  console.log(order);
        this.orderPlaced = order;
        this.updateDatabase(order);
      }, onError: err => {
        console.log(err);
      }
  })
    .render(this.paypalElement.nativeElement);
  }

  updateDatabase(orderPlaced) {
    console.log(orderPlaced);
    console.log(this.orderRecieved);
  }

}
