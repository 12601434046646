import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { BuyersGuideRoutingModule } from './buyersguide-routing';
import { BuyersguidelistComponent } from './buyersguidelist/buyersguidelist.component';
import { EditbuyersguideComponent } from './buyersguidelist/buyersguide-grid/editbuyersguide/editbuyersguide.component';
import { BuyersguideaddComponent } from './buyersguideadd/buyersguideadd.component';
import { RightmenubuyersguideComponent } from './rightmenubuyersguide/rightmenubuyersguide.component';
import { SystemscoveredaddComponent } from './systemscoveredadd/systemscoveredadd.component';
import { BuyersguideGridComponent } from './buyersguidelist/buyersguide-grid/buyersguide-grid.component';
import { SystemscoveredGridComponent } from './buyersguidelist/systemscovered-grid/systemscovered-grid.component';
import { SystemcoverededitComponent } from './buyersguidelist/systemscovered-grid/systemcoverededit/systemcoverededit.component';



@NgModule({
    declarations: [
        SystemcoverededitComponent,
        SystemscoveredGridComponent,
        BuyersguideaddComponent,
        BuyersguideGridComponent,
        BuyersguidelistComponent,
        RightmenubuyersguideComponent,
        EditbuyersguideComponent,
        SystemscoveredaddComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        BuyersGuideRoutingModule,
        AgChartsAngularModule,
        AgGridModule.withComponents([]),
    ]
})
export class BuyersGuideModule { }
