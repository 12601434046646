<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <div id="printableTable">
                      <br><br>
                      <div *ngIf="isSubscriptionsHistory">
                        <table class="pricing-table">
                          <!-- header -->
                          <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Card Last Four Digits</th>
                          </tr>
                          <tr class="td-row" *ngFor="let item of accountSubscriptionsList; let i=index">
                            <td>{{ item?.planName}}</td>
                            <td>{{ item?.price}}</td>
                            <td>{{item?.startDateUtc | date: 'MM/dd/yyyy'}}</td>
                            <td>{{item?.endDateUtc | date: 'MM/dd/yyyy'}}</td>
                            <td>{{ item?.cardLastFourDigits}}</td>
                          </tr>
                        </table>
                      </div>
                      <div *ngIf="!isSubscriptionsHistory">
                        <table class="pricing-table">
                          <!-- header -->
                          <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Select</th>
                          </tr>
                          <tr align="left" class="td-row" *ngFor="let item of paymentPlansList; let i=index">
                            <td>{{ item?.name}}</td>
                            <td align="right">400</td>
                            <td><input type="checkbox" min="0" [(ngModel)]="item.selected"/></td>
                          </tr>
                        </table>
                        <button type="button" (click)="orderSoftware()" class="pricing-button">Purchase</button>
                      </div>
                    </div>
                  </div>
                  <!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
    </div>
  </div>
</div>