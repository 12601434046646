import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-passwordresetemailsent',
  templateUrl: './passwordresetemailsent.component.html',
  styleUrls: ['./passwordresetemailsent.component.css']
})
export class PasswordresetemailsentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
