<div class="customer-form-sec">
  <div class="container-fluid">
<div class="row">
<div class="form-sec tab-content col-md-12">
  <div id="vehicle" class="tab-pane fade in active show">
<div style="width: 550px" class="modal1" id="myModal">
  <div class="modal-dialog1 modal1" style="width:550px;">
     <div class="modal1-content modal1" style="width:450px;" >

<div  class="modal-header modal1">
  <h4 class="modal-title pull-left">Add Feature</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div  class="modal-body modal1">


<div class="form-sec tab-content col-md-12" style="width:550px !important;">
  <div class="col-md-12 mise-left-grid">
    <form id="editForm" #editForm="ngForm" (ngSubmit)="update()">

    <div class="rw">
      <label>Name</label>
      <input type="text" class="cstm-input" name="feature"
      [ngModel]="featureRequest?.feature"
      (ngModel)="featureRequest.feature = $event"
      (ngModelChange) = "featureRequest.feature = $event; "
      (blur) = "onChange($event)" >
    </div>
    <div class="rw">
      <label >Contact me</label>
      <input type="checkbox" class="cstm-input" name="wouldYouLikeContacted"
      [checked] = "featureRequest?.wouldYouLikeContacted"
      [(ngModel)]="featureRequest.wouldYouLikeContacted"
      (ngModelChange) = "featureRequest.wouldYouLikeContacted = $event; "
      (blur) = "onChange($event)" >
    </div>
    
    <div class="rw">
      <label> Description</label>
      <textarea type="text" class="cstm-input" name="description"
      [ngModel] = "featureRequest?.description"
      (ngModel)="featureRequest.description"
      rows="10"
      cols="30"
      (ngModelChange) = "featureRequest.description = $event; "
      (blur) = "onChange($event)" ></textarea>
    </div>
    <div class="col-md-12 cstm-col ">
      <button [disabled]="!editForm.valid" type="submit" class="btn btn-primary ">
        SAVE
      </button>
      <p *ngIf="!editForm.valid" style="color: red">
        Save Button Will be enabled when all fields entered!
      </p>
    </div>
  </form>
  </div>	
   <!--button save-->
  
</div>

</div>
</div>
  </div>
</div>
  </div>
</div>
</div>
</div>




</div>