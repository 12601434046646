import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CompanyDepartmentType } from 'src/app/_models/CompanyDepartmentType';
import { Person } from 'src/app/_models/person';
import { WebsiteEmployee } from 'src/app/_models/websiteEmployees';

@Component({
  selector: 'app-editdepartment-modal',
  templateUrl: './editdepartment-modal.component.html',
  styleUrls: ['./editdepartment-modal.component.css']
})
export class EditdepartmentModalComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  person = {} as WebsiteEmployee ;
  selectedPersonTypeId: any;
  companyDepartmentType = {} as CompanyDepartmentType;
  @Input() companyDepartmentTypes = [] as CompanyDepartmentType[];
  @Output() update = new EventEmitter();
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log(this.companyDepartmentTypes);
  }
  selected(e) {
    console.log(e);
    this.companyDepartmentType.id = e;
  }
  submit(e) { this.update.emit(this.companyDepartmentType); this.bsModalRef.hide(); }
}
