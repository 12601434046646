import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PaymentType } from 'src/app/_models/paymenttype';

@Component({
  selector: 'app-payment-type-modal',
  templateUrl: './payment-type-modal.component.html',
  styleUrls: ['./payment-type-modal.component.css']
})
export class PaymentTypeModalComponent implements OnInit {
  @Output() updatePaymentType = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  paymentType: PaymentType;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  update() {
    this.paymentType = this.editForm.value;
    this.updatePaymentType.emit(this.paymentType);
    this.bsModalRef.hide();
  }

  selectChange(e) {

  }
}
