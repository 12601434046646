<br /><br />
<div class="container-fluid" *ngIf="settings">
  <div class="row">
    <div class="form-sec tab-content col-md-10">
      <div id="depreciate-costs2" class="tab-pane fade in active show">
        <div class="row">
          <div class="col-md-12">
            <div class="card" style="background-color: #DBE9F4 !important">
              <div class="col-sm-12">
                <div *ngIf="editForm.dirty" class="alert alert-info">
                  <strong>Information:</strong> You have made changes. Any
                  unsaved changes will be lost!
                </div>
              </div>
              <div class="row">
                <div class="col-sm-11">
                  <form
                    novalidate
                    #editForm="ngForm"
                    (ngSubmit)="onSubmit(editForm)"
                  >
                    <!-- Social next field-->
                    <div class="col">
                      <div class="form-group form-inline">
                        <label>Title</label>
                        <input
                          type="text"
                          class="form-control"
                          [ngModel]="settings?.title"
                          (ngModelChange)="settings.title = $event"
                          name="title"
                          #title="ngModel"
                        />
                      </div>
                    </div>
                    <!-- Social next field-->
                    <div class="col">
                      <div class="form-group form-inline">
                        <label>Smtp User Name</label>
                        <input
                          type="text"
                          class="form-control"
                          [ngModel]="settings?.smtpUserName"
                          (ngModelChange)="settings.smtpUserName = $event"
                          name="smtpUserName"
                          #smtpUserName="ngModel"
                        />
                      </div>
                    </div>
                    <!-- Social next field-->
                    <div class="col">
                      <div class="form-group form-inline">
                        <label>Smtp Pass</label>
                        <input
                          type="password"
                          class="form-control"
                          [ngModel]="settings?.smtpPass"
                          (ngModelChange)="settings.smtpPass = $event"
                          name="smtpPass"
                          #smtpPass="ngModel"
                        />
                      </div>
                    </div>
                    <!-- Social next field-->
                    <div class="col">
                      <div class="form-group form-inline">
                        <label>Smtp Port</label>
                        <input
                          type="text"
                          class="form-control"
                          [ngModel]="settings?.smtpPort"
                          (ngModelChange)="settings.smtpPort = $event"
                          name="smtpPort"
                          #smtpPort="ngModel"
                        />
                      </div>
                    </div>
                    <!-- Social next field-->
                    <div class="col">
                      <div class="form-group form-inline">
                        <label>Smtp Out</label>
                        <input
                          type="text"
                          class="form-control"
                          [ngModel]="settings?.smtpOut"
                          (ngModelChange)="settings.smtpOut = $event"
                          name="smtpOut"
                          #smtpOut="ngModel"
                        />
                      </div>
                    </div>
                    <!-- Social next field-->
                    <div class="col">
                      <div class="form-group form-inline">
                        <label>Image Url</label>
                        <input
                          type="text"
                          class="form-control"
                          [ngModel]="settings?.domainUrl"
                          (ngModelChange)="settings.domainUrl = $event"
                          name="domainUrl"
                          #domainUrl="ngModel"
                        />
                      </div>
                    </div>

                    <!-- <button
                      *ngIf="editForm.valid"
                      type="submit"
                      class="btn btn-primary"
                    > 
                      SAVE
                    </button> -->
                  </form>
                </div>


              </div>
            </div>
          </div>
          <!-- /.table-sec -->
        </div>
      </div>
    </div>
    <div class="customer-sidebar sidebar-with-btns col-md-1 mr-2">
      <div class="inner-sidebar">
        <ul style="list-style-type: none">
          <li>
            <button
              type="submit" *ngIf="editForm.dirty" 
              (click)="onSubmit(editForm)"
              class="cstm-btn img-blk-btn"
            >
              <img src="assets/images/ap-icon-1.png" />
              <span>Save</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
