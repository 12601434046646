import { Component, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Website } from 'src/app/_models/website';
import { AuthService } from 'src/app/_services/auth.service';
import { GpsMap } from 'src/app/_models/gpsmap';
import { CompaniesService } from 'src/app/_services/companydataservice/companies.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { WebsiteCompany } from 'src/app/_models/company';
import { CompanyGpsService } from 'src/app/_services/advertising/company-gps.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-gps-map',
  templateUrl: './gps-map.component.html',
  styleUrls: ['./gps-map.component.css']
})
export class GpsMapComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() company: WebsiteCompany;
  @Input() website: Website;
  @Input() gpsMaps: GpsMap;
  userAssignedToCompany: UserAssignedToCompany;
  companyGuid: string;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.editForm.dirty) {
      $event.returnValue = true;
    }
  }
  constructor(
    private alertify: ToastrService,
    private companyGpsService: CompanyGpsService) { }

  ngOnInit() {
    this.gpsMaps = this.website?.company?.gpsMaps;
  }

  onSubmit(e) {
    console.log(this.editForm.value);
    console.log(this.website?.company.parentGuid);
    this.companyGpsService.edit(e.value,  this.website?.websiteGuid).subscribe(data => { this.company = data; }, error => {
      this.alertify.error(error);
    }, () => { this.alertify.success('Success');  this.editForm.form.markAsPristine(); });
  }
}
