
  
  <!-- <div class="customer-header" (contextmenu)="onRightClick($event)"> -->

<br><br><br> 
    <!-- *ngIf="navService.visible === true" -->
    <div style="padding-top: 45px !important;"  class="customer-header">
      <nav  class="navbar navbar-expand-lg navbar-light customer-navbar" style="position:fixed;  left:5px; top:0px; z-index: 800; width: 100%;" >
        <img src="assets/images/menu-icon.jpg">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
  
        <div  class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item"><a></a></li>
             <!--LIST-->
             <li    class="nav-item dropdown">
              <div  *appHasRole="['Member']" class="dropdown" dropdown>
                  <a class="nav-link dropdown-toggle text-light" dropdownToggle>
                   List
                  </a>
                  <div style="background-color: rgb(228, 245, 225) !important;" class="dropdown-menu" *dropdownMenu> 
                    <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Data Seeds</a>
                    <div class="dropdown-divider"></div>                
                    <a class="dropdown-item"><i class="fa fa-sign-out"></i>&nbsp; Sale Status</a>
                    <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Sale Types</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" ><i class="fa fa-user"></i>&nbsp; Payment Types</a>
                    <!-- <a class="dropdown-item" [class]="['/list/paymentmethodgrid', selectedCompany1.parentGuid]"><i class="fa fa-user"></i>&nbsp; Payment Methods</a> -->
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" ><i class="fa fa-user"></i>&nbsp; Mileage Codes</a>
                    <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Vehicle Category/Body-Types</a>
                    <!-- <div class="dropdown-divider"></div> -->
                    <!-- <a class="dropdown-item" [class]="['/list/chartofaccountgrid', selectedCompany1.parentGuid]" ><i class="fa fa-sign-out"></i>&nbsp; Chart Accounts</a> -->
                    <div class="dropdown-divider"></div>
                    <!-- <a class="dropdown-item" [class]="['/list/formnamelist', selectedCompany1.parentGuid]"><i class="fa fa-user"></i>&nbsp; Label List</a>
                    <div class="dropdown-divider"></div> -->
                    <!-- <a class="dropdown-item" [class]="['/list/companydepartmenttypes', selectedCompany1.parentGuid]"><i class="fa fa-user"></i>&nbsp; Company Department Types</a> -->
                  </div>
                </div>
          </li>  
            
            <!--sales-->
           <li  class="nav-item dropdown">
              <div  class="dropdown" dropdown>
                <a class="nav-link dropdown-toggle text-light" dropdownToggle>
                  Sales
                </a>
                  <div class="dropdown-menu" *dropdownMenu>
                    <a class="dropdown-item" ><i class="fa fa-user"></i>&nbsp; Sales</a>
                    <div class="dropdown-divider"></div>
                    <a  class="dropdown-item" ><i class="fa fa-user"></i>&nbsp; Sales Journal</a>
                    <a  class="dropdown-item"  ><i class="fa fa-user"></i>&nbsp; Sales Tax</a>
                  </div>
                </div>
              </li>
              <!--INVENtoRY-->
            <li class="nav-item dropdown">
                <div   class="dropdown" dropdown>
                    <a class="nav-link dropdown-toggle text-light" dropdownToggle>
                      Inventory
                    </a>
                    <div style="background-color: rgb(228, 245, 225) !important;" class="dropdown-menu" *dropdownMenu>
                      <a class="dropdown-item"  ><i class="fa fa-sign-out"></i>&nbsp; Buyers Guide</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item"  [class]="['vehicledashboard/productlist/', selectedCompany1?.parentGuid]"><i class="fa fa-user" ></i>&nbsp; Advertising</a>
                      <a *appHasRole="['Admin']" class="dropdown-item"  [class]="['vehicledashboard/aging/', selectedCompany1?.parentGuid]"><i class="fa fa-user" ></i>&nbsp; Aging List</a>
                      <!-- <a *appHasRole="['Admin']" class="dropdown-item"  [class]="['productlist/vit', selectedCompany1?.parentGuid]"><i class="fa fa-user" ></i>&nbsp; VIT TAX</a>
                      <a class="dropdown-item" (click) = "inventory('sold')"><i class="fa fa-user"></i>&nbsp; Sold</a> -->
                      <!-- <a class="dropdown-item" (click) = "inventory('publishedSold')"><i class="fa fa-user"></i>&nbsp; Published Sold</a>
                      <a class="dropdown-item" (click) = "inventory('active')"><i class="fa fa-user"></i>&nbsp; Active</a>
                      <a class="dropdown-item" (click) = "inventory('nonPublishedActive')"><i class="fa fa-user"></i>&nbsp; Non Published Active</a>
                      <a class="dropdown-item" (click) = "inventory('published')"><i class="fa fa-user"></i>&nbsp;All Published</a>
                      <a class="dropdown-item" (click) = "inventory('publishedneedsimages')"><i class="fa fa-user"></i>&nbsp; Published Need Images</a>
                     
                      <a class="dropdown-item" (click) = "inventory('nonpublishedactive')"><i class="fa fa-user"></i>&nbsp; Non Published Active</a> -->
                      <div class="dropdown-divider"></div>
                      <!-- <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Inventory Defaults</a>
                      <div class="dropdown-divider"></div>                
                      <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Help</a> -->
                    </div>
                  </div>
            </li>
            <!-- <li  class="nav-item dropdown">
                <div  class="dropdown" dropdown>
                    <a class="nav-link dropdown-toggle text-light" dropdownToggle>
                      Tools
                    </a>
  
                    <div style="background-color: rgb(228, 245, 225) !important;" class="dropdown-menu" *dropdownMenu>
                      <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; View All</a>
                      <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Active</a>
                      <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Sold</a>
                      <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Published</a>
                      <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Non Published Active</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Inventory Defaults</a>
                      <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Help</a>
                      <div class="dropdown-divider"></div>
                      <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Compose Buyers Guide</a>
                    </div>
                    <div style="background-color: rgb(228, 245, 225) !important;" class="dropdown-menu" *dropdownMenu>
                        <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; View All</a>
                        <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Active</a>
                        <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Sold</a>
                        <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Published</a>
                        <a class="dropdown-item"><i class="fa fa-user"></i>&nbsp; Non Published Active</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Inventory Defaults</a>
                        <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Help</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" ><i class="fa fa-sign-out"></i>&nbsp; Compose Buyers Guide</a>
                      </div>
                  </div>
            </li> -->
            <!-- <li  class="nav-item"><a class="nav-link" > Window</a></li>
            <li  class="nav-item"><a class="nav-link" > Help</a></li>
            <li  class="nav-item"><a class="nav-link" > New User</a></li> -->
            <!-- <li  class="nav-item"><a class="nav-link" >Order Now</a></li> -->
            <li class="nav-item dropdown">
              <div   class="dropdown" dropdown>
                  <a class="nav-link dropdown-toggle text-light" dropdownToggle>
                    Videos
                  </a>
            <div class="dropdown-menu" *dropdownMenu>
              <!-- <a class="dropdown-item" ><i class="fa fa-user"></i>&nbsp; Sales</a> -->
              <div class="dropdown-divider"></div>
              <a *appHasRole="['Member']" class="dropdown-item" [class]="['video/list']" ><i class="fa fa-user"></i>&nbsp;  Video List</a>
            </div>
            
          </div>
            </li>
          </ul>
          <form *ngIf="!isPasswordReset" #loginForm="ngForm" class="form-inline my-2 my-lg-0" (ngSubmit)="login()">
            <input class="form-control mr-sm-2" type="text" name="email" placeholder="Enter Email" required [(ngModel)]="model.email">
            <input class="form-control mr-sm-2" type="password" name="password" placeholder="Password" required [(ngModel)]="model.password">
            <button [disabled]="!loginForm.valid" class="btn btn-success my-2 my-sm-0" type="submit">Login</button>
          
          </form>
          <form *ngIf="isPasswordReset" #passwordForm="ngForm" class="form-inline my-2 my-lg-0" >
            <input class="form-control mr-sm-2" type="text" name="email" placeholder="Enter Email" required [(ngModel)]="emailStartPwReset.email">
           
          </form>
          <div *ngIf="!isPasswordReset" class="form-inline my-2 my-lg-0" >
            <button  class="btn btn-success my-2 my-sm-0"  (click)="onPasswordRest()"   type="submit">Reset Password</button>
          </div>
          <div *ngIf="isPasswordReset" class="form-inline my-2 my-lg-0" >
            <button  class="btn btn-success my-2 my-sm-0" (click) = "onRequestNewPassword()" type="submit">Request Password Reset</button>
            <button  class="btn btn-alert my-2 my-sm-0" (click) = "onPasswordRest()" type="submit">Cancel</button>
          </div>

            <!-- *ngIf="isNewSupport"  -->

        </div>
   
      </nav>
  
  
      <div style="position: fixed;  left:5px; top:45px; min-width: 100% !important;" class="icons-header ">
        <div class="container-fluid">
          <ul class="main-menu">
            <li [class.active] = "isHome" >
              <a   (click)="isClick('isHome')"  [class]="['/home']" href="javascript:void(0)">
                <img src="assets/images/home.png">
                <span>Home</span>
              </a>
            </li>
            <li [class.active] = "isSales" >
              <a   (click)="isClick('sales')"  [class]="['saledashboard/saledashboard', selectedCompany1?.parentGuid]" href="javascript:void(0)">
                <img src="assets/images/icon-2.jpg">
                <span>Sales</span>
              </a>
            </li>
            <li [class.active] = "isAddVehicle">
              <a href="javascript:void(0)"    >
                <img src="assets/images/icon-4.jpg">
                <span>Add Item</span>
              </a>
            </li>
            <li [class.active] = "isInventory" >
              <a (click)="isClick('isInventory')"  [class]="['vehicledashboard/productlist', selectedCompany1?.parentGuid]"  href="javascript:void(0)">
                <img src="assets/images/icon-5.jpg">
                <span>Inventory</span>
              </a>
            </li>
            <!-- <li [class.active] = "isBuyersGuid">
              <a  (click) = "clickBuyersGuid()" data-toggle="modal" data-target="#buyer-guide-model">
                <img src="assets/images/icon-7.jpg">
                <span>Buyer Guide</span>
              </a>
            </li> -->
            <!-- <li [class.active] = "isService"  >
              <a *appHasRole="['Admin', 'Realestate']" (click)="isClick('isService')" [ngClass]="isService ? 'acitve' : ''"  [class]="['service/servicegrid']"  href="javascript:void(0)" >
                <img src="assets/images/realestate.jpg">
                <span>Service</span>
              </a>
            </li> -->
            <li  [class.active] = "isAdvertising"  >
                <a   [ngClass]="isAdvertising ? 'acitve' : ''"   href="javascript:void(0)">
                  <img src="assets/images/web.png">
                  <span>Advertising</span>
                </a>
              </li>
            <!-- <li  [class.active] = "isCredit">
              <a (click)="isClick('isCredit')"  href="javascript:void(0)" [class]="['creditbureaus', selectedCompany1?.parentGuid]"  >
                <img src="assets/images/icon-6.jpg">
                <span>Credit</span>
              </a>
            </li>
            
             
            <li>
                <li  (click)="isClick('isRealEstate')" [class.active] = "isRealEstate">
                    <a *appHasRole="['Admin', 'Realestate']"   [class]="['realestate/list', selectedCompany1?.parentGuid]"  href="javascript:void(0)" >
                      <img src="assets/images/realestate.jpg">
                      <span>Real Estate</span>
                    </a>
                  </li> -->
              
            <li  [class.active] = "isReports"> 
              <a (click)="isClick('isReports')"   [class]="['/reportsmanager/reportsmanager', selectedCompany1?.parentGuid]"   href="javascript:void(0)">
                <img src="assets/images/pdf.jpg">
                <span>PDFs</span>
              </a>
            </li>
            <!-- <li  [class.active] = "isAccounting">
              <a (click)="isClick('isAccounting')" *appHasRole="['Admin']"   [class]="['/accountingdashboard/generalledger', selectedCompany1?.parentGuid]"  href="javascript:void(0)">
                <img src="assets/images/icon-9.jpg">
                <span>Reports</span>
              </a>
            </li> -->
            <li  [class.active] = "isPhoneBook">
              <a (click)="isClick('isPhoneBook')"  [class]="['/dashboardpeople/list/', selectedCompany1?.parentGuid]"  href="javascript:void(0)">
                <img src="assets/images/icon-10.jpg">
                <span>People</span>
              </a>
            </li>
            <!-- addPaymentModal -->
            <!-- <li   [class.active] = "isPayments">
              <a  data-toggle="modal" data-target="#paymentScreenModal" (click)="isClick('isPayments')"   href="javascript:void(0)">
                <img src="assets/images/icon-11.jpg">
                <span>Payments</span>
              </a>
            </li> -->
            <li  [class.active] = "isLoan">
              <a (click)="isClick('isLoan')"  [class]="['dashboardloans/loanlist/', selectedCompany1?.parentGuid]" href="javascript:void(0)">
                <img src="assets/images/icon-14.jpg">
                <span>Recievables</span>
              </a>
            </li>
           
            <!-- <li  [class.active] = "isCalculator">
              <a (click)="isClick('isCalculator')"  href="javascript:void(0)" (click)="openCalculator()">
                <img src="assets/images/icon-13.jpg">
                <span>Payables</span>
              </a>
            </li> -->
            <!-- <li  [class.active] = "isCalculator">
              <a (click)="isClick('isCalculator')"  href="javascript:void(0)" (click)="openCalculator()">
                <img src="assets/images/icon-13.jpg">
                <span>POS</span>
              </a>
            </li> -->
            <li [class.active] = "isSupport" >
                <a   [class]="['/supportdashboard/supporttickets']"  href="javascript:void(0)">
                  <img src="assets/images/icon-14.jpg">
                  <span>Support</span>
                </a>
              </li>
            <li [class.active] = "isAdmin"  >
                <a (click)="isClick('isAdmin')"  [class]="['/admindashboard/admin']"  href="javascript:void(0)">
                  <img src="assets/images/admin.png">
                  <span>Admin</span>
                </a>
              </li>
              <li [class.active] = "isCompanyProfile"  >
                <a  [class]="['companyprofiledashboard/home/', selectedCompany1?.parentGuid]"  href="javascript:void(0)">
                  <img src="assets/images/company.png">
                  <span>Company</span>
                </a>
              </li>
              <!--  -->
              <li [class.active] = "isVideos" >
                <a    [class]="['video/list']" href="javascript:void(0)">
                  <img src="assets/images/camera.jpg">
                  <span>Videos</span>
                </a>
              </li>
              <!-- <li  style="margin-left: 20px;">
                  <a (click)="logout()" href="javascript:void(0)">
                    <img src="assets/images/icon-14.jpg">
                    <span>LogOut</span>
                  </a>
                </li> -->
          </ul>
        </div>
  
      </div>
  
  
         <div *ngIf="isRoute != undefined && isRoute.includes('admin2')" class="container-fluid customer-tabs-sec">
          <ul class="nav nav-tabs">
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show"   class='admin/dashboard' >Home Admin</a></li>
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" class='admin/listcomp' >Companies</a></li> 
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" class='admin/userlist' >User Info</a></li>
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" class='admin/rolemanager' >Permissions</a></li>
          </ul>
        </div>
        <div *ngIf="isRoute != undefined && isRoute.includes('estatedashboard')" class="container-fluid customer-tabs-sec">
            <ul class="nav nav-tabs">
              <!-- <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show"   class='compedit/edit/company.id' >Edit Company</a></li> -->
              <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" [class]="['editweb/companyinfo/', selectedCompany1.parentGuid]"   >Company Info</a></li>
              <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" [class]="['editweb/socialmedia/', selectedCompany1.parentGuid]" >Projections</a></li>
              <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" class='editweb/content' >Contribution Shares</a></li>
            </ul>
          </div>
        <div *ngIf="isRoute != undefined && isRoute.includes('editweb')" class="container-fluid customer-tabs-sec">
          <ul class="nav nav-tabs">
            <!-- <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show"   class='compedit/edit/company.id' >Edit Company</a></li> -->
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" [class]="['editweb/companyinfo/', selectedCompany1.parentGuid]"   >Company Info</a></li>
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" [class]="['editweb/socialmedia/', selectedCompany1.parentGuid]" >Website Info</a></li>
            <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" class='editweb/content' >Content</a></li>
          </ul>
        </div>
        <div *ngIf="isRoute != undefined && isRoute.includes('compedit')" class="container-fluid customer-tabs-sec">
            <ul class="nav nav-tabs">
              <!-- <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show"   class='compedit/edit/company.id' >Edit Company</a></li> -->
              <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" class='admin/listcomp' >Defaults</a></li>
              <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" class='admin/userlist' >User List</a></li>
            </ul>
          </div>
        <div *ngIf="isRoute != undefined && isRoute.includes('realestate')" class="container-fluid customer-tabs-sec">
            <ul class="nav nav-tabs">
              <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show"   class='realestate/estateadd' >House Details</a></li>
              <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" class='realestate/estaterepairs' >House Repairs</a></li>
              <li routerLinkActive="active"><a data-toggle="tab" routerLinkActive="active show" class='realestate/estatehouseincome' >Rental Income</a></li>
            </ul>
          </div> 
    </div> 
    
    <div *ngIf="isRoute != undefined && !isRoute.includes('productlist') " class="xtra-div">
  
    <!-- <mat-progress-bar style="width: 100%; min-height: 15px; color: blue;" color="accent" *ngIf="loading"  mode="indeterminate"></mat-progress-bar> -->
  </div>
  

  