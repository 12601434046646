<div class="col-md-12 ">
  <div class="row">
  <div class="col-md-2">

  </div>
  <div class="col-md-10">

    <button *ngIf="!isUpload" class="btn btn-outline-success ml-2 my-2" (click)="uploadPdf()">Upload PDF</button>
    <button *ngIf="isUpload" class="btn btn-outline-success ml-2 my-2" (click)="uploadCancelPdf()">Cancel Upload</button>
<div class="container mt5">
    <div class="row">
   
      <div class="col-12">
        <app-paging-header
        [totalCount] = "totalCount"
        [pageSize] = "shopParams.pageSize"
        [pageNumber] = "shopParams.pageNumber"
      ></app-paging-header>
        <div class="form-inline mt-2">
          <input (keyup.enter)="onSearch()" class="form-control mr-2" ng-model="searchValue" #search style="width: 300px;" type="text" placeholder="search">
          <button (click)="onSearch()"  class="btn btn-outline-primary my-2" >Search</button>
          <label class="mr-2 ml-2">Category</label>
            <select style="width: 140px;"  class="form-control mr-2" #pdfFormTypeCategory="ngModel" ngControl="selectedCategoryType"  name="pdfFormCategoryTypeIds"    
            [(ngModel)] ="pdfForm.id"  
            (change)="formTypeCategorySelected($event.target.value)" 
            >
            <option  *ngFor="let item of pdfFormTypesCategorys; index as i"   [value]="item.id">{{item?.value}}</option>
            </select>
          <!-- <button (click)="addProduct()" class="btn btn-outline-success ml-2 my-2" >Add Product</button> -->
            <label class="mr-2 ml-2">Form Type</label>
            <select style="width: 140px;"  class="form-control mr-2" #pdfFormType="ngModel" ngControl="selectedType"  name="pdfFormTypeId"    
            [(ngModel)] ="pdfForm.pdfFormTypeId"  (ngModelChange)="formTypeSelected($event)"
             >
            <option *ngFor="let item of pdfFormTypes; index as i"   [value]="item.id">{{item?.value}}</option>
            </select>
     
            <!-- <select style="width: 140px;"  class="form-control mr-2" #pdfFormState="ngModel" ngControl="selectedType"  name="pdfFormTypeId"    
            [(ngModel)] ="pdfForm.id"  (ngModelChange) = "onCategorySelected($event)" 
             >
            <option *ngFor="let item of supportSubMenusPdf; index as i"   [value]="item.id">{{item?.name}}</option>
            </select> -->
            <button (click)="onResetSearch()" class="btn btn-outline-success ml-2 my-2" >Reset</button>
        </div>
    <!-- grid -->
  <div class="row">
    <div class="col-12">
      <div class="col-md-10">
        <app-pdf-uploader (isPdfCompleted)="unloadPdloader($event)"  *ngIf="isUpload" [selectedTypeId] = "selectedTypeId" [pdfFormTypes] = "pdfFormTypes" [formNameToUpload] = "formNameToUpload" [selectedType] = "selectedType"></app-pdf-uploader>
      </div>
      <table  *ngIf="!isUpload" class="table table-hover" style="cursor: pointer;">
        <thead class="thead-light">
        <tr>
            <td>Icon</td>
          <td>Id</td>
          <th>Name</th>
          <!-- <th>Price</th>-->
          <th>Active?</th>
          <th>Image</th>
          <th>Rename</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let order of pdfListGridViewDto">
            <th><i class="fa fa-file-pdf-o"></i> </th>
          <th># {{order.id}}</th>
          <td  *ngIf="order.pdfFormImageUrl === null" > <i style="color: red;" class=" fa fa-check-circle "></i></td>
          <td  *ngIf="order.pdfFormImageUrl !== null"> <i style="color: green;" class=" fa fa-check-circle"></i></td>
          <th>{{order.name}}</th>
          <!-- <td>{{order.price | currency}}</td>
          <td>{{ order?.descriptionSmall}}</td>
          <td>{{order.price | currency}}</td> -->
          <td  *ngIf="order.deletedDate !== null" > <i style="color: red;" class=" fa fa-check-circle "></i></td>
          <td  *ngIf="order.deletedDate === null"> <i style="color: green;" class=" fa fa-check-circle"></i></td>
        
        <td style="text-align: center ;" class=" text-primary">
          <a class="text-success">
              <i  (click)="editPdfDescription(order)" class="fa fa-pencil" style="font-size: 2em; cursor: pointer;"></i>
          </a>
      </td>
          <td class="align-middle text-primary">
            <a class="text-success">
                <i  (click)="editPdf(order)" class="fa fa-edit" style="font-size: 2em; cursor: pointer;"></i>
            </a>
        </td>
      
          <td class="align-middle text-center">
            <a class="text-danger">
                <i  (click)="deleteProduct(order)" class="fa fa-trash" style="font-size: 2em; cursor: pointer;"></i>
            </a>
        </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</div>
<div class="d-flex justify-content-center" *ngIf="totalCount && totalCount > 0">
    <app-pager
    [pageSize]="shopParams.pageSize"
    [totalCount]="totalCount"
    (pageChanged)="onPageChange($event)"
    ></app-pager>
    </div>
    </div>
    </div>
    </div>
    </div>

