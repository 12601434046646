import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Sale } from 'src/app/_models/sale';
import { Vendor } from 'src/app/_models/vendor';


@Component({
  selector: 'app-vendor-modal',
  templateUrl: './vendor-modal.component.html',
  styleUrls: ['./vendor-modal.component.scss']
})
export class VendorModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  sale: Sale;
  isWarrantyCompany: boolean;
  isInsuranceCompany: boolean;
  isLienHolder: boolean;
  amount: number;
  constructor(public bsModalRef: BsModalRef) { }
  vendor: Vendor;

  ngOnInit() {
  }
  update() {
    this.sale.warrantyCompany.isWarrantyCompany = false;
    this.sale.warrantyCompany.isLienHolder = false;
    this.sale.warrantyCompany.isInsuranceCompany = false;
    if (this.isWarrantyCompany) {
      this.sale.warrantyCompany.isWarrantyCompany = true;
    }
    if (this.isInsuranceCompany) {
      this.sale.warrantyCompany.isInsuranceCompany = true;
    }
    if (this.isLienHolder) {
      this.sale.warrantyCompany.isLienHolder = true;
    }
    console.log(this.sale);
    this.emittedValues.emit(this.sale);
    this.bsModalRef.hide();
  }
  onChange(e) { }
}

