import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PersonLinqResultToReturnDto } from '../_models/DTOS/PersonLinqResultToReturnDto';
import { RealPropertyService } from '../_services/realpropertyservices/realpropertyservice';
import { ToastrService } from 'ngx-toastr';
import { RealPropertyLinqListDto } from '../_models/DTOS/realpropertylinqlistdto';
import { RealProperty } from '../_models/RealProperty';


@Injectable({
    providedIn: 'root'
})
export class RealPropertyEditDtoResolver implements Resolve<RealProperty> {
   // parentGuid
    constructor(private realPropertyService: RealPropertyService, private router: Router,
        private alertify: ToastrService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<RealProperty> {
        return this.realPropertyService.get(route.params.propertyid, route.params.parentGuid).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving the property.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
