




    <div class="col-md-12">
    <div class="row"> 
   
        <div  class="col-md-2">
           
            <div class="col-md-12 fixed" >
                <button class="button "   (click)="save('false')"  href="javascript:void(0)">
                    <img src="assets/images/icon-2.jpg"><br>
                    <span>Save</span>
                  </button><br>
                  <!-- (click)="calculator()"  -->
                  <button class="button" [ngClass]="{'active' : isCreateLabel}"   
                  href="javascript:void(0)" (click)="calculator()">
                    <img src="assets/images/icon-2.jpg"><br>
                    <span>Calculator</span>
                  </button><br>
                  <button class="button"   (click)="delteFIelds()"  href="javascript:void(0)">
                    <img src="assets/images/icon-2.jpg"><br>
                    <span>Clear All</span>
                  </button>
            </div>  
            <!-- <div class="col-md-12 fixed">
                <a class="button"   (click)="delteFIelds()"  href="javascript:void(0)">
                    <img src="assets/images/icon-2.jpg"><br>
                    <span>Clear All</span>
                  </a>
            </div> -->
            <br><br>   <br><br>   <br><br>
            <form *ngIf="ctrlSelected.length > 1 && !isSelected" id="dealInfoForm"
            #dealInfoForm="ngForm">
                <div class="col-md-12 fixed" >
                  
                    <button (click) = "alignLRCCtrlClick('left')" class="button" [ngClass]="{'active' : isLeft}">  <i  class="fa fa-align-left" style="size: 30px !important; float: Left; color: rgb(10, 184, 25);" ></i> </button>
                    <button (click) = "alignLRCCtrlClick('center')" class="button" [ngClass]="{'active' : isCenter}">  <i  class="fa fa-align-center" style="size: 30px !important; float: left; color: rgb(10, 184, 25);" ></i> </button>
                    <button (click) = "alignLRCCtrlClick('right')" class="button" [ngClass]="{'active' : isRight}">  <i  class="fa fa-align-right" style="size: 30px !important; float: left; color: rgb(10, 184, 25);" ></i> </button>
                <br><br>
              
                <br>
                    <label>Length &nbsp;</label>
                    <input name="width"
                    [ngModel]="field?.width || 0.00"
                    (ngModel)="field.width = $event"
                    (ngModelChange) = "field.width = $event; "
                      value='0.00' type="number"  style="max-width: 50px; border-color: rgb(10, 184, 25);">
                      <br>
                      <label>Height &nbsp;</label>
                      <input name="height"
                      [ngModel]="field?.height || 0.00"
                      (ngModel)="field.height = $event"
                      (ngModelChange) = "field.height = $event; "
                        value='0.00' type="number"  style="max-width: 50px; border-color: rgb(10, 184, 25);">
               
                        <br>
                     
                        <button  *ngIf="isSelected"  (click) = "onRemoveButtonClick()" class="buttonwarn" > Delete Field</button>
                    </div>
            </form>
          
            <br><br>
            <form *ngIf="isSelected" id="dealInfoForm"
                #dealInfoForm="ngForm">
                    <div class="col-md-12 fixed" >
                        <button  *ngIf="isSelected"  (click) = "onRemoveButtonClick()" class="buttonwarn" > Delete Field</button><br><br>
                        <button (click) = "alignLRC('left')" class="button" [ngClass]="{'active' : isLeft}">  <i  class="fa fa-align-left" style="size: 30px !important; float: Left; color: rgb(10, 184, 25);" ></i> </button>
                        <button (click) = "alignLRC('center')" class="button" [ngClass]="{'active' : isCenter}">  <i  class="fa fa-align-center" style="size: 30px !important; float: left; color: rgb(10, 184, 25);" ></i> </button>
                        <button (click) = "alignLRC('right')" class="button" [ngClass]="{'active' : isRight}">  <i  class="fa fa-align-right" style="size: 30px !important; float: left; color: rgb(10, 184, 25);" ></i> </button>
                    <br>
                        <label>Length</label>
                        <input name="width"
                        [ngModel]="field?.width || 0.00"
                        (ngModel)="field.width = $event"
                        (ngModelChange) = "field.width = $event; "
                          value='0.00' type="number"  style="max-width: 50px; border-color: rgb(10, 184, 25);">
                          <br>
                          <label>Height</label>
                          <input name="height"
                          [ngModel]="field?.height || 0.00"
                          (ngModel)="field.height = $event"
                          (ngModelChange) = "field.height = $event; "
                            value='0.00' type="number"  style="max-width: 50px; border-color: rgb(10, 184, 25);">
                    </div>
                </form>
            <br><br><br><br><br>
            <div class="col-md-12 " *ngIf="ctrlSelected.length>1 && !isSelected">
                <label class="fixed">ALIGN:</label><br>
                <button class="fixed" (click) = "alignItems('bottoms')" style="width: 120px; size: 20px !important; float: left; color: rgb(10, 184, 25);">Bottoms</button><br>
                <button class="fixed" (click) = "alignItems('lefts')" style="width: 120px; size: 20px !important; float: left; color: rgb(10, 184, 25);">Lefts</button><br>
                <button class="fixed" (click) = "alignItems('rights')" style="width: 120px; size: 20px !important; float: left; color: rgb(10, 184, 25);">Rights</button><br>
                <button class="fixed" (click) = "widthMakeAllSame()" style="width: 120px; size: 20px !important; float: left; color: rgb(10, 184, 25);">Width</button><br>
                <button class="fixed" (click) = "heightMakeAllSame()" style="width: 120px; size: 20px !important; float: left; color: rgb(10, 184, 25);">Height</button><br>
            </div>
          
        </div>
        <!-- <div class="col-md-10" [hidden] = "!isCacluator">
            <app-calculator-pdf></app-calculator-pdf>
        </div> -->
        <div class="col-md-10" >
        <tabset>
            <tab heading="PDF" id="tab1" (selectTab)="onSelect('pdf')">
                <div class="col-md-10 scrollit"  >

                    <div class="div" class="dotted" (dblclick) = "isDblClick('New Field')">
                    <div #dropZone   id="container1"
                    cdkDropListSortingDisabled="true"  
                    class = "container  example-boundary "  
                    style = "width: 816px !important; 
                    min-height: 1056px !important;" 
                    >
                   <div class="border "  >
                    
                    <!-- [ngClass]="{'width' : f.width}" -->
                    <div id ="{{ i }}" *ngFor="let f of fields; let i = index;"    
                    cdkDragHandle
                    (contextmenu)="rightClick($event, f)"
                    style="padding: none !important;  cursor: pointer;"
                      class="textField initialPosition" 
                      [attr.data-guid]="f.guid"  
                      [style.width.px]="(f.width / .75)"
                      [style.height.px]="(f.height / .75)"
                      [ngClass]="{'success fa fa-exclamation-triangle': f.id === field.id}" 
                      (mouseenter)="onFieldHover($event)" 
                      cdkDrag 
                      cdkDragBoundary=".example-boundary "
                      (click) = "selectedField(f, $event)" 
                      (cdkDragEnded)="onDragEnded($event, f)" 
                      [cdkDragFreeDragPosition]="f.position"
                      
                     > &nbsp;&nbsp;&nbsp;&nbsp;  {{f.name}}
        
                     <!-- <div class="textField-inner" >
                      </div> -->
                      <div *ngIf="isSelected == f"  style="float: Left; size: 40px !important;" >
                        <i (click) = "onRemoveTextField(f.guid)" class="fa fa-trash" style="size: 40px !important; float: Left; color: red;" ></i> 
                    </div>
                    <div *ngIf="isSelected !== f"  style="float: Left; size: 40px !important;" >
                        <i [ngClass]="f.selected ? 'fa fa-edit' : 'fa fa-smile-o' "  style="size: 40px !important; float: Left; color: rgb(7, 117, 3);" ></i> 
                    </div>
                    <!-- class="fa fa-smile-o" -->
                    </div>
                    </div>
                    <!-- [render-text]="true"
                    [render-text-mode]="2"
                   (text-layer-rendered)="textLayerRendered($event)"  (page-rendered)="pageRendered($event)"-->

                   <pdf-viewer   id="pdfViewer"  [src]="pdfSrc" 
                    [render-text]="false"
                    [original-size]="true"
                    style=" display: block; width: 816px; min-height: 1056; z-index: -1;"
                    (click) = "dragMoveListener($event)" 
                    (error) = "onError($event)" 
                    >            
                    </pdf-viewer>
                
                </div>
            </div> 
                </div>
            </tab>
            <tab (selectTab)="onSelect('calculator')" heading="Create Calculated Fields"><app-calculator-pdf  ></app-calculator-pdf></tab>
          </tabset>
        </div>
    </div>

   
</div>




 