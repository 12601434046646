<br /><br />
<div class="customer-form-sec" *ngIf="vehicleCashDownDropDowns?.length > 0">
  <div class="container-fluid">
    <div class="row">
      <!-- style="background-color:#DBE9F4 !important;" -->
      <div class="form-sec tab-content col-md-12">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->

                <div class="row">
                  <div class="col-md-5 table-sec">
                    <table border="1" borderColor="#ccc">
                      <tr>
                        <th>Id</th>
                        <th>Amount</th>
                        <th>Text Before</th>
                        <th>Text After</th>
                        <th>Is Active</th>
                        <th>Order</th>
                        <th></th>
                      </tr>
                      <tr
                        class="td-row"
                        *ngFor="let item of vehicleCashDownDropDowns"
                      >
                        <td>{{ item.id }}</td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.textBefore }}</td>
                        <td>{{ item.textAfter }}</td>
                        <td>{{ item.isActive }}</td>
                        <td>{{ item.sortOrder }}</td>
                        <td>
                          <a
                            class="btn btn-primary"
                            (click)="navigate(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View Order Detail"
                            ><i class="fa fa-fw fa-eye"></i
                          ></a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- /.table-sec -->
                  <!--FORM-->
                  <div class="col-md-5">
                    <form
                      id="editForm"
                      [hidden]="!isSelected"
                      novalidate
                      #editForm="ngForm"
                      (ngSubmit)="onSubmit(editForm)"
                    >
                      <div class="col-md-6">
                        <div class="form-group form-inline">
                          <label>Before:</label>
                          <input
                            style="width: 36%"
                            type="text"
                            class="form-control"
                            [ngModel]="model?.textBefore"
                            (ngModelChange)="model.textBefore = $event"
                            name="textBefore"
                            #textBefore="ngModel"
                            required
                          />
                        </div>
                      </div>
                      <div class="col-md-6 form-group form-inline">
                        <label>Amount:</label>
                        <input
                          type="text"
                          style="width: 36%"
                          class="form-control"
                          [ngModel]="model?.amount"
                          (ngModelChange)="model.amount = $event"
                          name="amount"
                          #amount="ngModel"
                          required
                        />
                      </div>
                      <div class="col-md-6 form-group form-inline">
                        <label>After:</label>
                        <input
                          type="text"
                          style="width: 36%"
                          class="form-control"
                          [ngModel]="model?.textAfter"
                          (ngModelChange)="model.textAfter = $event"
                          name="textAfter"
                          #textAfter="ngModel"
                          required
                        />
                      </div>
                      <div class="col-md-6 form-group form-inline">
                        <label>Order:</label>
                        <input
                          type="number"
                          style="width: 36%"
                          class="form-control"
                          [ngModel]="model?.sortOrder"
                          (ngModelChange)="model.sortOrder = $event"
                          name="sortOrder"
                          #sortOrder="ngModel"
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <!--BUTTON BAR-->
                  <div class="customer-sidebar sidebar-with-btns col-md-1 mr-2">
                    <div class="inner-sidebar">
                      <ul>
                        <li>
                          <button
                            *ngIf="editForm.dirty"
                            type="submit"
                            form="editForm"
                            class="cstm-btn img-blk-btn"
                          >
                            <img src="assets/images/ap-icon-1.png" />
                            <span>SAVE</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
    </div>
  </div>
</div>
