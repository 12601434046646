<!-- <div class="customer-form-sec"> -->
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div id="customer" class="tab-pane fade in active show">
<div class="modal-header">
    <h4 class="modal-title pull-left">Edit  {{user?.username }} </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #rolesForm="ngForm" id="rolesForm">
        <div class="form-check" *ngFor="let company of allCompanies">
            <input type="checkbox"
                class="form-check-input"
                [checked]="company?.isAssigned"
                value="company.companyName"
                (change)="company.isAssigned = !company.isAssigned"
                [disabled]=" user?.username === 'Admin1'"
            >
            <label class="mr-2">{{company.companyName}}</label>
        
            <label>Is Default</label>
          <input type="checkbox" 
              class="form-check-input"
              [checked]="company?.isDefaultCompany"
              (ngModel)= "company.isDefaultCompany"
              [ngModel]= "company?.isDefaultCompany"
              value="company.isDefaultCompany"
              (change)="changeCompanyDefault(company)"

          >
         
        </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close()">Cancel</button>
    <button type="button" class="btn btn-success" (click) = "updateEmployee()">Submit</button>
  </div>
  </div>
  </div>
  </div>
  </div>
  <!-- </div> -->


