<div style="padding-top: 40px;" class="container-fluid">


    <div class="container-fluid customer-tabs-sec">
      <ul class="nav nav-tabs">
        <li><a [ngClass]="{'active show' : isVehicles}" (click)="tabClicked('isVehicles')"
               href="javascript:void(0)">Vehicles Import</a></li>
    
        <li><a [ngClass]="{'active show' : isCost}" (click)="tabClicked('isCost')"
               href="javascript:void(0)">Repairs Import</a></li>

               <li><a [ngClass]="{'active show' : isPerson}" (click)="tabClicked('isPerson')"
               href="javascript:void(0)">Persons Import</a></li>

               <!-- <li><a [ngClass]="{'active show' : isCost}" (click)="tabClicked('isCost')"
                     href="javascript:void(0)">Repairs Import</a></li> -->

                     <li><a [ngClass]="{'active show' : isExpenses}" (click)="tabClicked('isExpenses')"
                     href="javascript:void(0)">Expenses Import</a></li>

                     <li><a [ngClass]="{'active show' : isInsurance}" (click)="tabClicked('isInsurance')"
                            href="javascript:void(0)">Insurance Import</a></li>
                            <li><a [ngClass]="{'active show' : isLienHolders}" (click)="tabClicked('isLienHolders')"
                                   href="javascript:void(0)">Import LienHolders</a></li>

                                   <li><a [ngClass]="{'active show' : isSimpleInterest}" (click)="tabClicked('isSimpleInterest')"
                                          href="javascript:void(0)">Simple Interest Import</a></li>
                                          <li><a [ngClass]="{'active show' : isPrecomputed}" (click)="tabClicked('isPrecomputed')"
                                                 href="javascript:void(0)">Import Precomputed</a></li>
    </ul>
    </div>
    
<app-clmvehicle-grid [hidden]="!isVehicles" ></app-clmvehicle-grid>
<app-cost-import [hidden]="!isCost"></app-cost-import>
<app-clmperson-grid [hidden]="!isPerson"></app-clmperson-grid>
<app-clmexpenses-grid [hidden]="!isExpenses"></app-clmexpenses-grid>
<app-clminsurance-grid [hidden]="!isInsurance"></app-clminsurance-grid>
<app-clmlienholders-grid [hidden]="!isLienHolders"></app-clmlienholders-grid>
<app-clmprecomputed-grid [hidden]="!isPrecomputed"></app-clmprecomputed-grid>
<app-clmsimpleinterest-grid [hidden]="!isSimpleInterest"></app-clmsimpleinterest-grid>
</div>