import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CompanyDepartmentType } from 'src/app/_models/CompanyDepartmentType';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyDepartmentTypesService {
  baseUrl = environment.apiUrl;
  companyId: number;
constructor(private http: HttpClient) { }


getPluralByWebsiteGuid(websiteguid): Observable<CompanyDepartmentType[]> {
  return this.http.get<CompanyDepartmentType[]>(this.baseUrl + 'websitecompanydepartmenttypes/getpluralbywebsiteguid/' + websiteguid);
}
getPlural(parentGuid): Observable<CompanyDepartmentType[]> {
  return this.http.get<CompanyDepartmentType[]>(this.baseUrl + 'companydepartmenttypes/getplural/' + parentGuid);
}
get(id): Observable<CompanyDepartmentType> {
  return this.http.get<CompanyDepartmentType>(this.baseUrl + 'companydepartmenttypes/get/' + id);
}

delete(id, parentId, isdelete): Observable<CompanyDepartmentType[]> {
  console.log(this.baseUrl + 'companydepartmenttypes/delete/' + parentId + '/' + id + '/' + isdelete);
  return this.http.get<CompanyDepartmentType[]>
  (this.baseUrl + 'companydepartmenttypes/delete/' + id + '/' + parentId + '/' + isdelete);
}

add(parentId, companyDepartmentType: CompanyDepartmentType): Observable<CompanyDepartmentType[]> {
  return this.http.put<CompanyDepartmentType[]>(this.baseUrl + 'companydepartmenttypes/add/' + parentId, companyDepartmentType);
}

edit(parentId, paymentType: CompanyDepartmentType): Observable<CompanyDepartmentType[]> {
  return this.http.put<CompanyDepartmentType[]>(this.baseUrl + 'companydepartmenttypes/edit/' + parentId, paymentType);
}

}
