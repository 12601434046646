import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VehicleBuyersGuide } from 'src/app/_models/buyersguidemodels/VehicleBuyersGuide';

@Component({
  selector: 'app-buyersguideadd',
  templateUrl: './buyersguideadd.component.html',
  styleUrls: ['./buyersguideadd.component.scss']
})
export class BuyersguideaddComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  vehicleBuyersGuide = {} as VehicleBuyersGuide;
  @Output() emittedValues = new EventEmitter();
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  close() {
    this.bsModalRef.hide();
  }
  save() {
    console.log(this.vehicleBuyersGuide);
    this.emittedValues.next(this.vehicleBuyersGuide);
    this.bsModalRef.hide();
  }
}
