import { Component, OnInit, ViewChild, HostListener, Input } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { Website } from 'src/app/_models/website';
import { WebsiteCompany } from 'src/app/_models/company';
import { NgForm } from '@angular/forms';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/advertising/website.service';
import { CompaniesWebsiteService } from 'src/app/_services/advertising/companies.service';
import { WebsiteCompanyToUpdateDto } from 'src/app/_models/DTOS/websitecompanytoupdate';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.css']
})
export class CompanyInfoComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() website: Website;
company: WebsiteCompany;
companyToUpdate: WebsiteCompanyToUpdateDto;
userAssignedToCompany: UserAssignedToCompany;
companyGuid: string;
@HostListener('window:beforeunload', ['$event'])
unloadNotification($event: any) {
  if (this.editForm.dirty) {
    $event.returnValue = true;
  }
}
  constructor(
    private alertify: ToastrService,
    private route: ActivatedRoute,
    private companyService: CompaniesWebsiteService) { }

  ngOnInit() {
    this.company = this.website?.company;
  }

  onSubmit(e) {
   // console.log(JSON.parse(this.editForm.value) );
    this.companyToUpdate = this.editForm.value;
    console.log(this.company.id);
    this.companyService.editCompany(this.company).subscribe(
      (data) => {
        this.company = data;
      },
      (error) => {
        this.alertify.error(error);
      },
      () => {
        this.alertify.success('Changes saved');
        this.editForm.form.markAsPristine();
      }
    );
  }


}
