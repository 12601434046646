import { AmoLoanType } from './amoLoanType';
import { AmoInstallment } from './AmoInstallment';
import { AmoAmortization } from './AmoAmortization';
import { AmoPayment } from './amopayments';

export class AmoLoan {
    id: number;
    loanStartDate: Date;
    firstDueDate: Date;
    finalDueDate: Date;
    nextDueDate: Date;
    addOnRate: number;
    termPaid: number;
    payment: number;
    term: number;
    apr: number;
    amountFinanced: number;
    nonAmortized: number;
    amortized: number;
    paymentNonAmortized: number;
    paymentNonAmortizedFinalPayment: number;
    paymentTotalFinalPayment: number;
    interest: number;
    amoLoanMethodId: number;
    amoLoanMethods: AmoLoanType;
    amoInstallmentId: number;
    amoInstallments: AmoInstallment;
    decliningBalance: number;
    isCalculateTerm: boolean;
    amoAmortizations: AmoAmortization[];
    amoPayments: AmoPayment[];
    principleBalance: number;
    totalSumPaid: number;
    totalPrinciplePaid: number;
    totalLoanPaid: number;
    totalInterestPaid: number;
    totalLateFeePaid: number;
    totalMiscPaid: number;
    lastPaidDate: Date;
    currentDue: number;
    contractRate: number;
    totalDeferredDownPaid: number;
    payoffToday: number;
    dsl: number;
    interestSinceLastPaid: number;
    totalNonAmortizedPaid: number;
    totalNonPaidInterestPastDue: number;
    scheduledDueDate: Date;
}
