import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CompanyPhotoService {
  baseUrl = environment.apiUrl;
constructor(private http: HttpClient) { }


deletePhoto(companyid: number, id: number, departmentid: number) {
  console.log(this.baseUrl + 'companyphoto/' + id + '/deletevehiclephotos/' + companyid);
  return this.http.get(this.baseUrl + 'companyphoto/' + id + '/deletevehiclephotos/' + companyid + '/' + departmentid );
}

}
