<div style="padding-top: 20px;" class="container-fluid">
  <div class="container-fluid customer-tabs-sec">
    <ul class="nav nav-tabs">
      <li><a [ngClass]="{'active show' : isCompanyInfo}" (click)="tabClicked('isCompanyInfo')"
             href="javascript:void(0)">Company Information</a></li>
      <li><a [ngClass]="{'active show' : isSubscriptions}" (click)="tabClicked('isSubscriptions')"
             href="javascript:void(0)">Subscriptions</a></li>
      <li><a [ngClass]="{'active show' : isSubscriptionsHistory}" (click)="tabClicked('isSubscriptionsHistory')"
             href="javascript:void(0)">Subscriptions History</a></li>
             <li><a [ngClass]="{'active show' : isImportedVehicles}" (click)="tabClicked('isImportedVehicles')"
              href="javascript:void(0)">Import Dms</a></li>
    </ul>
  </div>
 <!-- <app-company-import></app-company-import> -->
  <app-company-information [hidden]="!isCompanyInfo" [company]="company"></app-company-information>
  <app-subscriptions [hidden]="!isSubscriptions" [isSubscriptionsHistory]="false"></app-subscriptions>
  <app-subscriptions [hidden]="!isSubscriptionsHistory" [isSubscriptionsHistory]="true"></app-subscriptions>
  <app-payme-individual [hidden]="!isPayment"></app-payme-individual>
  <app-company-import [hidden]="!isImportedVehicles"></app-company-import>
</div>
