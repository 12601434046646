import { AfterViewInit, Component, DoCheck, HostListener, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { elementAt } from 'rxjs/operators';
import { AddcashdowntypeModalComponent } from 'src/app/modals/addcashdowntype-modal/addcashdowntype-modal.component';
import { AddtaxdefaultsModalComponent } from 'src/app/modals/addtaxdefaults-modal/addtaxdefaults-modal.component';
import { EditcashdowntypeModalComponent } from 'src/app/modals/editcashdowntype-modal/editcashdowntype-modal.component';
import { EdittaxdefaultsModalComponent } from 'src/app/modals/edittaxdefaults-modal/edittaxdefaults-modal.component';
import { GapModalComponent } from 'src/app/modals/gap-modal/gap-modal.component';
import { LabelDoubleClickModalComponent } from 'src/app/modals/LabelDoubleClickModal/LabelDoubleClickModal.component';
import { VendorModalComponent } from 'src/app/modals/vendor-modal/vendor-modal.component';
import { VendoraddModalComponent } from 'src/app/modals/vendoradd-modal/vendoradd-modal.component';
import { AmortisationClass } from 'src/app/_classes/dealinfoclasses/amortizatincl';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';
import { InstallmentChangedClass } from 'src/app/_classes/dealinfoclasses/installmentChange';
import { LoanCalculations } from 'src/app/_classes/dealinfoclasses/loancalc';
import { PdfMergePdfs } from 'src/app/_classes/pdffielddatats/merge-pdfdocuments';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { AmoInstallment } from 'src/app/_models/AmoInstallment';
import { AmoLoan } from 'src/app/_models/amoloans';
import { AmoLoanType } from 'src/app/_models/amoLoanType';
import { CalculateTermDto } from 'src/app/_models/DTOS/CalculateTermDto';
import { FormName } from 'src/app/_models/formName';
import { Label } from 'src/app/_models/label';
import { PaymentType } from 'src/app/_models/PaymentType';
import { PdfFormsPack } from 'src/app/_models/PdfFormPack';
import { PdfFormPackage } from 'src/app/_models/pdfformpackage';
import { Sale } from 'src/app/_models/sale';
import { SaleDefault } from 'src/app/_models/SaleDefault';
import { SaleDefaultTemplate } from 'src/app/_models/SaleDefaultTemplate';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Vendor } from 'src/app/_models/vendor';
import { AmoLoanService } from 'src/app/_services/amoloanservices/amoloan.service';
import { AuthService } from 'src/app/_services/auth.service';
import { FormNameLabelsService } from 'src/app/_services/formLabelservices/form-name-labels.service';
import { ItemService } from 'src/app/_services/itemservices/item.service';
import { ListService } from 'src/app/_services/listServices/list.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';
import { VendorService } from 'src/app/_services/vendorservices/vendor.service';
import { SaleamortisationModalComponent } from '../sale-amortization/saleamortisation-modal/saleamortisation-modal.component';
import printJS from 'print-js';
import { PrintPopupComponent } from '../print-popup/print-popup.component';
import { PdfPackageService } from 'src/app/_services/PdfSErvices/pdf-package.service';
import { Router } from '@angular/router';
import { PdfRenderService } from 'src/app/_services/PdfSErvices/pdf-render.service';
import { PdfFormPackageFullDto } from 'src/app/_models/DTOS/PdfFormPackageFullDto';
import { EMLINK } from 'constants';
import { Console } from 'console';
import { QbSaleService } from 'src/app/_services/quickbooksservices/qb-salesservice';
import { QuickBooksService } from 'src/app/_services/quickbooksservices/quickbooksservice';
import { QbPersonService } from 'src/app/_services/quickbooksservices/qb-personservice';

@Component({
  selector: 'app-sale-new',
  templateUrl: './sale-new.component.html',
  styleUrls: ['./sale-new.component.css'],
})
export class SaleNewComponent implements OnInit, OnChanges, AfterViewInit {
  amortization = [] as AmoAmortization[];
  @Input() sale: Sale;

  @ViewChild('dealInfoForm') dealInfoForm: NgForm;
  // labels
  @Input() formName: FormName;
  labels = [] as Label[];
  label: Label;
  labelToDisplay: any;

  labelPredelivery: any;
  labelTag: any;
  labelRegistration: any;
  labelTitle: any;
  labelExtra2: any;
  labelExtra1: any;
  labelGap: any;
  isprintDisabled= true;
  // modals
  bsModalRef: any | null;
  bsModalRef2: any;
  bsModalRef1: any;
  // Drop Down Changed Item
  selectedTaxItem: any;
  selectedDownItem: any;
  selectedLoanMethodsItem: any;
  selectedInstallent: any;
  selectedLienHolder: Vendor;
  // payment calculation items
  isAddOn = null as any;
  isApr = null as any;
  isTermEntered = null as any;
  isPaymentEntered = null as any;
  isAprEntered = null as any;
  isMathDone = false;
  // Arrays and selected item for drop downs and sale default template for new sale tax default
  paymentTypes = [] as PaymentType[];
  loanTypes = [] as AmoLoanType[];
  amoLoanType: AmoLoanType;
  saleDefaults: SaleDefault[];
  installments = [] as AmoInstallment[];
  amoInstallment: AmoInstallment;
  insuranceCompanies = [] as Vendor[];
  lienHolders = [] as Vendor[];
  warrantyCompanies = [] as Vendor[];
  saleDefaultTemplate: SaleDefaultTemplate;
  calculateTermDto = {} as CalculateTermDto;
  salePackages = [] as PdfFormPackage[];
  selectedPdfFormPack = {} as PdfFormPackage;
  pdfFormPack = [] as PdfFormsPack[];
  base64: any;
  isWithData = true;
  termValueFocusIn: number;
  aprValueFocusIn: number;
  paymentValueFocusIn: number;
  isPrintButtonPackage = false;
  buttonToPrint = '' as string;
  list = [];
  urLList = [];
  realmId: any;
  isEmptyForm = false;
  // format class and calculations
  formatDeal: DealInforFormat;

  // any user assigned to company goes here
  selectedCompany1: UserAssignedToCompany;
  // @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
  //   if (this.dealInfoForm.dirty) {
  //     $event.returnValue = true;
  //   }
  // }
  constructor(
    private calcLoans: AmortisationClass,
    private pdfService: PdfService,
    private installmentChange: InstallmentChangedClass,
    private modalService: BsModalService,
    private authService: AuthService,
    private format: DealInforFormat,
    private alertify: ToastrService,
    private formNameService: FormNameLabelsService,
    private vendorService: VendorService,
    private listService: ListService,
    private saleSerivce: SaleService,
    private toastr: ToastrService,
    private amoLoanService: AmoLoanService,
    private pdfMergePdfs: PdfMergePdfs,
    private loanCalcs: LoanCalculations,
    private router: Router,
    private pdfPackageService: PdfPackageService,
    private pdfRenderService: PdfRenderService,
    private qbSaleService: QbSaleService,
    private qbService: QuickBooksService,
    private qbPersonService: QbPersonService,
  ) {}
  async ngAfterViewInit() {
    this.sale = await this.format.formatDealInforNumbers(this.sale);
  }
  createQbInvoice() {
    this.qbService.getRealmId().subscribe(data => {
      this.realmId = data;
      if (data) {
        this.qbPersonService.createCustomer(this.selectedCompany1.parentGuid, this.sale.buyer.id, data).subscribe(customerResult => {
          console.log(customerResult);
          if (customerResult == null) {
            this.alertify.error('Not Connected to Quick Books.');
          } else {
            this.alertify.success('Quick books updated.');


            this.qbSaleService.createSale(this.sale.id, this.realmId).subscribe(data2 => {
              console.log(data2);
            }, error => { this.alertify.error('Error Saving Sale'); }, () =>
            {
              this.alertify.success('Sale Posted');
            });
          }
        });


          }
        //  this.loadPeople();
        }, err => {}, () => {
        });
  }
  pdfPackageSelected(e: PdfFormPackage) {
  //  console.log(e);
   // console.log(this.salePackages);
    this.selectedPdfFormPack = e;
    this.printPackage();
  }
  printPackage2(package1: PdfFormPackage) {
    this.pdfFormPack = package1.pdfFormsPacks;
    this.selectedPdfFormPack = package1;
    this.urLList = [];
    console.log(package1.pdfFormsPacks.length);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < package1.pdfFormsPacks.length; ++i) {
      // if (i !< 0) {
        this.urLList.push({url: package1.pdfFormsPacks[i].pdfForm.pdfFormUrl});
    //  }
    }
    this.pdfMergePdfs.copyPages(this.sale, package1.pdfFormsPacks[0].pdfForm.pdfFormUrl, this.urLList, this.isWithData, this.isEmptyForm)
    .then(data => { this.urLList = [];  this.alertify.success('Pdfs Populated.'); });
    this.list = [];
  }
  printPackage() {
   
      const initialState = {
        sale: this.sale,
        salePackages: this.salePackages,
      };
      this.bsModalRef = this.modalService.show(PrintPopupComponent, {
        initialState,
      });
    
  }
  async ngOnChanges() {}
  getLabels() {
    this.authService.currentFormNames.subscribe((data) => {
      data.forEach((element) => {
        if (element.value === 'DealInfo') {
          this.labels = element.labels;
          console.log(element.labels);
          this.decipherLabels(element.labels);
        }
      });
    });
  }
  async ngOnInit() {
    this.getLabels();
    if (
      this.sale !== undefined ||
      (this.sale !== null && this.vendorService.exportDropDowns !== undefined)
    ) {
      this.authService.company.subscribe((data) => {
        this.selectedCompany1 = data;
      });
      this.sale = await this.format.formatDealInforNumbers(this.sale);
      // this.getAmortisation(this.sale.amoLoan.id);
      this.termValueFocusIn = this.sale.term;
      this.isTermEntered = this.sale.isTermEntered;
      this.isPaymentEntered = this.sale.isPayment;
      this.aprValueFocusIn = this.sale.apr;
      this.listService.currentSaleDefaultTemplate.subscribe((data) => {
        this.saleDefaultTemplate = data;
      });
      this.listService.currentWarrantyCompanies.subscribe((data) => {
        this.warrantyCompanies = data;
      });
      this.listService.currentinsuranceCompanies.subscribe((data) => {
        this.insuranceCompanies = data;
      });
      this.listService.currentlienholders.subscribe((data) => {
        this.lienHolders = data;
      });
      this.listService.currentAmoInstallments.subscribe((data) => {
        this.installments = data;
       // this.selectedInstallent = this.sale.amoInstallments;
      });
      this.listService.currentAmoLoanTypes.subscribe((data) => {
        this.loanTypes = data;
      });
      this.listService.currentTaxDefaults.subscribe((data) => {
        this.saleDefaults = data;
      });
      this.listService.currentPaymentTypes.subscribe((data) => {
        this.paymentTypes = data;
      });
      this.listService.currentpdfFormPackages.subscribe((data) => {
        console.log(data);
        this.salePackages = data;
       // this.selectedPdfFormPack = data[0];
        this.pdfPackageService.changeSalePackages(data);
      });
      this.isMathDone = true;
    }
    this.pdfService.getPdfFieldNameTypes().subscribe(data => {
      this.pdfService.pdfFieldNameTypesCached = data;
   //   this.pdfService.setPdfFieldNameType(data);
      console.log(data);
    });
  }

  addLabel(e) {
    this.formNameService
      .addFormLabel(this.selectedCompany1.parentGuid, e, this.formName.id)
      .subscribe(
        (data) => {
          this.formName = data;
          this.labels = data.labels;
        },
        (error) => {
          this.alertify.error(error);
        },
        () => {
          this.decipherLabels(this.labels);
        }
      );
  }
  decipherLabels(e) {
    if (this.labels.length > 0) {
      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Predelivery'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Predelivery');
      }
      this.labelPredelivery = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Title');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Title');
      }
      this.labelTitle = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'TagSale'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Tag');
      }
      this.labelTag = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Registration'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Registration');
      }
      this.labelRegistration = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Extra 1'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Extra 1');
      }
      this.labelExtra1 = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Extra 2'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Extra 2');
      }
      this.labelExtra2 = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Gap');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Gap');
      }
      this.labelGap = this.labelToDisplay[0].value;
    }
  }
  toggleValue2(e) {
    const initialState = {
      labelName: e,
    };
    this.bsModalRef1 = this.modalService.show(LabelDoubleClickModalComponent, {
      initialState,
    });
    this.bsModalRef1.content.emittedValues.subscribe((values) => {
      this.formNameService
        .editFormLabelByFieldName(
          this.selectedCompany1.parentGuid,
          e,
          values,
          'DealInfo'
        )
        .subscribe(
          (data) => {
            this.formName = data;
            this.formName.labels = data.labels;
            console.log(data);
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {
            this.formNameService
              .getFormNameList(this.selectedCompany1.parentGuid)
              .subscribe(
                (data) => {
                  this.authService.changeFormLabels(data);
                },
                (error) => {
                  this.alertify.error(error);
                },
                () => {
                  this.alertify.success('Success!');
                  this.getLabels();
                }
              );
          }
        );
    });
  }
  async testTaxSelected(e) {
  }
  search(nameKey, myArray) {
    // tslint:disable-next-line:prefer-for-of
    // tslint:disable-next-line:no-var-keyword
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].name === nameKey) {
        return myArray[i];
      }
    }
  }
  async taxSelected(selectedItem) {
    // if none selected set all rates to 0
    if (selectedItem < 0) {
      this.toastr.success('Tax Removed');
      this.sale.saleDefaultsId = null;
      this.sale.taxRate1 = 0;
      this.sale.taxRate2 = 0;
      this.sale.taxRate3 = 0;
      this.sale.taxRate4 = 0;
      this.sale.nonAmortized = 0;
      this.sale.amortized = this.sale.amountFinanced;
      this.sale.saleDefaults = null;
      this.sale.saleDefaultsId = null;
      this.sale = await this.format.formatDealInforNumbers(this.sale);
    }
    if (selectedItem > -1 && selectedItem < 1) {
      this.toastr.success('Lets add a New Set of Tax Defaults!');
      this.sale.saleDefaultsId = null;
      this.popupTaxDefaultsAdd();
    }
    if (selectedItem > 0) {
      this.toastr.success('Tax Changed!');
      this.sale.saleDefaultsId = selectedItem;
      this.sale.saleDefaults = this.saleDefaults.find(
        (x) => x.id === selectedItem
      );
    }

    // transfer rate from default to sale. From tehre sale does the math not defaults.
    if (selectedItem > 0) {
      this.sale.taxRate1 = this.sale.saleDefaults.taxRate1;
      this.sale.taxRate2 = this.sale.saleDefaults.taxRate2;
      this.sale.taxRate3 = this.sale.saleDefaults.taxRate3;
      this.sale.taxRate4 = this.sale.saleDefaults.taxRate4;
    } else {
      this.sale.taxRate1 = 0;
      this.sale.taxRate2 = 0;
      this.sale.taxRate3 = 0;
      this.sale.taxRate4 = 0;
    }

    await this.format.formatDealInforNumbers(this.sale).then(async (data) => {
      this.sale = data;
      await this.calculatePayments();
    });
  }
  lienHolderSelected(selectedItem) {
    if (selectedItem < 0) {
      this.toastr.success('Lienholder Removed');
      this.sale.lienHolderId = null;
    }
    if (selectedItem > -1 && selectedItem < 1) {
      this.toastr.success('Lets add a lienholder!');
      this.sale.lienHolderId = null;
      this.popupLienHolderAdd();
    }
    if (selectedItem > 0) {
      this.toastr.success('Lienholder Changed!');
      this.sale.lienHolderId = selectedItem;
    }
  }
  warrantyComanySelected(selectedItem) {
    if (selectedItem < 0) {
      this.toastr.success('Warranty Company Removed');
      this.sale.warrantyCompanyId = null;
    }
    if (selectedItem > -1 && selectedItem < 1) {
      this.toastr.success('Lets add a Warranty Company!');
      this.sale.warrantyCompanyId = null;
      this.popupWarrantyAdd();
    }
    if (selectedItem > 0) {
      this.toastr.success('Warranty Company Changed!');
      this.sale.warrantyCompanyId = selectedItem;
    }
  }
  paymentTypeSelected(selectedItem) {
    if (selectedItem < 0) {
      this.toastr.success('Cash Down Type Removed');
      this.sale.downPaymentMethodsId = null;
    }
    if (selectedItem > -1 && selectedItem < 1) {
      this.toastr.success('Lets add a Cash Down Type!');
      this.sale.downPaymentMethodsId = null;
      this.popupAddPaymentType();
    }
    if (selectedItem > 0) {
      this.toastr.success('Cash Down Type Changed!');
      this.sale.downPaymentMethodsId = selectedItem;
    }
    // this.sale.downPaymentMethods = selectedItem;
  }

  createLoan() {
    const amoLoan = new AmoLoan();
    amoLoan.isCalculateTerm = this.isTermEntered;
    amoLoan.loanStartDate = this.sale.soldDate;
    amoLoan.nonAmortized = this.sale.nonAmortized;
    amoLoan.payment = this.sale.payment;
    amoLoan.amountFinanced = this.sale.amountFinanced;
  }

  amoLoanMethodSelected(selectedItem) {
    console.log(selectedItem);
    this.amoLoanType = this.loanTypes.find(
      (item: AmoLoanType) => item.id === selectedItem
    );
    this.sale.amoLoanTypeId = this.amoLoanType.id;
    this.sale.amoLoanTypes.value = this.amoLoanType.value;
    console.log(this.sale.amoLoanTypes.value);
  }
  async calculatePayments() {
    console.log(this.isTermEntered);
    console.log(this.isPaymentEntered);
  //  console.log('calc payment reached');
    this.calculateTermDto.amortized = this.sale.amortized;
    this.calculateTermDto.amountFinanced = this.sale.amountFinanced;
    this.calculateTermDto.apr = this.sale.apr;
    this.calculateTermDto.contractRate = this.sale.dapr;
    this.calculateTermDto.finalDueDate = this.sale.finalDueDate;
    this.calculateTermDto.firstDueDate = this.sale.firstDueDate;
    this.calculateTermDto.installment = this.sale.amoInstallments.value;
    this.calculateTermDto.loanStartDate = this.sale.soldDate;
    this.calculateTermDto.nonAmortized = this.sale.nonAmortized;
    this.calculateTermDto.term = this.sale.term;
    this.amoLoanService.getDateMathForPaymentByTerm(this.calculateTermDto).subscribe(
      async (data) => {
        console.log(data);
        if (data.payment >> 0) { this.sale.payment = data.payment; }
      //  this.sale.payment = data.payment;
        console.log(this.sale.payment);
        this.calculateTermDto.cycle = data.cycle;
        this.calculateTermDto.daysOverCycle = data.daysOverCycle;
        this.calculateTermDto.cycleDays = data.cycleDays;
      },
      (error) => {
        this.alertify.error(error);
      },
      async () => {
        this.alertify.success('Success');
        if (
          (this.sale.isTermEntered || this.isTermEntered ) &&
          this.sale.term >> 0 &&
          this.sale.amortized >> 0 &&
          !this.isPaymentEntered
        ) {
          console.log('isTermEntered: ' + this.sale.isTermEntered);
        //  this.amoLoanService.getFederalApr()
        console.log(this.sale);
          this.loanCalcs.regZ_GetPayment(this.sale,    this.calculateTermDto.cycle,   this.calculateTermDto.daysOverCycle).then(data => {
             console.log(data);  this.sale.payment = data.payment;
              this.calcLoans.createAmortisation(this.sale).then((data1) => {
              this.amortization = data1;
              this.calcLoans.ChangeAmortization(data1);
              // this.amoLoanService
              //   .saveAmortisation(

              //     this.sale.id,
              //     this.selectedCompany1.parentGuid,
              //     data1,
              //     this.sale.amoLoans.id
              //   )
              //   .subscribe(
              //     (data2) => {},
              //     (error) => {
              //       this.alertify.error(error);
              //     }
              //   );
            });
            }, (error) => {
              this.alertify.error(error);
            });
        }
        if ((this.sale.isPayment || this.isPaymentEntered) &&
        this.sale.payment >> 0 &&
        this.sale.amortized >> 0 &&
        !this.isTermEntered) {
          console.log('payment entered installment change');
          this.paymentFocusOut();
        }
      }
    );
  //  console.log(this.calculateTermDto);
  }
  async amoInstallmentSelected(selectedItem) {
    console.log(selectedItem);
    this.sale = await this.format.formatDealInforNumbers(this.sale);
    console.log('NONAMORTIZED: ' + this.sale.nonAmortized);
    console.log('AMORTIZED: ' + this.sale.amortized);
    this.amoInstallment = this.installments.find(
      (item: AmoInstallment) => item.id === selectedItem
    );
    console.log( this.amoInstallment);
    this.sale.amoInstallmentsId = this.amoInstallment.id;
    this.sale.amoInstallments = this.amoInstallment;
    this.sale.amortized = Number(this.sale.amortized);
    await this.installmentChange
      .getFirstDueDateInstallmentChanged(this.sale)
      .then((data) => {
        this.sale = data;
        this.calculatePayments();
      });
    console.log(this.sale);
  }
  async onChange(e) {
    if (this.sale !== null) {
      await this.format.formatDealInforNumbers(this.sale).then(async (data) => {
        this.sale = data;
      });
    }
  }

  async update() {
    console.log(this.sale);
    if (this.sale.saleTypes.value === 'In House Finance' || this.sale.saleTypes.value === 'Bank Loan' ) {
      console.log('payment calculated reached');
      this.calculatePayments();
    }

    // if(this.sale.docAuto === "false") {this.sale.docAuto = false; } else { this.sale.docAuto = true; }
    // this.sale.docAuto = 0;
    this.sale.passPmts = 0;
    if (this.sale !== null) {
      this.sale = await this.format.formatDealInforNumbers(this.sale);
      this.saleSerivce
        .updateSale(this.selectedCompany1.parentGuid, this.sale)
        .subscribe(
          (data) => {
            this.alertify.success('Sale Updated');
            this.alertify.success('Accounting will be updated in background.');
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {
            this.dealInfoForm.form.markAsPristine();
            if (this.sale.saleTypes.value === 'In House Finance' || this.sale.saleTypes.value === 'Bank Loan' ) {
              this.amoLoanService
              .saveAmortisation(
                this.sale.id,
                this.selectedCompany1.parentGuid,
                this.amortization,
                this.sale.amoLoans.id
              )
              .subscribe((data2) => {},
                (error) => { this.alertify.error(error); });
            }
          });
    }
  }
  // If Addon means they entered a negative Value
  // add on calculates 100 per month earned then breaks down to weekly, biweekly ...
  // conversion notes in class strucuture
  // Standard Simple Interest 365 days a year means entered Normal positive apr
  async aprChanged(e) {
    this.sale = await this.format.formatDealInforNumbers(this.sale);
   // await this.calculatePayments();
  }
  aprEntered(e) {
    // Keystroke occurred in apr field
    // two options negative is addon math positive is apr math
    this.isAddOn = false;
    this.isApr = false;

    if (this.sale.apr < 0) {
      this.isAddOn = true;
      this.isApr = false;
      this.sale.isAddon = true;
      this.sale.isApr = false;
    }
    if (this.sale.apr > 0) {
      this.isAddOn = false;
      this.isApr = true;
      this.sale.isApr = true;
      this.sale.isAddon = false;
    }
    console.log('isAddonEntered: ' + this.isAddOn);
    console.log('isAprEntered: ' + this.isApr);

    console.log('isPaymentEntered: ' + this.isPaymentEntered);
    console.log('isTermEntered: ' + this.isTermEntered);
  }
  aprFocusIn() { console.log('aprrate: ' + this.sale.apr); this.aprValueFocusIn = this.sale.apr; }
  aprFocusOut() {
    console.log('aprrate: ' + this.sale.apr);
    console.log('aprrateout: ' + this.aprValueFocusIn);
    if (this.aprValueFocusIn !== this.sale.apr ) {
    this.calculatePayments();
  }
}
  termEntered(e) {
    this.isPaymentEntered = false;
    this.isTermEntered = false;
    // if term calculate payment
    if (this.sale.term > 0) {
      this.isTermEntered = true;
      this.sale.isTermEntered = true;
      this.sale.isPayment = false;
    }
    console.log('isAddon: ' + this.isAddOn);
    console.log('isApr: ' + this.isApr);
    console.log('isTermEntered: ' + this.isTermEntered);
    console.log('isPaymentEntered: ' + this.isPaymentEntered);
  }
  termFocusIn() { this.termValueFocusIn = this.sale.term; }
  termFocusOutFunction() {
    if (this.sale.term !== this.termValueFocusIn) {
      this.calculatePayments();
    }
     }
  async termChanged(e) {
    this.sale = await this.format.formatDealInforNumbers(this.sale);
  }
  paymentFocusIn() {
    this.paymentValueFocusIn = this.sale.payment;
  }
  paymentFocusOut() {
    if (this.paymentValueFocusIn !== this.sale.payment) {
    //  this.calcLoans.calculateTerm(this.sale, this)
      this.calculateTermDto.amortized = this.sale.amortized;
      this.calculateTermDto.amountFinanced = this.sale.amountFinanced;
      this.calculateTermDto.apr = this.sale.apr;
      this.calculateTermDto.contractRate = this.sale.dapr;
      this.calculateTermDto.finalDueDate = this.sale.finalDueDate;
      this.calculateTermDto.firstDueDate = this.sale.firstDueDate;
      this.calculateTermDto.installment = this.sale.amoInstallments.value;
      this.calculateTermDto.loanStartDate = this.sale.soldDate;
      this.calculateTermDto.nonAmortized = this.sale.nonAmortized;
      this.calculateTermDto.term = this.sale.term;
      this.amoLoanService.getDateMathForPaymentByTerm(this.calculateTermDto).subscribe(
        async (data) => {
          console.log(data);
       //   this.sale.payment = data.payment;
          this.calculateTermDto.cycle = data.cycle;
          this.calculateTermDto.daysOverCycle = data.daysOverCycle;
          this.calculateTermDto.cycleDays = data.cycleDays;
        },
        (error) => {
          this.alertify.error(error);
          console.log(error);
        },
        async () => {
          this.alertify.success('Success');

         //   console.log('isTermEntered: ' + this.sale.isTermEntered);
            this.calcLoans.calculateTerm(this.sale,    this.calculateTermDto.daysOverCycle).then(data => {
                this.sale.payment = data.payment;
                this.sale.term = data.term;
                this.calcLoans.createAmortisation(this.sale).then((data1) => {
                this.amortization = data1;
                this.calcLoans.ChangeAmortization(data1);
                this.amoLoanService
                  .saveAmortisation(
                    this.sale.id,
                    this.selectedCompany1.parentGuid,
                    data1,
                    this.sale.amoLoans.id
                  )
                  .subscribe(
                    (data2) => {},
                    (error) => {
                      this.alertify.error(error);
                    }
                  );
              });
              }, (error) => {
                this.alertify.error(error);
                console.log(error);
              });
        }
      );
    }
  }
  async paymentChanged(e) {
    this.sale = await this.format.formatDealInforNumbers(this.sale);
  }
  paymentEntered(e) {
    this.isTermEntered = false;
    this.isPaymentEntered = false;
    // if payment calculate term
    if (this.sale.payment > 0) {
      this.isPaymentEntered = true;
      this.sale.isPayment = true;
      this.sale.isTermEntered = false;
    }
    // console.log('isAddon: ' + this.isAddOn);
    // console.log('isApr: ' + this.isApr);
    // console.log('isTermEntered: ' + this.isTermEntered);
    // console.log('isPaymentEntered: ' + this.isPaymentEntered);
    // console.log('isAprentered: ' + this.isAprEntered);
  }

  amortisationModalClick() {
    const config = {
      initialState: {
        amoAmortization: this.amortization,
      },
    };
    this.bsModalRef = this.modalService.show(
      SaleamortisationModalComponent,
      config
    );
    // this.bsModalRef.content.emittedValues.subscribe(values => {
    //   this.sale = values;
    // });
    // console.log(this.sale);
  }
  oppenGapeModal() {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        sale: this.sale,
      },
    };
    this.bsModalRef = this.modalService.show(GapModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values) => {
      this.sale = values;
    });
  }
  popupWarrantyAdd() {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        isWarrantyCompany: true,
        sale: this.sale,
      },
    };
    this.bsModalRef = this.modalService.show(VendoraddModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values) => {

    });
  }
  popupWarrantyEdit() {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        isWarrantyCompany: true,
        sale: this.sale,
      },
    };
    this.bsModalRef = this.modalService.show(VendorModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values) => {

    });
  }
  popupLienHolderAdd() {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        isLienHolder: true,
        sale: this.sale,
      },
    };
    this.bsModalRef = this.modalService.show(VendoraddModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values) => {
      console.log(values);
    });
  }
  popupLienHolderEdit() {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        isLienHolder: true,
        sale: this.sale,
      },
    };
    this.bsModalRef = this.modalService.show(VendorModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values) => {
      console.log(values);
    });
  }
  popupTaxDefaultsEdit() {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        sale: this.sale,
        saleDefaultTemplate: this.sale.saleDefaults,
      },
    };
    this.bsModalRef = this.modalService.show(
      EdittaxdefaultsModalComponent,
      config
    );
    this.bsModalRef.content.emittedValues.subscribe((values) => {
      console.log(values);
    });
  }
  popupTaxDefaultsAdd() {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        sale: this.sale,
        id: 1,
        saleDefaultTemplate: this.saleDefaultTemplate,
      },
    };
    this.bsModalRef = this.modalService.show(
      AddtaxdefaultsModalComponent,
      config
    );
    this.bsModalRef.content.emittedValues.subscribe((values) => {
      console.log(values);
    });
  }

  popupAddPaymentType() {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        sale: this.sale,
      },
    };
    this.bsModalRef = this.modalService.show(
      AddcashdowntypeModalComponent,
      config
    );
    this.bsModalRef.content.emittedValues.subscribe((values) => {
      console.log(values);
    });
  }
  popupEditPaymentType() {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
        sale: this.sale,
      },
    };
    this.bsModalRef = this.modalService.show(
      EditcashdowntypeModalComponent,
      config
    );
    this.bsModalRef.content.emittedValues.subscribe((values) => {
      console.log(values);
    });
  }
tabelViewClick(){
  this.router.navigate(['saledashboard/saledashboard/' + this.selectedCompany1.parentGuid]);
}

printDealInfo() {
  const pdfPackage = {} as PdfFormPackageFullDto;
  this.salePackages.forEach(element => {
    if (element.packageName === 'DealInfo') {
      console.log(element.packageName);
      pdfPackage.pdfFormsPacks = element.pdfFormsPacks;
      pdfPackage.parentGuid = element.parentGuid;
      pdfPackage.packageName = element.packageName;
      pdfPackage.isSale = element.isSale;
      pdfPackage.isReport = element.isReport;
      pdfPackage.isInventory = element.isInventory;
      pdfPackage.id = element.id;
      pdfPackage.defaultPack = element.defaultPack;
      pdfPackage.PdfFormPackagePrintLocationsId = element.pdfFormPackagePrintLocationId;
      pdfPackage.PdfFormPackagePrintLocations = element.pdfFormPackagePrintLocations;
     /// pdfPackages.push(pdfPackage);
    }
  });

 // this.pdfRenderService.setBase64OfAPdfToCacheToBrPrintedByButtonsInProgram(pdfPackage, this.sale, true, false);
    this.PrintOpen(pdfPackage);
   // pdfPackages.push(pdfPackage);
  }
printSalePackage() {
  const pdfPackage = {} as PdfFormPackageFullDto;
  this.salePackages.forEach(element => {
    console.log(this.salePackages);
    if (element.packageName === 'Sale Package') {
      console.log(element.packageName);
      pdfPackage.pdfFormsPacks = element.pdfFormsPacks;
      pdfPackage.parentGuid = element.parentGuid;
      pdfPackage.packageName = element.packageName;
      pdfPackage.isSale = element.isSale;
      pdfPackage.isReport = element.isReport;
      pdfPackage.isInventory = element.isInventory;
      pdfPackage.id = element.id;
      pdfPackage.defaultPack = element.defaultPack;
      pdfPackage.PdfFormPackagePrintLocationsId = element.pdfFormPackagePrintLocationId;
      pdfPackage.PdfFormPackagePrintLocations = element.pdfFormPackagePrintLocations;
     // pdfPackages.push(pdfPackage);
  //   this.pdfRenderService.setBase64OfAPdfToCacheToBrPrintedByButtonsInProgram(pdfPackage, this.sale, true, false);
     this.PrintOpen(pdfPackage);
    }
  });
}
  PrintPdf(e) {
   this.isprintDisabled= false;
    if (e === 'DealInfo') { this.printDealInfo(); }
    if (e === 'Sale Package1') { this.printSalePackage(); }
  }

  PrintOpen(e?: PdfFormPackage) {
    let salePackageToPush = [] as PdfFormPackageFullDto[];
    const pdfPackage = {} as PdfFormPackageFullDto;
        pdfPackage.pdfFormsPacks = e.pdfFormsPacks;
        pdfPackage.parentGuid = e.parentGuid;
        pdfPackage.packageName = e.packageName;
        pdfPackage.isSale = e.isSale;
        pdfPackage.isReport = e.isReport;
        pdfPackage.isInventory = e.isInventory;
        pdfPackage.id = e.id;
        pdfPackage.defaultPack = e.defaultPack;
        pdfPackage.PdfFormPackagePrintLocationsId = e.pdfFormPackagePrintLocationId;
        pdfPackage.PdfFormPackagePrintLocations = e.pdfFormPackagePrintLocations;
       // pdfPackages.push(pdfPackage);

    salePackageToPush = [];
    salePackageToPush.push(pdfPackage);
    console.log(salePackageToPush);

     
     
      const initialState = {
        isPrintButtonPackage: this.isPrintButtonPackage,
        buttonToPrint: 'DealInfo',
        sale: this.sale,
        salePackages:  salePackageToPush,
      };
      this.bsModalRef = this.modalService.show(PrintPopupComponent, {
        initialState,
      });
   

      this.isprintDisabled= true;
  
   }

  // PrintPdf2() {
  //   console.log('PrintPdf2 Reached');
  //     this.pdfService.getBase64Array().subscribe(data => {
  //       this.base64 = data;
  //      });
  //      printJS({printable: this.base64, type: 'pdf', showModal: true, base64: true});
  //      this.PrintOpen();
  // }
}
