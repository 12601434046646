import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aftercompanycreatedreload',
  templateUrl: './aftercompanycreatedreload.component.html',
  styleUrls: ['./aftercompanycreatedreload.component.css']
})
export class AftercompanycreatedreloadComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
    if (!localStorage.getItem('foo')) {
      localStorage.setItem('foo', 'no reload');
      location.reload();
    } else {
      localStorage.removeItem('foo');
      this.router.navigate(['/seedcompany']);

    }
  }

}
