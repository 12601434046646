<div class="customer-form-sec" style="width: 700px !important">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="customer" class="tab-pane fade in active show">
          <div class="modal-header form-sec tab-content">
            <h4 class="modal-title pull-left">Edit Note</h4>
            <button
              type="button"
              class="close pull-right"
              aria-label="Close"
              (click)="bsModalRef.hide()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form #rolesForm="ngForm" id="rolesForm">
              <div id="misc-info" class="tab-pane fade in active show">
                <div class="row">
                  <div class="col-md-12">
                    <div class="inner-misc">
                      <div class="misc-grid">
                        <div class="row">
                          <div class="col-md-12 mise-left-grid">
                            <div class="rw" >
                              <div class="inline-blk">
                                <label>Date</label>
                                <input
                                  type="date"
                                  class="cstm-input"
                                  name="dateCreated"
                                  #createdDate = "ngModel"
                                  [ngModel]="saleNotes?.createdDate | date:'yyyy-MM-dd'"  
                                  (ngModel)="saleNotes.createdDate = $event"
                                  (ngModelChange)="saleNotes.createdDate = $event"
                                />
                              </div>
                            </div> 
                            <div class="rw">
                              <textarea class="form-control" [(ngModel)]="saleNotes.note" name="note" rows="6"></textarea>
                              </div>
                            
                            <div class="rw col-md-12">
                              <div class="inline-blk">
                                <label>Delete?</label>
                                <input
                                  type="checkbox"
                                  class="cstm-input"
                                  name="isDeleted"
                                  #isDeleted = "ngModel"
                                  [checked]="saleNotes.isDeleted"
                                  value="saleNotes.isDeleted"
                                  [ngModel]="saleNotes?.isDeleted"
                                  (ngModel)="saleNotes.isDeleted = $event"
                                  (ngModelChange)="saleNotes.isDeleted = $event"
                                />
                              </div>
                            </div>
                            <div class="rw col-md-12" >
                              <div class="inline-blk">
                                <label>Remind me? </label>
                                <input
                                  type="checkbox"
                                  class="cstm-input"
                                  name="remind"
                                  #remind = "ngModel"
                                  [checked]="saleNotes.remind"
                                  value="saleNotes.remind"
                                  [ngModel]="saleNotes?.remind"
                                  (ngModel)="saleNotes.remind = $event"
                                  (ngModelChange)="saleNotes.remind = $event"
                                />
                              </div>
							</div>
							<div class="rw col-md-12" >
								<div class="inline-blk">
								  <label>Remind Me Date</label>
								  <input
									type="date"
									class="cstm-input"
									name="remindDate"
									[ngModel]="saleNotes?.remindDate | date:'yyyy-MM-dd'"  
									(ngModel)="saleNotes.remindDate = $event"
									(ngModelChange)="saleNotes.remindDate = $event"
								  />
								</div>
							  </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              (click)="bsModalRef.hide()"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-success" (click)="updateGap()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
