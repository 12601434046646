import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ClmVehicle } from 'src/app/_models/_models/clmvehicle';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { ClmVehicleService } from 'src/app/_services/clmvehiclesservices/clmvehicles.service';
import { DollarService } from 'src/app/_services/dollarservices/dollar.service';

@Component({
    moduleId: module.id,
    selector: 'app-clmvehicle-grid',
    templateUrl: 'clmvehicle-grid.component.html',
    styleUrls: ['clmvehicle-grid.component.scss']
})
export class ClmvehicleGridComponent implements OnInit {
    isNotClicked = false;
    clmVehicle = {} as ClmVehicle;
    clmVehicles = [] as ClmVehicle[];
    selectedCompany1 = {} as UserAssignedToCompany;

    constructor(private vehicleService: ClmVehicleService,
        private authService: AuthService, private dollarService: DollarService,
        private alertify: ToastrService) { }
    
    ngOnInit(): void {
        this.authService.currentCompany.subscribe(data => { this.selectedCompany1 = data; this.getVehicles(); });

    }
    getVehicles() {
        this.vehicleService.listAllVehicles(this.selectedCompany1.parentGuid).
        subscribe((data: ClmVehicle[]) => {
          console.log(data);
          this.clmVehicles = data;
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Success');
        });
    }
    deleteItems(){
        this.vehicleService.deleteItems(this.selectedCompany1.parentGuid).subscribe(data => {}, error => {}, ()=>{
            
            this.alertify.success('Success');
        });
    }
    deleteItemsRemainingVehicles(){
        this.vehicleService.deleteItemsRemainingVehicles(this.selectedCompany1.parentGuid).subscribe(data => {}, error => {}, ()=>{
            
            this.alertify.success('Success');
        });
    }
    async  postVehicles2() {
        this.isNotClicked = true;

        //  this.vehicleService.getVehicleKodList(this.selectedCompany1.parentGuid).subscribe(async (data: any[]) =>  {
        //             this.vehicleService.getVehicleWithRepairandPost(num, this.selectedCompany1.parentGuid).subscribe(async data1 => {

        //                    }, error => {}, ()=>{
        //                        this.alertify.success('Success');
        //                    } );
   
        //       })
    }
     async postVehicles() {
        
        this.vehicleService.getVehicleKodList(this.selectedCompany1.parentGuid).subscribe(async (data: any[]) =>  {
            for (const item of data) {
                await new Promise(resolve =>
                  this.vehicleService.getVehicleWithRepairandPost(item, this.selectedCompany1.parentGuid).subscribe(async data1 => {
                         resolve(data1);
                  }, error => { resolve(1); }, ()=>{
                      this.alertify.success('Success');
                  } )  )
                //  resolve();
                  }
              } )

      }
      }


