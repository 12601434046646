import * as moment from 'moment';
import { RealProperty } from 'src/app/_models/RealProperty';

export class RealPropertyMath {
  // = default languare? undefined
  // 'en-Us' US ONLY
  fnz02 = (num) =>
    num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    });

  fnz04 = (num) =>
    num.toLocaleString('en-US', {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
      useGrouping: false,
    });
  fnz0 = (num) =>
    num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: false,
    });

  async formatPropertyNumbers(property: RealProperty): Promise<RealProperty> {
    if (property?.insurance === null || property?.insurance === undefined) {
      property.insurance = Number(this.fnz02(0));
    }
    property.insurance = this.fnz02(property.insurance);

    if (property?.apr === null || property?.apr === undefined) {
      property.apr = Number(this.fnz04(0));
    }
    property.apr = this.fnz04(property.apr);

    if (property?.costAtClose === null || property?.costAtClose === undefined) {
      property.costAtClose = Number(this.fnz02(0));
    }
    property.costAtClose = this.fnz02(property.costAtClose);

    if (property?.downPayment === null || property?.downPayment === undefined) {
      property.downPayment = Number(this.fnz02(0));
    }
    property.downPayment = this.fnz02(property.downPayment);

    if (
      property?.equityAtClose === null ||
      property?.equityAtClose === undefined
    ) {
      property.equityAtClose = Number(this.fnz02(0));
    }
    property.equityAtClose = this.fnz02(property.equityAtClose);

    if (
      property?.fairMarketValue === null ||
      property?.fairMarketValue === undefined
    ) {
      property.fairMarketValue = Number(this.fnz02(0));
    }
    property.fairMarketValue = this.fnz02(property.fairMarketValue);

    if (property?.financed === null || property?.financed === undefined) {
      property.financed = Number(this.fnz02(0));
    }
    property.financed = this.fnz02(property.financed);

    if (property?.insurance === null || property?.insurance === undefined) {
      property.insurance = Number(this.fnz02(0));
    }
    property.insurance = this.fnz02(property.insurance);

    if (property?.payment === null || property?.payment === undefined) {
      property.payment = Number(this.fnz02(0));
    }
    property.payment = this.fnz02(property.payment);

    if (property?.points === null || property?.points === undefined) {
      property.points = Number(this.fnz02(0));
    }
    property.points = this.fnz02(property.points);

    if (
      property?.projectedRehab === null ||
      property?.projectedRehab === undefined
    ) {
      property.projectedRehab = Number(this.fnz02(0));
    }
    property.projectedRehab = this.fnz02(property.projectedRehab);

    if (
      property?.purchasePrice === null ||
      property?.purchasePrice === undefined
    ) {
      property.purchasePrice = Number(this.fnz02(0));
    }
    property.purchasePrice = this.fnz02(property.purchasePrice);

    if (property?.survey === null || property?.survey === undefined) {
      property.survey = Number(this.fnz02(0));
    }
    property.survey = this.fnz02(property.survey);

    if (property?.taxes === null || property?.taxes === undefined) {
      property.taxes = Number(this.fnz02(0));
    }
    property.taxes = this.fnz02(property.taxes);

    if (property?.term === null || property?.term === undefined) {
      property.term = Number(this.fnz0(0));
    }
    property.term = this.fnz0(property.term);

    if (
      property?.closingCostMisc === null ||
      property?.closingCostMisc === undefined
    ) {
      property.closingCostMisc = Number(this.fnz02(0));
    }
    property.closingCostMisc = this.fnz02(property.closingCostMisc);

    if (property?.deposit === null || property?.deposit === undefined) {
      property.deposit = Number(this.fnz02(0));
    }
    property.deposit = this.fnz02(property.deposit);

    if (property?.term === null || property?.term === undefined) {
      property.term = Number(this.fnz0(0));
    }
    property.term = this.fnz0(property.term);

    if (
      property?.projectedMonthlyIncome === null ||
      property?.projectedMonthlyIncome === undefined
    ) {
      property.projectedMonthlyIncome = Number(this.fnz02(0));
    }
    property.projectedMonthlyIncome = this.fnz02(
      property.projectedMonthlyIncome
    );

    if (
      property?.payDownMonthly === null ||
      property?.payDownMonthly === undefined
    ) {
      property.payDownMonthly = Number(this.fnz02(0));
    }
    property.payDownMonthly = this.fnz02(property.payDownMonthly);

    if (
      property?.carryingMonthly === null ||
      property?.carryingMonthly === undefined
    ) {
      property.carryingMonthly = Number(this.fnz02(0));
    }
    property.carryingMonthly = this.fnz02(property.carryingMonthly);

    if (
      property?.projectedMonthlyMiscCost === null ||
      property?.projectedMonthlyMiscCost === undefined
    ) {
      property.projectedMonthlyMiscCost = Number(this.fnz02(0));
    }
    property.projectedMonthlyMiscCost = this.fnz02(
      property.projectedMonthlyMiscCost
    );

    if (
      property?.equityPerMonth === null ||
      property?.equityPerMonth === undefined
    ) {
      property.equityPerMonth = Number(this.fnz02(0));
    }
    property.equityPerMonth = this.fnz0(property.equityPerMonth);

    if (property?.monthlyNet === null || property?.monthlyNet === undefined) {
      property.monthlyNet = Number(this.fnz02(0));
    }
    property.monthlyNet = this.fnz02(property.monthlyNet);

    if (property?.utilities === null || property?.utilities === undefined) {
        property.utilities = Number(this.fnz02(0));
      }
      property.utilities = this.fnz02(property.utilities);

      if (property?.escrows === null || property?.escrows === undefined) {
        property.escrows = Number(this.fnz02(0));
      }
      property.escrows = this.fnz02(property.escrows);

      if (property?.squareFeet === null || property?.squareFeet === undefined) {
        property.squareFeet = Number(this.fnz0(0));
      }
      property.squareFeet = this.fnz0(property.squareFeet);

      if (property?.owedPerSquare === null || property?.owedPerSquare === undefined) {
        property.owedPerSquare = Number(this.fnz02(0));
      }
      property.owedPerSquare = this.fnz02(property.owedPerSquare);

    if (property.isBnb) { property.payDownMonthly = Number(property.projectedMonthlyIncome) * .3; } else{
      property.payDownMonthly = Number(property.projectedMonthlyIncome) * 0;
    }
    if (property.financed > 0 && property.squareFeet > 0 ) {
      property.owedPerSquare = this.fnz02(Number(property.financed) / Number(property.squareFeet));
    }
    
    property.payDownMonthly = this.fnz02(property.payDownMonthly);

    property.monthlyNet =
      Number(property.projectedMonthlyIncome) -
      Number(property.projectedMonthlyMiscCost) -
      Number(property.payDownMonthly) -
      Number(property.utilities) -
      Number(property.escrows) -
      Number(property.carryingMonthly);
    property.monthlyNet = this.fnz0(property.monthlyNet);

    property.costAtClose =
      Number(property?.insurance) +
      Number(property?.survey) +
      Number(property?.points) +
      Number(property?.closingCostMisc) +
      Number(property?.downPayment) -
      Number(property?.deposit);

    property.equityAtClose =
      Number(property?.fairMarketValue) -
      Number(property?.projectedRehab) -
      Number(property?.financed);

    property.costAtClose = this.fnz02(property.costAtClose);
    property.equityAtClose = this.fnz02(property.equityAtClose);

    return property;
  }
}
