import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { CompanyProfileRoutingModule } from './companyprofile-routing';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { CompanyInformationComponent } from './company-information/company-information.component';
import { PaymeIndividualComponent } from './payme-individual/payme-individual.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { PaymentsGridComponent } from './payments-grid/payments-grid.component';
import { CompanyImportComponent } from './company-import/company-import.component';
import { ClmvehicleGridComponent } from './company-import/clmvehicle-grid/clmvehicle-grid.component';
import { CostImportComponent } from './company-import/cost-import/cost-import.component';
import { ClmpersonGridComponent } from './company-import/clmperson-grid/clmperson-grid.component';
import { ClmexpensesGridComponent } from './company-import/clmexpenses-grid/clmexpenses-grid.component';
import { ClminsuranceGridComponent } from './company-import/clminsurance-grid/clminsurance-grid.component';
import { ClmlienholdersGridComponent } from './company-import/clmlienholders-grid/clmlienholders-grid.component';
import { ClmprecomputedGridComponent } from './company-import/clmprecomputed-grid/clmprecomputed-grid.component';
import { ClmsimpleinterestGridComponent } from './company-import/clmsimpleinterest-grid/clmsimpleinterest-grid.component';


@NgModule({
    declarations: [
        CompanyProfileComponent,
        CompanyInformationComponent,
        PaymeIndividualComponent,
        SubscriptionsComponent,
        PaymentsGridComponent,
        CompanyImportComponent,
        ClmvehicleGridComponent,
        CostImportComponent,
        ClmpersonGridComponent,
        ClmexpensesGridComponent,
        ClminsuranceGridComponent,
        ClmlienholdersGridComponent,
        ClmprecomputedGridComponent,
        ClmsimpleinterestGridComponent,

    ],
    exports: [
        SubscriptionsComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        CompanyProfileRoutingModule,
        AgChartsAngularModule,
        AgGridModule.withComponents([]),
    ]
})
export class CompanyProfileModule { }
