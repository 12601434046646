<div class="customer-form-sec">
  <div class="container-fluid">
    <br>
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->
                <div class="row">
                  <div class="col-md-10 cal-grid">
                    <ul class="calander-grid">
                      <li><a href="javascript:void(0)">Jan</a></li>
                      <li><a href="javascript:void(0)">Feb</a></li>
                      <li><a href="javascript:void(0)">Mar</a></li>
                      <li><a href="javascript:void(0)" class="active">Apr</a></li>
                      <li><a href="javascript:void(0)">May</a></li>
                      <li><a href="javascript:void(0)">Jun</a></li>
                      <li><a href="javascript:void(0)">Jul</a></li>
                      <li><a href="javascript:void(0)">Aug</a></li>
                      <li><a href="javascript:void(0)">Sep</a></li>
                      <li><a href="javascript:void(0)">Oct</a></li>
                      <li><a href="javascript:void(0)">Nov</a></li>
                      <li><a href="javascript:void(0)">Dec</a></li>
                      <li><a href="javascript:void(0)">Q1</a></li>
                      <li><a href="javascript:void(0)">Q2</a></li>
                      <li><a href="javascript:void(0)">Q3</a></li>
                      <li><a href="javascript:void(0)">Q4</a></li>
                    </ul>
                    <div class="cal-btn-sec">
                      <button class="cal-btn">2018</button>
                      <div class="arrows">
                        <span class="arrow-up"><i class="fa fa-caret-up"></i></span>
                        <span class="arrow-down"><i class="fa fa-caret-down"></i></span>
                      </div>
                    </div>
                    <div class="filter-btn">
                      <button class="filter">
                        <img src="assets/images/custom-filter.png">
                        <span>Custom Filter</span>
                      </button>
                    </div>
                    <div class="cst-input">
                      <label>Select Report</label>
                      <select>
                        <option>Profit Loss</option>
                        <option>Account Payables</option>
                        <option>General Ledger</option>
                        <option>Sales Tax</option>
                        <option>Inventoried Asset</option>
                        <option>Cogs</option>
                      </select>
                    </div>
                   
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <table border="1" borderColor="#ccc">
                      <tr>
                        <th>Id</th>
                        <th>Created</th>
                        <th>Sale ID:Item ID</th>
                        <th>Description</th>
                        <th>User</th>
                        <th>Dealer Cost</th>
                        <th>Customer Cost</th>
                        <th>Profit</th>
                        <th>Dollar Data Field</th>
                        <th>Recievable</th>
                        <th>Payments Paid</th>
                        <th>Paid Date</th>
                      </tr>
                      <tr  (dblclick)="editItem(item)" align="right" class="td-row" *ngFor="let item of dollars; let i=index">
                        <td  align="left" *ngIf="i != dollars.length - 1">{{ item.id }}</td>
                        <td align="left" *ngIf="i != dollars.length - 1">{{ item?.dateCreated  | date: "shortDate" }}</td>
                        <td align="left" *ngIf="i != dollars.length - 1">{{ item?.saleId }} : {{ item?.itemId }}</td>
                        <td align="left" *ngIf="i != dollars.length - 1">{{ item?.description }}</td>
                        <td align="left" *ngIf="i != dollars.length - 1">{{ item?.createdBy?.person?.firstName }} {{item?.createdBy?.person?.lastName}}</td>
                        <td align="right" *ngIf="i != dollars.length - 1">{{ item?.credit | number: "1.2" }}</td>
                        <td align="right" *ngIf="i != dollars.length - 1">{{ item?.customerCost | number: "1.2" }}</td>
                        <td align="right" *ngIf="i != dollars.length - 1">{{ item?.profit | number: "1.2" }}</td>
                        <td align="left" *ngIf="i != dollars.length - 1">{{ item?.dollarTypes?.value | HumanizePipes }}</td>              
                       
                        <td align="right" *ngIf="i != dollars.length - 1">{{ item?.recievableBalance | number: "1.2" }}</td>
                        <td *ngIf="i != dollars.length - 1">{{ item?.incomeRecieved }}</td>
                        <td *ngIf="i != dollars.length - 1">{{ item?.dateOfTransaction | date: "shortDate"}}</td>
                        <!-- <td [hidden] = "isPrint" *ngIf="i != chartItems.length - 1">
                          <button
                            class="btn btn-outline-success"
                            (click)="editVehicle(item)"
                          >
                            Edit
                          </button>
                        </td> -->
                      </tr>
                       <!-- footer -->
                       <tr  align="right" class="border_top" *ngFor="let item of dollars; let i=index; last as isLast">
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                       
                        <td *ngIf="isLast && i !==0">Sub Totals: </td>
                        <td *ngIf="isLast && i !==0">{{ item?.credit | number: "1.2" }}</td>
                       
                        <td *ngIf="isLast && i !==0">{{ item?.customerCost | number: "1.2" }}</td>
                        <td *ngIf="isLast && i !==0">{{ item?.profit | number: "1.2" }}</td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">{{ item?.recievableBalance | number: "1.2" }}</td>
                        <td *ngIf="isLast && i !==0">{{ item?.incomeRecieved | number: "1.2" }}</td>
                        <td *ngIf="isLast && i !==0"></td>
                        </tr>
                    </table>
                  </div><!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div><!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-2">
        <div class="inner-sidebar">
          <ul>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png">
                <span>Add New</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-2.png">
                <span>View/Edit</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-3.png">
                <span>Mark Item Paid</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-4.png">
                <span>Print Check</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn" disabled="">
                <img src="assets/images/ap-icon-5.png">
                <span>Print Batch</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-6.png">
                <span>Print Report</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-7.png">
                <span>Check</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-7.png">
                <span>Bill</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn">
                <img src="assets/images/ap-icon-7.png">
                <span>Charge</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
