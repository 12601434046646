import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddServiceComponent } from './add-service/add-service.component';
import { ServiceGridComponent } from './service-grid/service-grid.component';

// servicedashboard/serviceadd/

const routes: Routes = [

    {path: 'servicegrid', component: ServiceGridComponent,
    resolve: {}
    },
    {path: 'serviceadd/:parentGuid', component: AddServiceComponent,
    resolve: {}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ServiceShopRoutingModule {}

