<!-- <div class="modal fade" id="buyer-guide-model" role="dialog" tabindex="-1"> -->
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <img src="assets/images/buyer-title-icon.jpg">
        <h4 class="modal-title">Compose Buyers Guide</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="stock-rw">
          <label>Stock #</label>
          <input type="text" name="" value="110530" class="cstm-input">
          <button class="cstm-btn"><img src="assets/images/car-icon.jpg"></button>
        </div>
        <div class="vehicle-make-rw">
          <ul>
            <li>
              <label>Vehicle Make</label>
              <input type="text" name="" value="FORD" class="cstm-input">
            </li>
            <li>
              <label>Model</label>
              <input type="text" name="" value="E350" class="cstm-input">
            </li>
            <li>
              <label>Year</label>
              <input type="text" name="" value="1989" class="cstm-input">
            </li>
            <li>
              <label>VIN #</label>
              <input type="text" name="" value="1FDKE37M6KHB71453" class="cstm-input">
            </li>
          </ul>
        </div>
        <div class="radio-sec">
          <div class="rw">
            <input type="radio" name="cstm_rad" value="1" class="cstm_rad" id="no-warranty" checked="">
            <label for="no-warranty">As is - no warranty</label>
          </div>
          <div class="rw">
            <input type="radio" name="cstm_rad" value="2" class="cstm_rad" id="warranty">
            <label for="warranty">Warranty</label>
          </div>
        </div>
        <div class="warranty-form">
          <div class="inner">
            <h2 class="war-title">Warranty</h2>
            <div class="cstm-rw">
              <ul>
                <li>
                  <input type="radio" name="war_rad" value="1" id="war_rad1" checked="" class="war_rad">
                  <label for="war_rad1">Full</label>
                </li>
                <li>
                  <input type="radio" name="war_rad" value="1" id="war_rad2" class="war_rad">
                  <label for="war_rad2">Limited Warr.</label>
                </li>
                <li>
                  <span class="dlr-pay-title">Dealer will pay</span>
                  <input type="text" name="" class="cstm-input">
                  <span>% (labor) and </span>
                </li>
                <li>
                  <input type="text" name="" class="cstm-input">
                  <span>% (parts)</span>
                </li>
              </ul>
            </div>
            <div class="stytem-grid-sec">
              <div class="row">
                <div class="col-md-6 cstm-grid">
                  <label>System Covered:</label>
                  <input type="text" name="" class="cstm-input">
                  <input type="text" name="" class="cstm-input">
                  <input type="text" name="" class="cstm-input">
                  <input type="text" name="" class="cstm-input">
                  <input type="text" name="" class="cstm-input">
                </div>
                <div class="col-md-6 cstm-grid">
                  <label>Duration:</label>
                  <input type="text" name="" class="cstm-input">
                  <input type="text" name="" class="cstm-input">
                  <input type="text" name="" class="cstm-input">
                  <input type="text" name="" class="cstm-input">
                  <input type="text" name="" class="cstm-input">
                </div>
              </div>
            </div>
          </div>
          <div class="warranty-footer">
            <ul class="first-ul-list">
              <li>
                <input type="checkbox" name="" id="service-cnt" class="wr-cbk">
                <label for="service-cnt">Service Contract</label>
              </li>
              <li>
                <input type="checkbox" name="" id="pre-print" class="wr-cbk">
                <label for="pre-print">Pre-Printed Stock</label>
              </li>
            </ul>
            <div class="btn-sec">
              <ul>
                <li><button class="cstm-btn">Preview</button></li>
                <li><button class="cstm-btn">Print</button></li>
                <li><button class="cstm-btn">Print Back Side</button></li>
                <li class="pos-right"><button class="cstm-btn" data-dismiss="modal">Close</button></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- </div> -->
<script type="text/javascript">
  $(".warranty-form .inner :input").attr("disabled", true);
  $('#warranty').on('change', function() {
    $(".warranty-form .inner :input").removeAttr("disabled");
  });
  $('#no-warranty').on('change', function() {
    $(".warranty-form .inner :input").attr("disabled", true);
  });

</script>
