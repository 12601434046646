
  <br>  <br>  
  <div class="customer-form-sec ">
    <div class="container-fluid">
  <div class="row"> 
  
    <div class="form-sec tab-content col-md-10">
        <div id="vehicle" class="tab-pane fade in active show">
      <form  id="editForm" novalidate #editForm="ngForm" (ngSubmit)="onSubmit(editForm)">
            <div class="row vehicle-form">
              <div class="col-md-6">
                  <div class="row">
                      <!--STOCK #-->
                      <div class="col-md-12 cstm-col">
                        <div class="inline-blk"
                        [ngClass]="{
                          'has-success': stockNumber?.valid && (stockNumber?.touched || stockNumber?.dirty),
                          'has-danger': stockNumber?.invalid && (stockNumber?.touched || stockNumber?.dirty)
                        }">
                          <label   (dblclick)="toggleValue2('StockNumber')"  >{{labelStock}}</label>
                          <input class="cstm-input" value=""
                          type="text"
                          autocomplete="off"
                          [ngModel]="chartItem?.itemVehicleTransientDetails?.stockNumber"
           
                          (ngModelChange)="chartItem.itemVehicleTransientDetails.stockNumber = $event"
                          name="stockNumber"
                          #stockNumber="ngModel"
                          required>
                        </div>
                        
                        <div
                        *ngIf="stockNumber.errors && (stockNumber?.touched || stockNumber?.dirty)">
                        <p *ngIf="stockNumber?.errors?.required">Stock Is Required</p>
                      </div>
                      </div>
                      <!-- CATEGORY  [ngModel]="chartItem.vehicle?.vehicleBodyClassTypes?.nameFriendly"-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Category')" >{{labelCategory}}</label>
                        <select class="select-input" #mileageCode="ngModel" ngControl="selectedBodyType"  name="vehicleBodyClassTypes"    [(ngModel)] ="chartItem.vehicle.vehicleBodyClassTypeId"  
                        (ngModelChange)="chartItem.vehicle.vehicleBodyClassTypes.id = $event" >
                          <option *ngFor="let item of bodyTypes; index as i"   [value]="item?.id">{{item?.nameFriendly}}</option>
                    </select>  
                        <!-- <input  list="category" 
                         
                        (ngModel)="chartItem.vehicle.vehicleBodyClassTypes.nameFriendly = $event"
                        (ngModelChange)="chartItem.vehicle.vehicleBodyClassTypes.nameFriendly = $event"
                        name="vehicleBodyClassTypes"
                        #vehicleBodyClassTypes="ngModel"/>
                          <datalist  id="category"  class="select-input">
                            <option   *ngFor="let item of bodyTypes" [selected]="item.id === chartItem.vehicle.vehicleBodyClassTypeId"  value="{{item.nameFriendly}}">
                                </option>
                          </datalist> -->
                      </div>
                       <!--VIN #-->
                      <div class="col-md-12 cstm-col">
                        <div class="inline-blk"
                        [ngClass]="{
                          'has-success': vin?.valid && (vin?.touched || vin?.dirty),
                          'has-danger': vin?.invalid && (vin?.touched || vin?.dirty)
                        }">
                        <label (dblclick)="toggleValue2('VIN')" >{{labelVin}}</label>
                          <input class="cstm-input" value=""
                          type="text"
                          autocomplete="off"
                          [ngModel]="chartItem?.vehicle?.vin"
                          (ngModel)="chartItem.vehicle.vin = $event"
                          (ngModelChange)="chartItem.vehicle.vin = $event"
                          (blur) = "vinLength($event)"
                          name="vin"
                          #vin="ngModel"
                          required>
                        </div>
                        <ul class="ph-btns" *ngIf="decodeVinShow">
                          <li>
                            <a href="javascript:void(0)"   (click) = "decodeVin()">
                              <span>Decode and Add</span>
                            
                            </a>
                          </li>
                        </ul>
                        <div
                        *ngIf="vin.errors && (vin?.touched || vin?.dirty)">
                        <p *ngIf="vin?.errors?.required">VIN Is Required</p>
                      </div>
                      </div>
                       <!--Year-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Year')" >{{labelYear}}</label>
                        <input autocomplete="Year-name" list="year" name="state-choice" 
                        [ngModel]="chartItem?.vehicle?.year"
                        (ngModel)="chartItem.vehicle.year = $event"
                        (ngModelChange)="chartItem.vehicle.year = $event"
                        name="year"
                        #year="ngModel"/>
                          <datalist autocomplete="Year-name" id="year" class="select-input">
                            <option *ngFor="let item of vehicleYears" value="{{item.value}}">
                          </datalist>
                      </div>
                       <!--Make-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Make')" >{{labelMake}}</label>
                        <input autocomplete="make-name" list="make" name="state-choice" 
                        [ngModel]="chartItem?.vehicle?.make"
                        (ngModel)="chartItem.vehicle.make = $event"
                        (ngModelChange)="chartItem.vehicle.make = $event"
                        name="make"
                        #make="ngModel"/>
                          <datalist autocomplete="off" id="make" style="max-height: 20px !important;" class="select-input">
                            <option *ngFor="let item of vehicleMakes" value="{{item.make_Name}}">
                          </datalist>
                      </div>
                      <!--Model-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Model')" >{{labelModel}}</label>
                        <input autocomplete="model-name" list="model" name="state-choice" 
                        [ngModel]="chartItem?.vehicle?.model"
                        (ngModel)="chartItem.vehicle.model = $event"
                        (ngModelChange)="chartItem.vehicle.model = $event"
                        name="model"
                        #model="ngModel"/>
                      </div>
                      <!--Exterior Color 1-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Ext Color 1')" >{{labelExertiorColor1}}</label>
                        <input autocomplete="puchasedAs-name"  list="extColor" name="state-choice" 
                        [ngModel]="chartItem?.vehicle?.exteriorColor1"
                        (ngModel)="chartItem.vehicle.exteriorColor1 = $event"
                        (ngModelChange)="chartItem.vehicle.exteriorColor1 = $event"
                        name="exteriorColor1"
                        #exteriorColor1="ngModel"/>
                          <datalist id="extColor" class="select-input">
                            <option value="Silver">
                            <option value="Black">
                          </datalist>
                      </div>
                      <!--SERIES-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Style')" >{{labelStyle}}</label>
                        <input autocomplete="v-name"  list="style" name="state-choice" 
                          [ngModel]="chartItem?.vehicle?.series"
                          (ngModel)="chartItem.vehicle.series = $event"
                          (ngModelChange)="chartItem.vehicle.series = $event"
                          name="series"
                          #series="ngModel"/>
                          <datalist id="style" class="select-input">
                            <option value="2 Door">
                            <option value="4 Door">
                          </datalist>
                      </div>
                       <!--Displacement-->
                    <div class="col-md-12 cstm-col">
                      <label (dblclick)="toggleValue2('Displacement')" >{{labelDisplacement}}</label>
                      <input 
                        [ngModel]="chartItem?.vehicle?.vehicleEngines?.displacementCC"
                        (ngModel)="chartItem.vehicle.vehicleEngines.displacementCC = $event"
                        (ngModelChange)="chartItem.vehicle.vehicleEngines.displacementCC = $event"
                        name="displacementCC"
                        #displacementCC="ngModel"/>
                        
                    </div>
                     <!--Cylinders-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Cylinders')" >{{labelCylinders}}</label>
                        <input autocomplete="v-name"   name="state-choice" 
                        [ngModel]="chartItem?.vehicle?.vehicleEngines?.engineCylinders"
                          (ngModel)="chartItem.vehicle.vehicleEngines.engineCylinders = $event"
                          (ngModelChange)="chartItem.vehicle.vehicleEngines.engineCylinders = $event"
                          name="engineCylinders"
                          #engineCylinders="ngModel"/>
                      </div>
                      <!--EMPTY SPACE-->
                      <div class="col-md-12 cstm-col">
                      </div>
                        <!--DECAL NUMBER-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Decal Number')" >{{labelDecalNumber}}</label>
                        <input autocomplete="puchasedAs-name" type="text" value="0.00" class="cstm-input" 
                        [ngModel]="chartItem?.itemVehicleTransientDetails?.decalNumber"
                        (ngModel)="chartItem.itemVehicleTransientDetails.decalNumber = $event"
                        (ngModelChange)="chartItem.itemVehicleTransientDetails.decalNumber = $event"
                        name="decalNumber"
                        #decalNumber="ngModel">
                      </div>
                      <!--tag-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Tag')" >{{labelTag}}</label>
                        <input autocomplete="puchasedAs-name" type="text" value="0.00" class="cstm-input" 
                        [ngModel]="chartItem?.itemVehicleTransientDetails?.tag"
                        (ngModel)="chartItem.itemVehicleTransientDetails.tag = $event"
                        (ngModelChange)="chartItem.itemVehicleTransientDetails.tag = $event"
                        name="tag"
                        #tag="ngModel">
                      </div>
                      <!--Tag Expires-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Tag Expires')" >{{labelTagExpires}}</label>
                        <input autocomplete="puchasedAs-name" type="date" value="" class="cstm-input" 
                        [ngModel]="chartItem?.itemVehicleTransientDetails?.tagExpires | date:'yyyy-MM-dd'"
                        (ngModel)="chartItem.itemVehicleTransientDetails.tagExpires = $event"
                        (ngModelChange)="chartItem.itemVehicleTransientDetails.tagExpires = $event"
                        name="tagExpires"
                        #tagExpires="ngModel">
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                <div class="row">
                    <!--County Code #-->
                    <div class="col-md-12 cstm-col" >
                      <label (dblclick)="toggleValue2('County Code')" >{{labelCountyCode}}</label>
                      <input class="cstm-input" value=" "
                      type="text"
                      
                      autocomplete="some-name"
                      [ngModel]="chartItem?.itemVehicleTransientDetails?.countyCode"
                      (ngModel)="chartItem.itemVehicleTransientDetails.countyCode = $event"
                      (ngModelChange)="chartItem.itemVehicleTransientDetails.countyCode = $event"
                      name="countyCode"
                      #countyCode="ngModel">
                    </div>
                 <!-- Status  -->
                    <div class="col-md-12 cstm-col">
                      <label (dblclick)="toggleValue2('Status')" >{{labelStatus}}</label>
                        <select class="select-input" #mileageCode="ngModel" ngControl="selectedCategory"  name="mileageCodes"    [(ngModel)] ="chartItem.itemVehicleTransientDetails.vehicleMileageCodesId"  
                            (ngModelChange)="mileageCodeChanged($event)" >
                              <option *ngFor="let item of mileageCodes; index as i"   [value]="item?.id">{{item?.value}}</option>
                        </select>
                    </div>
                      <!--Title Number-->
                    <div class="col-md-12 cstm-col">
                      <label (dblclick)="toggleValue2('Title Number')" >{{lableTitleNumber}}</label>
                      <input class="cstm-input" type="text"
                      autocomplete="off"
                      [ngModel]="chartItem?.itemVehicleTransientDetails?.titleNumber"
                      (ngModel)="chartItem.itemVehicleTransientDetails.titleNumber = $event"
                      (ngModelChange)="chartItem.itemVehicleTransientDetails.titleNumber = $event"
                      name="titleNumber"
                      #titleNumber="ngModel">
                    </div>
                     <!--Previous TItle Number-->
                    <div class="col-md-12 cstm-col">
                      <label (dblclick)="toggleValue2('Previous Title Number')" >{{labelPreviousTitleNumber}}</label>
                      <input class="cstm-input" type="text"
  
                      autocomplete="off"
                      [ngModel]="chartItem?.itemVehicleTransientDetails?.previousTitleNumber"
                      (ngModel)="chartItem.itemVehicleTransientDetails.previousTitleNumber = $event"
                      (ngModelChange)="chartItem.itemVehicleTransientDetails.previousTitleNumber = $event"
                      name="previousTitleNumber"
                      #previousTitleNumber="ngModel">
                    </div>
                    <!--Previous State-->
                    <div class="col-md-12 cstm-col">
                      <label (dblclick)="toggleValue2('Previous State')" >{{labelPreviousState}}</label>
                      <input class="cstm-input" type="text"
                      autocomplete="off"
                      [ngModel]="chartItem?.itemVehicleTransientDetails?.previousState"
                      (ngModel)="chartItem.itemVehicleTransientDetails.previousState = $event"
                      (ngModelChange)="chartItem.itemVehicleTransientDetails.previousState = $event"
                      name="previousState"
                      #previousState="ngModel">
                    </div>
                        <!--Purchased As-->
                    <div class="col-md-12 cstm-col">
                      <label (dblclick)="toggleValue2('Purchased As')" >{{labelPurchasedAs}}</label>
                      <input class="cstm-input" value=""
                        type="text"
                        autocomplete="off"
                        [ngModel]="chartItem?.itemVehicleTransientDetails?.purchasedAs"
                        (ngModel)="chartItem.itemVehicleTransientDetails.purchasedAs = $event"
                        (ngModelChange)="chartItem.itemVehicleTransientDetails.purchasedAs = $event"
                        name="purchasedAs"
                        #purchasedAs="ngModel"
                        required>
                    </div>
                     <!--Purchase Price-->	
                    <div *appHasRole="['Admin']" class="col-md-12 cstm-col">
                      <label (dblclick)="toggleValue2('Purchased Price')" >{{labelPurchasedPrice}}</label>
            
                        <input  class="cstmnumeric-input"  type="number"
                        [ngModel]="chartItem?.itemAmountSums?.purchasedPrice "
                        
                        (ngModelChange)="chartItem.itemAmountSums.purchasedPrice = $event "
                        (blur) = "onChange($event)"
                        #purchasedPrice="ngModel"
                        name="purchasedPrice"
                        >
                      </div>
                       <!--Repairs Depreciated-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Repairs:Depreciated')" >{{labelRepairsDepreciated}}</label>
                        <input autocomplete="puchasedAs-name" class="cstmnumeric-input" type="text"
                        [ngModel]="chartItem?.itemAmountSums?.repairCostSum || 0 | number : '1.2'" 
                        (ngModel)="chartItem.itemAmountSums.repairCostSum = $event"
                        (ngModelChange)="chartItem.itemAmountSums.repairCostSum = $event"
                        name="repairCostSum"
                        disabled=""
                        #repairCostSum="ngModel">
                        <input autocomplete="puchasedAs-name" type="text" value="0.00" class="cstmnumeric-input" 
                        [ngModel]="chartItem?.itemAmountSums?.depriatedCostSum || 0 | number : '1.2'"
                        (ngModel)="chartItem.itemAmountSums.depriatedCostSum = $event"
                        (ngModelChange)="chartItem.itemAmountSums.depriatedCostSum = $event"
                        name="depriatedCostSum"
                        disabled=""
                        #depriatedCostSum="ngModel">
                      </div>
                      <!--Total Fees-->
                      <div *appHasRole="['Admin']" class="col-md-12 cstm-col">
                        <label>Total Fees</label>
                        <input  autocomplete="puchasedAs-name" type="text" value="0.00" class="cstmnumeric-input" 
                        [ngModel]="chartItem?.itemAmountSums?.totalOtherFees  || 0 | number : '1.2'"
                        (ngModel)="chartItem.itemAmountSums.totalOtherFees = $event"
                        (ngModelChange)="chartItem.itemAmountSums.totalOtherFees = $event"
                        onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)" 
                        name="totalOtherFees"
                        disabled=""
                        #totalCostSum="ngModel">
                        <!-- <button (click)="showFees()">Show Fees</button> -->
                      </div>
                      <!--Total Cost-->  <!-- [ngModel]="chartItem?.itemAmountSums?.totalValue || 0 | number : '1.2'" -->
                      <div *appHasRole="['Admin']" class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Total Cost')" >{{labelTotalCost}}</label>
                        <input  autocomplete="puchasedAs-name" type="text" value="0.00" class="cstmnumeric-input" 
                        [ngModel]="chartItem?.itemAmountSums?.totalValue || 0 | number : '1.2'"
                        (ngModel)="chartItem.itemAmountSums.totalValue = $event"
                        (ngModelChange)="chartItem.itemAmountSums.totalValue = $event"
                        onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)" 
                        name="totalValue"
                        disabled=""
                        #totalValue="ngModel">
                        <!-- <button>&laquo;</button> -->
                      </div>
                       <!--Suggested Price-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Suggested Price')" >{{labelSuggestedPrice}}</label>
                        <input  type="number"  class="cstmnumeric-input" 
                        [ngModel]="chartItem?.itemAmountSums?.suggestedPrice "
                        (ngModelChange)="chartItem.itemAmountSums.suggestedPrice = $event"
                        (blur) = "onChange($event)"
                        name="suggestedPrice"
                        #suggestedPrice="ngModel">
                      </div>
                       <!--Date Purchased-->
                      <div class="col-md-12 cstm-col">
                        <label (dblclick)="toggleValue2('Date Purchased')" >{{labelDatePurchased}}</label>
                        <input autocomplete="puchasedAs-name" type="date" value="" class="cstm-input" 
                        [ngModel]="chartItem?.itemVehicleTransientDetails?.datePurchased | date:'yyyy-MM-dd'"
                        (ngModelChange)="chartItem.itemVehicleTransientDetails.datePurchased = $event"
                        name="datePurchased"
                        #dateSold="ngModel">
                      </div>
                      <!--purchased from-->
                        <div class="col-md-12 cstm-col">
                        <!--   <button>{{chartItem?.purchaseFrom?.firstName}} {{chartItem?.purchaseFrom?.lastName}}</button>
                          <button>{{chartItem?.purchaseFromCompany?.name}} {{chartItem?.purchaseFromCompany?.name}}</button> -->
                        </div>
                         <!--Trade In Account-->
                      <div class="col-md-12 cstm-col">
                        <label>Trade In Acct</label>
                        <input disabled="" autocomplete="puchasedAs-name" type="text" value="0.00" class="cstm-input" 
                        [ngModel]="chartItem?.itemVehicleTransientDetails?.tradeInAcct"
                        (ngModel)="chartItem.itemVehicleTransientDetails.tradeInAcct = $event"
                        name="tradeInAcct"
                        #tradeInAcct="ngModel">
                      </div>
                       <!--Date Sold-->
                      <div class="col-md-12 cstm-col">
                        <label>Date Sold</label>
                        <input   type="date"  class="cstm-input" 
                        [ngModel]="chartItem?.soldDate | date:'yyyy-MM-dd'"
                        (ngModel)="chartItem.soldDate = $event"
                        (ngModelChange)="chartItem.soldDate = $event"
                        name="soldDate"
                        #soldDate="ngModel">
                        <!-- <label style="background-color: green;" *ngIf="chartItem.soldDate === null" >NOT SOLD</label> -->
                      
                      </div>
            </div>
          </div>
             
              
             
              <div class="col-md-6 cstm-col">
              
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 cstm-col">
                    <div class="input-sec veh-mile-sec">
                      <!--Mileage-->
                      <div class="rw">
                        <label (dblclick)="toggleValue2('Mileage')" >{{labelMileage}}</label>
                        <input autocomplete="puchasedAs-name" type="number" value="0" class="cstmnumeric-input" 
                        [ngModel]="chartItem?.itemVehicleTransientDetails?.mileage || 0"
                        (ngModel)="chartItem.itemVehicleTransientDetails.mileage = $event"
                        (ngModelChange)="chartItem.itemVehicleTransientDetails.mileage = $event"
                        name="mileage"
                        #mileage="ngModel">
                      </div>
                      <!--WEight-->
                      <div class="rw">
                          <label (dblclick)="toggleValue2('Weight')" >{{labelWeight}}</label>
                        <input autocomplete="puchasedAs-name" type="text" value="0.00" class="cstmnumeric-input" 
                        [ngModel]="chartItem?.vehicle?.weight || 0"
                        (ngModel)="chartItem.vehicle.weight = $event"
                        (ngModelChange)="chartItem.vehicle.weight = $event"
                        name="weight"
                        #weight="ngModel">
                      </div>
                      <!--Mileage Code-->
                      <div class="rw">
                          <label (dblclick)="toggleValue2('Mileage Code')" >{{labelMileageCode}}</label>
                        <input autocomplete="v-name"  type="text" value="" class="cstm-input"
                        [ngModel]="chartItem?.itemVehicleTransientDetails?.mileageCode "
                        (ngModel)="chartItem.itemVehicleTransientDetails.mileageCode = $event"
                        (ngModelChange)="chartItem.itemVehicleTransientDetails.mileageCode = $event"
                        name="mileageCode"
                        #mileageCode="ngModel">
                      </div>
                    </div>
                    <!-- <a href="javascript:void(0)" class="veh-ebay-btn">
                      <img height="120px" src="{{ avatar }}">
                    </a> -->
                  </div>
                  <div class="col-md-6 cstm-col">
                    <div class="rw">
                      <label>Sold To</label>
                      <input disabled="" type="text" value="" class="cstm-input">
                    </div>
                    <label>Sold To Acct</label>
                <input disabled="" autocomplete="puchasedAs-name" type="text" value="" class="cstm-input" 
                [ngModel]="chartItem?.itemVehicleTransientDetails?.soldToAccount"
                (ngModel)="chartItem.itemVehicleTransientDetails.soldToAccount = $event"
               
                name="soldToAccount"
                #soldToAccount="ngModel">
                  </div>
                </div>
    </div>
   
  </div>
  </form>
    </div>
  </div>
  
        <div class="customer-sidebar sidebar-with-btns col-md-2">
            <div class="inner-sidebar">
              <ul>
                
                <li style="list-style-type:none !important;">
                    <button (click)="onSubmit(editForm)" class="cstm-btn img-blk-btn">
                      <img src="assets/images/ap-icon-1.png">
                      <span>SAVE</span>
                    </button>
                  </li>
                <li style="list-style-type:none !important;">
                  <button (click)="viewInventory()" class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-2.png">
                    <span>Table View</span>
                  </button>
                </li>  
              </ul>
            </div>
          </div>
  </div>