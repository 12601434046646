import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Sale } from 'src/app/_models/sale';
import { LoanPaymentsComponent } from '../loan-payments/loan-payments.component';

@Component({
  selector: 'app-navredirectmodalpayment',
  templateUrl: './navredirectmodalpayment.component.html',
  styleUrls: ['./navredirectmodalpayment.component.scss']
})
export class NavredirectmodalpaymentComponent implements OnInit {
  bsModalRefAddPayment: any;
  initialState: any;
  sale = null  as Sale;
  constructor(private modalService: BsModalService,
    private alertify: ToastrService) { }

  ngOnInit() {
    this.loadPaymentScreen();
  }
  loadPaymentScreen() {

     const initialState = {
        sale: this.sale
      };

    this.bsModalRefAddPayment = this.modalService.show(LoanPaymentsComponent, { initialState});
      this.bsModalRefAddPayment.content.paymentEnetered.subscribe((values) => {
      //  console.log(values);

          this.alertify.success('Payment Applied Successullfy.');
        }, error => {
          this.alertify.error(error);
        });
  }
}
