import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddRepairDto } from 'src/app/_models/DTOS/addrepairdto';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { RealPropertyLinqListDto } from 'src/app/_models/DTOS/realpropertylinqlistdto';
import { RealPropertyRepairLinqDto } from 'src/app/_models/DTOS/RealPropertyRepairLinqDto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { Person } from 'src/app/_models/person';
import { RealProperty } from 'src/app/_models/RealProperty';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class RealPropertyService {
    constructor(private http: HttpClient) { }
    baseUrl = environment.apiUrl;

    add(house, parentGuid): Observable<boolean> {
       return this.http.put<boolean>(this.baseUrl + 'realproperty/add/' + parentGuid, house);
    }
    edithouse(house, parentGuid): Observable<boolean> {
      return this.http.put<boolean>(this.baseUrl + 'realproperty/edithouse/' + parentGuid, house);
   }
    addRepair(houseId, repair: AddRepairDto, parentGuid): Observable<RealProperty> {
      return this.http.put<RealProperty>(this.baseUrl + 'realproperty/addrepair/' + parentGuid + '/' + houseId, repair);
   }
   editRepair(houseId, repair: AddRepairDto, parentGuid): Observable<RealProperty> {
    return this.http.put<RealProperty>(this.baseUrl + 'realproperty/editrepair/' + parentGuid + '/' + houseId, repair);
 }
   deleteRepair(repairId, houseid): Observable<RealProperty> {
    return this.http.get<RealProperty>(this.baseUrl + 'realproperty/deleterepair/' + repairId + '/' + houseid );
 }
    get(id, parentId): Observable<any> {
      return this.http.get<any>(this.baseUrl + 'realproperty/get/'  + id + '/' + parentId);
    }
    getLinqList(parentGuid, page?, itemsPerPage?, userParams?, likesParam?): Observable<PaginatedResult<RealPropertyLinqListDto[]>> {
        const paginatedResult: PaginatedResult<RealPropertyLinqListDto[]> = new PaginatedResult<RealPropertyLinqListDto[]>();
        if(page == null) { page = 1; }
        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
          params = params.append('pageNumber', page);
          params = params.append('pageSize', itemsPerPage);
        }
        if  (userParams != null) {
          if (userParams.orderBy !== undefined) { params = params.append('orderBy', userParams.orderBy); }
          if (userParams.isAscending !== undefined) {  params = params.append('isAscending', userParams.isAscending); }
          if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
          if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
          if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
        }

        return this.http.get<RealPropertyLinqListDto[]>(this.baseUrl + 'realproperty/pagedlist/' + parentGuid, { observe: 'response', params})
          .pipe(
            map(response => {
              paginatedResult.result = response.body;
              if (response.headers.get('Pagination') != null) {
                paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
              }
              return paginatedResult;
            })
          );
      }


      getLinqRepairList(houseid, page?, itemsPerPage?, userParams?, likesParam?): Observable<PaginatedResult<RealPropertyRepairLinqDto[]>> {
        itemsPerPage = 1000;
        const paginatedResult: PaginatedResult<RealPropertyRepairLinqDto[]> = new PaginatedResult<RealPropertyRepairLinqDto[]>();
        if(page == null) { page = 1; }
        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
          params = params.append('pageNumber', page);
          params = params.append('pageSize', itemsPerPage);
        }
        if  (userParams != null) {
          if (userParams.orderBy !== undefined) { params = params.append('orderBy', userParams.orderBy); }
          if (userParams.isAscending !== undefined) {  params = params.append('isAscending', userParams.isAscending); }
          if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
          if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
          if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
        }

        return this.http.get<RealPropertyRepairLinqDto[]>(this.baseUrl + 'realproperty/pagedrepairlist/' + houseid, { observe: 'response', params})
          .pipe(
            map(response => {
              paginatedResult.result = response.body;
              if (response.headers.get('Pagination') != null) {
                paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
              }
              return paginatedResult;
            })
          );
      }
  }
