import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AdminService } from 'src/app/_services/admin.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companydataservice/companies.service';
import { AddCompanyprofilepopupComponent } from './add-companyprofilepopup/add-companyprofilepopup.component';
import { EditCompanyprofilepopupComponent } from './edit-companyprofilepopup/edit-companyprofilepopup.component';

@Component({
  selector: 'app-company-grid',
  templateUrl: './company-grid.component.html',
  styleUrls: ['./company-grid.component.css']
})
export class CompanyGridComponent implements OnInit {
selectedCompany = {} as UserAssignedToCompany;
companyProfiles = [] as CompanyProfile[];
bsModalRef: any;
tennantGuid: any;
  constructor(private adminService: AdminService,
    private modalService: BsModalService,
    private authService: AuthService,
    private companiesService: CompaniesService,
    private router: ActivatedRoute,
private alertify: ToastrService,
    ) {}

  ngOnInit() {

    this.router.data.subscribe(data => {
      this.companyProfiles = data.companyProfiles;
      console.log(data);
     });
    // companyProfiles
    this.authService.company.subscribe(data => { this.selectedCompany = data; });
  }
  addCompany() {
    const initialState = {
    };
    this.bsModalRef = this.modalService.show(AddCompanyprofilepopupComponent,
       {initialState});

       this.bsModalRef.content.update.subscribe(data => {
        this.companiesService.saveAddNewCompany(data, this.selectedCompany.parentGuid).subscribe(result =>
          {
            this.companyProfiles = result;
            this.alertify.success('Company Created');
          });
       });
   }
   deleteDefault(data) {
     console.log(data);
    this.companiesService.makeDefault(data.id, data.userId).subscribe(result =>
      {
        this.companyProfiles = result;
        this.alertify.success('Company Created');
      });
   }
   makeDefault(data) {
    this.companiesService.makeDefault(data.id, data.userId).subscribe(result =>
      {
        this.companyProfiles = result;
        this.alertify.success('Company Created');
      });
   }
  edit(e) {
    console.log(e);
    const initialState = {
      company: e
    };
    this.bsModalRef = this.modalService.show(EditCompanyprofilepopupComponent,
      {initialState});

    this.bsModalRef.content.update.subscribe(data => {
      this.companiesService.saveEditCOmpanyByParentGuid(data).subscribe(arg => {
        this.companyProfiles = arg;
      }, error => { this.alertify.success('Error Saving Company'); });
    });
  }
}
