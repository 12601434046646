import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
import { CustomData } from './customData';
import { PDFMetaData } from './pdfmeta';
import { SaleDataBuyer } from './saledatabuyer';
import { SaleDataDates } from './saleDataDates';
import { SaleDataDealInfo } from './saledatadealinfo';
import { SaleDataDealLoan } from './saledatadealloan';
import { SaleDataDealTaxes } from './SaleDataDealTaxes';
import { SaleDataSellingCompany } from './saledatasellingcompany';
import { SaleDataVendors } from './saledatavendors';
import { SaleDataDealDownPayments } from './saledealdataDownPayments';
import { SaleDealInfoDollars } from './saledealinfodollars';
import { SaleDataVehicles } from './salevehiclesdata';
@Injectable({ providedIn: 'root' })
export class SaleData {
  constructor(
    private saleDataBuyer: SaleDataBuyer,
    private sellingCompany: SaleDataSellingCompany,
    private saleDataVehicles: SaleDataVehicles,
    private saleDataDealInfo: SaleDataDealInfo,
    private saleDataDates: SaleDataDates,
    private saleDealInfoDollars: SaleDealInfoDollars,
    private saleDataDealTaxes: SaleDataDealTaxes,
    private saleDataDealDownPayments: SaleDataDealDownPayments,
    private saleDataVendors: SaleDataVendors,
    private saleDataDealLoan: SaleDataDealLoan,
    private customData: CustomData
  ) {}
  didSellingPrice = 0 as number;
  didTag = 0 as number;
  fnz02 = (num) =>
    num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    })

  fnz04 = (num) =>
    num.toLocaleString('en-US', {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
      useGrouping: false,
    })
  fnz0 = (num) =>
    num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: false,
    })
  fillDollars(sale: Sale) {
    this.didSellingPrice = sale?.sellingPrice;
    this.didTag = sale?.tag;
  }
  fillPdfFieldsWithDAta(
    sale: Sale,
    pdfFields: PdfSaleFields[]
  ): Observable<PdfSaleFields[]> {
    console.log(sale);
    console.log(pdfFields);
    if (pdfFields.length === 0) { return null; }
    this.fillDollars(sale);
    let newPdfFields = [] as PdfSaleFields[];
     /** Fill Deal Dollars info */
     this.saleDealInfoDollars.fillPdfFieldsDealInfoDollars(sale, 'did', 'DealDollars', pdfFields).subscribe((data) => {
      if (data.length > 0) {
        newPdfFields = data;
      }
    });
     /** Fill Calculated FIelds */
   const calculatedFields = pdfFields.find((x) => x.calculate === 'true');
    this.customData.fillPdfFieldsWithDealDownPayments(sale, 'cst', 'calculated', pdfFields).subscribe(data => {
      newPdfFields = newPdfFields.concat(data);
    });
     /** Fill Deal Loan */
     this.saleDataDealLoan.fillPdfFieldsLoan(sale, 'al', 'Loan', pdfFields).subscribe((data) => {
      if (data.length > 0) {
        newPdfFields = newPdfFields.concat(data);
      }
    });
     /** Fill Deal Vendors */
     this.saleDataVendors.fillPdfFieldsWithVendors(sale, 'vsl', 'LienHolders', pdfFields).subscribe((data) => {
      if (data.length > 0) {
        newPdfFields = newPdfFields.concat(data);
      }
    });
     /** Fill Deal CashDown */
     this.saleDataDealDownPayments.fillPdfFieldsWithDealDownPayments(sale, 'dd', 'Taxes', pdfFields).subscribe((data) => {
      if (data.length > 0) {
        newPdfFields = newPdfFields.concat(data);
      }
    });
     /** Fill Deal Taxes */
     this.saleDataDealTaxes.fillPdfFieldsWithDealTaxes(sale, 'dt', 'Taxes', pdfFields).subscribe((data) => {
      if (data.length > 0) {
        newPdfFields = newPdfFields.concat(data);
      }
    });
   /** Fill Buyer info */
    this.saleDataBuyer.fillPdfFieldsWithPerson(sale?.buyer, 'b', 'Buyer', pdfFields).subscribe((data) => {
        if (data.length > 0) {
          newPdfFields = newPdfFields.concat(data);
        }
      });
     /** Fill CoBuyer info */
    this.saleDataBuyer.fillPdfFieldsWithPerson(sale?.coBuyer, 'bb', 'CoBuyer', pdfFields).subscribe((data) => {
        if (data.length > 0) {
          newPdfFields = newPdfFields.concat(data);
        }
      });
    // reference 1
    this.saleDataBuyer
      .fillPdfFieldsWithPerson(sale?.reference1, 'r', 'Reference', pdfFields)
      .subscribe((data) => {
        if (data.length > 0) {
          newPdfFields = newPdfFields.concat(data);
        }
      });
    // reference 2
    this.saleDataBuyer
      .fillPdfFieldsWithPerson(sale?.reference2, 'rr', 'Reference', pdfFields)
      .subscribe((data) => {
        if (data.length > 0) {
          newPdfFields = newPdfFields.concat(data);
        }
      });
    // reference 3
    this.saleDataBuyer
      .fillPdfFieldsWithPerson(sale?.reference3, 'rrr', 'Reference', pdfFields)
      .subscribe((data) => {
        if (data.length > 0) {
          newPdfFields = newPdfFields.concat(data);
        }
      });
    // reference 3
    this.sellingCompany
      .fillPdfFieldsWithSellingDealer(sale,
        sale?.companyProfile,
        'co',
        'Dealer',
        pdfFields
      )
      .subscribe((data) => {
        if (data.length > 0) {
          newPdfFields = newPdfFields.concat(data);
        }
      });
    // sold Vehicle
    this.saleDataVehicles
      .fillPdfFieldsWithVehicles(
        sale.vehiclePurchased,
        'sv',
        'VehicleSold',
        pdfFields
      )
      .subscribe((data) => {
        if (data.length > 0) {
          newPdfFields = newPdfFields.concat(data);
        }
      });

    // Trade 1
    if (sale?.tradeIn?.id !== null) {
    this.saleDataVehicles
      .fillPdfFieldsWithVehicles(sale?.tradeIn, 't', 'VehicleTrade', pdfFields)
      .subscribe((data) => {
        if (data.length > 0) {
          newPdfFields = newPdfFields.concat(data);
        }
      });
    }
    if (sale?.tradeIn2?.id !== null) {
    // Trade 2
    this.saleDataVehicles
      .fillPdfFieldsWithVehicles(
        sale.tradeIn2,
        'tt',
        'VehicleTrade2',
        pdfFields
      )
      .subscribe((data) => {
        if (data.length > 0) {
          newPdfFields = newPdfFields.concat(data);
        }
      });

    }
       // Deal Info
    this.saleDataDealInfo
    .fillPdfFieldsDealInfo(
      sale,
      'di',
      'DealInfo',
      pdfFields
    )
    .subscribe((data) => {
      if (data.length > 0) {
        newPdfFields = newPdfFields.concat(data);
      }
    });

       // Sale DAtes
       this.saleDataDates
       .fillPdfFieldsWithDates(
         sale,
         'da',
         'SaleDates',
         pdfFields
       )
       .subscribe((data) => {
         if (data.length > 0) {
           newPdfFields = newPdfFields.concat(data);
         }
       });
    return of(newPdfFields);
  }
}
