import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { QuickbooksSaledefaultsComponent } from './quickbooks-saledefaults/quickbooks-saledefaults.component';
import { QbdefaultsModalComponent } from './quickbooks-saledefaults/qbdefaults-modal/qbdefaults-modal.component';
import { ListQbRoutingModule } from './listqb-routing.module';
import { QbChartofaccountsComponent } from './qb-chartofaccounts/qb-chartofaccounts.component';
import { QbNavlistComponent } from './qb-navlist/qb-navlist.component';

@NgModule({
    declarations: [
        QuickbooksSaledefaultsComponent,
        QbdefaultsModalComponent,
        QbNavlistComponent,
        QbChartofaccountsComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        ListQbRoutingModule,
        AgChartsAngularModule,
        AgGridModule.withComponents([]),
    ],
    providers: []
})
export class ListQbModule { }
