import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { ClmVehicle } from 'src/app/_models/_models/clmvehicle';
import { ClmCost } from 'src/app/_models/_models/clmcost';

@Injectable({
  providedIn: 'root'
})
export class ClmVehicleService {
  baseUrl = environment.apiUrl;
  companyId: number;
constructor(private http: HttpClient) { }
deleteItems(parentGuid): Observable<any>{
  return this.http.get<ClmVehicle[]>(this.baseUrl + 'chartitem/getitemstoharddelete/' + parentGuid );
}
deleteItemsRemainingVehicles(parentGuid): Observable<any>{
  return this.http.get<ClmVehicle[]>(this.baseUrl + 'chartitem/deletevehiclesremaining/' + parentGuid );
}
listAllVehicles(parentGuid): Observable<ClmVehicle[]> {
  return this.http.get<ClmVehicle[]>(this.baseUrl + 'clmvehicle/clmvehicleslist/' + parentGuid );
}
getVehicleKodList(parentGuid): Observable<any[]>{
  return this.http.get<any[]>(this.baseUrl + 'clmvehicle/getlistvehicles/' + parentGuid );
}
listAllVehicleCosts(parentGuid): Observable<ClmCost[]> {
  return this.http.get<ClmCost[]>(this.baseUrl + 'clmvehicle/clmlistvehiclecost' );
}
getVehicleWithRepairandPost(kod: number, parentGuid: string):Observable<ClmVehicle[]>{
  console.log(kod);
  return this.http.get<ClmVehicle[]>(this.baseUrl + 'clmvehicle/clmvehiclesgetrepairsandvehicleandpostall/' + kod + '/' + parentGuid );
}


listPersons(parentGuid): Observable<any[]>{
  return this.http.get<any[]>(this.baseUrl + 'clmperson/listclmpersons/' );
}
getPeopleKodList(parentGuid): Observable<any[]>{
  return this.http.get<any[]>(this.baseUrl + 'clmperson/getlistpersonskods/' + parentGuid );
}
postPersons(kod: number, parentGuid: string):Observable<any[]>{
  
  return this.http.get<any[]>(this.baseUrl + 'clmperson/addpersonfromkod/' + kod + '/' + parentGuid );
}

listExpenses(parentGuid: string):Observable<any[]> {
  return this.http.get<any[]>(this.baseUrl + 'clmperson/listclmexpenses/' + parentGuid );
}


postInsuranceComanies(parentGuid: string):Observable<any[]>{
  
  return this.http.get<any[]>(this.baseUrl + 'clmperson/clmpostinsurancecompanies/'  + parentGuid );
}
listInsurances(parentGuid: string):Observable<any[]> {
  return this.http.get<any[]>(this.baseUrl + 'clmperson/listclminsurances/' );
}


postLienHolders(parentGuid: string):Observable<any[]>{
  
  return this.http.get<any[]>(this.baseUrl + 'clmperson/clmlienholderspost/'  + parentGuid );
}

listLienHolders( parentGuid: string):Observable<any[]> {
  return this.http.get<any[]>(this.baseUrl + 'clmperson/listclmlienholders' );
}



listPrecomputedPayments(parentGuid: string):Observable<any[]> {
  return this.http.get<any[]>(this.baseUrl + 'clmpaymentsprecomputed/listclmpaymentsprecomputed' );
}
  listPrepcomputedKods():Observable<string[]> {
    return this.http.get<any[]>(this.baseUrl + 'ClmPaymentsPrecomputed/listprecomputedpaymentskod' );
  }
}
