<!--component html goes here -->
<br>
<div class="customer-form-sec">
    <div class="container-fluid">

      <div class="row">
        <div class="form-sec tab-content col-md-12">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-row account-payable-row">
                  <div class="row">
                    <div class="col-md-12 table-sec">
                      <div id="printableTable">
                        <br><br>
                        <div>
                          <table class="pricing-table">
                            <tr>
                                <th>Id</th>
                                <th>Kod</th>
                                <th>Stock</th>
                                <th>Cost</th>
                                <th>Description</th>
                                <th> Date</th>
                            </tr>
                            <tr class="td-row" *ngFor="let item of clmCosts; let i=index">
                              <td>{{ item?.id}}</td>
                              <td>{{ item?.kod}}</td>
                              <td>{{ item?.stock}}</td>
                              <td>{{ item?.costAmount}}</td>
                              <td>{{ item?.description}}</td>
                              <td>{{item?.costDate | date: 'MM/dd/yyyy'}}</td>
                    
                              <td><button ng-if="!item?.isPublished" class="btn-success">Puplish</button></td>
                            </tr>
                          </table>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>