import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormName } from '../_models/formName';
import { CompanySeed } from '../_models/SeedCompany';
import { UserAssignedToCompany } from '../_models/userAssignedToCompany';
import { AuthService } from '../_services/auth.service';
import { FormNameLabelsService } from '../_services/formLabelservices/form-name-labels.service';
import { SeedService } from '../_services/seeds/seed.service';

@Component({
  selector: 'app-company-seeds',
  templateUrl: './company-seeds.component.html',
  styleUrls: ['./company-seeds.component.css']
})
export class CompanySeedsComponent implements OnInit {
selectedCompany1 = {} as UserAssignedToCompany;
companySeeds = {} as CompanySeed;
  constructor(private seedService: SeedService,
    private alertify: ToastrService,
    private formService: FormNameLabelsService,
    private authService: AuthService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data;
    this.seedPdfFieldNameType();
    this.seedSettings();
    this.seedSaleTaxDefault();
    this.seedAmoLoanMethod();
    this.seedAmoInstallments();
    this.seedSaleType();
    this.seedSaleStatus();
    this.seedTaxTemplate();
    this.seedFormNameDealInfo();
    this.seedVehicleMileageCodes();
    this.seedVehicleBodyTypes();
    this.seedItemTypes();
   // this.seedZipCodeLookups();
    this.seedDollarTypes();
    this.seedProgramDefaults();
    this.seedStates();
    this.seedPropertyRepairTypes();
    this.seedSalesSoldAs();
    this.seedPdfFormPackagePrintLocation();
    this.seedPdfEntityModelType();
    this.seedPdfTypeCategory();
    this.seedCountry();
    this.seedPaymentType();
    this.seedQbDefaults();
    });
  }

  getFormLabels() {
    this.formService.getFormNameList(this.selectedCompany1.parentGuid).subscribe(data => {
      localStorage.setItem('formsLabels', JSON.stringify(data));
      console.log(data);
      this.authService.changeFormLabels(data);
    });
  }
  seedPdfTypeCategory() {
    this.seedService.seedPdfTypeCategory(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedQbDefaults() {
    this.seedService.seedQbInvoiceDefaults(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
    }, () => { this.alertify.success('Success'); });
  }
  seedPdfEntityModelType() {
    this.seedService.seedPdfEntityModelType(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedPdfFormPackagePrintLocation() {
    this.seedService.seedPdfFormPackagePrintLocation(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedSalesSoldAs() {
    this.seedService.seedSalesSoldAs(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedPropertyRepairTypes() {
    this.seedService.seedPropertyRepairTypes(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedStates(){
    this.seedService.seedStates(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedProgramDefaults() {
    this.seedService.seedProgramDefaults(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedDollarTypes() {
    this.seedService.seeddollartypes(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedItemTypes() {
    this.seedService.seedItemTypes(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedZipCodeLookups() {
    this.seedService.seedZipCodeLookups(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedVehicleBodyTypes() {
    this.seedService.seedVehicleBodyTypes(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedVehicleMileageCodes() {
    this.seedService.seedVehicleMileageCodes(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedFormNameDealInfo() {
    this.seedService.seedFormNameDealInfo(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success');
    this.seedLabelsDealInfo();
  });
  }
  seedLabelsDealInfo() {
    this.seedService.seedLabelsDealInfo(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); this.seedFormNameVehicleDetails();
  });
  }
  seedLabelsVehicleDetails() {
    this.seedService.seedLabelsVehicleDetails(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); this.getFormLabels();
  });
  }
  seedFormNameVehicleDetails() {
    this.seedService.seedFormNameVehicleDetails(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); this.seedLabelsVehicleDetails();
  });
  }
  seedTaxTemplate() {
    this.seedService.seedTaxTemplate(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedSaleStatus() {
    this.seedService.seedSaleStatus(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedSaleType() {
    this.seedService.seedSaleType(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedCountry() {
    this.seedService.seedCountryList(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedAmoInstallments() {
    this.seedService.seedAmoInstallments(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedAmoLoanMethod() {
    this.seedService.seedAmoLoanMethod(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
 seedSettings() {
  this.seedService.seedSettings(this.selectedCompany1.parentGuid).subscribe(data => {
    this.companySeeds = data;
    console.log(data); },
    error => {
    console.log(error);
  }, () => { this.alertify.success('Success'); });
 }
 seedSaleTaxDefault() {
  this.seedService.seedSaleTaxDefault(this.selectedCompany1.parentGuid).subscribe(data => {
    this.companySeeds = data;
    console.log(data); },
    error => {
    console.log(error);
  }, () => { this.alertify.success('Success'); });
 }
 seedPaymentType() {
  this.seedService.seedPaymentType(this.selectedCompany1.parentGuid).subscribe(data => {
    this.companySeeds = data;
    console.log(data); },
    error => {
    console.log(error);
  }, () => { this.alertify.success('Success'); });
 }
  seedPdfFieldNames() {
    this.seedService.seedPdfFieldNames(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => { this.alertify.success('Success'); });
  }
  seedPdfFieldNameType() {
    this.seedService.seedPdfFieldNameTypes(this.selectedCompany1.parentGuid).subscribe(data => {
      this.companySeeds = data;
      console.log(data); },
      error => {
      console.log(error);
    }, () => {
      this.alertify.success('Success now fields.');
      this.seedPdfFieldNames();
    });
  }
}
