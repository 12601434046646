import { Component, OnInit, HostListener, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Person } from 'src/app/_models/person';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileUploader } from 'ng2-file-upload';
import { PersonPhoto } from 'src/app/_models/personphoto';
import { environment } from 'src/environments/environment';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css']
})

export class EditEmployeeComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  person = {} as Person ;
  @Output() update = new EventEmitter();
  @Output() userWebsiteToReturnDto = new EventEmitter();
  user: any;
  personType: any;
  todayDate = new Date();
  @Input() parentGuid: string;
  @Input() photos: PersonPhoto[];
  @Input() selectedCompany: UserAssignedToCompany;
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.editForm.dirty) {
      $event.returnValue = true;
    }
  }
  constructor(public bsModalRef1: BsModalRef) {}
  deletePhoto(){}
  ngOnInit() {
    this.initializeUploader();
    if (this.person.dateOfBirth == null) { this.person.dateOfBirth = this.todayDate; }
    if (this.person.insuranceExpiration == null) { this.person.insuranceExpires = this.todayDate; }
  }

  onSubmit() {
    this.update.emit(this.editForm.value);
    console.log(this.editForm.value);
    this.bsModalRef1.hide();
  }


  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  initializeUploader() {
    console.log(this.baseUrl   + 'personphotos/employeephoto/' + this.person.id + '/' + this.selectedCompany.parentGuid);
    console.log( localStorage.getItem('token'));
    this.uploader = new FileUploader({
      url: this.baseUrl   + 'personphotos/employeephoto/' + this.person.id + '/' + this.selectedCompany.parentGuid,
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: ['image'],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024
    });
// ,
// headers: [{ name: 'Bearer', value : localStorage.getItem('token') } ]
    this.uploader.onAfterAddingFile = (file) => {file.withCredentials = false; };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
     this.userWebsiteToReturnDto.emit(response);
     this.person = JSON.parse(response);
   //  this.bsModalRef.hide();
    this.photos = this.person.personPhotos;
    };
  }
}


