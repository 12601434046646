import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SaleType } from '../_models/saletype';
import { SaleTypeService } from '../_services/saleservices/sale-types.service';

@Injectable({
    providedIn: 'root'
})
export class SaleTypesResolver implements Resolve<SaleType[]> {
    constructor(private chartInvoiceSaleTypeService: SaleTypeService, private router: Router,
        private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<SaleType[]> {
      //  console.log(route.params['parentGuid']);
        return this.chartInvoiceSaleTypeService.getSaleTypes(route.params['parentGuid']).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving invoice statuses.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}