import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PdfStateList } from 'src/app/_models/PDFentites/PdfStateList';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companydataservice/companies.service';


@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() company: CompanyProfile;
  stateList = [] as PdfStateList[];
  userAssignedToCompany: UserAssignedToCompany;
  companyGuid: string;
  constructor(private companyService: CompaniesService,
    private pdfService: PdfService,
    private authService: AuthService,
    private alertify: ToastrService) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(data => { this.userAssignedToCompany = data; });

    this.pdfService.getPdfMasterStateList().subscribe(data => {
      this.stateList = data;
    });
  }
  stateSelected(e) {
    console.log(e);
    console.log(this.editForm.value);
  }
  onSubmit(e: CompanyProfile) {
    console.log(this.company);
    this.companyService.
    saveEditCompany(this.userAssignedToCompany.companyProfileId, this.company).
    subscribe((data: CompanyProfile) => {
      console.log(data);
      this.company = data;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Profile updated successfully');
      this.editForm.reset(this.company);
    });
    console.log(e);
  }
  

}
