<br /><br><br><br /><br> <br>
<app-produts-filters (pageChangeExport) = "pageChanged($event)"  
(userParamsExport) = "filter($event)" 
[pagination] = "pagination">
</app-produts-filters>

<br><br>
<div class="customer-tabs-sec" style="padding-bottom: 10px; width: 60% !important;">
  <ul class="nav nav-tabs">
    <li><a [ngClass] = "isDeleted ? 'active show' : ''" (click) ="isActiveDeletedClick('isDeleted')"  href="javascript:void(0)">Deleted</a></li>
    <li  ><a [ngClass] = "isActive ? 'active show' : ''" (click) ="isActiveDeletedClick('isActive')"  href="javascript:void(0)">Active</a></li>
    <li  ><a [ngClass] = "isAll ? 'active show' : ''" (click) ="isActiveDeletedClick('isAll')"  href="javascript:void(0)">All</a></li>
  </ul>
  </div>
  <br>
<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-11">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">          
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <div id="printableTable">
                    <table  borderColor="#ccc">
                      <tr>
                        <th>ID</th>
						<th  (click)="sort('category')">Category &nbsp;
							<i [ngClass]="isCategoryAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('stock')">Stock&nbsp;
							<i [ngClass]="isStockAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('year')">Year&nbsp;
							<i [ngClass]="isYearAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('make')">Make&nbsp;
							<i [ngClass]="isMakeAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('model')">Model&nbsp;
							<i [ngClass]="isModelAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('body')">Vin&nbsp;
							<i [ngClass]="isBodyAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('price')">Price&nbsp;
							<i [ngClass]="isPriceAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('published')">Publish&nbsp;
							<i [ngClass]="isPublishedAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('solddate')"> Sold Date&nbsp;
							<i [ngClass]="isSoldDateAscending ? 'up': 'down'"></i>
						</th>
						<th (click)="sort('pickcount')"> Picture Count&nbsp;
							<i [ngClass]="isPickCountAscending ? 'up': 'down'"></i>
						</th>
						<th></th>
					  </tr>
					  <tr  [hidden]="isPrint">
                        <td>
                          
                        </td>
						<td >
						
							<select  
								[(ngModel)] ="selectedCategory" (change) ="selectedCategoryChanged($event.target.value)"
								name="chartInvoiceSaleTypes"
								onmousedown = "value = '';" 
								#chartInvoiceSaleTypes="ngModel">
								<option ngValue= "null">--All--</option>
								<option *ngFor="let summary of bodyTypes" value="{{summary.id}}">
								  {{summary.nameFriendly}}
								</option>
						</select>
						</td>
                        <td style="width: 76px"><input (keyup)="setSearchBy('searchStock', $event.target.value)" >
                        </td>  
                        <td><input style="width: 55px;" (keyup)="setSearchBy('searchYear', $event.target.value)">
                        </td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchMake', $event.target.value)"></td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchModel', $event.target.value)"></td>  
                        <td><input style="width: 116px;" (keyup)="setSearchBy('searchVin', $event.target.value)"></td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchPrice', $event.target.value)"></td>  
                        <td></td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchSoldDate', $event.target.value)"></td>  
						<td></td>
						<td></td>
                      </tr>
                      <tr class="td-row" *ngFor="let item of chartItems; let i=index" >
                        <td *ngIf="i != chartItems.length - 1">{{ item.id }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.bodyType }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.stock }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.year }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.make }}</td>

                        <td *ngIf="i != chartItems.length - 1">{{ item?.model }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.vin }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.suggestedPrice | number: "1.2" }}</td>
                        <td *ngIf="i != chartItems.length - 1">
                          <button
                            style="width: 60px;"
                            [ngClass]="
                              item?.isPublished
                                ? 'btn btn-outline-success'
                                : 'btn btn-outline-danger'
                            "
                            (click)="updateIsPublished(item)"
                          >
                            {{ item?.isPublished }}
                          </button>
                        </td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.soldDate | date: "shortDate" }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.photoCount }}</td>

                        <td [hidden]="isPrint" *ngIf="i != chartItems.length - 1">
                          <button
                            class="btn btn-outline-success"
                            (click)="editVehicle(item)"
                          >
                            Edit
                          </button>
                        </td>
                        <td [hidden]="isPrint" *ngIf="i != chartItems.length - 1 && item.dateDeleted == null">
                          <button
                            class="btn btn-outline-warning"
                            (click)="deleteVehicle(item)"
                          >
                            Delete
                          </button>
                        </td>
                        <td [hidden]="isPrint" *ngIf="i != chartItems.length - 1 && item.dateDeleted != null ">
                          <button
                            class="btn btn-outline-success"
                            (click)="deleteVehicle(item)"
                          >
                            UnDelete
                          </button>
                        </td>
                      </tr>
                      <tr  align="right" class="border_top" *ngFor="let item of chartItems; let i=index; last as isLast">
                    
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">{{ item?.suggestedPrice | number: "1.2" }}</td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <tr>
                    </table>
                    </div>
                  </div>
                  <!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-1">
        <div class="inner-sidebar">
      
          <ul>
            <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->
            <li>
              <button (click)="addVehicleNoVin()" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png" />
                <span>Add Vehicle</span>
              </button>
            </li>
            <li>
              <button (click)="addVehicle()" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png" />
                <span>Add By Vin</span>
              </button>
            </li>
            <li *appHasRole="['Admin']">
              <button class="cstm-btn" (click) = "exportAsXLSX()">
                <img src="assets/images/icons8-microsoft-excel-48.png" />
                <span>Export Excel</span>
              </button>
            </li>
            <li>
              <button (click) = "printDiv()" class="cstm-btn">
                <img src="assets/images/ap-icon-6.png" />
                <span>Print Report</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>