import { Injectable } from '@angular/core';
import { WebsiteCompany } from 'src/app/_models/company';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyGpsService {
  baseUrl = environment.apiUrl;
  companyId: number;
constructor(private http: HttpClient) { }


edit(companygps, websiteguid): Observable<WebsiteCompany> {
  console.log(this.baseUrl + 'companygps/updategps/' + websiteguid);
  return this.http.put<WebsiteCompany>(this.baseUrl + 'companygps/updategps/' + websiteguid, companygps);
}
}
