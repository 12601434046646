import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyProfileResolver } from '../_resolvers/companyprofile.resolver';
import { CompanyProfileComponent } from './company-profile/company-profile.component';




const routes: Routes = [
     {path: 'home/:parentGuid', component: CompanyProfileComponent, resolve: {
        companyProfile: CompanyProfileResolver

    }},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CompanyProfileRoutingModule {}
