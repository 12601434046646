import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { WebsiteCompany } from 'src/app/_models/company';
import { ServiceOffer } from 'src/app/_models/serviceoffer';
import { CompanyPhoto } from 'src/app/_models/companyphoto';
import { Website } from 'src/app/_models/website';

@Component({
  selector: 'app-company-photos',
  templateUrl: './company-photos.component.html',
  styleUrls: ['./company-photos.component.css']
})
export class CompanyPhotosComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() company: WebsiteCompany;
  @Input() website: Website;
  companyPhotos: CompanyPhoto[];
  homePageCompanyPhotos = [] as CompanyPhoto[];
  companyPhoto: CompanyPhoto;
  selected: boolean;
  constructor() { }

  ngOnInit() {
   // console.log(this.website);
    this.companyPhotos = this.website?.company?.companyPhotos;
    this.companyPhotos.forEach(element => {
      if (element.companyHomePagePhotoNumber >> 0) {
    //    console.log(element);
        this.homePageCompanyPhotos.push(element);
      }
    });
    this.selected = false;
  }
  filterPhotos() {
    console.log(this.companyPhotos);
    this.companyPhotos.forEach(element => {
      if (element.companyHomePagePhotoNumber >> 0) {
      //  console.log(element);
        this.homePageCompanyPhotos.push(element);
      }
    });
  }
  seletedItem(e) {
    this.companyPhoto = e;
    this.selected = true;
  }
}
