import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormName } from '../_models/formName';
import { FormNameLabelsService } from '../_services/formnamelabels/form-name-labels.service';

@Injectable({
    providedIn: 'root'
})
export class FormNameListResolver implements Resolve<FormName[]> {
    constructor(private formNameService: FormNameLabelsService, private router: Router,
        ) {}
    //    private alertify: AlertifyService
    resolve(route: ActivatedRouteSnapshot): Observable<FormName[]> {
        const newLocal = 'parentGuid';
        return this.formNameService.getFormNameList(route.params[newLocal]).pipe(
            catchError(error => {
              //  this.alertify.error('Problem retrieving form name list.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
