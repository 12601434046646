import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastRef, ToastrService } from 'ngx-toastr';

import { AddPdfpackageComponent } from 'src/app/modals/add-pdfpackage/add-pdfpackage.component';
import { ConvertPdfToArraysAndBuffers } from 'src/app/_classes/pdffielddatats/convertpdf-toarrays';
import { PdfMergePdfs } from 'src/app/_classes/pdffielddatats/merge-pdfdocuments';
import { PdfFormsPack } from 'src/app/_models/PdfFormPack';
import { PdfFormPackage } from 'src/app/_models/pdfformpackage';
import { PdfForms } from 'src/app/_models/PdfForms';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { PdfPackageService } from 'src/app/_services/PdfSErvices/pdf-package.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import printJS from 'print-js';
import { Sale } from 'src/app/_models/sale';
import { PackageButtonLocations } from 'src/app/_models/PackageButtonLocations';
import { Item } from 'src/app/_models/item';
@Component({
  selector: 'app-pdf-mergingitems',
  templateUrl: './pdf-mergingitems.component.html',
  styleUrls: ['./pdf-mergingitems.component.css']
})
export class PdfMergingitemsComponent implements OnInit, OnChanges {
  bsModalRef: any;
  @Output() newPackageClickedPdfMergingItems = new EventEmitter<string>();
  selectedItem: PdfFormsPack;
  byteArray: any;
  isFormSelected = false;
  formNameSelected = '';
  ifFormSelected: any;
  isPackageSelected = false;
  pdfFormPack = [] as PdfFormsPack[];
  selectedPdfFormPack: PdfFormPackage;
  packageName = '';
  pdfToAddToPackage: PdfForms;
  selectedCompany1: UserAssignedToCompany;
  isReportsManagerFormSelected = false;
  @Input() packageButtonLocations = [] as PackageButtonLocations[];
  list = [];
  urLList = [];
  @Input() sale: Sale;
  @Input() item: Item;
  uint8Array: any;
  base64Array: any;
  isWithData = true;
  isEmptyForm = false;
  pdfForm = {} as PdfForms;
  @Input() salePackages = [] as PdfFormPackage[];
  @Input() inventoryPackages = [] as PdfFormPackage[];
  public loading = false;
  constructor(private modalService: BsModalService,
    private authService: AuthService,
    private pdfMergePdfs: PdfMergePdfs,
    private alertify: ToastrService,
    private pdfService: PdfService,
    private pdfPackageService: PdfPackageService,
    private convertPdfToArraysAndBuffers: ConvertPdfToArraysAndBuffers,
    private confirmService: ConfirmService) { }
  ngOnChanges() {
//    if (this.salePackages.length > 0 ) {
//      this.packageSelected(this.salePackages[0]);
//  //   this.packageSelected(this.salePackages[0]);
//     }


console.log(this.inventoryPackages);
  }
  getPackages() {
    this.pdfPackageService.geFormPackages(this.selectedCompany1.parentGuid).subscribe(data => {
    //  this.salePackages = data.salePackages;
      this.inventoryPackages = data.inventoryPackages;
      console.log(data.inventoryPackages);
    });
  }
/**
 * Located within PDF MERGING
 * sets with data or with out data on form
 * merges all pdf files into new pdf
 */
  subscribeToPdfFormSelectedOnParent(item) {
    console.log(item);
    this.loading = true;
    this.pdfToAddToPackage = item;
    this.isReportsManagerFormSelected = true;
    this.urLList = [];
    this.list = [];
    // this.pdfService.pdfForm.subscribe(data => {
    //   this.pdfForm = data;
    //   this.pdfMergePdfs.copyPages(this.sale, item.pdfFormUrl, this.urLList, this.isWithData, this.isEmptyForm);
    //   this.loading = false;  });
       console.log('reports manager form click pdf event');
  }
  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data;
      this.getPackages();
    });
  }
  formSelected(item: PdfFormsPack) {
    this.selectedItem = item;
  }
  packageSelected(package1: PdfFormPackage) {
   // this.loading = true;
    console.log(package1);
    console.log(this.isWithData);
    // this.pdfService.getIsEmpty().subscribe(data => { this.isEmptyForm = data; });
    // this.pdfService.getIsWithData().subscribe(data => { this.isWithData = data; });
    this.pdfFormPack = package1.pdfFormsPacks;
    this.selectedPdfFormPack = package1;
    this.isPackageSelected = true;
    this.packageName = package1?.packageName + ' is selected.';
    this.formNameSelected = null;
    this.ifFormSelected = false;
    this.urLList = [];
    console.log(package1.pdfFormsPacks.length);
    this.pdfPackageService.getIndividualPackageById(package1.id).subscribe(data => {
      this.selectedPdfFormPack = data;
    });
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < package1.pdfFormsPacks.length; ++i) {
        this.urLList.push({url: package1.pdfFormsPacks[i].pdfForm.pdfFormUrl});
    }
    console.log(this.urLList);
    this.pdfMergePdfs.copyPages(this.sale, package1.pdfFormsPacks[0].pdfForm.pdfFormUrl, this.urLList, this.isWithData, this.isEmptyForm)
    .then(data => { this.urLList = []; this.loading = false; this.alertify.success('Pdfs Populated.'); });
    this.list = []; console.log(this.urLList);
  }
  update() {
    // console.log(this.pdfForms);
    // this.emittedValues.emit(this.pdfForms);
  }
  addNewPackage() {
    const initialState = {
      packageButtonLocations: this.packageButtonLocations
    };
   this.bsModalRef = this.modalService.show(AddPdfpackageComponent, {initialState});
   this.bsModalRef.content.emittedValues.subscribe((data: PdfFormPackage) => {
     data.parentGuid = this.selectedCompany1.parentGuid;
     console.log(data);
     this.pdfPackageService.addFormPackage(data).subscribe((value) => {
      this.salePackages = value.salePackages;
      this.inventoryPackages = value.inventoryPackages;
     }, error => { this.alertify.error(error); }, () => {
       this.alertify.success('Package Added');
       this.newPackageClickedPdfMergingItems.emit('New Package');
     });
     console.log(data);
   });
  }
  removePackage() {
    this.confirmService.confirm('Confirm delete message', 'This cannot be undone').subscribe(result => {
      if (result) {
        if (this.isPackageSelected) {
        this.pdfPackageService.removeFormPackage(this.selectedPdfFormPack).subscribe((value) => {
          this.salePackages = value.salePackages;
          this.inventoryPackages = value.inventoryPackages;
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Package Removed');
          this.formNameSelected = null;
          this.isPackageSelected = false;
          this.ifFormSelected = false;
        });
        }
      }
    });
  }
  renamePackage() {
    const initialState = {
      pdfFormPackage: this.selectedPdfFormPack
    };
   this.bsModalRef = this.modalService.show(AddPdfpackageComponent, {initialState});
   this.bsModalRef.content.emittedValues.subscribe((data: PdfFormPackage) => {
     data.parentGuid = this.selectedCompany1.parentGuid;
     this.pdfPackageService.renameFormPackage(data).subscribe((value) => {
      this.salePackages = value.salePackages;
      this.inventoryPackages = value.inventoryPackages;
     }, error => { this.alertify.error(error); }, () => {
       this.alertify.success('Package Updated');
     });
     console.log(data);
   });
  }
  removeFormFromPackage() {
    this.confirmService.confirm('Delete pdf from package?', 'This cannot be undone').subscribe(result => {
      if (result) {
        if (this.selectedItem) {

        this.pdfPackageService.removeFormFromPdfFormPackage(this.selectedItem, this.selectedCompany1.parentGuid).subscribe((value) => {
          for (let i = 0; i < this.selectedPdfFormPack.pdfFormsPacks.length; i++) {
            if (this.selectedPdfFormPack.pdfFormsPacks[i].id === this.selectedItem.id) {
              this.selectedPdfFormPack.pdfFormsPacks.splice(i, 1);
                break;
            }
          }
          this.salePackages = value.salePackages;
          this.inventoryPackages = value.inventoryPackages;
          this.formNameSelected = null;
          this.ifFormSelected = false;
        }, error => { this.alertify.error(error); }, () => {
          // this.selectedItem = null;
          // this.ifFormSelected = false;
          // this.packageSelected(this.selectedPdfFormPack);
          // this.pdfFormPack = this.selectedPdfFormPack.pdfFormsPacks;
        //  console.log(this.pdfFormPack);
          this.alertify.success('Form Removed from package successfully.');
        });
        } else { this.alertify.error('No Form Selected.'); }
      }
    });
  }
  addFormToPackage() {
    console.log(this.pdfToAddToPackage);
    if (this.pdfToAddToPackage) {
      this.pdfPackageService.addFormToPdfFormPackage(this.selectedPdfFormPack, this.pdfToAddToPackage.id).subscribe((value) => {
       this.selectedItem = value.pdfFormsPack;
       console.log(value.pdfFormsPack);
     //   this.selectedPdfFormPack.pdfFormsPacks.push(value.pdfFormsPack);
        this.salePackages = value.salePackages;
        this.inventoryPackages = value.inventoryPackages;
        this.formNameSelected = null;
        this.ifFormSelected = false;
      }, error => { this.alertify.error(error); }, () => {
        if (this.selectedPdfFormPack.pdfFormsPacks.length >= 0 ) {
          this.selectedPdfFormPack.pdfFormsPacks.push(this.selectedItem);
        }
        this.alertify.success('Form Removed from package successfully.');
      });
      } else { this.alertify.error('No Form To Add.'); }

  }
  formEditor(item: PdfFormsPack) {
    this.selectedItem = item;
    this.loading = true;
    console.log(this.sale);
    this.pdfMergePdfs.copyPages(this.sale, item.pdfForm.pdfFormUrl, this.urLList, this.isWithData, this.isEmptyForm).then(
      data => { this.loading = false; this.alertify.success('Pdfs Populated.'); });
    this.formNameSelected = item?.pdfForm?.name;
    this.ifFormSelected = true;
  }
}
