import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Person } from 'src/app/_models/person';
import { Sale } from 'src/app/_models/sale';
@Injectable({ providedIn: 'root' })
export class SaleDataDates {
     moment = require('moment');
    convertDate(date) {
        const momentDate = moment().format('L').toString();
        return momentDate;
}


      fillPdfFieldsWithDates(sale: Sale, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
      let  field = '';
      let newpdfField = {} as PdfSaleFields;
      const newPdfFields = [] as PdfSaleFields[];
        pdfFields.forEach(element => {
        field = element.field;
        // buyer Information
        switch (field) {
            case 'daSoldDate': {
                newpdfField  = {
                    field: 'daSoldDate',
                    value: this.convertDate(sale.soldDate).toString(),
                    calculate: 'false',
                    groupBy: groupByField,
                    id: element.id,
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case 'daSoldDate1': {
                newpdfField  = {
                    field: 'daSoldDate1',
                    value: this.convertDate(sale.soldDate).toString(),
                    calculate: 'false',
                    groupBy: groupByField,
                    id: element.id,
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case 'daSoldDate2': {
                newpdfField  = {
                    field: 'daSoldDate2',
                    value: this.convertDate(sale.soldDate).toString(),
                    calculate: 'false',
                    groupBy: groupByField,
                    id: element.id,
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case 'daSoldDate3': {
                newpdfField  = {
                    field: 'daSoldDate3',
                    value: this.convertDate(sale.soldDate).toString(),
                    calculate: 'false',
                    groupBy: groupByField,
                    id: element.id,
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case 'daSoldDate4': {
                newpdfField  = {
                    field: 'daSoldDate4',
                    value: this.convertDate(sale.soldDate).toString(),
                    calculate: 'false',
                    groupBy: groupByField,
                    id: element.id,
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case 'daSoldDate5': {
                newpdfField  = {
                    field: 'daSoldDate5',
                    value: this.convertDate(sale.soldDate).toString(),
                    calculate: 'false',
                    groupBy: groupByField,
                    id: element.id,
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case 'vsSoldDate3': {
                newpdfField  = {
                    field:  'vsSoldDate3',
                    value: this.convertDate(sale.soldDate).toString() ,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }

            default: {
                break;
             }
          }
         });
         return of(newPdfFields);
    }


}
