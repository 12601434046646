import { Component, OnInit, ViewChild, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Person } from 'src/app/_models/person';
import { PersonPhoto } from 'src/app/_models/personphoto';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
@Component({
  selector: 'app-person-grid-card',
  templateUrl: './person-grid-card.component.html',
  styleUrls: ['./person-grid-card.component.css']
})
export class PersonGridCardComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() person: Person;
  @Output() update = new EventEmitter();
  @Output() userWebsiteToReturnDto = new EventEmitter();
  @Input() selectedCompany: UserAssignedToCompany;
  user: any;
  personType: any;
  @Input() photos: PersonPhoto[];
  @Input() parentGuid: string;
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  currentMain: PersonPhoto;
  dateF: any = new Date();
  @HostListener('window:beforeunload', ['$event'])
unloadNotification($event: any) {
  if (this.editForm.dirty) {
    $event.returnValue = true;
  }
}
  constructor( private authService: AuthService,
    private alertify: AlertifyService) { }

  ngOnInit() {
    console.log(this.person);
    this.initializeUploader();
    console.log(this.selectedCompany);
    this.photos = this.person.personPhotos;
    if (this.person.insuranceExpiration == null) {
      this.person.insuranceExpiration = this.dateF;
    }
    console.log(this.person.insuranceExpiration);
  }
  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  initializeUploader() {
    console.log(this.baseUrl + 'persons/' + this.person.id + '/photos');
    this.uploader = new FileUploader({
      url: this.baseUrl + 'persons/' + this.person.id + '/photos/employeephoto/' + this.selectedCompany.parentGuid,
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: ['image'],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024
    });

    this.uploader.onAfterAddingFile = (file) => {file.withCredentials = false; };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
     this.userWebsiteToReturnDto.emit(response);
   //  this.bsModalRef.hide();
    this.photos = this.person.personPhotos;
    };
  }

  setMainPhoto(photo: PersonPhoto) {
    
  }

  deletePhoto(id: number) {
    // this.alertify.confirm('DELETE', 'Are you sure you want to delete this photo?', () => {
    //   this.userService.deletePhoto(this.authService.decodedToken.nameid, id).subscribe(() => {
    //     this.photos.splice(this.photos.findIndex(p => p.id === id), 1);
    //     this.alertify.success('Photo has been deleted');
    //   }, error => {
    //     this.alertify.error('Failed to delete the photo');
    //   });
    // });
  }
  onSubmit() {
    this.update.emit(this.editForm.value);
  //  this.bsModalRef.hide();
  }
}
