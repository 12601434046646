import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';
import { AmoLoan } from 'src/app/_models/amoloans';
import { AmoPayment } from 'src/app/_models/amopayments';
import { TakePaymentDto } from 'src/app/_models/DTOS/takepaymentdto';
import { PaymentType } from 'src/app/_models/PaymentType';
import { Sale } from 'src/app/_models/sale';
import { LoanService } from 'src/app/_services/loanservieces/loan.service';
@Component({
  selector: 'app-loan-editpayment',
  templateUrl: './loan-editpayment.component.html',
  styleUrls: ['./loan-editpayment.component.scss']
})
export class LoanEditpaymentComponent implements OnInit {
  @Input() sale  = {} as Sale;
  @Input() paymentTypes = [] as PaymentType[];
  @Output() paymentEnetered = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() takePaymentDto  = {} as TakePaymentDto;
  @Input() paymentType = {} as PaymentType;
  public now: Date = new Date();
  @Input() loan: AmoLoan;
  @Input() amoPayment = { } as AmoPayment;
  constructor(public bsModalRef2: BsModalRef,
    private format: DealInforFormat,
    private takePaymentLoan: LoanService) { }

  ngOnInit() {
    console.log(this.amoPayment);
    if (this.sale) {
      this.loan = this.sale.amoLoans;
     // this.amoPayment.dueToday = this.takePaymentDto.dueToday;
      // this.amoPayment.todayLoanPaid = this.takePaymentDto.todayLoanPaid;
      // this.amoPayment.todayDeferredDown = this.takePaymentDto.todayDeferredDown;
      // this.amoPayment.todayMisc = this.takePaymentDto.todayMisc;
      // this.amoPayment.todayLateFee = this.takePaymentDto.todayLateFee;
      // this.amoPayment.totalSumPaid = this.takePaymentDto.sumPaidToday;
      // this.amoPayment.paymentTypesId = this.paymentType.id;
      // this.amoPayment.datePaid = new Date();
      this.onChange();
    }
  }

close() { this.bsModalRef2.hide(); }

paymentTypeSelected(selectedItem) {
  if (selectedItem > 0) {
    this.amoPayment.paymentTypesId = selectedItem;
  }
}
async onChange(e?) {
 // console.log(this.amoPayment);
  if (this.amoPayment !== null) {
    await this.format.formatPaymentNumber(this.amoPayment).then(async (data) => {
     // this.amoPayment = data;
      this.amoPayment.todayLoanPaid = data.todayLoanPaid;
      this.amoPayment.todayDeferredDown = data.todayDeferredDown;
      this.amoPayment.todayMisc = data.todayMisc;
      this.amoPayment.todayLateFee = data.todayLateFee;
      this.amoPayment.totalSumPaid = data.sumPaidToday;

     //   this.amoPayment.paymentTypesId =  this.amoPayment.paymentTypes.id ;

      console.log(data);
    });
  }
}
submit() {
  console.log(this.amoPayment);
  this.paymentEnetered.emit(this.amoPayment);
  this.bsModalRef2.hide();
}
updateCalcs() {
  this.takePaymentDto.datePaid = this.amoPayment.datePaid;
  this.takePaymentLoan.getPaymentDueToday(this.takePaymentDto).subscribe((data) => {
   console.log(data);
    this.amoPayment.todayLoanPaid = data.dueToday;
  }, error => { console.log(error); });
  console.log('datechange');
}
}
