<div style="margin-top: 70px;"  class="container-fluid">


  <div style="margin-top: -60px; cursor: pointer;" class="container-fluid customer-tabs-sec">
    <ul class="nav nav-tabs">
      <li routerLinkActive="active">
        <a
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('Employee')"
          >All</a
        >
      </li>
      <li routerLinkActive="active">
        <a
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('Employee')"
          >Active</a
        >
      </li>
      <li routerLinkActive="active">
        <a
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('Employee')"
          >Paid Off</a
        >
      </li>
      <li routerLinkActive="active">
        <a
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('Employee')"
          >Scheduled Due This Month</a
        >
      </li>
      <li routerLinkActive="active">
        <a
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('PastDue')"
          >Past Due</a
        >
      </li>
  
      <div class="d-flex justify-content-right">
          <pagination
        [boundaryLinks]="true"
        pageBtnClass = "nav nav-tabs"
            [(ngModel)]="pagination.currentPage"
            [totalItems]="pagination.totalItems"
            (pageChanged)="pageChanged($event)"
            [itemsPerPage]="pagination.itemsPerPage"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;"
          >
          </pagination>
        </div>
        <li >
          <a
            data-toggle="tab"
            [ngClass] = "isPaymentList ? 'active show' : ''"
            (click)="selectGrid('isPaymentList')"
            >Payment List</a
          >
        </li>
        <li>
          <a
            data-toggle="tab"
            [ngClass] = "isLoanList ? 'active show' : ''"
            (click)="selectGrid('isLoanList')"
            >Loan List</a
          >
        </li>
  </ul>  
  </div>
  <app-date-filter style="margin-top: -20px;" (calenderOutput) = "updateDates($event)" *ngIf = "isFilterDates"></app-date-filter>
  <div class="customer-form-sec">
    <div class="container-fluid">
      <div class="row">
        <div class="form-sec tab-content col-md-11">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-row account-payable-row">          
                  
                  <div class="row">
                    <div class="col-md-12 table-sec">
                      <div id="printableTable">
                      <table borderColor="#ccc" style="background-color: #e4e8ec; overflow: auto !important;" >
                        <tr>
                          <th >ID</th>
                          <th (click)="sort('isLoanId')">Loan Id &nbsp;
                            <i [ngClass]="isLoanId ? 'up': 'down'"></i>
                        </th>
                        <th (click)="sort('isSaleId')">Sale Id &nbsp;
                          <i [ngClass]="isSaleId ? 'up': 'down'"></i>
                      </th>
                             <th>Pmt #</th>
                             <th></th>
                          <th (click)="sort('isDatePaid')"> Date&nbsp;
                              <i [ngClass]="isDatePaid ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('isTodayNonAmortized')">Paid &nbsp;
                            <i [ngClass]="isTodayNonAmortized ? 'up': 'down'"></i>
                        </th>
                          <th (click)="sort('isTodayInterest')">Interest &nbsp;
                            <i [ngClass]="isTodayInterest ? 'up': 'down'"></i>
                        </th>
                          <th  (click)="sort('isTodayPrinciple')">Principle&nbsp;
                            <i [ngClass]="isTodayPrinciple ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('isTodayLateFee')">Late Fee&nbsp;
                              <i [ngClass]="isTodayLateFee ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('isTodayMisc')">Misc&nbsp;
                              <i [ngClass]="isTodayMisc ? 'up': 'down'"></i>
                          </th>
                         
                          <th (click)="sort('isTodayDeferred')"> Deferred&nbsp;
                            <i [ngClass]="isTodayDeferred ? 'up': 'down'"></i>
                        </th>
                          <th (click)="sort('isBuyer')">Buyer&nbsp;
                            <i [ngClass]="isBuyer ? 'up': 'down'"></i>
                        </th>
                          <th (click)="sort('isVin')">Vin&nbsp;
                            <i [ngClass]="isVin ? 'up': 'down'"></i>
                        </th>
                          <th (click)="sort('isVehicle')">Vehicle&nbsp;
                              <i 
                              [ngClass]="isVehicle ? 'up': 'down'"></i>
                          </th>
                         
                          <th (click)="sort('isPrincipleBalance')">Payoff&nbsp;
                            <i [ngClass]="isPrincipleBalance ? 'up': 'down'"></i>
                        </th>
                          <th></th>
                        </tr>
                        <tr  [hidden]="isPrint">
                          <td ></td>
                            <td style="width: 76px"></td>
                            <td style="width: 76px"></td>
                         
                            
                            <td></td>  
                          <td></td>
                     
                          <td ></td>
                          <td ></td>
                          <td ><input style="width: 50px;" (keyup)="setSearchBy('searchInvoiceNumber', $event.target.value)" >
                          <td ><input style="width: 50px;" (keyup)="setSearchBy('searchCustomer', $event.target.value)" >
                          </td>  
                          <td style="width: 40px;"><input style="width: 40px;" (keyup)="setSearchBy('searchPhone', $event.target.value)">
                          </td>  
                          <td></td>  
                          <td></td>  
                          <td></td>  
                          <td></td>  
                          <td></td>  
                          <td></td>  
                          <td></td>  
                        </tr>
                        <ng-container   *ngFor="let item of payments; let i=index; last as isLast" > 
                        <tr  align="right" class="td-row" (click)="setAddPaymentButton(item)" (dblclick) = "dueTodayLoad(item) ">
                          <td style="width: 76px" align="right" *ngIf="i != payments.length - 1" style="cursor: pointer; width: 76px;" 
                          (click)="dueTodayLoad(item)"><i style="color: rgb(0, 184, 0);" class="fa fa-credit-card">&nbsp;&nbsp;</i></td>
                        <td align="left" *ngIf="i != payments.length - 1">{{ item.id }}</td>
                        <td align="left" *ngIf="i != payments.length - 1">{{ item?.amoLoandId }}</td>
                        <td align="left" *ngIf="i != payments.length - 1">{{ item?.invoiceNumber }}</td>
                        <td align="left" *ngIf="i != payments.length - 1">{{ item?.paymentNumber }}</td>
                          <td align="left" *ngIf="i != payments.length - 1">{{ item?.datePaid | date: "shortDate" }}</td>
                          <td align="right" *ngIf="i != payments.length - 1">{{ item?.todayLoanPaid | number:'1.2-2'}}</td>
                          <td align="right" *ngIf="i != payments.length - 1">{{ item?.todayInterest | number:'1.2-2'}}</td>
                          <td align="right" *ngIf="i != payments.length - 1">{{ item?.todayPrinciple | number:'1.2-2'}}</td>
                          <td align="right"  *ngIf="i != payments.length - 1">{{ item?.todayLateFee | number:'1.2-2'}}</td>
                          <td *ngIf="i != payments.length - 1">{{ item?.todayMisc  | number:'1.2-2'}}</td>
                          <td align="right" *ngIf="i != payments.length - 1">{{ item?.todayDeferredDown | number:'1.2-2'}}</td>
                          <!-- {{ item?.nextDueDate | date: "shortDate"}} -->
                          <td *ngIf="i != payments.length - 1">{{ item?.buyerName }}</td>
                          <td *ngIf="i != payments.length - 1">{{ item?.vin  }}</td>
                          <td *ngIf="i != payments.length - 1">{{ item?.vehicle  }}</td>
                          <td align="right"  *ngIf="i != payments.length - 1">{{ item?.principleBalance | number:'1.2-2'}}</td>
                          <td  (click)="deletePayment(item)" align="right" *ngIf="i != payments.length - 1" style="cursor: pointer;" ><i *appHasRole="['Admin']"  style="color: rgb(248, 4, 4);" class="fa fa-trash"></i> </td>
                          <!-- <td *ngIf="i != loans.length - 1" [hidden]="isPrint" colspan="3">
                            <button *ngIf="item?.payoffToday > 0"
                              class="btn btn-outline-success"
                              (click)="loanDetails(item)"
                            >
                               Payment
                            </button>
                          </td> -->
                        </tr>
                         
                        </ng-container>
  
                        <tr  align="right" class="border_top" *ngFor="let item of payments; let i=index; last as isLast">
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0">{{ item?.paymentNumber }}</td>
                          <td *ngIf="isLast && i !==0">{{ item?.todayPrinciple | number:'1.2-2'}}</td>
                          <td *ngIf="isLast && i !==0">{{ item?.todayLateFee | number:'1.2-2'}}</td>
                          <td *ngIf="isLast && i !==0">{{ item?.todayMisc  | number:'1.2-2'}}</td>
                          <td *ngIf="isLast && i !==0">{{ item?.todayDeferredDown | number:'1.2-2'}}</td>
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0"></td>
                         
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0"></td>
                          <td *ngIf="isLast && i !==0"></td>
                      </tr>
                      </table>
                      </div>
                    </div>
                    <!-- /.table-sec -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.row -->
          </div>
        </div>
        <div class="customer-sidebar sidebar-with-btns col-md-1">
          <div class="inner-sidebar">
            <ul>
              <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->
              <!-- <li>
                <button class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-1.png" />
                  <span>Add Vehicle</span>
                </button>
              </li> -->
              <li *appHasRole="['Admin']">
                <button class="cstm-btn img-blk-btn" (click) = "exportAsXLSX()">
                  <img src="assets/images/icons8-microsoft-excel-48.png" />
                  <span>Export Excel</span> 
                </button>
              </li>
              <li>
                <button (click) = "printDiv()" class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-6.png" />
                  <span>Print Report</span>
                </button>
              </li>

              <li>
                <button (click)="addPayment()" *ngIf="isAddPayment" class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-1.png">
                  <span>Add Payment</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>