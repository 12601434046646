import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Sale } from 'src/app/_models/sale';
import { SaleNotes } from 'src/app/_models/SaleNote';


@Component({
  selector: 'app-editsalenote-modal',
  templateUrl: './editsalenote-modal.component.html',
  styleUrls: ['./editsalenote-modal.component.css']
})
export class EditsalenoteModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  sale: Sale;
  amount: number;
  dateTime = new Date();
  saleNotes = {} as SaleNotes;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log(this.saleNotes);
    this.validateDates();
  }
  validateDates() {
    if (!this.saleNotes.remindDate ) {
      this.saleNotes.remindDate = new Date();
    }
    if (!this.saleNotes.createdDate ) {
      this.saleNotes.createdDate = new Date();
    }
  }
  updateGap() {
    this.emittedValues.emit(this.saleNotes);
    this.bsModalRef.hide();
  }
  onChange(e) { }
}

