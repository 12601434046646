import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activedeleted-orallmenu',
  templateUrl: './activedeleted-orallmenu.component.html',
  styleUrls: ['./activedeleted-orallmenu.component.css']
})
export class ActivedeletedOrallmenuComponent implements OnInit {
  isNotes: boolean;
  isMiscCharges: boolean;
  isWashout: boolean;
  constructor() { }

  ngOnInit() {
  }
  tabClick(e) {

  }
}
