import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MenuReturnWebsiteMenuParagraphDto } from 'src/app/_models/DTOS/WebsiteMenuParagraphReturndto';
import { WebsiteMenu } from 'src/app/_models/websites/WebsiteMenu';
import { WebsiteMenuParagraph } from 'src/app/_models/websites/WebsiteMenuParagraph ';
import { Website } from 'src/app/_models/websites/websites';


@Component({
  selector: 'app-websitemenu-contentlist',
  templateUrl: './websitemenu-contentlist.component.html',
  styleUrls: ['./websitemenu-contentlist.component.scss']
})
export class WebsitemenuContentlistComponent implements OnInit {
  @Output()  websiteChanged2: EventEmitter<MenuReturnWebsiteMenuParagraphDto> = new EventEmitter();
  @Input() websiteMenuParagrahps = [] as WebsiteMenuParagraph[];

  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Input() website = null as Website;
  @Input()  websiteMenu = {} as WebsiteMenu;
  websiteMenuParagrahp = {} as WebsiteMenuParagraph;
  selectedIndex = 0;
  selectedparagraph = {} as any;
  selectedSaleType: any;
  constructor() { }

  ngOnInit() {
 if (this.websiteMenuParagrahps.length > 0) {
   this.websiteMenuParagrahp = this.websiteMenuParagrahps[0];
 }
  }
  setIndex(index: number, item: WebsiteMenuParagraph) {
    console.log(item);
    console.log(this.websiteMenuParagrahp);
    this.selectedIndex = index;
    this.websiteMenuParagrahp = item;
 }
 updateWebsite(e: MenuReturnWebsiteMenuParagraphDto) {
   console.log(e);
   console.log('parent reached');
   this.website = e.website;
   this.websiteChanged2.emit(e);
   this.websiteMenuParagrahps = e.websiteMenu.websiteMenuParagraphs;


 }
 cancelUpdate() { this.cancel.emit();  console.log('chaged reached');}
 changeParagraph( item) {
  console.log('chaged reached');
  console.log(item);
 console.log(this.websiteMenuParagrahp);
  console.log(this.selectedparagraph);
  // this.selectedparagraph = e;
  this.websiteMenuParagrahp = item;
 // this.selectedIndex = -1;
}
}
