<div class="customer-form-sec">
  <div class="container-fluid">
    <br><br>
    <div class="row">
          <div class="form-sec tab-content col-md-12">
            <div id="depreciate-costs" class="tab-pane fade in active show">
              <div class="row">
                <div class="col-md-12">
                  <div class="inner-row account-payable-row"> 

<div *ngIf="!paidFor">
  <h1>Buy this Subscription
  </h1>
  <p>{{amount  | number: "1.2"}}</p>
  <p>{{description }}</p>
</div>
<div *ngIf="paidFor">
  <p> congragulations!</p>
  </div>
  <div #paypal></div>

  </div></div></div></div></div></div></div></div>
