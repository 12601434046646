import { Component, ElementRef, OnInit, Output, ViewChild } from '@angular/core';
import {ClientSideRowModelModule, CsvExportModule, GridOptions, Module} from '@ag-grid-community/all-modules';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { ChartInvoiceTransactionGridTOReturnDto } from 'src/app/_models/DTOS/ChartInvoiceTransactionGridTOReturnDto';
import { AuthService } from 'src/app/_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ExcelService } from 'src/app/_services/exportservices/excel.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';
import { ToastrService } from 'ngx-toastr';
import { Sale } from 'src/app/_models/sale';
import { UserParams } from 'src/app/_models/userParams';
import { CartesianChart } from 'ag-charts-community';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { AmoLoan } from 'src/app/_models/amoloans';
import { PersonToLinqResultToReturnDto } from 'src/app/_classes/persontodto/PersonToLinqResultToReturnDto';
@Component({
  selector: 'app-sale-grid',
  templateUrl: './sale-grid.component.html',
  styleUrls: ['./sale-grid.component.css']
})
export class SaleGridComponent implements OnInit {
  selectedCompany1: UserAssignedToCompany;
  personToSendLoan = {} as PersonLinqResultToReturnDto;
  isDeleted = false;
  isActive = true;
  sales: Sale[];
  loans: AmoLoan[];
  pagination = {} as Pagination;
  isDateAscending = true;
  isCustomerAscending = true;
  isIdAscending = true;
  isLoanAmountAscending = true;
  isSaleTypeAscending = true;
  isSaleSTatusAscending = true;
  isVehicleAscending = true;
  isInvoiceAscending = true;
  isAscending = true;
  userParams = {} as any;
  showDates = false;
  selectType;
  selectStatus;
  isAllSales = false;
  isSalesTax = false;
  isSaleJournal = false;
  isVehicleSoldList = false;
  isPrint = false;
  isExpandedSearch = false;
  a: any;
  b: any;
  isLoanAmount = true;
  // chartInvoiceStatuses: SaleStatus[];
  // chartInvoiceSaleTypes: SaleType[];

  chartInvoiceStatuses: any[];
  chartInvoiceSaleTypes: any[];
  selectedOption: string;
  @ViewChild('search', {static: false}) public searchTerm: ElementRef<HTMLInputElement>;
public selectedOptionList =  [
  {name: 'Stock', value: 'stock'},
  {name: 'Account', value: 'acct'},
  {name: 'Make', value: 'make'},
{name: 'Model', value: 'model'},
{name: 'Vin', value: 'vin'},
{name: 'First Name', value: 'firstName'},
{name: 'Last Name', value: 'lastName'},
{name: 'Address', value: 'address'},
{name: 'Phone', value: 'phone'},
];

  public gridOptions: GridOptions;
    public rowData: any[];
    public columnDefs: any[];
     AgGrid = require('ag-grid-community');
 //   public modules: Module[] = ClientSideRowModelModule;
constructor(private router: Router,
  private authService: AuthService,
  private route: ActivatedRoute,
  private convertPerson: PersonToLinqResultToReturnDto,
  private excelService: ExcelService,
  private alertify: ToastrService,
  private saleService: SaleService,
  private confirmService: ConfirmService
  ) {

    }
    ngOnInit() {
      this.isAllSales = true;
      this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
      this.route.data.subscribe(data => {
        this.sales = data.sales.result;
        this.pagination = data.sales.pagination;
        this.chartInvoiceStatuses = data.chartInvoiceStatuses;
        this.chartInvoiceSaleTypes = data.chartInvoiceSaleTypes;
        this.isGridClick(data.sales.result);
      });

   //   console.log(this.sales);
    }

    changeValue(e) { console.log(e); this.userParams.searchBy = e; }
    onSearch(): void {
      this.userParams.searchFor = this.searchTerm.nativeElement.value;
      this.userParams.pageNumber = 1;
      this.loadInvoices();
     
    }
    onResetSearch(): void {
      this.searchTerm.nativeElement.value = '';
      this.userParams.searchFor = undefined;
      this.isExpandedSearch = false;
      this.userParams.isDeleted = 'isAll';
      this.loadInvoices();
    }
    isChildActiveDeletedClick(isDeleted) {
      console.log(isDeleted);
      if (isDeleted === 'isDeleted') {
        this.userParams.isDeleted = 'isDeleted';   this.userParams.searchFor = undefined;
      } else { this.userParams.isDeleted = 'isActive';    this.userParams.searchFor = undefined; }
      if (isDeleted === 'isAll') { this.userParams.isDeleted = 'isAll';   this.userParams.searchFor = undefined; }

      if (isDeleted === 'isExpandSearch') {
         if (!this.isExpandedSearch) {
           this.isExpandedSearch = true;
           this.userParams.isDeleted = 'isExpandSearch';
          } else {
            this.onResetSearch();
          }
      }
        else {
        }
      this.loadInvoices();
    }
    delete(e) {
      this.confirmService.confirm('Confirm delete message', 'Are you sure?').subscribe(result => {
        if (result) {
          this.saleService.deleteSAle(this.selectedCompany1.parentGuid, e.id).subscribe(data => {},
            err => { console.log(err); }, () => { this.loadInvoices(); });
        }
      });
    }
    SortBy(field, reverse, primer) {
      // tslint:disable-next-line:prefer-const
      let key = primer ?
      (x) => primer(x[field]) :
      (x) => x[field];
      reverse = [-1, 1][+!!reverse];
      return (a, b) => (a = key(a), b = key(b), reverse * (+(a > b) - +(b > a)));
  }

    updateDates(event) {
      console.log('new Update Reached');
      this.userParams.startDate = event.startDate;
      this.userParams.endDate = event.endDate;
      this.userParams.monthOfYear = event.month;
      this.userParams.yearForDate = event.year;
      this.userParams.isAll = event.isAll;
      this.userParams.isFullYear = event.isFullYear;
      this.userParams.isDeleted = this.isDeleted;
    //  console.log(this.userParams);
      this.loadInvoices();
    }
    selectedTypeChanged(e) {
      this.userParams.saleTypeId = '';
      if (this.selectType === 0) { this.userParams.saleTypeId = undefined; }
      if (this.selectType === null) { this.userParams.saleTypeId = undefined; }
      if (e > 0) {this.userParams.saleTypeId = e; }

      this.loadInvoices();
    }
    selectedStatusChanged(e) {
      this.userParams.saleStatusId = '';
      if (this.selectStatus === 0) { this.userParams.saleStatusId = undefined; }
      if (this.selectStatus === null) { this.userParams.saleStatusId = undefined; }
      if (e > 0) {this.userParams.saleStatusId = e; }

      this.loadInvoices();
    }
  navigateInvoice(e) {
    this.router.navigate(['/saledashboard/editsale/' + this.selectedCompany1.parentGuid + '/' +  e.id ]);
  }
  setSearchBy(e, event) {
    setTimeout(() => {
      console.log(e);
      this.userParams.searchBy = e;
      this.userParams.searchFor = event;
      this.loadInvoices();
    }, 2000);
  }
  showDatesTrue() {
    this.showDates = !this.showDates;
  }
  loadInvoices() {
    this.saleService.getSaleInvoice2(this.selectedCompany1.parentGuid, this.pagination.currentPage,
          this.pagination.itemsPerPage, this.userParams).subscribe((data: PaginatedResult<Sale[]>) => {
          this.sales = data.result;
          this.pagination = data.pagination;
        }, error => {
          this.alertify.error(error); }, () => {
            this.alertify.success('Your Sales have Loaded.');
          
          });
     //   console.log(this.sales);
    }

  sortDescription() {
  }

  pageChanged(event: any): void {
    console.log(event);
    this.pagination.currentPage = event.page;
    this.loadInvoices();
  }

  filter(e) {
    this.isAllSales = false;
    this.isSalesTax = false;
    this.isSaleJournal = false;
    this.isVehicleSoldList = false;
    this.userParams.filter = e;
    if (e === 'isAllSales') {
      this.isAllSales = true;
    }
    if (e === 'isSalesTax') {
      this.isSalesTax = true;
      this.router.navigate(['/saledashboard/advancetax/' + this.selectedCompany1.parentGuid]);
    }
    if (e === 'isSaleJournal') {
      this.isSaleJournal = true;
      this.router.navigate(['/saledashboard/salesjournal/' + this.selectedCompany1.parentGuid]);
    }
    if (e === 'isVehicleSoldList') {
      this.isVehicleSoldList = true;
    }
    this.loadInvoices();
  }
  resetAscending() {
    this.isDateAscending = true;
    this.isCustomerAscending = true;
    this.isIdAscending = true;
    this.isLoanAmountAscending = true;
    this.isSaleTypeAscending = true;
    this.isSaleSTatusAscending = true;
    this.isVehicleAscending = true;
    this.isInvoiceAscending = true;
  }
  sort(e) {
    console.log(e);
    this.isDateAscending = true;
    this.isCustomerAscending = true;
    this.isIdAscending = true;
    this.isLoanAmountAscending = true;
    this.isSaleTypeAscending = true;
    this.isSaleSTatusAscending = true;
    this.isVehicleAscending = true;
    this.isInvoiceAscending = true;
    this.isCustomerAscending = true;
    this.isAscending = !this.isAscending;
    this.userParams.isAscending = this.isAscending;
    this.userParams.itemsPerPage = 15;
    this.userParams.orderBy = e;
  //  this.loadInvoices(); item?.amountFinanced
    if (e === 'id') {this.isIdAscending = this.isAscending; this.sales.sort(this.SortBy('id', this.isAscending, parseInt)); }
    if (e === 'date') {
      this.isDateAscending = this.isAscending;
      // this.sales.sort(this.SortBy('soldDate', this.isAscending, parseInt));
      if (this.isAscending) { this.sales.sort((a, b) => +new Date(a.soldDate) - +new Date(b.soldDate)); } else { this.sales.sort((a, b) => +new Date(b.soldDate) - +new Date(a.soldDate)); }
      }
    if (e === 'invoiceNumber') { this.isInvoiceAscending = this.isAscending; this.sales.sort(this.SortBy('acct', this.isAscending, parseInt)); }
    if (e === 'customer') {this.isCustomerAscending = this.isAscending; this.sales.sort(this.SortBy('lastName', this.isAscending, parseInt)); }
    if (e === 'loanAmount') {this.isLoanAmountAscending = this.isAscending; console.log(this.sales.sort(this.SortBy('amountFinanced', this.isAscending, parseInt))); }
    if (e === 'saleType') {
      this.isSaleTypeAscending = this.isAscending;
      console.log(this.sales.sort(this.SortBy('saleTypesId', this.isAscending, parseInt))); }
    if (e === 'saleStatus') {this.isSaleSTatusAscending = this.isAscending; this.sales.sort(this.SortBy('saleStatusId', this.isAscending, parseInt)); }
    if (e === 'personType') {this.isCustomerAscending = this.isAscending; this.sales.sort(this.SortBy('purchasedVehicleId', this.isAscending, parseInt)); }
    if (e === 'vehicle') {this.isVehicleAscending = this.isAscending; }

  }
  exportAsXLSX(): void {
    this.pagination.itemsPerPage = 1000000;
   this.saleService.getSaleInvoice2(this.selectedCompany1.parentGuid, this.pagination.currentPage,
     this.pagination.itemsPerPage, this.userParams)
    .subscribe((data: PaginatedResult <any[]>) => {
      this.excelService.exportAsExcelFile(data.result, 'Sales Recieved');
  //    this.pagination = data.pagination;
      this.pagination.itemsPerPage = 15;
    }, error => { this.alertify.error(error); }, () => { this.alertify.success('Your Export is Ready'); } );
  }
  async printDiv() {
    await this.hideDiv().then(() => {
     setTimeout(() => {
       this.print();
     }, 2000);
      });
   }
   async hideDiv() {
     this.isPrint = true;
   }
   async print() {
     // tslint:disable-next-line:no-string-literal
     window.frames['print_frame'].document.body.innerHTML = document.getElementById('printableTable').innerHTML;
     // tslint:disable-next-line:no-string-literal
     window.frames['print_frame'].window.focus();
     // tslint:disable-next-line:no-string-literal
     window.frames['print_frame'].window.print();
     this.isPrint = false;
   }
   isGridClick(e: Sale) {
     if (e.amoLoans?.id != null) {
       this.personToSendLoan.id = e.id;
      this.convertPerson.convertPersonToLinq(e.buyer, e).then(data => {
        this.personToSendLoan = data;
        console.log(data);
        this.authService.setIsCurrentPerson(data);
      });
     } else { this.authService.setIsCurrentPerson(null); }
     // tslint:disable-next-line:prefer-for-of
     for (let i = 0; i < this.sales.length; i++) {
      if (e === this.sales[i]) { e.selected = true; }
      else {this.sales[i].selected = false; }
    }
   e.selected = true;
   console.log(e.selected);
   }
  }

  // ngOnInit() {
  // //   this.gridOptions = ({
  // //     onGridReady: () => {
  // //         this.gridOptions.api.sizeColumnsToFit();
  // //     },
  // //     defaultColDef: {
  // //       filter: true // set filtering on for all columns
  // //   },
  // // } as GridOptions);
  // // this.columnDefs = [
  // //     {headerName: 'Make', field: 'make'},
  // //     {headerName: 'Model', field: 'model'},
  // //     {headerName: 'Price', field: 'price'}
  // // ];
  // // this.rowData = [
  // //     {make: 'Toyota', model: 'Celica', price: 35000},
  // //     {make: 'Ford', model: 'Mondeo', price: 32000},
  // //     {make: 'Porsche', model: 'Boxter', price: 72000},
  // //     {make: 'Toyota', model: 'Celica', price: 35000},
  // //     {make: 'Ford', model: 'Mondeo', price: 32000},
  // //     {make: 'Porsche', model: 'Boxter', price: 72000},
  // //     {make: 'Toyota', model: 'Celica', price: 35000},
  // //     {make: 'Ford', model: 'Mondeo', price: 32000},
  // //     {make: 'Porsche', model: 'Boxter', price: 72000},
  // //     {make: 'Toyota', model: 'Celica', price: 35000},
  // //     {make: 'Ford', model: 'Mondeo', price: 32000},
  // //     {make: 'Porsche', model: 'Boxter', price: 72000},
  // // ];
  // // }
  // }

// }

