<br /><br />
<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <!-- style="background-color:#DBE9F4 !important;" -->
      <div class="form-sec tab-content col-md-12">
        <div class="tab-panel">
          <tabset class="member-tabset">
            <tab heading="Edit Paragraphs ">
              <div class="card" style="background-color: #DBE9F4 !important">
                <div class="col-sm-11">
                  <div *ngIf="editForm.dirty" class="alert alert-info">
                    <strong>Information:</strong> You have made changes. Any
                    unsaved changes will be lost!
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-10">
                    <form #editForm="ngForm" submit="onSubmit()" id="editForm">
                      <!------------------------------------------------------------------------PARAGRAPH 1-->
                      <div class="col-md-12 cstm-col">
                        <div class="inline-blk"
                        [ngClass]="{
                          'has-success': description1?.valid && (description1?.touched || description1?.dirty),
                          'has-danger': description1?.invalid && (description1?.touched || description1?.dirty)
                        }">
                          <label>Paragraph 1</label>
                          <textarea rows="9" cols="90"  value=""
                          type="text"
                          autocomplete="off"
                          [ngModel]="companyInfo?.description1"
                          (ngModel)="companyInfo.description1= $event"
                          (ngModelChange)="companyInfo.description1= $event"
                          name="description1"
                          #description1="ngModel"
                          required></textarea>
                        </div>
                        <div
                        *ngIf="description1.errors && (description1?.touched || description1?.dirty)">
                        <p *ngIf="description1?.errors?.required">Description Is Required</p>
                      </div>
                      </div>
                      <!------------------------------------------------------------------------PARAGRAPH 2-->
                      <div class="col-md-12 cstm-col">
                        <div class="inline-blk"
                        [ngClass]="{
                          'has-success': description2?.valid && (description2?.touched || description2?.dirty),
                          'has-danger': description2?.invalid && (description2?.touched || description2?.dirty)
                        }">
                          <label>Paragraph 2</label>
                          <textarea rows="9" cols="90"  value=""
                          type="text"
                          autocomplete="off"
                          [ngModel]="companyInfo?.description2"
                          (ngModel)="companyInfo.description2= $event"
                          (ngModelChange)="companyInfo.description2= $event"
                          name="description2"
                          #description2="ngModel"
                          required></textarea>
                        </div>
                      
                      </div>


                      <!------------------------------------------------------------------------PARAGRAPH 3-->
                      <div class="col-md-12 cstm-col">
                        <div class="inline-blk"
                        [ngClass]="{
                          'has-success': description3?.valid && (description3?.touched || description3?.dirty),
                          'has-danger': description3?.invalid && (description3?.touched || description3?.dirty)
                        }">
                          <label>Paragraph 3</label>
                          <textarea rows="9" cols="90"  value=""
                          type="text"
                          autocomplete="off"
                          [ngModel]="companyInfo?.description3"
                          (ngModel)="companyInfo.description3= $event"
                          (ngModelChange)="companyInfo.description3= $event"
                          name="description3"
                          #description3="ngModel"
                          required></textarea>
                        </div>
                   
                      </div>


                      <!------------------------------------------------------------------------PARAGRAPH 4-->
                      <div class="col-md-12 cstm-col">
                        <div class="inline-blk"
                        [ngClass]="{
                          'has-success': description4?.valid && (description4?.touched || description4?.dirty),
                          'has-danger': description4?.invalid && (description4?.touched || description4?.dirty)
                        }">
                          <label>Paragraph 4</label>
                          <textarea rows="9" cols="90"  value=""
                          type="text"
                          autocomplete="off"
                          [ngModel]="companyInfo?.description4"
                          (ngModel)="companyInfo.description4= $event"
                          (ngModelChange)="companyInfo.description4= $event"
                          name="description4"
                          #description4="ngModel"
                          required></textarea>
                        </div>
                       
                      </div>


                      <!------------------------------------------------------------------------PARAGRAPH 5-->
                      <div class="col-md-12 cstm-col">
                        <div class="inline-blk"
                        [ngClass]="{
                          'has-success': description5?.valid && (description5?.touched || description5?.dirty),
                          'has-danger': description5?.invalid && (description5?.touched || description5?.dirty)
                        }">
                          <label>Paragraph 5</label>
                          <textarea rows="9" cols="90"  value=""
                          type="text"
                          autocomplete="off"
                          [ngModel]="companyInfo?.description5"
                          (ngModel)="companyInfo.description5= $event"
                          (ngModelChange)="companyInfo.description5= $event"
                          name="description5"
                          #description5="ngModel"
                          required></textarea>
                        </div>
                        
                      </div>


                      <!------------------------------------------------------------------------PARAGRAPH 6-->
                      <div class="col-md-12 cstm-col">
                        <div class="inline-blk"
                        [ngClass]="{
                          'has-success': description6?.valid && (description6?.touched || description6?.dirty),
                          'has-danger': description6?.invalid && (description6?.touched || description6?.dirty)
                        }">
                          <label>Paragraph 6</label>
                          <textarea rows="9" cols="90"  value=""
                          type="text"
                          autocomplete="off"
                          [ngModel]="companyInfo?.description6"
                          (ngModel)="companyInfo.description6= $event"
                          (ngModelChange)="companyInfo.description6= $event"
                          name="description6"
                          #description6="ngModel"
                          required></textarea>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div class="customer-sidebar sidebar-with-btns col-md-1 mr-2">
                    <div class="inner-sidebar">
                      <ul>
                        <li *ngIf="editForm.dirty">
                          <button
                            (click)="onSubmit(editForm)"
                            class="cstm-btn img-blk-btn"
                          >
                            <img src="assets/images/ap-icon-1.png" />
                            <span>Save</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            </tabset>
            </div>
            </div>
            </div>
            </div>
            </div>
            

