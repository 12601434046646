import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SaleStatusesResolver } from '../_resolvers/chart-invoice-statuses.resolver';
import { FormNameListResolver } from '../_resolvers/form-name-list.resolver';
import { FormNameLabelListResolver } from '../_resolvers/FormName-label-list.resolver';
import { PaymentTypeResolver } from '../_resolvers/payment-type.resolver';
import { QbExpenseAccountsResolver } from '../_resolvers/qb-expenseaccounts.resolver';
import { QbDefaultsInventoriedAssetsResolver } from '../_resolvers/qb-InventoriedAssetAccounts.resolver';
import { QbDefaultsResolver } from '../_resolvers/qb-qbsaledefaults.resolver';
import { SaleTypesResolver } from '../_resolvers/saletypes.resolver';
import { VehicleBodyClassTypesResolver } from '../_resolvers/vehicle-bodytype-categories.resolver';
import { VehicleMileageCodesResolver } from '../_resolvers/vehicle-mileagecodes.resolver';
import { ChartInvoiceSaleTypesListComponent } from './chart-invoice-sale-types-list/chart-invoice-sale-types-list.component';
import { ChartInvoiceStatusListComponent } from './chart-invoice-status-list/chart-invoice-status-list.component';
import { EditFormLabelsComponent } from './labels/edit-form-labels/edit-form-labels.component';
import { FormnameGridComponent } from './labels/formname-grid/formname-grid.component';
import { PaymenttypeGridComponent } from './payments/paymenttype-grid/paymenttype-grid.component';
import { VehicleBodyTypeListComponent } from './vehicle-body-type-list/vehicle-body-type-list.component';
import { VehicleMileageCodesListComponent } from './vehicle-mileage-codes-list/vehicle-mileage-codes-list.component';

const routes: Routes = [
    // {path: 'vehiclecharts/:parrentGuid', component: InventoryGridComponent},
    {path: 'formnamelabellist/:parentGuid/:formName', component: EditFormLabelsComponent,  resolve: {
        formLabelNames: FormNameLabelListResolver
    }},
    {path: 'formnamelist/:parentGuid', component: FormnameGridComponent,  resolve: {
        formNames: FormNameListResolver
    }},

    {path: 'status/:parentGuid', component: ChartInvoiceStatusListComponent, resolve: {
        chartInvoiceStatus: SaleStatusesResolver,
    }},
    {path: 'vehiclebodytypes/:parentGuid', component: VehicleBodyTypeListComponent, resolve: {
        vehicleBodyTypes: VehicleBodyClassTypesResolver,
    }},
    {path: 'vehiclemileagecodes/:parentGuid', component: VehicleMileageCodesListComponent,  resolve: {
        mileageCodes: VehicleMileageCodesResolver,
    }},
    {path: 'paymenttypegrid/:parentGuid', component: PaymenttypeGridComponent,  resolve: {
        chartPaymentTypes: PaymentTypeResolver,
    }},
    {path: 'saletypes/:parentGuid', component: ChartInvoiceSaleTypesListComponent, resolve: {
        chartInvoiceSaleTypes: SaleTypesResolver,
    }},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ListRoutingModule {}

// {path: 'productlist/aging/:parentGuid', component: AgingListComponent,
// resolve: {chartItems: VehicleGridResolver, bodyTypes: VehicleBodyClassTypesResolver
// }},
