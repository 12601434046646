
	<!-- <div class="customer-form-sec">
		<div class="container-fluid">
			<div class="row"> -->
        <!-- <div class="container-fluid customer-tabs-sec">
          <ul class="nav nav-tabs">
            <li class="active"><a data-toggle="tab" class="active show" href="#deal-info">Deal Info</a></li>
            <li><a data-toggle="tab" href="javascript:void()">Loan</a></li>
            <li><a data-toggle="tab" href="#customer">Customer</a></li>
            <li><a data-toggle="tab" href="#vehicle">Vehicle</a></li>
            <li><a data-toggle="tab" href="#misc-info">Misc Info</a></li>
            <li><a data-toggle="tab" href="#notes">Notes</a></li>
            <li><a data-toggle="tab" href="#recap-washout">Recap/Washout</a></li>
          </ul>
        </div> -->
    

<div class="customer-form-sec deal-info-form-sec" [hidden]="!isMathDone">
		<div class="container-fluid">
			<br><br>
			<div class="row">
				<div class="form-sec tab-content col-md-11">
					
					<div id="deal-info" class="tab-pane fade in active show">
						<form  id="dealInfoForm"
						#dealInfoForm="ngForm"
						(ngSubmit)="update()">

						<div class="row">
							<div class="col-md-4 left-side-deal-info">

								<div class="form-row">
									<!-- <select class="cstm-select" name="saleDefaults" [(ngModel)]="sale.saleDefaults">
										<option *ngFor="let x of saleDefaults" [ngValue]="x?.id" [selected]="sale.saleDefaults.id == x.id">{{x.name}}</option>
									</select> -->
								</div>
								<div class="form-row  ">
									<label  class="vehicle-normallabel">Selling Price</label>
									<input   name="sellingPrice"
									[ngModel]="sale?.sellingPrice || 0.00"
									(ngModel)="sale.sellingPrice = $event"
									(ngModelChange) = "sale.sellingPrice = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"   class="cstm-input ">
									 
								</div>
								
								<div class="form-row">
									<label class="vehicle-customlabel" (dblclick)="toggleValue2('Predelivery')" >{{labelPredelivery}}</label>
									<input #predelivery = "ngModel"  name="predelivery" 
									[ngModel]="sale?.predelivery || 0.00"
									(ngModel)="sale.predelivery = $event"
									(ngModelChange) = "sale.predelivery = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label class="vehicle-customlabel" (dblclick)="toggleValue2('Title')" >{{labelTitle}}</label>
									<input  name="title" [ngModel]="sale?.title || 0.00"
									(ngModel)="sale.title = $event"
									(ngModelChange) = "sale.title = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label class="vehicle-customlabel " (dblclick)="toggleValue2('TagSale')" >{{labelTag}}</label>
									<input  name="tag"  [ngModel]="sale?.tag || 0.00"
									(ngModel)="sale.tag = $event"
									(ngModelChange) = "sale.tag = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label class="vehicle-customlabel" (dblclick)="toggleValue2('Registration')" >{{labelRegistration}}</label>
									<input  name="registration"   [ngModel]="sale?.registration || 0.00"
									(ngModel)="sale.registration = $event"
									(ngModelChange) = "sale.registration = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div  class="form-row">
									<label  class="vehicle-customlabel" (dblclick)="toggleValue2('Extra 1')" >{{labelExtra1}}</label>
									<input  name="smogC1"  [ngModel]="sale?.smogC1 || 0.00"
									(ngModel)="sale.smogC1 = $event"
									(ngModelChange) = "sale.smogC1 = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div   class="form-row">
									<label  class="vehicle-customlabel" (dblclick)="toggleValue2('Extra 2')" >{{labelExtra2}}</label>
									<input  name="miscTaxable" [ngModel]="sale?.miscTaxable || 0.00"
									(ngModel)="sale.miscTaxable = $event"
									(ngModelChange) = "sale.miscTaxable = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label  class="vehicle-customlabel" (dblclick)="toggleValue2('Gap')" >{{labelGap}}</label>
									<input  name="miscNonTax" [ngModel]="sale?.miscNonTax || 0.00"
									(ngModel)="sale.miscNonTax = $event"
									(ngModelChange) = "sale.miscNonTax = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
									<button tabindex="-1" class="cstm-btn" (click) = "oppenGapeModal()">GAP</button>
								</div>
								<div class="form-row">
									<label  class="vehicle-normallabel">Trade Allow</label>
									<input  style="color: #0fbb51 !important;" name="tradeAllow" [ngModel]="sale?.tradeAllow || 0.00"
									(ngModel)="sale.tradeAllow = $event"
									(ngModelChange) = "sale.tradeAllow = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label  class="vehicle-normallabel">Trade Lien</label>
									<input  style="color: #0fbb51 !important;" name="tradeLien" [ngModel]="sale?.tradeLien || 0.00"
									(ngModel)="sale.tradeLien = $event"
									(ngModelChange) = "sale.tradeLien = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
									 
									  <!-- <select #selectedCategory ngControl="selectedCategory" [(ngModel)]="item.itemTypeId" name="itemTypeId" (change)="getSubItemType(selectedCategory.value)" style="width: 100px"
									  id="select_item_type_1" class="form-control col-md-5 bg-c-gray">
									  <option [value]="0">Select an Item Type</option>
									  <option *ngFor="let itemType of itemTypes" [value]="itemType.itemTypeId">{{itemType.name}}</option>
									</select> -->
										<select tabindex="-1" class="cstm-select" #lienHolder="ngModel" ngControl="selectedCategory"  name="lienHolderId"    [(ngModel)] ="sale.lienHolderId"  
										(ngModelChange)="lienHolderSelected($event)" >
										<option style="color: #bb400f;" [value]="-1">None</option>
										<option style="color: #0386c3;" [value]="0">Add New Lienholder</option>
										  <option *ngFor="let item of lienHolders; index as i"   [value]="item.id">{{item?.name}}</option>
										</select>
										<div *ngIf = "sale.lienHolderId != null" class="input-group-prepend">
											<button tabindex="-1" (click) = "popupLienHolderEdit()" class="btn"><i class="fa fa-edit"></i></button>
										  </div>
										
									
									<!-- <button class="cstm-btn">Lien Holder</button> -->
								</div>
								<div class="form-row">
									<label  class="vehicle-normallabel">Warranty</label>
									<input  name="warranty" [ngModel]="sale?.warranty || 0.00"
									(ngModel)="sale.warranty = $event"
									(ngModelChange) = "sale.warranty = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
									  <select tabindex="-1" class="cstm-select" #lienHolder="ngModel" ngControl="selectedWarranty"  name="warrantyCompanyId"    [(ngModel)] ="sale.warrantyCompanyId"  
									  (ngModelChange)="warrantyComanySelected($event)" >
									  <option style="color: #bb400f;" [value]="-1">None</option>
										<option style="color: #0386c3;" [value]="0">Add New Lienholder</option>
										<option *ngFor="let item of warrantyCompanies; index as i"   [value]="item.id">{{item?.name}}</option>
									  </select>
									  <div *ngIf = "sale.warrantyCompanyId != null" class="input-group-prepend">
										  <button tabindex="-1" (click) = "popupWarrantyEdit()" class="btn"><i class="fa fa-edit"></i></button>
										</div>
								</div>
								<div [hidden] = "true" class="form-row">
									<label  class="vehicle-normallabel">Credit Life</label>
									<input  name="creditLife"  [ngModel]="sale?.creditLife || 0.00"
									(ngModel)="sale.creditLife = $event"
									(ngModelChange) = "sale.creditLife = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div [hidden] = "true" class="form-row">
									<label  class="vehicle-normallabel">Disability</label>
									<input  name="disability" 
									[ngModel]="sale?.disability || 0.00"
									(ngModel)="sale.disability = $event"
									(ngModelChange) = "sale.disability = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label  class="vehicle-normallabel">Cash Down</label>
									<input  style="color: #0fbb51 !important;" name="cashDown" [ngModel]="sale?.cashDown || 0.00"
									(ngModel)="sale.cashDown = $event"
									(ngModelChange) = "sale.cashDown = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
									  <select tabindex="-1" class="cstm-select" 
									  #paymentType="ngModel"  
									  name="downPaymentMethodsId"  
									  [(ngModel)]="sale.downPaymentMethodsId"
									 
									  (ngModelChange)="paymentTypeSelected($event)" >
									  <option style="color: #bb400f;" [value]="-1">None</option>
									  <option style="color: #0386c3;" [value]="0">Add New</option>
										<option *ngFor="let item of paymentTypes"     [ngValue]="item.id">{{item?.value}}</option>
									  </select>
									  <div *ngIf = "sale.downPaymentMethodsId != null" class="input-group-prepend">
										<button tabindex="-1" (click) = "popupEditPaymentType()" class="btn"><i class="fa fa-edit"></i></button>
									  </div>
								</div>
								<div class="form-row">
									<!-- <label  class="vehicle-normallabel">
										Doc Fees
										<input 
										 name="title" 
										 [checked] = "sale?.docAuto"
										 [ngModel]="sale?.docAuto"
										(ngModelChange) = "sale.docAuto = $event; "
										 type="checkbox" #docAuto = "ngModel" name="docAuto">
									</label> -->
									<label>Doc Fees</label>
									<input  name="docFees" [ngModel]="sale?.docFees || 0.00"
									(ngModel)="sale.docFees = $event"
									(ngModelChange) = "sale.docFees = $event;"
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
									<button tabindex="-1" class="cstm-btn">ROLL BACK</button>
								</div>
							</div>
							<div class="col-md-3 right-side-deal-info">
								<div class="pickup-sec">
									<h2 class="pick-title">Pickup Or Deferred Payments</h2>
									<table>
										<tr>
											<th></th>
											<th>Due Date:</th>
											<th>Amount:</th>
										</tr>
										<tr>
											<td>#1</td>
											<td><input  name="pickDate1" style="width: 170px;"  [ngModel]="sale?.pickDate1 | date: 'yyyy-MM-dd'"
												(ngModel)="sale.pickDate1 = $event"
												(ngModelChange) = "sale.pickDate1 = $event; " type="date"  class="cstm-input"></td>
											<td><input  style="color: #0fbb51 !important;" 
												name="pickAmount1" 
												[ngModel]="sale?.pickAmount1 || 0.00"
												(ngModel)="sale.pickAmount1 = $event"
												(ngModelChange) = "sale.pickAmount1 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' 
												  type="number"  
												  class="cstm-input sec-input"></td>
										</tr>
										<tr>
											<td>#2</td>
											<td><input  name="pickDate2" style="width: 170px;"  [ngModel]="sale?.pickDate2 | date: 'yyyy-MM-dd'"
												(ngModel)="sale.pickDate2 = $event"
												(ngModelChange) = "sale.pickDate2 = $event; " type="date"  class="cstm-input"></td>
											<td><input  style="color: #0fbb51 !important;" name="pickAmount2" [ngModel]="sale?.pickAmount2 || 0.00"
												(ngModel)="sale.pickAmount2 = $event"
												(ngModelChange) = "sale.pickAmount2 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"  class="cstm-input sec-input"></td>
										</tr>
										<tr>
											<td>#3</td>
											<td><input  name="pickDate3" style="width: 170px;"  [ngModel]="sale?.pickDate3 | date: 'yyyy-MM-dd'"
												(ngModel)="sale.pickDate3 = $event"
												(ngModelChange) = "sale.pickDate3 = $event; " type="date"  class="cstm-input"></td>
											<td><input  style="color: #0fbb51 !important;" name="pickAmount3" [ngModel]="sale?.pickAmount3 || 0.00"
												(ngModel)="sale.pickAmount3 = $event"
												(ngModelChange) = "sale.pickAmount3 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"  class="cstm-input sec-input"></td>
										</tr>
										<tr>
											<td>#4</td>
											<td><input  name="pickDate4" style="width: 170px;"  [ngModel]="sale?.pickDate4 | date: 'yyyy-MM-dd'"
												(ngModel)="sale.pickDate4 = $event"
												(ngModelChange) = "sale.pickDate4 = $event; " type="date"  class="cstm-input"></td>
											<td><input   style="color: #0fbb51 !important;" name="pickAmount4" [ngModel]="sale?.pickAmount4 || 0.00"
												(ngModel)="sale.pickAmount4 = $event"
												(ngModelChange) = "sale.pickAmount4 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"  class="cstm-input sec-input"></td>
										</tr>
										<tr>
											<td>#5</td>
											<td><input  name="pickDate5" style="width: 170px;"  
												[ngModel]="sale?.pickDate5 | date: 'yyyy-MM-dd'"
												(ngModel)="sale.pickDate5 = $event"
												(ngModelChange) = "sale.pickDate5 = $event; " type="date"  class="cstm-input"></td>
											<td><input  style="color: #0fbb51 !important;"  name="pickAmount5" [ngModel]="sale?.pickAmount5 || 0.00"
												(ngModel)="sale.pickAmount5 = $event"
												(ngModelChange) = "sale.pickAmount5 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"    class="cstm-input sec-input"></td>
											<td><span class="cstm-val">{{sale.pickupsTotal}}</span></td>
										</tr>
									</table>
								</div>
							
								<div class="form-row input-group" >
									<label>Sale Default</label>
									<select tabindex="-1" class="cstm-select" 
									#saleDefault="ngModel" 
									name="saleDefaultsId"    
									[(ngModel)]="sale.saleDefaultsId"  class="form-control"  (ngModelChange)="taxSelected($event)">
									<option  style="color: #bb400f;" [value]="-1">None</option>
									  <option style="color: #0386c3;" [value]="0">Add New</option>  
									<option *ngFor="let item of saleDefaults; index as i" 
									  [selected] = "item.id == sale?.saleDefaults?.id "  
									  [ngValue]= "item.id">{{item?.name}}</option>
									</select>
									<div *ngIf = "sale.saleDefaultsId != null" class="input-group-prepend">
										<button tabindex="-1" (click) = "popupTaxDefaultsEdit()" class="btn"><i class="fa fa-edit"></i></button>
									  </div>
									
								</div>
								<div class="pickup-sec taxes-sec">
									<h2 class="pick-title">Taxes</h2>
									<table>
										<tr>
											<th></th>
											<th>Amount Taxed</th>
											<th>Rate %</th>
										</tr>
										<tr>
											<td>Tax 1</td>
											<td><input  name="amtTaxed1" [ngModel]="sale?.amtTaxed1 || 0.00"
												(ngModel)="sale.amtTaxed1 = $event"
												(ngModelChange) = "sale.amtTaxed1 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number" class="cstm-input sec-input" disabled=""></td>
											<td><input  name="taxRate1" [ngModel]="sale?.taxRate1 || 0.00"
												(ngModel)="sale.taxRate1 = $event"
												(ngModelChange) = "sale.taxRate1 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"  class="cstm-input sec-input"></td>
											<td><span class="cstm-val">{{sale?.totalTax1}}</span></td>
										</tr>
										<tr>
											<td>Tax 2</td>
											<td><input  name="amtTaxed2" [ngModel]="sale?.amtTaxed2 || 0.00"
												(ngModel)="sale.amtTaxed2 = $event"
												(ngModelChange) = "sale.amtTaxed2 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"  class="cstm-input sec-input" disabled=""></td>
											<td><input  name="taxRate2" [ngModel]="sale?.taxRate2 || 0.00"
												(ngModel)="sale.taxRate2 = $event"
												(ngModelChange) = "sale.taxRate2 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"  class="cstm-input sec-input"></td>
											<td><span class="cstm-val">{{sale?.totalTax2}}</span></td>
										</tr>
										<tr>
											<td>Tax 3</td>
											<td><input  name="amtTaxed3" [ngModel]="sale?.amtTaxed3 || 0.00"
												(ngModel)="sale.amtTaxed3 = $event"
												(ngModelChange) = "sale.amtTaxed3 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"  class="cstm-input sec-input" disabled=""></td>
											<td><input  name="taxRate3" [ngModel]="sale?.taxRate3 || 0.00"
												(ngModel)="sale.taxRate3 = $event"
												(ngModelChange) = "sale.taxRate3 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"  class="cstm-input sec-input"></td>
											<td><span class="cstm-val">{{sale?.totalTax3}}</span></td>
										</tr>
										<tr>
											<td>Tax 4</td>
											<td><input  name="amtTaxed4" [ngModel]="sale?.amtTaxed4 || 0.00"
												(ngModel)="sale.amtTaxed4 = $event"
												(ngModelChange) = "sale.amtTaxed4 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number" class="cstm-input sec-input" disabled=""></td>
											<td><input  name="taxRate4" [ngModel]="sale?.taxRate4 || 0.00"
												(ngModel)="sale.taxRate4 = $event"
												(ngModelChange) = "sale.taxRate4 = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"  class="cstm-input sec-input"></td>
												  <!-- <td><input  name="totalTax4" [ngModel]="sale?.totalTax4 || 0.00"
													(ngModel)="sale.totalTax4 = $event"
													(ngModelChange) = "sale.totalTax4 = $event; "
													(blur) = "onChange($event)"
													  value='0.00' type="number"  class="cstm-input sec-input"></td> -->
											<td><span class="cstm-val">{{sale?.totalTax4}}</span></td>
										</tr>
										<tr>
											<td>Total Tax</td>
											<td><input tabindex="-1"  name="tax" [ngModel]="sale?.tax || 0.00"
												(ngModel)="sale.tax = $event"
												(ngModelChange) = "sale.tax = $event; "
												(blur) = "onChange($event)"
												  value='0.00' type="number"  value="0.00" class="cstm-input sec-input" disabled=""></td>
										</tr>
									</table>

								</div>
							</div>
							<div class="col-md-5 left-side-deal-info">
								<div class="form-row">
									<label class="input-color-label">Amount Financed</label>
									<input tabindex="-1"  name="amountFinanced" disabled="true" [ngModel]="sale?.amountFinanced || 0.00"
									(ngModel)="sale.amountFinanced = $event"
									(ngModelChange) = "sale.amountFinanced = $event; "
									  value='0.00' type="number" style="color: #0fbb51 !important;" class="cstm-input input-color-label">
								</div>
								<div class="form-row">
									<label>Non Amortized</label>
									<input  name="nonAmortized" disabled="true" [ngModel]="sale?.nonAmortized || 0.00"
									(ngModel)="sale.nonAmortized = $event"
									(ngModelChange) = "sale.nonAmortized = $event; "
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label>Amortized</label>
									<input tabindex="-1"  name="amortized" disabled="true" [ngModel]="sale?.amortized || 0.00"
									(ngModel)="sale.amortized = $event"
									(ngModelChange) = "sale.amortized = $event; "
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label>Loan Type</label>
									<select class="cstm-select" #amoLoanTypes = "ngModel"  name =  "amoLoanTypeId"  
									(ngModel) = "sale.amoLoanTypeId"  
									[ngModel] = "sale.amoLoanTypeId"  
									(ngModelChange)="amoLoanMethodSelected($event)" >
									<option style="color: #bb400f;" [value]="-1">None</option>
									<option *ngFor="let item of loanTypes"     [ngValue]="item.id">{{item?.value}}</option>
									</select>
								</div>
								<!-- <option *ngFor="let item of loanTypes; index as i"   
								[selected] = "sale?.amoLoanTypes?.id === item.id"  
								(ngValue)="item">{{item?.value}}</option> -->
								<!-- TEST FIELD INSTALLMENT-->
								<div class="form-row">
									<label  class="vehicle-normallabel">Installment</label>								
									  <select tabindex="-1" class="cstm-select" 
									  #amoInstallments="ngModel"  
									  name="amoInstallmentsId"  
									  [(ngModel)]="sale.amoInstallmentsId"							 
									  (ngModelChange)="amoInstallmentSelected($event)" >
									  <option style="color: #bb400f;" [value]="-1">None</option>
										<option *ngFor="let item of installments"     [ngValue]="item.id">{{item?.value}}</option>
									  </select>
								</div>
								<!-- END TeST FIELD INSTALLMENT -->
								<!-- <div class="form-row">
									<label>Installment</label>
									<select class="cstm-select" 
									#amoInstallments = "ngModel"  
									name="amoInstallmentsId"  
									[(ngModel)] = "selectedInstallent"  
									(ngModelChange)="amoInstallmentSelected(selectedInstallent)" >
									  <option *ngFor="let item of installments; index as i" 
									  [selected] = "sale?.amoInstallments?.id === item.id" 
									  (ngValue)="item"
									  >{{item?.value}} 
									   </option>
									</select>
								
								</div> -->
									<!--  [selected] = "sale?.amoInstallments?.id === item.id"   -->
								<div class="form-row">
									<label>APR</label>
									<input  name="apr" [ngModel]="sale?.apr || 0.00"
									(ngModel)="sale.apr = $event"
									(ngModelChange) = "sale.apr = $event; "
									(blur) = "aprChanged($event)"
									(keydown) = "aprEntered($event)"
									(focusout)="aprFocusOut()"
									(focus)="aprFocusIn()"
									  value='0.00' type="number"  class="cstm-input">
									<button class="cstm-btn">Help?</button>
								</div>
								<div class="form-row">
									<label>TERM</label>
									<input tabindex="-1"  name="term" [ngModel]="sale?.term || 0.00"
									(ngModel)="sale.payment = $event"
									(ngModelChange) = "sale.term = $event; "
									(blur) = "termChanged($event)"
									(keydown) = "termEntered($event)"
									(focusout)="termFocusOutFunction()"
									(focus)="termFocusIn()"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label>Payment</label>
									<input tabindex="-1" name="payment" [ngModel]="sale?.payment || 0.00"
									(ngModel)="sale.payment = $event"
									(ngModelChange) = "sale.payment = $event; "
									(blur) = "paymentChanged($event)"
									(keydown) = "paymentEntered($event)"
									(focusout)="paymentFocusOut()"
									(focus)="paymentFocusIn()"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label>Final Payment</label>
									<input   name="finalPayment"  disabled="true" [ngModel]="sale?.finalPayment || 0.00"
									(ngModel)="sale.finalPayment = $event"
									(ngModelChange) = "sale.finalPayment = $event; "
									(blur) = "onChange($event)"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label>Sold Date</label>
									<input  name="soldDate" style="width: 170px;"  
									[ngModel]="sale?.soldDate | date: 'yyyy-MM-dd'"
									(ngModel)="sale.soldDate = $event"
									(ngModelChange) = "sale.soldDate = $event; "
									  value='0.00' type="date"  class="cstm-input">
								</div>
								<div class="form-row">
									<label>First Due Date</label>
									<input  name="firstDueDate" style="width: 170px;"  [ngModel]="sale?.firstDueDate | date: 'yyyy-MM-dd'"
									(ngModel)="sale.firstDueDate = $event"
									(ngModelChange) = "sale.firstDueDate = $event; "
									  value='0.00' type="date"  class="cstm-input">
									
								</div>
								<div class="form-row">
									<label>Last Paid Date</label>
									<input  name="lastPaidDate" style="width: 170px;"  [ngModel]="sale?.lastPaidDate | date: 'yyyy-MM-dd'"
									(ngModel)="sale.lastPaidDate = $event"
									(ngModelChange) = "sale.lastPaidDate = $event; "
									  value='0.00' type="date"  class="cstm-input">
								</div>
								<div class="form-row">
									<label>Next Due Date</label>
									<input  name="nextDueDate" style="width: 170px;"  [ngModel]="sale?.nextDueDate | date: 'yyyy-MM-dd'"
									(ngModel)="sale.nextDueDate = $event"
									(ngModelChange) = "sale.nextDueDate = $event; "
									  value='0.00' type="date"  class="cstm-input">
								</div>
								<div class="form-row">
									<label>Total Late Fee</label>
									<input  name="lateFee"  disabled= "treu" [ngModel]="sale?.lateFee || 0.00"
									(ngModel)="sale.lateFee = $event"
									  value='0.00' type="number"  class="cstm-input">
								</div>
								<div class="form-row">
									<label>Skipped</label>
									<input  name="passPmts"  disabled= "treu" [ngModel]="sale?.passPmts || 0.00"
									(ngModel)="sale.passPmts = $event"
									  value='0.00' type="number"  class="cstm-input">
									<button class="cstm-btn">Skip a Payment?</button>
								</div>
							</div>
						</div>
						<!-- <div class="row bottom-row-sec">
							<div class="col-md-12 btn-sec">
								<div class="inner-sec">
									<select class="cstm-input" disabled="">
										<option></option>
										<option>Option 1</option>
										<option>Option 2</option>
									</select>
									<button class="cstm-input cstm-btn" disabled="">Print Package</button>
									<button class="cstm-input cstm-btn">Set Default Printer</button>
								</div>
								<div class="text-area-sec">
									<span class="title">Form</span>
									<textarea class="txt-area"></textarea>
								</div>
								<div class="additional-btn-sec">
									<button class="cstm-btn" disabled="">Additional Forms and Reports</button>
								</div>
							</div>	
						</div> -->
					</form>
					
					</div>
					<div class="alert alert-info" *ngIf="dealInfoForm.dirty">
						<strong>Information: </strong> You have made changes.  Any unsaved changes will be lost if not saved.
					</div>
					<!--	[selected] = "item.id == salePackages?.id "   -->
					<!-- #formId="ngModel" 
									name="formId2"    
									[(ngModel)]="salePackages" class="form-control"  (ngModelChange)="pdfPackageSelected($event)" -->
							
				</div>
				<div class="customer-sidebar sidebar-with-btns col-md-1">
					<div class="inner-sidebar">
						<ul>
							<li class="cstm-btn">
								<a href="javascript:void(0)" (click) = "PrintPdf('Sale Package1')">
									<img src="assets/images/sidebar-icon-1.jpg">
									<span class="caption">Print Package</span>
								</a>
							</li>
							<li class="item">
								<a href="javascript:void(0)" (click) = "createQbInvoice()">
									<img src="assets/images/quickbooks2.png">
									<span class="caption">Qb Invoice</span>
								</a>
							</li>
							<li class="item">
								<a (click) = "update()" href="javascript:void(0)">
									<img src="assets/images/sidebar-icon-2.jpg">
									<span class="caption">Save</span>
								</a>
							</li>
							
							<!-- <li class="item">
								<a href="javascript:void(0)" (click) = "amortisationModalClick()">
									<img src="assets/images/sidebar-icon-6.jpg">
									<span class="caption">Amortization</span>
								</a>
							</li> -->
							<li class="item" *ngIf="isprintDisabled">
								<a  href="javascript:void(0)"  (click) = "PrintPdf('DealInfo')">
									<img src="assets/images/sidebar-icon-7.jpg">
									<span class="caption">Deal Info</span>
								</a>
							</li>
							<li class="item">
								<a href="javascript:void(0)" (click) = "tabelViewClick()">
									<img src="assets/images/sidebar-icon-9.jpg">
									<span class="caption">Table View</span>
								</a>
							</li>
						</ul>
						<!-- <div class="form-row input-group" >
							<label>PDF FORMS</label>
							<select class="cstm-select" 
							#formId="ngModel" 
							name="formId2"    
							[(ngModel)]="salePackages" class="form-control" 
							(ngModelChange)="pdfPackageSelected($event)"
							>
							<option style="color: #bb400f;" [value]="-1">None</option>
							  <option style="color: #0386c3;" [value]="0">Add New</option>  
							<option  *ngFor="let item of salePackages; index as i" 
							
							[selected]="pdfPackageSelected(item)" 
							  [ngValue]= "item">{{item?.packageName}}</option>
							</select>
							<div  class="input-group-prepend"> 
								<button (click) = "printPackage(selectedPdfFormPack)" class="btn"><i class="fa fa-edit"></i></button>
							  </div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
  </div>

  <!-- <div class="customer-footer">
		<div class="container-fluid">
      <app-bottom-footer></app-bottom-footer>
		</div>
  </div> -->

  <!-- </div>
  </div>
	</div> -->