import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { AmoInstallment } from 'src/app/_models/AmoInstallment';
import { AmoPayment } from 'src/app/_models/amopayments';
import { Dollars } from 'src/app/_models/dollars';
import { AmoLinqPayments } from 'src/app/_models/DTOS/amolinqpayments';
import { AmoPaymentsDeferredTax } from 'src/app/_models/DTOS/amopaymentsdeferredtaxdto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { environment } from 'src/environments/environment';
import { VehicleBuyersGuide } from '../_models/buyersguidemodels/VehicleBuyersGuide';
import { VehicleBuyersGuideSystemsCovered } from '../_models/buyersguidemodels/VehicleBuyersGuideSystemsCovered';
import { BuyersGuideAndSystemsListReturned } from '../_models/DTOS/buyersguiddtos/BuyersGuideAndSystemsListReturned';
import { VehicleBuyersGuideNoIdDto } from '../_models/DTOS/buyersguiddtos/VehicleBuyersGuidDtoNoId';
import { VehicleBuyersGuideSystemsCoveredNoIdDto } from '../_models/DTOS/buyersguiddtos/VehicleBuyersGuideSystemsCoveredDtoNoId';


@Injectable({
  providedIn: 'root'
})
export class BuyersGuidService {
baseUrl = environment.apiUrl;
// BuyersGuideAndSystemsListReturned
  constructor(private http: HttpClient) { }
  getBuyersGuide(id: number): Observable<VehicleBuyersGuide> {
    return this.http.get<VehicleBuyersGuide>(this.baseUrl + 'vehiclebuyersguide/getbybuyersguide/' + id);
  }
  getSystem(id: number): Observable<VehicleBuyersGuideSystemsCovered> {
    return this.http.get<VehicleBuyersGuideSystemsCovered>(this.baseUrl + 'vehiclebuyersguide/getbybuyersguidesystem/' + id);
  }

  getBuyersGuideList(parentguid: string): Observable<VehicleBuyersGuide[]> {
    return this.http.get<VehicleBuyersGuide[]>(this.baseUrl + 'vehiclebuyersguide/listbuyersguides/' + parentguid);
  }

  getBuyersGuideSytemsList(parentguid: string): Observable<VehicleBuyersGuideSystemsCovered[]> {
    return this.http.get<VehicleBuyersGuideSystemsCovered[]>(this.baseUrl + 'vehiclebuyersguide/listbuyersguidesystems/' + parentguid);
  }

  editBuyersGuide(entity: VehicleBuyersGuide): Observable<BuyersGuideAndSystemsListReturned>{
      return this.http.post<BuyersGuideAndSystemsListReturned>(
          this.baseUrl + 'vehiclebuyersguide/editbuyersguide', entity );
  }
  editBuyersGuideSystems(entity: VehicleBuyersGuideSystemsCovered): Observable<BuyersGuideAndSystemsListReturned>{
    return this.http.post<BuyersGuideAndSystemsListReturned>(
        this.baseUrl + 'vehiclebuyersguide/editbuyersguidesystems', entity );
  }

  addBuyersGuide(entity: VehicleBuyersGuideNoIdDto): Observable<BuyersGuideAndSystemsListReturned>{
    return this.http.post<BuyersGuideAndSystemsListReturned>(
        this.baseUrl + 'vehiclebuyersguide/addbuyersguide', entity );
  }
    addBuyersGuideSystems(entity: VehicleBuyersGuideSystemsCoveredNoIdDto): Observable<BuyersGuideAndSystemsListReturned>{
        return this.http.post<BuyersGuideAndSystemsListReturned>(
            this.baseUrl + 'vehiclebuyersguide/addsystemscovered', entity );
    }
  
    deletSystem(id: number, parentGuid: string): Observable<BuyersGuideAndSystemsListReturned> {
      return this.http.get<BuyersGuideAndSystemsListReturned>(this.baseUrl + 'vehiclebuyersguide/deletebuyersguidesystems/' + id + '/' + parentGuid);
    }
    deletBuyersGuide(id: number, parentGuid: string): Observable<BuyersGuideAndSystemsListReturned> {
      return this.http.get<BuyersGuideAndSystemsListReturned>(this.baseUrl + 'vehiclebuyersguide/deletebuyersguide/' + id + '/' + parentGuid);
    }
}

