

<div style="margin-top: 26px;" class="container-fluid customer-tabs-sec">
    <ul class="nav nav-tabs">
      <li routerLinkActive="active">
        <a [ngClass]="{'active show' : isAll}" 
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('All')"
          >All</a
        >
      </li>  
      <div class="d-flex justify-content-right">
          <pagination
        [boundaryLinks]="true"
        pageBtnClass = "nav nav-tabs"
            [(ngModel)]="pagination.currentPage"
            [totalItems]="pagination.totalItems"
            (pageChanged)="pageChanged($event)"
            [itemsPerPage]="pagination.itemsPerPage"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;"
          >
          </pagination>
        </div>
  
  </ul>  
  </div>
  <br>
  <br>  <br>
  <!-- <app-date-filter *ngIf = "isFilterDates"></app-date-filter> -->
  <div class="customer-form-sec" style="z-index: -1; ">
    <div class="container-fluid">
      <div class="row">
        <div class="form-sec tab-content col-md-11">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-row account-payable-row">          
                  
                  <div class="row">
                    <div class="col-md-12">
                      <div id="printableTable" >
                        <table style="min-width: 100% !important;">
                          <thead>
                          <tr>
                            <th class="date-th">Date</th>
                            <th class="date-th">Type</th>
                            <th class="des-th">Description</th>
                            <th class="des-th">URL</th>
                            <th class="count-th">Count</th>
                            <th *appHasRole="['Admin']"  class="charges-th">Unit</th>
                            <th *appHasRole="['Admin']"  class="charges-th">Total</th>
                            <th></th>
                          </tr>
                        </thead>
                          <tbody>
                          <tr  (dblclick) = "edit(item)" class="td-rw" *ngFor = "let item of repairs; let i=index">
                            <td  *ngIf="i != repairs.length - 1" class="date-th">{{item.dateStarted | date: 'yyyy-MM-dd'}}</td>
                            <td *ngIf="i != repairs.length - 1"  class="date-th">{{item?.realPropertyRepairTypes?.value}}</td>
                            <td *ngIf="i != repairs.length - 1" class="des-th">{{item.description}}</td>
                            <td *ngIf="i != repairs.length - 1" class="des-th"><a *ngIf="item.url" href="{{item.url}}" target="_blank">Link</a></td>
                            <td *ngIf="i != repairs.length - 1" class="count-th">{{item.count }}</td>
                            <td *ngIf="i != repairs.length - 1"  class="charges-td ">{{item.itemAmount | currency}}</td>
                            <td *ngIf="i != repairs.length - 1" class="charges-td ">{{item.totalAmount | currency}}</td>
                            <td *ngIf="i != repairs.length - 1"><button class="btn btn-outline-success"  (click)="edit(item)">Edit </button> </td>
                            <td *ngIf="i != repairs.length - 1"><button class="btn btn-outline-warning"  (click)="deleteRepair(item)">Delete </button> </td>
                          </tr>
                          <tr align="right" class="border_top" *ngFor="let item of repairs; let i=index; last as isLast">
                            <td *ngIf="isLast && i !==0" class="charge-td">SUB Totals</td>
                            <td *ngIf="isLast && i !==0" class="charge-td"></td>
                            <td *ngIf="isLast && i !==0" class="charge-td">{{item.itemAmount | currency}}</td>
                            <td *ngIf="isLast && i !==0" class="charge-td">{{item.totalAmount | currency}}</td>
                            <td *ngIf="isLast && i !==0"  class="charge-td"></td>
                          </tr>
                        </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- /.table-sec -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.row -->
          </div>
        </div>
        
        <div class="customer-sidebar sidebar-with-btns col-md-1">
          <div class="inner-sidebar">
            <ul>
              <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->
              <li>
                <button (click) = "add()"  class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-1.png" />
                  <span>Add Repair</span>
                </button>
              </li>
              <li *appHasRole="['Admin']">
                <button class="cstm-btn" (click) = "exportAsXLSX()">
                  <img src="assets/images/icons8-microsoft-excel-48.png" />
                  <span>Export Excel</span>
                </button>
              </li>
              <li>
                <button (click) = "printDiv()" class="cstm-btn">
                  <img src="assets/images/ap-icon-6.png" />
                  <span>Print Report</span>
                </button>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>