import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ExportDropDowns } from '../_models/dropdowns';
import { Member } from '../_models/member';
import { MembersService } from '../_services/members.service';
import { VendorService } from '../_services/vendorservices/vendor.service';

@Injectable({
    providedIn: 'root'
})
export class LienHoldersInsuranceCompanyResolver implements Resolve<ExportDropDowns> {

    constructor(private vendorService: VendorService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<ExportDropDowns> {
        return this.vendorService.getBoth(route.paramMap.get('parentGuid'));
    }
}
