import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VehicleBuyersGuideSystemsCovered } from 'src/app/_models/buyersguidemodels/VehicleBuyersGuideSystemsCovered';
import { VehicleBuyersGuideSystemsCoveredNoIdDto } from 'src/app/_models/DTOS/buyersguiddtos/VehicleBuyersGuideSystemsCoveredDtoNoId';

@Component({
  selector: 'app-systemscoveredadd',
  templateUrl: './systemscoveredadd.component.html',
  styleUrls: ['./systemscoveredadd.component.css']
})
export class SystemscoveredaddComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Output() emittedValues = new EventEmitter();
  vehicleBuyersGuideSystemsCovered = {} as VehicleBuyersGuideSystemsCoveredNoIdDto;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  close() {
    this.bsModalRef.hide();
  }
  save() {
    console.log(this.editForm.value);
    this.emittedValues.next(this.vehicleBuyersGuideSystemsCovered);
    this.bsModalRef.hide();
  }
}
