import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CalculateFederalAprDto } from 'src/app/_models/DTOS/CalculateFederalAprDto';
import { CalculateTermDto } from 'src/app/_models/DTOS/CalculateTermDto';
import { Dollars } from 'src/app/_models/dollars';
import { ObserveOnMessage } from 'rxjs/internal/operators/observeOn';
import { SaleNotes } from 'src/app/_models/SaleNote';
import { DollarTypes } from 'src/app/_models/dollartypes';


@Injectable({
  providedIn: 'root'
})
export class DollarService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;
  dollars = [] as Dollars[];
  notes = [] as SaleNotes[];
  dollarTypes = [] as DollarTypes[];
  dollarSaleTypesCached: any;
  dataCache: any;

  dollarSaleDollarTypes = [] as DollarTypes[];
  getItemDollars(parentid, itemid): Observable<any> {
  //  console.log(parentid + '/' + itemid);
    return this.http.get<any>(this.baseUrl + 'dollars/getdollarsforitem/' + parentid + '/' + itemid);
  }
  setDollarsNotesDollarTypesForMiscCharges(data: any) {
    this.dataCache = data;
  }
  getSaleDollarsForMiscCharges(parentid, saleid): Observable<any> {
    if (this.dataCache) {
      console.log('got notes, misc charges and types');
      return of(this.dataCache);
    }
    return this.http.get<any>(this.baseUrl + 'dollars/getdollarsmiscchargesforsale/' + parentid + '/' + saleid);
  }
  postNewIemRepair(parentGuid: string, dollar: Dollars, itemid: number) {
    return this.http.post<any>(this.baseUrl + 'dollars/adddollarsforitem/'  + parentGuid + '/' + itemid, dollar);
  }
  editNewIemRepair(parentGuid: string, dollar: Dollars, itemid: number) {
    return this.http.post<any>(this.baseUrl + 'dollars/editdollarsforitem/'  + parentGuid + '/' + itemid, dollar);
  }
  setDollarTypesForSale(data) {
    this.dollarSaleDollarTypes = data;
  }
  getDollarTypesForSale(parentid, itemid): Observable<any> {
    if (this.dollarSaleTypesCached) {
      return of(this.dollarSaleDollarTypes);
    }
      return this.http.get<any>(this.baseUrl + 'dollars/getdollartypesforsale/' + parentid + '/' + itemid);
    }
  getDollarTypesForSaleMiscCost(parentid, itemid): Observable<any> {
    //  console.log(parentid + '/' + itemid);
      return this.http.get<any>(this.baseUrl + 'dollars/getdollarsforitem/' + parentid + '/' + itemid);
    }
  postNewMiscCost(parentGuid: string, dollar: Dollars,  saleid: number, itemid: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'dollars/adddollarsforsalemisccost/'  + parentGuid + '/' + itemid + '/' + saleid, dollar);
  }
  editMiscCost(parentGuid: string, dollar: Dollars,  saleid: number, itemid: number): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'dollars/editdollarsforsalemisccost/'  + parentGuid + '/' + itemid + '/' + saleid, dollar);
  }

  editWashoutDollar(parentGuid: string, dollar: Dollars,  saleid: number, itemid: number) {
    return this.http.post<any>(this.baseUrl + 'dollars/editdollarsforwashout/'  + parentGuid + '/' + itemid + '/' + saleid, dollar);
  }

}
