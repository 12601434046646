import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthService} from "./auth.service";
import {UserAssignedToCompany} from "../_models/userAssignedToCompany";
import {PaymentRequestCreateInput} from "../_models/payment/PaymentRequestCreateInput";
import {PaymentRequestCreateOutput} from "../_models/payment/PaymentRequestCreateOutput";
import {PaymentPlanDto} from "../_models/payment/PaymentPlanDto";
import {AccountSubscriptionDto} from "../_models/payment/AccountSubscriptionDto";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  baseUrl = environment.apiUrl;
  selectedCompany: UserAssignedToCompany;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.currentCompany.subscribe(data => {
      this.selectedCompany = data;
    });
  }

  getPaymentPlans = (): Observable<Array<PaymentPlanDto>> => {
    return this.http.get<Array<PaymentPlanDto>>(this.baseUrl + 'Payment/plans');
  }

  getAccountSubscriptions = (): Observable<Array<AccountSubscriptionDto>> => {
    return this.http.get<Array<PaymentPlanDto>>(this.baseUrl + 'Payment/list/AccountSubscriptions/' + this.selectedCompany.parentGuid);
  }

  createSubscription = (input: PaymentRequestCreateInput): Observable<PaymentRequestCreateOutput> => {
    return this.http.post<PaymentRequestCreateOutput>(this.baseUrl + 'Payment/' + this.selectedCompany.parentGuid, input);
  }
}
