
<table class="table table-hover" style="cursor: pointer;">
  <thead class="thead-light">
  <tr>
    <th>ID</th>
    <th>Labor</th>
    <th>Parts</th>
    <th>AsIs</th>
    <th>Dealer Warranty</th>
    <th>Limited Warranty</th>                        
    <th>Manufacture Still Apply</th>
    <th>Other Warranty</th>
    <th>Service Contract</th>
    <th>DeletedDate</th>
  </tr>
  </thead>
  <tbody>
    <tr class="td-row" *ngFor="let data of vehicleBuyersGuide">
      <td>{{data.id}}</td>
      <td>{{data.percentLabor}}</td>
      <td>{{data.percentParts}}</td>
      <td>{{data.isAsIs}}</td>
      <td>{{data.isDealerWarranty}}</td>
      <td>{{data.isLimitedWarranty}}</td>
      <td>{{data.isManufactureStillApply}}</td>
      <td>{{data.isOtherWarranty}}</td>
      <td>{{data.isServiceContract}}</td>
      <td>{{data.deletedDate}}</td>
      <td >
          <a class="btn btn-info" (click)="editNewBuyersGuid(data)" data-toggle="tooltip" data-placement="top" title="View Website Detail"><i class="fa fa-fw fa-edit"></i></a>
        </td>
        <td >
          <a class="btn btn-danger" (click)="delete(data)" data-toggle="tooltip" data-placement="top" title="View Website Detail"><i class="fa fa-fw fa-edit"></i></a>
        </td>
    </tr>
  </tbody>
</table>

