import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WebsiteMenu } from 'src/app/_models/websites/WebsiteMenu';
import { Website } from 'src/app/_models/websites/websites';


@Component({
  selector: 'app-editmenu-modal',
  templateUrl: './editmenu-modal.component.html',
  styleUrls: ['./editmenu-modal.component.scss']
})
export class EditmenuModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  website = {} as Website;
  entity = {} as {} as WebsiteMenu;
  menuLevel = [{id: 1}, {id: 2}, {id: 3} ];
  menuLevels = {};
  groupLevel = [{id: 1}, {id: 2}, {id: 3} ];
  groupLevels = {};
  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {
  }
  update() {
    this.emittedValues.emit(this.entity);
    this.modalRef.hide();
  }
  changeSubMenu(e) { this.entity.level = e; }

  changeGroupSubMenu(e) { this.entity.group = e; console.log(this.entity); }

  checkMark(e: any) {
    if (e === 'headermenu') { this.entity.headerMenu = !this.entity.headerMenu; }
    if (e === 'footermenu') { this.entity.footer = !this.entity.footer; }
  }
}
