


<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div class="payments-form">
          <div class="payments-tabs">
            <div class="inner">
          <div class="tab-content" id="paymentTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div class="inner">
                <div class="row">
          <div class="col-md-12 cstm-col left-grid">
            <div class="inner">
              <h2 class="title">Payment Details</h2>
              <div class="row">
                <div class="col-md-9 custom-col">
                  <form
                  id="editForm"
                  #editForm="ngForm"
                  (ngSubmit)="save()"
                >
                  <!--Paid Date-->
                  <div class="cstm-rw settings">
                    <label>Date Paid</label>
                    <input type="date" 
                    id="firstDueDate" 
                    name="datePaid"
                    [ngModel]="takePaymentDto?.datePaid | date:'yyyy-MM-dd'" 
                    (ngModelChange)="takePaymentDto.datePaid = $event; paidDate($event)"
                    [value]="takePaymentDto?.datePaid | date:'yyyy-MM-dd'" 
                    #datePaid="ngModel" 
                    required
                    class="cstm-input" >
                   
                  </div>
                 <!--Payment Due -->
                  <div class="cstm-rw settings">
                    <label>Payment Due</label>
                    <input 
                    value="0.00"
                    type="number"
                    step="10.00"
                    autocomplete="off"
                    tabIndex="-1"
                    (blur)="paymentDueLostFocus()" 
                    (keyup) ="paymentDueEntered()"
                    [ngModel]="takePaymentDto?.todayLoanPaid || 0.00"
                    (ngModel)="takePaymentDto.todayLoanPaid = $event"
                    (ngModelChange) = "takePaymentDto.todayLoanPaid = $event; paymentDueEntered()"
                    name="todayLoanPaid"
                    #todayLoanPaid="ngModel"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||  event.charCode == 46 || event.charCode == 0 "
                    (change)="setTwoNumberDecimal($event)"
                    class="cstm-input" >
                   
                  </div>
                  <!--NON Amortized Due-->
                  <div class="cstm-rw settings">
                    <label>Non Amortized Due</label>
                    <input 
                    value="0.00"
                    type="number"
                    step="10.00"
                    autocomplete="off"
                    tabIndex="-1"
                    [ngModel]="takePaymentDto?.todayNonAmortizedPaid || 0.00"
                    (ngModel)="takePaymentDto.todayNonAmortizedPaid = $event"
                    (ngModelChange) = "takePaymentDto.todayNonAmortizedPaid = $event;"
                    name="todayNonAmortizedPaid"
                    #todayNonAmortizedPaid="ngModel"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||  event.charCode == 46 || event.charCode == 0 "
                    (change)="setTwoNumberDecimal($event)"
                    class="cstm-input" >
                   
                  </div>
                  <!--Late Fee Paid-->
                  <div class="cstm-rw settings">
                    <label>Late Fee Paid</label>
                    <input 
                    value="0.00"
                                  type="number"
                                  step="10.00"
                                  tabIndex="-1"
                                  autocomplete="off"
                                  appTwoDigitDecimaNumber 
                                  [ngModel]="takePaymentDto?.todayLateFee || 0"
                                  (ngModel)="takePaymentDto.todayLateFee = $event"
                                  (ngModelChange) = "takePaymentDto.todayLateFee = $event; "
                                  (keyup)="lateFeeEntered()"
                                  (blur) = "lateFeeLostFocus()"
                                  name="todayLateFee"
                                  #todayLateFee="ngModel"
                                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||  event.charCode == 46 || event.charCode == 0 "
                                  (change)="setTwoNumberDecimal($event)"
                    class="cstm-input" >
                   
                  </div>
                  <!--Misc Fees-->
                  <div class="cstm-rw settings">
                    <label>Misc Fees Paid</label>
                    <input min="-9999999999.99" max="9999999999.99"
                    class="cstm-input"
                    value="0.00"
                    type="number"
                    step="10.00"
                    tabIndex="-1"
                    autocomplete="off"
                    [ngModel]="takePaymentDto?.todayMisc || 0"
                    (ngModel)="takePaymentDto.todayMisc = $event"
                    (ngModelChange) = "takePaymentDto.todayMisc = $event"
                    name="todayMisc"
                    #todayMisc="ngModel"
                    (blur) = "miscFeeLostFocus()"
                    (keyup) = "miscFeeEntered()"
                    (change)="setTwoNumberDecimal($event)"
                  >
                  </div>

                  <!--Deferred Down-->
                  <div class="cstm-rw settings">
                    <label>Deferred Down</label>
                    <input 
                    class="cstm-input"
                    value="0.00"
                    type="number"
                    step="10.00"
                    autocomplete="off"
                    (blur) = "deferredLostFocus()"
                    (keyup) = "deferredEntered()"
                    [ngModel]="takePaymentDto?.todayDeferredDown || 0"
                    (ngModel)="takePaymentDto.todayDeferredDown = $event; setTwoNumberDecimal($event)"
                    name="todayDeferredDown"
                    #todayDeferredDown="ngModel"
                    onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||  event.charCode == 46 || event.charCode == 0 "
                    (change)="setTwoNumberDecimal($event)"
                    >
                   
                  </div>
                 <!--Total Paid-->
                 <div class="cstm-rw settings">
                  <label>Total Paid Today</label>
                  <input 
                  class="cstm-input"
                  value="0.00"
                  type="number"
                  step="100.00"
                  autocomplete="off"
                  [ngModel]="takePaymentDto?.sumPaidToday || 0"
                  (ngModel)="takePaymentDto.sumPaidToday = $event; "
                  (ngModelChange) = setTwoNumberDecimal($event);
                  name="sumPaidToday"
                  #sumPaidToday="ngModel"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||  event.charCode == 46 || event.charCode == 0 "
                  (change)="setTwoNumberDecimal($event)"
                  disabled=""
                  >
                 
                </div>
                   <!--button save-->
                   <div class="col-md-12 cstm-col ">
                    <button
                      [disabled]="!editForm.valid"
                      class="btn btn-primary "
                    >
                      Save Payment
                    </button>
                    <p *ngIf="!editForm.valid" style="color: red">
                      Save Button Will be enabled when all fields entered!
                    </p>
                  </div>
                  </form>
                </div>
            
              </div>
            </div>
          </div>
          </div></div>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
          </div>
          </div>
          </div>



          


