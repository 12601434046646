import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { AmoInstallment } from 'src/app/_models/AmoInstallment';
import { AmoPayment } from 'src/app/_models/amopayments';
import { AmoLinqPayments } from 'src/app/_models/DTOS/amolinqpayments';
import { AmoPaymentsDeferredTax } from 'src/app/_models/DTOS/amopaymentsdeferredtaxdto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AmoInstallmentService {
baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }
  get(id: number): Observable<AmoInstallment> {
    return this.http.get<AmoInstallment>(this.baseUrl + 'amoinstallment/get/' + id);
  }

  getList(parentguid: string): Observable<AmoInstallment[]> {
    return this.http.get<AmoInstallment[]>(this.baseUrl + 'amoinstallment/getlist/' + parentguid);
  }


  getInstallments(parentguid: string): Observable<AmoInstallment[]> {
    return this.http.get<AmoInstallment[]>(this.baseUrl + 'amoinstallment/getlist/' + parentguid);
  }
  getAmortization(loanid): Observable<AmoAmortization[]> {
    return this.http.get<AmoAmortization[]>(this.baseUrl + 'amoloan/getamortization/' + loanid);
  }
  getInstallment(id: number ): Observable<AmoInstallment> {
    return this.http.get<AmoInstallment>(this.baseUrl + 'amoinstallment/get/' + id);
  }

  calculatePayments(parentid, invoiceid): Observable<any> {
    console.log('test detail reached');
    return this.http.get<any>(this.baseUrl + 'amoloan/calculateloanpayments/' + parentid + '/' + invoiceid);
  }
  saveAmortization(loanid, parentid, invoiceid, amoAmortization: AmoAmortization[]): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'amoloan/saveamortisation/' + loanid + '/' + parentid + '/' + invoiceid, amoAmortization);
  }
  deleteAmortisation(loanid, parentid, invoiceid): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'amoloan/deleteamortisation/' + loanid + '/' + parentid + '/' + invoiceid);
  }
  calculateAmortization(loanid, parentid, invoiceid): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'amoloan/createamortization/' + loanid + '/' + parentid + '/' + invoiceid);
  }
  deletePayment(paymentId): Observable<any>{
    return this.http.get<any>( this.baseUrl + 'amoloan/deletepayment/' + paymentId );
  }
  retrievePayments(parentid, loanId): Observable<AmoPayment[]> {
    return this.http.get<AmoPayment[]>( this.baseUrl + 'amoloan/getpayments/' + parentid + '/' + loanId);
  }
  retrievePagedLoans(parentGuid, page?, itemsPerPage?, userParams?): Observable<PaginatedResult<AmoLinqPayments[]>> {
    console.log('reached paged loans service');
    const paginatedResult: PaginatedResult<AmoLinqPayments[]> = new PaginatedResult<AmoLinqPayments[]>();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (userParams != null) {
      if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
      if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
      if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
      if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
      if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
      if (userParams.saleTypeId !== undefined) { params  = params.append('saleTypeId', userParams.saleTypeId); }
      if (userParams.saleStatusId !== undefined) { params  = params.append('saleStatusId', userParams.saleStatusId); }
      if (userParams.isFullYear !== undefined) { params  = params.append('isFullYear', userParams.isFullYear); }
      if (userParams.yearForDate !== undefined) { params  = params.append('yearForDate', userParams.yearForDate); }
      if (userParams.isAll !== undefined) { params  = params.append('isAll', userParams.isAll); }
      if (userParams.monthOfYear !== undefined) { params  = params.append('monthOfYear', userParams.monthOfYear); }
    }
    return this.http.get<AmoLinqPayments[]>(this.baseUrl + 'amoloan/getloanlist/' + parentGuid, {observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  retrievePagedPayments(parentGuid, page?, itemsPerPage?, userParams?): Observable<PaginatedResult<AmoPayment[]>> {
    const paginatedResult: PaginatedResult<AmoPayment[]> = new PaginatedResult<AmoPayment[]>();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (userParams != null) {
      if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
      if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
      if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
      if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
      if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
      if (userParams.isFullYear !== undefined) { params  = params.append('isFullYear', userParams.isFullYear); }
      if (userParams.yearForDate !== undefined) { params  = params.append('yearForDate', userParams.yearForDate); }
      if (userParams.isAll !== undefined) { params  = params.append('isAll', userParams.isAll); }
      if (userParams.monthOfYear !== undefined) { params  = params.append('monthOfYear', userParams.monthOfYear); }
    }
    return this.http.get<AmoPayment[]>(this.baseUrl + 'amoloan/getpaymentspaged/' + parentGuid, {observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  retrievePagedDeferredTaxPayments(parentGuid, page?, itemsPerPage?, userParams?): Observable<PaginatedResult<AmoPaymentsDeferredTax[]>> {
    const paginatedResult: PaginatedResult<AmoPaymentsDeferredTax[]> = new PaginatedResult<AmoPaymentsDeferredTax[]>();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (userParams != null) {
      if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
      if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
      if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
      if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
      if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
      if (userParams.isFullYear !== undefined) { params  = params.append('isFullYear', userParams.isFullYear); }
      if (userParams.yearForDate !== undefined) { params  = params.append('yearForDate', userParams.yearForDate); }
      if (userParams.isAll !== undefined) { params  = params.append('isAll', userParams.isAll); }
      if (userParams.monthOfYear !== undefined) { params  = params.append('monthOfYear', userParams.monthOfYear); }
    }
    return this.http.get<AmoPaymentsDeferredTax[]>(this.baseUrl + 'amoloan/getpaymentsdeferredpaged/' + parentGuid, {observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }
}

