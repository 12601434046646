	

	
	
	
		<div style="background-color: rgb(145, 137, 137)" class="modal1 " id="buyer-guide-model" role="dialog" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<img src="../../../assets/images/buyer-title-icon.jpg">
						<h4 class="modal-title">Add Systems</h4>
						<button type="button" class="close" data-dismiss="modal1" (click)="close()">&times;</button>
					</div>
					<div class="modal-body1">
						<form id="editForm" #editForm="ngForm" (ngSubmit)="save()">
						<div class="stock-rw">
							<label>Defaults name</label>
							<input 
                            [ngModel]="vehicleBuyersGuideSystemsCovered?.name"
                            (ngModelChange)="vehicleBuyersGuideSystemsCovered.name = $event"
                            name="name"
							#name="ngModel"
                            required
							type="text"  value="110530" class="cstm-input">
						</div>

						<div class="warranty-form">
							<div class="inner">
								<h2 class="war-title">Systems</h2>

								<div class="stytem-grid-sec">
									<div class="row">
										<div class="col-md-6 cstm-grid">
											<label>System Covered:</label>
											<input type="text"
											(ngModelChange)="vehicleBuyersGuideSystemsCovered.systemCovered1 = $event"
											systemCovered1="parts"
											[ngModel]="vehicleBuyersGuideSystemsCovered?.systemCovered1"
											#systemCovered1="ngModel"
											name="ngModel" class="cstm-input">
											<input type="text"
                      (ngModelChange)="vehicleBuyersGuideSystemsCovered.systemCovered2 = $event"
											name="systemCovered2"
											[ngModel]="vehicleBuyersGuideSystemsCovered?.systemCovered2"
											#systemCovered2="ngModel"
                       class="cstm-input">
											<input type="text" 
                      (ngModelChange)="vehicleBuyersGuideSystemsCovered.systemCovered3 = $event"
											[ngModel]="vehicleBuyersGuideSystemsCovered?.systemCovered3"
											#systemCovered2="ngModel"
                      name="systemCovered3"
                       class="cstm-input">
											<input type="text"  
                      (ngModelChange)="vehicleBuyersGuideSystemsCovered.systemCovered4 = $event"
											[ngModel]="vehicleBuyersGuideSystemsCovered?.systemCovered4"
											#systemCovered4="ngModel"
                      name="systemCovered4" class="cstm-input">
											<input type="text" 
                      (ngModelChange)="vehicleBuyersGuideSystemsCovered.systemCovered5 = $event"
											[ngModel]="vehicleBuyersGuideSystemsCovered?.systemCovered5"
											#systemCovered5="ngModel"
                      name="systemCovered5" class="cstm-input">
										</div>
										<div class="col-md-6 cstm-grid">
											<label>Duration:</label><br>
											<input type="text" 
                      (ngModelChange)="vehicleBuyersGuideSystemsCovered.durationCovered1 = $event"
											[ngModel]="vehicleBuyersGuideSystemsCovered?.durationCovered1"
											#durationCovered1="ngModel"
                      name="durationCovered1" class="cstm-input">
											<input type="text" 
                      (ngModelChange)="vehicleBuyersGuideSystemsCovered.durationCovered2 = $event"
											[ngModel]="vehicleBuyersGuideSystemsCovered?.durationCovered2"
											#durationCovered2="ngModel"
                      name="durationCovered2" class="cstm-input">
											<input type="text"
                      (ngModelChange)="vehicleBuyersGuideSystemsCovered.durationCovered3 = $event"
											[ngModel]="vehicleBuyersGuideSystemsCovered?.durationCovered3"
											#durationCovered3="ngModel"
                      name="durationCovered3" class="cstm-input">
											<input type="text" 
                      (ngModelChange)="vehicleBuyersGuideSystemsCovered.durationCovered4 = $event"
											[ngModel]="vehicleBuyersGuideSystemsCovered?.durationCovered4"
											#durationCovered4="ngModel"
                      name="durationCovered4" class="cstm-input">
											<input type="text"
                      (ngModelChange)="vehicleBuyersGuideSystemsCovered.durationCovered5 = $event"
											[ngModel]="vehicleBuyersGuideSystemsCovered?.durationCovered5"
											#durationCovered5="ngModel"
                      name="durationCovered5" class="cstm-input">
										</div>
									</div>
								</div>
							</div>
							<div class="warranty-footer">

								<div class="btn-sec">
									<ul>
										<li><button class="cstm-btn" (click)="save()">Save</button></li>
										<li class="pos-right"><button (click)="close()" class="cstm-btn" data-dismiss="modal">Close</button></li>
									</ul>
								</div>
							</div>
						</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	
