import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-left-sidmenu',
  templateUrl: './left-sidmenu.component.html',
  styleUrls: ['./left-sidmenu.component.css']
})
export class LeftSidmenuComponent implements OnInit {
  selectedCompany1: UserAssignedToCompany;
  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
  }
  getDashBoard() {
    this.router.navigate(['saledashboard/' + this.selectedCompany1.parentGuid]);
  }

  getActiveSales() {
    this.router.navigate(['salegrid/' + this.selectedCompany1.parentGuid]);
  }
  addSale() {
    this.router.navigate(['addsale/' + this.selectedCompany1.parentGuid]);
  }
}
