<div class="customer-form-sec">
  <div class="container-fluid">
    <br>
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <div class="form-row">
                      <label  class="vehicle-normallabel">Paragraph</label>								
                        <select tabindex="-1" class="cstm-select" 
                        #amoInstallments="ngModel"  
                        name="amoInstallmentsId"  
                        [(ngModel)]="selectedparagraph"							 
                        (ngModelChange)="changeParagraph($event)" >
                        <option style="color: #bb400f;" [value]="-1">None</option>
                        <option *ngFor="let item of websiteMenuParagrahps"     [ngValue]="item">{{item?.title}}</option>
                        </select>
                    </div>
                    <app-websitemenu-paragraph [websiteMenuParagrahp]="websiteMenuParagrahp" [websiteMenu] = "websiteMenu" (cancel)="cancelUpdate()"  (websiteChanged)="updateWebsite($event)" *ngIf="selectedIndex> -1" [website] = "website" [websiteMenuParagrahp] = "websiteMenuParagrahp" ></app-websitemenu-paragraph>
                  </div><!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div><!-- /.row -->
        </div>
      </div>
      
    </div>
  </div>
</div>
