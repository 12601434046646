import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import moment from 'moment';
import { Item } from 'src/app/_models/item';
import { AmoLoan } from 'src/app/_models/amoloans';
import { PaymentReceiptDto } from 'src/app/loans/loan-list/printreceiptdto';
@Injectable({ providedIn: 'root' })
export class AmoLoanDataFill {

    moment = require('moment');
    convertDate(date) {
        const momentDate = moment().format('L').toString();
        return momentDate;
}
      fillPdfFieldsReceipt(entity: PaymentReceiptDto, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
        console.log('FILL RECIEPT DATA FIELDS REACHED.');

    let  field = '';
     let newpdfField = {} as PdfSaleFields;
     const newPdfFields = [] as PdfSaleFields[];
       pdfFields.forEach(element => {
           field = element.field;
           const str = field;
           const matches = str.match(/\d+/g);
       let i;
       if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }
           field = field.replace(i.toString(), '');
           newpdfField.groupBy = 'Receipt';
            switch (field) {
                case prefix + 'BalanceMisc': {
                    newpdfField  = {
                        field: prefix + 'BalanceMisc' + i,
                        value: entity?.balanceMisc.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'CustomerAddress': {
                    newpdfField  = {
                        field: prefix + 'CustomerAddress' + i,
                        value: entity?.customerAddress.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'CustomerFirstName': {
                    newpdfField  = {
                        field: prefix + 'CustomerFirstName' + i,
                        value: entity?.customerFirstName.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'CustomerId': {
                    newpdfField  = {
                        field: prefix + 'CustomerId' + i,
                        value: entity?.customerId.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'CustomerLastName': {
                    newpdfField  = {
                        field: prefix + 'CustomerLastName' + i,
                        value: entity?.customerLastName.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'CustomerPayoff': {
                    newpdfField  = {
                        field: prefix + 'CustomerPayoff' + i,
                        value: entity?.customerPayoff.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'CustomerPhone': {
                    newpdfField  = {
                        field: prefix + 'CustomerPhone' + i,
                        value: entity?.customerPhone.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'CustomerStreet': {
                    newpdfField  = {
                        field: prefix + 'CustomerStreet' + i,
                        value: entity?.customerStreet.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'DatePaid': {
                    newpdfField  = {
                        field: prefix + 'DatePaid' + i,
                        value: this.convertDate(entity?.datePaid),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'DeferredBalance': {
                    newpdfField  = {
                        field: prefix + 'DeferredBalance' + i,
                        value: entity?.deferredBalance.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'GrossDeferredDown': {
                    newpdfField  = {
                        field: prefix + 'GrossDeferredDown' + i,
                        value: entity?.grossDeferredDown.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'GrossLoan': {
                    newpdfField  = {
                        field: prefix + 'GrossLoan' + i,
                        value: entity?.grossLoan.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'GrossMisc': {
                    newpdfField  = {
                        field: prefix + 'GrossMisc' + i,
                        value: entity?.grossMisc.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'PaymentId': {
                    newpdfField  = {
                        field: prefix + 'PaymentId' + i,
                        value: entity?.paymentId.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'SaleId': {
                    newpdfField  = {
                        field: prefix + 'SaleId' + i,
                        value: entity?.saleId.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'SumPaidToday': {
                    newpdfField  = {
                        field: prefix + 'SumPaidToday' + i,
                        value: entity?.sumPaidToday.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TodayDeferredDown': {
                    newpdfField  = {
                        field: prefix + 'TodayDeferredDown' + i,
                        value: entity?.todayDeferredDown.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TodayInterestPaid': {
                    newpdfField  = {
                        field: prefix + 'TodayInterestPaid' + i,
                        value: entity?.todayInterestPaid.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TodayLateFee': {
                    newpdfField  = {
                        field: prefix + 'TodayLateFee' + i,
                        value: entity?.todayLateFee.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TodayLoanPaid': {
                    newpdfField  = {
                        field: prefix + 'TodayLoanPaid' + i,
                        value: entity?.todayLoanPaid.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TodayMisc': {
                    newpdfField  = {
                        field: prefix + 'TodayMisc' + i,
                        value: entity?.todayMisc.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TotalDefferredPaid': {
                    newpdfField  = {
                        field: prefix + 'TotalDefferredPaid' + i,
                        value: entity?.totalDefferredPaid.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TotalInterestPaid': {
                    newpdfField  = {
                        field: prefix + 'TotalInterestPaid' + i,
                        value: entity?.totalInterestPaid.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TotalLoanPaid': {
                    newpdfField  = {
                        field: prefix + 'TotalLoanPaid' + i,
                        value: entity?.totalLoanPaid.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TotalMisPaid': {
                    newpdfField  = {
                        field: prefix + 'TotalMisPaid' + i,
                        value: entity?.totalMisPaid.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                // case prefix + 'DateAdded': {
                //     newpdfField  = {
                //         field: prefix + 'DateAdded' + i,
                //         value: this.convertDate(entity?.addOnRate),
                //         calculate: 'false',
                //         groupBy: groupByField
                //     };
                //     newPdfFields.push(newpdfField);
                //    break;
                // }
            }
        });
        return of(newPdfFields);
   }
}
