import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { FormNameLabelsService } from 'src/app/_services/formnamelabels/form-name-labels.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormName } from 'src/app/_models/formName';
import { Label } from 'src/app/_models/label';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { FormNameModalComponent } from '../label-modal/form-name-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ConfirmService } from 'src/app/_services/confirm.service';

@Component({
  selector: 'app-edit-form-labels',
  templateUrl: './edit-form-labels.component.html',
  styleUrls: ['./edit-form-labels.component.css']
})
export class EditFormLabelsComponent implements OnInit {
  formLabelNames: FormName;
  selectedCompany1: UserAssignedToCompany;
  formNames: FormName[];
  labels: Label[];
  isDelete: boolean;
  bsModalRef: any;
  constructor(  private confirmService: ConfirmService, private modalService: BsModalService, private authService: AuthService, private route: ActivatedRoute,
    private formNameService: FormNameLabelsService, private alertify: ToastrService,
    private router: Router) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.formLabelNames = data.formLabelNames;
    });
 //   console.log(this.formLabelNames);
    this.labels = this.formLabelNames.labels;
    this.authService.currentCompany.subscribe(company => {this.selectedCompany1 = company; });
  }


  edit(e: Label) {
    const initialState = {
      labelName:  e,
      isAdd:  false
    };
    this.bsModalRef = this.modalService.show(FormNameModalComponent, { initialState });
      this.bsModalRef.content.formLabel.subscribe((values) => {
      e.value = values.value;
      this.formNameService.editFormLabel(this.selectedCompany1.parentGuid, e.id, e.value).subscribe(data => {
        this.formNames = data;
      }, error => {this.alertify.error(error); }, () => { this.alertify.success('Label Updated'); });
    });
  }

  addLabel() {
    const initialState = {
      isAdd:  true
    };
    this.bsModalRef = this.modalService.show(FormNameModalComponent, {initialState});
    this.bsModalRef.content.formLabel.subscribe((values) => {
      console.log(values.value);
      this.formNameService.addFormLabel(this.selectedCompany1.parentGuid, values.value, this.formLabelNames.id).subscribe(data => {
       console.log(data);
        this.labels = data.labels;
      }, error => {this.alertify.error(error); }, () => {this.alertify.success('Label Added'); });
    });
  }

  delete(e: Label) {
    console.log(e.id);
    this.confirmService.confirm('Confirm delete label', 'Are you sure?').subscribe(result => {
      if (result) {
        this.formNameService.deleteFormLabel(this.selectedCompany1.parentGuid, this.formLabelNames.id, e.id).subscribe(data => {
          this.labels = data.labels;
        }, error => { this.alertify.error(error); }, () => {this.alertify.success('Label Deleted');  });
    }
    });
  }
}
