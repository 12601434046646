import { AddressDetail } from './AddressDetail';
import { PersonPhoto } from './personphoto';
import { Vendor } from './vendor';

export class Person {
    id: number;
    personPhotos: PersonPhoto[];
    insuranceExpiration: string;
    parentGuid: string;
    tennantGuid: string;
    email: string;
    firstName: string;
    lastName: string;
    middleName: string;
    phone: string;
    photoUrl: string;
    addressDetail: AddressDetail;
    employer: Vendor;
    employerId: number;
    driversLicense: string;
    driversLicenseState: string;
    driverLicenseExpires: Date;
    dateOfBirth: Date;
    insuranceExpires: Date;
    insurancePolicy: string;
    insuranceCompany: string;
    insuranceFire: boolean;
    insuranceTheft: boolean;
    insuranceCollision: boolean;
    insuranceDeductible: number;
    extraNumber: string;
    postedQuickBooksTime?: Date;
}
