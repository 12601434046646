<br><br><br>
<div class="customer-form-sec">
    <div class="container-fluid">
<div class="row">
    <div class="form-sec tab-content col-md-11">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="d-flex justify-content-right">
                <pagination
              [boundaryLinks]="true"
              pageBtnClass = "nav nav-tabs"
                  [(ngModel)]="pagination.currentPage"
                  [totalItems]="pagination.totalItems"
                  (pageChanged)="pageChanged($event)"
                  [itemsPerPage]="pagination.itemsPerPage"
                  previousText="&lsaquo;"
                  nextText="&rsaquo;"
                  firstText="&laquo;"
                  lastText="&raquo;"
                >
                </pagination>
              </div>
              <div class="inner-row account-payable-row">
                <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->
                
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <table border="1" borderColor="#ccc">
                      <tr>
            <th>Id</th>
            <th>Department</th>
						<th>Name</th>
                        <th>Phone</th>
                        <th>Avatar</th>
                      </tr>
                      <tr class="td-row" *ngFor="let item of persons">
                        <td>{{item.id}}</td>
                        <td>{{item.department}}</td>
                        <td>{{item?.firstName}}&nbsp; {{item?.lastName}}</td>
                        <td>{{item?.phone}}</td>
                        <td><img *ngIf="item?.imageUrl" [src]="item?.imageUrl" height="50px;"></td>
                        <td>
                          <a
                          [ngClass]="{'btn btn-danger' : !item.id , 'btn btn-success' : item.id}"
                          (click)="delete(item)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Is Active"
                          >Remove<i  class="fa fa-fw fa-check-o"></i
                        ></a>
                        </td>
                          <td >
                          <a
                            class="btn btn-primary"
                            (click)="editDepartMent(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit Department"
                            ><i class="fa fa-fw fa-files-o"></i
                          >Edit Department</a>
                        </td>
                        <td>
                          <a
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit Person Details"
                            class="btn btn-outline-success"
                            (click)="editPerson(item)"
                          >
                            Edit Person Details
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-1">
        <div class="inner-sidebar">
          <ul>
            <li  > 
              <button type="submit" (click) = "addNew()" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png">
                <span>New Employee</span>
              </button>
            </li>
            <li  > 
              <button type="submit" (click) = "addExisting()" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png">
                <span>Add Existing</span>
              </button>
            </li>
      
          </ul>
        </div>
      </div>
      </div>
</div>



