import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { Router } from '@angular/router';
import {  PaginatedResult, Pagination  } from 'src/app/_models/pagination';

import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { QuickBooksService } from 'src/app/_services/quickbooksservices/quickbooksservice';
import { VehicleService } from 'src/app/_services/vehicleserv/vehicle.service';


@Component({
  selector: 'app-produts-filters',
  templateUrl: './produts-filters.component.html',
  styleUrls: ['./produts-filters.component.css']
})
export class ProdutsFiltersComponent implements OnInit {
  @Input() selectedTab: any;
  @Input() pagination: Pagination;
  @Output() userParamsExport:
  EventEmitter<any> =  new EventEmitter<any>() ;
  @Output() pageChangeExport:
  EventEmitter<any> =  new EventEmitter<any>() ;
  userParams = {} as any;
  selectedCompany1: UserAssignedToCompany;
  isActive = false;
  isAll = false;
  isPublishedNeedsImages = false;
  isPublishedAll = false;
  isPublishedSold = false;
  isProfitReport = false;
  isNonPublishedActive = false;
  isSold = false;
  isWeblist = false;
  isQuickBooks = false;
  maxSize = 50;
  constructor(private router: Router, private vehicleService: VehicleService,
    private authService: AuthService,
    private qbService: QuickBooksService) {
    this.userParams =  this.vehicleService.getUserParams();
     }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
  //  this.selectTab(this.selectTab);
  if (this.router.url.includes('/productlist/') && !this.router.url.includes('/aging')) {
    this.isWeblist = true;
    this.isAll = true;
    this.isProfitReport = false;
    this.isQuickBooks = false;
  }
  if ( !this.router.url.includes('/qblist')) {
    this.isWeblist = true;
    this.isAll = true;
    this.isProfitReport = false;
    this.isQuickBooks = false;
  }
  if (this.router.url.includes('/qblist')) {  this.isProfitReport = true;
    this.isWeblist = false;
    this.isAll = true;
    this.isProfitReport = false;
    this.isQuickBooks = true;
  }
  }
  pageChanged(event: any): void {
    if (this.pagination.currentPage !== event.page) {
      // do the pagination
      this.userParams.pageNumber = event.page;
      // this.pagination.currentPage = event.page;
       this.pageChangeExport.emit(event);
    }
  }
  selectTab(e) {
  
    this.isActive = false;
    this.isAll = false;
    this.isQuickBooks = false;
    this.isPublishedNeedsImages = false;
    this.isPublishedAll = false;
    this.isPublishedSold = false;
    this.isProfitReport = false;
    this.isNonPublishedActive = false;
    this.isSold = false;
    if (e === 'isQuickBooks') {
      this.isQuickBooks = true;
    }
    if (e === 'all') {
      this.isAll = true;
    }
    if (e === 'active') {
      this.isActive = true;
    }
    if (e === 'nonPublishedActive') {
      this.isNonPublishedActive = true;
    }
    if (e === 'sold') {
      this.isSold = true;
    }
    if (e === 'publishedNeedsImages') {
      this.isPublishedNeedsImages = true;
    }
    if (e === 'publishedAll') {
      this.isPublishedAll = true;
    }
    if (e === 'publishedSold') {
      this.isPublishedSold = true;
    }
  }
  filter(e) {
    this.isActive = false;
    this.isAll = false;
    this.isPublishedNeedsImages = false;
    this.isPublishedAll = false;
    this.isPublishedSold = false;
    this.isNonPublishedActive = false;
    this.isSold = false;
    this.isActive = false;
    console.log(e);
    this.userParamsExport.emit(e);
    // this.vehicleService.setUserParams(e);
   // this.userParams.filter = e;
   if (e === 'isQuickBooks') {
    this.isQuickBooks = true;
  }
    if (e === 'all') {
      this.isAll = true;
    }

    if (e === 'active') {
      this.isActive = true;
    }
    if (e === 'nonPublishedActive') {
      this.isNonPublishedActive = true;
    }
    if (e === 'sold') {
      this.isSold = true;
    }
    if (e === 'publishedNeedsImages') {
      this.isPublishedNeedsImages = true;
    }
    if (e === 'publishedAll') {
      this.isPublishedAll = true;
    }
    if (e === 'publishedSold') {
      this.isPublishedSold = true;
    }
  }
  gridChange(e) {
    this.isProfitReport = false;
    this.isWeblist = false;
    this.isQuickBooks = false;

    if (e === 'isProfitReport') {
      this.isProfitReport = true;
      this.router.navigate(['/vehicledashboard/aging/' + this.selectedCompany1.parentGuid]);
    }
    if (e === 'weblist') {
      this.isWeblist = true;
      this.router.navigate(['/vehicledashboard/productlist/' + this.selectedCompany1.parentGuid]);
    }
    if (e === 'isQuickBooks') {
      console.log(e);
      localStorage.setItem('redirectRoute', 'vehicleList');
      this.qbService.setRedirectRoute('vehicleList');
      this.isQuickBooks = true;
      this.qbService.login(this.selectedCompany1.parentGuid).subscribe(data =>{}, err => {
        console.log(err);
      });
     // this.router.navigate(['/vehicledashboard/qblist/' + this.selectedCompany1.parentGuid]);
    }
  }
}
