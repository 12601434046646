import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../_modules/shared.module';
import { QuickBooksRoutingModule } from './quickbooks-routing.module';
import { QbConnectComponent } from './qb-connect/qb-connect.component';
import { QuickbooksConnectedComponent } from './quickbooks-connected/quickbooks-connected.component';

@NgModule({
    declarations: [
        QbConnectComponent,
        QuickbooksConnectedComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        QuickBooksRoutingModule,
    ]
})
export class QuickBooksModule { }
