<div class="modal-header">
  <h4 class="modal-title pull-left" *ngIf="!isAdd">Edit Label</h4>
  <h4 class="modal-title pull-left" *ngIf="isAdd">Add Label</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  
<div class="col-md-12">
  <div class="row">
    <div class="col-md-12">
        <form #editForm="ngForm" id="editForm">
        <div class="amount-rw">
            <div class="row">
              <div class="col-md-12 left-grid">
                  <div *ngIf="!isAdd" class="col-md-12 cstm-col">
                  Default Value: &nbsp;&nbsp; {{ labelName?.valueDefault }}
                  </div>
                  <div class="col-md-12 cstm-col">
                      <label *ngIf="!isAdd">New Name:</label>
                      <label *ngIf="isAdd">New Field Name:</label>
                      <div class="form-group" [ngClass]="{
                        'has-success': value?.valid && (value?.touched  || value?.dirty),
                        'has-danger': value?.invalid && (value?.touched  || value?.dirty)
                    }">
                    <input
                    value=""
                type="text"
                autocomplete="off"
                [ngModel]="labelName?.value"
                (ngModel)="labelName.value = $event"
                name="value"
                #value="ngModel"
                required
                  />
                  </div>
                  </div>
                  </div>
            </div>
          </div>
       
    </form>
        </div>
  
  </div>
</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-success" (click)="update()" form="editForm">Submit</button>
  </div>


