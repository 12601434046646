<div class="col-md-12 ">
  <div class="row">

  <div class="col-md-12">
    <br>  
<div class="container mt5">
    <div class="row">
   
      <div class="col-12">
    <div class="form-inline mt-2">
      <app-paging-header
      [totalCount] = "totalCount"
      [pageSize] = "shopParams.pageSize"
      [pageNumber] = "shopParams.pageNumber"
    ></app-paging-header>
    </div>
        <br><br>
        <div class="form-inline mt-2">
          <input (keyup.enter)="onSearch()" class="form-control mr-2" ng-model="searchValue" #search style="width: 300px;" type="text" placeholder="search">
          <button (click)="onSearch()"  class="btn btn-outline-primary my-2" >Search</button>
         
          <!-- <button (click)="addProduct()" class="btn btn-outline-success ml-2 my-2" >Add Product</button> -->
            <label class="mr-2 ml-2">Form Type</label>
            <select style="width: 140px;"  class="form-control mr-2" #pdfFormType="ngModel" ngControl="selectedType"  name="pdfFormTypeId"    
            [(ngModel)] ="pdfForm.pdfFormTypeId"  (ngModelChange)="formTypeSelected($event)"
             >
            <option *ngFor="let item of pdfFormTypes; index as i"   [value]="item.id">{{item?.value}}</option>
            </select>
            <label class="mr-2 ml-2">Form States</label>
            <select style="width: 140px;"  class="form-control mr-2" #pdfFormState="ngModel" ngControl="selectedType"  name="pdfFormTypeId"    
            [(ngModel)] ="pdfForm.id"  (ngModelChange) = "onCategorySelected($event)" 
             >
            <option *ngFor="let item of supportSubMenusPdf; index as i"   [value]="item.id">{{item?.name}}</option>
            </select>
            <button (click)="onResetSearch()" class="btn btn-outline-success ml-2 my-2" >Reset</button>
        </div>
    <!-- grid -->
  <div class="row">
    <div class="col-12">
      <table class="table table-hover" style="cursor: pointer;">
        <thead class="thead-light">
        <tr>
            <td>Icon</td>
          <td>Id</td>
          <td>Image?</td>
          <th>Name</th>
          <!-- <th>Price</th>-->
          <th>Active?</th>
          <th>Image</th>
          <th>Description</th>
          <th>Type</th>
          <th>States</th> 
         
          <th>Delete?</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let order of pdfListGridViewDto">
            <th><i class="fa fa-file-pdf-o"></i> </th>
          <th># {{order.id}}</th>
          <td  *ngIf="order.pdfFormImageUrl === null" > <i style="color: red;" class=" fa fa-check-circle "></i></td>
          <td  *ngIf="order.pdfFormImageUrl !== null"> <i style="color: green;" class=" fa fa-check-circle"></i></td>
          <th>{{order.name}}</th>
          <!-- <td>{{order.price | currency}}</td>
          <td>{{ order?.descriptionSmall}}</td>
          <td>{{order.price | currency}}</td> -->
          <td  *ngIf="order.deletedDate !== null" > <i style="color: red;" class=" fa fa-check-circle "></i></td>
          <td  *ngIf="order.deletedDate === null"> <i style="color: green;" class=" fa fa-check-circle"></i></td>
          <td class="align-middle text-center">
            <a class="text-info">
                <i class="fa fa-photo" style="font-size: 2em; cursor: pointer;"></i>
            </a>
        </td>
       
          <td class="align-middle text-center">
            <a class="text-danger">
              <button class="btn btn-outline-primary my-2" (click)="installPdf(order)">Install To Cloud</button>
            </a>
        </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
</div>
<div class="d-flex justify-content-center" *ngIf="totalCount && totalCount > 0">
    <app-pager
    [pageSize]="shopParams.pageSize"
    [totalCount]="totalCount"
    (pageChanged)="onPageChange($event)"
    ></app-pager>
    </div>
    </div>
    </div>
    </div>
    </div>
