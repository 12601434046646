import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { VehicleCashDownDropDown } from 'src/app/_models/VehicleCashDownDropDown';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsiteCashDownDropDownsService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;

    update(vehicleCashDownDropDown: VehicleCashDownDropDown, id, websiteId): Observable<VehicleCashDownDropDown[]> {
      return this.http.put<VehicleCashDownDropDown[]>
      (this.baseUrl + 'websitecashdown/editvehiclecashdowndropdown/' + id + '/' + websiteId, vehicleCashDownDropDown);
    }

    get( websiteId): Observable<VehicleCashDownDropDown[]> {
      return this.http.get<VehicleCashDownDropDown[]>
      (this.baseUrl + 'websitecashdown/getcashdowns/' + websiteId);
    }
  }
