import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { FileUploader } from 'ng2-file-upload';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ContentPhoto } from 'src/app/_models/websites/ContentPhoto';
import { WebsiteMenu } from 'src/app/_models/websites/WebsiteMenu';
import { ContentPhotoReturnedDto } from 'src/app/_models/websites/ConentPhotoReturnedDto';
@Component({
  selector: 'app-uploadimage-modal',
  templateUrl: './uploadimage-modal.component.html',
  styleUrls: ['./uploadimage-modal.component.scss']
})
export class UploadimageModalComponent implements OnInit {
  @Input() photos: ContentPhoto[];
  @Input() item: any;
  @Output() entityToSubscribe = new EventEmitter<ContentPhoto>();
  public uploader: FileUploader;
  @Input() websiteMenu = {} as WebsiteMenu;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  currentMain: any;
  contentPhoto = {} as ContentPhoto;

  constructor(public modalRef: BsModalRef,
    private alertify: ToastrService) {
    }

  ngOnInit() {
          this.contentPhoto.isAvatar = false;
          this.contentPhoto.isBanner = true;
          this.contentPhoto.isPageImage = false;
  //  //  this.photos = this.item.vehicle.photosOfVehicles;
      this.initializeUploader();
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  initializeUploader() {
    // url is url to post like a service
    this.uploader = new FileUploader({
      url: this.baseUrl + 'websitephotos/addmenuphoto/' + this.websiteMenu.id +
      '/' + this.contentPhoto.isPageImage + '/' + this.contentPhoto.isBanner,
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: ['image'],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024
    });

    this.uploader.onAfterAddingFile = (file) => {file.withCredentials = false; };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      if (response) {
        console.log(response);
        const res: ContentPhotoReturnedDto = JSON.parse( response);
        const photo = {
          id: res.id,
          url: res.url,
          dateAdded: res.dateAdded,
          isAvatar: res.isAvatar,
          sortOrder: res.id,
          fileName: res.fileName,
          isPageImage: res.isPageImage,
          isBanner: res.isBanner,
          alt: res.alt,
          originalName: res.originalName,
          deletedDate: res.deletedDate,
          isGallery: res.isGallery,
          websiteMenuId: res.websiteMenuId,
          nameDisplay: res.nameDisplay,
        };
       this.photos.push(photo);
      //  console.log(photo);
      this.entityToSubscribe.next(photo);
      this.modalRef.hide();
        this.createArray();
      }
    };
  }
  createArray() { console.log('This isuploaded'); }
  bannerChecked(e) { console.log(e); this.initializeUploader(); }
  galleryChecked(e) { console.log(e); this.initializeUploader(); }

}
