import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-LabelDoubleClickModal',
  templateUrl: './LabelDoubleClickModal.component.html',
  styleUrls: ['./LabelDoubleClickModal.component.css']
})
export class LabelDoubleClickModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  labelName: any;
  constructor(public bsModalRef1: BsModalRef) { }

  ngOnInit() {
  }
  updateGap() {

    console.log(this.labelName);
    this.emittedValues.emit(this.labelName);
    this.bsModalRef1.hide();
  }
  onChange(e) {}
}

