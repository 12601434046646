import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { AmoInstallment } from 'src/app/_models/AmoInstallment';
import { AmoPayment } from 'src/app/_models/amopayments';
import { Dollars } from 'src/app/_models/dollars';
import { AmoLinqPayments } from 'src/app/_models/DTOS/amolinqpayments';
import { AmoPaymentsDeferredTax } from 'src/app/_models/DTOS/amopaymentsdeferredtaxdto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AccountingService {
baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }
  get(id: number): Observable<AmoInstallment> {
    return this.http.get<AmoInstallment>(this.baseUrl + 'amoinstallment/get/' + id);
  }

  getList(parentguid: string): Observable<AmoInstallment[]> {
    return this.http.get<AmoInstallment[]>(this.baseUrl + 'amoinstallment/getlist/' + parentguid);
  }

  retrievePagedRecievables(parentGuid, page?, itemsPerPage?, userParams?): Observable<PaginatedResult<Dollars[]>> {
    const paginatedResult: PaginatedResult<Dollars[]> = new PaginatedResult<Dollars[]>();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    console.log(itemsPerPage);
    if (userParams != null) {
      if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
      if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
      if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
      if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
      if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
      if (userParams.saleTypeId !== undefined) { params  = params.append('saleTypeId', userParams.saleTypeId); }
      if (userParams.saleStatusId !== undefined) { params  = params.append('saleStatusId', userParams.saleStatusId); }
      if (userParams.yearForDate !== undefined) { params  = params.append('yearForDate', userParams.yearForDate); }
      if (userParams.isAll !== undefined) { params  = params.append('isAll', userParams.isAll); }
      if (userParams.monthOfYear !== undefined) { params  = params.append('monthOfYear', userParams.monthOfYear); }
    }
    return this.http.get<Dollars[]>(this.baseUrl + 'accounting/getdollarsbydate/' + parentGuid, {observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }


  retrievePagedRecievablesForSale(parentGuid, itemId, page?, itemsPerPage?, userParams?): Observable<PaginatedResult<Dollars[]>> {
    const paginatedResult: PaginatedResult<Dollars[]> = new PaginatedResult<Dollars[]>();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    console.log(itemsPerPage);
    if (userParams != null) {
      if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
      if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
      if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
      if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
      if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
      if (userParams.saleTypeId !== undefined) { params  = params.append('saleTypeId', userParams.saleTypeId); }
      if (userParams.saleStatusId !== undefined) { params  = params.append('saleStatusId', userParams.saleStatusId); }
      if (userParams.yearForDate !== undefined) { params  = params.append('yearForDate', userParams.yearForDate); }
      if (userParams.isAll !== undefined) { params  = params.append('isAll', userParams.isAll); }
      if (userParams.monthOfYear !== undefined) { params  = params.append('monthOfYear', userParams.monthOfYear); }
    }
    return this.http.get<Dollars[]>(this.baseUrl + 'accounting/getdollarsforsalebyitemid/' + parentGuid + '/' + itemId, {observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

}

