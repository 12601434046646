import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { QbPersonService } from 'src/app/_services/quickbooksservices/qb-personservice';
import { QuickBooksService } from 'src/app/_services/quickbooksservices/quickbooksservice';

@Component({
  selector: 'app-quickbooks-connected',
  templateUrl: './quickbooks-connected.component.html',
  styleUrls: ['./quickbooks-connected.component.css']
})
export class QuickbooksConnectedComponent implements OnInit {
userAssigntoCompany: UserAssignedToCompany;
realmId: string;
route: string;
redirectRoute: string;
  constructor(private qbService: QuickBooksService,
    private authService: AuthService,
    private router: Router,
    private qbPersonService: QbPersonService) { }

  ngOnInit() {
    // this.router.navigate(['/vehicledashboard/qblist/' + this.selectedCompany1.parentGuid]);
    this.qbService.getRealmId().subscribe(data => { this.realmId = data; });
    this.authService.company.subscribe(data => { this.userAssigntoCompany = data; });
    this.qbService.getRedirectRoute().subscribe(data => { this.redirectRoute = data;  this.redirect();}  );
  }
  redirect() {
    switch (this.redirectRoute) {
      case 'peopleList': {
         this.route = '/dashboardpeople/listqb/' + this.userAssigntoCompany.parentGuid;
         this.router.navigate([this.route]);
         break;
      }
      case 'vehicleList': {
        this.router.navigate(['/vehicledashboard/qblist/' + this.userAssigntoCompany.parentGuid]);
        break;
      }
      case 'qblist': {
        this.router.navigate(['/listqb/qbsaledefaultslist/' + this.userAssigntoCompany.parentGuid + '/' + this.realmId]);
        break;
      }
      default: {
         break;
      }
   }
  }
  addcustomer() {
    this.qbService.createCustomer(this.userAssigntoCompany.parentGuid, this.realmId).subscribe(data => {

    });
  }
}
