import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ClmVehicle } from 'src/app/_models/_models/clmvehicle';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { ClmVehicleService } from 'src/app/_services/clmvehiclesservices/clmvehicles.service';

@Component({
    selector: 'app-company-import',
    templateUrl: 'company-import.component.html',
    styleUrls: ['company-import.component.scss']
})
export class CompanyImportComponent implements OnInit {
isVehicles = true;
isCost = false;
isExpenses = false;
isInsurance = false;
isSimpleInterest  = false;
isPerson = false;
isLienHolders = false;
isPrecomputed = false;
clmVehicle = {} as ClmVehicle;
clmVehicles = [] as ClmVehicle[];
userAssignedToCompany = {} as UserAssignedToCompany;

constructor(private vehicleService: ClmVehicleService,
    private authService: AuthService,
    private alertify: ToastrService) { }

ngOnInit(): void {
    this.authService.currentCompany.subscribe(data => { this.userAssignedToCompany = data;  });

}
tabClicked(e) {
    console.log(e);
    this.isVehicles = false;
    this.isSimpleInterest = false;
    this.isCost = false;
    this.isPerson = false;
    this.isInsurance = false;
    this.isExpenses = false;
    this.isLienHolders = false;
    this.isPrecomputed = false;
    if(e == 'isSimpleInterest') {this.isSimpleInterest = true;}
    if(e == 'isPrecomputed') {this.isPrecomputed = true;}
    if(e == 'isLienHolders') {this.isLienHolders = true;}
    if(e == 'isInsurance') {this.isInsurance = true;}
    if(e == 'isExpenses') {this.isExpenses = true;}
    if(e == 'isVehicles') {this.isVehicles = true;}
    if(e == 'isCost') {this.isCost = true;}
    if(e == 'isPerson') {this.isPerson = true;}
}
}
