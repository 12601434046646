import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MiscchargAddComponent } from 'src/app/modals/misccharg-add/misccharg-add.component';
import { MiscchargeEditComponent } from 'src/app/modals/misccharge-edit/misccharge-edit.component';
import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';
import { ProgramDefaults } from 'src/app/_models/ProgramDefaults';
import { Sale } from 'src/app/_models/sale';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AccountingService } from 'src/app/_services/accountingservices/accounting.service';
import { AuthService } from 'src/app/_services/auth.service';
import { DollarService } from 'src/app/_services/dollarservices/dollar.service';

@Component({
  selector: 'app-washout-grid',
  templateUrl: './washout-grid.component.html',
  styleUrls: ['./washout-grid.component.css']
})
export class WashoutGridComponent implements OnInit {
  @Input() dollars = [] as Dollars[];
  @Input() dollarTypes = [] as DollarTypes[];
  @Input() dollarTypesAll = [] as DollarTypes[];
  @Input() sale = {} as Sale;
  isLocked = true;
  bsModalRef: any;
  @Input() programDefaults = {} as ProgramDefaults;
  selectedCompany1: UserAssignedToCompany;
  constructor(private modalService: BsModalService,
    private alertify: ToastrService,
    private accountingService: AccountingService, private authService: AuthService,
    private dollarService: DollarService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => {this.selectedCompany1 = data; });
  }


  addSaleCost() {
  //  console.log(this.sale);
    const config = {
      class: 'modal-lg',
      initialState: {
       sale: this.sale,
       dollarTypes: this.dollarTypes,
      }
    };
    this.bsModalRef = this.modalService.show(MiscchargAddComponent, config);
    this.bsModalRef.content.emittedValues.subscribe(values => {
      this.dollarService.postNewMiscCost(this.selectedCompany1.parentGuid, values, this.sale.id, this.sale.vehiclePurchased.id).subscribe(data => {
        this.dollars = data.dollars;
      }, error => { this.alertify.error(error); }, () => {
        this.alertify.success('Saved');
      //  console.log(this.dollars);
      });
    });
  }

  editItem(e: Dollars) {
    console.log(e);
    if (e.dollarTypes.isAddedCost) {
      e.saleId = null;
    }
    console.log(e);
    const initialState = {
      dollarTypes: this.dollarTypesAll,
      dollar: e,
      isLocked: true
    };
    this.bsModalRef = this.modalService.show(MiscchargeEditComponent, {initialState});
    this.bsModalRef.content.emittedValues.subscribe(values => {
      if (e.saleId == null) {
        this.dollarService.editNewIemRepair(this.selectedCompany1.parentGuid, values, this.sale.vehiclePurchased.id).subscribe(data => {
          // this.dollars = data;
          values.profit = 0;
          values.customerCost = 0;
         }, error => { this.alertify.error(error); }, () => {
           this.alertify.success(' Saved');
         });
      }else {
        this.dollarService.editWashoutDollar(this.selectedCompany1.parentGuid, values, this.sale.id, this.sale.vehiclePurchased.id).subscribe(data => {
          // this.dollars = data;
         }, error => { this.alertify.error(error); }, () => {
           this.alertify.success(' Saved');
         });
      }
    });
  }
}
