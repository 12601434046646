import { Injectable } from '@angular/core';
import moment from 'moment';

import { PDFDocument } from 'pdf-lib';
import { Observable, of } from 'rxjs';
import { ConvertPdfToArraysAndBuffers } from '../pdffielddatats/convertpdf-toarrays';
@Injectable({ providedIn: 'root' })
export class PDFCreateNew {
     moment = require('moment');
    convertDate(date) {
        const momentDate = moment().format('L').toString();
        return momentDate;
}
constructor(private convertPdfToArraysAndBuffers: ConvertPdfToArraysAndBuffers) {}

    async createPdf(title: string, pdfName: string) {
        const pdfDoc = await PDFDocument.create();
        const page = pdfDoc.addPage([612, 792]);
        const u8 = await pdfDoc.save();
        let blob;
        let blobToUpload;
        this.convertPdfToArraysAndBuffers.createBlob(u8, 'pdf/application').subscribe(data => {
            blob = data;
        });
         this.convertPdfToArraysAndBuffers.blobToFile(blob, pdfName).subscribe(data => {
             blobToUpload = data;
         }, error => {console.log(error); }, () => {
             return blobToUpload;
         });
        return  blobToUpload;
    }
}