import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PdfService } from './pdf.service';
import { PdfMergePdfs } from 'src/app/_classes/pdffielddatats/merge-pdfdocuments';
import { PdfFormPackage } from 'src/app/_models/pdfformpackage';
import { Sale } from 'src/app/_models/sale';
import { Observable, of } from 'rxjs';

import { PdfForms } from 'src/app/_models/PdfForms';
import { url } from 'inspector';

@Injectable({
  providedIn: 'root'
})
export class PdfRenderService {
  baseUrl = environment.apiUrl;
  localhostUrl: 'http://localhost:4200'
  constructor(private http: HttpClient, private pdfService: PdfService,
    private pdfMergePdfs: PdfMergePdfs) {  }
    getPDF(){
        const url = `${this.localhostUrl}/pdf`;
        const httpOptions = {
          responseType  : 'arraybuffer' as 'json'
           // 'responseType'  : 'blob' as 'json'        //This also worked
        };
        return this.http.get<any>(url, httpOptions);
        }

        setBase64OfAPdfToCacheToBrPrintedByButtonsInProgram(item: PdfFormPackage, sale: Sale, isWithData, isEmptyForm): Observable<PdfForms> {
          console.log(item);
          this.pdfService.changeIsEmpty(false);
          this.pdfService.changeIsWithData(true);
          let urLList = [];
          let pdfForm = {} as PdfForms;
          this.pdfService.changePdfForm(item.pdfFormsPacks[0].pdfForm);
          item.pdfFormsPacks.forEach(element => {
           urLList.push(element.pdfForm.pdfFormUrl);
          });
        
         //   urLList.push({url: item.pdfFormsPacks[0].pdfForm.pdfFormUrl});
// item.pdfFormsPacks[0].pdfForm.pdfFormUrl
              //   this.pdfService.pdfForm.next(data);
                 this.pdfMergePdfs.copyPages(sale, item.pdfFormsPacks[0].pdfForm.pdfFormUrl, urLList, isWithData, isEmptyForm)
                 .then(data => { urLList = []; });
             console.log('reports manager form click event');

             return of(pdfForm);
        }
}
