import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PdfNewfieldnameComponent } from 'src/app/modals/pdf-newfieldname/pdf-newfieldname.component';
import { PdfnewuploadnameModalComponent } from 'src/app/modals/pdfnewuploadname-modal/pdfnewuploadname-modal.component';
import { PdfListGridViewDto } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/PdfListGridViewDto';
import { ShopParams } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/ShopParams';
import { PdfFormTypesCategory } from 'src/app/_models/PDFentites/PdfFormTypesCategory';
import { PdfForms } from 'src/app/_models/PdfForms';
import { PdfFormTypes } from 'src/app/_models/PdfFormTypes';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { PdfPackageService } from 'src/app/_services/PdfSErvices/pdf-package.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { NewNameModel } from './pdfnewuploadnamemodalmodel';

@Component({
  selector: 'app-pdf-grid',
  templateUrl: './pdf-grid.component.html',
  styleUrls: ['./pdf-grid.component.scss']
})
export class PdfGridComponent implements OnInit {
  @ViewChild('search', {static: true}) public searchTerm: ElementRef<HTMLInputElement>;
  selectedIndex = 0;
  pdfListGridViewDto = [] as PdfListGridViewDto[];
  pdfListGridViewDtoIndividual = {} as PdfListGridViewDto;
  pdfFormTypesCategorys = [] as PdfFormTypesCategory[];
  pdfFormTypesCategorysCache = [] as PdfFormTypesCategory[];
  pdfFormTypesCategory = {} as PdfFormTypesCategory;
  pdfFormTypes = [] as PdfFormTypes[];
  pdfFormTypesNoAll = [] as PdfFormTypes[];
  selectedType = {} as PdfFormTypes;
  pdfForm = {} as PdfForms;
  // supportMenu = [] as SupportMenu[];
  // supportSubMenus = [] as SupportSubMenus[];
  // supportSubMenusPdf = [] as SupportSubMenus[];
  // supportSubMenusPdfCache = [] as SupportSubMenus[];
  // packageButtonLocations = [] as PackageButtonLocations[];
  formNameToUpload: string;
  isUpload = false;
  shopParams =  new ShopParams();
  totalCount: number;
  selectedTypeId: number;
  bsModalRef: any;
  selectedCompany1: UserAssignedToCompany;
  sortOptions = [
    {name: 'Alphabetical', value: 'name'},
    {name: 'Price Low To High', value: 'priceAsc'},
    {name: 'Price High To Low', value: 'priceDesc'},
  ];
  constructor(private pdfService: PdfService,
    private modalService: BsModalService,
    private pdfPackageService: PdfPackageService,
    private authService: AuthService,
    private toastR: ToastrService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
    this.getPdfsPaged();
   // this.getProductTypes();
    this.getProductCategories();
    this.getPdfBUttonLocations();
    this.getSubMenus();
    this.getPdfFormTypeCategoryList();
  }
  getPdfFormTypeCategoryList() {
    this.pdfService.getPdfFormTypeCategoryList(this.selectedCompany1.parentGuid).subscribe(data => {
   //   this.formTypesCache = data.pdfFormTypes;
   console.log(data);
   this.pdfFormTypesCategorys = data;
    }, error => { this.toastR.error(error); }, () => {
      this.toastR.success('Pdfs Retrieved');
      this.pdfPackageService.geFormPackages(this.selectedCompany1.parentGuid).subscribe(data1 => {
        this.pdfPackageService.changePdfFormPackages(data1);
        this.pdfFormTypes =  this.pdfFormTypesCategorys[0].pdfFormTypes;
      }, error => {}, () => {
     //   this.getData();
      });
    });
  }
  formTypeCategorySelected(typeId: number) {
  //  console.log(typeId);
    this.pdfService.getPdfFormTypeListByCategoryId(this.selectedCompany1.parentGuid, typeId).subscribe(data => {
      console.log(data);
      this.pdfFormTypes = data;
    });
  }
  formTypeSelected(typeId) {
    typeId = Number(typeId);
    if (typeId === 0) {
      typeId = 0;
      this.shopParams.typeId = 0;
    }
    else {
      this.shopParams.typeId = typeId;
    }
    this.shopParams.pageNumber = 1;
    this.getPdfsPaged();
  }
  getSubMenus() {
    // this.pdfService.getSupportSubMenuBySubMenuTypeValue('states').subscribe(data => {
    // //  this.supportSubMenus = data;
    //   this.supportSubMenusPdf = [{id: 0, checked: false, name: 'All', deletedDate: null, supportMenuId: null, priorityLevel: 0}, ...data];
    // });
  }
  getPdfFormsTypes() {
    this.pdfService.getPdfFormTypeList(this.selectedCompany1.parentGuid).subscribe(data => {
      this.pdfFormTypes = data;
      this.pdfFormTypesNoAll = data;
    }, error => { }, () => {
    });
  }
  getPdfBUttonLocations() {
    this.pdfPackageService.listPackageButtonLocations(this.selectedCompany1.parentGuid).subscribe(data => {
   //   this.packageButtonLocations = data;
    //  this.listServices.changePackageButtonLocations(data);
    }, error => {
    }, () => {});
  }
  getProducts() {
      this.pdfService.getPdfsPaged(this.shopParams).subscribe(data => {
        this.pdfListGridViewDto = data.data;
        this.shopParams.pageNumber = data.pageIndex;
        this.shopParams.pageSize = data.pageSize;
        this.totalCount = data.count;
      }, error => { console.log(error); }, () => {
     });
  }
  // tslint:disable-next-line:typedef
  getProductTypes() {
    this.pdfService.getPdfFormTypeList(this.selectedCompany1.parentGuid).subscribe(data => {
      // Add object to Array
      this.pdfFormTypesNoAll = data;
      this.pdfFormTypes = [{id: 0, value: 'All', deletedDate: null, parentGuid: this.selectedCompany1.parentGuid, pdfForms: [], pdfFormTypeCategory: null},  ...data];
    }, error => { console.log(error); }, () => {
    });
  }

  editPdf(e) {
 //   this.pdfService.getPdf(e.id).subscribe(data => { this.pdfListGridViewDtoIndividual = data; } );
    const config = {
      class: 'modal-lg',
      initialState: {
        pdfForm: e,
       // selectedType: this.selectedType,
        pdfFormTypes: this.pdfFormTypes,
      //  packageButtonLocations: this.packageButtonLocations
      }
    };
    // this.bsModalRef = this.modalService.show(EditpdfModalComponent, config);
    // this.bsModalRef.content.emittedValues.subscribe((values: PdfListGridViewDto) => {
    //   this.pdfService.editPdfEntity(values).subscribe(
    //     (data) => {
    //       this.toastR.success('FormUpdated');
    //     },
    //     (error) => {
    //       console.log(error);
    //     },
    //     () => {
    //       this.getPdfsPaged();
    //     }
    //   );
    // });
  }

  editPdfDescription(e) {
    this.pdfForm = e;
    const config = {
      class: 'modal-lg',
      initialState: {
        pdfForm: e,
       // selectedType: this.selectedType,
       pdfFormTypes: this.pdfFormTypes,
       parentGuid: this.selectedCompany1.parentGuid,
       pdfFormTypesCategorys:  this.pdfFormTypesCategorys,
       selectedType: this.selectedType,
      }
    };
    this.bsModalRef = this.modalService.show(PdfnewuploadnameModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values: NewNameModel) => {
      this.pdfService.renamePdf(this.shopParams, values.name, this.pdfForm.id, values.isUseUniqueFileName).subscribe(
        (data) => {
          this.toastR.success('FormUpdated');
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.getPdfsPaged();
        }
      );
    });
  }
  getProductCategories(): void {
  //   this.pdfService.getSubMenuById(1, 'states').subscribe(response => {
  //    // this.supportSubMenus = this.supportSubMenus;
  // //    this.supportSubMenus = [{id: 0, checked: false, name: 'All', deletedDate: null, supportMenuId: null, priorityLevel: 0}, ...response];
  //   //  this.supportSubMenus = response;
  //   }, error => { console.log(error); });
  }
  onPageChange(e: any): void {
    if (this.shopParams.pageNumber !== e) {
      this.shopParams.pageNumber = e;
      this.getProducts();
    }
  }
  getPdfsPaged() {
    this.pdfService.getPdfsPaged(this.shopParams).subscribe(data => {
      console.log(data);
      this.pdfListGridViewDto = data.data;
      this.shopParams.pageNumber = data.pageIndex;
      this.shopParams.pageSize = data.pageSize;
      this.totalCount = data.count;
    }, error => { console.log(error); }, () => {
    });
  }
  onSearch(): void {
    this.shopParams.search = this.searchTerm.nativeElement.value;
    this.shopParams.pageNumber = 1;
    this.getPdfsPaged();
  }
  onResetSearch(): void {
    this.searchTerm.nativeElement.value = '';
    this.shopParams = new ShopParams();
    this.getPdfsPaged();
  }
  onCategorySelected(categoryId: number): void {
    categoryId = Number(categoryId);
    if (categoryId === 0) {
      categoryId = null;
      this.shopParams.categoryId = 0;
    }
    else {
      this.shopParams.categoryId = categoryId;
    }
    this.shopParams.pageNumber = 1;
 //   this.getPdfsPaged();
//  this.pdfService.getPdfsPagedByMenuId(this.shopParams).subscribe(data => {
//   this.pdfListGridViewDto = data.data;
//   this.shopParams.pageNumber = data.pageIndex;
//   this.shopParams.pageSize = data.pageSize;
//   this.totalCount = data.count;
//   });
  }
  onTypeSelected(typeId: number): void {
    typeId = Number(typeId);
    if (typeId === 0) {
      typeId = 0;
      this.shopParams.typeId = 0;
    }
    else {
      this.shopParams.typeId = typeId;
    }
    this.shopParams.pageNumber = 1;
    this.getPdfsPaged();
  }
  onSortSelected(sort: string): void {
    this.shopParams.sort = sort;
    this.getPdfsPaged();
  }

  deleteProduct(e) {
    this.pdfService.deletePdf(this.shopParams, e.id).subscribe(data => {
      this.pdfListGridViewDto = data.data;
      this.shopParams.pageNumber = data.pageIndex;
      this.shopParams.pageSize = data.pageSize;
      this.totalCount = data.count;
    }, error => { console.log(error); }, () => {
    });
  }
  uploadCancelPdf() {
    this.isUpload = false;
  }
  uploadPdf() {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
       sale: this.pdfForm,
       parentGuid: this.selectedCompany1.parentGuid,
       pdfFormTypesCategorys:  this.pdfFormTypesCategorys,
       selectedType: this.selectedType,
       pdfFormTypes: this.pdfFormTypesNoAll,
      // packageButtonLocations: this.packageButtonLocations
      }
    };
    this.bsModalRef = this.modalService.show(PdfnewuploadnameModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe(values => {
      this.formNameToUpload = values.name;
      this.selectedTypeId = values.pdfFormTypeId;
      this.pdfFormTypes = this.pdfFormTypes.filter(data => data.id = values.pdfFormTypeId);
      this.pdfFormTypes = this.pdfFormTypes.filter(data => data.id = values.pdfFormTypeId);
      this.selectedType = this.pdfFormTypes[0];
      if (values.name) {
        if (values.name !== '' || values.name !== undefined) {
          if (!this.selectedType && !values.name || values.pdfFormTypeId === undefined || values.pdfFormTypeId === -1)  {
            alert('Select a Type');
            this.isUpload = false;
             } else {
               this.formNameToUpload = values.name;
              this.isUpload = true;
            }
        }
      }
    });
  }
  unloadPdloader(){ console.log('canceluploader'); this.isUpload = false; }
}

