<div class="customer-form-sec">
  <div class="container-fluid">
    <br>
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <div class="row">
                  <div  class="col-md-12  ">
                    <table  *appHasRole="['Admin']"  border="1" borderColor="#ccc">
                      <tr>
                        <th title="whatever">Id</th>
                        <th>Date</th>
                        
                        <th>Type</th>
                        <th>Created By</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Balance</th>
                        <th>Description</th>
                        <th>Outgoing</th>
                        <th>Incoming</th>
                      </tr>
                     
                      <tr title="Testing ?Tool Tip" (dblclick) = "editRepair(item)"  *ngFor = "let item of dollars; let i = index">
                        <td *ngIf="i != dollars.length - 1">  {{item?.id}}</td>
                        <td *ngIf="i != dollars.length - 1"> {{item?.dateOfTransaction | date: 'shortDate'}}</td>
                        <td *ngIf="i != dollars.length - 1"> {{ item.dollarTypes.value | HumanizePipes }}</td>
                        <td *ngIf="i != dollars.length - 1">{{ item?.createdBy?.person?.firstName }} {{item?.createdBy?.person?.lastName}}</td>
                        <td *ngIf="i != dollars.length - 1"> {{item?.credit | number: "1.2"}}</td>
                        <td *ngIf="i != dollars.length - 1"> {{item?.debit | number: "1.2"}}</td>
                        <td *ngIf="i != dollars.length - 1"> {{item?.balance | number: "1.2"}}</td>
                        <td *ngIf="i != dollars.length - 1"> {{item?.description}}</td>
                        <td *ngIf="i != dollars.length - 1"> {{item?.outGoing | number: "1.2"}}</td>
                        <td *ngIf="i != dollars.length - 1">{{item?.income | number: "1.2"}}</td>
                        <td></td>
                      </tr>

                      <tr style="color: rgb(9, 87, 2); font-size: larger;" title="Testing ?Tool Tip"   *ngFor = "let item of dollars; let i = index; last as isLast">
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"> </td>
                        <td *ngIf="isLast && i !==0"> </td>
                        <td *ngIf="isLast && i !==0"> {{item?.credit | number: "1.2"}}</td>
                        <td *ngIf="isLast && i !==0"> {{item?.debit | number: "1.2"}}</td>
                        <td *ngIf="isLast && i !==0"> {{item?.balance | number: "1.2"}}</td>
                        <td *ngIf="isLast && i !==0"> </td>
                        <td *ngIf="isLast && i !==0"> {{item?.outGoing | number: "1.2"}}</td>
                        <td *ngIf="isLast && i !==0">{{item?.income | number: "1.2"}}</td>
                        <td *ngIf="isLast && i !==0"></td>
                      </tr>
                    </table>
                  </div><!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div><!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-2">
        <div class="inner-sidebar">
          <ul>
            <li>
              <button (click) = "addRepair()" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png">
                <span>Add New</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
