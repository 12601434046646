import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DealInfoGuard } from '../_guards/newsale-dealinfo.guard';
import { AmoLoanMethodResolver } from '../_resolvers/amo-loantypes.resolver';
import { AmoInstallmentsResolver } from '../_resolvers/amoinstallments.resolver';
import { LabelDealInfoResolver } from '../_resolvers/label-dealinfo.resolver';
import { LabelVehicleDetailsResolver } from '../_resolvers/label-vehicledetails.resolver';
import { LienHoldersInsuranceCompanyResolver } from '../_resolvers/lienholders.resolver';
import { PaymentTypesResolver } from '../_resolvers/payment-types.resolver';
import { SaleActiveTypeResolver } from '../_resolvers/sale-activetype.resolver';
import { SaleGetSaleResolver } from '../_resolvers/sale-getsale.resolver';
import { SaleGetSaleMiscChargeResolver } from '../_resolvers/sale-miscdollars.resolver';
import { SaleStatusResolver } from '../_resolvers/sale-status.resolver';
import { SaleTaxDefaultsResolver } from '../_resolvers/sale-taxdefaults.resolver';
import { SalesResolver } from '../_resolvers/sales-grid.resolver';
import { SaleDashboardComponent } from './sale-dashboard/sale-dashboard.component';
import { SaleDeskComponent } from './Sale-Desk/Sale-Desk.component';
import { AdvancedComponent } from './sale-grid/advanced/advanced.component';
import { SaleGridComponent } from './sale-grid/sale-grid.component';
import { SalesJournalComponent } from './sales-journal/sales-journal.component';


const routes: Routes = [

    {path: 'saledashboard/:parentGuid', component: SaleGridComponent, resolve: {
        chartInvoiceStatuses: SaleStatusResolver,
        chartInvoiceSaleTypes: SaleActiveTypeResolver,
        sales: SalesResolver

    }},
    {path: 'salegrid/:parentGuid', component: SaleGridComponent, resolve: {
        chartInvoiceStatuses: SaleStatusResolver,
        chartInvoiceSaleTypes: SaleActiveTypeResolver,
        sales: SalesResolver

    }},
    {path: 'salesjournal/:parentGuid', component: SalesJournalComponent, resolve: {
        chartInvoiceStatuses: SaleStatusResolver,
        chartInvoiceSaleTypes: SaleActiveTypeResolver,
        sales: SalesResolver

    }},
    {path: 'advancetax/:parentGuid', component: AdvancedComponent, resolve: {
        chartInvoiceStatuses: SaleStatusResolver,
        chartInvoiceSaleTypes: SaleActiveTypeResolver,
        sales: SalesResolver

    }},
    //  saleMischarges: SaleGetSaleMiscChargeResolver,
    {path: 'editsale/:parentGuid/:saleId', component: SaleDeskComponent, resolve: {
        sale: SaleGetSaleResolver,
        dropdowns: LienHoldersInsuranceCompanyResolver
        }},
    {path: 'addsale/:parentGuid/:saleId', component: SaleDeskComponent, resolve: {
        dropdowns: LienHoldersInsuranceCompanyResolver,
    }, canDeactivate: [DealInfoGuard]},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SaleRoutingModule {}

