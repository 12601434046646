<div class="customer-form-sec">
  <div class="container-fluid">
<div class="row">
<div class="form-sec tab-content col-md-12">
  <div id="vehicle" class="tab-pane fade in active show">
<div style="width: 550px" class="modal1" id="myModal">
  <div class="modal-dialog1 modal1" style="width:550px;">
     <div class="modal1-content modal1" style="width:450px;" >

<div  class="modal-header modal1">
  <h4 class="modal-title pull-left">Update Or Add</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div  class="modal-body modal1">


<div class="form-sec tab-content col-md-12" style="width:550px !important;">
<form id="editForm" #editForm="ngForm" (ngSubmit)="updateStatus()">
    <div class="row vehicle-form">
        <div class="col-md-12 cstm-col">
            <div *ngIf="editForm.dirty" class="alert alert-info">
                <strong>Information:</strong> You have made changes. Any unsaved changes
                will be lost!
              </div>
        </div>

       <div class="col-md-12 cstm-col">
        <div class="inline-blk"
        [ngClass]="{
          'has-success': value?.valid && (value?.touched || value?.dirty),
          'has-danger': value?.invalid && (value?.touched || value?.dirty)
        }">
          <label>Name</label>
          <input class="cstm-input" value=""
          type="text"
          autocomplete="off"
          [ngModel]="vehicleMileageCodes?.value"
          (ngModel)="vehicleMileageCodes.value = $event"
          name="value"
          #value="ngModel"
          required>
        </div>

        <div
        *ngIf="value.errors && (value?.touched || value?.dirty)">
        <p *ngIf="value?.errors?.required">Status Value Is Required!</p>
      </div>
      </div>
        <!--Payment Type #-->

    
        <!--button save-->
      <div class="col-md-12 cstm-col ">
        <button [disabled]="!editForm.valid" type="submit" class="btn btn-primary ">
          SAVE
        </button>
        <p *ngIf="!editForm.valid" style="color: red">
          Save Button Will be enabled when all fields entered!
        </p>
      </div>
  
    </div>
</form>
</div>

</div>
</div>
  </div>
</div>
  </div>
</div>
</div>
</div>


