import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SaleService } from '../_services/saleservices/sale.service';
import { Sale } from '../_models/sale';
@Injectable({
    providedIn: 'root'  // **** ADD THIS LINE ****
  })
export class SaleGetSaleResolver implements Resolve<Sale> {
    constructor(private repo: SaleService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Sale> {
      //  console.log(route.params['parentGuid']);
        // tslint:disable-next-line:no-string-literal
        return this.repo.getSaleById(route.params['parentGuid'], route.params['saleId']).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving sale ative types.');
               // this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
