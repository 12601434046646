import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import moment from 'moment';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
import { evaluate } from 'mathjs/lib/browser/math.js';
import { Person } from 'src/app/_models/person';
import { concat, string } from 'mathjs';
@Injectable({ providedIn: 'root' })
export class CustomData {
  moment = require('moment');
  convertDate(date) {
    const momentDate = moment().format('L').toString();
    return momentDate;
}
  fillPdfFieldsWithDealDownPayments(
    sale: Sale,
    prefix: string,
    groupByField: string,
    pdfFields: PdfSaleFields[]
  ): Observable<PdfSaleFields[]> {
    if (pdfFields.length > 0) {
    // BUYER
      const bFirstName = sale?.buyer?.firstName;
      const bPhone = sale?.buyer?.phone;
      const bLastName = sale?.buyer?.lastName;
      const bMiddleName = sale?.buyer?.middleName;
      const bFullName = sale?.buyer?.firstName + ' ' + sale?.buyer?.lastName + ', ' + sale?.buyer?.middleName;
      const bBirthday = sale?.buyer?.dateOfBirth;
      const bDlExpires = sale?.buyer?.driverLicenseExpires;
      const bDlNumber = sale?.buyer?.driversLicense;
      const bDlState = sale?.buyer?.driversLicenseState;
      const bEmail = sale?.buyer?.email;
      const bEmployerName = sale?.buyer?.employer?.name;
      const bEmployerPhone = sale?.buyer?.employer?.phone;
      const bExtra = sale?.buyer?.extraNumber;
      const bCityStateZip = sale?.buyer?.addressDetail?.city + ' ' + sale?.buyer?.addressDetail?.state + ' ' + sale?.buyer?.addressDetail?.zip;
      const bAddress = sale?.buyer?.addressDetail?.street;
      const bStreet = sale?.buyer?.addressDetail?.street;
      const bZip = sale?.buyer?.addressDetail?.zip;
      const bCounty = sale?.buyer?.addressDetail?.county;
      const bInsExpires = sale?.buyer?.insuranceExpires;
      const bInsCompany = sale?.buyer?.insuranceCompany;
      const bInsPolicy = sale?.buyer?.insurancePolicy;
      const bDeductible = sale?.buyer?.insuranceDeductible;
      // coBuyer
      const bbPhone = sale?.coBuyer?.phone;
      const bbFirstName = sale?.coBuyer?.firstName;
      const bbLastName = sale?.coBuyer?.lastName;
      const bbMiddleName = sale?.coBuyer?.middleName;
      const bbFullName = sale?.coBuyer?.firstName + ' ' + sale?.coBuyer?.lastName + ', ' + sale?.coBuyer?.middleName;
      const bbBirthday = this.convertDate(sale?.coBuyer?.dateOfBirth) ;
      const bbDlExpires = this.convertDate(sale?.coBuyer?.driverLicenseExpires);
      const bbDlNumber = sale?.coBuyer?.driversLicense;
      const bbDlState = sale?.coBuyer?.driversLicenseState;
      const bbEmail = sale?.coBuyer?.email;
      const bbEmployerName = sale?.coBuyer?.employer?.name;
      const bbEmployerPhone = sale?.coBuyer?.employer?.phone;
      const bbExtra = sale?.coBuyer?.extraNumber;
      const bbCityStateZip = sale?.coBuyer?.addressDetail?.city + ' ' + sale?.coBuyer?.addressDetail?.state + ' ' + sale?.coBuyer?.addressDetail?.zip;
      const bbAddress = sale?.coBuyer?.addressDetail?.street;
      const bbStreet = sale?.coBuyer?.addressDetail?.street;
      const bbZip = sale?.coBuyer?.addressDetail?.zip;
      const bbCounty = sale?.coBuyer?.addressDetail?.county;
      const bbInsExpires = this.convertDate(sale?.coBuyer?.insuranceExpires);
      const bbInsCompany = sale?.coBuyer?.insuranceCompany;
      const bbInsPolicy = sale?.coBuyer?.insurancePolicy;
      const bbDeductible = sale?.coBuyer?.insuranceDeductible;
    // tennant company
      const coAddress = sale?.companyProfile?.street;
      const coCity = sale?.companyProfile?.city;
      const coState = sale?.companyProfile?.state;
      const coZip = sale?.companyProfile?.zip;
      const coCounty = sale?.companyProfile?.county;
      const coFax = sale?.companyProfile?.fax;
      const coPhone = sale?.companyProfile?.phone;
      const coEMail = sale?.companyProfile?.email;
      const coSellerFinanceTexas = 'Seller Finance :' + sale?.companyProfile?.taxNumber;
      const coName = sale?.companyProfile?.companyName;
      const coEIN = sale?.companyProfile?.ein;
      const coEFN = sale?.companyProfile?.efn;
      const coTaxNumber = sale?.companyProfile?.taxNumber;
      const coLicNumber = sale?.companyProfile?.licenseNumber;
      const coCityStateZip = sale?.companyProfile?.city + ' ' + sale?.companyProfile?.state + ' ' + sale?.companyProfile?.zip;
      const coIsTaxedDeferred = sale?.companyProfile?.isTexasDeferred;
      const coDeletedDate = sale?.companyProfile?.deletedDate;


      // SOLD VEHICLE
      const svStock = sale?.vehiclePurchased?.itemVehicleTransientDetails?.stockNumber;
      const svVin = sale?.vehiclePurchased?.vehicle?.vin;
      const svMake = sale?.vehiclePurchased?.vehicle?.make;
      const svYear = sale?.vehiclePurchased?.vehicle?.year;
      const svModel = sale?.vehiclePurchased?.vehicle?.model;
      const svTrim = sale?.vehiclePurchased?.vehicle?.trim;
      const svSeries = sale?.vehiclePurchased?.vehicle?.series;
      const svBody = sale?.vehiclePurchased?.vehicle?.vehicleBodyClassTypes?.nameFriendly;
      const svColor = sale?.vehiclePurchased?.vehicle?.exteriorColor1;
      const svColorr = sale?.vehiclePurchased?.vehicle?.exteriorColor2;
      const svCylinders = sale?.vehiclePurchased?.vehicle?.vehicleEngines?.engineCylinders;
      const svMileageCode = sale?.vehiclePurchased?.itemVehicleTransientDetails?.mileageCode;
      const svMileage = sale?.vehiclePurchased?.itemVehicleTransientDetails?.mileage;
      const svWeight = sale?.vehiclePurchased?.vehicle?.weight;
      const svSoldDate = this.convertDate(sale?.vehiclePurchased?.soldDate);
      const svDatePurchased = this.convertDate(sale?.vehiclePurchased?.purchasedDate);
      const svDecalNumber = sale?.vehiclePurchased?.itemVehicleTransientDetails?.decalNumber;
      const svTag = sale?.vehiclePurchased?.itemVehicleTransientDetails?.tag;
      const svTagExpires = this.convertDate(sale?.vehiclePurchased?.itemVehicleTransientDetails?.tagExpires);
      const svCountyCode = sale?.vehiclePurchased?.itemVehicleTransientDetails?.countyCode;
      const svTitleNumber = sale?.vehiclePurchased?.itemVehicleTransientDetails?.titleNumber;
      const svPurchasedAs = sale?.vehiclePurchased?.itemVehicleTransientDetails?.purchasedAs;
      const svPreviousTitleNumber = sale?.vehiclePurchased?.itemVehicleTransientDetails?.previousTitleNumber;
      const svPreviousState = sale?.vehiclePurchased?.itemVehicleTransientDetails?.previousState;
      const svPurchasedFromPersonName = sale?.vehiclePurchased?.purchaseFrom?.firstName;
      const svPurchasedFromPersonPhone = sale?.vehiclePurchased?.purchaseFrom?.phone;
      const svPurchasedFromCompanyName = sale?.vehiclePurchased?.purchaseFrom?.firstName;
      const svPurchasedFromCompanyPhone = sale?.vehiclePurchased?.purchaseFrom?.firstName;

      // tRADE1  VEHICLE
      const tStock = sale?.tradeIn?.itemVehicleTransientDetails?.stockNumber;
      const tVin = sale?.tradeIn?.vehicle?.vin;
      const tMake = sale?.tradeIn?.vehicle?.make;
      const tYear = sale?.tradeIn?.vehicle?.year;
      const tModel = sale?.tradeIn?.vehicle?.model;
      const tTrim = sale?.tradeIn?.vehicle?.trim;
      const tSeries = sale?.tradeIn?.vehicle?.series;
      const tBody = sale?.tradeIn?.vehicle?.vehicleBodyClassTypes?.nameFriendly;
      const tColor = sale?.tradeIn?.vehicle?.exteriorColor1;
      const tColorr = sale?.tradeIn?.vehicle?.exteriorColor2;
      const tCylinders = sale?.tradeIn?.vehicle?.vehicleEngines?.engineCylinders;
      const tMileageCode = sale?.tradeIn?.itemVehicleTransientDetails?.mileageCode;
      const tMileage = sale?.tradeIn?.itemVehicleTransientDetails?.mileage;
      const tWeight = sale?.tradeIn?.vehicle?.weight;
      const tSoldDate = this.convertDate(sale?.tradeIn?.soldDate);
      const tDatePurchased = this.convertDate(sale?.tradeIn?.purchasedDate);
      const tDecalNumber = sale?.tradeIn?.itemVehicleTransientDetails?.decalNumber;
      const tTag = sale?.tradeIn?.itemVehicleTransientDetails?.tag;
      const tTagExpires = sale?.tradeIn?.itemVehicleTransientDetails?.tagExpires;
      const tCountyCode = sale?.tradeIn?.itemVehicleTransientDetails?.countyCode;
      const tTitleNumber = sale?.tradeIn?.itemVehicleTransientDetails?.titleNumber;
      const tPurchasedAs = sale?.tradeIn?.itemVehicleTransientDetails?.purchasedAs;
      const tPreviousTitleNumber = sale?.tradeIn?.itemVehicleTransientDetails?.previousTitleNumber;
      const tPreviousState = sale?.tradeIn?.itemVehicleTransientDetails?.previousState;
      const tPurchasedFromPersonName = sale?.tradeIn?.purchaseFrom?.firstName;
      const tPurchasedFromPersonPhone = sale?.tradeIn?.purchaseFrom?.phone;
      const tPurchasedFromCompanyName = sale?.tradeIn?.purchaseFrom?.firstName;
      const tPurchasedFromCompanyPhone = sale?.tradeIn?.purchaseFrom?.firstName;
      // trade in 2
      const ttStock = sale?.tradeIn2?.itemVehicleTransientDetails?.stockNumber;
      const ttVin = sale?.tradeIn2?.vehicle?.vin;
      const ttMake = sale?.tradeIn2?.vehicle?.make;
      const ttYear = sale?.tradeIn2?.vehicle?.year;
      const ttModel = sale?.tradeIn2?.vehicle?.model;
      const ttTrim = sale?.tradeIn2?.vehicle?.trim;
      const ttSeries = sale?.tradeIn2?.vehicle?.series;
      const ttBody = sale?.tradeIn2?.vehicle?.vehicleBodyClassTypes?.nameFriendly;
      const ttColor = sale?.tradeIn2?.vehicle?.exteriorColor1;
      const ttColorr = sale?.tradeIn2?.vehicle?.exteriorColor2;
      const ttCylinders = sale?.tradeIn2?.vehicle?.vehicleEngines?.engineCylinders;
      const ttMileageCode = sale?.tradeIn2?.itemVehicleTransientDetails?.mileageCode;
      const ttMileage = sale?.tradeIn2?.itemVehicleTransientDetails?.mileage;
      const ttWeight = sale?.tradeIn2?.vehicle?.weight;
      const ttSoldDate = this.convertDate(sale?.tradeIn2?.soldDate);
      const ttDatePurchased = this.convertDate(sale?.tradeIn2?.purchasedDate);
      const ttDecalNumber = sale?.tradeIn2?.itemVehicleTransientDetails?.decalNumber;
      const ttTag = sale?.tradeIn2?.itemVehicleTransientDetails?.tag;
      const ttTagExpires = this.convertDate(sale?.tradeIn2?.itemVehicleTransientDetails?.tagExpires);
      const ttCountyCode = sale?.tradeIn2?.itemVehicleTransientDetails?.countyCode;
      const ttTitleNumber = sale?.tradeIn2?.itemVehicleTransientDetails?.titleNumber;
      const ttPurchasedAs = sale?.tradeIn2?.itemVehicleTransientDetails?.purchasedAs;
      const ttPreviousTitleNumber = sale?.tradeIn2?.itemVehicleTransientDetails?.previousTitleNumber;
      const ttPreviousState = sale?.tradeIn2?.itemVehicleTransientDetails?.previousState;
      const ttPurchasedFromPersonName = sale?.tradeIn2?.purchaseFrom?.firstName;
      const ttPurchasedFromPersonPhone = sale?.tradeIn2?.purchaseFrom?.phone;
      const ttPurchasedFromCompanyName = sale?.tradeIn2?.purchaseFrom?.firstName;
      const ttPurchasedFromCompanyPhone = sale?.tradeIn2?.purchaseFrom?.firstName;
       // DEAL LIENHOLDER
       const vslName = sale?.lienHolder?.name;
       const vslAddress = sale?.lienHolder?.addressDetail?.street;
       const vslCity = sale?.lienHolder?.addressDetail?.city;
       const vslState = sale?.lienHolder?.addressDetail?.state;
       const vslZip = sale?.lienHolder?.addressDetail?.zip;
       const vslCounty = sale?.lienHolder?.addressDetail?.county;
       const vslPhone = sale?.lienHolder?.phone;
       const vslFax = sale?.lienHolder?.fax;
       const vslEmail = sale?.lienHolder?.email;
       const vslLicNumber = sale?.lienHolder?.licenseNumber;
       const vslTaxNumber = sale?.lienHolder?.taxNumber;
       const vslEIN = sale?.lienHolder?.ein;
       const vslEFN = sale?.lienHolder?.efn;
     // DEAL DOLLARS$
     const didSellingPrice = sale?.sellingPrice;
     const didPredelivery = sale?.predelivery;
     const didTitle = sale?.title;
      const didTag = sale?.tag;
      const didRegistration = sale?.registration;
      const didMiscTaxable = sale?.miscTaxable;
      const didMiscNonTax = sale?.miscNonTax;
      const didSmogC = sale?.smogC1;
      const didTradeAllow = sale?.tradeAllow;
      const didTradeLien = sale?.tradeLien;
      const didWarranty = sale?.warranty;
      const didCreditLife = sale?.creditLife;
      const didDisability = sale?.disability;
      const didCashDown = sale?.cashDown;
      const didDocFees = sale?.docFees;
      const didAmountFinanced = sale?.amountFinanced;

      // TAX ITEMS
      const dtTax = sale?.cashDown;
      const dtTaxRateA = sale?.taxRate1;
      const dtTaxRateB = sale?.taxRate2;
      const dtTaxRateC = sale?.taxRate3;
      const dtTaxRateD = sale?.taxRate4;
      const dtTotalTaxA = sale?.totalTax1;
      const dtTotalTaxB = sale?.totalTax2;
      const dtTotalTaxC = sale?.totalTax3;
      const dtTotalTaxD = sale?.totalTax4;
      const dtAmtTaxedA = sale?.amtTaxed1;
      const dtAmtTaxedB = sale?.amtTaxed2;
      const dtAmtTaxedC = sale?.amtTaxed3;
      const dtAmtTaxedD = sale?.amtTaxed4;

      // Down Payment Items
      const ddPickupsTotal = sale?.pickupsTotal;
      const ddCashDown = sale?.cashDown;
      const ddTradeAllow = sale?.pickupsTotal;
      const ddTradeLien = sale?.cashDown;
      const ddTradeDifferencePositive = sale?.pickupsTotal;
      const ddTradeDifferenceNegative = sale?.cashDown;
      const ddPickAmountA = sale?.pickAmount1;
      const ddPickAmountB = sale?.pickAmount2;
      const ddPickAmountC = sale?.pickAmount3;
      const ddPickAmountD = sale?.pickAmount4;
      const ddPickAmountE = sale?.pickAmount5;
      const ddPickDateA = this.convertDate(sale?.pickDate1);
      const ddPickDateB = this.convertDate(sale?.pickDate2);
      const ddPickDateC = this.convertDate(sale?.pickDate3);
      const ddPickDateD = this.convertDate(sale?.pickDate4);
      const ddPickDateE = this.convertDate(sale?.pickDate5);

      // LOAN INFO
      const alBalance = sale?.amoLoans?.decliningBalance;
      const alFinalDueDate = this.convertDate(sale?.amoLoans?.finalDueDate);
      const alNextDueDate = this.convertDate(sale?.amoLoans?.nextDueDate);
      const alCurrentDue = sale?.amoLoans?.currentDue;
      const alPayOff = sale?.amoLoans?.payoffToday;
      const alLastPaidDate = this.convertDate(sale?.amoLoans?.lastPaidDate);
      const alInterestDays = sale?.interestDays;
      const alPrepaidFee = sale?.prepaidFee;
      const alReserve = sale?.reserve;
      const alAmountFinanced = sale?.amountFinanced;
      const alSoldDate = sale?.soldDate;
      const alNonAmortized = sale?.nonAmortized;
      const alAmortized = sale?.amortized;
      const alInstallment = sale?.amoInstallments?.value;
      const alApr = sale?.apr;
      const alTerm = sale?.term;
      const alPayment = sale?.payment;
      const alFinalPayment = sale?.finalPayment;
      const alFirstDueDate = this.convertDate(sale?.firstDueDate);
      const alDAPR = sale?.dapr;
      const alAddOn = sale?.addOn;
      const alNetReservePrc = sale?.netReservePrc;

      let expr;
      let field = '';
      let newpdfField = {} as PdfSaleFields;
      const newPdfFields = [] as PdfSaleFields[];
      pdfFields.forEach((element) => {
        if (element.isCalculated) {
          field = element.field;
         // console.log( element.calculation);
          const str = field;
          const matches = str.match(/\d+/g);
          let i;
          if (matches != null) {
            i = matches[0];
          } else {
            return of(pdfFields);
          }
          const e3 =  `${didSellingPrice} + ${didPredelivery} + ${didTitle} + ${didTag} + ${didRegistration} + ${didMiscTaxable} + ${didMiscNonTax} + ${didSmogC} + ${didTradeAllow} + ${didTradeLien} + ${didWarranty} + ${didCreditLife} + ${didDisability} + ${didCashDown} + ${didDocFees} + ${didAmountFinanced}`;
          field = field.replace(i.toString(), '');
          newpdfField.groupBy = 'calculated';
          let exp =  element.calculation; // element.calculation.replace(/"/g, '&quot;');
          // tslint:disable-next-line:no-eval
          exp = eval(exp);
          try {
            expr = evaluate(exp);
          } catch (error) {
            expr = exp.replace('+ " " +', '');
          }
          if (!isNaN(expr)) {
            expr = expr.toFixed(2);
          }
          const result = expr.toString();
          console.log(expr);
          newpdfField = {
            field: element.field,
            value: result,
            calculate: 'true',
            groupBy: groupByField,
          };
          newPdfFields.push(newpdfField);
        }
      });
      return of(newPdfFields);
    }
  }

}
