import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qb-chartofaccounts',
  templateUrl: './qb-chartofaccounts.component.html',
  styleUrls: ['./qb-chartofaccounts.component.scss']
})
export class QbChartofaccountsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
