import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CalculateFederalAprDto } from 'src/app/_models/DTOS/CalculateFederalAprDto';
import { CalculateTermDto } from 'src/app/_models/DTOS/CalculateTermDto';
import { Sale } from 'src/app/_models/sale';
import { PaymentType } from 'src/app/_models/PaymentType';


@Injectable({
  providedIn: 'root'
})
export class LoanService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;

  getSaleByLoanId(saleId: number): Observable<Sale>{
    return this.http.get<Sale>(this.baseUrl + 'amoloan/getsalebyloanid/' + saleId);
  }
  getFederalApr(loanDto): Observable<CalculateFederalAprDto> {
    return this.http.put<CalculateFederalAprDto>(this.baseUrl + 'amoloan/calculatefederalapr/', loanDto);
  }
  getCashPaymentType(parentGuid: string): Observable<PaymentType> {
    return this.http.get<PaymentType>(this.baseUrl + 'paymenttype/getcashpaymenttype/' + parentGuid);
  }
  getPaymentTYpes(parentGuid: string): Observable<PaymentType[]> {
    return this.http.get<PaymentType[]>(this.baseUrl + 'paymenttype/getpaymenttypes/' + parentGuid);
  }
  getPaymentByTerm(calculateTermDto): Observable<CalculateTermDto> {
    return this.http.put<CalculateTermDto>(this.baseUrl + 'amoloan/calculatebyterm/', calculateTermDto);
  }

  getTermByPaymentAndApr(calculateTermDto): Observable<CalculateTermDto> {
    return this.http.put<CalculateTermDto>(this.baseUrl + 'amoloan/calculategetterm/', calculateTermDto);
  }

  takePayment(calculateTermDto): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'amoloan/takepayment/', calculateTermDto);
  }
  editPayment(calculateTermDto, pmtId): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'amoloan/editpayment/' + pmtId, calculateTermDto);
  }
  printReceipt(invoiceid, pmtId): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'amoloan/printreciept/' + invoiceid + '/' + pmtId);
  }
  getPaymentDueToday(calculateTermDto): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'amoloan/preparepayment/', calculateTermDto);
  }

  calculateCurrentDues(calculateCurrentDueDto): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'amoloan/findcurrentdues/', calculateCurrentDueDto);
  }

}
