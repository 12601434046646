

<div class="customer-form-sec">
  <div class="container-fluid">
    <br>
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->
                <div class="row">
                  <div class="col-md-10 cal-grid">
                    <ul class="calander-grid">
                      <li><a href="javascript:void(0)">Jan</a></li>
                      <li><a href="javascript:void(0)">Feb</a></li>
                      <li><a href="javascript:void(0)">Mar</a></li>
                      <li><a href="javascript:void(0)" class="active">Apr</a></li>
                      <li><a href="javascript:void(0)">May</a></li>
                      <li><a href="javascript:void(0)">Jun</a></li>
                      <li><a href="javascript:void(0)">Jul</a></li>
                      <li><a href="javascript:void(0)">Aug</a></li>
                      <li><a href="javascript:void(0)">Sep</a></li>
                      <li><a href="javascript:void(0)">Oct</a></li>
                      <li><a href="javascript:void(0)">Nov</a></li>
                      <li><a href="javascript:void(0)">Dec</a></li>
                    </ul>
                    <div class="cal-btn-sec">
                      <button class="cal-btn">2018</button>
                      <div class="arrows">
                        <span class="arrow-up"><i class="fa fa-caret-up"></i></span>
                        <span class="arrow-down"><i class="fa fa-caret-down"></i></span>
                      </div>
                    </div>
                    <div class="filter-btn">
                      <button class="filter">
                        <img src="assets/images/custom-filter.png">
                        <span>Custom Filter</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <table border="1" borderColor="#ccc">
                      <tr>
                        <th>Date Due</th>
                        <th>Track</th>
                        <th>Acct</th>
                        <th>Acct Type</th>
                        <th>Payable To</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Balance</th>
                        <th>Running</th>
                        <th>Batch</th>
                      </tr>
                      <tr class="td-row">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </table>
                  </div><!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div><!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-2">
        <div class="inner-sidebar">
          <ul>
            <li>
              <button (click) ="openNewInvoice()"
               class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png">
                <span>Add New</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-3.png">
                <span>Mark Item Paid</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-4.png">
                <span>Print Invoice</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-4.png">
                <span>Print Work Order</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-6.png">
                <span>Print Report</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-6.png">
                <span>Export Excel</span>
              </button>
            </li>
            <li>
              <button class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-6.png">
                <span>Export Pdf</span>
              </button>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
