<br /><br />
<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-11">
        <form novalidate #editForm="ngForm" (ngSubmit)="onSubmit(editForm)">
          <div class="container">
            <!--  next field-->
            <div class="row">
              <div class="col-md-6">
                <div class="form-group form-inline">
                  <label>Monday</label>
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="storeHours?.monday"
                    (ngModelChange)="storeHours.monday = $event"
                    name="monday"
                    #monday="ngModel"
                    required
                  />
                </div>
              </div>
              <!--  next field-->
              <div class="col-md-6">
                <div class="form-group form-inline">
                  <label>Tuesday</label>
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="storeHours?.tuesday"
                    (ngModelChange)="storeHours.tuesday = $event"
                    name="tuesday"
                    #tuesday="ngModel"
                    required
                  />
                </div>
              </div>
            </div>
            <!--end row-->
            <div class="row">
              <!--  next field-->
              <div class="col-md-6">
                <div class="form-group form-inline">
                  <label>Wednesday</label>
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="storeHours?.wednesday"
                    (ngModelChange)="storeHours.wednesday = $event"
                    name="wednesday"
                    #wednesday="ngModel"
                    required
                  />
                </div>
              </div>
              <!--  next field-->
              <div class="col-md-6">
                <div class="form-group form-inline">
                  <label>Thursday</label>
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="storeHours?.thursday"
                    (ngModelChange)="storeHours.thursday = $event"
                    name="thursday"
                    #thursday="ngModel"
                    required
                  />
                </div>
              </div>
            </div>
            <!--end row-->
            <div class="row">
              <!--  next field-->
              <div class="col-md-6">
                <div class="form-group form-inline">
                  <label>Friday</label>
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="storeHours?.friday"
                    (ngModelChange)="storeHours.friday = $event"
                    name="friday"
                    #friday="ngModel"
                    required
                  />
                </div>
              </div>

              <!--  next field-->
              <div class="col-md-6">
                <div class="form-group form-inline">
                  <label>Saturday</label>
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="storeHours?.saturday"
                    (ngModelChange)="storeHours.saturday = $event"
                    name="saturday"
                    #saturday="ngModel"
                    required
                  />
                </div>
              </div>
            </div>
            <!--end row-->
            <!--  next field-->
            <div class="col-md-6">
              <div class="form-group form-inline">
                <label>Sunday</label>
                <input
                  type="text"
                  class="form-control"
                  [ngModel]="storeHours?.sunday"
                  (ngModelChange)="storeHours.sunday = $event"
                  name="sunday"
                  #sunday="ngModel"
                  required
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group form-inline">
                <label>Holidays</label>
                <input
                  type="text"
                  style="width: 60%"
                  class="form-control"
                  [ngModel]="storeHours?.holidays"
                  (ngModelChange)="storeHours.holidays = $event"
                  name="holidays"
                  #holidays="ngModel"
                  
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-1">
        <div class="inner-sidebar">
          <ul>
            <li *ngIf="editForm.dirty === true && editForm.valid">
              <button (click)="onSubmit(editForm)" class="cstm-btn img-blk-btn">
                <img src="assets/images/Save.jpg" />
                <span>SAVE</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
