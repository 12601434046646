
<div class="customer-tabs-sec" style="padding-bottom: 10px; width: 60% !important;">
  <ul class="nav nav-tabs">
    <li><a [ngClass] = "isAdd ? 'active show' : ''" (click) ="tabClick('isAdd')"  href="javascript:void(0)">Add</a></li>
    <li><a [ngClass] = "isDeleted ? 'active show' : ''" (click) ="tabClick('isDeleted')"  href="javascript:void(0)">Deleted</a></li>
    <li  ><a [ngClass] = "isActive ? 'active show' : ''" (click) ="tabClick('isActive')"  href="javascript:void(0)">Active</a></li>
    <li  ><a [ngClass] = "isAll ? 'active show' : ''" (click) ="tabClick('isAll')"  href="javascript:void(0)">All</a></li>
   
    <!-- <li><a (click) ="tabClick(isWashout)" href="javascript:void(0)">Payables</a></li>
    <li><a (click) ="tabClick(isWashout)" href="javascript:void(0)">Recievable</a></li> -->
    <!-- <li><a  [ngClass] = "isAmortization ? 'active show' : ''" (click) ="tabClick('isAmortization')" href="javascript:void(0)">Amortization</a></li> -->
  </ul>
  </div>
  <br>
<table border="1" borderColor="#ccc">
  <tr>
    <th class="date-th">Date</th>
    <th class="des-th">Note</th>
    <th class="charges-th">Remind me?</th>
    <th class="charges-th">Reminder Date?</th>
    <th class="charges-th">Deleted Date?</th>
  </tr>

  <tr (dblclick) = "editNote(item)" class="td-rw" *ngFor = "let item of saleNotes">
    <td class="date-td">{{item.createdDate | date: 'yyyy-MM-dd'}}</td>
    <td>{{item.note}}</td>
    <td ><i *ngIf="item.remind" class="fa fa-bell btn-success" aria-hidden="true"></i></td>
    <td   class="date-td">{{item.remindDate | date: 'yyyy-MM-dd'}}</td>
    <td   class="date-td">{{item.deletedDate | date: 'yyyy-MM-dd'}}</td>
  </tr>
 
</table>
<!-- <div class="customer-form-sec deal-info-form-sec vehicle-2-sec notes-history-sec">
  <div class="container-fluid">
    <br><br>
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="notes" class="tab-pane fade in active show">
          <div class="row">
            



            <div id="misc-info" class="tab-pane fade in active show">
              <div class="row">
                <div class="col-md-8">
                  <div class="inner-misc">
                    <div class="misc-grid">
            <div class="col-md-8 right-side-deal-info">
              <div class="misc-grid">
                <div class="table-sec">
                  <table >
                    <tr>
                      <th class="date">Date</th>
                      <th class="notes">Notes</th>
                      <th class="date">Reminder Date</th>
                      <th class="notes">Remind Me?</th>
                    </tr>
                    <tr class="tr-data" *ngFor = "let item of sale?.saleNotes">
                      <td>{{item.createdDate}}</td>
                      <td>{{item.note}}</td>
                      <td>{{item.remindMeDate  | date: 'yyyy-MM-dd'}}</td>
                      <td>{{item.remindMe}}</td>
                    </tr>
                  </table>
                </div>
                <div class="misc-btn-sec">
                  <button class="cstm-btn">Add</button>
                  <button class="cstm-btn">Edit</button>
                  <button class="cstm-btn">Delete</button>
                </div>
              </div>
            </div>
          </div>
             


</div>
</div>
              </div>


              <div class="comment-sec">
                <label>Comments:</label>
                <textarea rows="9"  name="pickDate4" style="width: 570px;"  [ngModel]="sale?.comments"
                (ngModel)="sale.comments = $event"
                (ngModelChange) = "sale.comments = $event; " type="date"  class="cstm-input">?</textarea>
              </div>
            </div>
            <div class="col-md-4 notes-button-sec">
              <div class="notes-btn-sec">
                <button class="cstm-btn" data-toggle="modal" data-target="#notesModal">Add</button>
                <button class="cstm-btn" disabled="">Edit</button>
                <button class="cstm-btn" disabled="">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="customer-sidebar col-md-2">
        <div class="inner-sidebar">
          <ul>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-1.jpg">
                <span>Add New</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-2.jpg">
                <span>Save</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-3.jpg">
                <span>Export Invoice</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-4.jpg">
                <span>Payment Coupons</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-5.jpg">
                <span>Final Coupons</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-6.jpg">
                <span>Amortization</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-7.jpg">
                <span>Deal Info</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/unlocked-icon.jpg">
                <span>UN locked</span>
              </a>
            </li>
            <li>
              <a href="javascript:void(0)">
                <img src="assets/images/sidebar-icon-9.jpg">
                <span>Table View</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div> -->
