<div class="customer-form-sec" style="width: 500px;">
	<div class="container-fluid">
	  <div class="row">
		<div class="form-sec tab-content col-md-10">
		  <div id="customer" class="tab-pane fade in active show">
  	<div class="modal-header form-sec tab-content ">
	  <h4 class="modal-title pull-left">Edit Caption</h4>
	  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef1.hide()">
		<span aria-hidden="true">&times;</span>
	  </button>
	</div>
	<div class="modal-body">
	  <form #rolesForm="ngForm" id="rolesForm">
		<div id="misc-info" class="tab-pane fade in active show">
			<div class="row">
			  <div class="col-md-12">
				<div class="inner-misc">
				  <div class="misc-grid">
					<div class="row">
					  <div class="col-md-12 mise-left-grid">
						<div class="rw">
						  <label>Original Name</label>
						  <input type="text" class="cstm-input" name="gapCompanyName"
						  [ngModel]="labelName"
						  (ngModel)="labelName = $event"
						  (ngModelChange) = "labelName = $event; "
						  (blur) = "onChange($event)" >
						</div>
					  </div>					
					</div>
				  </div>
				</div>
			  </div>
			</div>
		</div>
	  </form>
	</div>
	<div class="modal-footer">
	  <button type="button" class="btn btn-default" (click)="bsModalRef1.hide()">Cancel</button>
	  <button type="button" class="btn btn-success" (click)="updateGap()">Submit</button>
	</div>
	</div>
	</div>
	</div>
	</div>
	</div>

