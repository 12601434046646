import { Sale } from 'src/app/_models/sale';
import moment from 'moment';
export class InstallmentChangedClass {
    moment = require('moment');
    dayOfMonth = 0;
    async getFirstDueDateInstallmentChanged(sale: Sale): Promise<Sale> {
        switch (sale.amoInstallments.value) {
            case 'Weekly':
              sale.firstDueDate =  this.addDays(moment(sale.soldDate).toDate(), 7);
              break;
              case 'Monthly':
                sale.firstDueDate = moment(sale.soldDate).add(1, 'M').toDate();
              break;
              case 'Bi-Weekly':
                  sale.firstDueDate = this.addDays(moment(sale.soldDate).toDate(), 14);
              break;
              case 'Semi-Monthly':
                // payments will always be
                //  5th and 20th or 1st and 15th
                // so if loan starts less than 5 first due is 20
                this.dayOfMonth = parseFloat(moment(sale.soldDate).format('D')) ;
               // this.tempFirstDueDate = sale.soldDate;

                if (this.dayOfMonth >= 2  && this.dayOfMonth <= 4) {
                  sale.firstDueDate = moment(sale.soldDate).set('date', 20).toDate();
                }
                if (this.dayOfMonth >= 6 && this.dayOfMonth <= 14) {
                  sale.firstDueDate = moment(sale.soldDate).set('date', 1).toDate();
                  sale.firstDueDate = moment(sale.firstDueDate).add(1, 'M').toDate();
                }
                if (this.dayOfMonth >= 16 && this.dayOfMonth <= 19) {
                  sale.firstDueDate = moment(sale.soldDate).set('date', 5).toDate();
                  sale.firstDueDate = moment(sale.firstDueDate).add(1, 'M').toDate();
                }
                if (this.dayOfMonth >= 21 && this.dayOfMonth <= 31) {
                  sale.firstDueDate = moment(sale.soldDate).set('date', 15).toDate();
                  sale.firstDueDate = moment(sale.firstDueDate).add(1, 'M').toDate();
                }
                // two freebies
                if (this.dayOfMonth === 1) {
                  sale.firstDueDate = moment(sale.soldDate).set('date', 15).toDate();
                }
                if (this.dayOfMonth === 5) {
                  sale.firstDueDate = moment(sale.soldDate).set('date', 20).toDate();
                }
                if (this.dayOfMonth === 15) {
                  sale.firstDueDate = moment(sale.soldDate).set('date', 1).toDate();
                  sale.firstDueDate = moment(sale.firstDueDate).add(1, 'M').toDate();
                }
                if (this.dayOfMonth === 20) {
                  sale.firstDueDate = moment(sale.soldDate).set('date', 5).toDate();
                  sale.firstDueDate = moment(sale.firstDueDate).add(1, 'M').toDate();
                }
                // code block
              break;
            default:
              // code block
          }
          return sale;
    }
    addDays(date: Date, days): Date {
        date.setDate(date.getDate() + parseInt(days, 10));
        return date;
      }
}