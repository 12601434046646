

   <button  (click) = "addImage()" style="width: 200px;" class="btn btn-success">Add Image</button>
   

<div>
   
   <tabset>
     <tab heading="Page Images" id="tab1">
      <div cdkDropList  class="row"
      id="page_images"
                  #displayedFieldsList = "cdkDropList"
                  [cdkDropListData] = " sortBycontentBannerPhotos('sortOrder')"
                 
      (cdkDropListDropped)="drop($event)" >
            <div *ngFor= "let photo of sortBycontentBannerPhotos('sortOrder'); index as i" style="padding: 5px;" class="pop md-3-12"  cdkDrag>{{ i + 1 }}
               <img style="padding: 5px;" width="500" height="200" [src]="photo.url" alt="" >
            </div>
      </div>
      </tab>
     <tab heading="Banner Images">
      <div cdkDropList class="row"
      id="banner_images"
                  #displayedFieldsList2 = "cdkDropList"
                  [cdkDropListData] = "sortBycontentPagePhotos('sortOrder')"
                 
      (cdkDropListDropped)="drop($event)" >
      <div  *ngFor= "let photo of sortBycontentPagePhotos('sortOrder'); index as i" class="pop md-3-12" cdkDrag>{{ i + 1 }}
         <img  style="padding: 5px;"  width="500" height="200" [src]="photo.url" alt="">
      </div>
      </div>
      </tab>
     <!-- <tab heading="Basic Title 2">
        Basic content 2
      </tab> -->
   </tabset>
 </div>