<div class="customer-form-sec">
  <div class="container-fluid">
    <br />
    <button [ngClass]="companySeeds?.seedQuickBooksInvoiceDefaults ? 'btn btn-success': 'btn btn-info'"  (click)="seedQbDefaults()" class="mr-2 mb-2" type="submit">Seed Quick Books Invoice</button>
    <button [ngClass]="companySeeds?.seedPdfCountryList ? 'btn btn-success': 'btn btn-info'"  (click)="seedCountry()" class="mr-2 mb-2" type="submit">CountryList Seeds</button>
    <button [ngClass]="companySeeds?.seedPDFCustomFields ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="button" value="Input">seedPDFCustomFields</button>
    <button [ngClass]="companySeeds?.seedPDFVehicleSold ? 'btn btn-success': 'btn btn-info'" (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Sold Vehicles</button>
    <button [ngClass]="companySeeds?.seedPDFVehicleTrade1 ? 'btn btn-success': 'btn btn-info'" (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Trade Vehicle1</button>
    <button [ngClass]="companySeeds?.seedPDFVehicleTrade2 ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Trade Vehicle1</button>
    <button [ngClass]="companySeeds?.seedSaleToRetrieveLoan ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Loan</button>
    <button [ngClass]="companySeeds?.seedSaleToRetrieveDealInfo ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Loan</button>
    <button [ngClass]="companySeeds?.seedSaleToRetrieve ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Sale</button>
    <button [ngClass]="companySeeds?.seedSaleToRetrieveTaxes ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Taxes</button>

    <button [ngClass]="companySeeds?.seedSaleToRetrieveDownPayment ? 'btn btn-success': 'btn btn-info'" (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Down Payments</button>
    <button [ngClass]="companySeeds?.seedPdfVehicleSoldLienHolderProfile ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed LienHolder</button>
    <button [ngClass]="companySeeds?.seedPdfVehicleTrade1LienHolderProfile ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed LienHolder1</button>
    <button [ngClass]="companySeeds?.seedPdfVehicleTrade2LienHolderProfile ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed LienHolder2</button>
    <button [ngClass]="companySeeds?.seedPdfSoldToInsurance ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Sold Ins</button>

    <button [ngClass]="companySeeds?.seedPdfCompanyProfile ? 'btn btn-success': 'btn btn-info'"   (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Company</button>
    <button [ngClass]="companySeeds?.seedSaleToRetrieve ? 'btn btn-success': 'btn btn-info'"   (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Sale</button>
    <button [ngClass]="companySeeds?.seedPdfFieldSalesman1 ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Salesman 1</button>
    <button [ngClass]="companySeeds?.seedPdfFieldSalesman2 ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Salesman 2</button>
    <button [ngClass]="companySeeds?.seedPdfFieldNameBuyers ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class=" mr-2 mb-2" type="submit" value="Submit">Pdf Seed Buyers</button>
    <button [ngClass]="companySeeds?.seedPdfFieldCoBuyers ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class=" mr-2 mb-2" type="submit" value="Submit">Pdf Seed Co Buyers</button>

    <button [ngClass]="companySeeds?.seedPdfFieldReference1 ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Reference 1</button>
    <button [ngClass]="companySeeds?.seedPdfFieldReference2 ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Reference 2</button>
    <button [ngClass]="companySeeds?.seedPdfFieldReference3 ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Reference 3</button>
    <button [ngClass]="companySeeds?.seedPdfActiveVehicleType ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Active Vehicle</button>
    <button [ngClass]="companySeeds?.seedLoanPaymentRecieptPDFInfo ? 'btn btn-success': 'btn btn-info'" (click)="seedPdfFieldNames()" class="mr-2 mb-2" type="submit" value="Submit">Pdf Seed Loan Reciepts</button>
    
    <button [ngClass]="companySeeds?.seedWebsiteSettings ? 'btn btn-success': 'btn btn-info'"  (click)="seedSettings()" class="mr-2 mb-2" type="submit" value="Submit">Seed Settings</button>
    <button [ngClass]="companySeeds?.seedPaymentType ? 'btn btn-success': 'btn btn-info'"  (click)="seedPaymentType()" class=" mr-2 mb-2" type="submit" value="Submit">Seed Payment Types</button>
    <button [ngClass]="companySeeds?.seedSaleTaxDefault ? 'btn btn-success': 'btn btn-info'" (click)="seedSaleTaxDefault()" class="mr-2 mb-2" type="submit" value="Submit">Seed Sale Tax</button>
    <button [ngClass]="companySeeds?.seedAmoLoanMethod ? 'btn btn-success': 'btn btn-info'"  (click)="seedAmoLoanMethod()" class="mr-2 mb-2" type="submit" value="Submit">Seed Loan methods</button>
    <button [ngClass]="companySeeds?.seedAmoInstallments ? 'btn btn-success': 'btn btn-info'"  (click)="seedAmoInstallments()" class="mr-2 mb-2" type="submit" value="Submit">Seed Loan Installments</button>
    
    <button  [ngClass]="companySeeds?.seedSaleType ? 'btn btn-success': 'btn btn-info'"   (click)="seedSaleType()" class="mr-2 mb-2" type="submit" value="Submit">Seed Sale Type</button>
    <button  [ngClass]="companySeeds?.seedSaleStatus ? 'btn btn-success': 'btn btn-info'"   (click)="seedSaleStatus()" class="mr-2 mb-2" type="submit" value="Submit">Seed Sale Status</button>
    <button  [ngClass]="companySeeds?.seedTaxTemplate ? 'btn btn-success': 'btn btn-info'"   (click)="seedTaxTemplate()" class="mr-2 mb-2" type="submit" value="Submit">Seed Sale Tax Template</button>
    
    <button [ngClass]="companySeeds?.seedFormNameDealInfo ? 'btn btn-success': 'btn btn-info'"   (click)="seedFormNameDealInfo()" class="btn btn-primary mr-2 mb-2" type="submit" value="Submit">Seed DealInfo  Form Name </button>
    <button [ngClass]="companySeeds?.seedFormNameVehicleDetails ? 'btn btn-success': 'btn btn-info'"   (click)="seedFormNameVehicleDetails()" class="btn btn-primary mr-2 mb-2" type="submit" value="Submit">Seed Vehicles  Form Name </button>
    <button [ngClass]="companySeeds?.seedLabelsVehicleDetails ? 'btn btn-success': 'btn btn-info'"   (click)="seedLabelsVehicleDetails()" class="btn btn-primary mr-2 mb-2" type="submit" value="Submit">Seed Vehicles Labels</button>
    <button [ngClass]="companySeeds?.seedLabelsDealInfo ? 'btn btn-success': 'btn btn-info'"  (click)="seedLabelsDealInfo()" class="btn btn-primary mr-2 mb-2" type="submit" value="Submit">Seed DealInfo labels </button>
    
    <button [ngClass]="companySeeds?.seedVehicleMileageCodes ? 'btn btn-success': 'btn btn-info'"   (click)="seedVehicleMileageCodes()" class="mr-2 mb-2" type="submit" value="Submit">Seed Mileage Codes </button>
    <button [ngClass]="companySeeds?.seedVehicleBodyTypes ? 'btn btn-success': 'btn btn-info'"   (click)="seedVehicleBodyTypes()" class="mr-2 mb-2" type="submit" value="Submit">Seed Body Types </button>
    <button [ngClass]="companySeeds?.seedZipCodeLookups ? 'btn btn-success': 'btn btn-info'"   (click)="seedZipCodeLookups()" class="mr-2 mb-2" type="submit" value="Submit">Seed Zip Code Lookups </button>
    
    <button [ngClass]="companySeeds?.seedItemTypes ? 'btn btn-success': 'btn btn-info'"   (click)="seedItemTypes()" class=" mr-2 mb-2" type="submit" value="Submit">Seed Item Types </button>
    <button [ngClass]="companySeeds?.seedDollarTypes ? 'btn btn-success': 'btn btn-info'"   (click)="seedDollarTypes()" class="mr-2 mb-2" type="submit" value="Submit">Seed Dollar Types</button>
    <button [ngClass]="companySeeds?.seedProgramDefaults ? 'btn btn-success': 'btn btn-info'"  (click)="seedZipCodeLookups()" class=" mr-2 mb-2" type="submit" value="Submit">Seed Program Defaults </button>
    <button [ngClass]="companySeeds?.seedStates ? 'btn btn-success': 'btn btn-info'"    (click)="seedStates()" class="mr-2 mb-2" type="submit" value="Submit">Seed States </button>


    <button  [ngClass]="companySeeds?.seedPropertyRepairTypes ? 'btn btn-success': 'btn btn-info'" (click)="seedPropertyRepairTypes()" class="mr-2 mb-2" type="submit" value="Submit">Seed Property Repair Types </button>
    <button [ngClass]="companySeeds?.seedSalesSoldAs ? 'btn btn-success': 'btn btn-info'"  (click)="seedSalesSoldAs()" class="mr-2 mb-2" type="submit" value="Submit">Seed Sold As </button>
    <button [ngClass]="companySeeds?.seedPdfFormPackagePrintLocation ? 'btn btn-success': 'btn btn-info'" (click)="seedPdfFormPackagePrintLocation()" class=" mr-2 mb-2" type="submit" value="Submit">Seed Pdf Form Locations </button>
    <button [ngClass]="companySeeds?.seedPdfEntityModelType ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfEntityModelType()" class=" mr-2 mb-2" type="submit" value="Submit">Seed Entity Model Type </button>
    <button [ngClass]="companySeeds?.seedPdfTypeCategory ? 'btn btn-success': 'btn btn-info'"  (click)="seedPdfTypeCategory()" class=" mr-2 mb-2" type="submit" value="Submit">Seed Pdf Category Types </button>
   </div>
   </div>
   
   
   
  

