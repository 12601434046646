import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';

import { NgForm } from '@angular/forms';
import { Label } from 'src/app/_models/label';
import { AuthService } from 'src/app/_services/auth.service';
import { FormNameLabelsService } from 'src/app/_services/formnamelabels/form-name-labels.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-form-name-modal',
  templateUrl: './form-name-modal.component.html',
  styleUrls: ['./form-name-modal.component.css']
})
export class FormNameModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef, private authService: AuthService,
      private formNameService: FormNameLabelsService,
       private alertify: AlertifyService) { }
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Output() formLabel = new EventEmitter();
  labelName: Label;
  formValues: any;
  isAdd: boolean;
  selectedCompany1: UserAssignedToCompany;
  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
  }

  update() {
    this.formValues = this.editForm.value;
    this.labelName = this.formValues.value;
    this.formLabel.emit(this.editForm.value);
    this.bsModalRef.hide();
  }
}
