import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AddRepairDto } from 'src/app/_models/DTOS/addrepairdto';
import { RealPropertyRepairs } from 'src/app/_models/RealPropertyRepairs';
import { RealPropertyRepairTypes } from 'src/app/_models/RealPropertyRepairTypes';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';

@Component({
  selector: 'app-realprop-addrepair',
  templateUrl: './realprop-addrepair.component.html',
  styleUrls: ['./realprop-addrepair.component.css']
})
export class RealpropAddrepairComponent implements OnInit {
  selectedCompany: UserAssignedToCompany;
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  repair = {} as AddRepairDto ;
  repairTypes = [] as RealPropertyRepairTypes[];
  selectedCategory: any;
  @Output() update = new EventEmitter<AddRepairDto>();
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.editForm.dirty) {
      $event.returnValue = true;
    }
  }
  constructor(public bsModalRef1: BsModalRef) {

  }

  ngOnInit() {
    this.repair.itemAmount = 0;
    this.repair.itemAmount = Number(this.repair.itemAmount.toFixed(2)); 
    this.repair.totalAmount = 0;
    this.repair.totalAmount = Number(this.repair.totalAmount.toFixed(2)); 
    this.repair.count = 0;
  }
  onSubmit() {
    this.update.emit( this.repair);
    console.log(this.repair);
    this.bsModalRef1.hide();
  }
  blur() {
    if (this.repair.itemAmount === null || this.repair?.itemAmount === undefined) { this.repair.itemAmount  = this.fnz02(0); }
    if (this.repair.count === null || this.repair?.count === undefined) { this.repair.count  = this.fnz02(0); }
    if (this.repair.itemAmount >= 0 && this.repair.count >= 0) {
      this.repair.totalAmount = (this.repair.itemAmount * this.repair.count);
       this.repair.itemAmount = Number(this.repair.itemAmount.toFixed(2));
      this.repair.totalAmount = Number(this.repair.totalAmount.toFixed(2));
    }
  }
  selected(e: number) {
    console.log(e);
    if(e > 0) {
      if (this.repairTypes.length >> 0) { this.selectedCategory = this.repairTypes[0];  this.repair.realPropertyRepairTypesId = this.repairTypes[0].id; }
      this.repair.realPropertyRepairTypesId = e;
      console.log(this.repair);
    }
    if(e === -1) {
      this.repair.realPropertyRepairTypesId = null;
      console.log(this.repair);
    }
  }
  fnz02 = (num) => num.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
    })
}
