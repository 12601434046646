


<div class="customer-form-sec" style="margin-top: 10px;">
  <div class="container-fluid">
    <app-produts-filters (pageChangeExport) = "pageChanged($event)"  
(userParamsExport) = "filter($event)" 
[pagination] = "pagination">
</app-produts-filters>
<br><br>
<br>
    <div class="row">
      <div class="form-sec tab-content col-md-11" >
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <!-- class="inner-row account-payable-row" style="overflow: -moz-hidden-unscrollable;" -->
              <div  class="inner-row account-payable-row" >          
                <div class="row">
                
                  <div class="col-md-11 table-sec" style="z-index: 100000; " >
                   
                      <table borderColor="#ccc" style="position: fixed; max-width:inherit !important; width: inherit !important;">
                          <!-- header -->
                            <tr>
                            <th>ID</th>
                          <th (click)="sort('category')">Category &nbsp;
                          <i [ngClass]="isCategoryAscending ? 'up': 'down'"></i>
                          </th>
                            <th (click)="sort('stock')">Stock&nbsp;
                          <i [ngClass]="isStockAscending ? 'up': 'down'"></i>
                          </th>
                            <th (click)="sort('year')">Year&nbsp;
                          <i [ngClass]="isYearAscending ? 'up': 'down'"></i>
                          </th>
                            <th (click)="sort('make')">Make&nbsp;
                          <i [ngClass]="isMakeAscending ? 'up': 'down'"></i>
                          </th>
                            <th (click)="sort('model')">Model&nbsp;
                          <i [ngClass]="isModelAscending ? 'up': 'down'"></i>
                          </th>
                            <th (click)="sort('body')">Body&nbsp;
                          <i [ngClass]="isBodyAscending ? 'up': 'down'"></i>
                          </th>
                            <th (click)="sort('price')">Price&nbsp;
                          <i [ngClass]="isPriceAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('isRepairsAscending')">Repairs&nbsp;
                          <i [ngClass]="isRepairsAscending ? 'up': 'down'"></i>
                          </th>
                            <th (click)="sort('isCostAscending')">Cost&nbsp;
                          <i [ngClass]="isCostAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('isTotalCostAscending')">Total Cost&nbsp;
                          <i [ngClass]="isTotalCostAscending ? 'up': 'down'"></i>
                            <th (click)="sort('solddate')"> Sold Date&nbsp;
                          <i [ngClass]="isSoldDateAscending ? 'up': 'down'"></i>
                          </th>
                          
                          <th (click)="sort('isPurchaseDate')"> Puchased Date&nbsp;<i [ngClass]="isPurchaseDate ? 'up': 'down'"></i></th>
                          <th (click)="sort('isDaysInStockAscending')">Days Old&nbsp;<i [ngClass]="isDaysInStockAscending ? 'up': 'down'"></i></th>
                          <th></th>
                          </tr>
                            <!-- Search -->
                            <tr  [hidden] = "isPrint">
                              <td></td>
                            <td >

                            <select  
                            [(ngModel)] ="selectedCategory" (change) ="selectedCategoryChanged($event.target.value)"
                            name="vehicleBodyClassTypes"
                            onmousedown = "value = '';" 
                            #vehicleBodyClassTypes="ngModel">
                            <option ngValue= "null">--All--</option>
                            <option *ngFor="let summary of bodyTypes" value="{{summary.id}}">
                            {{summary.nameFriendly}}
                            </option>

                            </select>
                            </td>
                              <td style="width: 76px"><input (keyup)="setSearchBy('searchStock', $event.target.value)" >
                              </td>  
                              <td><input style="width: 55px;" (keyup)="setSearchBy('searchYear', $event.target.value)">
                              </td>  
                              <td><input style="width: 96px;" (keyup)="setSearchBy('searchMake', $event.target.value)"></td>  
                              <td><input style="width: 96px;" (keyup)="setSearchBy('searchModel', $event.target.value)"></td>  
                              <td><input style="width: 116px;" (keyup)="setSearchBy('searchBody', $event.target.value)"></td>  
                              <td><input style="width: 96px;" (keyup)="setSearchBy('searchPrice', $event.target.value)"></td>  
                              <td><input style="width: 96px;" (keyup)="setSearchBy('searchRepairs', $event.target.value)"></td>  
                              <td><input style="width: 96px;" (keyup)="setSearchBy('searchCost', $event.target.value)"></td> 
                              <td></td> 
                              <td><input style="width: 96px;" (keyup)="setSearchBy('searchSoldDate', $event.target.value)"></td>  
                              <td><input style="width: 96px;" (keyup)="setSearchBy('searchPurchasedDate', $event.target.value)"></td>  
                            <td></td>
                            <td></td>
                            </tr>
                       </table>
                      
                      </div>
                      <br><br>
                      
                       <div class="col-md-12 table-sec" >  
                        <div  id="printableTable">
                        <table  borderColor="#ccc" >
                      <!-- results -->
                      <tr  (dblclick)="editVehicle(item)" align="right" class="td-row" *ngFor="let item of chartItems; let i=index">
                        <td  align="left" *ngIf="i != chartItems.length - 1">{{ item.id }}</td>
                        <td align="left" *ngIf="i != chartItems.length - 1">{{ item?.bodyType }}</td>
                        <td align="left" *ngIf="i != chartItems.length - 1">{{ item?.stock }}</td>
                        <td align="left" *ngIf="i != chartItems.length - 1">{{ item?.year }}</td>
                        <td align="left" *ngIf="i != chartItems.length - 1">{{ item?.make }}</td>
                        <td align="left" *ngIf="i != chartItems.length - 1">{{ item?.model }}</td>
                        <td align="left" *ngIf="i != chartItems.length - 1">{{ item?.series }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.suggestedPrice | number: "1.2" }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.repairs | number: "1.2" }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.purchasePrice | number: "1.2" }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.totalCost | number: "1.2" }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.soldDate | date: "shortDate" }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.purchaseDate | date: "shortDate" }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.daysInStock }}</td>

                        <td [hidden] = "isPrint" *ngIf="i != chartItems.length - 1">
                          <button
                            class="btn btn-outline-success"
                            (click)="editVehicle(item)"
                          >
                            Edit
                          </button>
                        </td>
                      </tr>
                      <!-- footer -->
                      <tr  align="right" class="border_top" *ngFor="let item of chartItems; let i=index; last as isLast">
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">Sub Totals: </td>
                        <td *ngIf="isLast && i !==0">{{ item?.suggestedPrice | number: "1.2" }}</td>
                        <td *ngIf="isLast && i !==0">{{ item?.repairs | number: "1.2" }}</td>
                        <td *ngIf="isLast && i !==0">{{ item?.purchasePrice | number: "1.2" }}</td>
                        <td *ngIf="isLast && i !==0">{{ item?.totalCost | number: "1.2" }}</td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">{{ item?.daysInStock }}</td>
                        <td *ngIf="isLast && i !==0"></td>
                        </tr>
                    </table>
                    </div> 
                  </div>
                  <!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-1">
        <div class="inner-sidebar">
          <ul>
            <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->
            <!-- <li>
              <button (click)="addVehicle()" class="cstm-btn img-blk-btn">
                <img src="assets/images/ap-icon-1.png" />
                <span>Add Vehicle</span>
              </button>
            </li> -->
            <li >
              <button class="cstm-btn" (click) = "exportAsXLSX()">
                <img src="assets/images/icons8-microsoft-excel-48.png" />
                <span>Export Excel</span>
              </button>
            </li>
            <li>
              <button (click) = "printDiv()" class="cstm-btn">
                <img src="assets/images/ap-icon-6.png" />
                <span>Print Report</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>