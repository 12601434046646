import { Component, OnInit } from '@angular/core';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ChartInvoiceSaletypeModaladdComponent } from './chart-invoice-saletype-modaladd/chart-invoice-saletype-modaladd.component';
import { SaleType } from 'src/app/_models/saletype';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SaleTypeService } from 'src/app/_services/saleservices/sale-types.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-chart-invoice-sale-types-list',
  templateUrl: './chart-invoice-sale-types-list.component.html',
  styleUrls: ['./chart-invoice-sale-types-list.component.css']
})
export class ChartInvoiceSaleTypesListComponent implements OnInit {
  chartInvoiceSaleTypes: SaleType[];
  selectedCompany1: UserAssignedToCompany;
  isDelete: boolean;
  bsModalRef: any;

  constructor(private route: ActivatedRoute,
    private modalService: BsModalService, private chartInvoiceSaleTypeService: SaleTypeService,
     private authService: AuthService,
    private alertify: ToastrService) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      this.chartInvoiceSaleTypes = data.chartInvoiceSaleTypes;
    });
  }

  delete(e) {
    console.log(e);
    this.isDelete = false;
    if (e.deletedDate == null) {
      this.isDelete = true;
    }
    this.chartInvoiceSaleTypeService.deleteSaleType(this.selectedCompany1.parentGuid, e.id, this.isDelete).subscribe(data => {
      console.log(data);
      this.chartInvoiceSaleTypes = data;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Payment Types Updated');
    });
}

editStatus(e: SaleType) {
  const initialState = {
    chartInvoiceSaleType:  e
  };
  this.bsModalRef = this.modalService.show(ChartInvoiceSaletypeModaladdComponent, { initialState });
  this.bsModalRef.content.update.subscribe((values) => {
    e.value = values.value;
    this.chartInvoiceSaleTypeService.editSaleType(this.selectedCompany1.parentGuid, e).subscribe(data => {
      this.chartInvoiceSaleTypes = data;
    }, error => {this.alertify.error(error); }, () => {this.alertify.success('Status  Updated'); });
  });
}

addStatus() {
  this.bsModalRef = this.modalService.show(ChartInvoiceSaletypeModaladdComponent);
  this.bsModalRef.content.update.subscribe((values) => {
    console.log(values.value);
    this.chartInvoiceSaleTypeService.addSaleType(this.selectedCompany1.parentGuid, values.value).subscribe(data => {
      this.chartInvoiceSaleTypes = data;
    }, error => {this.alertify.error(error); }, () => {this.alertify.success('Status  Added'); });
  });
}
}
