import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PersonLinqResultToReturnDtoResolver } from '../_resolvers/PersonLinqResultToReturnDto.resolver';
import { QbConnectComponent } from './qb-connect/qb-connect.component';
import { QuickbooksConnectedComponent } from './quickbooks-connected/quickbooks-connected.component';



const routes: Routes = [

    {path: 'connect/:parentGuid', component: QbConnectComponent },
    {path: 'connect', component: QbConnectComponent },
    {path: 'connected', component: QuickbooksConnectedComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class QuickBooksRoutingModule {}

