import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';
import { Person } from 'src/app/_models/person';
import { Sale } from 'src/app/_models/sale';
import { SaleStatus } from 'src/app/_models/salestatus';
import { SaleType } from 'src/app/_models/saletype';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { ListService } from 'src/app/_services/listServices/list.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';

@Component({
  selector: 'app-sale-customer',
  templateUrl: './sale-customer.component.html',
  styleUrls: ['./sale-customer.component.css']
})
export class SaleCustomerComponent implements OnInit, OnChanges {
  @Input() sale: Sale;
  @ViewChild('dealInfoForm') dealInfoForm: NgForm;
  selectedSaleType: any;
  selectedSaleStatus: any;
  saleTypes: SaleType[];
  saleType: SaleType;
  saleStatus: SaleStatus[];
  saleStat: SaleStatus;
  selectedCompany1: UserAssignedToCompany;
  i = 0 as number;
buyer = {} as Person;
coBuyer = {} as Person;
  constructor(private listService: ListService,
     private saleSerivce: SaleService,
     private authService: AuthService,
     private format: DealInforFormat, private router: Router,
     private alertify: ToastrService) { }
  ngOnChanges() {
  //  this.dealInfoForm.controls.saleTypes.value(this.saleTypes);
    this.listService.currentSaleTypes.subscribe(data => { this.saleTypes = data;  });
   // console.log(this.sale.saleTypes);
    this.selectedSaleType = this.sale.saleTypes.value;
    this.listService.currentsaleStatus.subscribe(data => { this.saleStatus = data;
      // console.log(data);
    });
  //  console.log(this.sale.saleStatus);
    this.selectedSaleStatus = this.sale.saleStatus.value;
  }
  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
    if (localStorage && !localStorage.getItem('size')) {
      try {
          // Test up to 10 MB
          for (this.i = 250; this.i <= 10000; this.i += 250) {
              localStorage.setItem('test', new Array((this.i * 1024) + 1).join('a'));
          }
      } catch (e) {
          localStorage.removeItem('test');
          localStorage.setItem('size', (this.i - 250).toString() );
      }
      if (self.indexedDB) {
        console.log('IndexedDB is supported');
    }
  }
}
tabelViewClick(){
  this.router.navigate(['saledashboard/saledashboard/' + this.selectedCompany1.parentGuid]);
}
amoSaleStatusSelected(e) {
  console.log(e);
  this.saleStat = this.saleStatus.find(
    (item: SaleStatus) => item.value === e
  );
   this.sale.saleStatusid = this.saleStat.id;
   this.sale.saleStatus = this.saleStat;
 // console.log(this.sale);
}
amoSaleTypeSelected(e) {
  console.log(e);
  this.saleType = this.saleTypes.find(
    (item: SaleType) => item.value === e
  );
   this.sale.saleTypesId = this.saleType.id;
   this.sale.saleTypes = this.saleType;
}
test(e) {
  console.log(e.value);
}
   onChange(e) { console.log(this.sale); }
  // async update() {
  //   console.log(this.sale);
  //   // if(this.sale.docAuto === "false") {this.sale.docAuto = false; } else { this.sale.docAuto = true; }
  //   // this.sale.docAuto = 0;
  //   this.sale.passPmts = 0;
  //   if (this.sale !== null) {
  //  //   this.dealInfoForm.reset(this.sale);
  //   this.sale = await this.format.formatDealInforNumbers(this.sale);
  //   this.saleSerivce.updateSale(this.selectedCompany1.parentGuid, this.sale).subscribe(data => {
  //     console.log(data);
  //   }, error => { this.alertify.error(error); });
  //   }

  // }
  async update() {
    console.log(this.sale);
    // if(this.sale.docAuto === "false") {this.sale.docAuto = false; } else { this.sale.docAuto = true; }
    // this.sale.docAuto = 0;
    this.sale.passPmts = 0;
    if (this.sale !== null) {
      this.sale = await this.format.formatDealInforNumbers(this.sale);
      this.saleSerivce
        .updateSale(this.selectedCompany1.parentGuid, this.sale)
        .subscribe(
          (data) => {
            this.alertify.success('Sale Updated');
            this.alertify.success('Accounting will be updated in background.');
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {}
        );
    }
  }
}
