export class Field {
    id: number;
    name?: string;
    index: number;
    guid: string;
    length?: number;
    position: { x: number; y: number };
    x?: number;
    y?: number;
    color?: string;
    height?: number;
    width?: number;
    align?: any;
    selected?: boolean;
    zindex?: number;
    isCalculated?: boolean;
    calculation?: string;
    nameFriendly?: string;
    parsedBy?: string;
}
// export class Field {
//     id: number;
//     selected: boolean;
//     zindex?: number;
//     name?: string;
//     index: number;
//     guid: string;
//     position: { x: number; y: number };
//     x?: number;
//     y?: number;
//     color?: string;
//     height?: number;
//     width?: number;
//     align?: any;
//   }
