<!-- width: 60% !important; -->
<div class="customer-tabs-sec" style="padding-bottom: 10px;">
  <ul class="nav nav-tabs">
    <li><a [ngClass] = "isDeleted ? 'active show' : ''" (click) ="isActiveDeletedClick('isDeleted')"  href="javascript:void(0)">Deleted</a></li>
    <li  ><a [ngClass] = "isActive ? 'active show' : ''" (click) ="isActiveDeletedClick('isActive')"  href="javascript:void(0)">Active</a></li>
    <li  ><a [ngClass] = "isAll ? 'active show' : ''" (click) ="isActiveDeletedClick('isAll')"  href="javascript:void(0)">All</a></li>
    <li  ><a [ngClass] = "isExpandSearch ? 'active show' : ''" (click) ="isActiveDeletedClick('isExpandSearch')"  href="javascript:void(0)">Expand Search</a></li>
    <!-- <li><a (click) ="tabClick(isWashout)" href="javascript:void(0)">Payables</a></li>
    <li><a (click) ="tabClick(isWashout)" href="javascript:void(0)">Recievable</a></li> -->
    <!-- <li><a  [ngClass] = "isAmortization ? 'active show' : ''" (click) ="tabClick('isAmortization')" href="javascript:void(0)">Amortization</a></li> -->
  </ul>
  </div>