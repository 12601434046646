
<app-admin-dashboard></app-admin-dashboard>
<div class="container-fluid customer-tabs-sec">
    <!-- <ul class="nav nav-tabs">
     <li><a [ngClass]="{'active show' : isUsers }"  (click)="tabClicked('isUsers')" href="javascript:void(0)">Vehicles</a></li>
      <li><a [ngClass]="{'active show' : isDefaults }"  (click)="tabClicked('isDefaults')" href="javascript:void(0)">All</a></li>
    </ul> -->
  </div>

  <!-- <app-user-management [hidden] = "!isUsers"></app-user-management>
  <app-photo-management [hidden] = "!isDefaults"></app-photo-management> -->