import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PaymentType } from '../_models/paymenttype';
import { PaymentTypeService } from '../_services/paymenttypeservices/payment-type.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class PaymentTypeResolver implements Resolve<PaymentType[]> {
    constructor(private paymentTypeService: PaymentTypeService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<PaymentType[]> {
      //  console.log(route.params['parentGuid']);
        return this.paymentTypeService.getPaymentTypes(route.params.parentGuid).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving chart payment types.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
