export class WebsiteCompanyToUpdateDto {
    addressDetailId: number;
    street: string;
    city: string;
    state: string;
    zip: string;
    county: string;
    companyId: number;
    companyName: string;
    phone: string;
    phone2: string;
    email: string;
}
