import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';
import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';

@Component({
  selector: 'app-misccharge-edit',
  templateUrl: './misccharge-edit.component.html',
  styleUrls: ['./misccharge-edit.component.css']
})
export class MiscchargeEditComponent implements OnInit {
  @Output() emittedValues = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  dollar = {} as Dollars;
  dollarTypes: DollarTypes[];
  selectedDollarType: any;
  isLocked;
  isSoldVehicle = false;
  constructor(public bsModalRef: BsModalRef, private format: DealInforFormat) {
  }

  ngOnInit() {
    this.dollar.isPaid = true;
    this.selectedDollarType = this.dollar.dollarTypeId;
    if (this.dollar.dollarTypes.groupBy === 'AVehicleCost') {
      this.isSoldVehicle = true;
    }
    if (this.dollar.dollarTypes.isAddedCost) {
      this.isLocked = false;
    }
    if (this.dollar.dollarTypes.isMiscChargeToSale) {
      this.isLocked = false;
    }
    console.log(this.isLocked);
  //  this.validateDate();
  }
  validateDate() {
    if (this.dollar.dollarTypeId == null) { this.dollar.dollarTypeId = this.dollarTypes[0].id; }
    if (!this.dollar.dateOfTransaction) {
      this.dollar.dateOfTransaction = new Date();
    }
    if (this.dollar.customerCost === null || this.dollar.customerCost === undefined ) { this.dollar.customerCost = Number(this.format.fnz02(0)) ;
    } else {
      this.dollar.customerCost = Number(this.format.fnz02(this.dollar.customerCost)) ;
    }
    if (this.dollar.credit === null || this.dollar.credit === undefined ) { this.dollar.credit = Number(this.format.fnz02(0)) ;
    } else {
      this.dollar.credit = Number(this.format.fnz02(this.dollar.credit)) ;
    }
    this.dollar.recievableBalance = this.dollar.customerCost;
    this.dollar.profit = this.dollar.customerCost - this.dollar.credit;
  }
  dollarTypeChanged(e) {
    this.dollar.dollarTypeId = Number(e);
    console.log(e);
  }
  update() {
    console.log(this.dollar);
    this.emittedValues.emit(this.dollar);
    this.bsModalRef.hide();
  }

  selectChange(e) {

  }
}

