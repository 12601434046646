import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PdfFormTypes } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/PdfFormTypes';
import { PdfListGridViewDto } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/PdfListGridViewDto';
import { ShopParams } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/ShopParams';
import { PdfForms } from 'src/app/_models/PdfForms';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { PdfImportService } from 'src/app/_services/PdfSErvices/pdf-import.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';

@Component({
  selector: 'app-pdf-importingfromshop',
  templateUrl: './pdf-importingfromshop.component.html',
  styleUrls: ['./pdf-importingfromshop.component.css']
})
export class PdfImportingfromshopComponent implements OnInit {
  @ViewChild('search', {static: true}) public searchTerm: ElementRef<HTMLInputElement>;
pdfListGridViewDto = [] as PdfListGridViewDto[];
  selectedIndex = 0;
  pdfListGridViewDtoIndividual = {} as PdfListGridViewDto;
  pdfFormTypes = [] as PdfFormTypes[];
  shopParams =  new ShopParams();
  pdfForm = {} as PdfForms;
  totalCount = 0;
  supportSubMenusPdf = [];
  pdfFormTypeId: number;
  selectedCompany1: UserAssignedToCompany;
  constructor(private pservice: PdfService, private pdfService: PdfImportService, private authService: AuthService) { }

  ngOnInit(): void {
    this.pservice.getPdfFormType().subscribe((data) => {
      this.pdfFormTypeId = data.id;
      console.log(data);
    });
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
    this.getPdfsPaged();
    this.getSubMenus();
    this.getProductTypes();
  }

  installPdf(e: PdfForms) {
    console.log(e);
    e.pdfFormTypeId = this.pdfFormTypeId;
    this.pdfService.installPdf(e, this.selectedCompany1.parentGuid).subscribe(data => {}, error => { console.log(error); });
  }
  getSubMenus() {
    this.pdfService.getSupportSubMenuBySubMenuTypeValue('states').subscribe(data => {
    //  this.supportSubMenus = data;
      this.supportSubMenusPdf = [{id: 0, checked: false, name: 'All', deletedDate: null, supportMenuId: null, priorityLevel: 0}, ...data];
    }, error => { console.log(error); });
  }
  getPdfsPaged() {
    this.pdfService.getPdfsPaged(this.shopParams).subscribe(data => {
      this.pdfListGridViewDto = data.data;
      this.shopParams.pageNumber = data.pageIndex;
      this.shopParams.pageSize = data.pageSize;
      this.totalCount = data.count;
      console.log(data);
    }, error => { console.log(error); }, () => {  console.log(this.totalCount);
    });
  }
  getProductTypes() {
    this.pdfService.getPdfFormTypeList().subscribe(data => {
      // Add object to Array , pdfForms: []
      this.pdfFormTypes = [{id: 0, value: 'All', deletedDate: null}, ...data];
    }, error => { console.log(error); }, () => {
    });
  }
  onPageChange(e: any): void {
    if (this.shopParams.pageNumber !== e) {
      this.shopParams.pageNumber = e;
      this.getPdfsPaged();
    }
  }
  onTypeSelected(typeId: number): void {
    this.shopParams.typeId = typeId;
    this.shopParams.pageNumber = 1;
    this.getPdfsPaged();
  }
  formTypeSelected(typeId) {
    typeId = Number(typeId);
    if (typeId === 0) {
      typeId = 0;
      this.shopParams.typeId = 0;
    }
    else {
      this.shopParams.typeId = typeId;
    }
    this.shopParams.pageNumber = 1;
    this.getPdfsPaged();
  }
  onCategorySelected(categoryId: number): void {
    console.log(categoryId);
    this.shopParams.categoryId = categoryId;
    this.shopParams.pageNumber = 1;
  // this.getPdfsPaged();
 this.pdfService.getPdfsPagedByMenuId(this.shopParams).subscribe(data => {
  this.pdfListGridViewDto = data.data;
  this.shopParams.pageNumber = data.pageIndex;
  this.shopParams.pageSize = data.pageSize;
  this.totalCount = data.count;
 // this.getPdfsPaged();
  });
  }
  onSearch(): void {
    this.shopParams.search = this.searchTerm.nativeElement.value;
    this.shopParams.pageNumber = 1;
    this.getPdfsPaged();
  }
  onResetSearch(): void {
    this.searchTerm.nativeElement.value = '';
    this.shopParams.typeId = 0;
    this.shopParams.categoryId = 0;
    this.selectedIndex = 0;
    this.pdfForm.pdfFormTypeId = -1;
    this.pdfForm.id = -1;
    this.shopParams = new ShopParams();
    this.getPdfsPaged();
  }
}
