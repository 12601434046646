
  <div class="inner-sidebar">
    <ul>
      <li>
        <button (click) = "addNewBuyersGuid()" class="cstm-btn img-blk-btn">
          <img src="assets/images/ap-icon-1.png">
          <span>Add As Is</span>
        </button>
      </li>
      <li>
        <button (click)="addSystems()"  class="cstm-btn img-blk-btn">
          <img src="assets/images/ap-icon-2.png">
          <span>Add Systems</span>
        </button>
      </li>
     
    </ul>
  </div>


