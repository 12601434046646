import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CompanyProfile } from 'src/app/_models/companyProfile';

@Component({
  selector: 'app-add-companyprofilepopup',
  templateUrl: './add-companyprofilepopup.component.html',
  styleUrls: ['./add-companyprofilepopup.component.css']
})
export class AddCompanyprofilepopupComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  company = {} as CompanyProfile;
  @Output() update = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  saveChanges() {
    this.update.emit(this.company);
  }
  cancel() {
    this.bsModalRef.hide();
  }
}
