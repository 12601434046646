import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebsiteCompany } from 'src/app/_models/company';
import { CompanyDepartmentType } from 'src/app/_models/CompanyDepartmentType';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from '../auth.service';
import { CompanyType } from 'src/app/_models/companyType';
import { WebsiteCompanyToUpdateDto } from 'src/app/_models/DTOS/websitecompanytoupdate';

@Injectable({
  providedIn: 'root'
})
export class CompaniesWebsiteService {
  baseUrl = environment.apiUrl;
  companyId: number;
constructor(private http: HttpClient) { }


getCompanies(parentGuid): Observable<WebsiteCompany[]> {
  return this.http.get<WebsiteCompany[]>(this.baseUrl + 'company/getcompanies/' + parentGuid);
}
getCompanyTypeByName(parentGuid, typename): Observable<CompanyType> {
  return this.http.get<CompanyType>(this.baseUrl + 'company/getcompanytypebyname/' + parentGuid + '/typename/' + typename);
}
  getCompanyById(id): Observable<WebsiteCompany> {
    return this.http.get<WebsiteCompany>(this.baseUrl + 'company/getcompany/' + id);
  }

  getCompanyByCompanyGuid(companyGuid): Observable<WebsiteCompany> {
    return this.http.get<WebsiteCompany>(this.baseUrl + 'company/getcompanybycompanyguid/' + companyGuid);
  }

  makeCompanyTheDefault(id, company): Observable<WebsiteCompany[]> {
    return this.http.put<WebsiteCompany[]>(this.baseUrl + 'company/makecompanydefault/' + id, company);
  }

  editCompany(company: WebsiteCompany): Observable<WebsiteCompany> {
    const entity = new WebsiteCompanyToUpdateDto();
    entity.addressDetailId = company.addressDetailId;
    entity.state = company.addressDetail.state;
    entity.zip = company.addressDetail.zip;
    entity.street = company.addressDetail.street;
    entity.county = company.addressDetail.county;
    entity.city = company.addressDetail.city;
    entity.phone = company.phone;
    entity.phone2 = company.phone2;
    entity.email = company.email;
    entity.companyId = company.id;
    entity.companyName  = company.companyName;
    console.log(entity);
    return this.http.post<WebsiteCompany>(this.baseUrl + 'websitecompany/updatewebsitecompany/', entity);
  }
  saveEditCompany(id, company) {
    return this.http.post(this.baseUrl + 'company/updateparentcompany/' + id, company);
  }
  updateParagraphs(id, company): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'websitecompany/updateparagraphs/' + id, company);
  }

  getOnlyParentCompanies(): Observable<WebsiteCompany[]> {
    return this.http.get<WebsiteCompany[]>(this.baseUrl + 'company/getonlyparentcompanies/');
  }
  getOnlyParentCompaniesGrid(): Observable<WebsiteCompany[]> {
    return this.http.get<WebsiteCompany[]>(this.baseUrl + 'company/getonlyparentcompaniesgrid/1');
  }

  createParentCompanies(company): Observable<WebsiteCompany> {
    return  this.http.put<WebsiteCompany>(this.baseUrl + 'company/addnewparentcompany/', company );
  }

  createCompany(company, parentGuid): Observable<WebsiteCompany> {
    return  this.http.put<WebsiteCompany>(this.baseUrl + 'company/addcompany/' + parentGuid, company );
  }

  createCompanyReturnCompanies(company, parentGuid): Observable<WebsiteCompany[]> {
    return  this.http.put<WebsiteCompany[]>(this.baseUrl + 'company/addcompanyreturncompanies/' + parentGuid, company );
  }

  getCompanyTypes(parentGuid): Observable<CompanyType[]> {
    return  this.http.get<CompanyType[]>(this.baseUrl + 'company/getcompanytypes/' + parentGuid );
  }
  getCompanyDepartmentTypes(): Observable<CompanyDepartmentType[]> {
    return  this.http.get<CompanyDepartmentType[]>(this.baseUrl + 'company/getcompanydeparmenttypes/');
  }

  getUsersAssignedToCompanies(id): Observable<UserAssignedToCompany[]> {
    return  this.http.get<UserAssignedToCompany[]>(this.baseUrl + 'company/getusersassignedtocompanies/' + id);
  }
  getUsersAssignedToCompaniesForMenu(id): Observable<UserAssignedToCompany[]> {
    return  this.http.get<UserAssignedToCompany[]>(this.baseUrl + 'company/getusersassignedtocompaniesformenu/' + id);
  }
  getUsersAssignedToCompaniesAssignTempCheck(id): Observable<WebsiteCompany> {
    return this.http.get<WebsiteCompany>(this.baseUrl + 'company/getusersassignedtocompaniessettempcheck/' + id);
  }
  getUsersUnAssignedToCompaniesAssignTempCheck(id): Observable<WebsiteCompany> {
    return this.http.get<WebsiteCompany>(this.baseUrl + 'company/getusersassignedtocompaniessettempuncheck/' + id);
  }
  
  assignUsersToCompanies(companies: WebsiteCompany[], id): Observable<WebsiteCompany[]> {
    return this.http.put<WebsiteCompany[]>(this.baseUrl + 'company/assigncompanytouser/' + id, companies);
  }

  retrieveUsersToCompanies( id): Observable<WebsiteCompany[]> {
    return this.http.get<WebsiteCompany[]>(this.baseUrl + 'company/retrievecompaniesperuserlogin/' + id);
  }

  
}
