<div class="customer-form-sec" style="width: 500px">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="customer" class="tab-pane fade in active show">
          <div class="modal-header form-sec tab-content">
            <h4 class="modal-title pull-left">New Website</h4>
            <button
              type="button"
              class="close pull-right"
              aria-label="Close"
              (click)="bsModalRef.hide()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form #websiteForm="ngForm" id="websiteForm">
              <div id="misc-info" class="tab-pane fade in active show">
                <div class="col-md-12">
                  <div class="row">
                    <div class="form-group form-inline">
                      <label>Name: </label>
                      <input
                        type="text"
                        class="cstm-input"
                        name="name"
                        [ngModel]="website?.name"
                        (ngModel)="website.name = $event"
                        (ngModelChange)="website.name = $event"
                        (blur)="onChange($event)"
                      />
					</div>
					<div class="form-group form-inline">
						<label>Url: </label>
						<input
						  type="text"
						  class="cstm-input"
						  name="url"
						  [ngModel]="website?.url"
						  (ngModel)="website.url = $event"
						  (ngModelChange)="website.url = $event"
						  (blur)="onChange($event)"
						/>
					  </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              (click)="bsModalRef.hide()"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-success" (click)="updateGap()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
