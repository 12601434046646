import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormName } from 'src/app/_models/formName';


@Injectable({
  providedIn: 'root'
})
export class FormNameLabelsService {

  constructor(private http: HttpClient) { }
  formName: FormName;
  baseUrl = environment.apiUrl;

    getFormNameList(parentguid): Observable<FormName[]> {
    return  this.http.get<FormName[]>(this.baseUrl + 'formname/getformnamelist/' + parentguid );
    }

    getFormNameById(parentguid, id): Observable<FormName> {
      return  this.http.get<FormName>(this.baseUrl + 'formname/getformnamebyid/' + parentguid + '/' + id);
    }

    getFormNameByName(parentguid, name): Observable<FormName> {
    //  console.log(name);
      return  this.http.get<FormName>(this.baseUrl + 'formname/getformnamebyname/' + parentguid + '/' + name);
    }

    addFormNameByName(parentguid, name): Observable<FormName[]> {
      return  this.http.get<FormName[]>(this.baseUrl + 'formname/addformname/' + parentguid + '/' + name);
    }

    editFormNameById(parentguid, id): Observable<FormName[]> {
      return  this.http.get<FormName[]>(this.baseUrl + 'formname/editformname/' + parentguid + '/' + id);
    }
    // used on popups of double clicking a label....
    editFormLabelByFieldName(parentguid, fieldname, value, pagename): Observable<FormName> {
      return this.http.get<FormName>
      (this.baseUrl + 'formname/editformlabelbyfieldname/' + parentguid + '/' + fieldname + '/' + value + '/' + pagename);
    }
    editFormLabel(parentguid, id, value): Observable<FormName[]> {
      return this.http.get<FormName[]>(this.baseUrl + 'formname/editformlabelbyid/' + parentguid + '/' + id + '/' + value);
    }
    addFormLabel(parentguid, value, id): Observable<FormName> {
      return this.http.get<FormName>(this.baseUrl + 'formname/addformlabel/' + parentguid + '/' + value + '/' + id);
    }

    deleteFormLabel(parentguid, formid, labelid): Observable<FormName> {
      return this.http.get<FormName>(this.baseUrl + 'formname/deleteformlabel/' + parentguid + '/' + formid + '/' + labelid);
    }

}
