import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { InventoryVehicleGridToReturnDto } from '../_models/DTOS/inventoryvehiclegriddto';
import { UserParams } from '../_models/userParams';
import { VehicleService } from '../_services/vehicleserv/vehicle.service';

@Injectable({
    providedIn: 'root'  // **** ADD THIS LINE ****
  })
export class VehicleGridResolver implements Resolve<InventoryVehicleGridToReturnDto[]> {
    pageNumber = 1;
    pageSize = 50;
    userParams: UserParams;
    constructor(private chartItemService: VehicleService, private router: Router,
        private alertify: ToastrService) {
            this.userParams = this.chartItemService.getUserParams();
        }

    resolve(route: ActivatedRouteSnapshot): Observable<InventoryVehicleGridToReturnDto[]> {
        const newLocal = 'parentGuid';
     //   console.log(route.params['parentGuid']);
        return this.chartItemService.getVehicleListGrid(route.params[newLocal], this.userParams).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving item data');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
