
<div class="customer-form-sec">
  <div class="container-fluid">
<div class="row">
 
  <!-- style="background-color:#DBE9F4 !important;" -->
  <div class="form-sec tab-content col-md-12">
      <div id="depreciate-costs" class="tab-pane fade in active show">
        <div class="row">
          <div class="col-md-12">
            <div class="inner-row account-payable-row">
              <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->
              
              <div class="row">
                <div class="col-md-5 table-sec">
                  <table border="1" borderColor="#ccc">
                    <tr>
          <th>Id</th>
          <th>Type</th>
          <th>Name</th>
                      <th>Image</th>

                      <th></th>

                    </tr>
                    <tr class="td-row" *ngFor="let item of serviceOffers " >
          <td>{{item.id}}</td>
          <td>{{item?.departmentName}}</td>
          <td>{{item.name}}</td>
                      <td><img style="height: 50px" src="{{item.url}}"></td>

                      <td>
                        <a
                          class="btn btn-primary"
                          (click)="deleteItem(item)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="View Order Detail"
                          >DELETE<i class="fa fa-fw fa-eye"></i
                        ></a>
                        <a
                          class="btn btn-primary"
                          (click)="seletedItem(item)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Clone Order"
                          ><i class="fa fa-fw fa-files-o"></i
                        ></a>
                      </td>
                    </tr>
                  </table>
                  <app-companyservice-photo *ngIf = "selected" [serviceOffer] = "serviceOffer" [companyDepartment] = "companyDepartment" [website] = "website"></app-companyservice-photo>
                </div>
                <!-- /.table-sec -->
                <!--FORM-->
                <div class="col-md-4">
                  <!-- <app-serviceofferadd-modal ></app-serviceofferadd-modal> -->
                  <!-- [hidden] = "!selected" -->
                  <form *ngIf="selected"   #editForm="ngForm" id="editForm" class="vehicle-form" (ngSubmit)="onUpdate(editForm)">
                    <!-- <div class="form-group   form-inline">
                      <label>Department: &nbsp;</label>
                      <input type="text" class="form-control cstmnumeric-input" 
                      [ngModel]="serviceOffer?.departmentName" 
                      (ngModel)="serviceOffer.departmentName=$event"
                      (ngModelChange)="serviceOffer.departmentName=$event"
                        name="departmentName" #departmentName="ngModel">
                    </div> -->
                    <div class="form-row">
                      <label>Type</label>
                      <select class="cstm-select" #companyDepartmentTypeId = "ngModel"  name =  "companyDepartmentTypeId"  
                      [ngModel] = "serviceOffer.companyDepartmentTypeId"
                      (ngModel) = "serviceOffer?.companyDepartmentTypeId"    
                      (ngModelChange)="serviceOfferCompanyType($event)" >
                      <option style="color: #bb400f;" [value]="-1">None</option>
                      <option *ngFor="let item of companyDepartmentTypes"     [ngValue]="item.id">{{item?.value}}</option>
                      </select>
                    </div>
                    <div class="form-group   form-inline">
                      <label>Name: &nbsp;</label>
                      <input type="text" class="form-control cstmnumeric-input" 
                      [ngModel]="serviceOffer?.name" 
                      (ngModel)="serviceOffer.name=$event"
                      (ngModelChange)="serviceOffer.name=$event"
                        name="name" #name="ngModel">
                    </div>
                    <div class="form-group   form-inline">
                        <label>Price: &nbsp;</label>
                        <input type="text" class="form-control cstmnumeric-input" 
                        [ngModel]="serviceOffer?.price" 
                        (ngModel)="serviceOffer.price=$event"
                        (ngModelChange)="serviceOffer.price=$event"
                          name="price" #price="ngModel"> 
                      </div>
                      <div class="form-group   form-inline">
                        <label>Boolet One: &nbsp;</label>
                        <input type="text" class="form-control cstboolet-input" 
                        [ngModel]="serviceOffer?.booletOne" 
                        (ngModel)="serviceOffer.booletOne=$event"
                        (ngModelChange)="serviceOffer.booletOne=$event"
                          name="booletOne" #booletOne="ngModel">
                      </div>
                  
                      <div class="form-group   form-inline">
                        <label>Boolet Two: &nbsp;</label>
                        <input type="text" class="form-control cstboolet-input" 
                        [ngModel]="serviceOffer?.booletTwo" (ngModel)="serviceOffer.booletTwo=$event"
                        (ngModelChange)="serviceOffer.booletTwo=$event"
                          name="booletTwo" #booletTwo="ngModel">
                      </div>
                  
                      <div class="form-group   form-inline">
                        <label>Boolet Three: &nbsp;</label>
                        <input type="text" class="form-control cstboolet-input" 
                        [ngModel]="serviceOffer?.booletThree" (ngModel)="serviceOffer.booletThree=$event"
                        (ngModelChange)="serviceOffer.booletThree=$event"
                          name="booletThree" #booletThree="ngModel">
                      </div>
                  
                      <div class="form-group   form-inline">
                        <label>Boolet Four: &nbsp;</label>
                        <input type="text" class="form-control cstboolet-input" 
                        [ngModel]="serviceOffer?.booletFour" (ngModel)="serviceOffer.booletFour=$event"
                        (ngModelChange)="serviceOffer.booletFour=$event"
                          name="booletFour" #booletFour="ngModel">
                      </div>
                      <div class="form-group   form-inline">
                        <label>Small Description: &nbsp;</label>
                        <textarea type="text" rows="10" class="form-control csttextarea-input" 
                        [ngModel]="serviceOffer?.smallDescription" (ngModel)="serviceOffer.smallDescription=$event"
                        (ngModelChange)="serviceOffer.smallDescription=$event"
                          name="smallDescription" #smallDescription="ngModel"></textarea>
                      </div>
                     
                  </form>
      </div>
                <!--RIGHT SIDE BUTTONS-->
                <div class="customer-sidebar sidebar-with-btns col-md-1">
                  <div class="inner-sidebar">
                    <ul>
                      <li *ngIf="selected">
                        <button (click)="addServiceOfferListItem(editForm)"  class="cstm-btn img-blk-btn">
                          <img src="assets/images/ap-icon-1.png">
                          <span>Add Sub Products</span>
                        </button>
                      </li>
                      <li  >
                        <button  (click)="onUpdate(editForm)"  class="cstm-btn img-blk-btn">
                          <img src="assets/images/ap-icon-1.png">
                          <span>Save</span>
                        </button>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div>
    </div>

   
    </div>
</div>



