import { Component, OnInit } from '@angular/core';
import { VehicleBodyClassTypes } from 'src/app/_models/VehicleBodyClassTypes';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { VehicleBodytypeCategoriesService } from 'src/app/_services/vehicleserv/vehicle-bodytype-categories.service';
import { VehicleBodyTypeModalComponent } from './vehicle-body-type-modal/vehicle-body-type-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-vehicle-body-type-list',
  templateUrl: './vehicle-body-type-list.component.html',
  styleUrls: ['./vehicle-body-type-list.component.css']
})
export class VehicleBodyTypeListComponent implements OnInit {
  entity: VehicleBodyClassTypes[];
  selectedCompany1: UserAssignedToCompany;
  isDelete: boolean;
  bsModalRef: any;

  constructor(private route: ActivatedRoute,
    private modalService: BsModalService, private vehicleBodyTypesService: VehicleBodytypeCategoriesService,
    private authService: AuthService,
    private alertify: AlertifyService) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      this.entity = data.vehicleBodyTypes;
    });
  }

  delete(e) {
    console.log(e);
    this.isDelete = false;
    if (e.deletedDate == null) {
      this.isDelete = true;
    }
    this.vehicleBodyTypesService.delete(this.selectedCompany1.parentGuid, e.id, this.isDelete).subscribe(data => {
      console.log(data);
      this.entity = data;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Body Type Updated');
    });
}

edit(e: VehicleBodyClassTypes) {
  console.log(e);
  const initialState = {
    vehicleBodyTypes:  e
  };
  this.bsModalRef = this.modalService.show(VehicleBodyTypeModalComponent, { initialState });
  this.bsModalRef.content.update.subscribe((values) => {
    e.nameFriendly = values.nameFriendly;
    this.vehicleBodyTypesService.edit(this.selectedCompany1.parentGuid, e).subscribe(data => {
      this.entity = data;
    }, error => {this.alertify.error(error); }, () => {this.alertify.success('Body Type Updated'); });
  });
}

add() {
  this.bsModalRef = this.modalService.show(VehicleBodyTypeModalComponent);
  this.bsModalRef.content.update.subscribe((values) => {
    this.vehicleBodyTypesService.add(this.selectedCompany1.parentGuid, values.value).subscribe(data => {
      this.entity = data;
    }, error => {this.alertify.error(error); }, () => {this.alertify.success('Body Type Added'); });
  });
}
}

