
<!-- Main Start -->
<div class="main-section">

  <div class="page-section mb35">
    <div class="container">

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
           <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
         <div class="row"> 
       <div class="cs-contact-form">              
                     <form #registerForm="ngForm" (submit)="register()">
                     <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">  
                       <div class="row"> 
                       
                   <!-- <fieldset ngModelGroup="person" class="col-lg-12 col-md-12 col-sm-12 col-xs-12"> -->
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                         <div class="cs-form-holder form-group">
                           <div class="input-holder">
                             <input id="firstName" required name="firstName" (ngModel)="model?.firstName" type="text"  class="form-control" placeholder="First Name"><i class=" icon-user"></i>
                            
                           </div>
                           
                         </div>
                       </div>
   
                       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                         <div class="cs-form-holder form-group">
                           <div class="input-holder"><input required name="lastName" (ngModel)="model?.lastName" type="text"  class="form-control" placeholder="Last Name"><i class=" icon-user"></i></div>
                         </div>
                       </div> 
             
                       <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                         <div class="cs-form-holder form-group">
                           <div class="input-holder"><input required name="zip" (ngModel)="model?.zip" type="text"  class="form-control" placeholder="Zip Code"><i class="icon-build"></i></div>
                         </div>
                       </div> 
                    
                       <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                         <div class="cs-form-holder form-group">
                           <div class="input-holder"><input required name="phone" (ngModel)="model?.phone" type="text"  class="form-control" placeholder="Phone"><i class="icon-build"></i></div>
                         </div>
                       </div> 
                     
                       <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                         <div class="cs-form-holder form-group">
                           <div class="input-holder"><input required name="email" (ngModel)="model?.email" type="text"  class="form-control" placeholder="Email"><i class=" icon-envelope"></i></div>
                         </div>
                       </div>
   
                       <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="cs-form-holder">
                            <div class="input-holder">
                            <textarea name="message" (ngModel)="model?.message" type="text"  class="form-control" placeholder="Please contact me at you earliest convenience."></textarea><i></i></div>
                          </div>
                        </div> 
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                           <div class="cs-form-holder">
                        <!-- <re-captcha  required name="declarativeFormCaptchaValue"  (resolved)="resolved($event)" (siteKey)="getSiteKey()"></re-captcha> -->
                        <div #captchaDiv  onVerify={this.onVerify} class="m-t"></div>
                      </div></div>
                    
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          
                      <br><br>
                         <div *ngIf="isValid" class="cs-btn-submit cs-color">	
                         <input [disabled]="!registerForm.valid " type="submit" value="Contact Dealer">
                         </div>
                       </div> 
                     <!-- </fieldset> -->
   
                     </div>
                     </div> 
                     </form>
                     </div>		 
         </div>		 
   
           </div> 
      </div>   
  </div>
  </div>
</div>  
<!-- <app-ServiceSpecials-Horizontal></app-ServiceSpecials-Horizontal> -->
</div>
<!-- <pre>{{ registerForm.value | json }}</pre> -->
<!-- Main End --> 
