import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VehicleGridToReturnDto } from '../_models/DTOS/vehiclegridtoreturndto';
import { ItemService } from '../_services/itemservices/item.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'  // **** ADD THIS LINE ****
  })
export class ProductListResolver implements Resolve<VehicleGridToReturnDto[]> {
    pageNumber = 1;
    pageSize = 50;
    constructor(private chartItemService: ItemService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<VehicleGridToReturnDto[]> {
     //   console.log(route.params['parentGuid']);
     const userParams = {} as any;
     userParams.isDeleted = 'isAll';
        return this.chartItemService.getChartItems(route.params['parentGuid'], this.pageNumber, this.pageSize, userParams).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving chart item data');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}