import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { UserSubscriptionPlan } from 'src/app/_models/UserSubscriptionPlan';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companydataservice/companies.service';
import { UserCartService } from 'src/app/_services/userpaymentservices/User-Cart.service';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
  company = {} as CompanyProfile;
  userSubscriptionPlan = {} as UserSubscriptionPlan;
  isCompanyInfo = true;
  isSubscriptions = false;
  isImportedVehicles = false;
  isSubscriptionsHistory = false;
  isPayment = false;
  constructor(private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private userCartService: UserCartService,
    private alertify: ToastrService,
    private companyService: CompaniesService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.company = data.companyProfile;
    });
    this.userCartService.currentuserSubscriptionPlan.subscribe(data => {
      this.userSubscriptionPlan = data;
    });
    console.log(this.company);
  }
  tabClicked(e) {
    this.isCompanyInfo = false;
    this.isSubscriptions = false;
    this.isSubscriptionsHistory = false;
    this.isPayment = false;
    this.isImportedVehicles = false;
    if (e === 'isImportedVehicles') {  this.isImportedVehicles  = true; }
    if (e === 'isCompanyInfo') {  this.isCompanyInfo  = true; }
    if (e === 'isSubscriptions') {  this.isSubscriptions  = true; }
    if (e === 'isPayment') {  this.isPayment  = true; }
    if (e === 'isSubscriptionsHistory') {  this.isSubscriptionsHistory  = true; }
  }
}
