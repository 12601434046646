import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';
import { VehicleMileageCodes } from '../../_models/VehicleMileageCodes';
import { UserAssignedToCompany } from '../../_models/userAssignedToCompany';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleMileagecodesService {
  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.currentCompany.subscribe(data => { this.selectedCompany = data; });
   }
  baseUrl = environment.apiUrl;
  selectedCompany: UserAssignedToCompany;

  getList(): Observable<VehicleMileageCodes[]> {
    return this.http.get<VehicleMileageCodes[]>(this.baseUrl + 'vehiclemileagecodes/getmileagecodes/' + this.selectedCompany.parentGuid);
  }

  getActiveList(): Observable<VehicleMileageCodes[]> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<VehicleMileageCodes[]>(this.baseUrl + 'vehiclemileagecodes/getactivemileagecodes/' + this.selectedCompany.parentGuid);
  }

  getStatus(id: number): Observable<VehicleMileageCodes> {
    return this.http.get<VehicleMileageCodes>(this.baseUrl + 'vehiclemileagecodes/getmileagecode/' + id);
  }

  delete( id: number, isdelete: boolean): Observable<VehicleMileageCodes[]> {
    return this.http.get<VehicleMileageCodes[]>(this.baseUrl + 'vehiclemileagecodes/deletemileagecode/'
    + this.selectedCompany.parentGuid + '/' + id + '/' + isdelete);
  }

  edit(parentguid: string, chartInvoiceStatus: VehicleMileageCodes): Observable<VehicleMileageCodes[]> {
    return this.http.put<VehicleMileageCodes[]>(this.baseUrl + 'vehiclemileagecodes/editmileagecode/', chartInvoiceStatus);
  }

  add( value: string): Observable<VehicleMileageCodes[]> {
    return this.http.get<VehicleMileageCodes[]>(this.baseUrl + 'vehiclemileagecodes/addmileagecode/'
    + this.selectedCompany.parentGuid + '/' + value);
  }

}
