import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { VehicleBuyersGuide } from 'src/app/_models/buyersguidemodels/VehicleBuyersGuide';
import { BuyersGuideAndSystemsListReturned } from 'src/app/_models/DTOS/buyersguiddtos/BuyersGuideAndSystemsListReturned';
import { VehicleBuyersGuideNoIdDto } from 'src/app/_models/DTOS/buyersguiddtos/VehicleBuyersGuidDtoNoId';
import { VehicleBuyersGuideSystemsCoveredNoIdDto } from 'src/app/_models/DTOS/buyersguiddtos/VehicleBuyersGuideSystemsCoveredDtoNoId';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { BuyersGuidService } from '../buyersguid-service';
import { BuyersguideaddComponent } from '../buyersguideadd/buyersguideadd.component';
import { SystemscoveredaddComponent } from '../systemscoveredadd/systemscoveredadd.component';

@Component({
  selector: 'app-rightmenubuyersguide',
  templateUrl: './rightmenubuyersguide.component.html',
  styleUrls: ['./rightmenubuyersguide.component.css'],
})
export class RightmenubuyersguideComponent implements OnInit {
  bsModalRef1: any;
  vehicleBuyersGuides = [] as VehicleBuyersGuide[];
  buyersGuideAndSystemsListReturned = {} as BuyersGuideAndSystemsListReturned;
  vehicleBuyersGuide = {} as VehicleBuyersGuide;
  selectedCompany: UserAssignedToCompany;
  constructor(private authService: AuthService,
    private modalService: BsModalService,
    private repoBuyerGuid: BuyersGuidService,
    private alertify: ToastrService
  ) {}

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany = data; });
  }
  addNewBuyersGuid() {
    const initialState = {};
    this.bsModalRef1 = this.modalService.show(BuyersguideaddComponent, {
      initialState,
    });
    this.bsModalRef1.content.emittedValues.subscribe(
      (values: VehicleBuyersGuideNoIdDto) => {
        values.parentId = this.selectedCompany.parentGuid;
        this.repoBuyerGuid.addBuyersGuide(values).subscribe(
          (data) => {
            this.buyersGuideAndSystemsListReturned = data;
          },
          (err) => {
            console.log(err);
            this.alertify.success('Error adding Systems');
          },
          () => {
            this.alertify.success('Added Buyers Guide');
          }
        );
      }
    );
  }

  addSystems() {
    const initialState = {};
    this.bsModalRef1 = this.modalService.show(SystemscoveredaddComponent, {
      initialState,
    });
    this.bsModalRef1.content.emittedValues.subscribe(
      (values: VehicleBuyersGuideSystemsCoveredNoIdDto) => {
        console.log(values);
        values.parentGuid = this.selectedCompany.parentGuid;
        this.repoBuyerGuid.addBuyersGuideSystems(values).subscribe(
          (data) => {
            this.buyersGuideAndSystemsListReturned = data;
          },
          (err) => {
            console.log(err);
            this.alertify.success('Error adding Systems');
          },
          () => {
            this.alertify.success('Added Buyers Guide Systems');
          }
        );
      }
    );
  }
}
