import { Component, OnInit, HostListener, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Person } from 'src/app/_models/person';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileUploader } from 'ng2-file-upload';
import { PersonPhoto } from 'src/app/_models/personphoto';
import { environment } from 'src/environments/environment';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';

@Component({
  selector: 'app-addnew-personmodal',
  templateUrl: './addnew-personmodal.component.html',
  styleUrls: ['./addnew-personmodal.component.css']
})
export class AddnewPersonmodalComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  person = {} as Person ;
  @Output() update = new EventEmitter();
  user: any;
  selectedCompany: UserAssignedToCompany;
  initialState: any;
  personType: any;
  todayDate = new Date();
  uploader: FileUploader;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.editForm.dirty) {
      $event.returnValue = true;
    }
  }

  constructor(public bsModalRef1: BsModalRef) {}

  ngOnInit() {
    this.person = new Person();
    if (this.person.dateOfBirth == null) { this.person.dateOfBirth = this.todayDate; }
    if (this.person.insuranceExpiration == null) { this.person.insuranceExpires = this.todayDate; }
  }

  onSubmit() {
    this.update.emit(this.editForm.value);
    console.log(this.editForm.value);
    this.bsModalRef1.hide();
  }

}

