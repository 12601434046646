import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Label } from 'src/app/_models/label';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-label-double-click-modal',
  templateUrl: './label-double-click-modal.component.html',
  styleUrls: ['./label-double-click-modal.component.css']
})
export class LabelDoubleClickModalComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Output() formLabel = new EventEmitter();
  labelName: Label;
  formValues: any;
  isAdd: boolean;

  constructor(public bsModalRef1: BsModalRef) { }

  ngOnInit() {
    // this.formValues = this.editForm.value;
    // this.labelName = this.formValues.value;
    // this.formLabel.emit(this.editForm.value);
    // this.bsModalRef1.hide();
  }
  update() {
    this.formValues = this.editForm.value;
    this.labelName = this.formValues.value;
    this.formLabel.emit(this.labelName);
    this.bsModalRef1.hide();
  }
}
