<!--component html goes here -->
<br>
<div class="customer-form-sec">
    <div class="container-fluid">
      <br><br>
      <button *ngIf="!isNotClicked" class="btn-success" (click)="postVehicles()">Post</button>
      <button *ngIf="!isNotClicked" class="btn-success" (click)="deleteItems()">DELETE</button>
      <button *ngIf="!isNotClicked" class="btn-success" (click)="deleteItemsRemainingVehicles()">DeleteRemainingVehicles</button>
      <div class="row">
        <div class="form-sec tab-content col-md-12">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-row account-payable-row">
                  <div class="row">
                    <div class="col-md-12 table-sec">
                      <div id="printableTable">
                        <br><br>
                        <div>
                          <table class="pricing-table">
                            <!-- header -->
                            <tr>
                                <th>Id</th>
                                <th>Stock</th>
                                <th>Year</th>
                                <th>Make</th>
                                <th>Sold Date</th>
                                <th>Purchased Date</th>
                                <th>Sold To</th>
                                <th>Is Published</th>
                            </tr>
                            <tr class="td-row" *ngFor="let item of clmVehicles; let i=index">
                              <td>{{ item?.id}}</td>
                              <td>{{ item?.stock}}</td>
                              <td>{{ item?.yr}}</td>
                              <td>{{ item?.make}}</td>
                              <td>{{item?.soldDate | date: 'MM/dd/yyyy'}}</td>
                              <td>{{item?.purchaseDate | date: 'MM/dd/yyyy'}}</td>
                              <td>{{ item?.purchasedName}}</td>
                              <td>{{ item?.isPublished}}</td>
                              <td><button ng-if="!item?.isPublished" class="btn-success">Puplish</button></td>
                            
                            </tr>
                          </table>
                        </div>
                        
                      </div>
                    </div>
                    <!-- /.table-sec -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.row -->
          </div>
        </div>
      </div>
    </div>
  </div>