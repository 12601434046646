import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';
import { Person } from 'src/app/_models/person';
import { Sale } from 'src/app/_models/sale';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';

@Component({
  selector: 'app-sale-cobuyer',
  templateUrl: './sale-cobuyer.component.html',
  styleUrls: ['./sale-cobuyer.component.css']
})
export class SaleCobuyerComponent implements OnInit {
  @Input() sale: Sale;
  @ViewChild('dealInfoForm') dealInfoForm: NgForm;
  selectedCompany1: UserAssignedToCompany;
  constructor(private saleSerivce: SaleService,
    private authService: AuthService,
    private router: Router,
    private format: DealInforFormat,
    private alertify: ToastrService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
  }
  onChange(e) {
    if (this.sale.coBuyerId === null) {
      console.log('add a cobuyer here');
    }
  }
  // async update() {
  //   console.log(this.sale);
  //   // if(this.sale.docAuto === "false") {this.sale.docAuto = false; } else { this.sale.docAuto = true; }
  //   // this.sale.docAuto = 0;
  //   this.sale.passPmts = 0;
  //   if (this.sale !== null) {
  //  //   this.dealInfoForm.reset(this.sale);
  //   this.sale = await this.format.formatDealInforNumbers(this.sale);
  //   this.saleSerivce.updateSale(this.selectedCompany1.parentGuid, this.sale).subscribe(data => {
  //     console.log(data);
  //   }, error => { this.alertify.error(error); });
  //   }

  // }
  async update() {
    console.log(this.sale);
    // if(this.sale.docAuto === "false") {this.sale.docAuto = false; } else { this.sale.docAuto = true; }
    // this.sale.docAuto = 0;
    this.sale.passPmts = 0;
    if (this.sale !== null) {
      this.sale = await this.format.formatDealInforNumbers(this.sale);
      this.saleSerivce
        .updateSale(this.selectedCompany1.parentGuid, this.sale)
        .subscribe(
          (data) => {
            this.alertify.success('Sale Updated');
            this.alertify.success('Accounting will be updated in background.');
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {}
        );
    }
  }
  tabelViewClick(){
    this.router.navigate(['saledashboard/saledashboard/' + this.selectedCompany1.parentGuid]);
  }
}
