<table border="1" borderColor="#ccc">
  <tr>
    <th class="date-th">Date</th>
    <th class="des-th">Description</th>
    <th class="charges-th">Charge</th>
    <th *appHasRole="['Admin']"  class="charges-th">Cost</th>
    <th *appHasRole="['Admin']"  class="charges-th">Profit</th>
  </tr>
  <tr (dblclick) = "editMischCharge(item)" class="td-rw" *ngFor = "let item of dollars; let i=index">
    <td *ngIf="i != dollars.length - 1" class="date-td">{{item.dateCreated | date: 'yyyy-MM-dd'}}</td>
    <td *ngIf="i != dollars.length - 1">{{item.description}}</td>
    <td *ngIf="i != dollars.length - 1" class="charge-td">{{item.customerCost | currency}}</td>
    <td *ngIf="i != dollars.length - 1"  class="charge-td">{{item.credit | currency}}</td>
    <td *ngIf="i != dollars.length - 1" class="charge-td">{{item.profit | currency}}</td>
  </tr>
  <tr align="right" class="border_top" *ngFor="let item of dollars; let i=index; last as isLast">
    <td *ngIf="isLast && i !==0" class="charge-td">SUB Totals</td>
    <td *ngIf="isLast && i !==0" class="charge-td"></td>
    <td *ngIf="isLast && i !==0" class="charge-td">{{item.customerCost | currency}}</td>
    <td *ngIf="isLast && i !==0" class="charge-td">{{item.credit | currency}}</td>
    <td *ngIf="isLast && i !==0"  class="charge-td">{{item.profit | currency}}</td>
    </tr>
</table>

<!-- <div class="customer-sidebar col-md-2">
  <div class="inner-sidebar">
    <ul>
  
      <li>
        <a href="javascript:void(0)" (click)="update()" >
          <img src="assets/images/sidebar-icon-2.jpg">
          <span>Save</span>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" (click)="tabelViewClick()">
          <img src="assets/images/sidebar-icon-9.jpg">
          <span>Table View</span>
        </a>
        </li>
    </ul>
  </div>
</div> -->



