<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div id="vehicle" class="tab-pane fade in active show">
          <div style="width: 550px" class="modal1" id="myModal">
            <div class="modal-dialog1 modal1" style="width: 550px">
              <div class="modal1-content modal1" style="width: 450px">
                <div class="modal-header modal1">
                  <h4 class="modal-title pull-left">Edit</h4>
                  <button
                    type="button"
                    class="close pull-right"
                    aria-label="Close"
                    (click)="bsModalRef.hide()"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body modal1">
                  <div 
                    class="form-sec tab-content col-md-12"
                    style="width: 550px !important"
                  >
                  <div *ngIf="isSoldVehicle">You may not alter vehicle cost structure on a sale. Please open the vehicle to edit vehicle cost.</div>
                    <form *ngIf="!isSoldVehicle"
                      id="editForm"
                      #editForm="ngForm"
                      (ngSubmit)="update()"
                    >
                      <div class="row vehicle-form">
                        <div class="col-md-12 cstm-col">
                          <div *ngIf="editForm.dirty" class="alert alert-info">
                            <strong>Information:</strong> You have made changes.
                            Any unsaved changes will be lost!
                          </div>
                        </div>
                        <div class="col-md-12 cstm-col">
                          <label>Mark Paid</label>
                          <input
                            type="checkbox"
                            checked
                            (ngModelChange)="dollar.isPaid = $event"
                            [checked]="dollar?.isPaid"
                            [ngModel]="dollar?.isPaid"
                            name="isPaid"
                            #isPaid="ngModel"
                          />
                        </div>
                        <!--ADD DOLLARTYPE HERE-->

                        <div class="col-md-12 cstm-col">
                          <label>Dollar Type</label>
                          <select
                            class="select-input"
                            required
                            [disabled] = "isLocked"
                            #dollarType="ngModel"
                            ngControl="selectedDollarType"
                            name="dollarTypes"
                            [(ngModel)]="selectedDollarType"
                            (ngModelChange)="dollarTypeChanged($event)"
                          >
                            <option value="">
                              --Please choose an option--
                            </option>
                            <option
                              *ngFor="let item of dollarTypes; index as i"
                              [value]="item?.id"
                            >
                              {{ item?.value }}
                            </option>
                          </select>
                        </div>
                        <!--END dollar type-->
                        <!--Date #-->
                        <div class="col-md-12 cstm-col">
                          <div
                            class="inline-blk"
                            [ngClass]="{
                              'has-success':
                                dateOfTransaction?.valid &&
                                (dateOfTransaction?.touched ||
                                  dateOfTransaction?.dirty),
                              'has-danger':
                                dateOfTransaction?.invalid &&
                                (dateOfTransaction?.touched ||
                                  dateOfTransaction?.dirty)
                            }"
                          >
                            <label>Date</label>
                            <input
                              class="cstm-input"
                              value=""
                              type="date"
                              autocomplete="off"
                              [ngModel]="
                                dollar?.dateOfTransaction | date: 'yyyy-MM-dd'
                              "
                              (ngModelChange)="
                                dollar.dateOfTransaction = $event
                              "
                              (blur)="validateDate()"
                              name="dateOfTransaction"
                              #dateOfTransaction="ngModel"
                              required
                            />
                          </div>
                          <div
                            *ngIf="
                              dateOfTransaction.errors &&
                              (dateOfTransaction?.touched ||
                                dateOfTransaction?.dirty)
                            "
                          >
                            <p *ngIf="dateOfTransaction?.errors?.required">
                              Date Is Required
                            </p>
                          </div>
                        </div>
                        <!--END Date #-->

                        <!--AMOUNT #-->
                        <div class="col-md-12 cstm-col">
                          <div
                            class="inline-blk"
                            [ngClass]="{
                              'has-success':
                                customerCost?.valid &&
                                (customerCost?.touched || customerCost?.dirty),
                              'has-danger':
                                customerCost?.invalid &&
                                (customerCost?.touched || customerCost?.dirty)
                            }"
                          >
                            <label>Amount</label>
                            <input
                              class="cstm-input"
                              value=""
                              type="number"
                              autocomplete="off"
                              [ngModel]="dollar?.customerCost"
                              (ngModelChange)="dollar.customerCost = $event"
                              (blur)="validateDate()"
                              name="customerCost"
                              #customerCost="ngModel"
                              required
                            />
                          </div>

                          <div
                            *ngIf="
                              customerCost.errors &&
                              (customerCost?.touched || customerCost?.dirty)
                            "
                          >
                            <p *ngIf="customerCost?.errors?.required">
                              Amount Is Required
                            </p>
                          </div>
                        </div>
                        <!--END AMOUNT #-->
                        <!--COST #-->
                        <div class="col-md-12 cstm-col">
                          <div
                            class="inline-blk"
                            [ngClass]="{
                              'has-success':
                                credit?.valid &&
                                (credit?.touched || credit?.dirty),
                              'has-danger':
                                credit?.invalid &&
                                (credit?.touched || credit?.dirty)
                            }"
                          >
                            <label>Cost</label>
                            <input
                              class="cstm-input"
                              value=""
                              type="number"
                              autocomplete="off"
                              [ngModel]="dollar?.credit"
                              (ngModelChange)="dollar.credit = $event"
                              (blur)="validateDate()"
                              name="credit"
                              #credit="ngModel"
                              required
                            />
                          </div>

                          <div
                            *ngIf="
                              credit.errors &&
                              (credit?.touched || credit?.dirty)
                            "
                          >
                            <p *ngIf="credit?.errors?.required">
                              Cost Is Required
                            </p>
                          </div>
                        </div>
                        <!-- Description-->
                        <div class="col-md-12 cstm-col">
                          <label>Description</label>
                          <input
                            class="cstm-input"
                            value=""
                            type="text"
                            autocomplete="off"
                            [ngModel]="dollar?.description"
                            (ngModelChange)="dollar.description = $event"
                            name="description"
                            #description="ngModel"
                          />
                        </div>

                        <!--button save-->
                        <div class="col-md-12 cstm-col">
                          <button
                            [disabled]="!editForm.valid"
                            type="submit"
                            class="btn btn-primary"
                          >
                            SAVE
                          </button>
                          <p *ngIf="!editForm.valid" style="color: red">
                            Save Button Will be enabled when all fields entered!
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
