

<div style="margin-top: 10px;" class="container-fluid">
<div class="d-flex justify-content-right">
  <div class="col-md-12" *ngIf="isExpandedSearch">
    <div class="row" >
     
      <div class="col-md-3">
        <input (keyup.enter)="onSearch()" class="form-control mr-1" ng-model="searchValue" #search  type="text" placeholder="search">
      </div>
      <div class="col-md-3">
        <select   [(ngModel)]="selectedOption"  class="form-control mr-1"  (change)="changeValue($event.target.value)">
          <option *ngFor="let item of selectedOptionList" [value]="item.value">{{item.name}}</option>
        </select>
      </div>
      <div class="col-md-2">
        <button (click)="onSearch()"  class="btn btn-outline-primary form-control" >Search</button>
      </div>
      <div class="col-md-4"> 
      </div>
    </div>
    
  </div>
  <!-- <app-deletedactive-option (isDeletedClick) = "isChildActiveDeletedClick($event)"></app-deletedactive-option> -->
</div>
</div>

<div style="margin-top: 0px; height: 100%;" class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <div id="printableTable">
                      <!-- customer-tabs-sec -->
                      <div class="  col-md-12" >
                        <div class="row">
                          <div class="col-md-5">
                            <app-deletedactive-option (isDeletedClick) = "isChildActiveDeletedClick($event)"></app-deletedactive-option>
                          </div>
                          <div class="col-md-6">
                            <div >
                              <ul class="nav nav-tabs">
                                <td *ngIf="isNewFormTypeClick && selectedSaleFromGrid">
                                  <a
                                  class="btn btn-success "
                                  (click)="printSaleSelected(selectedItem)"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Is Active"
                                  ><i  class="fa fa-fw fa-print"></i
                                ></a>
                              </td>
                              <td *ngIf="isNewFormTypeClick && selectedSaleFromGrid">
                                <a class="btn btn-success"
                                (click)="downloadItem(item)"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Is Active"
                                >Download</a>
                            </td>
                            <td  *ngIf="isNewFormTypeClick && selectedSaleFromGrid" >
                              <a class="btn btn-success"
                              (click)="previewItem(item)"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Is Active"
                              >Preview</a>
                          </td>
                          <td  *ngIf="isNewFormTypeClick && selectedSaleFromGrid" >
                            <!-- <a class="btn btn-success"
                            (click)="previewItem(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Is Active"
                            >Packages</a> -->
                            <select class="btn btn-info dropdown-toggle">
                              <option  class="dropdown-menu"> Select Form Package</option>
                              <option *ngFor="let item of packagesSales" [value] = "item.id"  class="dropdown-menu"> 
                                {{item.name}}</option>
                            </select>
                            <!-- <div class="dropdown">
                              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false">
                                Select Form Package
                              </button>
                              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item active" href="#">Action</a>
                              </div>
                            </div> -->
                        </td>
                                <!-- <li><a (click) ="tabClick(isWashout)" href="javascript:void(0)">Payables</a></li>
                                <li><a (click) ="tabClick(isWashout)" href="javascript:void(0)">Recievable</a></li> -->
                                <!-- <li><a  [ngClass] = "isAmortization ? 'active show' : ''" (click) ="tabClick('isAmortization')" href="javascript:void(0)">Amortization</a></li> -->
                              </ul>
                              </div>
                          </div>
                        </div>
                      
                      
                      
                      </div>
                    <table   borderColor="#ccc">
                     
                      <tr>
           
                        <th *ngIf="isNewFormTypeClick">Select</th>
						            <!-- <th *ngIf="isNewFormTypeClick && selectedSaleFromGrid">Print &nbsp;</th>
                        <th *ngIf="isNewFormTypeClick && selectedSaleFromGrid">Preview &nbsp;</th>
                        <th *ngIf="isNewFormTypeClick && selectedSaleFromGrid">Download &nbsp;</th> -->
                        <th *ngIf="!isNewFormTypeClick && !selectedSaleFromGrid">Id &nbsp;</th>
                          <th (click)= "sort('invoiceNumber')">Invoice # &nbsp;
                            <i [ngClass]="isInvoiceAscending ? 'up': 'down'"></i></th>
                            <th>Account</th>
                          <th>Phone </th>
                        <th (click)= "sort('customer')">Customer &nbsp;
                          <i [ngClass]="isCustomerAscending ? 'up': 'down'"></i></th>
                        
                        <th (click)= "sort('saleType')">Sale Type&nbsp;
                          <i [ngClass]="isSaleTypeAscending ? 'up': 'down'"></i></th>
                        <th (click)= "sort('saleStatus')">Sale Status &nbsp;
                          <i [ngClass]="isSaleSTatusAscending ? 'up': 'down'"></i></th>
                        <th (click)= "sort('vehicle')">Vehicle&nbsp; <i [ngClass]="isVehicleAscending ? 'up': 'down'"></i></th>
                       
                      </tr>

                      <tr [hidden]="isPrint" id="hideRow" class="hidden-print" >
                        <td *ngIf=" isNewFormTypeClick"></td>
                        <!-- <td *ngIf="isNewFormTypeClick && selectedSaleFromGrid"></td>
                        <td *ngIf="isNewFormTypeClick && selectedSaleFromGrid"></td>
                        <td *ngIf="isNewFormTypeClick && selectedSaleFromGrid"></td> -->
                        <td><input style="width: 55px;" (keyup)="setSearchBy('searchId', $event.target.value)"></td>  
                        <td><input style="width: 55px;" (keyup)="setSearchBy('acct', $event.target.value)"></td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('phone', $event.target.value)"></td>  
                        
                        <td><input style="width: 96px;" (keyup)="setSearchBy('customer', $event.target.value)"></td>  
                        <td><select  
                          [(ngModel)] ="selectType" (change) ="selectedTypeChanged($event.target.value)"
                          name="vehicleBodyClassTypes"
                          onmousedown = "value = '';" 
                          #vehicleBodyClassTypes="ngModel">
                          <option ngValue= "null">--All--</option>
                          <option *ngFor="let summary of chartInvoiceSaleTypes" value="{{summary.id}}">
                            {{summary.value}}
                          </option>
                      </select></td>  
                       
                        <td><select  
                          [(ngModel)] ="selectStatus" (change) ="selectedStatusChanged($event.target.value)"
                          name="chartInvoiceStatusess"
                          onmousedown = "value = '';" 
                          #chartInvoiceStatusess="ngModel">
                          <option ngValue= "null">--All--</option>
                          <option *ngFor="let summary of chartInvoiceStatuses" value="{{summary.id}}">
                            {{summary.value}}
                          </option>
                      </select>
                    </td>  
                    
                      </tr>
                   
                      <tbody> <!-- *ngIf=" isLoanAmount"  GRID DATA *ngIf="i != sales.length - 1" --> 
                      <tr class="td-row" *ngFor="let item of sales; let i=index">
                  
                      <tr class="td-row" *ngFor="let item of sales; let i=index">
                        <td *ngIf="i != sales.length - 1 && isNewFormTypeClick">
                          <a
                          class="btn btn-success "
                          (click)="saleSelected(item.id)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Is Active"
                          >Select</a>
                      </td>
                        <!-- <td *ngIf="i != sales.length - 1 && isNewFormTypeClick && selectedSaleFromGrid">
                          <a
                          class="btn btn-success "
                          (click)="printSaleSelected(item.id)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Is Active"
                          ><i  class="fa fa-fw fa-print"></i
                        ></a>
                      </td>
                      <td *ngIf="i != sales.length - 1 && isNewFormTypeClick && selectedSaleFromGrid">
                        <a class="btn btn-success"
                        (click)="downloadItem(item)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Is Active"
                        >Download</a>
                    </td>
                    <td *ngIf="i != sales.length - 1  && isNewFormTypeClick && selectedSaleFromGrid" >
                      <a class="btn btn-success"
                      (click)="previewItem(item)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Is Active"
                      >Preview</a>
                  </td> -->
                        <td *ngIf="i != sales.length - 1">{{item?.id}}</td>
						            <td *ngIf="i != sales.length - 1">{{item?.acct}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.buyer?.phone}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.buyer?.firstName}}&nbsp; {{item?.buyer?.lastName}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.saleTypes?.value}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.saleStatus?.value}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.vehiclePurchased?.vehicle?.year + " " + item?.vehiclePurchased?.vehicle?.make + " " + item?.vehiclePurchased?.vehicle?.model}}</td>
                        <td *ngIf="i != sales.length - 1"></td> 
                      
                        <!-- {{item?.itemAmountSums.repairs}} -->
                        <td [hidden]="isPrint" *ngIf="i != sales.length - 1 && !selectedSaleFromGrid">
                          <a
                            class="btn btn-success"
                            (click)="navigateInvoice(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View Order Detail"
                            ><i class="fa fa-fw fa-eye"></i
                          ></a>
                         
                        </td>

                      </tr>
                      <!--FOOTER OF GRID-->
                      <tr  align="right" class="border_top" *ngFor="let item of sales; let i=index; last as isLast">
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">Sub Totals:</td>
                        <!-- <td *ngIf="isLast && i !==0">{{item?.amountFinanced | number : '1.2'}}</td> -->

                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>

                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <!-- <td style="font-weight: bold;">{{getAmtTaxed3('amttaxed3') }}</td>
                        <td></td>
                        <td style="font-weight: bold;">{{getTax3()}}</td> -->
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                    </tr>
                  </tbody>
                    </table>
                     
                    <div class="col-md-12">
                      <pagination
                      [boundaryLinks]="true"
                      pageBtnClass = "nav nav-tabs"
                      [(ngModel)]="pagination.currentPage"
                      [totalItems]="pagination.totalItems"
                      (pageChanged)="pageChanged($event)"
                      [itemsPerPage]="pagination.itemsPerPage"
                      previousText="&lsaquo;"
                      nextText="&rsaquo;"
                      firstText="&laquo;"
                      lastText="&raquo;"
                    >
                    </pagination>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->
          <!-- /.row -->
        </div>
      </div>
 
    </div>
  </div>
</div>
<iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>


