
<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row"> 
      <div class="form-sec tab-content col-md-10">
        <div id="customer" class="tab-pane fade in active show">
<div class="modal-header">
    <h4 class="modal-title pull-left">{{title}}</h4>
  </div>
  <div class="modal-body">
    <p>{{message}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="confirm()">{{btnOkText}}</button>
    <button type="button" class="btn btn-danger" (click)="decline()">{{btnCancelText}}</button>
  </div>

  </div>
  </div>
  </div>
  </div>
  </div>
