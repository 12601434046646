<!--component html goes here -->
<!--component html goes here -->
<br>
<div class="customer-form-sec">
    <div class="container-fluid">
      <br><br>
      <button *ngIf="!isNotClicked" class="btn-success" (click)="post()">Post</button>
      <div class="row">
        <div class="form-sec tab-content col-md-12">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-row account-payable-row">
                  <div class="row">
                    <div class="col-md-12 table-sec">
                      <div id="printableTable">
                        <br><br>
                        <div>
                          <table class="pricing-table">
                            <!-- header -->
                            <tr>
                                <th>Id</th>
                                <th>Kod</th>
                                <th>Last Name</th>
                                <th>First Name</th>
                                <th>Is Posted</th>
                                <th>Is Published</th>
                            </tr>
                            <tr class="td-row" *ngFor="let item of clmPersons; let i=index">
                              <td>{{ item?.id}}</td>
                              <td>{{ item?.kod}}</td>
                              <td>{{ item?.lastName}}</td>
                              <td>{{ item?.firstName}}</td>
                              <td>{{ item?.isPosted}}</td>
                              <td><button *ngIf="!item?.isPublished" class="btn-success">Puplish</button></td>
                            
                            </tr>
                          </table>
                        </div>
                        
                      </div>
                    </div>
                    <!-- /.table-sec -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.row -->
          </div>
        </div>
      </div>
    </div>
  </div>