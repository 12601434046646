import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ClmCost } from 'src/app/_models/_models/clmcost';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { ClmVehicleService } from 'src/app/_services/clmvehiclesservices/clmvehicles.service';

@Component({
    moduleId: module.id,
    selector: 'app-cost-import',
    templateUrl: 'cost-import.component.html',
    styleUrls: ['cost-import.component.scss']
})
export class CostImportComponent {

    clmCost = {} as ClmCost;
    clmCosts = [] as ClmCost[];
    userAssignedToCompany = {} as UserAssignedToCompany;

    constructor(private vehicleService: ClmVehicleService,
        private authService: AuthService,
        private alertify: ToastrService) { }
    
    ngOnInit(): void {
        this.authService.currentCompany.subscribe(data => { 
            this.userAssignedToCompany = data; 
            this.getCost(); });

    }
    getCost() {
        this.vehicleService.listAllVehicleCosts(this.userAssignedToCompany.parentGuid).
        subscribe((data: ClmCost[]) => {
          console.log(data);
          this.clmCosts = data;
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Success');
        });
    }
    postVehicles() {
        this.vehicleService.listAllVehicleCosts(this.userAssignedToCompany.parentGuid).subscribe(data => {
            console.log(data);
        }, error => { this.alertify.error(error); }, () => {
            this.alertify.success('Success');
          });
    }
}
