import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { PhotosOfVehicles } from 'src/app/_models/PhotosOfVehicles';
import { VehiclePhotosService } from 'src/app/_services/vehicleserv/vehicle-photos.service';
import { Item } from 'src/app/_models/Item';
import { ToastrService } from 'ngx-toastr';
import { ConfirmService } from 'src/app/_services/confirm.service';

@Component({
  selector: 'app-addnewphotos-vehicle',
  templateUrl: './addnewphotos-vehicle.component.html',
  styleUrls: ['./addnewphotos-vehicle.component.css']
})
export class AddnewphotosVehicleComponent implements OnInit {
  @Input() photos: PhotosOfVehicles[];
  @Input() item: Item;
  @Output() getMemberPhotoChange = new EventEmitter<string>();
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  currentMain: PhotosOfVehicles;

  constructor(private authService: AuthService,
    private alertify: ToastrService, private vehiclePhotoService: VehiclePhotosService,
    private confirmService: ConfirmService) {
    }

  ngOnInit() {
    if (this.item.vehicle.photosOfVehicles !== undefined) {
      this.photos = this.item.vehicle.photosOfVehicles;
      this.initializeUploader();
    }
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  initializeUploader() {
    // url is url to post like a service
    this.uploader = new FileUploader({
      url: this.baseUrl + 'vehicle/' + this.item.vehicleId + '/photos',
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: ['image'],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024
    });

    this.uploader.onAfterAddingFile = (file) => {file.withCredentials = false; };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      if (response) {
        const res: PhotosOfVehicles = JSON.parse( response);
        const photo = {
          id: res.id,
          url: res.url,
          dateAdded: res.dateAdded,
          isAvatar: res.isAvatar,
          sortOrder: res.id,
          fileName: res.fileName,
          vehicleId: res.vehicleId,
          alt: res.alt,
          originalName: res.originalName,
          publicId: res.publicId
        };
        this.photos.push(photo);
        console.log(this.photos);
       if (photo.isAvatar) {
         this.vehiclePhotoService.changeVechicleAvatar(photo.url);
         // localStorage.setItem('user', JSON.stringify(this.authService.currentUser));
       }
      }
    };
  }

  setMainPhoto(photo: PhotosOfVehicles) {
    this.vehiclePhotoService.setMainPhoto(this.item.vehicleId, photo.id).subscribe((data) => {
       this.currentMain = this.photos.filter(p => p.isAvatar === true)[0];
       this.currentMain.isAvatar = false;
       photo.isAvatar = true;
       this.vehiclePhotoService.changeVechicleAvatar(photo.url);
    }, error => {
      this.alertify.error(error);
    });
  }

  deletePhoto(id: number) {
    this.confirmService.confirm('Confirm delete message', 'This cannot be undone').subscribe(result => {
      if (result) {
        this.vehiclePhotoService.deletePhoto(this.item.vehicleId, id).subscribe(() => {
          this.photos.splice(this.photos.findIndex(p => p.id === id), 1);
          this.alertify.success('Photo has been deleted');
        }, error => {
          this.alertify.error('Failed to delete the photo');
        });
      }
    });
  }
}

