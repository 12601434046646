import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PdfFieldNameType } from 'src/app/_models/PdfFieldNameType';
import { PdfForms } from 'src/app/_models/PdfForms';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';

@Component({
  selector: 'app-saleformeditorviewsized',
  templateUrl: './saleformeditorviewsized.component.html',
  styleUrls: ['./saleformeditorviewsized.component.scss']
})
export class SaleformeditorviewsizedComponent implements OnInit {
  pdfFieldNameTypes = [] as PdfFieldNameType[];
  pdfForm = {} as PdfForms;
  selectedCompany1 = {} as UserAssignedToCompany;
  personType = {} as any;
  person = {} as PdfFieldNameType;
  pdfFormId: any;
  constructor(private pdfService: PdfService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private alertify: AlertifyService) { }

  ngOnInit() {
    this.pdfFormId = this.activatedRoute.snapshot.params.pdfid;
    this.getPdfFieldNameTypes();
    // this.pdfService.changeIsEmpty(this.isEmptyForm);
    // this.pdfService.changeIsWithData(this.isWithData);
    this.authService.company.subscribe(data => {this.selectedCompany1 = data;
      // this.getPdfBUttonLocations(data.parentGuid); } );
   // this.isMerging = false;
 //   this.getData();
    });
    this.pdfService.emittedpdfFieldNameTypes.subscribe(data1 => {
       this.pdfFieldNameTypes = data1;
    });
  }

  getPdfFieldNameTypes() {
    console.log(this.pdfFormId );
    this.pdfService.getPdfFieldNameTypes().subscribe(
      (data) => {
        this.pdfFieldNameTypes = data;
        this.pdfService.pdfFieldNameTypesCached = data;
        this.pdfService.setPdfFieldNameType(data);
      },
      (error) => {
        this.alertify.error(error);
      },
      () => {
        this.personType = 'b';
        this.person = this.pdfFieldNameTypes.find(
          (x) => x.parseBy === this.personType
        );
        this.person?.pdfFieldNames?.sort((a, b) => a.sortOrder - b.sortOrder);
        this.alertify.success('Fields Retrieved');
      }
    );
  }
}
