import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { MemberRoutingModule } from './memeber-routing.module';
import { PhotoEditorComponent } from './photo-editor/photo-editor.component';
import { MemberEditComponent } from './member-edit/member-edit.component';
import { MemberCardComponent } from './member-card/member-card.component';
import { MemberDetailComponent } from './member-detail/member-detail.component';
import { MemberListComponent } from './member-list/member-list.component';
import { MembersCartComponent } from './members-cart/members-cart.component';
import { PayMeComponent } from './pay-me/pay-me.component';
import { PaymeHistoryComponent } from './payme-history/payme-history.component';
import { MemberMessagesComponent } from './member-messages/member-messages.component';
import { TextInputComponent } from '../_forms/text-input/text-input.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { PasswordresetemailsentComponent } from './passwordresetemailsent/passwordresetemailsent.component';
import {CompanyProfileModule} from "../companyprofile/companyprofile.module";

@NgModule({
    declarations: [
        MemberCardComponent,
        MemberEditComponent,
        PhotoEditorComponent,
        MemberMessagesComponent,
        MemberDetailComponent,
        MemberCardComponent,
        MembersCartComponent,
        PayMeComponent,
        PaymeHistoryComponent,
        PasswordResetComponent,
        PasswordresetemailsentComponent,
        MemberListComponent,
    ],
    exports: [
        MemberListComponent,
    ],
    imports: [
        MemberRoutingModule,
        CommonModule,
        FormsModule,
        SharedModule,
        AgChartsAngularModule,
        CompanyProfileModule,
        // AgGridModule.withComponents([]),
    ]
})
export class MemberModule { }
