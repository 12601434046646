
<!-- <app-produts-filters (pageChangeExport) = "pageChanged($event)"  
(userParamsExport) = "filter($event)" 
[pagination] = "pagination">
</app-produts-filters> -->

<pagination
[boundaryLinks]="true"
pageBtnClass = "nav nav-tabs"
[(ngModel)]="pagination.currentPage"
[totalItems]="pagination.totalItems"
(pageChanged)="pageChanged($event)"
[itemsPerPage]="pagination.itemsPerPage"
previousText="&lsaquo;"
nextText="&rsaquo;"
firstText="&laquo;"
lastText="&raquo;"
>
</pagination>

  <br>
<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">

      <div class="form-sec tab-content col-md-12">
        <div class="customer-tabs-sec" >
          <ul class="nav nav-tabs">
            <li  class="btn btn-outline-success !important" ><a [ngClass] = "isDeleted ? 'active show' : ''" (click) ="isActiveDeletedClick('isDeleted')"  href="javascript:void(0)">
              <i style="color: green;" class="fa fa-fw fa-filter"></i>&nbsp;&nbsp;Deleted</a>
            </li>
            <li  class="btn btn-outline-success !important" ><a  [ngClass] = "isActive ? 'active show' : ''" (click) ="isActiveDeletedClick('isActive')"  href="javascript:void(0)">
              <i style="color: green;" class="fa fa-fw fa-filter"></i>&nbsp;&nbsp;Active</a></li>
            <li  class="btn btn-outline-success !important" ><a  [ngClass] = "isAll ? 'active show' : ''" (click) ="isActiveDeletedClick('isAll')"  href="javascript:void(0)">
              <i style="color: green;" class="fa fa-fw fa-filter"></i>&nbsp;&nbsp;All</a></li>
          
          <td  *ngIf=" isNewFormTypeClick && selectedSaleFromGrid">
            <a
            class="btn btn-outline-success"
            (click)="printSaleSelected(selectedItem)"
            ><i  class="fa fa-fw fa-print"></i
          >&nbsp; Print</a>
          </td>

          <td *ngIf=" isNewFormTypeClick && selectedSaleFromGrid">
            <a  
            class="btn btn-outline-success"
            (click)="downloadItem()"
            data-toggle="tooltip"
            data-placement="top"
            title="Is Active"
            ><i  class="fa fa-fw fa-download"></i
              >&nbsp; Download</a>

        </td>
        <td *ngIf="isNewFormTypeClick && selectedSaleFromGrid" >
          <a class="btn btn-outline-success"
          (click)="previewItem(item)"
          data-toggle="tooltip"
          data-placement="top"
          title="Is Active"
          ><i  class="fa fa-edit"></i
            >&nbsp;Preview</a>
      </td>
          </ul>
        </div>
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">          
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <div id="printableTable">
                    <table  borderColor="#ccc">
                        

            <tr>
             <th *ngIf="isNewFormTypeClick">Select</th>
						   <!-- <th *ngIf="isNewFormTypeClick && selectedSaleFromGrid">Print &nbsp;</th>
               <th *ngIf="isNewFormTypeClick && selectedSaleFromGrid">Preview &nbsp;</th>
               <th *ngIf="isNewFormTypeClick && selectedSaleFromGrid">Download &nbsp;</th> -->
						<th  (click)="sort('category')">Category &nbsp;
							<i [ngClass]="isCategoryAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('stock')">Stock&nbsp;
							<i [ngClass]="isStockAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('year')">Year&nbsp;
							<i [ngClass]="isYearAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('make')">Make&nbsp;
							<i [ngClass]="isMakeAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('model')">Model&nbsp;
							<i [ngClass]="isModelAscending ? 'up': 'down'"></i>
						</th>
                        <!-- <th (click)="sort('body')">Body&nbsp;
							<i [ngClass]="isBodyAscending ? 'up': 'down'"></i>
						</th>
                        <th (click)="sort('price')">Price&nbsp;
							<i [ngClass]="isPriceAscending ? 'up': 'down'"></i>
						</th> -->
               
                        <th (click)="sort('solddate')"> Sold Date&nbsp;
							<i [ngClass]="isSoldDateAscending ? 'up': 'down'"></i>
						</th>
					
						<th></th>
					  </tr>

         
					            <tr  [hidden]="isPrint">
                        <td></td>
						<td >
						
							<select  
								[(ngModel)] ="selectedCategory" (change) ="selectedCategoryChanged($event.target.value)"
								name="chartInvoiceSaleTypes"
								onmousedown = "value = '';" 
								#chartInvoiceSaleTypes="ngModel">
								<option ngValue= "null">--All--</option>
								<option *ngFor="let summary of bodyTypes" value="{{summary.id}}">
								  {{summary.nameFriendly}}
								</option>
						</select>
						</td>
                        <td style="width: 76px"><input (keyup)="setSearchBy('searchStock', $event.target.value)" >
                        </td>  
                        <td><input style="width: 55px;" (keyup)="setSearchBy('searchYear', $event.target.value)">
                        </td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchMake', $event.target.value)"></td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchModel', $event.target.value)"></td>   
                       
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchSoldDate', $event.target.value)"></td>  
					
						<td></td>
                      </tr>




                      <tr  [ngClass]="{'active-row tr:hover': selectedVehicleId == item.id}" class="td-row tbody" *ngFor="let item of chartItems; let i=index" (click) = "vehicleClicked(item)" >
                          <td *ngIf="i != chartItems.length - 1 && isNewFormTypeClick" >
                            <a
                            class="btn btn-outline-warning"
                            (click)="itemSelected(item.id)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Is Active"
                            >Select</a>
                        </td>
                    
                        <!-- <td *ngIf="i != chartItems.length - 1 && isNewFormTypeClick && selectedSaleFromGrid">
                            <a
                            class="btn btn-success "
                            (click)="printSaleSelected(item.id)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Is Active"
                            ><i  class="fa fa-fw fa-print"></i
                          ></a>
                        </td>
                        <td *ngIf="i != chartItems.length - 1 && isNewFormTypeClick && selectedSaleFromGrid">
                          <a class="btn btn-success"
                          (click)="downloadItem(item)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Is Active"
                          >Download</a>
                      </td>
                      <td *ngIf="i != chartItems.length - 1  && isNewFormTypeClick && selectedSaleFromGrid" >
                        <a class="btn btn-success"
                        (click)="previewItem(item)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Is Active"
                        >Preview</a>
                    </td> -->
                        <td *ngIf="i != chartItems.length - 1">{{ item?.bodyType }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.stock }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.year }}</td>
                        <td *ngIf="i != chartItems.length - 1">{{ item?.make }}</td>

                        <td *ngIf="i != chartItems.length - 1">{{ item?.model }}</td>
                      
                       
                        
                        <td *ngIf="i != chartItems.length - 1">{{ item?.soldDate | date: "shortDate" }}</td>
                        <td [hidden]="isPrint" *ngIf="i != chartItems.length - 1">
                          <button
                            class="btn btn-outline-success"
                            (click)="editVehicle(item)"
                          >
                            Edit
                          </button>
                        </td>
                       
                      </tr>
               
                      <tr  align="right" class="border_top" *ngFor="let item of chartItems; let i=index; last as isLast">
                    
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">{{ item?.suggestedPrice | number: "1.2" }}</td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                      </tr>
                   


                    </table>
                    </div>
                  </div>
                  <!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>

    </div>
  </div>
</div>

<iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>