export class PdfFIeldName {
    id?: number;
    value: string;
    height: number;
    width: number;
    font?: string;
    align: number;
    color?: string;
    borderWidth?: number;
    borderColor?: string;
    pdfFieldNameTypeId: number;
    nameFriendly: string;
    sortOrder: number;
    calculation?: string;
    parseBy?: string;
    isCalculated?: boolean;
    isActive?: boolean;
}
