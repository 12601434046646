import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormName } from '../_models/formName';
import { FormNameLabelsService } from '../_services/formnamelabels/form-name-labels.service';
import { Item } from '../_models/Item';
import { VehicleService } from '../_services/vehicleserv/vehicle.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class VehicleToEditResolver implements Resolve<Item> {
    constructor(private vehicleService: VehicleService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Item> {
        return this.vehicleService.getVehicle(route.params.itemId).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving Vehicle from resolver.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
