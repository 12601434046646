import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { LoginResetPasswordDto } from 'src/app/_models/DTOS/LoginResetPasswordDto';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account.service';

@Component({
  selector: 'app-emailverified',
  templateUrl: './emailverified.component.html',
  styleUrls: ['./emailverified.component.css']
})
export class EmailverifiedComponent implements OnInit {
  passwordResetGuid = {} as any;
  isGuidVerified = false;
  isStartVerfication: any;
  isStartVerificationTrue = false;
  isSubmitted = true;

  @ViewChild('editForm') editForm: NgForm;
  @Input() appUser: User;
  maxDate: Date;
  validationErrors: string[] = [];
  user = {} as  any;
  toSubmit: LoginResetPasswordDto = {
    comfirmPassword: '',
    email: '',
    newPassword: '',
    oldPassword: '',
    password: ''
  };
  constructor( private activatedRoute: ActivatedRoute, private accountService: AccountService, private toastr: ToastrService,
    private fb: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.passwordResetGuid = this.activatedRoute.snapshot.params.passwordResetGuid;
  //  this.accountService.currentUser$.pipe(take(1)).subscribe(user => this.appUser = user);
    // this.maxDate = new Date();
    // this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    // console.log(this.passwordResetGuid); // OUTPUT 1534
    this.verifyPasswordResetGuid();
  }
  // https://localhost:4200/emailverified/17D443ED-EA37-43BC-A0B9-3E7B0245142A
  verifyPasswordResetGuid() {
    console.log(this.passwordResetGuid);
    if (this.passwordResetGuid === 'emailcomfirm') {
      this.isStartVerificationTrue = !this.isStartVerificationTrue;
    } else {
      this.accountService.verifyGuid(this.passwordResetGuid).subscribe(data => {
        this.isGuidVerified = true;
        console.log(data);
        this.user = data;
      }, errr => {
        this.toastr.error(errr);
        this.router.navigate(['/']);
        }, () => {
          this.toastr.success('GUID VERIFIED');
        });
    }
    console.log(this.isStartVerificationTrue);
  }
  register() {
    this.isSubmitted = false;
    // this.user.email = this.appUser.email;
    console.log(this.user);
    if (this.user.comfirmPassword !== this.user.newPassword) {
      this.toastr.error('Passwords do not match.');
    }
    if (this.user.comfirmPassword === this.user.newPassword) {
      this.accountService.resetPassword(this.passwordResetGuid, this.user.newPassword
        ).subscribe(response => {
          console.log(response);
          this.router.navigate(['/']);
       }, error => {
         /// this.validationErrors = error;
         this.router.navigate(['/']);
         console.log(error);
       }, () => {
         this.router.navigate(['/']);
        });
        this.router.navigate(['/']);
    }
  }

  cancel() {
    // this.cancelRegister.emit(false);
  }

}

