import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { VehicleBuyersGuideSystemsCovered } from 'src/app/_models/buyersguidemodels/VehicleBuyersGuideSystemsCovered';
import { BuyersGuideAndSystemsListReturned } from 'src/app/_models/DTOS/buyersguiddtos/BuyersGuideAndSystemsListReturned';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { BuyersGuidService } from '../../buyersguid-service';
import { SystemcoverededitComponent } from './systemcoverededit/systemcoverededit.component';

@Component({
  selector: 'app-systemscovered-grid',
  templateUrl: './systemscovered-grid.component.html',
  styleUrls: ['./systemscovered-grid.component.css']
})
export class SystemscoveredGridComponent implements OnInit {
  vehicleBuyersGuideSystemsCovered = [] as VehicleBuyersGuideSystemsCovered[];
  buyersGuideAndSystemsListReturned = {} as BuyersGuideAndSystemsListReturned;
  selectedCompany: UserAssignedToCompany;
  bsModalRef1: any;
  constructor(private repo: BuyersGuidService, private authService: AuthService,
    private modalService: BsModalService, private repoBuyerGuid: BuyersGuidService,
    private alertify: ToastrService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => {
      this.selectedCompany = data;
      this.repo.getBuyersGuideSytemsList(this.selectedCompany.parentGuid).subscribe(data1 => {
        this.vehicleBuyersGuideSystemsCovered = data1; });
    });
  }
  deleteSystem(e: VehicleBuyersGuideSystemsCovered) {
    this.repo.deletSystem(e.id, this.selectedCompany.parentGuid).subscribe(data => {
      this.vehicleBuyersGuideSystemsCovered = data.bguidesystems;
    });
  }

  edit(e: VehicleBuyersGuideSystemsCovered) {
    const initialState = {
      vehicleBuyersGuideSystemsCovered: e
    };
    this.bsModalRef1 = this.modalService.show(SystemcoverededitComponent, {
      initialState,
    });
    this.bsModalRef1.content.emittedValues.subscribe(
      (values: VehicleBuyersGuideSystemsCovered) => {
        values.parentGuid = this.selectedCompany.parentGuid;
        this.repoBuyerGuid.editBuyersGuideSystems(values).subscribe(
          (data) => {
            this.buyersGuideAndSystemsListReturned = data;
          },
          (err) => {
            console.log(err);
            this.alertify.success('Error adding Systems');
          },
          () => {
            this.alertify.success('Added Buyers Guide');
          }
        );
      }
    );
  }
}
