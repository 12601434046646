import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { ShopParams } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/ShopParams';
import { IPdfPagination } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/IPdfPagination';
import { SupportSubMenus } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/SupportSubMenus';
import { PdfFormTypes } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/PdfFormTypes';
import { PdfForms } from 'src/app/_models/PdfForms';
@Injectable({
    providedIn: 'root',
  })
export class PdfImportService {
 parentId: string;
 baseUrl = environment.apiUrl;
 supportUrl = environment.supportUrl;
 pdfFormTypesCached = [] as PdfFormTypes[];
  constructor(private http: HttpClient, private authService: AuthService) {
    // this.authService.company.subscribe(data => { this.parentId = data?.apiGuid; console.log(data?.apiGuid); });
    this.parentId = '163995EB-0503-4254-8A27-6362EC8AC206';
  }
  installPdf(pdf: PdfForms, parentguid): Observable<PdfForms> {
    return this.http.post<PdfForms>(this.baseUrl + 'pdfimport/installpdffromshop/' + parentguid, pdf);
  }
  getPdfsPaged(shopParams: ShopParams): Observable<IPdfPagination> {
    let params = new HttpParams();
    if (shopParams.categoryId !== 0) {
      params = params.append('categoryId', shopParams.categoryId.toString());
    }
    if (shopParams.typeId !== 0) {
      params = params.append('typeId', shopParams.typeId.toString());
    }
    if (shopParams.search) {
      params = params.append('search', shopParams.search);
    }
    params = params.append('sort', shopParams.sort.toString());
    params = params.append('pageIndex', shopParams.pageNumber.toString());
    params = params.append('pageSize', shopParams.pageSize.toString());

    return this.http.get<IPdfPagination>(this.baseUrl + 'pdfimport/getpagedpdfforms/' + this.parentId, {observe: 'response', params})
    .pipe(
      map(response => {
        return response.body;
      })
    );
  }

  getSupportSubMenuBySubMenuTypeValue(subMenuType: string) {
    // if (this.mentCache.length > 1) { console.log('returned cached menus'); return of(this.mentCache);   }
    return this.http.get<SupportSubMenus[]>(
      this.baseUrl + 'pdfimport/getsupportsubmenusbymenutypestring/' + this.parentId + '/' + subMenuType );
  }

  getPdfFormTypeList(): Observable<PdfFormTypes[]> {
    if (this.pdfFormTypesCached.length > 0) {
      return of(this.pdfFormTypesCached);
    }
    return this.http.get<PdfFormTypes[]>(
      this.baseUrl + 'pdfimport/getpdfformtypesforuser/' + this.parentId
    );
  }
  getPdfsPagedByMenuId(shopParams: ShopParams): Observable<IPdfPagination> {
    let params = new HttpParams();
    if (shopParams.categoryId !== 0) {
      params = params.append('categoryId', shopParams.categoryId.toString());
    }
    if (shopParams.typeId !== 0) {
      params = params.append('typeId', shopParams.typeId.toString());
    }
    if (shopParams.search) {
      params = params.append('search', shopParams.search);
    }
    params = params.append('sort', shopParams.sort.toString());
    params = params.append('pageIndex', shopParams.pageNumber.toString());
    params = params.append('pageSize', shopParams.pageSize.toString());

    return this.http.get<IPdfPagination>(
      this.baseUrl + 'pdfimport/getpagedpdfformsbytype/' + this.parentId, {observe: 'response', params})
    .pipe(
      map(response => {
        return response.body;
      })
    );
  }

}
