import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Website } from 'src/app/_models/website';
import { Settings } from 'http2';
import { Setting } from 'src/app/_models/setting';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingsService {
  baseUrl = environment.apiUrl;
constructor(private http: HttpClient) { }


  edit(settings: Setting): Observable<any> {
    return this.http.put<any>(this.baseUrl + 'websitecompanysettings/updatewebsitesettings/', settings);
  }
  get(settingsId: number): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'websitecompanysettings/getwebsitesettings/' +  settingsId);
  }
}
