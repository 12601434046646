import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Sale } from 'src/app/_models/sale';
import { SaleNotes } from 'src/app/_models/SaleNote';

@Component({
  selector: 'app-addsalenote-modal',
  templateUrl: './addsalenote-modal.component.html',
  styleUrls: ['./addsalenote-modal.component.css']
})
export class AddsalenoteModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  sale: Sale;
  dateTime = new Date();

  constructor(public bsModalRef: BsModalRef) { }
  entity = {} as SaleNotes;

  ngOnInit() {
    this.entity.createdDate = this.dateTime;
    this.entity.remind = true;
    this.entity.remindDate = this.dateTime;
    console.log(this.entity);
  }
  updateGap() {
    this.emittedValues.emit(this.entity);
    this.bsModalRef.hide();
  }
  onChange(e) { }
}

