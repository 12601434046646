import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VehicleBodyClassTypes } from 'src/app/_models/VehicleBodyClassTypes';


@Component({
  selector: 'app-vehicle-body-type-modal',
  templateUrl: './vehicle-body-type-modal.component.html',
  styleUrls: ['./vehicle-body-type-modal.component.css']
})
export class VehicleBodyTypeModalComponent implements OnInit {
  @Output() update = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  vehicleBodyTypes: VehicleBodyClassTypes;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log(this.vehicleBodyTypes);
  }

  updateStatus() {
    this.vehicleBodyTypes = this.editForm.value;
    this.update.emit(this.vehicleBodyTypes);
    this.bsModalRef.hide();
  }
}

