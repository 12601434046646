import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AmoInstallment } from 'src/app/_models/AmoInstallment';
import { AmoLoanType } from 'src/app/_models/amoLoanType';
import { WebsiteCompany } from 'src/app/_models/company';
import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';
import { FormName } from 'src/app/_models/formName';
import { Item } from 'src/app/_models/Item';
import { PackageButtonLocations } from 'src/app/_models/PackageButtonLocations';
import { PaymentType } from 'src/app/_models/PaymentType';
import { PdfFormPackage } from 'src/app/_models/pdfformpackage';
import { Sale } from 'src/app/_models/sale';
import { SaleDefault } from 'src/app/_models/SaleDefault';
import { SaleDefaultTemplate } from 'src/app/_models/SaleDefaultTemplate';
import { SaleStatus } from 'src/app/_models/salestatus';
import { SaleTaxDefault } from 'src/app/_models/SaleTaxDefault';
import { SaleType } from 'src/app/_models/saletype';
import { Vendor } from 'src/app/_models/vendor';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ListService {

  saleTypes = new BehaviorSubject<SaleType[]>(null);
  currentSaleTypes = this.saleTypes.asObservable();

  saleStatus = new BehaviorSubject<SaleStatus[]>(null);
  currentsaleStatus = this.saleStatus.asObservable();

  paymentTypes = new BehaviorSubject<PaymentType[]>(null);
  currentPaymentTypes = this.paymentTypes.asObservable();

  taxDefaults = new BehaviorSubject<SaleDefault[]>(null);
  currentTaxDefaults = this.taxDefaults.asObservable();


    amoLoanTypes = new BehaviorSubject<AmoLoanType[]>(null);
    currentAmoLoanTypes = this.amoLoanTypes.asObservable();

    installments = new BehaviorSubject<AmoInstallment[]>(null);
    currentAmoInstallments = this.installments.asObservable();

    amoSale = new BehaviorSubject<Sale>(null);
    currentAmoSale = this.amoSale.asObservable();

    insuranceCompanies = new BehaviorSubject<Vendor[]>(null);
    currentinsuranceCompanies = this.insuranceCompanies.asObservable();

    lienHolders = new BehaviorSubject<Vendor[]>(null);
    currentlienholders = this.lienHolders.asObservable();

    warrantyCompanies = new BehaviorSubject<Vendor[]>(null);
    currentWarrantyCompanies = this.warrantyCompanies.asObservable();

    saleLabelFormName = new BehaviorSubject<FormName>(null);
    currentSaleLabelFormName = this.saleLabelFormName.asObservable();

    itemDollars = new BehaviorSubject<Dollars[]>(null);
    currentItemDollars = this.itemDollars.asObservable();

    itemDollarTypes = new BehaviorSubject<DollarTypes[]>(null);
    currentItemDollarTypes = this.itemDollarTypes.asObservable();

    vehicleLabelFormName = new BehaviorSubject<FormName>(null);
    currentvehicleLabelFormName = this.vehicleLabelFormName.asObservable();

    saleDefaultTemplate = new BehaviorSubject<SaleDefaultTemplate>(null);
    currentSaleDefaultTemplate = this.saleDefaultTemplate.asObservable();

    itemVehicleEdit = new BehaviorSubject<Item>(null);
    currentItemVehicleEdit = this.itemVehicleEdit.asObservable();

    pdfFormPackages = new BehaviorSubject<PdfFormPackage[]>(null);
    currentpdfFormPackages = this.pdfFormPackages.asObservable();

    pdfPackageButtonLocations = new BehaviorSubject<PackageButtonLocations[]>(null);
    currentpdfPackageButtonLocations = this.pdfPackageButtonLocations.asObservable();
  constructor(private http: HttpClient) { }

  changePackageButtonLocations(packageButtonLocations: PackageButtonLocations[]) {
    this.pdfPackageButtonLocations.next(packageButtonLocations);
  }
  changeDollars(dollars: Dollars[]) {
    this.itemDollars.next(dollars);
  }
  changeDollarTypes(dollarTypes: DollarTypes[]) {
    this.itemDollarTypes.next(dollarTypes);
  }
  changeItemVehicleEdit(data: Item) {
    this.itemVehicleEdit.next(data);
  }
  changeVehicleLavelFormName(data: FormName) {
    this.vehicleLabelFormName.next(data);
  }
  changeSaleLabelFormName(data: FormName) {
    this.saleLabelFormName.next(data);
  }

  changeSaleDefaultTemplate(data: SaleDefaultTemplate) {
    this.saleDefaultTemplate.next(data);
  }
  changeWarrantyCompanies(data: Vendor[]) {
    this.warrantyCompanies.next(data);
  }
  changeInsuranceCompanies(data: Vendor[]) {
    this.insuranceCompanies.next(data);
  }
  changeLienHolders(data: Vendor[]) {
    this.lienHolders.next(data);
  }
  changeSaleTypes(data: SaleType[]) {
    this.saleTypes.next(data);
  }
  changeSaleStatus(data: SaleStatus[]) {
    this.saleStatus.next(data);
  }
  changePaymentTypes(data: PaymentType[]) {
    this.paymentTypes.next(data);
  }
  changeTaxDefaults(data: SaleDefault[]) {
    this.taxDefaults.next(data);
  }
  changeAmoLoanType(data: AmoLoanType[]) {
    this.amoLoanTypes.next(data);
  }

  changePdfFormPackages(data: PdfFormPackage[]) {
    this.pdfFormPackages.next(data);
  }

  changeAmoInstallments(data: AmoInstallment[]) {
    this.installments.next(data);
  }

  changeAmoSale(data: Sale) {
    this.amoSale.next(data);
  }

}
