<table class="table">
  <thead>
  <tr>
      <th width="50%">Name</th>
      <th>Size</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of uploader.queue">
      <td><strong>{{ item?.file?.name }}</strong></td>
      <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
  </tr>
  </tbody>
</table>

<div>
  <div>
      Queue progress:
      <div class="progress mb-4">
          <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
      </div>
  </div>
  <button type="button" class="btn btn-success btn-s"
          (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
      <span class="fa fa-upload"></span> Upload
  </button>
  <button type="button" class="btn btn-warning btn-s"
          (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
      <span class="fa fa-ban"></span> Cancel
  </button>
  <button type="button" class="btn btn-danger btn-s"
          (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
      <span class="fa fa-trash"></span> Remove
  </button>
</div>





