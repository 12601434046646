import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WebsitesDetailResolver } from '../_resolvers/websites.resolver';
import { WebsiteDashboardComponent } from './website-dashboard/website-dashboard.component';
import { WebsiteListComponent } from './website-list/website-list.component';



const routes: Routes = [
    {path: 'websitelist', component: WebsiteListComponent,
    resolve: {website: WebsitesDetailResolver}},
    {path: 'dashboard/:websiteguid', component: WebsiteDashboardComponent}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdvertisingRoutingModule {}


