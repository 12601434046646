import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoanListComponent } from './loan-list/loan-list.component';
import { SaleActiveTypeResolver } from '../_resolvers/sale-activetype.resolver';
import { AmoLinqPaymentsResolver } from '../_resolvers/amo-retrievepayments.resolver';
import { SaleStatusResolver } from '../_resolvers/sale-status.resolver';

import { PaymentListComponent } from './payment-list/payment-list.component';
import { AmoPaymentsResolver } from '../_resolvers/amo-payments.resolver';
import { LoanPaymentsComponent } from './legacy/loan-payments/loan-payments.component';
import { NavredirectmodalpaymentComponent } from './legacy/navredirectmodalpayment/navredirectmodalpayment.component';







const routes: Routes = [
  // {path: 'vehiclecharts/:parrentGuid', component: InventoryGridComponent},
  // {
  //   path: 'loanpayments/:parentGuid',
  //   component: LoanPaymentsComponent,
  //   resolve: {
  //   },
  // },
  {
    path: 'loanlist/:parentGuid',
    component: LoanListComponent,
    resolve: { loans: AmoLinqPaymentsResolver,
      chartInvoiceStatuses: SaleStatusResolver,
      chartInvoiceSaleTypes: SaleActiveTypeResolver,
    },
  },
  {
    path: 'legacypaymentgrid/:parentGuid',
    component: NavredirectmodalpaymentComponent,
    resolve: { loans: AmoLinqPaymentsResolver,
      chartInvoiceStatuses: SaleStatusResolver,
      chartInvoiceSaleTypes: SaleActiveTypeResolver,
    },
  },
  {
    path: 'paymentlist/:parentGuid',
    component: PaymentListComponent,
    resolve: { loans: AmoLinqPaymentsResolver,
      payments: AmoPaymentsResolver,
    },
  },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LoanRoutingModule {}

// {path: 'productlist/aging/:parentGuid', component: AgingListComponent,
// resolve: {chartItems: VehicleGridResolver, bodyTypes: VehicleBodyClassTypesResolver
// }},
