import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FieldAlreadyExistsError, grayscale, PDFDocument, PDFImage, PDFTextField, rgb, TextAlignment } from 'pdf-lib';
import * as fontkit from '@pdf-lib/fontkit';
import { PdfRenderService } from 'src/app/_services/PdfSErvices/pdf-render.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';
import { AuthService } from 'src/app/_services/auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Sale } from 'src/app/_models/sale';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { PdfForms } from 'src/app/_models/PdfForms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { _ } from 'ag-grid-community';
import { SaleData } from 'src/app/_classes/pdfdataclasses/saledata';
import { WindowRef } from 'src/app/_services/PdfSErvices/WIndowREF';

import * as print from 'printj';
import printJS from 'print-js';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { PdfFieldServer } from 'src/app/_models/PdfFieldServer';
import { clear } from 'console';
import { PdfFieldLocationClass } from 'src/app/_classes/pdffielddatats/pdffield-location';
import { Router } from '@angular/router';
import { PdfFormTypes } from 'src/app/_models/PdfFormTypes';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PdfnewuploadnameModalComponent } from 'src/app/modals/pdfnewuploadname-modal/pdfnewuploadname-modal.component';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ConvertPdfToArraysAndBuffers } from 'src/app/_classes/pdffielddatats/convertpdf-toarrays';
import { string } from 'mathjs';
import { PdfUploaderComponent } from '../pdf-uploader/pdf-uploader.component';
import { PdfPackageService } from 'src/app/_services/PdfSErvices/pdf-package.service';
import { PackageButtonLocations } from 'src/app/_models/PackageButtonLocations';
import { ListService } from 'src/app/_services/listServices/list.service';

@Component({
  selector: 'app-pdfform-editor',
  templateUrl: './pdfform-editor.component.html',
  styleUrls: ['./pdfform-editor.component.scss']
})
export class PdfformEditorComponent implements OnInit {
  constructor(private pdfRenderService: PdfRenderService,
    private pdfService: PdfService,
    private winRef: WindowRef,
    private saleService: SaleService,
     private authService: AuthService,
     private alertify: ToastrService,
     private saleData: SaleData,
     private router: Router,
     private modalService: BsModalService,
     private pdfFieldLocationClass: PdfFieldLocationClass,
     private pdfPackageService: PdfPackageService,
     private confirmService: ConfirmService,
     private convertPdfToArraysAndBuffers: ConvertPdfToArraysAndBuffers,
     private listServices: ListService,
     private el: ElementRef, private render: Renderer2) {
       // const printJS = require('print-js');
     }
     @Output() callParentIsUpload = new EventEmitter<string>();
     @Input() formTypes: PdfFormTypes[];
     @Input() selectedType: PdfFormTypes;
     @ViewChild('pdfViewer') pdfViewer;
     pdfUploaderComponent = [] as PdfUploaderComponent[];
isEmptyForm = false;
bsModalRef: any;
formNameToUpload: any;
isUpload = false;
   byteArray: any;
   byteBase64Array: any;
   serverPdfFields = [] as PdfFieldServer[];
   serverPdfField = {} as PdfFieldServer;
  pdfSrc: any;
  doc: any;
  pdfSrcBase64: any;
 @Input() sale: Sale;
  id = 34 as  number;
  value = 5 as number;
  isWithData = true;
  pdfForms = [] as PdfForms[];
  @Input() pdfForm = {} as PdfForms;
  pdfSaleFields = [] as PdfSaleFields[];
  pdfSaleFieldsWithDAta = [] as PdfSaleFields[];
  pdfSaleField = {} as PdfSaleFields;
  LogPdfFields = [] as any[];
  rect1: any;
  top: any;
  left: any;
  DocumentX: number;
  DocumentTop: number;
  DocumentY: number;
  DocumentSize: number;
  DocWidth: number;
  DocHeight: number;
  x: number;
  y: number;
  i = 0 as number;
    window: any;
    isPrint = false;
  formUrl = 'http://localhost:5000/StaticFiles/vehicleimages/ken.pdf';
  imgURl = 'http://localhost:5000/StaticFiles/texas/130U.jpg';
  pngUrl = '../../../assets/images/transparent.png';
  selectedCompany1: UserAssignedToCompany;
  @Input() packageButtonLocations = [] as PackageButtonLocations[];
context = {} as any;

  ngOnInit() {
    // ng2-pdf-viewer-container
    // document.getElementsByClassName('ng2-pdf-viewer-container').getBoundingClientRect('click', (event) => { }
    document.getElementById('pdfViewer').addEventListener('click', (event) => {
      console.log(event);
      this.x = event.x - this.DocumentX;
      this.y = event.y - this.top;
      console.log('event.y ;: ' + event.y );
      console.log(' event.x: ' +  event.x);
      console.log('this.DocumentY;: ' + this.DocumentY);
      console.log('this.DocumentX;: ' + this.DocumentX);
      console.log('this.DocumentTop: ' + this.DocumentTop);
      console.log('this.x: ' + this.x);
      console.log('this.y: ' + this.y);
     // document.getElementById('pdfViewer').addEventListener('mousemove', this.myFunction);
  });
  // name was in namedItem now what goes there?
  const d = document.getElementsByClassName('pdfViewer removePageBorders').namedItem('name');
  console.log('Name of remove borders ' + d);
  this.render.listen('window', 'load', () => {
    const rect = this.el.nativeElement.getBoundingClientRect().top;


  //  console.log('Left? : ' + rect + ' ; Top? : ' + top + '.');
});

  document.getElementById('pdfViewer').onclick = function clickEvent(e) {
    // e = Mouse click event.
   // console.log(e);
   // const rect = this.el.nativeElement.getBoundingClientRect();
  //  const rect = e.target.getBoundingClientRect();
    // const x = e.clientX - this.left; // x position within the element.
    // const y = e.clientY - top;  // y position within the element.
    // console.log('Left? : ' + x + ' ; Top? : ' + y + '.');
  };
    this.authService.company.subscribe(data => {this.selectedCompany1 = data; });
     this.getPdfForms();
  }
  myFunction(e) {
   // console.log(e);
  }
  getPdfForms() {
    this.pdfService.currentpdfForm.subscribe(data => {
      this.pdfForm = data;
      this.formUrl = data?.pdfFormUrl;
   //   this.getData();
   this.fillForm();
    }, error => { this.alertify.error(error);  });
  }
  // getData() {
  //   this.saleService.getSaleById(this.selectedCompany1.parentGuid, this.id ).subscribe(data => {
  //     this.sale = data;
  //   }, error => {}, () => {
  //     this.fillForm();
  //   });
  // }
  pageRendered(e: CustomEvent) {
    console.log('(page-rendered)', e);
  }
  textLayerRendered(e: CustomEvent) {
    console.log('(text-layer-rendered)', e);
  }
  setPdfToVIew(bytes) {
  this.pdfSrc = bytes;
  }
  callBackFn(pdf: PDFDocumentProxy) {
    // do anything with "pdf"
 }
  dragMoveListener(event) {
    const test = document.getElementById('pdfViewer').getBoundingClientRect();
      // tslint:disable-next-line:one-variable-per-declaration
      const target = event.target,
      // keep the dragged position in the data-x/data-y attributes
      x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
      y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

  // translate the element
  target.style.webkitTransform =
  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
console.log('target transform: ' +  target.style.transform );
  // update the posiion attributes
  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);
  this.DocumentX = Number(test.left) ;
  this.DocumentY =   Number(test.top) ;
  this.DocumentTop =   Number(test.top);
  this.DocWidth = test.width;
  this.DocHeight = test.height;
  this.top = test.top;
  console.log('y: ' + this.DocumentY + 'x: ' + this.DocumentX + 'Top: ' + this.DocumentTop);

  console.log(test);
}
getServerPdfFields(formUrl) {
  this.pdfFieldLocationClass.getFieldLocations(formUrl)
    .then(
       data => { this.serverPdfFields = data; console.log(data); },
       err => { console.log(err); }
  );
}
  async  fillForm() {
    // image to bytes array
    const pngImageBytes = await fetch(this.pngUrl).then((res) => res.arrayBuffer());
    console.log(pngImageBytes);
    this.serverPdfFields = [];
    this.getServerPdfFields(this.formUrl);
    // form to bytes array
    const formPdfBytes = await fetch(this.formUrl).then(res => res.arrayBuffer());
    // const pdfDoc =  await  PDFDocument.load(formPdfBytes, { ignoreEncryption: true });
    if (formPdfBytes) {
      const pdfDoc2 = await PDFDocument.load(formPdfBytes);
      const pngImage = await pdfDoc2.embedPng(pngImageBytes);

    //  Xfactor equals: 612PPI/815 .. .7509
    //  YFActor equals: 792PPI/1056px    ..7509
      const pagesInDoc = await pdfDoc2.getPages();
      const page = await pdfDoc2.getPage(0);

      const form2 = pdfDoc2.getForm();

      const superheroField = form2.createTextField('svYear12');
    //  superheroField.setMaxLength(250);
    //  superheroField.updateAppearances
      superheroField.setAlignment(TextAlignment.Left);
      superheroField.addToPage(page, { y: 700, x: 194.25,  textColor: rgb(0, 0, 0),  width: 50, height: 14, borderWidth: 0,  backgroundColor: undefined, } );
      //  superheroField.setText('One');
        superheroField.addToPage(page, {
          x: 40,
          y: 65,
          width: 50,
          height: 14,
          textColor: rgb(0, 0, 0),
          borderColor: rgb(0, 0, 1),
          backgroundColor: undefined,
          borderWidth: 0,
        });
        superheroField.addToPage(page, {
          x: 40,
          y: 25,
          width: 50,
          height: 14,
          textColor: rgb(0, 0, 0),
          borderColor: rgb(0, 0, 1),
          backgroundColor: undefined,
          borderWidth: 0,
        });
        superheroField.addToPage(page, {
          x: 40,
          y: 45,
          width: 50,
          height: 14,
          textColor: rgb(0, 0, 0),
          borderColor: rgb(0, 0, 1),
          backgroundColor: undefined,
          borderWidth: 0,
        });

        // borderColor: rgb(0, 0, 1),
    const pdfBytes2 = await pdfDoc2.save();
    const  pdfDoc = await PDFDocument.load(pdfBytes2);
    let fieldToSet = {} as PDFTextField;


      // Get the form so we can add fields to it
      const form = pdfDoc.getForm();
      const fields = form.getFields();
       console.log(fields);
         this.pdfSaleFields = [];
         // multiple funcitons
         // get field names
         // send field names for values populating array of values for formfields
         // populate pdf with field name if selected !isWithData
      fields.forEach(field => {
           const type = field.constructor.name;
           const name = field.getName();
           this.LogPdfFields.push( name.toString());
           // PDFTextField
           if (type === 'PDFTextField') {
              fieldToSet = form.getTextField(name);
         //     form.removeField(name);
              fieldToSet.removeMaxLength();
              fieldToSet.setMaxLength(250);
              this.pdfSaleField = {id: this.i, field: '', value: '', calculate: 'false'};
              this.pdfSaleField.field = name.toString();
              this.pdfSaleField.id = this.id;
              this.pdfSaleField.calculate = 'false';
              this.setFieldValues(this.pdfSaleField );
              this.id = this.id + 1;
              if (!this.isWithData && !this.isEmptyForm) {  fieldToSet.setText(name.toString()); }
            }
         });
        this.saleData.fillPdfFieldsWithDAta(this.sale, this.pdfSaleFields).subscribe(data => {
          this.pdfSaleFieldsWithDAta =  data;
        });
        // fill form with values from data base
         if (this.isWithData && !this.isEmptyForm) {
          this.pdfSaleFieldsWithDAta.forEach(element => {
            fieldToSet = form.getTextField(element.field);
              fieldToSet.setText(element.value);
          });

        }
        // fill empty form
        if (this.isEmptyForm) {
          this.pdfSaleFieldsWithDAta.forEach(element => {
            fieldToSet = form.getTextField(element.field);
              fieldToSet.setText('');
          });
        }
        this.pdfService.setPdfFieldServer(this.serverPdfFields);
        console.log(this.serverPdfFields);
      const pdfBytes = await pdfDoc.save();
      const mydf = await pdfDoc.saveAsBase64();
      this.byteBase64Array = mydf;
    //  console.log(this.byteBase64Array);
      this.byteArray = pdfBytes;
      this.setPdfToVIew(pdfBytes);
      console.log(pdfBytes);
    //  this.saveByteArray('Sample Report', sampleArr); // downloads base64 array by converting to 8
  }
 // download(pdfBytes, "pdf-lib_form_creation_example.pdf", "application/pdf");
  }
  onProgress(progressData) {

  }
  editPdf() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/reportsmanager/pdfsized/' + this.pdfForm.id]));
    window.open(url, '_blank');
  //  this.router.navigate(['reportsmanager/pdfsized/' + this.pdfForm.id]);
  }
  // Convert Uin8array from base 64
  base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
       const ascii = binaryString.charCodeAt(i);
       bytes[i] = ascii;
    }
    return bytes;
 }
 // switch for download or preview at window open
   saveByteArray(reportName, byte) {
    const blob = new Blob([byte], {type: 'application/pdf'});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const blobURL = URL.createObjectURL(blob);
      if (this.isPrint) { window.open(blobURL); }
      if (!this.isPrint) {
        const fileName = reportName;
        link.download = fileName;
        link.click();
      }
    //  this.convertPdfToArraysAndBuffers.createDownLoadLink(byte, 'application/pdf', this.isPrint, reportName);
}
PrintPdf(e) {
  if (e === 'view') {this.isPrint = true; this.saveByteArray(this.pdfForm.name, this.byteArray); }
  if (e === 'print') {this.isPrint = false;
    printJS({printable: this.byteBase64Array, type: 'pdf', showModal: true, base64: true});
  }
  if (e === 'download') {this.isPrint = false; this.saveByteArray(this.pdfForm.name, this.byteArray); }
}
  setFieldValues(value: PdfSaleFields) {
   const length = this.pdfSaleFields.push(value);
  }
  fieldValues() {

  }
  clickEvent() {
}
  changeIsWithData(isempty?: any){
    if (isempty === 'emptyform') {
      this.isEmptyForm = true;
      this.fillForm();
    }
    else {
      this.isWithData = !this.isWithData;
      this.isEmptyForm = false;
      this.pdfService.changeIsWithData(this.isWithData);
      this.pdfService.changeIsEmpty(!this.isWithData);
      this.fillForm();
    }


  }
  mapFields() {

  }
  selectPdf() {

  }
  autoMapFields() {}

 refreshpdfPackageButtonLocations() {
  this.pdfPackageService.listPackageButtonLocations(this.selectedCompany1.parentGuid).subscribe(data => {
    this.packageButtonLocations = data;
    this.listServices.changePackageButtonLocations(data);
  }, error => {
    this.alertify.error(error);
  }, () => {
    const config = {
      class: 'modal-dialog-centered',
      initialState: {
       sale: this.pdfForm,
       selectedType: this.selectedType,
       pdfFormTypes: this.formTypes,
       pdfUploaderComponent: this.pdfUploaderComponent
      }
    };
    this.bsModalRef = this.modalService.show(PdfnewuploadnameModalComponent, config);
    this.bsModalRef.content.emittedValues.subscribe(values => {
      this.formNameToUpload = values.name;
      console.log(values.name);
      if (values.name) {
        if (values.name !== '' || values.name !== undefined) {
         this.callParentIsUpload.emit('true');
        }
      }
    });
  });
 }
  uploadPdf() {
    console.log('reached pdfupload');
    this.listServices.pdfPackageButtonLocations.subscribe(data => { this.packageButtonLocations = data;
    if (data.length > 0) {
      const config = {
        class: 'modal-dialog-centered',
        initialState: {
         sale: this.pdfForm,
         selectedType: this.selectedType,
         pdfFormTypes: this.formTypes,
         pdfUploaderComponent: this.pdfUploaderComponent
        }
      };
      this.bsModalRef = this.modalService.show(PdfnewuploadnameModalComponent, config);
      this.bsModalRef.content.emittedValues.subscribe(values => {
        this.formNameToUpload = values.name;
        console.log(values.name);
        if (values.name) {
          if (values.name !== '' || values.name !== undefined) {
           this.callParentIsUpload.emit('true');
          }
        }
      });
    } else {
      this.refreshpdfPackageButtonLocations();
    }
    });
  }

  deletePdf() {
    this.confirmService.confirm('Confirm delete message', 'Are you sure?').subscribe(result => {
      if (result) {
      this.pdfService.deleletePdf(this.selectedCompany1.parentGuid, this.pdfForm.id)
         .subscribe(data => {
           this.pdfForms = data.pdfForms;
           this.pdfService.setPdfFormList(data.pdfForms);
           this.pdfService.setPdfFormTypes(data.pdfFormTypes);
         }, error => { this.alertify.error(error); }, () => {
           this.alertify.success('Pdf Deleted.');
         });
        }
        });

  }

}
