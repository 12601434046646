import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WebsisteCompanyDescriptions } from 'src/app/_models/DTOS/websitedtos/companyparagraphs';
import { WebsiteCompany } from 'src/app/_models/company';
import { Website } from 'src/app/_models/website';
import { CompaniesWebsiteService } from 'src/app/_services/advertising/companies.service';

@Component({
  selector: 'app-company-paragraphs',
  templateUrl: './company-paragraphs.component.html',
  styleUrls: ['./company-paragraphs.component.scss'],
})
export class CompanyParagraphsComponent implements OnInit {
  @ViewChild('editForm', { static: true }) editForm: NgForm;
  @Input() companyInfo2 = {} as WebsiteCompany;
  @Input() website = {} as Website;
  companyInfo = {} as WebsisteCompanyDescriptions;
  constructor(
    private alertify: ToastrService,
    private route: ActivatedRoute,
    private companyService: CompaniesWebsiteService
  ) {}

  ngOnInit() {
    this.companyInfo.description1 = this.companyInfo2.description1;
    this.companyInfo.description2 = this.companyInfo2.description2;
    this.companyInfo.description3 = this.companyInfo2.description3;
    this.companyInfo.description4 = this.companyInfo2.description4;
    this.companyInfo.description5 = this.companyInfo2.description5;
    this.companyInfo.description6 = this.companyInfo2.description6;
  }
  onSubmit(e) {
    console.log(this.companyInfo2.id);
    this.companyService
      .updateParagraphs(this.companyInfo2.id, this.companyInfo)
      .subscribe(
        (data) => {
          this.companyInfo2 = data.company;
          this.website = data.website;
        },
        (error) => {
          this.alertify.error(error);
        },
        () => {
          this.alertify.success('Changes saved');
          this.editForm.form.markAsPristine();
        }
      );
  }
}
