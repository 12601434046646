import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Member } from 'src/app/_models/member';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account.service';
import { MembersService } from 'src/app/_services/members.service';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { UserCartService } from 'src/app/_services/userpaymentservices/User-Cart.service';
import { UserCart } from 'src/app/_models/UserCart';

@Component({
  selector: 'app-member-edit',
  templateUrl: './member-edit.component.html',
  styleUrls: ['./member-edit.component.css']
})
export class MemberEditComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;
  member: Member;
  user: User;
  cartTotal = 0;
  membersCart = false;
  cartLenth = 0;
  userCart = [] as UserCart[];
  @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
    if (this.editForm.dirty) {
      $event.returnValue = true;
    }
  }

  constructor(private accountService: AccountService,
     private memberService: MembersService,
     private userCartService: UserCartService,
    private toastr: ToastrService) {
      this.accountService.currentUser$.pipe(take(1)).subscribe(user => this.user = user);
  }

  ngOnInit(): void {
    this.getOwner();
    this.getCart();
  }

getCart() {
  this.userCartService.getCart().subscribe(data => {
    this.userCart = data;
    this.cartLenth = data.length;
    this.userCartService.changeCartItems(data);
    this.userCartService.changeCartCount(data.length);
    console.log(data);
}, error => { this.toastr.error(error); }, () => {
  this.userCartService.cartCount.subscribe(data => {
    this.cartLenth = data;
    this.userCartService.currentcartCount.subscribe(data1 => { this.cartTotal = data1; });
    // this.toastr.success('Cart Updated');
    }
  );
});
 }

  // loadMember() {
  //   this.memberService.getMember(this.user.username).subscribe(member => {
  //     this.member = member;

  //   });
  // }
  getOwner() {
    this.memberService.getOwner(this.user.email).subscribe(data => { console.log(data);
    this.member = data;
    });
  }
  updateMember() {
    this.memberService.updateMember(this.member).subscribe((data) => {
      this.toastr.success('Profile updated successfully');
      this.editForm.reset(this.member);
      this.getOwner();
    });
  }
}
