import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Observable, BehaviorSubject } from 'rxjs';
import { PhotosOfVehicles } from '../../_models/PhotosOfVehicles';

@Injectable({
  providedIn: 'root'
})
export class VehiclePhotosService {
  mainAvatar = new BehaviorSubject<string>(null);
  currentmainAvatar = this.mainAvatar.asObservable();

  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;

  setMainPhoto(vehicleId: number, id: number): Observable<PhotosOfVehicles[]> {
    return this.http.get<PhotosOfVehicles[]>(this.baseUrl + 'vehicle/' + vehicleId + '/photos/' + id + '/setmain');
  }
  changeVechicleAvatar(photo: string) {
    this.mainAvatar.next(photo);
  }
  deletePhoto(vehicleId: number, id: number) {
    console.log(this.baseUrl + 'vehicle/' + vehicleId + '/photos/' + id + '/deletevehiclephotos');
    return this.http.get(this.baseUrl + 'vehicle/' + vehicleId + '/photos/' + id + '/deletevehiclephotos');
  }

}
