import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AmoInstallment } from '../_models/AmoInstallment';
import { CompanyProfile } from '../_models/companyProfile';
import { AmoInstallmentService } from '../_services/amoinstallmentservices/amoinstallments.service';
import { CompaniesService } from '../_services/companydataservice/companies.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyProfileResolver implements Resolve<CompanyProfile> {
    constructor(private repo: CompaniesService, private router: Router, private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<CompanyProfile> {
        const newLocal = 'parentGuid';
        return this.repo.getCompanyByCompanyGuid(route.params[newLocal]).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving company.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
