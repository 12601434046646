import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddVehicleVinModalComponent } from 'src/app/modals/add-vehicle-vin-modal/add-vehicle-vin-modal.component';
import { LabelDoubleClickModalComponent } from 'src/app/modals/LabelDoubleClickModal/LabelDoubleClickModal.component';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';
import { FormName } from 'src/app/_models/formName';
import { Item } from 'src/app/_models/item';
import { Label } from 'src/app/_models/label';
import { Sale } from 'src/app/_models/sale';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { VehicleMake } from 'src/app/_models/vehiclemakes';
import { VehicleYears } from 'src/app/_models/vehicleYears';
import { AuthService } from 'src/app/_services/auth.service';
import { FormNameLabelsService } from 'src/app/_services/formLabelservices/form-name-labels.service';
import { ListService } from 'src/app/_services/listServices/list.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';
import { VehicleService } from 'src/app/_services/vehicleserv/vehicle.service';
import { VendorService } from 'src/app/_services/vendorservices/vendor.service';


@Component({
  selector: 'app-sale-vehicle',
  templateUrl: './sale-vehicle.component.html',
  styleUrls: ['./sale-vehicle.component.css']
})
export class SaleVehicleComponent implements OnInit, OnChanges {
  @Input() sale: Sale;
  @Input() formName: FormName;
 // @Input() item: Item;
  labels = [] as Label[];
  label: Label;
  labelToDisplay: any;
  loading: boolean;

  labelStock: any;
  labelVin: any;
  labelYear: any;
  labelStatus: any;
  labelCountyCode: any;
  labelCategory: any;
  lableTitleNumber: any;
  labelPreviousTitleNumber: any;
  labelMake: any;
  labelPreviousState: any;
  labelModel: any;
  labelPurchasedAs: any;
  labelExertiorColor1: any;
  labelPurchasedPrice: any;
  labelStyle: any;
  labelRepairsDepreciated: any;
  labelDisplacement: any;
  labelTotalCost: any;
  labelCylinders: any;
  labelSuggestedPrice: any;
  labelDatePurchased: any;
  labelDecalNumber: any;
  labelTag: any;
  labelTagExpires: any;
  labelMileage: any;
  labelWeight: any;
  isLoaded: boolean;
  labelMileageCode: any;

  vehicleYears = [] as VehicleYears[];
  vehicleMakes = [] as VehicleMake[];

  isTrade1 = false;
  isTrade2 = false;
  bsModalRef: any;
  bsModalRef1: any;
  selectedCompany1: UserAssignedToCompany;
  bodyTypes: any[] = [];
  constructor(private listService: ListService,
     private formNameService: FormNameLabelsService,
     private alertify: ToastrService,
     private modalService: BsModalService,
     private authService: AuthService,
     private vendorService: VendorService,
     private router: Router,
     private vehicleService: VehicleService,
     private saleSerivce: SaleService,
    private format: DealInforFormat) { }
  ngOnChanges() {
    this.getLabels();
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
    this.listService.vehicleLabelFormName.subscribe(data => { this.formName = data; });
  }
  getLabels(){
    this.authService.currentFormNames.subscribe(data => {
      data.forEach(element => {
        if (element.value === 'VehicleDetails'){ this.labels = element.labels; this.decipherLabels(element.labels); }
      });
    });
  }
  ngOnInit() {
    if (this.sale.tradeInId) { this.isTrade1 = true; }
    this.getLabels();
  }
  toggleValue2(e) {
    const initialState = {
      labelName:  e
    };
    this.bsModalRef1 = this.modalService.show(LabelDoubleClickModalComponent, {initialState});
    this.bsModalRef1.content.emittedValues.subscribe((values) => {
      this.formNameService.editFormLabelByFieldName(this.selectedCompany1.parentGuid, e, values, 'VehicleDetails').subscribe(data => {
        this.formName = data;
        this.labels = data.labels;
        }, (error) => {
          this.alertify.error(error);
        }, () => { this.formNameService.getFormNameList(this.selectedCompany1.parentGuid).subscribe(data => {
          this.authService.changeFormLabels(data);
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Success!');  this.getLabels(); });
        });
       });
  }
  addLabel(e) {
    this.formNameService.addFormLabel(this.selectedCompany1.parentGuid, e, this.formName.id).subscribe(data => {
      this.formName = data;
      this.labels = data.labels;
    }, error => {this.alertify.error(error); }, () => {this.decipherLabels(this.labels); } );
  }
  decipherLabels(e) {
    this.labels = e;

    if  (this.labels.length > 0) {
      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Vin');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Vin'); }
      this.labelVin = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'StockNumber');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('StockNumber'); }
      this.labelStock = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Year');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Year'); }
      this.labelYear = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Status');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Status'); }
      this.labelStatus = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'County Code');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('County Code'); }
      this.labelCountyCode = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Category');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Category'); }
      this.labelCategory = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Title Number');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Title Number'); }
      this.lableTitleNumber = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Year');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Year'); }
      this.labelYear = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Previous Title Number');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Previous Title Number'); }
      this.labelPreviousTitleNumber = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Make');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Make'); }
      this.labelMake = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Previous State');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Previous State'); }
      this.labelPreviousState = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Model');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Model'); }
      this.labelModel = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Purchased As');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Purchased As'); }
      this.labelPurchasedAs = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Ext Color 1');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Ext Color 1'); }
      this.labelExertiorColor1 = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Purchased Price');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Purchased Price'); }
      this.labelPurchasedPrice = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Style');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Style'); }
      this.labelStyle = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Repairs:Depreciated');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Repairs:Depreciated'); }
      this.labelRepairsDepreciated = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Displacement');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Displacement'); }
      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Displacement');
      this.labelDisplacement = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Total Cost');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Total Cost'); }
      this.labelTotalCost = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Cylinders');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Cylinders'); }
      this.labelCylinders = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Suggested Price');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Suggested Price'); }
      this.labelSuggestedPrice = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Date Purchased');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Date Purchased'); }
      this.labelDatePurchased = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Decal Number');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Decal Number'); }
      this.labelDecalNumber = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Tag');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Tag'); }
      this.labelTag = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Tag Expires');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Tag Expires'); }
      this.labelTagExpires = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Mileage');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Mileage'); }
      this.labelMileage = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Weight');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Weight'); }
      this.labelWeight = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Mileage Code');
      if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Mileage Code'); }
      this.labelMileageCode = this.labelToDisplay[0].value;
    }
  }
  trade1Click(){
    if (this.sale.tradeInId) {
      this.isTrade1 = true;
      this.isTrade2 = false;
    } else {
      this.addTrade1();
    }

  }
  trade2Click(){
    console.log(this.sale.tradeIn2Id);
    if (this.sale.tradeIn2Id) {
    this.isTrade2 = true;
    this.isTrade1 = false;
    } else {
      this.addTrade2();
    }
  }
  addTrade2() {
    this.bsModalRef = this.modalService.show(AddVehicleVinModalComponent);
    this.bsModalRef.content.updateVehicle
       .subscribe(arg => {
         this.vehicleService.addVehicleTrade1(arg, this.selectedCompany1.parentGuid, this.sale.id, false, false, true).subscribe(
         data => { console.log(data); this.sale.tradeIn2Id = data.vehicleChartItem.id; this.sale.tradeIn2 = data.vehicleChartItem; this.isTrade2 = true; this.isTrade1 = false;}, error => { this.alertify.error(error); }, () => {
           this.alertify.success('Trade 1 Added');
         });
       });
  }
  addTrade1() {
    this.bsModalRef = this.modalService.show(AddVehicleVinModalComponent);
   this.bsModalRef.content.updateVehicle
      .subscribe(arg => {
        this.vehicleService.addVehicleTrade1(arg, this.selectedCompany1.parentGuid, this.sale.id, true, true, false).subscribe(
        data => { console.log(data); this.sale.tradeInId = data.vehicleChartItem.id; this.sale.tradeIn = data.vehicleChartItem; this.isTrade1 = true; }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Trade 1 Added');
        });
      });
   // this.router.navigate(['vehicledashboard/editvehicle/' +   this.selectedCompany1.parentGuid + '/269']);
  }
  async update() {
    console.log(this.sale);
    // if(this.sale.docAuto === "false") {this.sale.docAuto = false; } else { this.sale.docAuto = true; }
    // this.sale.docAuto = 0;
    this.sale.passPmts = 0;
    if (this.sale !== null) {
      this.sale = await this.format.formatDealInforNumbers(this.sale);
      this.saleSerivce
        .updateSale(this.selectedCompany1.parentGuid, this.sale)
        .subscribe(
          (data) => {
            this.alertify.success('Sale Updated');
            this.alertify.success('Accounting will be updated in background.');
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {}
        );
    }
  }

  tabelViewClick(){
    this.router.navigate(['saledashboard/saledashboard/' + this.selectedCompany1.parentGuid]);
  }
}
