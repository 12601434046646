import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';

import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SaleType } from 'src/app/_models/saletype';


@Component({
  selector: 'app-chart-invoice-saletype-modaladd',
  templateUrl: './chart-invoice-saletype-modaladd.component.html',
  styleUrls: ['./chart-invoice-saletype-modaladd.component.css']
})
export class ChartInvoiceSaletypeModaladdComponent implements OnInit {
  @Output() update = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  chartInvoiceSaleType: SaleType;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  updateStatus() {
    this.chartInvoiceSaleType = this.editForm.value;
    this.update.emit(this.chartInvoiceSaleType);
    this.bsModalRef.hide();
  }

}
