import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ChartInvoiceTransactionGridTOReturnDto } from '../_models/DTOS/ChartInvoiceTransactionGridTOReturnDto';
import { SaleService } from '../_services/saleservices/sale.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
    providedIn: 'root'  // **** ADD THIS LINE ****
  })


export class SalesResolver implements Resolve<ChartInvoiceTransactionGridTOReturnDto[]> {
    pageNumber = 1;
    pageSize = 10;
    constructor(private saleService: SaleService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
      //  console.log(route.params['parentGuid']);
        // tslint:disable-next-line:no-string-literal
        return this.saleService.getSaleInvoice2(route.params['parentGuid'], this.pageNumber, this.pageSize).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving invoices.');
            //    this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
