import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AmoPayment } from 'src/app/_models/amopayments';
import { AmoLinqPayments } from 'src/app/_models/DTOS/amolinqpayments';
import { CalculateCurrentDueDto } from 'src/app/_models/DTOS/calculateCurrentDueDto';
import { TakePaymentDto } from 'src/app/_models/DTOS/takepaymentdto';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { SaleStatus } from 'src/app/_models/salestatus';
import { SaleType } from 'src/app/_models/saletype';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AmoInstallmentService } from 'src/app/_services/amoinstallmentservices/amoinstallments.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ExcelService } from 'src/app/_services/exportservices/excel.service';
import { LoanService } from 'src/app/_services/loanservieces/loan.service';
import moment from 'moment';
import { AddPaymentModalComponent } from '../add-payment-modal/add-payment-modal.component';
import { AmoLoanService } from 'src/app/_services/amoloanservices/amoloan.service';
import { Sale } from 'src/app/_models/sale';
import { PaymentReceiptDto } from './printreceiptdto';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { PersonToLinqResultToReturnDto } from 'src/app/_classes/persontodto/PersonToLinqResultToReturnDto';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
@Component({
  selector: 'app-loan-list',
  templateUrl: './loan-list.component.html',
  styleUrls: ['./loan-list.component.css']
})
export class LoanListComponent implements OnInit {
    selectedCompany: UserAssignedToCompany;
    pagination: Pagination;
    loans: AmoLinqPayments[];
    loan = {} as AmoLinqPayments;
    invoiceId = 0 as number;
    userParams = {} as any;
    todayDate = new Date();
    isAscending = true;
    isCustomerAscending = true;
    isTransactionDateAscending = true;
    isAmountFinancedAscending = true;
    isFirstDueDateAscending = true;
    isNextDueDateAscending = true;
    isAmountDueAscending = true;
    isLastPaidDateAscending = true;
    isPaymentAscending = true;
    isNextScheduledDateAscending = true;
    isPayoffAscending = true;
    isFilterDates = false;
    isInvoiceNumberAscending = true;
    isExpandPayments = false;
    calculateCurrentDueDto = {} as CalculateCurrentDueDto;
    itemId: number;
    amoPayments: AmoPayment[];
    chartInvoiceStatuses: SaleStatus[];
    chartInvoiceSaleTypes: SaleType[];
    bsModalRefAddPayment: any;
    firstLoad = true;
    selectType;
    selectStatus;
    isPrint = false;
    paymentRecieptDto = {} as PaymentReceiptDto;
    numZero = 0 as number ;
    sale = {} as Sale;
    isPaymentList = false;
    isLoanList = false;
    isAddPayment = false;
    personToSendLoan = {} as PersonLinqResultToReturnDto;
    takePaymentDto: TakePaymentDto =  {
      datePaid: moment(Date.now()).toDate(),
      invoiceId: null,
      todayLoanPaid: 1,
      todayDeferredDown: 2,
      todayLateFee: 3,
      todayMisc: 4,
      sumPaidToday: 5,
      parentId: ' '
    };
    constructor(private authService: AuthService,
      private takePaymentLoan: AmoLoanService,
      private loansService: LoanService,
      private convertPerson: PersonToLinqResultToReturnDto,
      private route: ActivatedRoute,
      private modalService: BsModalService,
      private router: Router,
      private alertify: ToastrService,
      private confirmService: ConfirmService,
      private loanService: AmoInstallmentService,
      private loansDue: LoanService,
      private excelService: ExcelService) { }

    ngOnInit() {
      this.authService.setIsCurrentPerson(null);
      this.isLoanList = true;
      this.isFilterDates = true;
      this.authService.currentCompany.subscribe(company => this.selectedCompany = company);
      this.route.data.subscribe(data => {
        const newLocal = 'loans';
        this.loans = data[newLocal].result;
        this.pagination =  data[newLocal].pagination;
        const newLocal1 = 'chartInvoiceStatuses';
        this.chartInvoiceStatuses = data[newLocal1];
        const newLocal2 = 'chartInvoiceSaleTypes';
        this.chartInvoiceSaleTypes = data[newLocal2];
        this.firstLoad = true;
      });
        this.userParams.returnByBodyTypeId = undefined;
        this.calcAllLoansCurrentDues();
    }
    loanSelected(e) {
      this.authService.setIsCurrentPerson(null);
      this.loansService.getSaleByLoanId(e.amoLoanId).subscribe(sale => {
        this.convertPerson.convertPersonToLinq(sale.buyer, sale).then(data => {
          this.personToSendLoan = data;
          console.log(data);
          this.authService.setIsCurrentPerson(data);
        });
      });

    }
    async dueTodayLoad(item) {
      console.log(item);
      this.invoiceId = item.saleId;
      this.takePaymentDto.datePaid = moment(Date.now()).toDate();
      this.takePaymentDto.parentId = this.selectedCompany.parentGuid;
      this.takePaymentDto.invoiceId = item.saleId;
      this.takePaymentLoan.getPaymentDueToday(this.takePaymentDto).subscribe( (data) => {
        console.log(data);
      //  console.log(moment(Date.now()).toDate());
        this.takePaymentDto.sumPaidToday = parseFloat(data.sumDueTOday.toFixed(2));
        this.takePaymentDto.todayNonAmortizedPaid = parseFloat(data.nonAmortizedPaymentDue.toFixed(2));
        this.takePaymentDto.todayDeferredDown = parseFloat(data.deferredBalance.toFixed(2));
        this.takePaymentDto.todayLateFee = parseFloat(this.numZero.toFixed(2));
        this.takePaymentDto.todayMisc = parseFloat(this.numZero.toFixed(2));
        this.takePaymentDto.todayLoanPaid = data.dueToday;
        // returned items
  //       sumPaidNoAmo: 260.4
  // sumLoanPaid: 6048.02
  // nonAmortizedPaymentDue: 26.04
  // nonAmortizedBlance: 364.6
  // todayPayoffAmortized: 4659.560063013699
  // totalDueTillToday: 11658.96
  // dueToday: 5110.160063013699
  // deferredBalance: 86
  // totalPayoff: 5110.16006301369
      }, (error) => {
      //  this.alertify.error(error);
      }, () => {
        this.takePayment(item); }
        );
    }

    loanDoubleClick(item){
      this.dueTodayLoad(item);
    }
    calcAllLoansCurrentDues() {
   //   console.log('calc currentdues reached in loanlist component');
      this.loans.forEach(element => {
        if (element.term > 0) {
          this.calculateCurrentDueDto.parentGuid = this.selectedCompany.parentGuid;
          this.calculateCurrentDueDto.loanId = element.amoLoanId;
          this.calculateCurrentDueDto.currentDueDate =  this.todayDate; // .format('dd/MM/yyyy hh:mm TT');
            this.loansDue.calculateCurrentDues(this.calculateCurrentDueDto)
            .subscribe(data => { console.log(data); }, error => {
             // this.alertify.error(error);
            }, () => {
            //  this.alertify.success('Loan Calculated ' + element.id);
            } );
        }
      });
    }
    selectedTypeChanged(e) {
      this.userParams.saleTypeId = '';
      this.userParams.filter = null;
      console.log(this.selectType);
      if (this.selectType === 0) { this.userParams.saleTypeId = undefined; }
      if (this.selectType === null) { this.userParams.saleTypeId = undefined; }
      if (e > 0) {this.userParams.saleTypeId = e; this.userParams.filter = 'SaleType'; }
      this.loadLoans();
    }
    selectedStatusChanged(e) {
      this.userParams.saleStatusId = '';
      this.userParams.filter = null;
      console.log(this.selectStatus);
      if (this.selectStatus === 0) { this.userParams.saleStatusId = undefined;    this.userParams.filter = undefined;}
      if (this.selectStatus === null) { this.userParams.saleStatusId = undefined; this.userParams.filter = undefined;}
      if (e > 0) {this.userParams.saleStatusId = e;  this.userParams.filter = 'SaleStatus'; }
      this.loadLoans();
    }
    updateDates(event) {
      // if (this.firstLoad) {
      this.userParams.startDate = event.startDate;
      this.userParams.endDate = event.endDate;
      this.userParams.monthOfYear = event.month;
      this.userParams.yearForDate = event.year;
      this.userParams.isAll = event.isAll;
      this.userParams.isFullYear = event.isFullYear;
      //   this.userParams.isDeleted = this.isDeleted;
      this.loadLoans();
      console.log('thisupdatedates hit');
    //  }
    }
    showNextDue() {
      this.userParams.filter = 'FilterNextDue';
      this.loadLoans();
    }
    showTransactionDate() {
        this.userParams.isAll = true;
        this.userParams.filter = 'FilterTransactionDate';
        this.loadLoans();
    }
    showFirstDue() {
      this.userParams.filter = 'FilterFirstDue';
      this.loadLoans();
    }
    showScheduledDate() {
      this.userParams.filter = 'FilterScheduldedDate';
      this.loadLoans();
    }
    deletePayment(e, a) {
      console.log(e);
      console.log(a);
      this.confirmService.confirm('Confirm delete payment', 'Are you sure?').subscribe(result => {
        if (result) {
      this.loanService.deletePayment(e.id).subscribe(data => {
        this.amoPayments = data;
      }, error => { this.alertify.error(); }, () => {this.alertify.success('Payment Updated'); });
    }
  });
    }
    findDetails(item: AmoLinqPayments) {
      this.amoPayments = [];
      console.log(item);
        this.loanService.retrievePayments(this.selectedCompany.parentGuid, item.amoLoanId).subscribe(data => {
          this.amoPayments = data;
          this.itemId = item.id;
          this.isExpandPayments = !this.isExpandPayments;
        //  this.alertify.success('Success');
          item.isExpanded = !item.isExpanded;
          console.log(data);
        }, error => {
       //   this.alertify.error(error);
         }, () => {
          this.alertify.success('Success');
          console.log(this.itemId );
        });
  
    //  item.isExpanded = !item.isExpanded;
      // this.filter(this.amoPayments.filter(f=> f.amoLoanId === item.amoLoanId));
     // return  item.amoPayments.filter(f => f.amoLoanId === item.amoLoanId);
  
    }

    takePayment(item)  {
      console.log(this.takePaymentDto);
      const initialState = {
        chartInvoiceTransaction: this.loans,
        takePaymentDto: this.takePaymentDto
      };
      this.bsModalRefAddPayment = this.modalService.show(AddPaymentModalComponent, {initialState, class: 'modal-md'});
        this.bsModalRefAddPayment.content.paymentEnetered.subscribe((values) => {
        //  console.log(values);
          this.takePaymentDto = values;
          this.takePaymentLoan.takePayment(values).subscribe(data => {
         //   this.chartInvoice = data.invoice;
            this.amoPayments = data.amoPayments;
            this.paymentRecieptDto = data.recieptFields;
            console.log(data);
            this.alertify.success('Payment Applied Successullfy.');
          }, error => {
        //    this.alertify.error(error);
          });
    });
    }
    editPayment(item, amoLoan)  {
      // console.log(this.takePaymentDto);
      console.log(amoLoan);
      const initialState = {
        chartInvoiceTransaction: this.loans,
        takePaymentDto: item
      };
      this.bsModalRefAddPayment = this.modalService.show(AddPaymentModalComponent, {initialState, class: 'modal-md'});

      this.bsModalRefAddPayment.content.paymentEnetered.subscribe((values) => {
        //  console.log(values);
          this.takePaymentDto = values;
          this.takePaymentDto.invoiceId = amoLoan.saleId;
          this.takePaymentLoan.editPayment(values, item.id).subscribe(data => {
         //   this.chartInvoice = data.invoice;
            this.amoPayments = data.amoPayments;
            this.alertify.success('Payment Applied Successullfy.');
          }, error => {
          //  this.alertify.error(error);
          });
    });
    }
    takePayments(item) {
      // loan/payments/:invoiceId/:parentGuid
      console.log('/loan/payments/' + item.id + '/' + this.selectedCompany.parentGuid);
      this.router.navigate(['/loan/payments/' + item.id + '/' + this.selectedCompany.parentGuid]);
      console.log(item);
    }
    loanDetails(item) {
      this.router.navigate(['/loan/payments/' + item.id + '/' + this.selectedCompany.parentGuid]);
    }
    pageChanged(event: any): void {
      this.pagination.currentPage = event.page;
      this.loadLoans();
    }
  sort(e) {
      console.log(e);
      this.isInvoiceNumberAscending = true;
      this.isCustomerAscending = true;
      this.isTransactionDateAscending = true;
      this.isAmountFinancedAscending = true;
      this.isFirstDueDateAscending = true;
      this.isNextDueDateAscending = true;
      this.isAmountDueAscending = true;
      this.isLastPaidDateAscending = true;
      this.isPaymentAscending = true;
      this.isPayoffAscending = true;
      this.isNextScheduledDateAscending = true;
      this.isAscending = !this.isAscending;
      this.userParams.isAscending = this.isAscending;
      this.userParams.itemsPerPage = 15;
      this.userParams.orderBy = e;
      this.loadLoans();
      if (e === 'isInvoiceNumberAscending') {this.isInvoiceNumberAscending = this.isAscending; }
      if (e === 'isPayoffAscending') {this.isPayoffAscending = this.isAscending; }
      if (e === 'isTransactionDateAscending') {this.isTransactionDateAscending = this.isAscending; }
      if (e === 'isAmountFinancedAscending') {this.isAmountFinancedAscending = this.isAscending; }
      if (e === 'isFirstDueDateAscending') {this.isFirstDueDateAscending = this.isAscending; }
      if (e === 'isNextDueDateAscending') {this.isNextDueDateAscending = this.isAscending; }
      if (e === 'isAmountDueAscending') {this.isAmountDueAscending = this.isAscending; }
      if (e === 'isLastPaidDateAscending') {this.isLastPaidDateAscending = this.isAscending; }
      if (e === 'isPaymentAscending') {this.isPaymentAscending = this.isAscending; }
      if (e === 'isCustomerAscending') {this.isCustomerAscending = this.isAscending; }
      if (e === 'isNextScheduledDateAscending') {this.isNextScheduledDateAscending = this.isAscending; }
  }

  loadLoans() {
    this.loanService.retrievePagedLoans(this.selectedCompany.parentGuid, this.pagination.currentPage,
      this.pagination.itemsPerPage, this.userParams)
    .subscribe((data: PaginatedResult<AmoLinqPayments[]>) => {
      this.loans = data.result;
      this.pagination = data.pagination;
      console.log(data.result);
    }, error => {
     // this.alertify.error(error);
    }, () => {
     // this.alertify.success('Loans Retrieved.');
    });
  }
  setSearchBy(e, event) {
      setTimeout(() => {
        console.log(event);
        console.log(e);
        this.userParams.searchBy = e;
        this.userParams.searchFor = event;
        this.loadLoans();
      }, 2000);
  }
  selectGrid(e) {
    this.isPaymentList = false;
    this.isLoanList = false;
    if (e === 'isLoanList') {  this.router.navigate(['/dashboardloans/loanlist/' + this.selectedCompany.parentGuid]); }
    if (e === 'isPaymentList') { this.router.navigate(['/dashboardloans/paymentlist/' + this.selectedCompany.parentGuid]); }
  }
  filter(e) {
      console.log(e);
      this.userParams.filter = e;
      this.loadLoans();
  }
  exportAsXLSX(): void {
      this.pagination.itemsPerPage = 1000000;
      this.loanService.retrievePagedLoans(this.selectedCompany.parentGuid, this.pagination.currentPage,
        this.pagination.itemsPerPage, this.userParams)
      .subscribe((data: PaginatedResult<AmoLinqPayments[]>) => {
        this.excelService.exportAsExcelFile(data.result, 'Loans');
        this.pagination.itemsPerPage = 15;
      }, error => { 
        this.alertify.error(error);
       }, () => {
        this.alertify.success('Your Export is Ready');
      } );
  }
   async printDiv() {
    await this.hideDiv().then(() => {
     setTimeout(() => {
       this.print();
     }, 2000);
      });
   }
   async hideDiv() {
     this.isPrint = true;
   }
   async print() {
     window.frames['print_frame'].document.body.innerHTML = document.getElementById('printableTable').innerHTML;
     window.frames['print_frame'].window.focus();
     window.frames['print_frame'].window.print();
     this.isPrint = false;
   }

   loanClick(e) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.loans.length; i++) {
      if (e === this.loans[i]) { e.selected = true; }
      else {this.loans[i].selected = false; }
    }
   e.selected = true;
   console.log(e.selected);
     this.isAddPayment = true;
     this.loan = e;
     this.loanSelected(e);
   }
   addPayment() {
    console.log(this.loan);
    this.dueTodayLoad(this.loan);
   }
  }
