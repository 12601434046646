<div class="container mt-5">
  <div><i class="fa fa-check-circle fa-5x" style="color: green;"></i></div>
  <h2>Thank you. We emailed you a comfirmation link.</h2>
  <h4>
    Please open email seek the email. 
    It may be in your spam folder or call our support office if having problems.
    You can Reach us at 321-292-9429.
  </h4>
  <!-- <button routerLink="/orders/" class="btn btn-outline-success">View your order</button>
  <button routerLink="/orders" class="btn btn-outline-success">View your orders</button> -->

</div>