import { Component, OnInit } from '@angular/core';
import { VehicleMileageCodes } from 'src/app/_models/VehicleMileageCodes';
import { ActivatedRoute } from '@angular/router';

import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { VehicleService } from 'src/app/_services/vehicleserv/vehicle.service';
import { VehicleMileageCodesModalComponent } from './vehicle-mileage-codes-modal/vehicle-mileage-codes-modal.component';
import { AuthService } from 'src/app/_services/auth.service';
import { VehicleMileagecodesService } from 'src/app/_services/vehicleserv/vehicle-mileagecodes.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-vehicle-mileage-codes-list',
  templateUrl: './vehicle-mileage-codes-list.component.html',
  styleUrls: ['./vehicle-mileage-codes-list.component.css']
})
export class VehicleMileageCodesListComponent implements OnInit {
  mileageCodes: VehicleMileageCodes[];
  selectedCompany1: UserAssignedToCompany;
  isDelete: boolean;
  bsModalRef: any;

  constructor(private route: ActivatedRoute,
    private modalService: BsModalService, private vehicleMileageCodeService: VehicleMileagecodesService, private authService: AuthService,
    private alertify: AlertifyService) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      const newLocal = 'mileageCodes';
      this.mileageCodes = data[newLocal];
    });
  }

  delete(e) {
    console.log(e);
    this.isDelete = false;
    if (e.deletedDate == null) {
      this.isDelete = true;
    }
    this.vehicleMileageCodeService.delete(e.id, this.isDelete).subscribe(data => {
      console.log(data);
      this.mileageCodes = data;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Mileage Code Updated');
    });
}

edit(e: VehicleMileageCodes) {
  const initialState = {
    vehicleMileageCodes:  e
  };
  this.bsModalRef = this.modalService.show(VehicleMileageCodesModalComponent, { initialState });
  this.bsModalRef.content.update.subscribe((values) => {
    e.value = values.value;
    this.vehicleMileageCodeService.edit(this.selectedCompany1.parentGuid, e).subscribe(data => {
      this.mileageCodes = data;
    }, error => {this.alertify.error(error); }, () => {this.alertify.success('Mileage Code Updated'); });
  });
}

add() {
  this.bsModalRef = this.modalService.show(VehicleMileageCodesModalComponent);
  this.bsModalRef.content.update.subscribe((values) => {
    this.vehicleMileageCodeService.add(values.value).subscribe(data => {
      this.mileageCodes = data;
    }, error => {this.alertify.error(error); }, () => {this.alertify.success('Mileage Codes Added'); });
  });
}
}

