<div  id="addPaymentModal" role="dialog" tabindex="-1"> 
  <!-- this is the ADD PAYMENT NOT VIEW PAYMENTS MODAL-->
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <img src="assets/images/payment-icon.png">
          <h4 class="modal-title">Payment</h4>
          <button type="button" class="close" (click)="close()" onclick="$('#addPaymentModal').modal('hide');">&times;</button>
        </div>
        <div class="modal-body payments-form">
          <div class="payments-list-grid">
            <div class="row">
              <div class="col-md-4 cstm-col">
                <!-- <div class="rw">
                  <label>Payment #</label>
                  <input type="text" name="" value="1" class="cstm-input">
                </div> -->
                <div class="rw">
                  <label>Receipt #</label>
                  <input type="text" name="" 
                  [ngModel]="amoPayment?.recieptNumber || 0.00"
                  (ngModel)="amoPayment.recieptNumber = $event"
                  (ngModelChange) = "amoPayment.recieptNumber = $event; "
                  class="cstm-input">
                </div>
                <div class="rw">
                  <!-- value="{{takePaymentDto?.datePaid | date: 'MM/dd/yyyy'}}"  -->
                  <label>Date Paid</label>
                  <input type="date" name="datePaid" 
                  [ngModel]="amoPayment?.datePaid | date: 'yyyy-MM-dd'"
                  (ngModel)="amoPayment.datePaid = $event"
                  (change)="updateCalcs()"
                  (ngModelChange) = "amoPayment.datePaid = $event; "
                  class="cstm-input">
                  <button class="cstm-btn">...</button>
                </div>
              </div>
              <div class="col-md-4 cstm-col">
              
                <div class="rw">
                  <label>Last Date</label>
                  <input type="date" name="" 
                  value="{{loan?.lastPaidDate | date:'yyyy-MM-dd'}}" class="cstm-input" disabled="">
                </div>
                <!-- <div class="rw">
                  <label>Date Next</label>
                  <input type="text" name="" value="{{loan?.nextDueDate | date:'MM/dd/yyyy'}}" class="cstm-input">
                  <button class="cstm-btn">...</button>
                </div> -->
              </div>
              <div class="col-md-4 cstm-col last-grid-col">
                <div class="cstm-rw">
                  <label >Scheduled Pmt</label>
                  <input type="number" 
                  style="max-width: 80px;" 
                  width="50px" name="" 
                  
                  value="{{sale?.payment | number:'1.2-2'}}" 
                  class="cstm-inputsm without-border"
                  >
                </div>
                <!-- <div class="rw">
                  <label>Taxes</label>
                  <input type="text" name="" value="{{loan?.payment | number:'1.2-2'}}" class="cstm-input without-border">
                </div> -->
                <div class="cstm-rw">
                  <label>Misc Charges</label>
                  <input type="text" name="" style="max-width: 80px;" value="{{loan?.payment | number:'1.2-2'}}" class="cstm-inputsm without-border">
                </div>
                <div class="cstm-rw">
                  <label>PickUps</label>
                  <input type="text" name="" style="max-width: 80px;" value="{{loan?.payment | number:'1.2-2'}}" class="cstm-inputsm without-border">
                </div>
              </div>
            </div>
          </div>
          <div class="payments-tabs">
            <div class="inner">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">	<img src="assets/images/payment-icon.png">
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                    <img src="assets/images/notes-icon-1.jpg">
                  </a>
                </li>
              </ul>
              <div class="tab-content" id="paymentTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div class="inner">
                    <div class="row">
                      <div class="col-md-4 cstm-col left-grid">
                        <div class="inner">
                          <h2 class="title">Today's</h2>
                          <div class="row">
                            <div class="col-md-12 custom-col">
                              <div class="cstm-rw">
                                <label class="amt-label">Today</label>
                                <!-- value="{{takePaymentDto?.sumPaidToday | number:'1.2-2'}}"  -->
                                <input 
                                type="number" name="" 
                              
                                class="cstm-input amt-input"
                                [ngModel]="amoPayment?.todayLoanPaid || 0.00"
                                (ngModel)="amoPayment.todayLoanPaid = $event"
                                (ngModelChange) = "amoPayment.todayLoanPaid = $event; "
                                (blur) = "onChange($event)"
                                >
                                <button class="amt-btn">Pay <span>Off</span></button>
                              </div>
                              <!-- <div class="cstm-rw">
                                <label>Principal</label>
                                <input type="text" name="" value="{{takePaymentDto?.sumPaidToday | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Interest</label>
                                <input type="text" name="" value="{{takePaymentDto?.sumPaidToday | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Taxes</label>
                                <input type="text" name="" value="{{takePaymentDto?.sumPaidToday | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div> -->
                              <div class="cstm-rw">
                                <label>Late Fee</label>
                                <input type="number" name="" 
                                [ngModel]="amoPayment?.todayLateFee || 0.00"
                                (ngModel)="amoPayment.todayLateFee = $event"
                                (ngModelChange) = "amoPayment.todayLateFee = $event; "
                                (blur) = "onChange($event)"
                                class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Misc</label>
                                <input type="number" name="" 
                                [ngModel]="amoPayment?.todayMisc || 0.00"
                                (ngModel)="amoPayment.todayMisc = $event"
                                (ngModelChange) = "amoPayment.todayMisc = $event; "
                                (blur) = "onChange($event)"
                                class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>PickUps</label>
                                <input type="number" name=""
                                [ngModel]="amoPayment?.todayDeferredDown || 0.00"
                                (ngModel)="amoPayment.todayDeferredDown = $event"
                                (ngModelChange) = "amoPayment.todayDeferredDown = $event; "
                                (blur) = "onChange($event)"
                                 class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Amount Paid</label>
                                <input type="text" name="" 
                                [ngModel]="amoPayment?.totalSumPaid || 0.00"
                                (ngModel)="amoPayment.totalSumPaid = $event"
                                (ngModelChange) = "amoPayment.totalSumPaid = $event; "
                                (blur) = "onChange($event)" 
                                class="cstm-input" 
                                disabled="">
                              </div>
                              <!-- <div class="cstm-rw">
                                <label>Pay Off</label>
                                <input type="text" name="" value="{{takePaymentDto?.sumPaidToday | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div> -->
                              <!-- <div class="cstm-rw">
                                <label>Balance</label>
                                <input type="text" name="" value="{{takePaymentDto?.sumPaidToday | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div> -->
                              <div class="cstm-rw">
                                <label style="color: green;">Method Required</label>
  
                              </div>
                              <div class="cstm-rw">
                                <select tabindex="-1" class="cstm-select" 
                                #paymentType="ngModel"  
                                name="paymentTypesId"  
                                [(ngModel)]="amoPayment.paymentTypesId"
                                (ngModelChange)="paymentTypeSelected($event)" >
                                <option style="color: #bb400f;" [value]="-1">None</option>
                                <option *ngFor="let item of paymentTypes"     [ngValue]="item.id">{{item?.value}}</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 cstm-col center-grid">
                        <div class="inner">
                          <h2 class="title">Total Paid</h2>
                          <div class="row">
                            <div class="col-md-12 custom-col">
                              <div class="cstm-rw">
                                <label>Total</label>
                                <input type="text" name="" 
                                value="{{loan?.totalLoanPaid | number:'1.2-2'}}" 
                                class="cstm-input" disabled="">
                              </div>
                              <!-- <div class="cstm-rw">
                                <label>Principal</label>
                                <input type="text" name="" value="{{takePaymentDto?.sumPaidToday | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div> -->
                              <!-- <div class="cstm-rw">
                                <label>Interest</label>
                                <input type="text" name="" value="{{takePaymentDto?.sumPaidToday | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Taxes</label>
                                <input type="text" name="" value="{{takePaymentDto?.sumPaidToday | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div> -->
                              <div class="cstm-rw">
                                <label>Late Fee</label>
                                <input type="text" name="" 
                                value="{{loan?.totalLateFeePaid | number:'1.2-2'}}" 
                                class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Misc</label>
                                <input type="text" name="" 
                                value="{{loan?.totalMiscPaid | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>PickUps</label>
                                <input type="text" name="" 
                                value="{{loan?.totalDeferredDownPaid | number:'1.2-2'}}" 
                                class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Amount Paid</label>
                                <input type="text" name=" " value="{{loan?.totalSumPaid | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Previous Pay Off</label>
                                <input type="text" name="" value="{{loan?.payoffToday | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <!-- <div class="cstm-rw">
                                <label>Balance</label>
                                <input type="text" name="" value="{{loan?.totalSumPaid | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 cstm-col right-grid">
                        <div class="inner">
                          <!-- <h2 class="title">Balance (Unpaid)</h2> -->
                          <div class="row">
                            <div class="col-md-12 custom-col">
                              <!-- <div class="cstm-rw">
                                <label>Interest</label>
                                <input type="text" name="" value="{{loan?.totalSumPaid | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Taxes</label>
                                <input type="text" name="" value="{{loan?.totalSumPaid | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Misc</label>
                                <input type="text" name="" value="{{loan?.totalSumPaid | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>PickUps</label>
                                <input type="text" name="" value="{{loan?.totalSumPaid | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div> -->
                              <!-- <div class="cstm-rw">
                                <label>Today's Unpaid Interest</label>
                                <input type="text" name="" value="{{loan?.totalSumPaid | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">Pick Ups / Misc Charges</div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">Customer / Vehicle / Notes</div>
              </div>
            </div>
          </div>
          <div class="payments-foot">
            <div class="row">
              <div class="col-md-6 left-col">
                <div class="inner">
                  <h3 class="title">Future Payoff</h3>
                  <span>After</span>
                  <input type="text" name="" value="10" class="af-day-input">
                  <span>days</span>
                  <span class="pay-off">Pay Off</span>
                  <input type="text" name="" value="120.00" disabled="" class="payoff-input">
                </div>
              </div>
              <div class="col-md-6 right-col">
                <button class="cstm-btn">Reset</button>
                <button *ngIf="amoPayment?.paymentTypesId > 0" (click)="submit()" class="cstm-btn">OK</button>
                <button (click)="close()" class="cstm-btn">Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
   </div>