

<div class="modal-dialog" id="myModal1">
  <div class="modal-content">
    <!-- <div class="modal-header">
      <img src="assets/images/payment-icon.png">
      <h4 class="modal-title">Payment</h4>
      <button type="button" class="close" data-dismiss="modal">&times;</button>
    </div> -->
<div class="customer-form-sec" style="min-width: 1100px !important;">
  <div class="container-fluid" style="width: 100% !important;">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div class="payments-form">
          <div class="payment-first-rw" [hidden]="isLoaded">
          <p style="color: green;"> ...We are Caclulcating Current Dues ...</p>
            </div>
          <div class="payment-first-rw" [hidden]="!isLoaded">
            <div class="input-sec" >
              <span>Account</span>
              <input type="text" name="" value="{{sale?.acct}}" class="cstm-input">
              <div>Name: &nbsp; {{ sale?.buyer?.firstName}} &nbsp; {{sale?.buyer?.lastName}}</div>
            </div>
            <div class="payment-btn-sec">
              <ul>
                <li><button  (click)="takePayment()"  class="cstm-btn" data-toggle="modal" data-target="#addPaymentModal">Add Payment</button></li>
                <li><button (click)="editPaymentBtnClick()" *ngIf="isEditPayment"  class="cstm-btn">Edit Payment</button></li>
                <li><button (click)= "deletePayment()"  *ngIf="isEditPayment" class="cstm-btn">Delete Payment</button></li>
                <li  *ngIf="isEditPayment"  class="recept-sec">
                  <button (click)="printReceipt()" class="cstm-btn">
                    <img class="btn-icon" src="assets/images/recept-icon.png">
                    <div class="txt-sec">
                      <span>Receipt</span>
                      <span class="form-txt">Form</span>	
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="payments-tabs">
            <div class="inner">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="javascript:void(0);" role="tab" aria-controls="home" aria-selected="true">Deal Details</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="javascript:void(0);" role="tab" aria-controls="profile" aria-selected="false">Pick Ups / Misc Charges</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="contact-tab" data-toggle="tab" href="javascript:void(0);" role="tab" aria-controls="contact" aria-selected="false">Customer / Vehicle / Notes</a>
                </li> -->
              </ul>
              <div class="tab-content" id="paymentTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div class="inner">
                    <div class="row">
                      <div class="col-md-6 cstm-col left-grid">
                        <div class="inner">
                          <h2 class="title">Account Details</h2>
                          <div class="row">
                            <div class="col-md-6 custom-col">
                              <div class="cstm-rw">
                                <label>Principal</label>
                                <input type="text" name="" value="{{ sale?.amountFinanced | number:'1.2-2' }}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Cash Down</label>
                                <input type="text" name="" value="{{ sale?.cashDown | number:'1.2-2' }}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>By Installment</label>
                                <input type="text" name="" value="{{sale?.amoInstallments?.value}}" class="cstm-input" disabled="">
                              
                              </div>
                              <div class="cstm-rw">
                                <label>APR</label>
                                <input type="text" name="" value="{{loan?.apr | number:'1.4-4'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Term</label>
                                <input type="text" name="" value="{{loan?.term}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>DTaxable Amt</label>
                                <input type="text" name="" value="{{loan?.nonAmortized | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>DTax</label>
                                <input type="text" name="" value="{{loan?.nonAmortized | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                            </div>
                            <div class="col-md-6 custom-col">
                              <div class="cstm-rw">
                                <label>Sold Date</label>
                                <input type="text" name="" value="{{loan?.loanStartDate  | date: 'shortDate'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>First Due Date</label>
                                <input type="text" name="" value="{{sale?.firstDueDate | date:'shortDate'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Payments</label>
                                <input type="text" name="" value="{{loan?.payment | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Interest</label>
                                <input type="text" name="" value="{{loan?.interest | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Deffered Tax</label>
                                <input type="text" name="" value="{{loan?.nonAmortized | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Payment</label>
                                <input type="text" name="" value="{{loan?.payment | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                              <div class="cstm-rw">
                                <label>Tax On Down</label>
                                <input type="text" name="" value="{{loan?.paymentNonAmortized | number:'1.2-2'}}" class="cstm-input" disabled="">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><!-- /.left-grid -->
                      <div class="col-md-6 cstm-col right-grid">
                        <div class="inner">
                          <h2 class="title">Total Paid</h2>
                          <div class="row">
                            <div class="col-md-6 custom-col">
                              <div class="cstm-rw">
                                <label>Term Due</label>
                                <input type="text" name="" value="{{countPaymentsDueTillToday | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Term Paid</label>
                                <input type="text" name="" value="{{loan?.termPaid | number:'1.5-5'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Term Past Due</label>
                                <input type="text" name="" value="{{countPaymentsDueTillToday - loan.termPaid | number:'1.5-5'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Principal Paid</label>
                                <input type="text" name="" value="{{loan?.totalPrinciplePaid | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Interest Paid</label>
                                <input type="text" name="" value="{{loan?.totalInterestPaid | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Total Misc</label>
                                <input type="text" name="" value="{{loan?.totalMiscPaid | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Total Deferred</label>
                                <input type="text" name="" value="{{loan?.totalDeferredDownPaid | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Late Fees</label>
                                <input type="text" name="" value="{{loan?.totalLateFeePaid | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Total Due Paid</label>
                                <input type="text" name="" value="{{loan?.totalSumPaid | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Total Pmts Paid</label>
                                <input type="text" name="" value="{{loan?.totalLoanPaid | number:'1.2-2'}}" class="cstm-input">
                              </div>
                            </div>
                            <div class="col-md-6 custom-col">
                              <div class="cstm-rw">
                                <label>Payoff</label>
                                <input type="text" name="" value="{{loan?.principleBalance | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Balance</label>
                                <input type="text" name="" value="{{loan?.decliningBalance | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Last Paid Date</label>
                                <input type="text" name="" value="{{loan?.lastPaidDate | date: 'shortDate'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Current Due</label>
                                <input type="text" name="" value="{{loan?.currentDue | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Next Due Date</label>
                                <input type="text" name="" value= "{{dueTodayasOfTodayDate| date:'shortDate'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Total Late Fee</label>
                                <input type="text" name="" value="{{loan?.totalLateFeePaid | number:'1.2-2'}}" class="cstm-input">
                              </div>
                              <div class="cstm-rw">
                                <label>Unpaid / Balance</label>
                                <input type="text" name="" value="{{loan?.totalNonPaidInterestPastDue | number:'1.2-2'}}" class="cstm-input">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div><!-- /.right-grid -->
                    </div>
                  </div><!-- /.inner --> 
                </div> 
                <!-- <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">Pick Ups / Misc Charges</div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">Customer / Vehicle / Notes</div> -->
              </div>
            </div>
          </div><!-- /.payments-tabs -->
          <div class="payment-table">
            <div class="inner-table">
              <!-- <table border="1" bordercolor="#ccc">
                <tr>
                  <th>R #</th>
                  <th>Date</th>
                  <th>Amount Paid</th>
                  <th>Principal Paid</th>
                  <th>Interest Paid</th>
                  <th>Tax Paid</th>
                  <th>Late Fee</th>
                  <th>Total Pmts</th>
                  <th>Total Principal</th>
                  <th>Total Interest</th>
                  <th>Demo Heading 1</th>
                  <th>Demo Heading 2</th>
                  <th>Demo Heading 3</th>
                  <th>Demo Heading 4</th>
                  <th>Demo Heading 5</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </table> -->
              <table style="overflow: auto !important;" border="1" bordercolor="#ccc" class="tablescroll">
                <tr>
                  <th>PaymentNumber:</th>
                  <th>Date:</th>
                  <th>Payment Paid</th>
                  <th>Total Loan Payments</th>
                  <th>Principal Paid</th>
                  <th>Principle Balance</th>
                  <th>Total Principle</th>
                  <th>Today Interest Earned</th>
                  <th>Today Unpaid Interest</th>
                  <th>Unpaid Applied</th>
                  <th>Interest Paid</th>
                  <th>Total Interest Paid</th>
                  <th>Total Earned Interest</th>
                  <th>Non Amortized</th>
                  <th>Total Non Amortized</th>
                  <th>Late Fee</th>
                  <th>Total Late Fee</th>
                  <th>Misc Today</th>
                  <th>Total Misc</th>
                  <th>Deferred Down</th>
                  <th>TOtal Deferred</th>
                  <th>Total Today</th>
                  <th>Total Paid</th>
                  <th>Total Unpaid Interest</th>
                  <th>DSL</th>
                </tr>
                <tr style="max-height: 200px;"  [ngClass]="{highlight: item.selected ===true }" (click) = "setActiveRow(item)"  (dblclick) = "editPayment(item)" 
                *ngFor="let item of amoPayments | orderBy: '+paymentNumber'">
                  <td>{{item?.paymentNumber}}</td>
                  <td>{{item?.datePaid | date: 'shortDate'}}</td>
                  <td>{{item?.todayLoanPaid | number:'1.2-2'}}</td>
                  <td>{{item?.totalLoanPaid | number:'1.2-2'}}</td>
                  <td>{{item?.todayPrinciple | number:'1.2-2'}}</td>
                  <td>{{item?.principleBalance | number:'1.2-2'}}</td>
                  <td>{{item?.totalPrinciplePaid | number:'1.2-2'}}</td>
                  <td>{{item?.todayInterestDue | number:'1.2-2'}}</td>
                  <td>{{item?.todayInterestUnpaid | number:'1.2-2'}}</td>
                  <td>{{item?.todayInterestUnpaidApplied | number:'1.2-2'}}</td>
                  <td>{{item?.todayInterest | number:'1.2-2'}}</td>
                  <td>{{item?.totalInterestPaid | number:'1.2-2'}}</td>
                  <td>{{item?.totalInterestDue | number:'1.2-2'}}</td>
                  
                  <td>{{item?.todayNonAmortizedPaid | number:'1.2-2'}}</td>
                  <td>{{item?.totalNonAmortizedPaid | number:'1.2-2'}}</td>
                  <td>{{item?.todayLateFee | number:'1.2-2'}}</td>
                  <td>{{item?.totalLateFeePaid | number:'1.2-2'}}</td>
                  <td>{{item?.todayMisc | number:'1.2-2'}}</td>
                  <td>{{item?.totalMisc | number:'1.2-2'}}</td>
                  <td>{{item?.todayDeferredDown | number:'1.2-2'}}</td>
                  <td>{{item?.totalDeferredDown | number:'1.2-2'}}</td>
                  <td>{{item?.sumPaidToday | number:'1.2-2'}}</td>
                  <td>{{item?.totalSumPaid | number:'1.2-2'}}</td>
                  
                  <td>{{item?.totalInterestUnpaid | number:'1.2-2'}}</td>
                  <td>{{item?.dsl | number:'1.2-2'}}</td>
                 
                </tr>
                
              </table>
            </div><!-- /.inner-table -->
          </div><!-- /.payment-table -->
        </div>
      </div>
     
    </div>
  </div>
</div>
</div>
</div>

<!-- 
<div class="modal fade lg" id="addPaymentModal" role="dialog" tabindex="-1">  -->
  <!-- <app-loan-addpayment></app-loan-addpayment> -->
  <!-- </div> -->