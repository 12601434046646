import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { VehicleBodyClassTypes } from '../_models/VehicleBodyClassTypes';
import { VehicleBodytypeCategoriesService } from '../_services/vehicleserv/vehicle-bodytype-categories.service';
@Injectable({
    providedIn: 'root'  // **** ADD THIS LINE ****
  })
export class VehicleBodyClassTypesResolver implements Resolve<VehicleBodyClassTypes[]> {
    constructor( private vehicleBodyTypesService: VehicleBodytypeCategoriesService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<VehicleBodyClassTypes[]> {
        return this.vehicleBodyTypesService.getLisResolverView(route.params.parentGuid).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving vehicle Body Types.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
