<br><br>
<div style="margin-top: -30px;" class="container-fluid customer-tabs-sec">
    <ul class="nav nav-tabs">
      <li routerLinkActive="active">
        <a
          data-toggle="tab"  [ngClass]="{'active show' : isEmployee}" 
          routerLinkActive="active show"
          (click)="sort('isQuickBooks')"
          >QuickBooks</a
        >
      </li>
      <li routerLinkActive="active">
        <a
          data-toggle="tab"  [ngClass]="{'active show' : isEmployee}" 
          routerLinkActive="active show"
          (click)="filter('Employee')"
          >Employees</a
        >
      </li>
      <li routerLinkActive="active">
        <a
          data-toggle="tab"  [ngClass]="{'active show' : isCustomer}" 
          routerLinkActive="active show"
          (click)="filter('Customer')"
          >Customers</a
        >
      </li>
      <li routerLinkActive="active">
        <a [ngClass]="{'active show' : isBirthday}" 
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('Birthday')"
          >Birthday Near</a
        >
      </li>
      <li routerLinkActive="active">
        <a [ngClass]="{'active show' : isWebsiteLead}" 
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('WebsiteLead')"
          >Website Leads</a
        >
      </li>
      <li routerLinkActive="active">
        <a [ngClass]="{'active show' : isAll}" 
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('All')"
          >All</a
        >
      </li>  
      <li routerLinkActive="active">
        <a [ngClass]="{'active show' : hasLoan}" 
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('HasLoan')"
          >Has Loan</a
        >
      </li>  
      <div class="d-flex justify-content-right">
          <pagination
        [boundaryLinks]="true"
        pageBtnClass = "nav nav-tabs"
            [(ngModel)]="pagination.currentPage"
            [totalItems]="pagination.totalItems"
            (pageChanged)="pageChanged($event)"
            [itemsPerPage]="pagination.itemsPerPage"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;"
          >
          </pagination>
        </div>
  
  </ul>  
  </div>
  <!-- <app-date-filter *ngIf = "isFilterDates"></app-date-filter> -->
  <div class="customer-form-sec">
    <div class="container-fluid">
      <div class="row">
        <div class="form-sec tab-content col-md-11">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-row account-payable-row">          
                  
                  <div class="row">
                    <div class="col-md-12 table-sec">
                      <div id="printableTable">
                      <table border="1" borderColor="#ccc">
                        <tr>
                          <th>ID</th>
                          <th (click)="sort('lastName')">LastName &nbsp;
                              <i [ngClass]="isLastNameAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('firstName')">FirstName&nbsp;
                              <i [ngClass]="isFirstNameAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('phone')">Phone&nbsp;
                              
                          </th>
                          <th (click)="sort('city')">City&nbsp;
                              <i [ngClass]="isCityAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('state')">State&nbsp;
                              <i [ngClass]="isStateAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('bithday')">Birthday&nbsp;
                              <i [ngClass]="isBirthDateAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('insExpires')">Insurance Expire&nbsp;
                            <i [ngClass]="isInsuranceExpire ? 'up': 'down'"></i>
                        </th>
                          <th (click)="sort('email')">Email&nbsp;
                              <i [ngClass]="isEmailAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('personType')">Person Type&nbsp;
                              <i [ngClass]="isCustomerAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('weblead')">Website Lead&nbsp;
                            <i [ngClass]="isWebleadAscending ? 'up': 'down'"></i>
                        </th>
                        <th (click)="sort('weblead')">Loan?&nbsp;
                          <i [ngClass]="isWebleadAscending ? 'up': 'down'"></i>
                      </th>
                          <th></th>
                        </tr>
                        <tr  >
                          <td></td>
                          <td style="width: 76px"><input (keyup)="setSearchBy('searchLastName', $event.target.value)" >
                          <td style="width: 76px"><input (keyup)="setSearchBy('searchFirstName', $event.target.value)" >
                          </td>  
                          <td><input style="width: 55px;" (keyup)="setSearchBy('searchPhone', $event.target.value)">
                          </td>  
                          <td><input style="width: 96px;" (keyup)="setSearchBy('searchCity', $event.target.value)"></td>  
                          <td><input style="width: 96px;" (keyup)="setSearchBy('searchState', $event.target.value)"></td>  
                          <td></td>  
                          <td></td>  
                          <td><input style="width: 96px;" (keyup)="setSearchBy('searchEmail', $event.target.value)"></td>  
                          <td></td>  
                          <td></td>  
                        </tr>
                        <tr (click)="setLoanPerson(item)"
                        [ngClass]="{highlight: item.selected ===true }" 
                        class="td-row" *ngFor="let item of persons">
                          <td>{{ item.id }}</td>
                          <td>{{ item?.lastName }}</td>
                          <td>{{ item?.firstName }}</td>
                          <td>{{ item?.phone }}</td>
                          <td>{{ item?.city }}</td>
  
                          <td>{{ item?.state }}</td>
                          <td>{{ item?.dateOfBirth | date: "shortDate" }}</td>
                          <td>{{ item?.insuranceExpires | date: "shortDate" }}</td>
                          <td>{{ item?.email }}</td>
                          <td>{{item?.personType}}</td>
                          <td>{{item?.isWebsiteLead}}</td>
                          <td *ngIf="item.amoLoans.length > 0"><i style="color: green;" class="fa fa-check"></i></td>
                          <td *ngIf="item.amoLoans.length == 0">no</td>
                          <td>
                            <button
                              class="btn btn-outline-success"
                              (click)="editPerson(item)"
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                      </table>
                      </div>
                    </div>
                    <!-- /.table-sec -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.row -->
          </div>
        </div>
        <div class="customer-sidebar sidebar-with-btns col-md-1">
          <div class="inner-sidebar">
            <ul>
              <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->
              <li>
                <button (click) = "addPerson()"  class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-1.png" />
                  <span>Add Person</span>
                </button>
              </li>
              <li *appHasRole="['Admin']">
                <button class="cstm-btn" (click) = "exportAsXLSX()">
                  <img src="assets/images/icons8-microsoft-excel-48.png" />
                  <span>Export Excel</span>
                </button>
              </li>
              <li>
                <button (click) = "printDiv()" class="cstm-btn">
                  <img src="assets/images/ap-icon-6.png" />
                  <span>Print Report</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>