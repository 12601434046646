import { Component, Input, OnInit } from '@angular/core';
import { PdfFormTypesCategory } from 'src/app/_models/PDFentites/PdfFormTypesCategory';
import { PdfFormTypes } from 'src/app/_models/PdfFormTypes';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';

@Component({
  selector: 'app-pdfformtypecreate',
  templateUrl: './pdfformtypecreate.component.html',
  styleUrls: ['./pdfformtypecreate.component.scss']
})
export class PdfformtypecreateComponent implements OnInit {
  @Input() pdfFormTypesCategorys = [] as PdfFormTypesCategory[];
  isNewFormTypeClick = false;
  isNewFormTypeClickLabel = 'Create Form Type.';
  id: any;
  formTypes = [] as PdfFormTypes[];
  selectedCompany1: UserAssignedToCompany;
  constructor(private pdfService: PdfService, private authService: AuthService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
  }
  createFormType() {
    this.isNewFormTypeClick = !this.isNewFormTypeClick;
    if (this.isNewFormTypeClick) { this.isNewFormTypeClickLabel = 'Cancel'; } else { this.isNewFormTypeClickLabel = 'Create Form Type.'; }
    }

    formTypeCategorySelected(typeId: number) {
      //  console.log(typeId);
        this.pdfService.getPdfFormTypeListByCategoryId(this.selectedCompany1.parentGuid, typeId).subscribe(data => {
          console.log(data);
          this.formTypes = data;
        });
      }
}
