<div class="text-center mt-3">
    <h2>{{predicate === 'liked' ? 'Members I like' : 'Members who like me'}}</h2>
</div>

<div class="container mt-3">
    <div class="row">
        <div class="btn-group" name="predicate">
            <button class="btn btn-primary" btnRadio="liked" 
                [(ngModel)]="predicate" (click)="loadLikes()">Members I like</button>
            <button class="btn btn-primary" btnRadio="likedBy" 
                [(ngModel)]="predicate" (click)="loadLikes()">Members who like me</button>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-2" *ngFor="let member of members">
            <app-member-card [member]="member"></app-member-card>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center" *ngIf="pagination">
    <pagination 
        [boundaryLinks]="true" 
        [totalItems]="pagination.totalItems"
        [itemsPerPage]="pagination.itemsPerPage"
        [(ngModel)]="pagination.currentPage"
        (pageChanged)="pageChanged($event)"
        previousText="&lsaquo;" 
        nextText="&rsaquo;" 
        firstText="&laquo;" 
        lastText="&raquo;">
</pagination>
</div>