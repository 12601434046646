import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PersonDataService } from '../_services/personinfo/person-data.service';
import { PersonLinqResultToReturnDto } from '../_models/DTOS/PersonLinqResultToReturnDto';


@Injectable({
    providedIn: 'root'
})
export class PersonLinqResultToReturnDtoResolver implements Resolve<PersonLinqResultToReturnDto[]> {
    pageNumber = 1;
    pageSize = 10;
    constructor(private personService: PersonDataService, private router: Router,
        private alertify: AlertifyService) {}
    resolve(route: ActivatedRouteSnapshot): Observable<PersonLinqResultToReturnDto[]> {
        const newLocal = 'parentGuid';
     //   console.log(route.params['parentGuid']);
        return this.personService.getCustomerLinqList(route.params[newLocal], this.pageNumber, this.pageSize).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving customer list.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
