import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../_services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { NavigationEnd, Router } from '@angular/router';
import { AccountService } from '../_services/account.service';
import { filter } from 'rxjs/operators';
import { UserAssignedToCompany } from '../_models/userAssignedToCompany';
import { FeatureRequestService } from '../_services/Featurerequestservices/featurerequest.service';
import { FeatureRequest } from '../_models/featurereqeustentities/featurerequest';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FeatureCreateComponent } from './feature-create/feature-create.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  registerMode = true;
  isVideo = false;
  isRoute: string;
  isSales = false;
  photoUrl: string;
  isContact = false;
  isFirstLoad = true;
  selectedCompany1 = {} as UserAssignedToCompany;
  userAssignedToCompany = [] as UserAssignedToCompany[];
  user: any;
  bsModalRef: any;
  baseUrl = 'https://www.youtube.com/embed/';
  featureLists = [] as FeatureRequest[];
  feature = {} as FeatureRequest;
  
  constructor(private authService: AuthService,
    private alertify: ToastrService,
    private bsModal: BsModalService,
    private featureRequestService: FeatureRequestService,
    private router: Router, private accountService: AccountService) { }

  ngOnInit(): void {
    console.log(this.loggedIn());
    const token = localStorage.getItem('token');
    this.isSales = true;
  //  this.authService.chartItem.subscribe(data => this.chartItem = data );
    if (this.loggedIn()) {
    this.authService.currentPhotoUrl.subscribe(photoUrl => this.photoUrl = photoUrl);
    this.authService.currentUserAssignedCompanies.subscribe(data => { this.userAssignedToCompany = data;  });
    this.authService.currentCompany.subscribe(company =>  {
      this.selectedCompany1 = company;
    this. featureRequest();
    });
    this.accountService.currentUser$.subscribe(data4 => {  this.user = data4;  });
    this.router.events
   .pipe(
     filter(e => e instanceof NavigationEnd)
   )
   .subscribe((navEnd: NavigationEnd) => {
    this.isRoute = navEnd.urlAfterRedirects;
    if (this.isRoute === 'undefined') {
      this.isRoute = '/';
    }
   });
  } else {
   // this.isRoute = '/';
   console.log('reached nav is no token');
    this.logout();
  }

  }
  featureRequest() {
    this.featureRequestService.listAll().subscribe(data => {
      this.featureLists = data;
      console.log(data);
    }, err => { this.alertify.error('Feature Request Not Retrieved. Refresh page.'); }, () => {
      this.alertify.success('Feature Request Retrived.');
    });
  }
  requestFeature() {
    console.log('reached gap');
    const config = {
      class: 'modal-lg',
      initialState: {
       parentGuid: this.selectedCompany1.parentGuid
      }
    };
    this.bsModalRef = this.bsModal.show(FeatureCreateComponent, config);
    this.bsModalRef.content.emittedValues.subscribe((values: FeatureRequest) => {
      this.feature.feature = values.feature;
      this.feature.parentGuid = this.selectedCompany1.parentGuid;
      this.feature.description = values.description;
      this.feature.wouldYouLikeContacted = values.wouldYouLikeContacted;
      console.log(this.feature);
      this.featureRequestService.add(this.feature, this.selectedCompany1.parentGuid).subscribe(data => {
        console.log(data);
        this.featureLists = data;
      }, err => { this.alertify.error('Feature Not Added.'); }, () => {
        this.alertify.success('Success');
      });
    });
  }
  loggedIn() {
    const token = localStorage.getItem('token');

   if (!this.authService.loggedIn) {
    this.logout();
   }
   return !!token;
  }
  logout() {
    if (localStorage.getItem('formsLabels') != null) { localStorage.removeItem('formsLabels'); }
    if (localStorage.getItem('token') != null) { localStorage.removeItem('token'); }
    if (localStorage.getItem('user') != null) { localStorage.removeItem('user'); }
    // if (localStorage.getItem('companyDepartmentTypes') != null) { localStorage.removeItem('companyDepartmentTypes'); }
    // if (localStorage.getItem('personTypes') != null) { localStorage.removeItem('personTypes'); }
    if (localStorage.getItem('userAssignedToCompany') != null) { localStorage.removeItem('userAssignedToCompany'); }
    // if (localStorage.getItem('users') != null) { localStorage.removeItem('users'); }
    // if (localStorage.getItem('website') != null) { localStorage.removeItem('website'); }
    // if (localStorage.getItem('websites') != null) { localStorage.removeItem('websites'); }
    if (localStorage.getItem('company') != null) { localStorage.removeItem('company'); }
   // localStorage.removeItem('parentComanies');
    this.authService.decodedToken = null;
    this.authService.currentUser = null;
    this.alertify.success('logged out');
    this.accountService.logout();
    this.router.navigate(['/']);
  }
  registerToggle() {
    this.registerMode = true;
    this.isVideo = false;
    this.isContact = false;
  }

  cancelRegisterMode(event: boolean) {
    this.registerMode = event;
    this.isVideo = false;
    this.isContact = false;
  }
  videoClicked() {
    this.isVideo = true;
    this.registerMode = false;
    this.isContact = false;
  }
  contactClicked() {
    this.isVideo = false;
    this.isContact = true;
    this.registerMode = false;

  }
  homeClick() {
    console.log('home');
   // this.cancelRegisterMode(false);
    this.registerMode = false;
    this.isContact = false;
    this.isVideo = true;
  }

}
