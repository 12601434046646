import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileItem, FileLikeObject, FileUploader } from 'ng2-file-upload';
import { take } from 'rxjs/operators';
import { AppUser } from 'src/app/_models/AppUser';
import { PdfFormTypes } from 'src/app/_models/PdfFormTypes';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account.service';
import { environment } from 'src/environments/environment';
import { FieldAlreadyExistsError, grayscale, PDFDocument, PDFTextField, TextAlignment } from 'pdf-lib';
import { PdfFieldLocationClass } from 'src/app/_classes/pdffielddatats/pdffield-location';
import { start } from 'repl';
import { PdfPackageService } from 'src/app/_services/PdfSErvices/pdf-package.service';
import { PackageButtonLocations } from 'src/app/_models/PackageButtonLocations';
import { PdfStateList } from 'src/app/_models/PDFentites/PdfStateList';
@Component({
  selector: 'app-pdf-uploader',
  templateUrl: './pdf-uploader.component.html',
  styleUrls: ['./pdf-uploader.component.css']
})
export class PdfUploaderComponent implements OnInit {
  @Output() callParentGetForms = new EventEmitter<string>();
  @Output() callParentGetForms2 = new EventEmitter<PdfStateList[]>();
  @Output() callParentCanceUploader = new EventEmitter<boolean>();
  @Input() packageButtonLocations = [] as PackageButtonLocations[];
  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  baseUrl = environment.apiUrl;
  response: string;
  user: User;
  base64Array: any;
  uint8Array: any;
  arrayBuffer: any;
  rawDataToPost: any;
  valorFileItem: FileItem;
  fileLikeObject: FileLikeObject;
  list = new DataTransfer();
  file2: File;
  @Input() formNameToUpload: string;
  @Input() selectedType: PdfFormTypes;
  @Input()   stateName: any;

  constructor(private accountService: AccountService,
    private pdfFieldLocationClass: PdfFieldLocationClass,
    private pdfPackageService: PdfPackageService) {}
  ngOnInit() {
    console.log(this.packageButtonLocations);
    console.log(this.selectedType);
    console.log(this.formNameToUpload);
    this.accountService.currentUser$.pipe(take(1)).subscribe(user => this.user = user);
   // if (this.formNameToUpload) {
      this.initializeUploader();
   // }
  }


 closeUploader() { this.callParentCanceUploader.emit(true); }


  saveImages() {
    // tslint:disable-next-line:prefer-const
    let fileCount: number = this.uploader.queue.length;
    if (fileCount > 0) {
    this.uploader.queue.forEach((val, i, array) => {
        const fileReader = new FileReader();
        fileReader.onloadend = (e) => {
            const pdfData = fileReader.result;
            this.pdfFieldLocationClass.createPdfFromBytes(pdfData, 0).then(pdf => { console.log('application/pdf;base64,' + pdf); this.base64Array = pdf;
            const buffer = new ArrayBuffer(pdf.length);
          });
          let rawData =  this.base64Array;
            if (rawData.length > 1) {
                rawData = rawData[1];
                this.rawDataToPost = rawData;
            }
        };
        fileReader.readAsDataURL(val._file);
      });
    }
  }

  initializeUploader() {
    this.uploader = new FileUploader({
      url: this.baseUrl + 'pdfupload/pdfforms/' + this.selectedType.id + '/' + this.formNameToUpload,
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: ['pdf'],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024,
    });

    this.uploader.onAfterAddingFile = (file) => {
    //  console.log(file);
      console.log('fileaddedonuploader');
     // this.pdfFieldLocationClass.readFile(file).then(data => { console.log(data); });
      // this.getNumberOfPages(file);
      file.withCredentials = false;
    };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      if (response) {
        console.log(response);
        console.log(item);
        console.log(status);
        this.callParentGetForms.emit('eventDesc');
      
         const pdfStateList: PdfStateList[] = JSON.parse(response);
         this.callParentGetForms2.emit(pdfStateList);
        // this.member.photos.push(photo);
        //  if (photo.isMain) {
        //    this.user.photoUrl = photo.url;
        //    this.member.photoUrl = photo.url;
        //    this.accountService.setCurrentUser(this.user);
        //  }
      }
    };
  }
  // handleFileInput(e: FileList) {
  //   console.log(e);
  //   const date: number = new Date().getTime();
  //  const item = e[0];
  //  console.log(item);
  //  // get array promist and split pdf
  //  // create new list and upload
  //  item.arrayBuffer().then(data => {
  //    console.log(data);
  //    /// before this call. Send PDF to see how many pages.
  //    // Then loop here to add those pdfs as inividual files to list
  //     this.pdfFieldLocationClass.createPdfFromBytes(data, 0).then(pdf => {
  //     console.log(pdf);
  //     this.file2 = null;
  //     this.file2 = new File([pdf], 'mypdf.pdf', {type: 'application/pdf', lastModified: date});
  //     this.list.items.add(this.file2);

  //     // tslint:disable-next-line:prefer-const
  //     const myFileList = this.list.files;
  //     const item2 = myFileList[0];
  //     item2.arrayBuffer().then(data1 => {console.log(data1); });
  //   console.log(myFileList);
  //   });
  // });
 
  // }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
}
