import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscriptions } from 'src/app/_models/Subscriptions';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { UserCart } from 'src/app/_models/UserCart';
import { AuthService } from 'src/app/_services/auth.service';
import { UserCartService } from 'src/app/_services/userpaymentservices/User-Cart.service';
import { UserSubscriptionServiceService } from 'src/app/_services/userpaymentservices/UserSubscriptionService.service';

@Component({
  selector: 'app-members-cart',
  templateUrl: './members-cart.component.html',
  styleUrls: ['./members-cart.component.scss']
})
export class MembersCartComponent implements OnInit {
  subscriptions: Subscriptions[];
  userCart = [] as UserCart[];
  cartLenth = 0;
  price = 0;
  userAssignedToCompany = {} as UserAssignedToCompany;
  selectedCategory: any;
    constructor(private subscriptionService: UserSubscriptionServiceService,
      private userCartService: UserCartService,
      private alertify: ToastrService,
      private authService: AuthService) { }

    ngOnInit() {
     // this.getCartItems();
     this.userCartService.cartItems.subscribe(data1 => { this.userCart = data1; });
     this.userCartService.cartCount.subscribe(data2 => { this.cartLenth = data2; });
     this.authService.currentCompany.subscribe(company => this.userAssignedToCompany = company);
    }
    quantityMinus(e) {
      console.log(e);
    }
    removeFromCart(e) {
      console.log(e);
      this.userCartService.removeFromCart(e).subscribe(data => {
        console.log(data);
        this.userCart = data;
        this.userCartService.changeCartCount(data.length);
        this.price = 0;
        for(let i = 0, n = data.length; i < n; i++)
        {
          this.price += data[i].amount;
        }
      }, error => {
        this.alertify.error(error);
      }, () => {
     //   this.alertify.success('Cart Updated!');
        this.userCartService.changeCartItems(this.userCart);
        this.userCartService.changeCartTotal(this.price);
      });
    }

  //   getCartItems() {
  //     this.userCartService.getCart().subscribe(data => {
  //      this.userCart = data;
  //  ///     this.userCartService.cartCount.subscribe(data1 => { this.cartLenth = data1; });
  //     }, error => {}, () => {
  //      //
  //     });
  //   }
  }
