<div class="form-sec tab-content col-md-12">
  <div class="card" style="background-color: #DBE9F4 !important">
    <div class="col-sm-12">
      <div *ngIf="editForm.dirty" class="alert alert-info">
        <strong>Information:</strong> You have made changes. Any unsaved changes
        will be lost!
      </div>
    </div>
    <form #editForm="ngForm" id="editForm">
      <div class="row">
        
        <div class="col-8">
          <div class="col-md-7">
            <div
              class="form-group"
              [ngClass]="{
                'has-success': propertyName?.valid && (propertyName?.touched || propertyName?.dirty),
                'has-danger': propertyName?.invalid && (propertyName?.touched || propertyName?.dirty)
              }"
            >
              <label>Property Name</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  [ngModel]="house?.propertyName"
                  (ngModel)="house.propertyName = $event"
                  name="propertyName"
                  #propertyName="ngModel"
                  minlength="2"
                  required
                />
                <div
                  *ngIf="!propertyName.errors && (propertyName?.touched || propertyName?.dirty)"
                  class="input-group-prepend"
                >
                  <button type="button" class="btn btn-outline-success">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div
                class="form-group-feedback"
                *ngIf="propertyName.errors && (propertyName?.touched || propertyName?.dirty)"
              >
                <p style="color: red" *ngIf="propertyName?.errors.required">
                  Name Is Required
                </p>
                <p style="color: red" *ngIf="propertyName?.errors.minlength">
                  Name required
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div
              class="form-group"
              [ngClass]="{
                'has-success': purchasePrice?.valid && (purchasePrice?.touched || purchasePrice?.dirty),
                'has-danger': purchasePrice?.invalid && (purchasePrice?.touched || purchasePrice?.dirty)
              }"
            >
              <label>Purchase Price</label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  [ngModel]="house?.purchasePrice"
                  (ngModel)="house.purchasePrice = $event"
                  name="purchasePrice"
                  #purchasePrice="ngModel"
                  minlength="2"
                  required
                />
                <div
                  *ngIf="!purchasePrice.errors && (purchasePrice?.touched || purchasePrice?.dirty)"
                  class="input-group-prepend"
                >
                  <button type="button" class="btn btn-outline-success">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div
                class="form-group-feedback"
                *ngIf="purchasePrice.errors && (purchasePrice?.touched || purchasePrice?.dirty)"
              >
                <p style="color: red" *ngIf="purchasePrice?.errors.required">
                  Zip Is Required
                </p>
                <p style="color: red" *ngIf="purchasePrice?.errors.minlength">
                  PurchasePrice required
                </p>
              </div>
            </div>
          </div>
          <fieldset ngModelGroup="addressDetail">
            <div class="col-md-12">
              <div
                class="form-group"
                [ngClass]="{
                  'has-success': zip?.valid && (zip?.touched || zip?.dirty),
                  'has-danger': zip?.invalid && (zip?.touched || zip?.dirty)
                }"
              >
                <label>Zip</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="house?.addressDetail?.zip"
                    (ngModel)="house.addressDetail.zip = $event"
                    name="zip"
                    #zip="ngModel"
                    minlength="2"
                    required
                  />
                  <div
                    *ngIf="!zip.errors && (zip?.touched || zip?.dirty)"
                    class="input-group-prepend"
                  >
                    <button type="button" class="btn btn-outline-success">
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="form-group-feedback"
                  *ngIf="zip.errors && (zip?.touched || zip?.dirty)"
                >
                  <p style="color: red" *ngIf="zip?.errors.required">
                    Zip Is Required
                  </p>
                  <p style="color: red" *ngIf="zip?.errors.minlength">
                    Zip requires at least 2 characters
                  </p>
                </div>
              </div>
            </div>

            <div
              class="form-group"
              [ngClass]="{
                'has-success':
                  street?.valid && (street?.touched || street?.dirty),
                'has-danger':
                  street?.invalid && (street?.touched || street?.dirty)
              }"
            >
              <label>Street</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  [ngModel]="house?.addressDetail?.street"
                  (ngModel)="house.addressDetail.street = $event"
                  name="street"
                  #street="ngModel"
                  required
                />
                <div
                  *ngIf="!street.errors && (street?.touched || street?.dirty)"
                  class="input-group-prepend"
                >
                  <button type="button" class="btn btn-outline-success">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
              <div
                class="form-group-feedback"
                *ngIf="street.errors && (street?.touched || street?.dirty)"
              >
                <p style="color: red" *ngIf="street?.errors.required">
                  Street Is Required
                </p>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div
                  class="form-group"
                  [ngClass]="{
                    'has-success':
                      street?.valid && (street?.touched || street?.dirty),
                    'has-danger':
                      street?.invalid && (street?.touched || street?.dirty)
                  }"
                >
                  <label>City</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      [ngModel]="house?.addressDetail?.city"
                      (ngModel)="house.addressDetail.city = $event"
                      name="city"
                      #city="ngModel"
                      required
                    />
                    <div
                      *ngIf="!city.errors && (city?.touched || city?.dirty)"
                      class="input-group-prepend"
                    >
                      <button type="button" class="btn btn-outline-success">
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="form-group-feedback"
                    *ngIf="city.errors && (city?.touched || city?.dirty)"
                  >
                    <p style="color: red" *ngIf="city?.errors.required">
                      City Is Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  class="form-group"
                  [ngClass]="{
                    'has-success':
                      state?.valid && (state?.touched || state?.dirty),
                    'has-danger':
                      state?.invalid && (state?.touched || state?.dirty)
                  }"
                >
                  <label>State</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      [ngModel]="house?.addressDetail?.state"
                      (ngModel)="house.addressDetail.state = $event"
                      name="state"
                      #state="ngModel"
                      required
                    />
                    <div
                      *ngIf="!state.errors && (state?.touched || state?.dirty)"
                      class="input-group-prepend"
                    >
                      <button type="button" class="btn btn-outline-success">
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="form-group-feedback"
                    *ngIf="state.errors && (state?.touched || state?.dirty)"
                  >
                    <p style="color: red" *ngIf="state?.errors.required">
                      State Is Required
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="form-group"
                  [ngClass]="{
                    'has-success':
                      county?.valid && (county?.touched || county?.dirty),
                    'has-danger':
                      county?.invalid && (county?.touched || county?.dirty)
                  }"
                >
                  <label>County</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      [ngModel]="house?.addressDetail?.county"
                      (ngModel)="house.addressDetail.county = $event"
                      name="county"
                      #county="ngModel"
                      required
                    />
                    <div
                      *ngIf="
                        !county.errors && (county?.touched || county?.dirty)
                      "
                      class="input-group-prepend"
                    >
                      <button type="button" class="btn btn-outline-success">
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="form-group-feedback"
                    *ngIf="county.errors && (county?.touched || county?.dirty)"
                  >
                    <p style="color: red" *ngIf="county?.errors.required">
                      County Is Required
                    </p>
                  </div>
                </div>
              </div>

              <div class="col">
                <!-- <div class="form-group" [ngClass]="{
                                       'has-success': country?.valid && (country?.touched  || country?.dirty),
                                       'has-danger': country?.invalid && (county?.touched  || country?.dirty)
                                   }">
                   <label>Country</label>
                   <div class="input-group"> 
                   <input type="text" class="form-control" [ngModel]="house?.addressDetail?.country" (ngModel)="house.addressDetail.country=$event"
                     name="country" #country="ngModel" required>
                     <div *ngIf="!country.errors && (country?.touched  || country?.dirty)" class="input-group-prepend">
                         <button type="button" class="btn btn-outline-success">
                             <i class="fa fa-check" aria-hidden="true"></i>
                           </button>
                       </div>
                     </div>
                   <div class="form-group-feedback" *ngIf="country.errors && (country?.touched  || country?.dirty)">
                     <p style="color:red" *ngIf="country?.errors.required">Country Is Required</p>
                   </div>
                 </div> -->
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </form>
    <button type="submit" (click)="onSubmit()" class="cstm-btn img-blk-btn">
      <img src="assets/images/ap-icon-1.png" />
      <span>Save</span>
    </button>
  </div>
</div>
