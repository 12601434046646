<div class="form-group">
    <input 
        type="text"
        [class.is-invalid]='ngControl.touched && ngControl.invalid'
        class='form-control'
        [formControl]='ngControl.control'
        placeholder="{{label}}"
        bsDatepicker
        [bsConfig]='bsConfig'
        [maxDate]='maxDate'
    >
    <div *ngIf="ngControl.control.errors?.required" class="invalid-feedback">{{label}} is required</div>
</div>
