<div class="customer-form-sec" style="width: 700px !important">
	<div class="container-fluid">
	  <div class="row">
		<div class="form-sec tab-content col-md-10">
		  <div id="customer" class="tab-pane fade in active show">
			<div class="modal-header form-sec tab-content">
			  <h4 class="modal-title pull-left">Add Note</h4>
			  <button
				type="button"
				class="close pull-right"
				aria-label="Close"
				(click)="bsModalRef.hide()"
			  >
				<span aria-hidden="true">&times;</span>
			  </button>
			</div>
			<div class="modal-body">
			  <form #rolesForm="ngForm" id="rolesForm">
				<div id="misc-info" class="tab-pane fade in active show">
				  <div class="row">
					<div class="col-md-12">
					  <div class="inner-misc">
						<div class="misc-grid">
						  <div class="row">
							<div class="col-md-12 mise-left-grid">
							  <div class="rw" style="align-items: baseline">
								<div class="inline-blk">
								  <label>Date</label>
								  <input
									type="date"
									class="cstm-input"
									name="createdDate"
									#createdDate = "ngModel"
									[ngModel]="entity?.createdDate | date:'yyyy-MM-dd'"  
									(ngModel)="entity.createdDate = $event"
									(ngModelChange)="entity.createdDate = $event"
									(blur)="onChange($event)"
								  />
								</div>
							  </div>
							  <div class="rw">
								<textarea class="form-control" [(ngModel)]="entity.note" name="note" rows="6"></textarea>
							  </div>
							  

                <div class="rw" style="align-items: baseline">
                  <div class="inline-blk">
                    <label>Remind me after?</label>
                    <input
                    type="date"
                    class="cstm-input"
					name="remindMeDate"
					#remindDate = "ngModel"
					[ngModel]="entity?.remindDate | date:'yyyy-MM-dd'"  
                    (ngModel)="entity.remindDate = $event"
                    (ngModelChange)="entity.remindDate = $event"
                    />
                  </div>
                  </div>
                  <div class="rw" style="align-items: baseline">
                    <div class="inline-blk">
                      <label>Remind me? </label>
                      <input
                        type="checkbox"
                        class="cstm-input text"
                        name="remind"
                        [checked]="entity.remind"
                        value="entity.remind"
                        [ngModel]="entity?.remind"
                        (ngModel)="entity.remind = $event"
                        (ngModelChange)="entity.remind = $event"
                      />
                    </div>
    </div>
							</div>
						  </div>
						</div>
					  </div>
					</div>
				  </div>
				</div>
			  </form>
			</div>
			<div class="modal-footer">
			  <button
				type="button"
				class="btn btn-default"
				(click)="bsModalRef.hide()"
			  >
				Cancel
			  </button>
			  <button type="button" class="btn btn-success" (click)="updateGap()">
				Submit
			  </button>
			</div>
		  </div>
		</div>
	  </div>
	</div>
  </div>
  

