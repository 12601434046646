import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddPaymentModalComponent } from 'src/app/loans/add-payment-modal/add-payment-modal.component';
import { AmoLoan } from 'src/app/_models/amoloans';
import { TakePaymentDto } from 'src/app/_models/DTOS/takepaymentdto';
import { Sale } from 'src/app/_models/sale';
import { AmoInstallmentService } from 'src/app/_services/amoinstallmentservices/amoinstallments.service';
import { AmoLoanService } from 'src/app/_services/amoloanservices/amoloan.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ExcelService } from 'src/app/_services/exportservices/excel.service';
import { LoanService } from 'src/app/_services/loanservieces/loan.service';
import moment from 'moment';
import { AmoLinqPayments } from 'src/app/_models/DTOS/amolinqpayments';
import { AmoPayment } from 'src/app/_models/amopayments';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
@Component({
  selector: 'app-salewashout-paymentsrecieved',
  templateUrl: './salewashout-paymentsrecieved.component.html',
  styleUrls: ['./salewashout-paymentsrecieved.component.css']
})
export class SalewashoutPaymentsrecievedComponent implements OnInit {
@Input() sale: Sale;

selectedCompany: UserAssignedToCompany;
bsModalRefAddPayment: any;
loans: AmoLinqPayments[];
amoPayments: AmoPayment[];
pagination: Pagination;
    invoiceId = 0 as number;
    userParams = {} as any;
    todayDate = new Date();
    isAscending = true;
    isPrint = false;
takePaymentDto: TakePaymentDto =  {
  datePaid: moment(Date.now()).toDate(),
  invoiceId: null,
  todayLoanPaid: 1,
  todayDeferredDown: 2,
  todayLateFee: 3,
  todayMisc: 4,
  sumPaidToday: 5,
  parentId: ' '
};
amoLoan: AmoLoan;
  constructor(private authService: AuthService,
    private takePaymentLoan: AmoLoanService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private router: Router,
    private alertify: ToastrService,
    private loanService: AmoInstallmentService,
    private loansDue: LoanService,
    private excelService: ExcelService,
    private amoLoanService: AmoLoanService) { }

  ngOnInit() {
    console.log(this.sale);
    
  }
  takePayment(item)  {
    console.log(this.takePaymentDto);
    const initialState = {
      chartInvoiceTransaction: this.loans,
      takePaymentDto: this.takePaymentDto
    };
    this.bsModalRefAddPayment = this.modalService.show(AddPaymentModalComponent, {initialState, class: 'modal-md'});
      this.bsModalRefAddPayment.content.paymentEnetered.subscribe((values) => {
      //  console.log(values);
        this.takePaymentDto = values;
        this.takePaymentLoan.takePayment(values).subscribe(data => {
       //   this.chartInvoice = data.invoice;
          this.amoPayments = data.amoPayments;
          this.alertify.success('Payment Applied Successullfy.');
        }, error => {
      //    this.alertify.error(error);
        });
  });
  }
  editPayment(item, amoLoan)  {
    // console.log(this.takePaymentDto);
    console.log(amoLoan);
    const initialState = {
      chartInvoiceTransaction: this.loans,
      takePaymentDto: item
    };
    this.bsModalRefAddPayment = this.modalService.show(AddPaymentModalComponent, {initialState, class: 'modal-md'});

    this.bsModalRefAddPayment.content.paymentEnetered.subscribe((values) => {
      //  console.log(values);
        this.takePaymentDto = values;
        this.takePaymentDto.invoiceId = amoLoan.saleId;
        this.takePaymentLoan.editPayment(values, item.id).subscribe(data => {
       //   this.chartInvoice = data.invoice;
          this.amoPayments = data.amoPayments;
          this.alertify.success('Payment Applied Successullfy.');
        }, error => {
        //  this.alertify.error(error);
        });
  });
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.sale.amoLoans.amoPayments, 'Loans');
}

async printDiv() {
  await this.hideDiv().then(() => {
   setTimeout(() => {
     this.print();
   }, 2000);
    });
 }
 async hideDiv() {
   this.isPrint = true;
 }
 async print() {
   window.frames['print_frame'].document.body.innerHTML = document.getElementById('printableTable').innerHTML;
   window.frames['print_frame'].window.focus();
   window.frames['print_frame'].window.print();
   this.isPrint = false;
 }
}
