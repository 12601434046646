import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Website } from '../_models/website';
import { WebsiteService } from '../_services/advertising/website.service';



@Injectable({
    providedIn: 'root'
})
export class WebsitesDetailResolver implements Resolve<Website[]> {
    constructor(private websiteService: WebsiteService, private router: Router,
        private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Website[]> {
        return this.websiteService.getWebsites().pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving website data');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
