import {Component, Input, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {Subscriptions} from 'src/app/_models/Subscriptions';
import {UserCart} from 'src/app/_models/UserCart';
import {UserSubscriptionPlan} from 'src/app/_models/UserSubscriptionPlan';
import {AuthService} from 'src/app/_services/auth.service';
import {UserCartService} from 'src/app/_services/userpaymentservices/User-Cart.service';
import {UserSubscriptionServiceService} from 'src/app/_services/userpaymentservices/UserSubscriptionService.service';
import {PaymentService} from "../../_services/payment-service";
import {PaymentPlanDto} from "../../_models/payment/PaymentPlanDto";
import {PaymentRequestProductCreateDto} from "../../_models/payment/PaymentRequestProductCreateDto";
import {PaymentType} from "../../_models/payment/PaymentType";
import {PaymentRequestCreateInput} from "../../_models/payment/PaymentRequestCreateInput";
import {AccountSubscriptionDto} from "../../_models/payment/AccountSubscriptionDto";

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  @Input() isSubscriptionsHistory = false;

  paymentPlansList: Array<PaymentPlanDto> = [];
  accountSubscriptionsList: Array<AccountSubscriptionDto> = [];

  constructor(private paymentService: PaymentService, private alertify: ToastrService) {
  }

  ngOnInit() {
    if (!this.isSubscriptionsHistory) {
      this.paymentService.getPaymentPlans().subscribe({
        next: (response) => {
          this.paymentPlansList = response;
        },
        error: (err) => {
          console.log(err);
          this.alertify.error('Problem retrieving payment plans.');
        }
      });
    } else {
      this.paymentService.getAccountSubscriptions().subscribe({
        next: (response) => {
          this.accountSubscriptionsList = response;
        },
        error: (err) => {
          console.log(err);
          this.alertify.error('Problem retrieving account subscriptions.');
        }
      })
    }

  }

  orderSoftware() {
    let products: Array<PaymentRequestProductCreateDto> = [];
    for (let paymentPlanIndex = 0; paymentPlanIndex < this.paymentPlansList.length; paymentPlanIndex++) {
      if (this.paymentPlansList[paymentPlanIndex].selected) {
        products.push({
            code: this.paymentPlansList[paymentPlanIndex].name,
            name: this.paymentPlansList[paymentPlanIndex].name,
            count: 1,
            planId: this.paymentPlansList[paymentPlanIndex].id,
            paymentType: PaymentType.Subscription
          }
        )
      }
    }
    if (products.length > 0) {
      let input: PaymentRequestCreateInput = {
        products: products
      }
      this.paymentService.createSubscription(input).subscribe({
        next: (response) => {
          window.location.href = response.redirectUrl
        },
        error: (err) => {
          console.log(err);
          this.alertify.error('Problem creating payment request.');
        }
      });
    } else {
      this.alertify.error('Kindly enter the count of each plan you want to subscribe to');
    }
  }
}
