import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PdfStateListsResolver } from '../_resolvers/pdf-statelist.resolver';
import { ProductListResolver } from '../_resolvers/product-list.resolver';
import { PdfFormByIdResolver } from '../_resolvers/retrieve-pdfform.resolver';
import { SaleActiveTypeResolver } from '../_resolvers/sale-activetype.resolver';
import { SaleStatusResolver } from '../_resolvers/sale-status.resolver';
import { SalesResolver } from '../_resolvers/sales-grid.resolver';
import { VehicleBodyClassTypesResolver } from '../_resolvers/vehicle-bodytype-categories.resolver';
import { DraggableResisableComponent } from './draggable-resisable/draggable-resisable.component';
import { PdfGridComponent } from './pdf-grid/pdf-grid.component';
import { PdfImportingfromshopComponent } from './pdf-importingfromshop/pdf-importingfromshop.component';
import { PdfViewSizedComponent } from './Pdf-View-Sized/Pdf-View-Sized.component';
import { PdfformEditorComponent } from './pdfform-editor/pdfform-editor.component';
import { ResportsManagerComponent } from './resports-manager/resports-manager.component';
import { SaleformeditorviewsizedComponent } from './SaleFormeditorViewSized/SaleFormeditorViewSized.component';
// import { SaleformeditorviewsizedComponent } from './SaleFormeditorViewSized/SaleFormeditorViewSized.component';





// reportsmanager/ + route below TesthtmlpdfComponent
const routes: Routes = [
    {path: 'pdfsized/:pdfformid', component: PdfViewSizedComponent, resolve: {pdfForms: PdfFormByIdResolver} },
    {path: 'pdfimport', component: PdfImportingfromshopComponent },
    {path: 'gridview', component: PdfGridComponent },
    {path: 'saleformeditor/:pdfid', component: SaleformeditorviewsizedComponent },
    {path: 'reportsmanager/1', component: DraggableResisableComponent},
    {path: 'reportsmanager/:parentGuid', component: ResportsManagerComponent, resolve: {
        chartInvoiceStatuses: SaleStatusResolver,
        chartInvoiceSaleTypes: SaleActiveTypeResolver,
        sales: SalesResolver,
        pdfStateList: PdfStateListsResolver,
        chartItems: ProductListResolver, bodyTypes: VehicleBodyClassTypesResolver
    }},
    {path: 'pdfformeditor/:parrentGuid/:saleid', component: PdfformEditorComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FormAndReportsRoutingModule {}


