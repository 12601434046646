import { CompanyDepartmentType } from "./CompanyDepartmentType";

export class ServiceOffer {
    id: number;
    name: string;
    departmentName: string;
    isFinanceDepartment: boolean;
    companyDepartmentType: CompanyDepartmentType;
    companyDepartmentTypeId?: number;
    booletOne: string;
    booletTwo: string;
    booletThree: string;
    booletFour: string;
    smallDescription: string;
    url: string;
    file: string;
    isAvatar: boolean;
    dateAdded: Date;
    price: number;
    parentGuid: string;
    websiteGuid: string;
    WebsiteCompanyId: number;
    publicId: string;
}


