import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { SaleNewComponent } from '../sales/sale-new/sale-new.component';
import { ConfirmService } from '../_services/confirm.service';

@Injectable({
  providedIn: 'root'
})
export class DealInfoGuard implements CanDeactivate<unknown> {

  constructor(private confirmService: ConfirmService) {}

  canDeactivate(component: SaleNewComponent ): Observable<boolean> | boolean {
    if (component?.dealInfoForm?.dirty) {
      return this.confirmService.confirm();
    }

    return true;
  }

}