import { Component, Input, OnInit } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MiscchargAddComponent } from 'src/app/modals/misccharg-add/misccharg-add.component';
import { MiscchargeEditComponent } from 'src/app/modals/misccharge-edit/misccharge-edit.component';
import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';
import { Sale } from 'src/app/_models/sale';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { DollarService } from 'src/app/_services/dollarservices/dollar.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';

@Component({
  selector: 'app-misccharges-grid',
  templateUrl: './misccharges-grid.component.html',
  styleUrls: ['./misccharges-grid.component.css']
})
export class MiscchargesGridComponent implements OnInit {
  @Input() dollars = [] as Dollars[];
  @Input() miscChargeDollarTypes: DollarTypes[];
  @Input() sale = {} as Sale;
  selectedDollarType: any;
  selectedCompany1: UserAssignedToCompany;
  bsModalRef: any;
  constructor(private modalService: BsModalService,
    private alertify: ToastrService,
    private saleService: SaleService, private authService: AuthService,
    private dollarService: DollarService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
  }
  openMiscModal() {
    console.log(this.sale);
    const config = {
      class: 'modal-lg',
      initialState: {
       sale: this.sale,
       dollarTypes: this.miscChargeDollarTypes,
      }
    };
    this.bsModalRef = this.modalService.show(MiscchargAddComponent, config);
    this.bsModalRef.content.emittedValues.subscribe(values => {
      this.dollarService.postNewMiscCost(this.selectedCompany1.parentGuid, values, this.sale.id, this.sale.vehiclePurchased.id).subscribe(data => {
        this.dollars = data.dollars;
      }, error => { this.alertify.error(error); }, () => {
        this.alertify.success('Note Saved');
        console.log(this.dollars);
      });
    });
  }

  editMischCharge(e: Dollars) {
    console.log('reached gap');
    const initialState = {
        dollar: e,
        dollarTypes: this.miscChargeDollarTypes,
        isLocked: false
    };
    this.bsModalRef = this.modalService.show(MiscchargeEditComponent, {initialState});
    this.bsModalRef.content.emittedValues.subscribe((values: Dollars )=> {
      this.dollarService.editMiscCost(this.selectedCompany1.parentGuid, values, this.sale.id, this.sale.vehiclePurchased.id).subscribe(data => {
        this.sale.saleMiscCharges = data;
        this.dollarService.setDollarsNotesDollarTypesForMiscCharges(data);
      }, error => { this.alertify.error(error); }, () => {
        this.alertify.success('Note Saved');
      });
    });
  }
}
