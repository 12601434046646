import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-inventory-dashboard',
  templateUrl: './inventory-dashboard.component.html',
  styleUrls: ['./inventory-dashboard.component.css'],
})
export class InventoryDashboardComponent implements OnInit {
  isDashboard = false;
  isAll = false;
  isActive = false;
  isInternal = false;
  selectedCompany1: UserAssignedToCompany;
  constructor(private authService: AuthService, private router: Router) {
    this.authService.company.subscribe((data) => {
      this.selectedCompany1 = data;
    });
  }

  ngOnInit() {}
  tabClicked(e) {
    this.isDashboard = false;
    this.isAll = false;
    this.isActive = false;
    this.isInternal = false;
    if (e === 'isDashboard') {
      this.isDashboard = true;
      this.router.navigate([
        'inventorydashboard/inventorydashboard/' +
          this.selectedCompany1.parentGuid,
      ]);
    }
    if (e === 'isAll') {
      this.isAll = true;
      this.router.navigate([
        'inventorydashboard/repairs/' + this.selectedCompany1.parentGuid,
      ]);
    }
    if (e === 'isActive') {
      this.isActive = true;
      this.router.navigate([
        'inventorydashboard/images/' + this.selectedCompany1.parentGuid,
      ]);
    }
    if (e === 'isInternal') {
      this.isInternal = true;
      this.router.navigate([
        'inventorydashboard/inventorygrid/' + this.selectedCompany1.parentGuid,
      ]);
    }
  }
}
