import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PdfFormPackages } from 'src/app/_models/pdfformpackages';
import { PdfFormPackage } from 'src/app/_models/pdfformpackage';
import { PdfFormsPack } from 'src/app/_models/PdfFormPack';
import { PackageButtonLocations } from 'src/app/_models/PackageButtonLocations';

@Injectable({
  providedIn: 'root'
})
export class PdfPackageService {
  baseUrl = environment.apiUrl;

  pdfFormPackagesCached: PdfFormPackages;

  pdfFormPackages = new BehaviorSubject<any>(null);
  currentpdfFormPackages = this.pdfFormPackages.asObservable();

  salePackages = new BehaviorSubject<PdfFormPackage[]>(null);
  currentSalePackages = this.salePackages.asObservable();

  constructor(private http: HttpClient) {  }

changeSalePackages(pdfFormPackage: PdfFormPackage[]) {
  this.pdfFormPackages.next(pdfFormPackage);
  this.salePackages.next(pdfFormPackage);
}
getSalePackages(): Observable<PdfFormPackage[]> {
 return  this.currentSalePackages;
}
  changePdfFormPackages(pdfFormPackages: PdfFormPackages) {
    this.pdfFormPackages.next(pdfFormPackages);
    this.pdfFormPackagesCached = pdfFormPackages;
  }
  geFormPackagesNoForms(guid: string): Observable<PdfFormPackages> {
   return  this.http.get<PdfFormPackages>(this.baseUrl + 'pdfpackage/getsaletablepackagenoforms/' + guid);
  }
  geFormPackages(guid: string): Observable<PdfFormPackages> {
   return  this.http.get<PdfFormPackages>(this.baseUrl + 'pdfpackage/listpackages/' + guid);
  }
  addFormPackage(pdfPackage: PdfFormPackage): Observable<PdfFormPackages> {
   return  this.http.post<PdfFormPackages>(this.baseUrl + 'pdfpackage/addpdfpackage/', pdfPackage);
  }
  removeFormPackage(pdfPackage: PdfFormPackage): Observable<PdfFormPackages> {
    return  this.http.post<PdfFormPackages>(this.baseUrl + 'pdfpackage/removepdfpackage/', pdfPackage);
   }
   renameFormPackage(pdfPackage: PdfFormPackage): Observable<PdfFormPackages> {
    return  this.http.post<PdfFormPackages>(this.baseUrl + 'pdfpackage/renamepdfpackage/', pdfPackage);
   }
   addFormToPdfFormPackage(pdfFormPackage: PdfFormPackage, formid: number): Observable<PdfFormPackages> {
    return  this.http.post<PdfFormPackages>(this.baseUrl + 'pdfpackage/addformtopdfpackage/' + formid, pdfFormPackage);
   }
   removeFormFromPdfFormPackage(pdfFormsPack: PdfFormsPack, parentguid: string): Observable<PdfFormPackages> {
    return  this.http.post<PdfFormPackages>(this.baseUrl + 'pdfpackage/removeformfrompdfpackage/' + parentguid, pdfFormsPack);
   }
   listPackageButtonLocations(parentguid: string): Observable<PackageButtonLocations[]>{
    return  this.http.get<PackageButtonLocations[]>(this.baseUrl + 'pdfpackage/getformbuttonpdflocations/' + parentguid);
   }
   getIndividualPackageById(packageId: number): Observable<PdfFormPackage>  {
    return  this.http.get<PdfFormPackage>(this.baseUrl + 'pdfpackage/getpackagebyid/' + packageId);
   }

}
