import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { FormNameLabelsService } from '../_services/formnamelabels/form-name-labels.service';
import { FormName } from '../_models/formName';
import { AuthService } from '../_services/auth.service';
import { UserAssignedToCompany } from '../_models/userAssignedToCompany';
@Injectable({
    providedIn: 'root'
})
export class LabelVehicleDetailsResolver implements Resolve<FormName> {
    selectedCompany: UserAssignedToCompany;
    constructor(
        private formNameService: FormNameLabelsService,
        private router: Router,
        private authService: AuthService
        ) {}
// private alertify: AlertifyService
    resolve(route: ActivatedRouteSnapshot): Observable<FormName> {
        const newLocal = 'parentGuid';
        this.authService.currentCompany.subscribe(data => { this.selectedCompany = data; });
      //  console.log(route.params['parentGuid']);
        return this.formNameService.getFormNameByName(this.selectedCompany.parentGuid, 'VehicleDetails').pipe(
            catchError(error => {
             //   this.alertify.error('Problem retrieving Vehicle Detail Labels');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
