
<br><br>
<div  style="width: 90% !important;"> 
 <div class="col-md-12">
  <div class="row">
      <div class="col-md-2 form-sec tab-content">
        <app-nav-list></app-nav-list>
    </div>
       
      <div class="form-sec tab-content col-md-9">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <h2>Payment Types</h2>
                    <table border="1" borderColor="#ccc">
                      <tr>
                        <th>Id</th>
                        <th>Payment Type</th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr class="td-row" *ngFor="let item of chartPaymentTypes">
            <td>{{item.id}}</td>
            <td>{{item.value}}</td>
                        <td >
                            <a
                            [ngClass]="{'btn btn-danger' : item.deletedDate , 'btn btn-success' : !item.dateDeleted}"
                           
                            (click)="delete(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Is Active"
                            ><i  class="fa fa-fw fa-check-o"></i
                          ></a>
                        </td>
                        <td >
                            <a
                            class="btn btn-warning"
                            (click)="editPaymentType(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit Sales Tax Setting"
                            ><i class="fa fa-fw fa-files-o"></i
                          ></a>
                        </td>
                       
                      </tr>
                    </table>
                  </div>
                  <!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
      <div style="padding-left: 90px;" class="customer-sidebar sidebar-with-btns col-md-1">
          <div class="inner-sidebar">
              <ul  style="list-style-type:none;">
                  <li>
                      <button  (click)="addPaymentType()" class="cstm-btn img-blk-btn">
                        <img src="assets/images/ap-icon-1.png">
                        <span>Add New</span>
                      </button>
                    </li>

              </ul>
            </div>
        </div>
    </div>
  </div>

</div>