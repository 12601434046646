import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { GeneralLedgerComponent } from './general-ledger/general-ledger.component';
import { AccountingRoutingModule } from './accounting-routing.module';
import { SharedModule } from '../_modules/shared.module';


@NgModule({
    declarations: [
        GeneralLedgerComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        AccountingRoutingModule,
        AgChartsAngularModule,
        AgGridModule.withComponents([]),
    ]
})
export class AccountingModule { }
