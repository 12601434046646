import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserCart } from 'src/app/_models/UserCart';
import { UserSubscriptionPlan } from 'src/app/_models/UserSubscriptionPlan';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserCartService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;
  isCart = new BehaviorSubject<boolean>(null);
  currentisCart = this.isCart.asObservable();

  totalCart = new BehaviorSubject<number>(0);
  currentTotalCart = this.totalCart.asObservable();

  cartItems = new BehaviorSubject<UserCart[]>(null);
  currentCartItems = this.cartItems.asObservable();

  cartCount = new BehaviorSubject<number>(null);
  currentcartCount = this.cartCount.asObservable();

  userSubscriptionPlan = new BehaviorSubject<UserSubscriptionPlan>(null);
  currentuserSubscriptionPlan = this.userSubscriptionPlan.asObservable();

  changeuserSubscriptionPlan(e) {
    this.userSubscriptionPlan.next(e);
  }
  getUserSubscriptionPlan() {
    return this.currentuserSubscriptionPlan;
  }
  getCart(): Observable<UserCart[]> {
    return this.http.get<UserCart[]>(this.baseUrl + 'userCart/getcart/' );
  }

  addToCart(subscriptionItem: UserSubscriptionPlan): Observable<UserCart[]> {
    return this.http.get<UserCart[]>(this.baseUrl + 'userCart/addcartitem/' + subscriptionItem.id );
  }
  removeFromCart(cartItem: UserCart): Observable<UserCart[]> {
    return this.http.get<UserCart[]>(this.baseUrl + 'usercart/removecartitem/' + cartItem.id);
  }
  changeCartTotal(amount: number) {
    this.totalCart.next(amount);
  }
  changeIsCart(isCart: boolean) {
    this.isCart.next(isCart);
  }
  changeCartItems(cartItems: UserCart[]) {
    this.cartItems.next(cartItems);
  }
  changeCartCount(itemCount: number) {
    this.cartCount.next(itemCount);
  }
  getIsCart()
  {
    return this.isCart;
  }
}

