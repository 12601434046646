<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-10">
            <tabset class="member-tabset">
              <tab heading="Buyers Guides">
                <app-buyersguide-grid></app-buyersguide-grid>
                </tab>
                <tab heading="Systems">
                  <app-systemscovered-grid></app-systemscovered-grid>
                </tab>
            </tabset>
          </div>
      <div class="customer-sidebar sidebar-with-btns col-md-2">
        <app-rightmenubuyersguide></app-rightmenubuyersguide>
      </div>
    </div>
  </div>
</div>