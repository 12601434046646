import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RealPropertyAddDto } from 'src/app/_models/DTOS/realpropertyadddto';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';

@Component({
  selector: 'app-add-housemodal',
  templateUrl: './add-housemodal.component.html',
  styleUrls: ['./add-housemodal.component.css']
})
export class AddHousemodalComponent implements OnInit {
  selectedCompany: UserAssignedToCompany;
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  house = {} as RealPropertyAddDto ;
  @Output() update = new EventEmitter();
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.editForm.dirty) {
      $event.returnValue = true;
    }
  }
  constructor(public bsModalRef1: BsModalRef) { }

  ngOnInit() {
  }
  onSubmit() {
    this.update.emit(this.editForm.value);
    console.log(this.editForm.value);
    this.bsModalRef1.hide();
  }
}
