

  <div class="customer-form-sec"  style="width: 1150px;">
    <div class="container-fluid">
      <div class="row">
        <div class="form-sec tab-content col-md-11">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-row account-payable-row">          
                  
                  <div class="row">
                    <div class="col-md-12 table-sec">
                      <div id="printableTable">
                        <div class="modal-header" style="width: 850px;">
                          <h4 class="modal-title pull-left">Amortisation</h4>
                          <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body" style="width: 850px;">
<table  >
  <tr>
    <th>PaymentNumber:</th>
    <th>Date paid</th>
    <th>Pmt Amortized</th>
    <th>Princple</th>
    <th>Interest</th>
    <th>Non Amortized Pmt</th>
    <th>Payment Total</th>
    <th>Declining Balance</th>
   
    <th>Sum Paid</th>
    <th>Pmt Type</th>
    <th>Non Amortized Total</th>
    <th>Apr</th>
    <th>Contact Rate</th>
    <th>Principle Balance</th>
    <th>DSL</th>
    <th>Daily Factor</th>
    <th>Days Per Year</th>

    
   
    <!-- | orderBy: '+paymentNumber' -->
  </tr>
  <tr   *ngFor = "let items of amoAmortization ">
    <td  >{{items.paymentNumber}}</td>
    <td >{{items.datePaid | date: 'shortDate'}}</td>
    <td>{{items.paymentAmo | number:'1.2-2'}}</td>
    <td>{{items.principle | number:'1.2-2'}}</td>
    <td>{{items?.interest | number:'1.2-2'}}</td>
    <td>{{items.paymentNoAmo | number:'1.2-2'}}</td>
    <td>{{items?.paymentTotal | number:'1.2-2'}}</td>    
    <td>{{items?.decliningBalance | number:'1.2-2'}}</td>  
    <td>{{items.sumPaid | number:'1.2-2'}}</td>
   
    <td>{{items?.paymentType }}</td>
    <td>{{items?.nonAmortizedTotal | number:'1.2-2'}}</td>
    <td>{{items.aPR | number:'1.4-4'}}</td>
    <td>{{items?.contractRate | number:'1.4-4'}}</td>
    <td>{{items?.principleBalance | number:'1.2-2'}}</td>          
    <td>{{items?.dsl | number:'1.0-0'}}</td>                  
    <td>{{items.dailyFactor | number:'1.6-6'}}</td>
    <td >{{items.daysPerYear}}</td>
  </tr>
</table>
</div>
</div>
                    </div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

