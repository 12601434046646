

<div style="margin-top: 10px;" class="container-fluid customer-tabs-sec">
  <app-date-filter (calenderOutput) = "updateDates($event)"></app-date-filter>
  <ul class="nav nav-tabs">
    <li>
      <a
        data-toggle="tab"
        [ngClass] = "isAllSales ? 'active show' : ''"
        (click)="filter('isAllSales')"
        >All Sales</a
      >
    </li>
    <li *appHasRole="['Admin']" >
      <a
        data-toggle="tab"
        [ngClass] = "isSalesTax ? 'active show' : ''"
        (click)="filter('isSalesTax')"
        >Sales Tax Report</a
      >
    </li>
    <li *appHasRole="['Admin']" >
      <a
        data-toggle="tab"
        [ngClass] = "isSalesJournal ? 'active show' : ''"
        (click)="filter('isSalesJournal')"
        >Sales Journal</a
      >
    </li>
    <li *appHasRole="['Admin']" >
      <a
        data-toggle="tab"
        [ngClass] = "isVehicleSoldList ? 'active show' : ''"
        (click)="filter('isVehicleSoldList')"
        >Vehicle Sold List</a
      >
    </li>
	<div class="d-flex justify-content-right">
		<pagination
      [boundaryLinks]="true"
      pageBtnClass = "nav nav-tabs"
		  [(ngModel)]="pagination.currentPage"
		  [totalItems]="pagination.totalItems"
		  (pageChanged)="pageChanged($event)"
		  [itemsPerPage]="pagination.itemsPerPage"
		  previousText="&lsaquo;"
		  nextText="&rsaquo;"
		  firstText="&laquo;"
		  lastText="&raquo;"
		>
		</pagination>
	  </div>

</ul>
 
</div>

<div style="margin-top: 80px; height: 100%;" class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <div class="row">
                  <div class="col-md-11 table-sec">
                    <br>
                   
                    <div  id="printableTable">
                    <table   borderColor="#ccc">
                      <tr>
                        <th (click)= "sort('saleType')">Sale  Type&nbsp;
                          <i [ngClass]="isSaleTypeAscending ? 'up': 'down'"></i></th>
              <th (click)= "sort('saleType')">Sale Status&nbsp;
                <i [ngClass]="isSaleTypeAscending ? 'up': 'down'"></i></th>
                        <th (click)="sort('date')">Date &nbsp;
                          <i [ngClass]="isDateAscending ? 'up': 'down'"></i></th>
                          <th (click)= "sort('invoiceNumber')">Invoice # &nbsp;
                            <i [ngClass]="isInvoiceAscending ? 'up': 'down'"></i></th>
                            <th (click)= "sort('vehicle')">Vehicle&nbsp; <i [ngClass]="isVehicleAscending ? 'up': 'down'"></i></th>
                        <th (click)= "sort('customer')">Customer &nbsp;
                          <i [ngClass]="isCustomerAscending ? 'up': 'down'"></i></th>
                          <th (click)= "sort('isCountyAscending')">County &nbsp;
                            <i [ngClass]="isCountyAscending ? 'up': 'down'"></i></th>
                        <th (click)= "sort('isAmountTaxed1')">Amt Taxed 1 &nbsp;
                          <i [ngClass]="isAmountTaxed1 ? 'up': 'down'"></i></th>
                        
                        <th (click)= "sort('isTaxRate1')">tax Rate 1&nbsp;
                          <i [ngClass]="isTaxRate1 ? 'up': 'down'"></i></th>

                        <th (click)= "sort('isTax1')">Tax 1 &nbsp;
                          <i [ngClass]="isTax1 ? 'up': 'down'"></i></th>


                          <th (click)= "sort('isAmountTaxed2')">Amt Taxed 2 &nbsp;
                            <i [ngClass]="isAmountTaxed2 ? 'up': 'down'"></i></th>
                          
                          <th (click)= "sort('isTaxRate2')">tax Rate 2&nbsp;
                            <i [ngClass]="isTaxRate2 ? 'up': 'down'"></i></th>
  
                          <th (click)= "sort('isTax2')">Tax 2 &nbsp;
                            <i [ngClass]="isTax2 ? 'up': 'down'"></i></th>

                            <!-- <th (click)= "sort('isAmountTaxed3')">Amt Taxed 3 &nbsp;
                              <i [ngClass]="isAmountTaxed3 ? 'up': 'down'"></i></th>
                            
                            <th (click)= "sort('isTaxRate3')">tax Rate 3&nbsp;
                              <i [ngClass]="isTaxRate3 ? 'up': 'down'"></i></th>
    
                            <th (click)= "sort('isTax3')">Tax 3 &nbsp;
                              <i [ngClass]="isTax3 ? 'up': 'down'"></i></th> -->
                       
                                <!-- <th (click)= "sort('isTax')">Tax &nbsp;
                                  <i [ngClass]="isTax ? 'up': 'down'"></i></th> -->
                      </tr>
                      <tr  [hidden]="isPrint">
                       
                        <td><select  
                          [(ngModel)] ="selectType" (change) ="selectedTypeChanged($event.target.value)"
                          name="vehicleBodyClassTypes"
                          onmousedown = "value = '';" 
                          #vehicleBodyClassTypes="ngModel">
                          <option ngValue= "null">--All--</option>
                          <option *ngFor="let summary of chartInvoiceSaleTypes" value="{{summary.id}}">
                            {{summary.value}}
                          </option>
                      </select></td>  
                        <td><select  
                          [(ngModel)] ="selectStatus" (change) ="selectedStatusChanged($event.target.value)"
                          name="chartInvoiceStatusess"
                          onmousedown = "value = '';" 
                          #chartInvoiceStatusess="ngModel">
                          <option ngValue= "null">--All--</option>
                          <option *ngFor="let summary of chartInvoiceStatuses" value="{{summary.id}}">
                            {{summary.value}}
                          </option>
                      </select>
                    </td> 
                    <td></td>
                        <td><input style="width: 55px;" (keyup)="setSearchBy('searchInvoiceNumber', $event.target.value)"></td>  
                        <td></td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchCustomer', $event.target.value)"></td>  
                        <td><input style="width: 96px;" (keyup)="setSearchBy('searchCounty', $event.target.value)"></td>  
                        <td></td> 
                        <td></td>  
                        <td></td>  
                        <td></td>  
                        <td></td>  
                       
                       
                       
                     
                    
                      </tr>
                      <tr  align="right"  class="td-row" *ngFor="let item of sales; let i = index">
                       
                        <td  align="left" *ngIf="i != sales.length - 1">{{item?.saleTypes?.value}}</td>
                        <td  align="left" *ngIf="i != sales.length - 1">{{item?.saleStatus?.value}}</td>
                        <td align="left" *ngIf="i != sales.length - 1">{{item?.soldDate | date: 'shortDate'}}</td>
                        <td align="left" *ngIf="i != sales.length - 1">{{item?.acct}}</td>
                        <td align="left" *ngIf="i != sales.length - 1">{{item?.vehiclePurchased?.vehicle?.year + " " + item?.vehiclePurchased?.vehicle?.make + " " + item?.vehiclePurchased?.vehicle?.model}}</td>
                        <td align="left" *ngIf="i != sales.length - 1">{{item?.buyer?.firstName}}&nbsp; {{item?.buyer?.lastName}}</td>
                        <td align="left" *ngIf="i != sales.length - 1">{{item?.buyer?.addressDetail?.county}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.amtTaxed1 | number : '1.2'}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.taxRate1 | number : '1.2'}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.totalTax1 | number : '1.2'}}</td>

                        <td *ngIf="i != sales.length - 1">{{item?.amtTaxed2 | number : '1.2'}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.taxRate2 | number : '1.2'}}</td>
                        <td *ngIf="i != sales.length - 1">{{item?.totalTax2 | number : '1.2'}}</td>

                        <!-- <td>{{item?.amountTaxed3 | number : '1.2'}}</td>
                        <td>{{item?.taxRate3 | number : '1.2'}}</td>
                        <td>{{item?.totalTax3 | number : '1.2'}}</td> -->

                        <!-- <td>{{item?.tax | number : '1.2'}}</td> -->
                        <td  [hidden]="isPrint" *ngIf="i != sales.length - 1">
                          <a
                            class="btn btn-success"
                            (click)="navigateInvoice(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View Order Detail"
                            ><i class="fa fa-fw fa-eye"></i
                          ></a>
                        </td>
                      </tr>
                      <tr  align="right" class="border_top" *ngFor="let item of sales; let i=index; last as isLast">
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">Sub Totals:</td>
                        <td *ngIf="isLast && i !==0">{{item?.amountTaxed1 | number : '1.2'}}</td>

                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">{{item?.totalTax1 | number : '1.2'}}</td>

                        <td *ngIf="isLast && i !==0">{{item?.amountTaxed2 | number : '1.2'}}</td>
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0">{{item?.totalTax2 | number : '1.2'}}</td>
                        <!-- <td style="font-weight: bold;">{{getAmtTaxed3('amttaxed3') }}</td>
                        <td></td>
                        <td style="font-weight: bold;">{{getTax3()}}</td> -->
                        <td *ngIf="isLast && i !==0"></td>
                        <td *ngIf="isLast && i !==0"></td>
                    </tr>
                    </table>
                  </div> 
                  <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>
                  </div>
                  <!-- /.table-sec -->

                  <div class="customer-sidebar sidebar-with-btns col-md-1">
                    <div class="inner-sidebar">
                      <ul>
                        <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->
                        
                        <li>
                          <button class="cstm-btn" (click) = "exportAsXLSX()">
                            <img src="assets/images/icons8-microsoft-excel-48.png" />
                            <span>Export Excel</span>
                          </button>
                        </li>
                        <li>
                          <button  (click) = "printDiv()" class="cstm-btn">
                            <img src="assets/images/ap-icon-6.png" />
                            <span>Print Report</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
 
    </div>
  </div>
</div>

