			 <!--STOCK #-->
       <div class="col-md-12 cstm-col">
							
        <label   (dblclick)="toggleValue2('StockNumber')"  >{{labelStock}}</label>
        <input class="cstm-input" value=""
        type="text"
        autocomplete="off"
        [ngModel]="sale?.tradeIn?.itemVehicleTransientDetails?.stockNumber"
        (ngModel)="sale.tradeIn.itemVehicleTransientDetails.stockNumber = $event"
        (ngModelChange)="sale.tradeIn.itemVehicleTransientDetails.stockNumber = $event"
        name="stockNumber"
        #stockNumber="ngModel"
        >
      
      
      <div
      *ngIf="stockNumber.errors && (stockNumber?.touched || stockNumber?.dirty)">
      <p *ngIf="stockNumber?.errors?.required">Stock Is Required</p>
      </div>
      </div>
      <!-- CATEGORY -->
      <div class="col-md-12 cstm-col">
      <label (dblclick)="toggleValue2('Category')" >{{labelCategory}}</label>
        <input  list="category" 
        [ngModel]="sale?.tradeIn?.vehicle?.vehicleBodyClassTypes?.nameFriendly"
      (ngModel)="sale.tradeIn.vehicle.vehicleBodyClassTypes.nameFriendly = $event"
      (ngModelChange)="sale.tradeIn.vehicle.vehicleBodyClassTypes.nameFriendly = $event"
      name="vehicleBodyClassTypes"
      #vehicleBodyClassTypes="ngModel"/>
        <datalist  id="category"  class="select-input">
        <option   *ngFor="let item of bodyTypes" [selected]="item.id === sale.tradeIn.vehicle.vehicleBodyClassTypeId"  value="{{item.nameFriendly}}">
          </option>
        </datalist>
      </div>
      <!--VIN-->
      <div class="col-md-12 cstm-col">
      <label (dblclick)="toggleValue2('VIN')" >{{labelVin}}</label>
        <input class="cstm-input" value=""
        type="text"
        autocomplete="off"
        [ngModel]="sale?.tradeIn?.vehicle?.vin"
        (ngModel)="sale.tradeIn.vehicle.vin = $event"
        (ngModelChange)="sale.tradeIn.vehicle.vin = $event"
        name="vin"
        #vin="ngModel"
        required>
      <div
      *ngIf="vin.errors && (vin?.touched || vin?.dirty)">
      <p *ngIf="vin?.errors?.required">VIN Is Required</p>
      </div>
      </div>
     <!--Year-->
     <div class="col-md-12 cstm-col">
      <label (dblclick)="toggleValue2('Year')" >{{labelYear}}</label>
      <input autocomplete="Year-name" list="year" name="state-choice" 
      [ngModel]="sale?.tradeIn?.vehicle?.year"
      (ngModel)="sale.tradeIn.vehicle.year = $event"
      (ngModelChange)="sale.tradeIn.vehicle.year = $event"
      name="year"
      #year="ngModel"/>
      </div>
      <!--Make-->
      <div class="col-md-12 cstm-col">
      <label (dblclick)="toggleValue2('Make')" >{{labelMake}}</label>
      <input autocomplete="make-name" list="make" name="state-choice" 
      [ngModel]="sale?.tradeIn?.vehicle?.make"
      (ngModel)="sale.tradeIn.vehicle.make = $event"
      (ngModelChange)="sale.tradeIn.vehicle.make = $event"
      name="make"
      #make="ngModel"/>
        <datalist autocomplete="off" id="make" style="max-height: 20px !important;" class="select-input">
        <option *ngFor="let item of vehicleMakes" value="{{item.make_Name}}">
        </datalist>
      </div>
    <!--Model-->
    <div class="col-md-12 cstm-col">
      <label (dblclick)="toggleValue2('Model')" >{{labelModel}}</label>
      <input autocomplete="model-name" list="model" name="state-choice" 
      [ngModel]="sale?.tradeIn?.vehicle?.model"
      (ngModel)="sale.tradeIn.vehicle.model = $event"
      (ngModelChange)="sale.tradeIn.vehicle.model = $event"
      name="model"
      #model="ngModel"/>
    </div>
     <!--Exterior Color 1-->
     <div class="col-md-12 cstm-col">
      <label (dblclick)="toggleValue2('Ext Color 1')" >{{labelExertiorColor1}}</label>
      <input autocomplete="puchasedAs-name"  list="extColor" name="state-choice" 
      [ngModel]="sale?.tradeIn?.vehicle?.exteriorColor1"
      (ngModel)="sale.tradeIn.vehicle.exteriorColor1 = $event"
      (ngModelChange)="sale.tradeIn.vehicle.exteriorColor1 = $event"
      name="exteriorColor1"
      #exteriorColor1="ngModel"/>
        <datalist id="extColor" class="select-input">
        <option value="Silver">
        <option value="Black">
        </datalist>
      </div>
     <!--SERIES-->
     <div class="col-md-12 cstm-col">
      <label (dblclick)="toggleValue2('Style')" >{{labelStyle}}</label>
      <input autocomplete="v-name"  list="style" name="state-choice" 
        [ngModel]="sale?.tradeIn?.vehicle?.series"
        (ngModel)="sale.tradeIn.vehicle.series = $event"
        (ngModelChange)="sale.tradeIn.vehicle.series = $event"
        name="series"
        #series="ngModel"/>
        <datalist id="style" class="select-input">
        <option value="2 Door">
        <option value="4 Door">
        </datalist>
      </div>
    <!--Displacement-->
    <div class="col-md-12 cstm-col">
    <label (dblclick)="toggleValue2('Displacement')" >{{labelDisplacement}}</label>
    <input 
      [ngModel]="sale?.tradeIn?.vehicle?.vehicleEngines?.displacementCC"
      (ngModel)="sale.tradeIn.vehicle.vehicleEngines.displacementCC = $event"
      (ngModelChange)="sale.tradeIn.vehicle.vehicleEngines.displacementCC = $event"
      name="displacementCC"
      #displacementCC="ngModel"/>
      
    </div>
   <!--Cylinders-->
   <div class="col-md-12 cstm-col">
    <label (dblclick)="toggleValue2('Cylinders')" >{{labelCylinders}}</label>
    <input autocomplete="v-name"   name="state-choice" 
    [ngModel]="sale?.tradeIn?.vehicle?.vehicleEngines?.engineCylinders"
      (ngModel)="sale.tradeIn.vehicle.vehicleEngines.engineCylinders = $event"
      (ngModelChange)="sale.tradeIn.vehicle.vehicleEngines.engineCylinders = $event"
      name="engineCylinders"
      #engineCylinders="ngModel"/>
    </div>
       <!--DECAL NUMBER-->
       <div class="col-md-12 cstm-col">
      <label (dblclick)="toggleValue2('Decal Number')" >{{labelDecalNumber}}</label>
      <input autocomplete="puchasedAs-name" type="text" value="0.00" class="cstm-input" 
      [ngModel]="sale?.tradeIn?.itemVehicleTransientDetails?.decalNumber"
      (ngModel)="sale.tradeIn.itemVehicleTransientDetails.decalNumber = $event"
      (ngModelChange)="sale.tradeIn.itemVehicleTransientDetails.decalNumber = $event"
      name="decalNumber"
      #decalNumber="ngModel">
      </div>
     <!--tag-->
     <div class="col-md-12 cstm-col">
      <label (dblclick)="toggleValue2('Tag')" >{{labelTag}}</label>
      <input autocomplete="puchasedAs-name" type="text" value="0.00" class="cstm-input" 
      [ngModel]="sale?.tradeIn?.itemVehicleTransientDetails?.tag"
      (ngModel)="sale.tradeIn.itemVehicleTransientDetails.tag = $event"
      (ngModelChange)="sale.tradeIn.itemVehicleTransientDetails.tag = $event"
      name="tag"
      #tag="ngModel">
      </div>
    <!--Tag Expires-->
    <div class="col-md-12 cstm-col">
    <label (dblclick)="toggleValue2('Tag Expires')" >{{labelTagExpires}}</label>
    <input autocomplete="puchasedAs-name" type="date" value="" class="cstm-input" 
    [ngModel]="sale?.tradeIn?.itemVehicleTransientDetails?.tagExpires | date:'yyyy-MM-dd'"
    (ngModel)="sale.tradeIn.itemVehicleTransientDetails.tagExpires = $event"
    (ngModelChange)="sale.tradeIn.itemVehicleTransientDetails.tagExpires = $event"
    name="tagExpires"
    #tagExpires="ngModel">
    </div>
        <!--MILEAGE-->
      <div class="sv-rw">
        <label (dblclick)="toggleValue2('Mileage')" >{{labelMileage}}</label>
        <input autocomplete="puchasedAs-name" type="number" value="0" class="cstmnumeric-input" 
        [ngModel]="sale?.tradeIn?.itemVehicleTransientDetails?.mileage || 0"
        (ngModel)="sale.tradeIn.itemVehicleTransientDetails.mileage = $event"
        (ngModelChange)="sale.tradeIn.itemVehicleTransientDetails.mileage = $event"
        name="mileage"
        #mileage="ngModel">
      </div>
      <div class="sv-rw">
        <label (dblclick)="toggleValue2('Weight')" >{{labelWeight}}</label>
        <input autocomplete="puchasedAs-name" type="text" value="0.00" class="cstmnumeric-input" 
        [ngModel]="sale?.tradeIn?.vehicle?.weight || 0"
        (ngModel)="sale.tradeIn.vehicle.weight = $event"
        (ngModelChange)="sale.tradeIn.vehicle.weight = $event"
        name="weight"
        #weight="ngModel">
      </div>
     
