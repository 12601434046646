import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Person } from 'src/app/_models/person';
import { Website } from 'src/app/_models/website';
import { NgForm } from '@angular/forms';
import { WebsiteEmployee } from 'src/app/_models/websiteEmployees';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { PersonPhoto } from 'src/app/_models/personphoto';
import { ActivatedRoute } from '@angular/router';
import { PersonDataService } from 'src/app/_services/personinfo/person-data.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { WebsiteService } from 'src/app/_services/advertising/website.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserWebsiteToReturnDto } from 'src/app/_models/DTOS/UserWebsiteToReturnDto';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { AddPersonModalComponent } from 'src/app/people/add-person-modal/add-person-modal.component';
import { EditdepartmentModalComponent } from './editdepartment-modal/editdepartment-modal.component';
import { CompanyDepartmentType } from 'src/app/_models/CompanyDepartmentType';
import { SelectexistingpersonModalComponent } from 'src/app/modals/selectexistingperson-modal/selectexistingperson-modal.component';
import { AddnewPersonmodalComponent } from 'src/app/people/addnew-personmodal/addnew-personmodal.component';
import { CompanyDepartmentsService } from 'src/app/_services/companyDepartments/company-departments.service';
import { Observable } from 'rxjs';
import { WebsiteEmployeeService } from 'src/app/_services/advertising/website-employee.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.css']
})
export class EmployeesComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() website: Website;
  companyGuid: string;
  isDelete: boolean;
  photos: PersonPhoto[];
  employee: Person;
  personToEdit: Person;
  websiteEmployees: WebsiteEmployee[];
  isSelected: boolean;
  selectedCompany1: UserAssignedToCompany;
  userAssigned: UserWebsiteToReturnDto[];
  persons: PersonLinqResultToReturnDto[];
  websiteEmployee: WebsiteEmployee;
  @Input() companyDepartmentTypes: CompanyDepartmentType[];
  bsModalRef: any;
  bsModalRef1: any;
  pagination = {} as Pagination;
  userParams = {} as any;
  constructor(private websiteService: WebsiteService, private companyDepartments: CompanyDepartmentsService,
    private alertify: ToastrService,
    private modalService: BsModalService,
    private websiteEmployeeService: WebsiteEmployeeService,
    private route: ActivatedRoute,
    private personService: PersonDataService,
    private authService: AuthService) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company =>  {this.selectedCompany1 = company; });
    this.route.params.subscribe(params => {
      const newLocal = 'parentGuid';
      this.companyGuid = params[newLocal];
    });
    this.getCompanyDepartmentTypesz();
      this.websiteEmployees = this.website.websiteEmployees;
      
      this.isSelected = false;
      this.getEmployees();
  }
  getCompanyDepartmentTypesz() {
    this.companyDepartments.listCompanyDepartments(this.website.id, true, false).subscribe(data => {
      this.companyDepartmentTypes = data;
      console.log(data);
    }, error => { console.log(error); });
  }
  getEmployees() {
    this.userParams.filter = 'Employee';
    this.personService.getEmployoeeLinqList(this.website.websiteGuid, this.pagination.currentPage,
      this.pagination.itemsPerPage, this.userParams)
    .subscribe((data: PaginatedResult <PersonLinqResultToReturnDto[]>) => {
      this.persons = data.result;
      console.log(data.result);
      this.pagination = data.pagination;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Persons Retrieved.');
    });
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.getEmployees();
  }
  // getEmployees() {
  //   this.websiteService.getWebsitesEmployees(this.website.companyGuid).subscribe(data => {
  //    // this.userAssigned = data; console.log(data);
  //     this.employee = this.websiteEmployees[0].person;
  //   });
  // }
  selectedEmployee(e) {
    this.employee = e.person;
    const initialState = {
      person:  e.person,
      selectedCompany: this.selectedCompany1
    };
    this.editPerson(e);
  }

  delete(i) {
    if (i.websiteId != null) {
      this.isDelete = false;
    } else {
      this.isDelete = true;
  }
    this.websiteService.unpublishEmployee(i.id, i.websiteGuid).subscribe(data => {
    //  this.userAssigned = data;
    this.persons = data.result;
    console.log(data.result);
    this.pagination = data.pagination;
    }, error => {this.alertify.error(error); }, () => {
      this.alertify.success('Employeed updated');
    });
  }

  addNew() {
    const initialState = {
      selectedCompany: this.selectedCompany1
    };
    this.bsModalRef1 = this.modalService.show(AddnewPersonmodalComponent, { initialState,  class: 'modal-lg'});
    this.bsModalRef1.content.update.subscribe((values) => {
      console.log(values);
      this.websiteService
        .addNewEmployee(
          this.selectedCompany1.parentGuid,
          this.website.websiteGuid,
          values
        )
        .subscribe(
          (data) => {
            // this.websiteEmployees = data;
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {
            this.alertify.success('Employee Added');
            this.getEmployees();
          }
        );
    });
  }

 editDepartMent(e: PersonLinqResultToReturnDto) {
   console.log(this.companyDepartmentTypes);
  this.websiteEmployeeService.getWEbsiteEmployeeById(e.id).subscribe(data =>    {
    this.websiteEmployee  = data;
    console.log(data);
  }, error => {this.alertify.error(error); }, () => {
    this.alertify.success('Success');
    console.log(this.websiteEmployee);
    const initialState = {
      person: this.websiteEmployee ,
      companyDepartmentTypes: this.companyDepartmentTypes
    };
    // initialState,
    this.bsModalRef1 = this.modalService.show(EditdepartmentModalComponent,  {initialState, class: 'modal-lg'});
    this.bsModalRef1.content.update.subscribe((values) => {
      this.websiteService.updateEmployeeDepartMentType(e.id, this.website.websiteGuid, values.id).subscribe(data2 => {
        this.persons = data2.result;
        console.log(data2.result);
        this.pagination = data2.pagination;
      });
      this.alertify.success('Customer Retrived');
    }, error => {this.alertify.error(error); }, () => {
     
    });
  });
 }
  editPerson(e: PersonLinqResultToReturnDto) {
    console.log(e.id);
    this.personService.getPerson(this.selectedCompany1.parentGuid, e.personId).subscribe(data =>    {
      this.employee  = data; 
      this.photos = data.personPhotos;
    }, error => {this.alertify.error(error); }, () => {
      this.alertify.success('Success');
      const initialState = {
        person: this.employee ,
        selectedCompany: this.selectedCompany1,
        photos: this.photos
      };
      console.log(this.employee );
      this.bsModalRef1 = this.modalService.show(AddPersonModalComponent, {initialState, class: 'modal-lg'});
      this.bsModalRef1.content.userWebsiteToReturnDto.subscribe(data => {
        console.log(data);
        this.photos = data.personPhotos;
      });
      this.bsModalRef1.content.update.subscribe((values) => {
        this.personToEdit = values;
        this.personToEdit.id = e.personId;
        this.personService.edit(this.selectedCompany1.parentGuid, this.personToEdit).subscribe(data => {
          this.employee = data.person;
         // this.loadPeople();
        });
        this.alertify.success('Customer Retrived');
      }, error => {this.alertify.error(error); }, () => {
      });
    });
  }

  addExisting() {
    const initialState = {
    };
    this.bsModalRef1 = this.modalService.show(SelectexistingpersonModalComponent, {initialState, class: 'modal-lg'});
    this.bsModalRef1.content.emittedValues.subscribe(data => {
      console.log(data);
      this.websiteService.addEmployee(data.id, this.website.websiteGuid).subscribe(data2 => {
        this.persons = data2.result;
        console.log(data2.result);
        this.pagination = data2.pagination;
      });
    });
  }
}
