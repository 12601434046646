<div class="customer-form-sec" style="width: 900px !important">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div id="customer" class="tab-pane fade in active show">
          <div class="modal-header form-sec tab-content">
            <h4 *ngIf="isWarrantyCompany" class="modal-title pull-left">Warranty Company</h4> 
            <h4 *ngIf="isLienHolder" class="modal-title pull-left">Lienholder</h4> 
            <h4 *ngIf="isInsuranceCompany" class="modal-title pull-left">Insurance Company</h4> 
            <button
              type="button"
              class="close pull-right"
              aria-label="Close"
              (click)="bsModalRef.hide()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="customer-form-sec">
              <div class="container-fluid">
                <div class="row">
                  <div class="form-sec tab-content col-md-12">       
                    <div id="customer" class="tab-pane fade in active show">
                      <div class="row">
                        <div class="col-md-6 cstm-col">
                          <div class="inline-blk">
                            <label>Name</label>
                            <input type="text" class="cstm-input" name="name"
                            [ngModel]="sale?.warrantyCompany?.name"
                            (ngModel)="sale.warrantyCompany.name = $event"
                            (ngModelChange) = "sale.warrantyCompany.name = $event; "
                            (blur) = "onChange($event)" >
                          </div>

                        </div>
                        
            
                        <div class="col-md-6 cstm-col">
                          <div class="inline-blk">
                            <label>Zip</label>
                            <input type="text" class="cstm-input" name="zip"
                            [ngModel]="sale?.warrantyCompany?.addressDetail?.zip"
                            (ngModel)="sale.warrantyCompany.addressDetail.zip = $event"
                            (ngModelChange) = "sale.warrantyCompany.addressDetail.zip = $event; "
                            (blur) = "onChange($event)" >
                          </div>
                     
                        </div>
                      
                       
                        <div class="col-md-6 cstm-col">
                          <div class="inline-blk">
                            <label>Street</label>
                            <input type="text" class="cstm-input" name="street"
                            [ngModel]="sale?.warrantyCompany?.addressDetail?.street"
                            (ngModel)="sale.warrantyCompany.addressDetail.street = $event"
                            (ngModelChange) = "sale.warrantyCompany.addressDetail.street = $event; "
                            (blur) = "onChange($event)" >
                          </div>
                      
                        </div>
                        
                        <div class="col-md-6 cstm-col">
                          <label>City</label>
                          <input type="text" class="cstm-input" name="city"
                          [ngModel]="sale?.warrantyCompany?.addressDetail?.city"
                          (ngModel)="sale.warrantyCompany.addressDetail.city = $event"
                          (ngModelChange) = "sale.warrantyCompany.addressDetail.city = $event; "
                          (blur) = "onChange($event)" >
                        </div>

                        <div class="col-md-6 cstm-col">
                          <label>State</label>
                          <input type="text" class="cstm-input" name="state"
                          [ngModel]="sale?.warrantyCompany?.addressDetail?.state"
                          (ngModel)="sale.warrantyCompany.addressDetail.state = $event"
                          (ngModelChange) = "sale.warrantyCompany.addressDetail.state = $event; "
                          (blur) = "onChange($event)" >
                        </div>
  
                        <div class="col-md-6 cstm-col">
                          <label>Phone</label>
                          <input type="text" class="cstm-input" name="phone"
                          [ngModel]="sale?.warrantyCompany?.phone"
                          (ngModel)="sale.warrantyCompany.phone = $event"
                          (ngModelChange) = "sale.warrantyCompany.phone = $event; "
                          (blur) = "onChange($event)" >
                        </div>

                        <div class="col-md-6 cstm-col">
                          <label>County</label>
                          <input type="text" class="cstm-input" name="county"
                          [ngModel]="sale?.warrantyCompany?.addressDetail?.county"
                          (ngModel)="sale.warrantyCompany.addressDetail.county = $event"
                          (ngModelChange) = "sale.warrantyCompany.addressDetail.county = $event; "
                          (blur) = "onChange($event)" >
                        </div>

                        <div class="col-md-6 cstm-col state-sec">
                          <label>Email</label>
                          <input type="text" class="cstm-input" name="email"
                          [ngModel]="sale?.warrantyCompany?.email"
                          (ngModel)="sale.warrantyCompany.email = $event"
                          (ngModelChange) = "sale.warrantyCompany.email = $event; "
                          (blur) = "onChange($event)" >
                        </div>
                        <div class="col-md-6 cstm-col">
                          <label>Fax</label>
                          <input type="text" class="cstm-input" name="fax"
                          [ngModel]="sale?.warrantyCompany?.fax"
                          (ngModel)="sale.warrantyCompany.fax = $event"
                          (ngModelChange) = "sale.warrantyCompany.fax = $event; "
                          (blur) = "onChange($event)" >
                        </div>
                        <div class="col-md-6 cstm-col">
                          <label>Ein</label>
                          <input type="text" class="cstm-input" name="ein"
                          [ngModel]="sale?.warrantyCompany?.ein"
                          (ngModel)="sale.warrantyCompany.ein = $event"
                          (ngModelChange) = "sale.warrantyCompany.ein = $event; "
                          (blur) = "onChange($event)" >
                        </div>
                        <div class="col-md-6 cstm-col">
                          <label>Ein</label>
                          <input type="text" class="cstm-input" name="efn"
                          [ngModel]="sale?.warrantyCompany?.efn"
                          (ngModel)="sale.warrantyCompany.efn = $event"
                          (ngModelChange) = "sale.warrantyCompany.efn = $event; "
                          (blur) = "onChange($event)" >
                        </div>

                        <!--Warrany Data -->
                      </div>
                      <div class="row" *ngIf="isWarrantyCompany">
                        <div class="col-md-12 cstm-col">
                          <div class="col-md-12 cstm-col" >
                            <label style="width: 200px;">Warranty Miles</label>
                            <input type="number" class="cstm-input" name="wMiles"
                              [ngModel]="sale?.wMiles"
                              (ngModel)="sale.wMiles = $event"
                              (ngModelChange) = "sale.wMiles = $event; "
                              (blur) = "onChange($event)" >
                          </div>
                          <div class="col-md-12 cstm-col">
                            <label style="width: 200px;" >Warranty Months</label>
                            <input type="number" class="cstm-input" name="wMonths"
                            [ngModel]="sale?.wMonths"
                            (ngModel)="sale.wMonths = $event"
                            (ngModelChange) = "sale.wMonths = $event; "
                            (blur) = "onChange($event)" >
                          </div>
                          <div class="col-md-12 cstm-col">
                            <label style="width: 200px;">Warranty Deductible</label>
                            <input type="number" class="cstm-input" name="wDeductible"
                            [ngModel]="sale?.wDeductible"
                            (ngModel)="sale.wDeductible = $event"
                            (ngModelChange) = "sale.wDeductible = $event; "
                            (blur) = "onChange($event)" >
                          </div>
                          <div class="col-md-12 cstm-col">
                            <label style="width: 200px;">Extra 1</label>
                            <input type="text" class="cstm-input" name="wExtra1"
                            [ngModel]="sale?.wExtra1"
                            (ngModel)="sale.wExtra1 = $event"
                            (ngModelChange) = "sale.wExtra1 = $event; "
                            (blur) = "onChange($event)" >
                          </div>
                          <div class="col-md-12 cstm-col">
                            <label style="width: 200px;">Extra 2</label>
                            <input type="text" class="cstm-input" name="wExtra2"
                            [ngModel]="sale?.wExtra2"
                            (ngModel)="sale.wExtra2 = $event"
                            (ngModelChange) = "sale.wExtra2 = $event; "
                            (blur) = "onChange($event)" >
                          </div>
                          </div>
                          </div>


                    </div>
            
                  </div>
            
                </div>
              </div>
            </div>


          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              (click)="bsModalRef.hide()"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-success" (click)="update()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


