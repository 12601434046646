import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { ChildActivationStart, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UploadimageModalComponent } from './uploadimage-modal/uploadimage-modal.component';
import { environment } from 'src/environments/environment';
import { WebsiteMenu } from 'src/app/_models/websites/WebsiteMenu';
import { ContentPhotos } from 'src/app/_models/websites/WebsiteContentPhotos';
import { BannerPagedImagesToReturnDto } from 'src/app/_models/DTOS/BannerandPageImagesToReturnDto';
import { SortOrderDto } from 'src/app/_models/DTOS/sortOrderDto';
import { SortDto } from 'src/app/_models/DTOS/SortDto';
import { WebsiteService } from '../../website.service';
import { BusyService } from 'src/app/_services/busy.service';
import { NewWebsiteMenuDto } from 'src/app/_models/DTOS/newWebsiteMenuDto';
import { ContentPhoto } from 'src/app/_models/websites/ContentPhoto';


@Component({
  selector: 'app-websiteimagemanager',
  templateUrl: './websiteimagemanager.component.html',
  styleUrls: ['./websiteimagemanager.component.scss']
})
export class WebsiteimagemanagerComponent implements OnInit {
 @Input() websiteMenu = {} as WebsiteMenu;
  contentPhotos = [] as ContentPhotos[];
  @Input() contentPagePhotos = [] as ContentPhotos[];
  @Input() contentBannerPhotos = [] as ContentPhotos[];
  bannerPagedImagesToReturnDto = {} as BannerPagedImagesToReturnDto;
  contentPhoto = {} as ContentPhotos;
  bsModalRef: any;
  sortOrderDto = {} as SortOrderDto;
  sortOrderDtos = [] as SortOrderDto[];
  sortDto = {} as SortDto;
  baseUrl = environment.apiUrl;
  constructor(private modalService: BsModalService,
    private router: Router, private websiteService: WebsiteService,
    private busyService: BusyService) {  }
  ngOnInit() {
      this.contentBannerPhotos = this.websiteService.contentBannerPhotos;
      this.contentPagePhotos = this.websiteService.contentPagePhotos;
    this.getImages();
  }
getImages() {
  this.websiteService.listImagesCategorized(this.websiteMenu.id).subscribe(data => {
    this.contentBannerPhotos = data.bannerImages;
    this.contentPagePhotos = data.pageImages;
    console.log(data);
  }, error => {
  }, () => {

  });
}
  drop(event: CdkDragDrop<string[]>) {
  // moveItemInArray(this.artists, event.previousIndex, event.currentIndex);
  if (event.previousContainer === event.container) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }
  // console.log(this.artists);
//  console.log(event.container.data);
 this.contentPhotos = JSON.parse(JSON.stringify(event.container.data));
  // console.log( this.contentPhotos);
  // console.log(event.currentIndex);
  // console.log(event.item);
  this.busyService.busy();
 // if(event.container.id === 'page_images') {
  let i = 1;
  this.sortOrderDtos = [];
  this.contentPhotos.forEach((element) => {
    this.sortOrderDto.menuId = element.websiteMenuId;
    this.sortOrderDto.sort = i;
    this.sortOrderDto.id = element.id;
    this.createArray(this.sortOrderDto);
    i = i + 1;
  });
  // else {
  this.sortDto.SortOrderDtos = this.sortOrderDtos;
  this.websiteService.setSortOrders(this.websiteMenu.id, this.sortDto).subscribe(data => {
     this.contentPagePhotos = data.pageImages;
     this.contentBannerPhotos = data.bannerImages;
     this.busyService.idle();
    });
 //    }
  // if(event.container.id === 'playlist_add') {
  //   // Do Something
  // }
  }
createArray(sortOrderDto: SortOrderDto) {
  this.sortOrderDtos.push(sortOrderDto);
  this.sortOrderDto = {id: null, sort: null, menuId: null};
}
  addImage() {
   // this.websiteMenu = e;
    const config = {
class: 'modal-lg',
initialState: {
  websiteMenu: this.websiteMenu,
}
};
this.bsModalRef = this.modalService.show(UploadimageModalComponent, config);
this.bsModalRef.content.entityToSubscribe.subscribe((values: ContentPhoto) => {
console.log(values);
this.websiteService.listImagesCategorized(this.websiteMenu.id).subscribe(data => {
  console.log(data);
  if (values.isBanner) {
    this.contentBannerPhotos = data.bannerImages;
  }
  if (values.isPageImage) {
    this.contentPagePhotos = data.pageImages;
  }
}, error => {

}, () => {

});
});

  }
  sortBycontentPagePhotos(prop: string) {
    return this.contentPagePhotos.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }
  sortBycontentBannerPhotos(prop: string) {
    return this.contentBannerPhotos.sort((a, b) => a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1);
  }
}
