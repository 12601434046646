
<div class="customer-form-sec">
  <div class="container-fluid">
<div class="row">
 
  <!-- style="background-color:#f0f0f0 !important;" -->
  <div class="form-sec tab-content col-md-12">
      <div id="depreciate-costs" class="tab-pane fade in active show">
        <div class="row">
          <div class="col-md-12">
            <div class="inner-row account-payable-row">
              <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->
              
              <div class="row">
                <div class="col-md-5 table-sec">
                  <table border="1" borderColor="#ccc">
                    <tr>
          <th>Id</th>
          <th>Url</th>
                      <th>Type</th>

                      <th></th>

                    </tr>
                    <tr class="td-row" *ngFor="let item of homePageCompanyPhotos">
          <td>{{item.id}}</td>
          <td>{{item.url}}</td>
                      <td><img style="height: 50px" src="{{item.url}}"></td>

                      <td>
                        <a
                          class="btn btn-primary"
                          (click)="seletedItem(item)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="View Order Detail"
                          ><i class="fa fa-fw fa-eye"></i
                        ></a>
                        <a
                          class="btn btn-primary"
                          (click)="seletedItem(item)"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Clone Order"
                          ><i class="fa fa-fw fa-files-o"></i
                        ></a>
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- /.table-sec -->
                <!--FORM-->
                <div class="col-md-4">
                <form [hidden]="!selected" #editForm="ngForm" id="editForm">

                  <div class="form-group">
                    <label>Url</label>
                    <input type="text" class="form-control" [ngModel]="companyPhoto?.url" (ngModel)="companyPhoto.url=$event"
                      name="url" #url="ngModel">
                  </div>
                  <div class="form-group">
                      <label>Type</label>
                      <input type="text" class="form-control" [ngModel]="companyPhoto?.companyPhotoType?.value" (ngModel)="companyPhoto.companyPhotoType.value=$event"
                        name="value" #value="ngModel">
                    </div>
        </form>
      </div>
                <!--RIGHT SIDE BUTTONS-->
                <div class="customer-sidebar sidebar-with-btns col-md-1">
                  <div class="inner-sidebar">
                    <ul>
                      <li *ngIf="editForm.dirty">
                        <button class="cstm-btn img-blk-btn">
                          <img src="assets/images/ap-icon-1.png">
                          <span>Save</span>
                        </button>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.row -->
      </div>
    </div>
    <button (click) = "filterPhotos()">Filter Photos</button>
   <app-companyhomepage-photos [website] = "website"  [company] = "company" [photos] = "homePageCompanyPhotos"></app-companyhomepage-photos>
    
    </div>
</div>





