import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { BannerPagedImagesToReturnDto } from '../_models/DTOS/BannerandPageImagesToReturnDto';
import { NewWebsiteMenuDto } from '../_models/DTOS/newWebsiteMenuDto';
import { SortDto } from '../_models/DTOS/SortDto';
import { MenuReturnWebsiteMenuParagraphDto } from '../_models/DTOS/WebsiteMenuParagraphReturndto';
import { WebsiteEmployee } from '../_models/websiteEmployees';
import { WebsiteBusinessType } from '../_models/websites/WebsiteBusinessTypes';
import { ContentPhotos } from '../_models/websites/WebsiteContentPhotos';
import { WebsiteMenu } from '../_models/websites/WebsiteMenu';
import { Website } from '../_models/websites/websites';
import { WebsiteTemplates } from '../_models/websites/WebsiteTemplates';


@Injectable({
  providedIn: 'root'
})
export class WebsiteService {
  baseUrl = environment.apiUrl;
  contentPagePhotos = [] as ContentPhotos[];
 contentBannerPhotos = [] as ContentPhotos[];

  constructor(private http: HttpClient) { }

  setSortOrders(menuid: any, sort: SortDto): Observable<BannerPagedImagesToReturnDto> {
    return this.http.post<BannerPagedImagesToReturnDto>(this.baseUrl + 'websitephotos/sortordersofimages/' + menuid, sort);
  }
  listImagesCategorized(menuid: any): Observable<BannerPagedImagesToReturnDto> {
    return this.http.get<BannerPagedImagesToReturnDto>(this.baseUrl + 'websitephotos/getmenuimagescategorized/' + menuid);
  }

  listWebsiteIndustryTypes(): Observable<WebsiteBusinessType[]> {
    return this.http.get<WebsiteBusinessType[]>(this.baseUrl + 'website/getindustrytypes');
  }
  getWebsiteIndustryTypeById(id: number): Observable<WebsiteBusinessType> {
    return this.http.get<WebsiteBusinessType>(this.baseUrl + 'website/getindustrytype/' + id);
  }
  addWebsiteIndusrtyType(type: string): Observable<WebsiteBusinessType[]> {
    return this.http.get<WebsiteBusinessType[]>(this.baseUrl + 'website/nvewwebsitebusinesstype/' + type);
  }
  listWebsites(): Observable<Website[]> {
    return this.http.get<Website[]>(this.baseUrl + 'website/getwebsites/');
  }
  getWebsiteMenuById(id: any) {
    return this.http.get<WebsiteMenu>(this.baseUrl + 'website/getmenuby/' + id);
  }
  markIndustryTypeInactive(id): Observable<WebsiteBusinessType[]>{
    return this.http.get<WebsiteBusinessType[]>(this.baseUrl + 'website/markbussinesstypedeleted/' + id);
  }
  markWebsiteInactive(id): Observable<Website[]>{
    return this.http.get<Website[]>(this.baseUrl + 'website/markwebsitedeleted/' + id);
  }
  getWebsiteById(id: any): Observable<Website> {
    return this.http.get<Website>(this.baseUrl + 'website/getwebsitebyid/' + id);
  }
  addWebsite(id: number, name: string, templateId: any): Observable<Website[]> {
    return this.http.get<Website[]>(this.baseUrl + 'website/newwebsite/' + name + '/' + id + '/' + templateId);
  }
  updateWebsite(websiteid: any, website: Website) {
    return this.http.post<Website>(this.baseUrl + 'website/updatewebsitebasedetails/' + websiteid, website);
  }
  updateWebsiteSetting(websiteid: any, website: Website) {
    return this.http.post<Website>(this.baseUrl + 'website/updatewebsitebasesettings/' + websiteid, website);
  }
  markMenuActive(menuid: any, websiteid: any) {
    return this.http.get<any>(this.baseUrl + 'websitemenu/markwebsitemenudeleted/' + menuid + '/' + websiteid);
  }
  editWebsiteMenu(menu: WebsiteMenu): Observable<Website> {
    return this.http.post<Website>(this.baseUrl + 'website/editwebsitemenu/', menu);
  }
  addWebsiteMenu(menu: NewWebsiteMenuDto, websiteid: any): Observable<WebsiteMenu[]> {
    return this.http.post<WebsiteMenu[]>(this.baseUrl + 'websitemenu/postnewmenu/' + websiteid, menu);
  }
  listWebsiteTemplates(): Observable<WebsiteTemplates[]> {
    return this.http.get<WebsiteTemplates[]>(this.baseUrl + 'website/listwebsitetemplates/');
  }
  markWebsiteTemplateInactive(id: any): Observable<WebsiteTemplates[]> {
    return this.http.get<WebsiteTemplates[]>(this.baseUrl + 'website/markwebsitetemplateinactive/' + id);
  }

  addWebsiteTemplate(e: WebsiteTemplates): Observable<WebsiteTemplates[]> {
    return this.http.post<WebsiteTemplates[]>(this.baseUrl + 'website/postnewtemplate/', e);
  }

}
