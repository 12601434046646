import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Item } from '../_models/item';
import { VehicleService } from '../_services/vehicleserv/vehicle.service';
@Injectable({
    providedIn: 'root'  // **** ADD THIS LINE ****
  })
export class VehicleNewResolver implements Resolve<Item> {
    constructor( private vehicleService: VehicleService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Item> {
        // tslint:disable-next-line:no-string-literal
        return this.vehicleService.getNewVehicle(route.params['parentGuid']).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving new vehicle.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
