import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ServiceOffer } from 'src/app/_models/serviceoffer';

@Component({
  selector: 'app-serviceofferadd-modal',
  templateUrl: './serviceofferadd-modal.component.html',
  styleUrls: ['./serviceofferadd-modal.component.css']
})
export class ServiceofferaddModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() serviceOfferDept: any;
  serviceOffer = {} as ServiceOffer;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log(this.serviceOfferDept);
    this.serviceOffer.departmentName = this.serviceOfferDept;
    this.serviceOffer.booletOne = '';
    this.serviceOffer.booletTwo = '';
    this.serviceOffer.booletThree = '';
    this.serviceOffer.booletFour = '';
    this.serviceOffer.smallDescription = '';
  }

  onUpdate(e) {
    this.serviceOffer = e.value;
    this.emittedValues.emit(this.serviceOffer);
    this.bsModalRef.hide();
  }

}
