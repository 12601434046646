<br><br>
<div class="customer-form-sec ">
    <div class="container-fluid">
<label for="options">Options:</label>
<div class="col-md-10">
  <div class="row">
      <div  *ngFor="let option of vehicleDefaultOptions" class="form-group col-md-2">
          <div >
              <label class="container">  {{option.name}}
                  <input type="checkbox" 
                         name="options"
                         value="{{option.name}}"
                         [(ngModel)]="option.checked"
                         (change) = "addOption(option)"
                         />
                         <span class="checkmark"></span>
                 
              </label>
          </div>
      </div>
  </div>
</div>
 