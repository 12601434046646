import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyProfileResolver } from '../_resolvers/companyprofile.resolver';
import { BuyersguidelistComponent } from './buyersguidelist/buyersguidelist.component';





const routes: Routes = [
     {path: 'home/:parentGuid', component: BuyersguidelistComponent, resolve: {
    }},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BuyersGuideRoutingModule {}
