import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RealPropertyMath } from 'src/app/_classes/dealinfoclasses/realpropertymath';
import { AddRepairDto } from 'src/app/_models/DTOS/addrepairdto';
import { Pagination } from 'src/app/_models/pagination';
import { RealProperty } from 'src/app/_models/RealProperty';
import { RealPropertyRepairs } from 'src/app/_models/RealPropertyRepairs';
import { RealPropertyRepairTypes } from 'src/app/_models/RealPropertyRepairTypes';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { RealPropertyService } from 'src/app/_services/realpropertyservices/realpropertyservice';
import { EdithouserepairModalComponent } from '../edithouserepair-modal/edithouserepair-modal.component';
import { RealpropAddrepairComponent } from '../realprop-addrepair/realprop-addrepair.component';

@Component({
  selector: 'app-edit-realproperty',
  templateUrl: './edit-realproperty.component.html',
  styleUrls: ['./edit-realproperty.component.css']
})
export class EditRealpropertyComponent implements OnInit {
property = {} as RealProperty;
@ViewChild('editForm', {static: true}) editForm: NgForm;
house = {} as RealProperty ;
repair = {} as AddRepairDto;
selectedCompany: UserAssignedToCompany;
repairTypes = [] as RealPropertyRepairTypes[];
repairs = [] as any[];
pagination: Pagination;
bsModalRef: any;
isCarryingCost = false;
isRepairs = false;
isHouseInfo = false;
isPhotos = false;
  constructor(private alertify: ToastrService,
    private authService: AuthService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private realPropertyService: RealPropertyService,
    private realPropertyMath: RealPropertyMath,
    private router: Router) { }
    tabClicked(e) {
      this.isCarryingCost = false;
      this.isRepairs = false;
      this.isHouseInfo = false;
      this.isPhotos = false;
      if (e === 'isCarryingCost') {  this.isCarryingCost = true; }
      if (e === 'isRepairs') {  this.isRepairs = true;}
      if (e === 'isHouseInfo') {  this.isHouseInfo = true; }
      if (e === 'isPhotos') {  this.isPhotos = true; }
    }
  ngOnInit() {
  this.load();
  this.isHouseInfo = true;
    this.authService.company.subscribe(data => { this.selectedCompany = data; });
  }
  onSubmit(e) {
    console.log(this.house);
    this.realPropertyService.edithouse(this.house, this.selectedCompany.parentGuid).subscribe(data => { console.log(data); },
    error => { this.alertify.error(error); }, () => { this.alertify.success('Success!');   this.realPropertyMath.formatPropertyNumbers(this.property).then(data => { this.property = data; }); }); 
  }
  load() {
    this.route.data.subscribe(data => {
      this.property = data.property.house;
      this.house = data.property.house;
      this.repairTypes = data.property.repairTypes;
      this.repairs = data.repairs.result;
      this.pagination = data.repairs.pagination;
      console.log(data);
    });
    this.realPropertyMath.formatPropertyNumbers(this.property).then(data => { this.property = data; });
  //  this.repairs = this.house.realPropertyRepairs;
  }
  print(){}
  add() {
    console.log(this.repairTypes);
    const initialState = {
      selectedCompany: this.selectedCompany,
      repairTypes:  this.repairTypes
    };
    this.bsModalRef = this.modalService.show(RealpropAddrepairComponent, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.update.subscribe(data => {
      console.log(data);
      this.repair = data;
      this.repair.realPropertyId = this.house.id;
        this.realPropertyService.addRepair( this.house.id,  this.repair, this.selectedCompany.parentGuid).subscribe(
          (value) => {
            this.repairs = value.realPropertyRepairs;
            console.log(value);
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {
            this.alertify.success('Person Added');
          //  this.load();
            this.getRepairs();
          }
        );
    });
  }
  getRepairs() {
    this.realPropertyService.getLinqRepairList(this.house.id, 1, 1000).subscribe(data => { 
      this.repairs = data.result;
    });
  }
  edit(e) {
    console.log(e);
    const initialState = {
      selectedCompany: this.selectedCompany,
      repairTypes: this.repairTypes,
      repair: e
    };
    this.bsModalRef = this.modalService.show(EdithouserepairModalComponent, {initialState, class: 'modal-lg'});
    this.bsModalRef.content.update.subscribe(data => {
      console.log(data);
        this.realPropertyService.editRepair(this.house.id, data, this.selectedCompany.parentGuid).subscribe(
          (value) => {
            console.log(value);
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {
            this.alertify.success('Person Added');
          //  this.load();
            this.getRepairs();
          }
        );
    });
  }
  deleteRepair(e) {
    this.realPropertyService.deleteRepair(e.id, this.house.id).subscribe(data => {
      this.house = data;
    }, error => { this.alertify.error(error); }, () => { this.alertify.success('Repair Deleted');   this.getRepairs();
  });
  }
  termChanged() {

  }
  aprChanged(){}
  termEntered(){}
  paymentChanged(){}
  paymentEntered(){}
  aprEntered(){}
}
