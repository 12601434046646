import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { VehicleBuyersGuide } from 'src/app/_models/buyersguidemodels/VehicleBuyersGuide';
import { BuyersguideaddComponent } from '../buyersguideadd/buyersguideadd.component';

@Component({
  selector: 'app-buyersguidelist',
  templateUrl: './buyersguidelist.component.html',
  styleUrls: ['./buyersguidelist.component.scss']
})
export class BuyersguidelistComponent implements OnInit {
  vehicleBuyersGuide = [] as VehicleBuyersGuide[];
  bsModalRef1: any;

  constructor(private modalService: BsModalService,
    private route: ActivatedRoute) { }

  ngOnInit() {
  }

}
