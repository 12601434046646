import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { VehicleBodyTypeListComponent } from './vehicle-body-type-list/vehicle-body-type-list.component';
import { VehicleBodyTypeModalComponent } from './vehicle-body-type-list/vehicle-body-type-modal/vehicle-body-type-modal.component';
import { ListRoutingModule } from './list-routing.module';
import { NavListComponent } from './nav-list/nav-list.component';
import { VehicleMileageCodesListComponent } from './vehicle-mileage-codes-list/vehicle-mileage-codes-list.component';
import { VehicleMileageCodesModalComponent } from './vehicle-mileage-codes-list/vehicle-mileage-codes-modal/vehicle-mileage-codes-modal.component';
import { PaymentTypeModalComponent } from './payments/payment-type-modal/payment-type-modal.component';
import { PaymenttypeGridComponent } from './payments/paymenttype-grid/paymenttype-grid.component';
import { ChartInvoiceSaletypeModaladdComponent } from './chart-invoice-sale-types-list/chart-invoice-saletype-modaladd/chart-invoice-saletype-modaladd.component';
import { ChartInvoiceSaleTypesListComponent } from './chart-invoice-sale-types-list/chart-invoice-sale-types-list.component';
import { ChartInvoiceStatusListComponent } from './chart-invoice-status-list/chart-invoice-status-list.component';
import { ChartInvoiceStatusModaladdComponent } from './chart-invoice-status-list/chart-invoice-status-modaladd/chart-invoice-status-modaladd.component';
import { AddFormModalComponent } from './labels/add-form-modal/add-form-modal.component';
import { EditFormLabelsComponent } from './labels/edit-form-labels/edit-form-labels.component';
import { FormnameGridComponent } from './labels/formname-grid/formname-grid.component';
import { LabelDoubleClickModalComponent } from './labels/label-double-click-modal/label-double-click-modal.component';
import { FormNameModalComponent } from './labels/label-modal/form-name-modal.component';

@NgModule({
    declarations: [
        VehicleBodyTypeListComponent,
        ChartInvoiceSaletypeModaladdComponent,
        VehicleBodyTypeModalComponent,
        NavListComponent,
        VehicleMileageCodesListComponent,
        VehicleMileageCodesModalComponent,
        PaymentTypeModalComponent,
        PaymenttypeGridComponent,
        ChartInvoiceSaleTypesListComponent,
        ChartInvoiceStatusListComponent,
        ChartInvoiceStatusModaladdComponent,
        AddFormModalComponent,
        EditFormLabelsComponent,
        FormNameModalComponent,
        FormnameGridComponent,
        LabelDoubleClickModalComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        ListRoutingModule,
        AgChartsAngularModule,
        AgGridModule.withComponents([]),
    ],
    providers: []
})
export class ListModule { }
