import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './home/register/register.component';
import { MemberListComponent } from './members/member-list/member-list.component';
import { MemberDetailComponent } from './members/member-detail/member-detail.component';
import { ListsComponent } from './lists/lists.component';
import { MessagesComponent } from './messages/messages.component';
import { SharedModule } from './_modules/shared.module';
import { TestErrorsComponent } from './errors/test-errors/test-errors.component';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { ServerErrorComponent } from './errors/server-error/server-error.component';
import { MemberCardComponent } from './members/member-card/member-card.component';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { MemberEditComponent } from './members/member-edit/member-edit.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoadingInterceptor } from './_interceptors/loading.interceptor';
import { PhotoEditorComponent } from './members/photo-editor/photo-editor.component';
import { TextInputComponent } from './_forms/text-input/text-input.component';
import { DateInputComponent } from './_forms/date-input/date-input.component';
import { MemberMessagesComponent } from './members/member-messages/member-messages.component';


import { HasRoleDirective } from './_directives/has-role.directive';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { PhotoManagementComponent } from './admin/photo-management/photo-management.component';
import { RolesModalComponent } from './modals/roles-modal/roles-modal.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { BottomFooterComponent } from './menus/bottom-footer/bottom-footer.component';
import {AgGridModule} from '@ag-grid-community/angular';
import {ModuleRegistry} from '@ag-grid-community/core';     // @ag-grid-community/core will always be implicitly available
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {CsvExportModule} from '@ag-grid-community/csv-export';
import { SaleModule } from './sales/sale.module';
import { InventoryModule } from './inventory/inventory.module';
import * as $AB from 'jquery';
import * as bootstrap from 'bootstrap';
import { InventoryBuyersguideComponent } from './inventory/inventory-buyersguide/inventory-buyersguide.component';

import { AdminModule } from './admin/admin.module';
import { AccountingModule } from './accounting/accounting.module';
import { CompanySeedsComponent } from './company-seeds/company-seeds.component';
import { OrderBy } from './_pipes/orderby';
import { AddPaymentModalComponent } from './loans/add-payment-modal/add-payment-modal.component';

import { ExcelService } from './_services/exportservices/excel.service';
import { SharedMonthDateFilterService } from './shared/date-filter/date-filter.component';
import { GapModalComponent } from './modals/gap-modal/gap-modal.component';
import { MiscchargAddComponent } from './modals/misccharg-add/misccharg-add.component';
import { MiscchargeEditComponent } from './modals/misccharge-edit/misccharge-edit.component';
import { EditsalenoteModalComponent } from './modals/editsalenote-modal/editsalenote-modal.component';
import { AddsalenoteModalComponent } from './modals/addsalenote-modal/addsalenote-modal.component';
import { VendorModalComponent } from './modals/vendor-modal/vendor-modal.component';
import { VendoraddModalComponent } from './modals/vendoradd-modal/vendoradd-modal.component';
import { AddcashdowntypeModalComponent } from './modals/addcashdowntype-modal/addcashdowntype-modal.component';
import { EditcashdowntypeModalComponent } from './modals/editcashdowntype-modal/editcashdowntype-modal.component';
import { AddtaxdefaultsModalComponent } from './modals/addtaxdefaults-modal/addtaxdefaults-modal.component';
import { CalculatorModalComponent } from './modals/calculator-modal/calculator-modal.component';
import { EdittaxdefaultsModalComponent } from './modals/edittaxdefaults-modal/edittaxdefaults-modal.component';
import { LabelDoubleClickModalComponent } from './modals/LabelDoubleClickModal/LabelDoubleClickModal.component';
import { AddVehicleVinModalComponent } from './modals/add-vehicle-vin-modal/add-vehicle-vin-modal.component';
import { ServiceShopModule } from './serviceshop/service-shop.module';
import { AddRepairModalComponent } from './modals/add-repair-modal/add-repair-modal.component';
import { EditVehicleRepairModalComponent } from './modals/edit-vehicle-repair-modal/edit-vehicle-repair-modal.component';
import { ProfitItemsComponent } from './modals/profit-items/profit-items.component';
import { FormAndReportsRoutingModule } from './forms/forms-routing.module';
import { PdfnewuploadnameModalComponent } from './modals/pdfnewuploadname-modal/pdfnewuploadname-modal.component';
import { AdvertisingModule } from './advertising/advertising.module';
import { PeopleModule } from './people/people.module';
import { SelectexistingpersonModalComponent } from './modals/selectexistingperson-modal/selectexistingperson-modal.component';
import { RealEstateModule } from './realestate/realestate.module';
import { AmortisationClass } from './_classes/dealinfoclasses/amortizatincl';
import { InstallmentChangedClass } from './_classes/dealinfoclasses/installmentChange';
import { RealPropertyMath } from './_classes/dealinfoclasses/realpropertymath';
import { LoanModule } from './loans/loan.module';
import { LoanCalculations } from './_classes/dealinfoclasses/loancalc';
import { PayMeComponent } from './members/pay-me/pay-me.component';
import { PaymeHistoryComponent } from './members/payme-history/payme-history.component';
import { MembersCartComponent } from './members/members-cart/members-cart.component';
import { CompanyProfileModule } from './companyprofile/companyprofile.module';
import { DatePipe } from '@angular/common';
import { MemberModule } from './members/member.module';
import { PasswordResetComponent } from './members/password-reset/password-reset.component';
import { EmailverifiedComponent } from './members/emailverified/emailverified.component';
import { SupportticketModalComponent } from './support/supportticket-modal/supportticket-modal.component';
import { BuyersGuideModule } from './buyersguid/buyersguide.module';
import { ListModule } from './lists/list.module';
import { ChartInvoiceSaleTypesListComponent } from './lists/chart-invoice-sale-types-list/chart-invoice-sale-types-list.component';
import { ActivedeletedOrallmenuComponent } from './menus/activedeleted-orallmenu/activedeleted-orallmenu.component';
import { VideoModule } from './video/video.module';
import { DeletedactiveOptionComponent } from './shared/deletedactive-option/deletedactive-option.component';
import { PdfformtypecreateComponent } from './modals/pdfformtypecreate/pdfformtypecreate.component';
import { NavloggedouttopComponent } from './home/navloggedouttop/navloggedouttop.component';
import { VideonotloggedComponent } from './video/videonotlogged/videonotlogged.component';
import { ContactComponent } from './home/contact/contact.component';
import { NewuserComponent } from './home/newuser/newuser.component';
import { AftercompanycreatedreloadComponent } from './home/aftercompanycreatedreload/aftercompanycreatedreload.component';
import { QuickBooksModule } from './quickbooks/quickbooks.module';
import { ListQbModule } from './listsquickbooks/listqb.module';
import { FeatureCreateComponent } from './home/feature-create/feature-create.component';


ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule
]);
@NgModule({
    declarations: [
        FeatureCreateComponent,
        AftercompanycreatedreloadComponent,
        NewuserComponent,
        ContactComponent,
        VideonotloggedComponent,
        NavloggedouttopComponent,
        AppComponent,
        NavComponent,
        HomeComponent,
        RegisterComponent,
        ListsComponent,
        MessagesComponent,
        TestErrorsComponent,
        NotFoundComponent,
        ServerErrorComponent,
        TextInputComponent,
        DateInputComponent,
        CompanySeedsComponent,
        EditVehicleRepairModalComponent,
        RolesModalComponent,
        ConfirmDialogComponent,
        BottomFooterComponent,
        InventoryBuyersguideComponent,
        CompanySeedsComponent,
        GapModalComponent,
        MiscchargAddComponent,
        MiscchargeEditComponent,
        AddsalenoteModalComponent,
        EditsalenoteModalComponent,
        VendorModalComponent,
        VendoraddModalComponent,
        AddcashdowntypeModalComponent,
        PdfformtypecreateComponent,
        EditcashdowntypeModalComponent,
        AddtaxdefaultsModalComponent,
        CalculatorModalComponent,
        EdittaxdefaultsModalComponent,
        LabelDoubleClickModalComponent,
        AddVehicleVinModalComponent,
        AddRepairModalComponent,
        ProfitItemsComponent,
        PdfnewuploadnameModalComponent,
        SelectexistingpersonModalComponent,
        EmailverifiedComponent,
        NavloggedouttopComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        VideoModule,
        ReactiveFormsModule,
        SharedModule,
        SaleModule,
        ListModule,
        ListQbModule,
        BuyersGuideModule,
        AdvertisingModule,
        PeopleModule,
        InventoryModule,
        QuickBooksModule,
        AdminModule,
        NgxSpinnerModule,
        AccountingModule,
        ServiceShopModule,
        FormAndReportsRoutingModule,
        RealEstateModule,
        LoanModule,
        CompanyProfileModule,
        MemberModule,
    ],
    exports: [
        MemberListComponent,
        TextInputComponent
    ],
    providers: [
        ExcelService,
        LoanCalculations,
        SharedMonthDateFilterService,
        DatePipe,
        AmortisationClass,
        RealPropertyMath,
        InstallmentChangedClass,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
