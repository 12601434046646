import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';

@Component({
  selector: 'app-saleamortisation-modal',
  templateUrl: './saleamortisation-modal.component.html',
  styleUrls: ['./saleamortisation-modal.component.scss']
})
export class SaleamortisationModalComponent implements OnInit {
  @Input() amoAmortization: AmoAmortization[];
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
