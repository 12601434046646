
  <div class="customer-form-sec" >
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
    

              <div id="calculator">
                <!-- Screen and clear key -->
                <div class="top">
                  <span (click) ="createNewField()" class="clear">Create New</span>
                <span  style="float: left;">NAME: &nbsp;</span>
                  <input disabled  class="screen" #nameFriendly (ngModelChange) = "textAreaValue.nameFriendly = $event" [ngModel] = "textAreaValue?.nameFriendly">
                  <span [hidden] = "!isActive"  style="float: left;"> &nbsp;&nbsp; WIDTH: &nbsp;</span>
                  <input [hidden] = "!isActive"  name="width" class="screeninput"
                    [ngModel]="textAreaValue?.width || 0.00"
                    (ngModel)="textAreaValue.width = $event"
                    (ngModelChange) = "textAreaValue.width = $event; "
                      value='0.00' type="number"  >
                      <span [hidden] = "!isActive"  style="float: left;"> &nbsp;&nbsp; HEIGHT: &nbsp;</span>
                      <input [hidden] = "!isActive"  name="height" class="screeninput"
                        [ngModel]="textAreaValue?.height || 0.00"
                        (ngModel)="textAreaValue.height = $event"
                        (ngModelChange) = "textAreaValue.height = $event; "
                          value='0.00' type="number"  >
                </div>
                
                <textarea [hidden] = "!isActive"  id="calculation" #text [ngModel] = "textAreaValue?.calculation"  (ngModelChange)="textAreaValue.calculation = text.value"  rows="5" [value]='textAreaValue.calculation'  style="width: 100%;">
                  {{textAreaValue?.calculation}}
              </textarea>
                <div class="keys" [hidden] = "!isActive">
                  <span (click) ="createNewField()" class="clear">Create New</span>
                  <span  (click) ="clear()" class="clear">Clear</span>
                  <span (click) ="addSpace()"  class="clear">Space</span>
                  <span (click) ="addComma()"  class="clear">Comma</span>
                  <span (click) ="doMath('(')" class="operator">(</span>
                  <span (click) ="doMath('+')" class="operator">+</span>
                  <span (click) ="doMath('-')" class="operator">-</span>
                  <span (click) ="doMath('/')" class="operator">÷</span>
                  <span (click) ="doMath('*')" class="operator">x</span>
                  <span (click) ="doMath(')')"  class="clear">)</span>
                  <span (click) ="cancelNewField()"  class="clear">Cancel</span>
                  <span *ngIf="!isSaved" (click) ="save()" (click)= "save()" class="clear">Save</span>
                  <span *ngIf="textAreaValue.id > 0 && isDelete"  (click)= "deleteFieldName()" class="clear">Delete</span>
                </div>
                <div  [hidden] = "!isActive" class="keys">
                  <span (click) ="doMath('1')" class="operator">1</span>
                  <span (click) ="doMath('2')" class="operator">2</span>
                  <span (click) ="doMath('3')" class="operator">3</span>
                  <span (click) ="doMath('4')" class="operator">4</span>
                  <span (click) ="doMath('5')" class="operator">5</span>
                  <span (click) ="doMath('6')" class="operator">6</span>
                  <span (click) ="doMath('7')"  class="operator">7</span>
                  <span (click) ="doMath('8')"  class="operator">8</span>
                  <span (click) ="doMath('9')"  class="operator">9</span>
                  <span (click) ="doMath('0')"  class="operator">0</span> 
                </div>
                <div  [hidden] = "!isActive" class="keys">
                  <span class="operator"  [ngClass]="{'active' : isLeft}" >  <button (click) = "alignLRCCtrlClick('left')" class="button" [ngClass]="{'active' : isLeft}">  <i  class="fa fa-align-left" style="size: 30px !important; float: Left; color: rgb(10, 184, 25);" ></i> </button></span>
                  <span class="operator" [ngClass]="{'active' : isCenter}"><button (click) = "alignLRCCtrlClick('center')" class="button" [ngClass]="{'active' : isCenter}">  <i  class="fa fa-align-center" style="size: 30px !important; float: left; color: rgb(10, 184, 25);" ></i> </button></span>
                  <span class="clear" [ngClass]="{'active' : isRight}"> <button (click) = "alignLRCCtrlClick('right')" class="button" [ngClass]="{'active' : isRight}">  <i  class="fa fa-align-right" style="size: 30px !important; float: left; color: rgb(10, 184, 25);" ></i> </button></span> 
                 
                </div>
              </div>

        </div>
      </div>
    </div>
  </div>

  

