<div class="customer-form-sec">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row"  *ngIf="loggedIn()" >
                <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->

                <div class="row">
                  <div class="col-md-10 table-sec">
                    <table border ="1" borderColor="#ccc">
                      <tr >
                        <th>Feature Requested</th>
                        <th>Decription</th>
                        <th>Date</th>
                        <th>Company</th>
                        <th>Completed</th>
                        <th>On Next Patch</th>
                        <th>Note Programmers</th>
                      </tr>
                      <tr [ngClass]="item.isComplete ? 'green' : 'red' " class="td-row" *ngFor="let item of featureLists">

                        <td [ngClass]="item.isComplete ? 'green' : 'red'">{{item?.feature}}</td>
                        <td [ngClass]="item.isComplete ? 'green' : 'red'">{{item?.description}}</td>
                        <td [ngClass]="item.isComplete ? 'green' : 'red'">{{item?.createdDate | date: 'shortDate'}}</td>
                        <td [ngClass]="item.isComplete ? 'green' : 'red'">{{item?.companyProfiles?.companyName}}</td>
                        <td [ngClass]="item.isComplete ? 'green' : 'red'">{{item?.isComplete}}</td>
                        <td [ngClass]="item.isComplete ? 'green' : 'red'"> {{item?.isOnNextPatch}}</td>
                        <td [ngClass]="item.isComplete ? 'green' : 'red'">{{item?.versionFeatureAdded}}</td>
                      </tr>
                    </table>
                  </div><!-- /.table-sec -->
                </div>
              </div>
                  
                <div  *ngIf="!loggedIn()"  class="container mt-2 "  >
                    <div    >
                        <div class="row justify-content-center">
                            <div class="col-6">
                              <app-contact *ngIf="isContact"></app-contact>
                                <app-register  *ngIf="registerMode" (cancelRegister)="cancelRegisterMode($event)" ></app-register>
                            </div>
                        </div>
                    </div>
                </div>
               
          </div>
        </div>
        </div>
        
       
      </div>
      <div class="customer-sidebar col-md-2" style="min-height: 100% !important;">
        <div class="inner-sidebar" style="padding-top: 10px;">
          <br>
          <br>
          <ul *ngIf="!loggedIn()">
              <!-- <li (click) = "homeClick()">
                  <a class="btn" href="javascript:void(0)">
                    <span></span>
                  </a>
                </li> -->
                <li class="spacing"></li>
            <li *ngIf="!loggedIn()" (click)="registerToggle()">
              <a class="btn" href="javascript:void(0)" >
                <img src="assets/images/register.png">
                <span>REGISTER</span>
              </a>
            </li>
            <li class="spacing"></li>
            <li *ngIf="!loggedIn()" (click)="videoClicked()">
              <a class="btn" href="javascript:void(0)">
                <img src="assets/images/camera.jpg ">
                <span>Videos</span>
              </a>
            </li>
            <li class="spacing"></li>
            <li (click)="contactClicked()">
              <a class="btn" href="javascript:void(0)"
              
              >
                <img src="assets/images/contact.png">
                <span>Contact</span>
              </a>
            </li>
          </ul>

          <ul *ngIf="loggedIn()">
            <!-- <li (click) = "homeClick()">
                <a class="btn" href="javascript:void(0)">
                  <span></span>
                </a>
              </li> -->
              <li class="spacing"></li>
          <li  (click)="requestFeature()">
            <a class="btn" href="javascript:void(0)" >
              <img src="assets/images/register.png">
              <span>Add Request</span>
            </a>
          </li>
          <li class="spacing"></li>
        </ul>
        </div>
      </div>
    </div>
  </div>
  


<br><br>

<app-navloggedouttop (homeClick)="homeClick($event)" *ngIf="!loggedIn()"></app-navloggedouttop>