
<br>
<div class="customer-form-sec">
    <div class="container-fluid">
      <br><br>
      <div class="row">
        <div class="form-sec tab-content col-md-12">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-row account-payable-row">
                  <div class="row">
                    <div class="col-md-12 table-sec">
                      <div id="printableTable">
                        <br><br>
                        <div>
                          <table class="pricing-table">
                            <tr>
                                <th>Id</th>
                                <th>Kod</th>
                                <th>Acct</th>
                                <th>Expense</th>
                                <th>IsImported</th>
                            </tr>
                            <tr class="td-row" *ngFor="let item of entities; let i=index">
                              <td>{{ item?.id}}</td>
                              <td>{{ item?.kod}}</td>
                              <td>{{ item?.acct}}</td>
                              <td>{{ item?.expense}}</td>
                              <td>{{ item?.isImported}}</td>
                            
                            </tr>
                          </table>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.row -->
          </div>
        </div>
      </div>
    </div>
  </div>