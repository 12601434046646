<div class="row" *ngIf="member">
    <div class="col-4">
        <h1>Your profile</h1>
    </div>
    <div class="col-8">
        <div class="alert alert-info" *ngIf="editForm.dirty">
            <strong>Information: </strong> You have made changes.  Any unsaved changes will be lost
        </div>
    </div>
    <div class="col-2">
        <div class="card">
            <img src="{{member.photoUrl || './assets/user.png'}}" alt="{{member.knownAs}}" 
                class="card-img-top img-thumbnail">
            <div class="card-body">
                
                
                <!-- <div>
                    <strong>Last Active:</strong>
                    <p>{{member.lastActive | timeago}}</p>
                </div>
                <div>
                    <strong>Member since:</strong>
                    <p>{{member.created | date: 'dd MMM yyyy'}}</p>
                </div> -->
            </div>
            <div class="card-footer">
                <button [disabled]="!editForm.dirty" form="editForm" type="submit" class="btn btn-success btn-block">Save Changes</button>
            </div>
        </div>
    </div>

    <div class="col-10">
        <tabset class="member-tabset">
            <tab heading='About {{member.knownAs}}'>
                <form #editForm="ngForm" id="editForm" (ngSubmit)="updateMember()">
                   <div class="form-inline">
                    <label for="city">Known As: </label>
                    <input [(ngModel)]="member.knownAs" type="text" name="knownAs" class="form-control mx-2">
                   </div>
                    <h4 class="mt-2">Billing Details: </h4>
                    <div class="form-inline">
                        <label for="city">First Name: </label>
                        <input 
                        [ngModel]="member.person.firstName" 
                        (ngModel)="member?.person?.firstName" 
                        (ngModelChange) = "member.person.firstName = $event"
                        type="text" name="firstName" class="form-control mx-2">
                        <label for="city">Last Name: </label>
                        <input 
                        (ngModel)="member?.person?.lastName" 
                        [ngModel]="member.person.lastName" 
                        (ngModelChange) = "member.person.lastName = $event"
                        type="text" name="lastName" class="form-control mx-2">

                       
                    </div>
                    <div class="form-inline">
                        <label for="city">Zip: </label>
                        <input [(ngModel)]="member.person.addressDetail.zip" type="text" name="zip" class="form-control mx-2">
                        <label for="city">Country: </label>
                        <input [(ngModel)]="member.person.addressDetail.country" type="text" name="country" class="form-control mx-2">
                    </div>
                    <div class="form-inline">
                        <label for="city">Address: </label>
                        <input [(ngModel)]="member.person.addressDetail.street" type="text" name="street" class="form-control mx-2">
                    </div>
                    <div class="form-inline">
                        <label for="city">City: </label>
                        <input [(ngModel)]="member.person.addressDetail.city" type="text" name="city" class="form-control mx-2">
                        <label for="city">State: </label>
                        <input [(ngModel)]="member.person.addressDetail.state" type="text" name="state" class="form-control mx-2">
                    </div>
                </form>
               
            </tab>
            <tab heading='Edit Photos'>
                <app-photo-editor [member]="member"></app-photo-editor>
            </tab>
            <tab heading='Password Reset'>
            <app-password-reset></app-password-reset>
            </tab>
            <tab heading='Payment History'>
                <app-payme-history></app-payme-history>
            </tab>
            <tab heading = 'Subscriptions'>
                <app-subscriptions></app-subscriptions>
            </tab>
            <tab (click) = "getCart()" *ngIf="cartLenth > 0" heading = 'Cart Items: {{ cartLenth }}' >
                <app-members-cart [userCart] = "userCart"></app-members-cart>
            </tab>
            <tab *ngIf="cartTotal > 0" heading='Make Payment'> 
                <app-pay-me></app-pay-me>
            </tab>
        </tabset>
    </div>
</div>

