
<div style="width: 550px" class="modal1" id="myModal">
  <div class="modal-dialog1 modal1" style="width:550px;">
     <div class="modal1-content modal1" style="width:450px;" >

<div  class="modal-header modal1">
  <h4 class="modal-title pull-left">Edit Company</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div  class="modal-body modal1"> 

<div class="row">
  <div class="col-sm-12">
    <form #editForm="ngForm" submit="onSubmit()" id="editForm">
      <div class="row">
        <div class="col-md-12 ">
          <div class="col-md-12">
            <div
              class="form-group form-inline">
              <label class="label">API KEY</label>
            
            
            
            
            
              <div class="input-group">
                <input
                  style="width: 100%"
                  type="text"
                  class="form-control"
                  [ngModel]="company.parentGuid"
                  name="parentGuid"
                  #parentGuid="ngModel"
                  required
                />
              </div>
            </div>
          </div>
            <div class="col-md-12">
              <div
                class="form-group form-inline"
                [ngClass]="{
                  'has-success':
                    companyName?.valid &&
                    (companyName?.touched ||
                      companyName?.dirty),
                  'has-danger':
                    companyName?.invalid &&
                    (companyName?.touched || companyName?.dirty)
                }"
              >
                <label class="label">Company Name</label>
                <div class="input-group">
                  <input
                    style="width: 70%"
                    type="text"
                    class="form-control"
                    [ngModel]="company?.companyName"
                    (ngModelChange)="company.companyName = $event"
                    name="companyName"
                    #companyName="ngModel"
                    required
                  />
                  <div
                    *ngIf="
                      !companyName.errors &&
                      (companyName?.touched ||
                        companyName?.dirty)
                    "
                    class="input-group-prepend"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-success"
                    >
                      <i
                        class="fa fa-check"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
                <div
                  class="form-group-feedback"
                  *ngIf="
                    companyName.errors &&
                    (companyName?.touched || companyName?.dirty)
                  "
                >
                  <p
                    style="color: red"
                    *ngIf="companyName?.errors.required"
                  >
                    Company Name Is Required
                  </p>
                </div>
              </div>
            </div>
    

            <div class="col">
              <div
                class="form-group form-inline"
                [ngClass]="{
                  'has-success':
                    phone?.valid &&
                    (phone?.touched || phone?.dirty),
                  'has-danger':
                    phone?.invalid &&
                    (phone?.touched || phone?.dirty)
                }"
              >
                <label>Phone</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="company?.phone"
                    (ngModelChange)="company.phone = $event"
                    name="phone"
                    #phone="ngModel"
                    required
                  />
                  <div
                    *ngIf="
                      !phone.errors &&
                      (phone?.touched || phone?.dirty)
                    "
                    class="input-group-prepend"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-success"
                    >
                      <i
                        class="fa fa-check"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
                <div
                  class="form-group-feedback"
                  *ngIf="
                    phone.errors &&
                    (phone?.touched || phone?.dirty)
                  "
                >
                  <p
                    style="color: red"
                    *ngIf="phone?.errors.required"
                  >
                    Phone Is Required
                  </p>
                </div>
              </div>
            </div>

            <div class="col">
              <div
                class="form-group form-inline"
                [ngClass]="{
                  'has-success':
                    email?.valid &&
                    (email?.touched || email?.dirty),
                  'has-danger':
                    email?.invalid &&
                    (email?.touched || email?.dirty)
                }"
              >
                <label>Email</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    [ngModel]="company?.email"
                    (ngModelChange)="company.email = $event"
                    name="email"
                    #email="ngModel"
                    required
                  />
                  <div
                    *ngIf="
                      !email.errors &&
                      (email?.touched || email?.dirty)
                    "
                    class="input-group-prepend"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-success"
                    >
                      <i
                        class="fa fa-check"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
                <div
                  class="form-group-feedback"
                  *ngIf="
                    email.errors &&
                    (email?.touched || email?.dirty)
                  "
                >
                  <p
                    style="color: red"
                    *ngIf="email?.errors.required"
                  >
                    Email Is Required
                  </p>
                </div>
              </div>
            </div>
     
          <fieldset ngModelGroup="addressDetail">
            <div
              class="form-group form-inline"
              [ngClass]="{
                'has-success':
                  street?.valid &&
                  (street?.touched || street?.dirty),
                'has-danger':
                  street?.invalid &&
                  (street?.touched || street?.dirty)
              }"
            >
              <label>Street</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  [ngModel]="company?.street"
                  (ngModelChange)="
                    company.street = $event
                  "
                  name="street"
                  #street="ngModel"
                  required
                />
                <div
                  *ngIf="
                    !street.errors &&
                    (street?.touched || street?.dirty)
                  "
                  class="input-group-prepend"
                >
                  <button
                    type="button"
                    class="btn btn-outline-success"
                  >
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
              </div>
              <div
                class="form-group-feedback"
                *ngIf="
                  street.errors &&
                  (street?.touched || street?.dirty)
                "
              >
                <p
                  style="color: red"
                  *ngIf="street?.errors.required"
                >
                  Street Is Required
                </p>
              </div>
            </div>

         
              <div class="col">
                <div
                  class="form-group form-inline"
                  [ngClass]="{
                    'has-success':
                      street?.valid &&
                      (street?.touched || street?.dirty),
                    'has-danger':
                      street?.invalid &&
                      (street?.touched || street?.dirty)
                  }"
                >
                  <label>City</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      [ngModel]="company?.city"
                      (ngModelChange)="
                      company.city = $event
                      "
                      name="city"
                      #city="ngModel"
                      required
                    />
                    <div
                      *ngIf="
                        !city.errors &&
                        (city?.touched || city?.dirty)
                      "
                      class="input-group-prepend"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-success"
                      >
                        <i
                          class="fa fa-check"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="form-group-feedback"
                    *ngIf="
                      city.errors &&
                      (city?.touched || city?.dirty)
                    "
                  >
                    <p
                      style="color: red"
                      *ngIf="city?.errors.required"
                    >
                      City Is Required
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  class="form-group form-inline"
                  [ngClass]="{
                    'has-success':
                      state?.valid &&
                      (state?.touched || state?.dirty),
                    'has-danger':
                      state?.invalid &&
                      (state?.touched || state?.dirty)
                  }"
                >
                  <label>State</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      [ngModel]="company?.state"
                      (ngModelChange)="
                        company.state = $event
                      "
                      name="state"
                      #state="ngModel"
                      required
                    />
                    <div
                      *ngIf="
                        !state.errors &&
                        (state?.touched || state?.dirty)
                      "
                      class="input-group-prepend"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-success"
                      >
                        <i
                          class="fa fa-check"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="form-group-feedback"
                    *ngIf="
                      state.errors &&
                      (state?.touched || state?.dirty)
                    "
                  >
                    <p
                      style="color: red"
                      *ngIf="state?.errors.required"
                    >
                      State Is Required
                    </p>
                  </div>
                </div>
              </div>
          
     
              <div class="col">
                <div
                  class="form-group form-inline"
                  [ngClass]="{
                    'has-success':
                      zip?.valid &&
                      (zip?.touched || zip?.dirty),
                    'has-danger':
                      zip?.invalid &&
                      (zip?.touched || zip?.dirty)
                  }"
                >
                  <label>Zip</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      [ngModel]="company?.zip"
                      (ngModelChange)="
                        company.zip = $event
                      "
                      name="zip"
                      #zip="ngModel"
                      minlength="2"
                      required
                    />
                    <div
                      *ngIf="
                        !zip.errors &&
                        (zip?.touched || zip?.dirty)
                      "
                      class="input-group-prepend"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-success"
                      >
                        <i
                          class="fa fa-check"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="form-group-feedback"
                    *ngIf="
                      zip.errors && (zip?.touched || zip?.dirty)
                    "
                  >
                    <p
                      style="color: red"
                      *ngIf="zip?.errors.required"
                    >
                      Zip Is Required
                    </p>
                    <p
                      style="color: red"
                      *ngIf="zip?.errors.minlength"
                    >
                      Zip requires at least 2 characters
                    </p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div
                  class="form-group form-inline"
                  [ngClass]="{
                    'has-success':
                      county?.valid &&
                      (county?.touched || county?.dirty),
                    'has-danger':
                      county?.invalid &&
                      (county?.touched || county?.dirty)
                  }"
                >
                  <label>County</label>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      [ngModel]="company?.county"
                      (ngModelChange)="
                        company.county = $event
                      "
                      name="county"
                      #county="ngModel"
                      required
                    />
                    <div
                      *ngIf="
                        !county.errors &&
                        (county?.touched || county?.dirty)
                      "
                      class="input-group-prepend"
                    >
                      <button
                        type="button"
                        class="btn btn-outline-success"
                      > 
                        <i
                          class="fa fa-check"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="form-group-feedback"
                    *ngIf="
                      county.errors &&
                      (county?.touched || county?.dirty)
                    "
                  >
                    <p
                      style="color: red"
                      *ngIf="county?.errors.required"
                    >
                      County Is Required
                    </p>
                  </div>
                </div>
              </div>
         
          </fieldset>
          <div class="col">
            
          </div>
        </div>
      </div>
    </form>
   
  </div>
  </div>

  <button (click)="saveChanges()" class="btn btn-success mr-2">SAVE</button>
  <button (click)="cancel()" class="btn btn-success">Cancel</button>
  </div>
  </div>
  </div>
  </div>
  
