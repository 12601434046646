<br>
  <div class="container-fluid customer-tabs-sec">
    <ul class="nav nav-tabs">
      <li><a  [ngClass]="{'active show' : isEmployees}" (click)="tabClicked('isEmployees')"  href="javascript:void(0)" >Employees</a></li>
      <li><a [ngClass]="{'active show' : isRoles}"  (click)="tabClicked('isRoles')" href="javascript:void(0)" >Employee Roles</a></li>
      <li><a   [ngClass]="{'active show' : isCompanies}"  (click)="tabClicked('isCompanies')" href="javascript:void(0)" >Companies</a></li>

      <!--   <li><a  [ngClass]="{'active show' : isActive}" (click)="tabClicked('isActive')"  href="javascript:void(0)" >Active Sales</a></li>
      <li><a  [ngClass]="{'active show' : isPaid}" (click)="tabClicked('isPaid')"  href="javascript:void(0)" >Paid Sales</a></li>
      <li><a  [ngClass]="{'active show' : isLease}" (click)="tabClicked('isLease')"  href="javascript:void(0)" >Leases</a></li>
      <li><a  [ngClass]="{'active show' : isRental}" (click)="tabClicked('isRental')"  href="javascript:void(0)" >Rentals</a></li> -->
    </ul>
  </div>

<!-- <app-sale-charts [hidden] = "!isDashboard"></app-sale-charts> -->
<app-employee-management [hidden] = "!isEmployees"></app-employee-management>
<app-user-management [hidden] = "!isRoles"></app-user-management>
<app-company-grid [hidden] = "!isCompanies"></app-company-grid>
<!-- <app-member-list [hidden] = "!isEmployees"></app-member-list> -->
