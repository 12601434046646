
<div class="customer-form-sec">
    <div class="container-fluid">
<div class="row">
   
    <!-- style="background-color:#DBE9F4 !important;" -->
    <div class="form-sec tab-content col-md-12">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->
                
                <div class="row">
                  <div class="col-md-5 table-sec">
                    <table border="1" borderColor="#ccc">
                      <tr>
						<th>Id</th>
						<th>Type</th>
                        <th>Phone</th>
                        <th>Sort</th>
                        <th>Delete</th>
                        <th>Edit</th>
                      </tr>
                      <tr (dblclick) = "navigateDepartment(item)" class="td-row" *ngFor="let item of companyDepartments">
                        <td>{{item.id}}</td>
                        <td>{{item?.companyDepartmentType?.value}}</td>
                        <td>{{item.phone}}</td>
                        <td>{{item.sortOrder}}</td>
                        <!-- <td>
                          <a
                            class="btn btn-primary"
                            (click)="editSortOrder(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View Order Detail"
                            ><i class="fa fa-fw fa-garbage"></i
                          >SortOrder</a>
                        </td> -->
                       
                        <td>
                          <a
                            class="btn btn-primary"
                            (click)="delete(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View Order Detail"
                            ><i class="fa fa-fw fa-garbage"></i
                          >Delete</a>
                        </td>

                        <td>
                          <a
                            class="btn btn-primary"
                            (click)="navigateDepartment(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View Order Detail"
                            ><i class="fa fa-fw fa-eye"></i
                          >Edit</a>
                        </td>
                      </tr>
                    </table>
                    <app-departmentphotos [website] = "website" [hidden]= "!isSelected" [companyDepartment] = "model" [company] = "company" [photos] = "companyPhotos"></app-departmentphotos>
                  </div>
                  <!-- /.table-sec -->
                  <!--FORM SECTION-->
                  <div class="col-md-6">
                   
                      <form id="editForm"
                        [hidden]= "!isSelected"
                      novalidate
                      #editForm="ngForm"
                      (ngSubmit)="onSubmitDepartment(editForm)"> 
                      <div class="row">
                        <div class="col-md-6 form-group  form-inline">
                            <label>Department Name:</label>
                            <input disabled="true" type="text" class="form-control" [ngModel]="model?.departmentName" (ngModel)="model.departmentName=$event" 
                            (ngModelChange)="model.departmentName=$event" 
                            name="departmentName"
                            #departmentName="ngModel" required>
                        </div>
                        <div class="col-md-6 form-group  form-inline">
                          <label>Sort Order:</label>
                          <input 
                          type="text" 
                          class="form-control" [ngModel]="model?.sortOrder" (ngModel)="model.sortOrder=$event" 
                          (ngModelChange)="model.sortOrder=$event" 
                          name="sortOrder"
                          #sortOrder="ngModel" required>
                      </div>
                        <div class="col-md-6 form-group  form-inline">
                            <label>Fax:</label>
                            <input type="text" class="form-control" [ngModel]="model?.fax" (ngModel)="model.fax=$event" 
                            (ngModelChange)="model.fax=$event" 
                            name="fax"  #fax="ngModel" required>
                        </div>
                      </div>
                      <div class="row">
                      <div class="col-md-6 form-group form-inline">
                          <label>Phone:</label>
                          <input type="text" class="form-control" [ngModel]="model?.phone" (ngModel)="model.phone=$event" 
                          (ngModelChange)="model.phone=$event" 
                          name="phone"
                          #departmentName="ngModel" required>
                      </div>
                      <div class="col-md-6 form-group form-inline">
                        <label>Email:</label>
                          <input type="text" class="form-control" [ngModel]="model?.email" (ngModel)="model.email=$event" 
                          (ngModelChange)="model.email=$event" 
                          name="email"  #email="ngModel" required>
                      </div>
                    </div>
                    <fieldset ngModelGroup="addressDetail">
                        <div
                          class="form-group form-inline"
                          [ngClass]="{
                            'has-success':
                              street?.valid &&
                              (street?.touched || street?.dirty),
                            'has-danger':
                              street?.invalid &&
                              (street?.touched || street?.dirty)
                          }"
                        >
                          <label>Street</label>
                          <input style="min-width: 65%;"
                            type="text"
                            class="form-control"
                            [ngModel]="model?.addressDetail?.street"
                            (ngModel)="model.addressDetail.street = $event"
                            (ngModelChange)="model.addressDetail.street=$event" 
                            name="street"
                            #street="ngModel"
                            required
                          />
                          <div
                            class="form-group-feedback"
                            *ngIf="
                              street.errors &&
                              (street?.touched || street?.dirty)
                            "
                          >
                            <p *ngIf="street?.errors?.required">
                              Street Is Required
                            </p>
                          </div>
                        </div>
                        <div
                          class="form-group form-inline"
                          [ngClass]="{
                            'has-success':
                              zip?.valid && (zip?.touched || zip?.dirty),
                            'has-danger':
                              zip?.invalid && (zip?.touched || zip?.dirty)
                          }"
                        >
                          <label>Zip</label>
                          <input
                            type="text"
                            class="form-control"
                            [ngModel]="model?.addressDetail?.zip"
                            (ngModel)="model.addressDetail.zip = $event"
                            (ngModelChange)="model.addressDetail.zip=$event" 
                            name="zip"
                            #zip="ngModel"
                            minlength="2"
                            required
                          />
                          <div
                            class="form-group-feedback"
                            *ngIf="
                              zip.errors && (zip?.touched || zip?.dirty)
                            "
                          >
                            <p *ngIf="zip?.errors?.required">
                              Zip Is Required
                            </p>
                          </div>
                        </div>
                        <!--Start Row-->
                        <div class="row">
                          <div class="col-md-6">
                            <div
                              class="form-group form-inline"
                              [ngClass]="{
                                'has-success':
                                  street?.valid &&
                                  (street?.touched || street?.dirty),
                                'has-danger':
                                  street?.invalid &&
                                  (street?.touched || street?.dirty)
                              }"
                            >
                              <label>City</label>
                              <input
                                type="text"
                                class="form-control"
                                [ngModel]="model?.addressDetail?.city"
                                (ngModel)="model.addressDetail.city = $event"
                                (ngModelChange)="model.addressDetail.city=$event" 
                                name="city"
                                #city="ngModel"
                                required
                              />
                              <div
                                class="form-group-feedback"
                                *ngIf="
                                  city.errors &&
                                  (city?.touched || city?.dirty)
                                "
                              >
                                <p *ngIf="city?.errors.required">
                                  City Is Required
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div
                              class="form-group form-inline"
                              [ngClass]="{
                                'has-success':
                                  state?.valid &&
                                  (state?.touched || state?.dirty),
                                'has-danger':
                                  state?.invalid &&
                                  (state?.touched || state?.dirty)
                              }"
                            >
                              <label>State</label>
                              <input
                                type="text"
                                class="form-control"
                                [ngModel]="model?.addressDetail?.state"
                                (ngModel)="
                                  model.addressDetail.state = $event
                                "
                                (ngModelChange)="model.addressDetail.state=$event" 
                                name="state"
                                #state="ngModel"
                                required
                              />
                              <div
                                class="form-group-feedback"
                                *ngIf="
                                  state.errors &&
                                  (state?.touched || state?.dirty)
                                "
                              >
                                <p *ngIf="state?.errors?.required">
                                  State Is Required
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                  
                        <div
                          class="form-group form-inline"
                          [ngClass]="{
                            'has-success':
                              county?.valid &&
                              (county?.touched || county?.dirty),
                            'has-danger':
                              county?.invalid &&
                              (county?.touched || county?.dirty)
                          }"
                        >
                          <label>County</label>
                          <input
                            type="text"
                            class="form-control"
                            [ngModel]="model?.addressDetail?.county"
                            (ngModel)="model.addressDetail.county = $event"
                            (ngModelChange)="model.addressDetail.county=$event" 
                            name="county"
                            #county="ngModel"
                            required
                          />
                          <div
                            class="form-group-feedback"
                            *ngIf="
                              county.errors &&
                              (county?.touched || county?.dirty)
                            "
                          >
                            <p *ngIf="county?.errors?.required">
                              County Is Required
                            </p>
                          </div>
                        </div>
                     
                      </fieldset>
                      <fieldset ngModelGroup="departmentHours">
                        <div class="row">
                          <div class="col-md-6 form-group  form-inline">
                              <label>Monday:</label>
                              <input type="text" class="form-control" 
                              (ngModelChange)="model.departmentHours.monday=$event" 
                              [ngModel]="model?.departmentHours?.monday" (ngModel)="model.departmentHours.monday=$event" name="monday"
                              #monday="ngModel" required>
                          </div>
                          <div class="col-md-6 form-group  form-inline">
                              <label>Teuesday:</label>
                              <input type="text" class="form-control" [ngModel]="model?.departmentHours?.tuesday" 
                              (ngModel)="model.departmentHours.tuesday=$event" name="tuesday"
                              (ngModelChange)="model.departmentHours.tuesday=$event" 
                              #tuesday="ngModel" required>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 form-group  form-inline">
                              <label>Wednesday:</label>
                              <input type="text" class="form-control" [ngModel]="model?.departmentHours?.wednesday" 
                              (ngModel)="model.departmentHours.wednesday=$event" 
                              (ngModelChange)="model.departmentHours.wednesday=$event" 
                              name="wednesday"
                              #wednesday="ngModel" required>
                          </div>
                          <div class="col-md-6 form-group form-inline">
                              <label>Thursday:</label>
                              <input type="text" class="form-control" [ngModel]="model?.departmentHours?.thursday" (ngModel)="model.departmentHours.thursday=$event" name="thursday"
                              (ngModelChange)="model.departmentHours.thursday=$event"
                              #thursday="ngModel" required>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 form-group  form-inline form-inline">
                              <label>Friday:</label>
                              <input type="text" class="form-control" [ngModel]="model?.departmentHours?.friday" (ngModel)="model.departmentHours.friday=$event" name="friday"
                              (ngModelChange)="model.departmentHours.friday=$event"
                              #friday="ngModel" required>
                          </div>
                          <div class="col-md-6 form-group form-inline">
                              <label>Saturday:</label>
                              <input type="text" class="form-control" [ngModel]="model?.departmentHours?.saturday" (ngModel)="model.departmentHours.saturday=$event" name="saturday"
                              (ngModelChange)="model.departmentHours.saturday=$event"
                              #saturday="ngModel" required>
                          </div>
                        </div>
                       
                          <div class="col-md-6 form-group form-inline">
                              <label>Sunday:</label>
                              <input type="text" class="form-control" [ngModel]="model?.departmentHours?.sunday" (ngModel)="model.departmentHours.sunday=$event" name="sunday"
                              (ngModelChange)="model.departmentHours.sunday=$event"
                              #sunday="ngModel" required>
                          </div>
                          <div class="col-md-6 form-group form-inline">
                            <label>Holidays:</label>
                            <input type="text" class="form-control" [ngModel]="model?.departmentHours?.holidays" (ngModel)="model.departmentHours.holidays=$event" name="holidays"
                            (ngModelChange)="model.departmentHours.holidays=$event"
                            #holidays="ngModel" required>
                        </div>
                      </fieldset>
                                        </form>
                                      </div>
                                  
                  <!--BUTTON BAR-->
                  <div  class="customer-sidebar sidebar-with-btns col-md-1">
                      <div   class="inner-sidebar">
                          
                        <ul>
                          <li>
                            <button *ngIf="isSelected" (click) = "addServiceOffer()" class="cstm-btn img-blk-btn">
                              <img src="assets/images/Save.jpg">
                              <span>Add Offer</span>
                            </button>
                          </li>
                            <li>
                                <button (click) = "addNew()" class="cstm-btn img-blk-btn">
                                  <img src="assets/images/Save.jpg">
                                  <span>Add New</span>
                                </button>
                              </li>
                          <li>
                            <button (click)= "onSubmitDepartment(editForm)" *ngIf="editForm.dirty"  class="cstm-btn img-blk-btn">
                              <img src="assets/images/Save.jpg">
                              <span>Save</span>
                            </button>
                          </li>
                        
                        </ul>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>

     
      </div>
</div>



