import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Person } from 'src/app/_models/person';
import { User } from 'src/app/_models/user';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';

@Component({
  selector: 'app-employee-assigncompany',
  templateUrl: './employee-assigncompany.component.html',
  styleUrls: ['./employee-assigncompany.component.css']
})
export class EmployeeAssigncompanyComponent implements OnInit {
  @Input() selectedCompanies: UserAssignedToCompany[];
  @Input() allCompanies: UserAssignedToCompany[];
  @Input() exportSelectedCompanies = new EventEmitter();
  @Input() user: Partial<User>;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log(this.selectedCompanies);
  }
  updateEmployee() {
    console.log(this.selectedCompanies);
    this.exportSelectedCompanies.emit(this.allCompanies);
    this.bsModalRef.hide();
  }
  close() {
    console.log(this.allCompanies);
    this.bsModalRef.hide();
  }

  changeCompanyDefault(e) {
    console.log(e);
    if (e.isDefaultCompany === true){
      e.isDefaultCompany = false;
      console.log(e.isDefaultCompany);
    }
    else {
      e.isDefaultCompany = true;
      console.log(e.isDefaultCompany); }
  }
}
