

      <div class="col-md-12">
        <div class="row">
        <div class="col-md-2">
            <!-- <mat-sidenav-container style="height: 100vh; width: 180px; background-color:transparent;"  >
                <mat-sidenav-content> -->
                  <div style="height: 88vh;">
                      <div class="customer-sidebar sidebar-with-btns ">
                          <div class="inner-sidebar">
                            <ul style="list-style-type:none;">
                              <li>
                                <button (click)="getQuickBooksDefaults()"   class="cstm-btn img-blk-btn">
                                  <img src="assets/images/ap-icon-1.png">
                                  <span>Quick Books Defaults</span>
                                </button>
                              </li>
                              <li>
                                <button (click)="buttonClick()" [routerLink]="['/list/saletypes', selectedCompany1.parentGuid]"  class="cstm-btn img-blk-btn">
                                  <img src="assets/images/ap-icon-1.png">
                                  <span>Sale Types</span>
                                </button>
                              </li>
                                <li>
                                    <button [routerLink]="['/list/status', selectedCompany1.parentGuid]"  class="cstm-btn img-blk-btn">
                                      <img src="assets/images/ap-icon-1.png">
                                      <span>Sale Status</span>
                                    </button>
                                  </li>
                                <li>
                                    <button [routerLink]="['/list/paymenttypegrid', selectedCompany1?.parentGuid]"  class="cstm-btn img-blk-btn">
                                      <img src="assets/images/ap-icon-1.png">
                                      <span>Payment Types</span>
                                    </button>
                                  </li>
                              
                              <li>
                                  <button  [routerLink]="['/list/vehiclemileagecodes', selectedCompany1?.parentGuid]" class="cstm-btn img-blk-btn">
                                    <img src="assets/images/ap-icon-1.png">
                                    <span>Mileage Codes</span>
                                  </button>
                                </li>
                              <li>
                                <button [routerLink]="['/list/vehiclebodytypes', selectedCompany1?.parentGuid]"   class="cstm-btn img-blk-btn">
                                  <img src="assets/images/ap-icon-2.png">
                                  <span>Body Types</span>
                                </button>
                              </li>
                              <li>
                                <!-- <button [routerLink]="['/list/formnamelist', selectedCompany1?.parentGuid]"  class="cstm-btn img-blk-btn">
                                  <img src="assets/images/ap-icon-3.png">
                                  <span>Form Labels</span>
                                </button> -->
                              </li>
                              
                              <!-- <li>
                                <button [routerLink]="['/accountingdashboard/chartitemgrid', selectedCompany1?.parentGuid]"  class="cstm-btn img-blk-btn">
                                  <img src="assets/images/ap-icon-6.png">
                                  <span>Journal Entries</span>
                                </button>
                              </li> -->
                            </ul>
                          </div>
                          </div>
                        
                      <!-- <a mat-list-item [routerLink]="'/accounts'"> Chart Accounts </a>
                      <a mat-list-item [routerLink]="'/create-account'"> Chart Items </a>
                      <a mat-list-item [routerLink]="'/contacts'"> General Ledger </a>
                      <a mat-list-item [routerLink]="'/create-contact'"> Graphs</a>
                      <a mat-list-item [routerLink]="'/activities'"> Reports </a>
                      <a mat-list-item [routerLink]="'/create-activity'">Journal Entry </a> -->
                      <!-- <a mat-list-item (click)="sidenav.toggle()" href="javascript:Void(0)" mat-list-item>Close</a> -->
                    <router-outlet></router-outlet>
                  </div>
                <!-- </mat-sidenav-content>
              </mat-sidenav-container> -->
        </div>
        <!-- <div class="col">
        </div> -->
      </div>
      </div>

   
     
