import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Vehicle } from '../../_models/vehicle';

import { VehicleWebsiteilterDto } from '../../_models/VehicleWebsiteilterDto';

import { VehicleMileageCodes } from '../../_models/VehicleMileageCodes';
import { environment } from 'src/environments/environment';
import { VehicleBodyClassTypes } from '../../_models/VehicleBodyClassTypes';
import { VehicleYears } from '../../_models/vehicleYears';
import { VehicleMake } from '../../_models/vehiclemakes';
import { Item } from '../../_models/Item';
import { AuthService } from '../auth.service';
import { UserAssignedToCompany } from '../../_models/userAssignedToCompany';
import { InventoryVehicleGridToReturnDto } from 'src/app/_models/DTOS/inventoryvehiclegriddto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { getPaginatedResult, getPaginationHeaders } from '../paginationHelper';
import { UserParams } from 'src/app/_models/userParams';
import { AccountService } from '../account.service';
import { User } from 'src/app/_models/user';
import { AddVehicleDto } from 'src/app/_models/DTOS/addvehicledto';
import { VehicleNewDto } from 'src/app/_models/_models/DTO/VehicleNewDto';


@Injectable({
  providedIn: 'root'
})
export class VehicleService {
getVehicelListGridCache = new Map();
userParams: UserParams;
user: User;
item: Item;
itemsCached = [] as Item[];
item2: Item;
item3: Item;
vehicleMileageCodesCached = [] as VehicleMileageCodes[];
vehicleYearsCached = [] as VehicleYears[];
vehicleMakesCached = [] as VehicleMake[];
constructor(
  private http: HttpClient, private accountService: AccountService,
  private authService: AuthService
  ) {
    this.authService.currentCompany.subscribe(data => { this.selectedCompany = data; } );
    this.userParams = new UserParams();
   }
vehicleWebsiteilterDto: VehicleWebsiteilterDto;
baseUrl = environment.apiUrl;
selectedCompany: UserAssignedToCompany;

  getVehicles(vehicleWebsiteilterDto: VehicleWebsiteilterDto): Observable<Vehicle[]> {
  return  this.http.post<Vehicle[]>(this.baseUrl + 'vehicle/getvehiclelistweb', vehicleWebsiteilterDto );
  }
  setItemToCache(itemrecieved: Item) {
    const item2 = this.itemsCached.find((item: Item) =>  item.id === itemrecieved.id  );
    if (item2 === undefined) {
      this.itemsCached.push(itemrecieved);
    }
 }
 // getvehiclewebsiteview
  getVehicle(id: number): Observable<Item> {
   

    this.item3 = undefined;
    this.itemsCached.forEach(element => {
      if (Number(element.id) === Number(id) ) { this.item3 = element; console.log(element); }
    });

  // if (this.item3 !== undefined) { console.log('returned cached vehicle'); return of(this.item3);   }
    return this.http.get<Item>(
      this.baseUrl + 'item/getitem/' +  id
    );
  }
  setMileageCodes(mileagecode: VehicleMileageCodes[]) {
    this.vehicleMileageCodesCached = mileagecode;
  }
  getmileagecodesPublic(): Observable<VehicleMileageCodes[]> {
 //   console.log('mileagecodes');
    if (this.vehicleMileageCodesCached.length > 1 ) {
      return of(this.vehicleMileageCodesCached);
    }
    return this.http.get<VehicleMileageCodes[]>(
      this.baseUrl + 'vehiclepublic/getmileagecodespublic/' + this.selectedCompany.parentGuid
    );
  }

  getBodyTypesPublic(): Observable<VehicleBodyClassTypes[]> {
    return this.http.get<VehicleBodyClassTypes[]>(
      this.baseUrl + 'vehiclepublic/getbodytypespublic/' + this.selectedCompany.parentGuid
    );
  }
  setYearsPublic(vehicleYears: VehicleYears[]) {
    this.vehicleYearsCached = vehicleYears;
  }
  getYearsPublic(): Observable<VehicleYears[]> {
    if (this.vehicleYearsCached.length > 0 ) { return of(this.vehicleYearsCached); }
    return this.http.get<VehicleYears[]>(
      this.baseUrl + 'vehiclepublic/getyears'
    );
  }
  setMakesPublic(makes: VehicleMake[]) {
    this.vehicleMakesCached = makes;
  }
  getMakesPublic(): Observable<VehicleMake[]> {
    if (this.vehicleMakesCached.length > 0 ) {return of(this.vehicleMakesCached); }
    return this.http.get<VehicleMake[]>(
      this.baseUrl + 'vehiclepublic/getmakespublic'
    );
  }
  editVehicle(v): Observable<Item> {
    // KL7CJLSB3GB653301
    return this.http.post<Item>(
      this.baseUrl + 'vehiclepublic/editvehicle/' + this.selectedCompany.parentGuid, v
    );
  }
  deletVehicle(vehicleid): Observable<Item> {
    // KL7CJLSB3GB653301
    return this.http.get<Item>(
      this.baseUrl + 'chartitem/deletevehicle/' + this.selectedCompany.parentGuid + '/' + vehicleid,
    );
  }
  getNewVehicle(tennantId: string): Observable<Item> {
    return this.http.get<Item>(
      this.baseUrl + 'vehiclepublic/getemptyvehicle/' + tennantId);
  }
  saveNonDecodedItem( item: Item): Observable<Item> {
    return this.http.post<Item>(
      this.baseUrl + 'vehiclepublic/savenondecodedvinitem', item);
  }
  /**
   * adds new vehicle and required sub tables and creates a item
   * @param send vehicle
   * @returns item, sale = sale,  vehicleChartItem = vehicleToReturned, 
   * and a item
   * @posting addnewvehicle/{parentGuid}/{tradein?}/{tradeininvoicenumber?}/{istradein1?}/{istradein2?}/{isservicevehicle?}
   */
  addVehicle(v: VehicleNewDto): Observable<any> {
    // KL7CJLSB3GB653301
  //  console.log(v);
  //  console.log(JSON.parse(JSON.stringify(v)) );
    return this.http.post<any>(
      this.baseUrl + 'vehiclepublic/addnewvehicle/' + this.selectedCompany.parentGuid, v
    );
    // return this.http.get<boolean>(
    //   this.baseUrl + 'vehicle/validurl1')
    // ;
  }
  addVehicleTrade1(v: VehicleNewDto, parentGuid, tradeininvoicenumber: number, tradein: boolean, istradein1?: boolean, istradein2?: boolean): Observable<any> {
    // KL7CJLSB3GB653301
  //  console.log(v);
    return this.http.post<any>(
      this.baseUrl + 'vehiclepublic/addnewvehicle/' + parentGuid + '/'  + tradein + '/'  + tradeininvoicenumber + '/' + istradein1 + '/' + istradein2, v
    );
  }
  // tslint:disable-next-line:max-line-length
  addVehicleTrade2(v, parentGuid, tradeininvoicenumber: number, tradein: boolean, istradein1: boolean, istradein2: boolean): Observable<any> {
    // KL7CJLSB3GB653301
    // tslint:disable-next-line:max-line-length
    // console.log(this.baseUrl + 'vehiclepublic/addnewvehicle/' + parentGuid + + '/' + tradein + '/' + tradeininvoicenumber + '/' + istradein1 + '/' + istradein2);
    return this.http.post<any>(
      // tslint:disable-next-line:max-line-length
      this.baseUrl + 'vehiclepublic/addnewvehicle/' + parentGuid  + '/' + tradein + '/' + tradeininvoicenumber + '/' + istradein1 + '/' + istradein2, v
    );
  }
  setUserParams(params: UserParams) {
    this.userParams = params;
  }
  getUserParams() {
    return this.userParams;
  }
  getVehicleListGrid(parentGuid, userParams: UserParams): Observable<PaginatedResult<InventoryVehicleGridToReturnDto[]>> {
    const paginatedResult: PaginatedResult<InventoryVehicleGridToReturnDto[]> = new PaginatedResult<InventoryVehicleGridToReturnDto[]>();
   // let params = new HttpParams();
  // console.log(this.userParams);
    if (userParams) {
      console.log(Object.values(userParams).join('-'));
      // tslint:disable-next-line:no-var-keyword
      const response = this.getVehicelListGridCache.get(Object.values(userParams).join('-'));
    if (response) {
      return of(response);
    }
    }
    let params = getPaginationHeaders(userParams.pageNumber, userParams.pageSize);
  //  console.log(this.userParams);
    // params = params.append('pageNumber', '1');
    //  params = params.append('pageSize', '50');
  //  params  = params.append('year', userParams.year.toString());
    if (userParams.year !== undefined) { params  = params.append('year', userParams.year); }
    if (userParams.make !== undefined) { params  = params.append('make', userParams.make); }
    if (userParams.model !== undefined) { params  = params.append('model', userParams.model); }
    if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
     if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
    if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
     if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
     if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
    if (userParams.returnByBodyTypeId !== undefined) { params  = params.append('returnByBodyTypeId', userParams.returnByBodyTypeId); }

    return getPaginatedResult<InventoryVehicleGridToReturnDto[]>(this.baseUrl + 'chartitem/getchartitemsvehiclesinventorylist/' + parentGuid, params, this.http)
      // tslint:disable-next-line:no-shadowed-variable
      .pipe(map(response => {
        this.getVehicelListGridCache.set(Object.values(userParams).join('-'), response);
        return response;
      }));
    // return this.http.get<InventoryVehicleGridToReturnDto[]>(this.baseUrl + 'chartitem/getchartitemsvehiclesinventorylist/' + parentGuid, {observe: 'response', params})
    // .pipe(
    //   map(response => {
    //     paginatedResult.result = response.body;
    //     if (response.headers.get('Pagination') != null) {
    //       paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
    //     }
    //     return paginatedResult;
    //   })
    // );
  }
}

