import { Component, Input, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { AmortisationClass } from 'src/app/_classes/dealinfoclasses/amortizatincl';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { Sale } from 'src/app/_models/sale';
import { AmoLoanService } from 'src/app/_services/amoloanservices/amoloan.service';

@Component({
  selector: 'app-sale-amortization',
  templateUrl: './sale-amortization.component.html',
  styleUrls: ['./sale-amortization.component.scss']
})
export class SaleAmortizationComponent implements OnInit {
  @Input() amoAmortization: AmoAmortization[];
  @Input() sale: Sale;
  constructor(  private calcLoans: AmortisationClass,
    private amoLoanService: AmoLoanService) { }

  ngOnInit() {
    console.log(this.amoAmortization);
    console.log(this.sale.amoLoans.id);
    this.calcLoans.savedAmortization.subscribe(data => {  this.amoAmortization = data; } );
    this.getAmortisation(this.sale.amoLoans.id);
  }
  getAmortisation(loanId: any) {
    this.amoLoanService.getAmortisation(loanId).subscribe(data => {
      this.calcLoans.currentSavedAmortization = of(data);
     this.amoAmortization = data;
     });
  }
}
