





  <div class="col-md-12 cal-grid">
    <ul class="calander-grid">
      <li><a href="javascript:void(0)" [ngClass]="{active: isJanuary }"  (click) = "setMonth('January')">Jan</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isFebruary }"  (click) = "setMonth('February')">Feb</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isMarch }"  (click) = "setMonth('March')">Mar</a></li>
      <li>
        <a href="javascript:void(0)" [ngClass]="{active: isApril }" (click) = "setMonth('April')">Apr</a>
      </li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isMay }"  (click) = "setMonth('May')">May</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isJune }"  (click) = "setMonth('June')">Jun</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isJuly }"  (click) = "setMonth('July')">Jul</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isAugust }"  (click) = "setMonth('August')">Aug</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isSeptember }"  (click) = "setMonth('September')">Sep</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isOctober }"  (click) = "setMonth('October')">Oct</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isNovember }" (click) = "setMonth('November')">Nov</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isDecember }"  (click) = "setMonth('December')">Dec</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isAll }"  (click) = "setMonth('All')">All</a></li>
      <li><a href="javascript:void(0)"   (click) = "setYear('plus')">+</a></li>
      <li><a href="javascript:void(0)" [ngClass]="{active: isYear }"  (click) = "setMonth('Year')">{{yearSelected}}</a></li>
      <li><a href="javascript:void(0)"   (click) = "setYear('minus')">-</a></li>
    </ul>
  </div>
  {{"Start Date: " +  startDate}}<br>
  {{"  End Date: " + endDate}}





