    <!-- <div class="row"> -->
      <div class="col-md-12">
        <div class="row" >
          <div  class="col-md-6">
            <div class="row">
  
            <div class="col-md-4">
              <select   class="form-control mr-2" #pdfFormTypeCategory="ngModel" ngControl="selectedCategoryType"  name="pdfFormCategoryTypeIds"    
                [(ngModel)] ="id"  
                (change)="formTypeCategorySelected($event.target.value)" 
                >
                <option  *ngFor="let item of pdfFormTypesCategorys; index as i"   [value]="item.id">{{item?.value}}</option>
                </select>
            </div>
            <div class="col-md-4" >
              <button class="btn btn-outline-secondary" (click) ="createFormType()" type="button" id="button-addon1">{{isNewFormTypeClickLabel}}</button>
            </div>
            <div class="col-md-4"></div>
          </div>
      
      
      </div>
  
      <div class="col-md-6">
        <div class="row">
  
        <div class="col-md-6">
        <!-- <span class="input-group-text" id="basic-addon2">@example.com</span> -->
        <input [hidden]="!isNewFormTypeClick" type="text" class="form-control float left" placeholder="Enter PDF Type for Category Selected!" aria-label="Recipient's username" aria-describedby="basic-addon2">
        </div>
        <div class="col-md-6">
          <button [hidden]="!isNewFormTypeClick"  class="btn btn-outline-secondary float left" type="button" id="button-addon1">Create Tree List: PDF Type</button>   
        </div>
                
      </div>
      </div>
      
      </div>
      </div>
      <!-- </div> -->
