import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Vehicle } from 'src/app/_models/vehicle';
import { VehicleService } from 'src/app/_services/vehicleserv/vehicle.service';

import { Pagination, PaginatedResult } from 'src/app/_models/pagination';
import { VehicleBodyClassTypes } from 'src/app/_models/VehicleBodyClassTypes';
import { VehicleGridToReturnDto } from 'src/app/_models/DTOS/vehiclegridtoreturndto';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ItemService } from 'src/app/_services/itemservices/item.service';
import { ExcelService } from 'src/app/_services/exportservices/excel.service';
import { AddVehicleVinModalComponent } from 'src/app/modals/add-vehicle-vin-modal/add-vehicle-vin-modal.component';
import { Item } from 'src/app/_models/item';
import { MessageService } from 'src/app/_services/messageserv/message.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { InventoryVehicleGridToReturnDto } from 'src/app/_models/DTOS/inventoryvehiclegriddto';



@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  selectedCompany1: UserAssignedToCompany;
  chartItems: VehicleGridToReturnDto[];
  bsModalRef: BsModalRef;
  bodyTypes: VehicleBodyClassTypes;
  isPublished: boolean;
  vehicle: Vehicle;
  pagination: Pagination;
  isAscending = true;
  isMakeAscending = true;
  isCategoryAscending = true;
  isStockAscending = true;
  isModelAscending = true;
  isBodyAscending = true;
  isPriceAscending = true;
  isPublishedAscending = true;
  isSoldDateAscending = true;
  isPickCountAscending = true;
  isProfitReport = false;
  isYearAscending = true;
  userParams = {} as any;
  selectedCategory;
  selectedSearchPhrase;
  isActive = false;
  isPublishedNeedsImages = false;
  isPublishedAll = false;
  isAll = false;
  isSold = false;
  isNonPublishedActive  = false;
  isPublishedSold = false;
  isPrint = false;
  isDeleted = false;

  constructor(private router: Router,
    private vehicleService: VehicleService,
    private modalService: BsModalService,
    private route: ActivatedRoute, private authService: AuthService,
    private chartService: ItemService,
    private alertify: ToastrService, private excelService: ExcelService,
    private confirmService: ConfirmService) { }

  ngOnInit() {
    this.isAll = true;
    this.selectedCategory = 0;
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      this.bodyTypes = data.bodyTypes;
      this.chartItems = data.chartItems.result;
      this.pagination = data.chartItems.pagination;
    });
    this.userParams.returnByBodyTypeId = undefined;
 //   this.userParams.year = 2017;
    // this.userParams.make = 'LEXUS';
   console.log(this.chartItems);
  }
  isActiveDeletedClick(isDeleted) {
    this.isDeleted = false;
    this.isAll = false;
    this.isActive = false;
    console.log(isDeleted);
    if (isDeleted === 'isDeleted') {
      this.userParams.isDeleted = 'isDeleted';
      this.isDeleted = true;
    } else { this.isActive = true; this.userParams.isDeleted = 'isActive'; }
    if (isDeleted === 'isAll') {
      this.userParams.isDeleted = 'isAll';
      this.isDeleted = false;
      this.isAll = true;
      this.isActive = false;
    }
    this.loadVehicles();
    console.log(this.chartItems);
  }
  selectedCategoryChanged(e){
    this.userParams.returnByBodyTypeId = '';
    console.log(this.selectedCategory);
    if (this.selectedCategory === 0) { this.userParams.returnByBodyTypeId = undefined; }
    if (this.selectedCategory === null) { this.userParams.returnByBodyTypeId = undefined; }
    if (e > 0) {this.userParams.returnByBodyTypeId = e; }
    this.loadVehicles();
  }

  filter(e) {
    console.log(e);
    this.userParams.filter = e;
    this.loadVehicles();
  }
  sort(e) {
    this.isMakeAscending = true;
    this.isCategoryAscending = true;
    this.isStockAscending = true;
    this.isModelAscending = true;
    this.isBodyAscending = true;
    this.isPriceAscending = true;
    this.isPublishedAscending = true;
    this.isSoldDateAscending = true;
    this.isPickCountAscending = true;
    this.isYearAscending = true;
    this.userParams.orderBy = e;
    this.isAscending = !this.isAscending;
    this.userParams.isAscending = this.isAscending;
    this.userParams.itemsPerPage = 50;
    console.log(e);
    this.loadVehicles();
    if (e === 'make') {this.isMakeAscending = this.isAscending; }
    if (e === 'model') {this.isModelAscending = this.isAscending; }
    if (e === 'category') {this.isCategoryAscending = this.isAscending; }
    if (e === 'stock') {this.isStockAscending = this.isAscending; }
    if (e === 'year') {this.isYearAscending = this.isAscending; }
    if (e === 'body') {this.isBodyAscending = this.isAscending; }
    if (e === 'price') {this.isPriceAscending = this.isAscending; }
    if (e === 'solddate') {this.isSoldDateAscending = this.isAscending; }
    if (e === 'pickcount') {this.isPickCountAscending = this.isAscending; }

    if (e === 'published') {this.isPublishedAscending = this.isAscending; }

  }
  async setSearchBy(e, event) {
    setTimeout(() => {
      console.log(event);
      console.log(e);
      this.userParams.searchBy = e;
      this.userParams.searchFor = event;
     // this.loadVehicles();
     this.pageChanged(1);
    }, 2000);
  }
  updateIsPublished(e) {
    console.log(e);
    this.isPublished = !e.isPublished;
    this.chartService.setIsPublished(this.selectedCompany1.parentGuid, e.id, this.isPublished).subscribe(() => {
      this.alertify.success('Published Updated');
      this.loadVehicles();
    }, error => { this.alertify.error(error); }, () => {
      this.loadVehicles();
    });
  }
  pageChanged(event?: any): void {
    this.pagination.currentPage = event.page;
    this.loadVehicles();
  }
  loadVehicles() {
    this.chartService.getChartItems(this.selectedCompany1.parentGuid, this.pagination.currentPage,
      this.pagination.itemsPerPage, this.userParams)
    .subscribe((data: PaginatedResult <VehicleGridToReturnDto[]>) => {
      this.chartItems = data.result;
      this.pagination = data.pagination;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Vehicles Retrieved.');
    });
  }
  addItem() {
    this.router.navigate(['productlist/additem']);
  }

  editVehicle(item: Item) {
  //  this.messageService.sendMessage(item);
    this.router.navigate(['vehicledashboard/editvehicle/' +   this.selectedCompany1.parentGuid + '/' + item.id]);
  }

  deleteVehicle(item: Item) {
    console.log(item);
    this.confirmService.confirm('Confirm delete vehicle', 'Are you sure?').subscribe(result => {
      if (result) {
        this.vehicleService.deletVehicle(item.id).subscribe(data => {}, err => { console.log(err); },
        () => { this.loadVehicles(); });
    }
    });
  }
  addVehicleNoVin() {
    this.router.navigate(['vehicledashboard/addvehicle/' +   this.selectedCompany1.parentGuid ]);
  }
  addVehicle() {
      this.bsModalRef = this.modalService.show(AddVehicleVinModalComponent);
      this.bsModalRef.content.updateVehicle.subscribe((values) => {
        this.vehicleService.addVehicle(values).subscribe(data => {
          // this.chartItems = data.chartItems;
          this.router.navigate(['vehicledashboard/editvehicle/' +   this.selectedCompany1.parentGuid + '/' + data.item.id]);
        });
      });
  }

  exportAsXLSX(): void {
    // InventoryVehicleGridToReturnDto
    // VehicleGridToReturnDto
    this.pagination.itemsPerPage = 1000000;
    this.chartService.getVehicleListGrid(this.selectedCompany1.parentGuid, this.pagination.currentPage,
      this.pagination.itemsPerPage, this.userParams)
    .subscribe((data: PaginatedResult <InventoryVehicleGridToReturnDto[]>) => {
      this.excelService.exportAsExcelFile(data.result, 'Vehicles Advertised');
  //    this.pagination = data.pagination;
      this.pagination.itemsPerPage = 15;
    }, error => { this.alertify.error(error); }, () => { this.alertify.success('Your Export is Ready'); } );
 }
async printDiv() {
 await this.hideDiv().then(() => {
  setTimeout(() => {
    this.print();

  }, 2000);
   });
 // await this.print();

}
async hideDiv() {
  this.isPrint = true;
}
async print() {
  // window.frames.print_frame.document.body.innerHTML = document.getElementById('printableTable').innerHTML;
  // window.frames.print_frame.window.focus();
  // window.frames.print_frame.window.print();
  // this.isPrint = false;
}
}
