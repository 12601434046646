import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VehicleMileageCodes } from 'src/app/_models/VehicleMileageCodes';


@Component({
  selector: 'app-vehicle-mileage-codes-modal',
  templateUrl: './vehicle-mileage-codes-modal.component.html',
  styleUrls: ['./vehicle-mileage-codes-modal.component.css']
})
export class VehicleMileageCodesModalComponent implements OnInit {
  @Output() update = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  vehicleMileageCodes: VehicleMileageCodes;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  updateStatus() {
    this.vehicleMileageCodes = this.editForm.value;
    this.update.emit(this.vehicleMileageCodes);
    this.bsModalRef.hide();
  }
}
