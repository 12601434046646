
<div class="col-md-12">
  <h4>Labels For: &nbsp;{{formLabelNames.value}}</h4>
</div>
 <div class="col-md-12">
  <div class="row"> 
      <div class="form-sec tab-content col-md-9">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <table border="1" borderColor="#ccc">
                      <tr>
                        <th>Id</th>
                        <th>Data base name</th>
                        <th>Default Value</th>
                        <th>Customer Assigned Name</th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr class="td-row" *ngFor="let item of labels">
                            <td>{{ item.id }}</td>
                            <td>{{ item.valueDefault }}</td>
                            <td>{{ item.valueDefault }}</td>
                            <td>{{ item.value }}</td>
                        <td >
                            <a
                            [ngClass]="{'btn btn-danger' : item.deletedDate , 'btn btn-warning' : !item.dateDeleted}"
                           
                            (click)="delete(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Is Active"
                            ><i  class="fa fa-fw fa-check-o"></i
                          ></a>
                        </td>
                        <td >
                            <a
                            class="btn btn-info"
                            (click)="edit(item)"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit Label Name"
                            ><i class="fa fa-fw fa-files-o"></i
                          ></a>
                        </td>
                       
                      </tr>
                    </table>
                  </div>
                  <!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      </div>
      <div style="padding-left: 90px;" class="customer-sidebar sidebar-with-btns col-md-1">
          <div class="inner-sidebar">
              <ul  style="list-style-type:none;">
                  <li>
                      <button  (click)="addLabel()" class="cstm-btn img-blk-btn">
                        <img src="assets/images/ap-icon-1.png">
                        <span>Add New</span>
                      </button>
                    </li>

              </ul>
            </div>
        </div>
    </div>
  </div>



