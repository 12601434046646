	

	
	
	
		<div style="background-color: rgb(145, 137, 137)" class="modal1 " id="buyer-guide-model" role="dialog" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<img src="../../../assets/images/buyer-title-icon.jpg">
						<h4 class="modal-title">Add Buyers Guide Default</h4>
						<button (click)="close()" type="button" class="close" data-dismiss="modal1">&times;</button>
					</div>
					<div class="modal-body1">
						<form id="editForm" #editForm="ngForm" (ngSubmit)="save()">
						<div class="stock-rw">
							<label>Defaults name</label>
							<input 
                            [ngModel]="vehicleBuyersGuide?.name"
							(ngModel)="vehicleBuyersGuide.name"
                            (ngModelChange)="vehicleBuyersGuide.name = $event"
                            name="name"
                            required
							type="text"  value="110530" class="cstm-input">
						</div>

						<div class="radio-sec">
							<div class="rw">
								<input
								[ngModel]="vehicleBuyersGuide?.isAsIs"
								(ngModel)="vehicleBuyersGuide.isAsIs"
								#isAsIs="ngModel"
								name="isAsIs"
								(ngModelChange)="vehicleBuyersGuide.isAsIs = !vehicleBuyersGuide.isAsIs"
								
								type="checkbox" value="1" class="cstm_rad" id="no-warranty" >
								<label for="no-warranty">As is - no warranty</label>
							</div>
							<div class="rw">
								<input 
								[ngModel]="vehicleBuyersGuide?.isDealerWarranty"
								(ngModel)="vehicleBuyersGuide.isDealerWarranty"
								#isDealerWarranty="ngModel"
								(ngModelChange)="vehicleBuyersGuide.isDealerWarranty = !vehicleBuyersGuide.isDealerWarranty"
								name="isDealerWarranty"
								type="checkbox"  class="cstm_rad" id="warranty">
								<label for="warranty">Warranty</label>
							</div>
						</div>
						<div class="warranty-form">
							<div class="inner">
								<h2 class="war-title">Warranty</h2>
								<div class="cstm-rw">
									<ul>
										<li>
											<input 
											(ngModelChange)="vehicleBuyersGuide.isFullWaranty = !vehicleBuyersGuide.isFullWaranty"
											name="isFullWaranty"
											(ngModel)="vehicleBuyersGuide.isFullWaranty"
											[ngModel]="vehicleBuyersGuide?.isFullWaranty"
											#isFullWaranty="ngModel"
											type="checkbox"  value="1" id="war_rad1" checked="" class="war_rad">
											<label for="war_rad1">Full</label>
										</li>
										<li>
											<input 
											(ngModelChange)="vehicleBuyersGuide.isLimitedWarranty = !vehicleBuyersGuide.isLimitedWarranty"
											name="isLimitedWarranty"
											(ngModel)="vehicleBuyersGuide.isLimitedWarranty"
											[ngModel]="vehicleBuyersGuide?.isLimitedWarranty"
											#isLimitedWarranty="ngModel"
											type="checkbox"  value="1" id="war_rad2" class="war_rad">
											<label for="war_rad2">Limited Warr.</label>
										</li>
										<li>
											<span class="dlr-pay-title">Dealer will pay</span>
											<input type="text" 
											(ngModelChange)="vehicleBuyersGuide.percentLabor = $event"
											name="percentLabor"
											(ngModel)="vehicleBuyersGuide.percentLabor"
											[ngModel]="vehicleBuyersGuide?.percentLabor"
											#labor="ngModel"
											class="cstm-input">
											<span>% (labor) and </span>
										</li>
										<li>
											<input type="text"
											(ngModelChange)="vehicleBuyersGuide.percentParts = $event"
											(ngModel)="vehicleBuyersGuide.percentParts"
											name="percentParts"
											[ngModel]="vehicleBuyersGuide?.percentParts"
											#percentParts="ngModel"
											class="cstm-input">
											<span>% (parts)</span>
										</li>
									</ul>
								</div>
								<div class="stytem-grid-sec">
									
									<!--DROP DOWN OF SYSTEMS COVERED-->
								</div>
							</div>
							<div class="warranty-footer">
								<ul class="first-ul-list">
									
									<li>
										<input type="checkbox"
										(ngModelChange)="vehicleBuyersGuide.isManufactureStillApply = !vehicleBuyersGuide.isManufactureStillApply"
											[ngModel]="vehicleBuyersGuide?.isManufactureStillApply"
											(ngModel)="vehicleBuyersGuide.isManufactureStillApply"
											#isManufactureStillApply="ngModel"
										name="isManufactureStillApply" id="pre-print" class="wr-cbk">
										<label for="pre-print">Manufacture Warranty Still Applies</label>
									</li>
									<li>
										<input type="checkbox"
										(ngModelChange)="vehicleBuyersGuide.isManufacturerUsedCarWarranty = !vehicleBuyersGuide.isManufacturerUsedCarWarranty"
											[ngModel]="vehicleBuyersGuide?.isManufacturerUsedCarWarranty"
											(ngModel)="vehicleBuyersGuide.isManufacturerUsedCarWarranty"
											#isManufacturerUsedCarWarranty="ngModel"
										name="isManufacturerUsedCarWarranty" id="pre-print" class="wr-cbk">
										<label for="pre-print">Manufacture Used Vehicle Warranty</label>
									</li>
									<li>
										<input type="checkbox"
										(ngModelChange)="vehicleBuyersGuide.isOtherWarranty = !vehicleBuyersGuide.isOtherWarranty"
											[ngModel]="vehicleBuyersGuide?.isOtherWarranty"
											(ngModel)="vehicleBuyersGuide.isOtherWarranty"
											#isOtherWarranty="ngModel"
										name="isOtherWarranty" id="pre-print" class="wr-cbk">
										<label for="pre-print">Other Warranty</label>
									</li>

									<li>
										<input type="checkbox" 
										(ngModelChange)="vehicleBuyersGuide.isServiceContract = !vehicleBuyersGuide.isServiceContract"
											[ngModel]="vehicleBuyersGuide?.isServiceContract"
											(ngModel)="vehicleBuyersGuide.isServiceContract"
											#isServiceContract="ngModel"
											name="isServiceContract" id="service-cnt" class="wr-cbk">
										<label for="service-cnt">Service Contract</label>
									</li>
								</ul>
								<div class="btn-sec">
									<ul>
										<li><button class="cstm-btn" (click)="save()">Save</button></li>

										<li  class="pos-right"><button (click)="close()"  class="cstm-btn" data-dismiss="modal">Close</button></li>
									</ul>
								</div>
							</div>
					
						</div>
					</form>
					</div>
				</div>
			</div>
		</div>
	
