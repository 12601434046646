import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'jquery';
import { Observable, of } from 'rxjs';
import { scan } from 'rxjs/operators';
import { ExportDropDowns } from 'src/app/_models/dropdowns';
import { PaymentType } from 'src/app/_models/PaymentType';
import { Vendor } from 'src/app/_models/vendor';
import { environment } from 'src/environments/environment';
import { ListService } from '../listServices/list.service';


@Injectable({
  providedIn: 'root'
})
export class VendorService {
  baseUrl = environment.apiUrl;
  exportDropDowns: ExportDropDowns;

  constructor(private http: HttpClient, private listService: ListService) { }

  getLienHolders(parentguid: number): Observable<Vendor[]> {
    return this.http.get<Vendor[]>(this.baseUrl + 'vendor/getlienholders/' + parentguid);
  }

  getInsuranceCompanies(parentguid: string): Observable<Vendor[]> {
    return this.http.get<Vendor[]>(this.baseUrl + 'vendor/getinsurancecompanies/' + parentguid);
  }
  getBoth(parentguid: string): Observable<ExportDropDowns> {
  //  console.log(this.exportDropDowns);
    if (this.exportDropDowns !== undefined) {
      return of(this.exportDropDowns);
    }
     this.http.get<ExportDropDowns>(this.baseUrl + 'vendor/getsaledropdowns/' + parentguid).subscribe((data: ExportDropDowns) => {
      console.log('dropdowns Just recieved');
      console.log(data);
        this.exportDropDowns = data;
        // this.listService.changeVehicleLavelFormName(data.formNameVehicle);
        // this.listService.changeSaleLabelFormName(data.formNameDealInfo);packageButtonLocations
        this.listService.changeSaleDefaultTemplate(data.saleDefaultTemplate);
        this.listService.changeWarrantyCompanies(data.warrantyCompanies);
        this.listService.changeLienHolders(data.insuranceCompanies);
        this.listService.changeInsuranceCompanies(data.lienHolders);
        this.listService.changeSaleTypes(data.saleTypes);
        this.listService.changeSaleStatus(data.saleStatuses);
        this.listService.changePaymentTypes(data.paymentTypes);
        this.listService.changeTaxDefaults(data.saleTaxDefaults);
        this.listService.changeAmoInstallments(data.installments);
        this.listService.changeAmoLoanType(data.amoLoanMethods);
        this.listService.changePdfFormPackages(data.pdfFormPackages);
    }, error => { console.log(error); }, () => {
      return of(this.exportDropDowns);
    });
  }
}

