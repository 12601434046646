import { zeros } from 'mathjs';
import * as moment from 'moment';
import { AmoLoan } from 'src/app/_models/amoloans';
import { AmoPayment } from 'src/app/_models/amopayments';
import { Sale } from 'src/app/_models/sale';
import { SaleDefault } from 'src/app/_models/SaleDefault';
import { SaleDefaultTemplate } from 'src/app/_models/SaleDefaultTemplate';
import { SaleTaxDefault } from 'src/app/_models/SaleTaxDefault';

export class DealInforFormat {

// = default languare? undefined
// 'en-Us' US ONLY
    fnz02 = (num) => num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
      })

        fnz04 = (num) => num.toLocaleString('en-US', {
          minimumFractionDigits: 4,
          maximumFractionDigits: 4,
          useGrouping: false,
          })
            fnz0 = (num) => num.toLocaleString('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              useGrouping: false,
              })
  async  formatPaymentNumber(payment: AmoPayment): Promise<AmoPayment>{
    console.log(payment);
    if ( payment !== undefined) {
      console.log(payment);
      if (payment?.todayLoanPaid === null || payment?.todayLoanPaid === undefined ) { payment.todayLoanPaid = Number(this.fnz02(0));
      } else {
        payment.todayLoanPaid = this.fnz02(payment.todayLoanPaid);
      }
      if (payment?.todayLateFee === null || payment?.todayLateFee === undefined ) { payment.todayLateFee = Number(this.fnz02(0));
      } else {
        payment.todayLateFee = this.fnz02(payment.todayLateFee);
      }

      if (payment?.todayMisc === null || payment?.todayMisc === undefined ) { payment.todayMisc = Number(this.fnz02(0));
      } else {
        payment.todayMisc = this.fnz02(payment.todayMisc);
      }

      if (payment?.todayDeferredDown === null || payment?.todayDeferredDown === undefined ) { payment.todayDeferredDown = Number(this.fnz02(0));
      } else {
        payment.todayDeferredDown = this.fnz02(payment.todayDeferredDown);
      }
      payment.sumPaidToday = Number(payment.todayDeferredDown) + Number(payment.todayLoanPaid) +
      Number(payment.todayLateFee) + Number(payment.todayMisc);
      if (payment?.sumPaidToday === null || payment?.sumPaidToday === undefined ) { 
        payment.totalSumPaid = Number(this.fnz02(0));
      } else {
        payment.totalSumPaid = this.fnz02(payment.totalSumPaid);
        if (payment.totalSumPaid < 0) {this.fnz02('0'); }
      }
  }
  console.log(payment);
  return payment;
}
  async  formatDealInforNumbers(sale: Sale): Promise<Sale>{

      if ( sale !== undefined) {


    if (sale?.sellingPrice === null || sale?.sellingPrice === undefined ) { sale.sellingPrice = Number(this.fnz02(0));
    } else {
      sale.sellingPrice = this.fnz02(sale.sellingPrice);
    }

    if (sale.smogC1 === null  || sale?.smogC1 === undefined) { sale.smogC1 = Number(this.fnz02(0)); }
    sale.smogC1 = this.fnz02(sale.smogC1);

    if (sale.tag === null || sale?.tag === undefined) { sale.tag = this.fnz02(0); }
    sale.tag = this.fnz02(sale.tag);

    if (sale.tax === null || sale?.tax === undefined) { sale.tax = this.fnz02(0); }
    sale.tax = this.fnz02(sale.tax);

    if (sale.taxRate1 === null || sale?.taxRate1 === undefined) { sale.taxRate1 = this.fnz02(0); }
    sale.taxRate1 = this.fnz02(sale.taxRate1);

    if (sale.taxRate2 === null || sale?.taxRate2 === undefined) { sale.taxRate2 = this.fnz04(0); }
    sale.taxRate2 = this.fnz04(sale.taxRate2);

if (sale.taxRate3 === null || sale?.taxRate3 === undefined) { sale.taxRate3 = this.fnz02(0); }
    sale.taxRate3 = this.fnz02(sale.taxRate3);

    if (sale.taxRate4 === null || sale?.taxRate4 === undefined) { sale.taxRate4 = this.fnz02(0); }
    sale.taxRate4 = this.fnz02(sale.taxRate4);

    if (sale.title === null || sale?.title === undefined) { sale.title = this.fnz02(0); }
    sale.title = this.fnz02(sale.title);

    if (sale.totalPaid === null || sale?.totalPaid === undefined) { sale.totalPaid = this.fnz02(0); }
    sale.totalPaid = this.fnz02(sale.totalPaid);

    if (sale.totalProfit === null || sale?.totalProfit === undefined) { sale.totalProfit = this.fnz02(0); }
    sale.totalProfit = this.fnz02(sale.totalProfit);

    if (sale.totalTax1 === null || sale?.totalTax1 === undefined) { sale.totalTax1 = this.fnz02(0); }
    sale.totalTax1 = this.fnz02(sale.totalTax1);

    if (sale.totalTax2 === null || sale?.totalTax2 === undefined) { sale.totalTax2 = this.fnz02(0); }
    sale.totalTax2 = this.fnz02(sale.totalTax2);

    if (sale.totalTax4 === null || sale?.totalTax4 === undefined) { sale.totalTax4 = this.fnz02(0); }
    sale.totalTax4 = this.fnz02(sale.totalTax4);

    if (sale.totalTax3 === null  || sale?.totalTax3 === undefined) { sale.totalTax3 = this.fnz02(0); }
    sale.totalTax3 = this.fnz02(sale.totalTax3);

    if (sale.tradeAllow === null || sale?.tradeAllow === undefined) { sale.tradeAllow = this.fnz02(0); }
    sale.tradeAllow = this.fnz02(sale.tradeAllow);

    if (sale.tradeLien === null || sale?.tradeLien === undefined) { sale.tradeLien = this.fnz02(0); }
    sale.tradeLien = this.fnz02(sale.tradeLien);

    if (sale.wCost === null || sale?.wCost === undefined) { sale.wCost = this.fnz02(0); }
    sale.wCost =  this.fnz02(sale.wCost);

    if (sale.wExtraNum === null || sale?.wExtraNum === undefined) { sale.wExtraNum = this.fnz02(0); }
    sale.wExtraNum = this.fnz02(sale.wExtraNum);

    if (sale.warranty === null || sale?.warranty === undefined) { sale.warranty = this.fnz02(0); }
    sale.warranty = this.fnz02(sale.warranty);

    if (sale.warrantyCost === null || sale?.warrantyCost === undefined) { sale.warrantyCost = this.fnz02(0); }
    sale.warrantyCost = this.fnz02(sale.warrantyCost);

    if (sale.zAPR === null || sale?.zAPR === undefined) { sale.zAPR = this.fnz04(0); }
    sale.zAPR = Number( this.fnz04(sale.zAPR));

    if (sale.apr === null || sale?.apr === undefined) { sale.apr = this.fnz04(0); }
    sale.apr = this.fnz04(sale.apr);

    if (sale.aCV === null || sale?.aCV === undefined) { sale.aCV = this.fnz02(0); }
    sale.aCV = this.fnz02(sale.aCV);

    if (sale.aCV2 === null || sale?.aCV2 === undefined) { sale.aCV2 = this.fnz02(0); }
    sale.aCV2 = this.fnz02(sale.aCV2);

    if (sale.addOn === null || sale?.addOn === undefined) { sale.addOn = this.fnz02(0); }
    sale.addOn = this.fnz02(sale.addOn);


    if (sale.amountFinanced === null || sale?.amountFinanced === undefined) { sale.amountFinanced = this.fnz02(0); }
    sale.amountFinanced = this.fnz02(sale.amountFinanced);

    if (sale.amtTaxed1 === null || sale?.amtTaxed1 === undefined) { sale.amtTaxed1 = this.fnz02(0); }
    sale.amtTaxed1 = this.fnz02(sale.amtTaxed1);

    if (sale.amtTaxed2 === null || sale?.amtTaxed2 === undefined) { sale.amtTaxed2 = this.fnz02(0); }
    sale.amtTaxed2 = this.fnz02(sale.amtTaxed2);

    if (sale.amtTaxed3 === null || sale?.amtTaxed3 === undefined) { sale.amtTaxed3 = this.fnz02(0); }
    sale.amtTaxed3 = this.fnz02(sale.amtTaxed3);

    if (sale.amtTaxed4 === null || sale?.amtTaxed4 === undefined) { sale.amtTaxed4 = this.fnz02(0); }
    sale.amtTaxed4 = this.fnz02(sale.amtTaxed4);

    if (sale.cashDown === null || sale?.cashDown === undefined) { sale.cashDown = this.fnz02(0); }
    sale.cashDown = this.fnz02(sale.cashDown);

    if (sale.balance === null || sale?.balance === undefined) { sale.balance = this.fnz02(0); }
    sale.balance = this.fnz02(sale.balance);

    if (sale.commission2 === null || sale?.commission2 === undefined) { sale.commission2 = this.fnz02(0); }
    sale.commission2 = this.fnz02(sale.commission2);

    if (sale.creditLife === null || sale?.creditLife === undefined) { sale.creditLife = this.fnz02(0); }
    sale.creditLife = this.fnz02(sale.creditLife);

    if (sale.currentDue === null || sale?.currentDue === undefined) { sale.currentDue = this.fnz02(0); }
    sale.currentDue = this.fnz02(sale.currentDue);

    if (sale.dapr === null || sale?.dapr === undefined) { sale.dapr = this.fnz02(0); }
    sale.dapr = this.fnz02(sale.dapr);

    if (sale.disability === null || sale?.disability === undefined) { sale.disability = this.fnz02(0); }
    sale.disability = this.fnz02(sale.disability);


    if (sale.discount === null || sale?.discount === undefined) { sale.discount = this.fnz02(0); }
    sale.discount = this.fnz02(sale.discount);

    // if (sale.docAuto === null || sale?.docAuto === undefined) { sale.docAuto = this.fnz02(0); }
    // sale.docAuto = this.fnz02(sale.docAuto);

    if (sale.expense === null || sale?.expense === undefined) { sale.expense = this.fnz02(0); }
    sale.expense = this.fnz02(sale.expense);

    if (sale.fn1 === null || sale?.fn1 === undefined) { sale.fn1 = this.fnz02(0); }
    sale.fn1 = this.fnz02(sale.fn1);

    if (sale.fn2 === null || sale?.fn2 === undefined) { sale.fn2 = this.fnz02(0); }
    sale.fn2 = this.fnz02(sale.fn2);

    if (sale.fn3 === null || sale?.fn3 === undefined) { sale.fn3 = this.fnz02(0); }
    sale.fn3 = this.fnz02(sale.fn3);

    if (sale.fn4 === null || sale?.fn4 === undefined) { sale.fn4 = this.fnz02(0); }
    sale.fn4 = this.fnz02(sale.fn4);

    if (sale.fn5 === null || sale?.fn5 === undefined) { sale.fn5 = this.fnz02(0); }
    sale.fn5 = this.fnz02(sale.fn5);

    if (sale.registration === null || sale?.registration === undefined) { sale.registration = this.fnz02(0); }
    sale.registration = this.fnz02(sale.registration);

    // if (sale.gAP === null || sale?.gAP === undefined) { sale.gAP = this.fnz02(0); }
    // sale.gAP = this.fnz02(sale.gAP);

    if (sale.gapCost === null || sale?.gapCost === undefined) { sale.gapCost = this.fnz02(0); }
    sale.gapCost = this.fnz02(sale.gapCost);

    // if (sale.h10day === null || sale?.h10day === undefined) { sale.h10day = this.fnz02(0); }
    // sale.h10day = this.fnz02(sale.h10day);

    // if (sale.hAdd === null || sale?.hAdd === undefined) { sale.hAdd = this.fnz02(0); }
    // sale.hAdd = this.fnz02(sale.hAdd);

    // if (sale.hComp === null || sale?.hComp === undefined) { sale.hComp = this.fnz02(0); }
    // sale.hComp = this.fnz02(sale.hComp);

    if (sale.interest === null || sale?.interest === undefined) { sale.interest = this.fnz02(0); }
    sale.interest = this.fnz02(sale.interest);

    if (sale.term === null || sale?.term === undefined) { sale.term = this.fnz0(0); }
    sale.term = this.fnz0(sale.term);

    if (sale.interestDays === null || sale?.interestDays === undefined) { sale.interestDays = this.fnz02(0); }
    sale.interestDays = this.fnz02(sale.interestDays);

    if (sale.lateFee === null || sale?.lateFee === undefined) { sale.lateFee = this.fnz02(0); }
    sale.lateFee = this.fnz02(sale.lateFee);

    if (sale.miscNonTax === null || sale?.miscNonTax === undefined) { sale.miscNonTax = this.fnz02(0); }
    sale.miscNonTax = this.fnz02(sale.miscNonTax);

    if (sale.miscTaxable === null || sale?.miscTaxable === undefined) { sale.miscTaxable = this.fnz02(0); }
    sale.miscTaxable = this.fnz02(sale.miscTaxable);

    if (sale.netReserve === null || sale?.netReserve === undefined) { sale.netReserve = this.fnz02(0); }
    sale.netReserve = this.fnz02(sale.netReserve);

    if (sale.netReservePrc === null || sale?.netReservePrc === undefined) { sale.netReservePrc = this.fnz02(0); }
    sale.netReservePrc = this.fnz02(sale.netReservePrc);

    if (sale.passPmts === null || sale?.passPmts === undefined) { sale.passPmts = this.fnz02(0); }
    sale.passPmts = this.fnz02(sale.passPmts) ;

    if (sale.payOff === null || sale?.payOff === undefined) { sale.payOff = this.fnz02(0); }
    sale.payOff = this.fnz02(sale.payOff);

    if (sale.payment === null || sale?.payment === undefined) { sale.payment = this.fnz02(0); }
    sale.payment = this.fnz02(sale.payment);

    if (sale.pickAmount1 === null || sale?.pickAmount1 === undefined) { sale.pickAmount1 = this.fnz02(0); }
    sale.pickAmount1 = this.fnz02(sale.pickAmount1);


    if (sale.pickAmount2 === null || sale?.pickAmount2 === undefined) { sale.pickAmount2 = this.fnz02(0); }
    sale.pickAmount2 = this.fnz02(sale.pickAmount2);

    if (sale.pickAmount3 === null || sale?.pickAmount3 === undefined) { sale.pickAmount3 = this.fnz02(0); }
    sale.pickAmount3 = this.fnz02(sale.pickAmount3);

    if (sale.pickAmount4 === null || sale?.pickAmount4 === undefined) { sale.pickAmount4 = this.fnz02(0); }
    sale.pickAmount4 = this.fnz02(sale.pickAmount4);

    if (sale.pickAmount5 === null || sale?.pickAmount5 === undefined) { sale.pickAmount5 = this.fnz02(0); }
    sale.pickAmount5 = this.fnz02(sale.pickAmount5);

    if (sale.predelivery === null || sale?.predelivery === undefined) { sale.predelivery = this.fnz02(0); }
    sale.predelivery = this.fnz02(sale.predelivery);

    if (sale.prepaidFee === null || sale?.prepaidFee === undefined) { sale.prepaidFee = this.fnz02(0); }
    sale.prepaidFee = this.fnz02(sale.prepaidFee);

    if (sale.reserve === null || sale?.reserve === undefined) { sale.reserve = this.fnz02(0); }
    sale.reserve = this.fnz02(sale.reserve);

    if (sale.amortized === null || sale?.amortized === undefined) { sale.amortized = this.fnz02(0); }
    sale.amortized = this.fnz02(sale.amortized);

    if (sale.nonAmortized === null || sale?.nonAmortized === undefined) { sale.nonAmortized = this.fnz02(0); }
    sale.nonAmortized = this.fnz02(sale.nonAmortized);

    if (sale.reserve === null || sale?.reserve === undefined) { sale.reserve = this.fnz02(0); }
    sale.reserve = this.fnz02(sale.reserve);

    if (sale.reservePrc === null || sale?.reservePrc === undefined) { sale.reservePrc = this.fnz02(0); }
    sale.reservePrc = this.fnz02(sale.reservePrc);
    sale.totalTax1 = this.fnz02(sale.amtTaxed1 * sale.taxRate1 * .01);
    sale.totalTax2 = this.fnz02(sale.amtTaxed2 * sale.taxRate2 * .01);
    sale.totalTax3 = this.fnz02(sale.amtTaxed3 * sale.taxRate3 * .01);
    sale.totalTax4 = this.fnz02(sale.amtTaxed4 * sale.taxRate4 * .01);

    sale.tax = Number(sale.totalTax1 + sale.totalTax2 + sale.totalTax3 + sale.totalTax4);


    this.calculateTaxes(sale);
  }
    return sale;
  }
  async calculateTaxes(sale: Sale) {

    if ( sale !== undefined) {
        if (sale?.saleDefaults?.saleTaxDefaults.length > 0) {
          sale.amtTaxed1 = Number(0);
          sale.amtTaxed2 = Number(0);
          sale.amtTaxed3 = Number(0);
          sale.amtTaxed4 = Number(0);
          sale?.saleDefaults?.saleTaxDefaults.forEach(element => {
            if (element.fieldName === 'Selling Price') {
              if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.sellingPrice); }
              if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.sellingPrice); }
              if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.sellingPrice); }
              if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.sellingPrice); }
            }
           if (element.fieldName === 'Predelivery') {
             if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.predelivery); }
             if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.predelivery); }
             if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.predelivery); }
             if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.predelivery); }
           }
           if (element.fieldName === 'Title') {
            if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.title); }
            if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.title); }
            if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.title); }
            if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.title); }
          }
          if (element.fieldName === 'Registration') {
            if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.registration); }
            if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.registration); }
            if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.registration); }
            if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.registration); }
          }
          if (element.fieldName === 'Tag') {
            if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.tag); }
            if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.tag); }
            if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.tag); }
            if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.tag); }
          }
          if (element.fieldName === 'MiscTaxable') {
            if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.miscTaxable); }
            if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.miscTaxable); }
            if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.miscTaxable); }
            if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.miscTaxable); }
          }
          if (element.fieldName === 'MiscNonTax') {
            if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.miscNonTax); }
            if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.miscNonTax); }
            if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.miscNonTax); }
            if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.miscNonTax); }
          }
          if (element.fieldName === 'SmogC1') {
            if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.smogC1); }
            if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.smogC1); }
            if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.smogC1); }
            if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.smogC1); }
          }
          if (element.fieldName === 'TradeAllow') {
            if (!element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) - Number(sale.tradeAllow); }
            if (!element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) - Number(sale.tradeAllow); }
            if (!element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) - Number(sale.tradeAllow); }
            if (!element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) - Number(sale.tradeAllow); }
          }
          if (element.fieldName === 'Disability') {
            if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.disability); }
            if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.disability); }
            if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.disability); }
            if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.disability); }
          }
          if (element.fieldName === 'CreditLife') {
            if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.creditLife); }
            if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.creditLife); }
            if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.creditLife); }
            if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.creditLife); }
          }
          if (element.fieldName === 'Warranty') {
            if (element.isTaxed1) { sale.amtTaxed1 = Number(sale.amtTaxed1) + Number(sale.warranty); }
            if (element.isTaxed2) { sale.amtTaxed2 = Number(sale.amtTaxed2) + Number(sale.warranty); }
            if (element.isTaxed3) { sale.amtTaxed3 = Number(sale.amtTaxed3) + Number(sale.warranty); }
            if (element.isTaxed3) { sale.amtTaxed4 = Number(sale.amtTaxed4) + Number(sale.warranty); }
          }


         });

         if (sale.amtTaxed1 < 0) {sale.amtTaxed1 = 0; }
         if (sale.amtTaxed2 < 0) {sale.amtTaxed2 = 0; }
         if (sale.amtTaxed3 < 0) {sale.amtTaxed3 = 0; }
         if (sale.amtTaxed4 < 0) {sale.amtTaxed4 = 0; }

        }
    }

    if (sale?.saleDefaults?.maxTaxableValue2 > 0 && sale?.saleDefaults.minMaxTaxableValue2 > 0) {
      if (sale.amtTaxed2 > sale?.saleDefaults.maxTaxableValue2 ) {
        sale.amtTaxed2 = sale?.saleDefaults?.maxTaxableValue2  - sale?.saleDefaults.minMaxTaxableValue2;
      }
      if (sale.amtTaxed2 < sale?.saleDefaults.minMaxTaxableValue2 ) {
        sale.amtTaxed2 = 0;
      }
      if (sale.amtTaxed2 > sale?.saleDefaults.minMaxTaxableValue2 && sale.amtTaxed2 <  sale?.saleDefaults.maxTaxableValue  ) {
        sale.amtTaxed2 = (sale.amtTaxed2 - sale?.saleDefaults.minMaxTaxableValue2 );
      }
    }

    if (sale?.saleDefaults?.maxTaxableValue > 0  ) {
      if ( sale.saleDefaults.maxTaxableValue < sale.amtTaxed1 ) { sale.amtTaxed1 = sale.saleDefaults.maxTaxableValue; }
    }
    if (sale.taxRate1 === null) { sale.taxRate1 = 0; }
    if (sale.taxRate2 === null) { sale.taxRate2 = 0; }
    if (sale.taxRate3 === null) { sale.taxRate3 = 0; }
    if (sale.taxRate4 === null) { sale.taxRate4 = 0; }
    sale.totalTax1 = sale.amtTaxed1 * sale.taxRate1 * .01;
    sale.totalTax2 = sale.amtTaxed2 * sale.taxRate2 * .01;
    sale.totalTax3 = sale.amtTaxed3 * sale.taxRate3 * .01;
    sale.totalTax4 = sale.amtTaxed4 * sale.taxRate4 * .01;
    sale.totalTax1 = this.fnz02(sale.totalTax1);
    sale.totalTax2 = this.fnz02(sale.totalTax2);
    sale.totalTax3 = this.fnz02(sale.totalTax3);
    sale.totalTax4 = this.fnz02(sale.totalTax4);
    sale.amtTaxed1 = this.fnz02(sale.amtTaxed1);
    sale.amtTaxed2 = this.fnz02(sale.amtTaxed2);
    sale.amtTaxed3 = this.fnz02(sale.amtTaxed3);
    sale.amtTaxed4 = this.fnz02(sale.amtTaxed4);
  //  console.log(sale.saleDefaults.name);
         // Sets Up Non Amortized Amount for TEXAS DEFERRRED!!!!!!
         if (sale?.companyProfile?.isTexasDeferred)  {
          sale.nonAmortized = this.fnz02(sale.totalTax4);
        } else {
          sale.nonAmortized = this.fnz02(0);
        }
        if (sale.amortized === null || sale?.amortized === undefined) { sale.amortized = this.fnz02(0); }
        sale.amortized = this.fnz02(sale.amortized);
    sale.tax = Number(sale.totalTax1) + Number(sale.totalTax2) + Number(sale.totalTax3) + Number(sale.totalTax4);


    if (sale.tax === null || sale?.tax === undefined) { sale.tax = this.fnz02(0); }
    sale.tax = this.fnz02(sale.tax);

    sale.pickupsTotal = Number(sale.pickAmount5) + Number(sale.pickAmount4) + Number(sale.pickAmount3) + Number(sale.pickAmount2) + Number(sale.pickAmount1);
    sale.pickupsTotal = this.fnz02(sale.pickupsTotal);
    sale.amountFinanced = Number(sale.creditLife) + Number(sale.disability) + Number(sale.docFees)
    + Number(sale.miscNonTax) + Number(sale.miscTaxable) + Number(sale.predelivery) + Number(sale.registration) + Number(sale.sellingPrice) + Number(sale.smogC1)
    + Number(sale.tag) + Number(sale.tax) + Number(sale.title) + Number(sale.warranty) - Number(sale.cashDown) - Number(sale.pickupsTotal) - Number(sale.tradeAllow) + Number(sale.tradeLien);
    sale.amountFinanced = Number(this.fnz02(sale.amountFinanced)) ;

    sale.amortized = Number(sale.amountFinanced) - Number(sale.nonAmortized);
    sale.amortized = this.fnz02(sale.amortized);


    // Set UP Loan Math
    // make sure to recalc loan
        if (sale.amoLoans) {
        //  console.log(sale);
          sale.amoLoans.apr = sale?.apr;
          sale.amoLoans.isCalculateTerm = sale.isTermEntered;
          sale.amoLoans.amountFinanced = sale.amountFinanced;
          sale.amoLoans.loanStartDate  = sale.soldDate;
          sale.amoLoans.nonAmortized = sale.nonAmortized;
          sale.amoLoans.amortized = sale.amortized;
          sale.amoLoans.term = sale.term;
          sale.amoLoans.payment = sale.payment;
        }
  }
  async  formatTaxTemplate(defaultTemplate: SaleDefaultTemplate): Promise<SaleDefaultTemplate>{
    if (defaultTemplate) {
      defaultTemplate.taxRate1 = this.fnz02(defaultTemplate.taxRate1);
      defaultTemplate.taxRate2 = this.fnz04(defaultTemplate.taxRate2);
      defaultTemplate.taxRate3 = this.fnz02(defaultTemplate.taxRate3);
      defaultTemplate.taxRate4 = this.fnz02(defaultTemplate.taxRate4);
      defaultTemplate.maxTaxableValue = this.fnz04(defaultTemplate.maxTaxableValue);
      defaultTemplate.minMaxTaxableValue2 = this.fnz02(defaultTemplate.minMaxTaxableValue2);
      defaultTemplate.maxTaxableValue2 = this.fnz02(defaultTemplate.maxTaxableValue2);
      // loop fields to .00
      if (defaultTemplate?.saleDefaultTemplateFields.length > 0) {
        defaultTemplate?.saleDefaultTemplateFields.forEach(element => {
            element.defaultValue = (this.fnz02(element.defaultValue));
       });
      }
    }
    return defaultTemplate;
  }
  async  formatTaxEditScreen(defaultTemplate: SaleDefault): Promise<SaleDefault>{
    if (defaultTemplate) {
      defaultTemplate.taxRate1 = this.fnz02(defaultTemplate.taxRate1);
      defaultTemplate.taxRate2 = this.fnz04(defaultTemplate.taxRate2);
      defaultTemplate.taxRate3 = this.fnz02(defaultTemplate.taxRate3);
      defaultTemplate.taxRate4 = this.fnz02(defaultTemplate.taxRate4);
      defaultTemplate.maxTaxableValue = this.fnz04(defaultTemplate.maxTaxableValue);
      defaultTemplate.minMaxTaxableValue2 = this.fnz02(defaultTemplate.minMaxTaxableValue2);
      defaultTemplate.maxTaxableValue2 = this.fnz02(defaultTemplate.maxTaxableValue2);
      // loop fields to .00
      if (defaultTemplate?.saleTaxDefaults.length > 0) {
        defaultTemplate?.saleTaxDefaults.forEach(element => {
            element.defaultValue = (this.fnz02(element.defaultValue));
       });
      }
    }
    return defaultTemplate;
  }
}

