import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SupportTicketRecievedDto } from '../_models/DTOS/SupportDtos/SupportTicketRecievedDto';
import { TicketCountDto } from '../_models/DTOS/SupportDtos/TicketCountDto';
import { SupportRequest } from '../_models/SupportRequest';
import { SupportTicket } from '../_models/supportticket';
import { SupportType } from '../_models/SupportType';


@Injectable({
  providedIn: 'root'
})
export class SupportService {
  baseUrl = environment.apiUrl;
  supportUrl = environment.supportUrl;
  private currentSupportRequest = new BehaviorSubject<SupportRequest>(null);
  supportRequest$ = this.currentSupportRequest.asObservable();
constructor(private http: HttpClient) { }
// http://localhost:5000/api/techsupport/supportrequest

countTicketsRequests(parentGuid: any): Observable<TicketCountDto> {
 return this.http.get<TicketCountDto>(this.baseUrl + 'techsupport/getsupportcounts/' + parentGuid);
}

changeCurrentSupportRequest(t: SupportRequest) {
  this.currentSupportRequest.next(t);
}
getSupportRequest(id: number, parentGuid): Observable<SupportRequest> {
  return this.http.get<SupportRequest>(this.baseUrl + 'techsupport/supportrequestbyid/' + id + '/client' + '/' + parentGuid );
 // return this.http.get<SupportRequest>(this.supportUrl + 'techsupport/supportrequestbyid/' + id + '/client' + '/' + parentGuid );
}
getList(id: number, parentid): Observable<SupportRequest[]> {
  return this.http.get<SupportRequest[]>(this.baseUrl + 'techsupport/supportrequest/' + id + '/' + parentid);
 // return this.http.get<SupportRequest[]>(this.supportUrl + 'techsupport/supportrequest/' + id + '/' + parentid);
}
getSupportTYpes(): Observable<SupportType[]> {
  return this.http.get<SupportType[]>(this.baseUrl + 'techsupport/supporttypes');
}
postNewSupportRequest(supportRequest: SupportTicketRecievedDto): Observable<SupportRequest>{
  return this.http.post<SupportRequest>(this.baseUrl + 'techsupport/newsupportcase/client/', supportRequest);
}

postNewSupportTicketResponses(entity: SupportTicket, tennantguid: string): Observable<SupportRequest> {
  return this.http.post<SupportRequest>(this.baseUrl + 'techsupport/supportticket/' + tennantguid + '/client/', entity);
}


deleteSupportRequest(id, parentguid): Observable<SupportRequest>{
  return this.http.get<SupportRequest>(this.baseUrl + 'techsupport/deleteticket/' + id + '/' + parentguid);
}
}
