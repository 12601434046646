    // Angular
    import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
    import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

    /**
     * Sanitize HTML
     */
    @Pipe({
      name: 'safe'
    })
    export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    // commented return throws error, both with URL and with RESOURCE_URL. idk why
    // return this.sanitizer.sanitize(SecurityContext.URL, url);
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.sanitizer.sanitize(SecurityContext.URL, url));
  }

}
    //   /**
    //    * Pipe Constructor
    //    *
    //    * @param _sanitizer: DomSanitezer
    //    */
    //   // tslint:disable-next-line
    //   constructor(protected _sanitizer: DomSanitizer) {
    //   }

    //   /**
    //    * Transform
    //    *
    //    * @param value: string
    //    * @param type: string
    //    */
    //   transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    //     switch (type) {
    //       case 'html':
    //         return this._sanitizer.bypassSecurityTrustHtml(value);
    //       case 'style':
    //         return this._sanitizer.bypassSecurityTrustStyle(value);
    //       case 'script':
    //         return this._sanitizer.bypassSecurityTrustScript(value);
    //       case 'url':
    //         return this._sanitizer.bypassSecurityTrustUrl(value);
    //       case 'resourceUrl':
    //         return this._sanitizer.bypassSecurityTrustResourceUrl(value);
    //       default:
    //         return this._sanitizer.bypassSecurityTrustHtml(value);
    //     }
    //   }
    // }
