import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AddPersonModalComponent } from 'src/app/people/add-person-modal/add-person-modal.component';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { AddNewEmployeeRegisterDto } from 'src/app/_models/DTOS/usersdtos/AddNewEmployeeToCompanyDto';
import { Member } from 'src/app/_models/member';
import { Pagination } from 'src/app/_models/pagination';
import { Person } from 'src/app/_models/person';
import { PersonPhoto } from 'src/app/_models/personphoto';
import { User } from 'src/app/_models/user';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { UserParams } from 'src/app/_models/userParams';
import { AccountService } from 'src/app/_services/account.service';
import { AdminService } from 'src/app/_services/admin.service';
import { CompanySettingsService } from 'src/app/_services/advertising/company-settings.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompaniesService } from 'src/app/_services/companydataservice/companies.service';
import { MembersService } from 'src/app/_services/members.service';
import { PersonDataService } from 'src/app/_services/personinfo/person-data.service';
import { PersonService } from 'src/app/_services/personinfo/person.service';
import { PresenceService } from 'src/app/_services/presence.service';
import { AddEmployeeModalComponent } from './add-employee-modal/add-employee-modal.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { EmployeeAssigncompanyComponent } from './employee-assigncompany/employee-assigncompany.component';

@Component({
  selector: 'app-employee-management',
  templateUrl: './employee-management.component.html',
  styleUrls: ['./employee-management.component.css']
})
export class EmployeeManagementComponent implements OnInit {
  bsModalRefBuyer: any;
  bsModalRefBuyerAssign: any;
  persons: PersonLinqResultToReturnDto[];
  person1: Person;
  photos = [] as PersonPhoto[];
  members: Member[];
  pagination: Pagination;
  personToEdit: Person;
  userParams: UserParams;
  newEmployee = {} as AddNewEmployeeRegisterDto;
  user: User;
  users: Partial<User[]>;
  genderList = [{ value: 'male', display: 'Males' }, { value: 'female', display: 'Females' }];
  selectedCompany1: UserAssignedToCompany;
  selectedCompanies: UserAssignedToCompany[];
  refinedCompanieList = [] as UserAssignedToCompany[];
  allComapnies: UserAssignedToCompany[];
  companyProfile = {} as CompanyProfile;
  constructor(private memberService: MembersService,
    private authService: AuthService,
    private alertify: ToastrService,
    private adminService: AdminService,
    private router: Router,
    private modalService: BsModalService,
    private personService: PersonDataService,
    public presence: PresenceService,
    private accountSerice: AccountService,
    private companyProfileService: CompaniesService) {
      this.authService.company.subscribe((data) => {
        this.selectedCompany1 = data;
        console.log(this.selectedCompany1);
        this.userParams = this.memberService.getUserParams();
      });
  }
  getUsersWithRoles() {
    this.adminService.getUsersWithRolesByTennantId().subscribe(users => {
      this.users = users;
      console.log(users);
    });
  }
  ngOnInit() {
  //  this.userParams.parentGuid = this.selectedCompany1.parentGuid ;
  

    this.companyProfileService.getCompanyByCompanyGuid(this.selectedCompany1.parentGuid).subscribe(
      data => { this.companyProfile = data;
        this.loadMembers();

        this.getUsersWithRoles();
        this.authService.parentUserAssignedCompanies
      .subscribe(arg => { this.selectedCompanies = arg; }); }
    );

  }
  loadMembers() {
    this.members = [];
    this.userParams.parentGuid = this.companyProfile.parentGuid ;
    console.log(this.userParams);
    this.memberService.setUserParams(this.userParams);
    this.memberService.getMembers(this.userParams).subscribe((response) => {
      this.members = response.result;
      console.log(response.result);
      this.pagination = response.pagination;
    }, error => {}, () => {  console.log(this.members); });

  }

  resetFilters() {
    this.userParams = this.memberService.resetUserParams();
    this.loadMembers();
  }

  pageChanged(event: any) {
    this.userParams.pageNumber = event.page;
    this.memberService.setUserParams(this.userParams);
    this.loadMembers();
  }
  deactivateUser(e) {
    this.memberService.deactivateEmployee(e.id).subscribe(data => {
      console.log(data);
      this.getUsersWithRoles();
      this.loadMembers();
    });
  }
  deleteLogin(e) {
    this.memberService.deleteEMployeeLogin(e.id).subscribe(data => {
      this.getUsersWithRoles();
      this.loadMembers();
     this.alertify.success('Deleted Successfully. Employee login blocked.')
    });
  }
  getAssignedToCOmpany(e) {
    console.log(e);
    this.refinedCompanieList = [];
    this.companyProfileService.getUserAssignedByAppUserId(e.id).subscribe(data => {
      this.selectedCompanies = data;
      this.companyProfileService.getUserAssignedByTennantId(e.id).subscribe(response => {
        this.allComapnies = response;
        console.log(this.allComapnies);
      }, error => { this.alertify.error(error); }, () => {
        console.log(  this.allComapnies);
        this.allComapnies.forEach(element => {
         this.companyProfileService.getDoesExistInUserAssignedComapneis(e.id, element.companyProfileId).subscribe(arg => {
          if (arg === true) {
            element.isAssigned = true;
            element.isDefaultCompany = element.isDefaultCompany;
            this.refinedCompanieList.push(element);
          } else {
            element.isAssigned = false;
            element.isDefaultCompany = element.isDefaultCompany;
            this.refinedCompanieList.push(element); }
         });
          // method here to validate is it in selectedcompanies for checkmark
        });
        this.refinedCompanieList.forEach(element => {
        this.companyProfileService.getIsDefaultCompany(e.id).subscribe(arg2 => {
          if (arg2 === true) {
            element.isDefaultCompany = true;
            this.refinedCompanieList.push(element);
          } else {
            element.isDefaultCompany = false;
            this.refinedCompanieList.push(element); }
         });
        });
   //   });
      });
      });
      console.log(this.refinedCompanieList);
    //  this.refinedCompanieList =  JSON.parse(this.refinedCompanieList.toString());

      this.assignToCompany(e);
  }
  assignToCompany(e) {
    console.log(e);
    const initialState = {
      user: e,
      selectedCompanies: this.selectedCompanies,
      allCompanies: this.refinedCompanieList
    };
      this.bsModalRefBuyer = this.modalService.show(EmployeeAssigncompanyComponent, {initialState});
      this.bsModalRefBuyer.content.exportSelectedCompanies.subscribe(data1 => {
        this.selectedCompanies = data1;
        console.log(this.selectedCompanies);
        this.companyProfileService.assignUsersToUserASsignedtoCompany(data1, e.id).subscribe(result => {
          this.alertify.success('Reached save function');
        });
      });
    }

  editPerson(e) {
    this.personService.getPerson(this.selectedCompany1.parentGuid, e.id).subscribe(data =>    {
      this.person1 = data;
      this.photos = data.personPhotos;
    }, error => {this.alertify.error(error); }, () => {
      this.alertify.success('Success');
      const initialState = {
        person: this.person1,
        selectedCompany: this.selectedCompany1,
        photos: this.photos
      };
      console.log(this.person1);
      this.bsModalRefBuyerAssign = this.modalService.show(EditEmployeeComponent, {initialState, class: 'modal-lg'});
      this.bsModalRefBuyerAssign.content.userWebsiteToReturnDto.subscribe(data => {
        console.log(data);
        this.photos = data.personPhotos;
      });
      this.bsModalRefBuyerAssign.content.update.subscribe((values) => {
        this.personToEdit = values;
        this.personToEdit.id = e.id;
        this.memberService.editEmployee(this.selectedCompany1.parentGuid, this.personToEdit).subscribe(data => {
          this.person1 = data.person;
          this.getUsersWithRoles();
          this.loadMembers();
        });
        this.alertify.success('Customer Retrived');
      }, error => {this.alertify.error(error); }, () => {
      });
    });
}

addEmployee() {
  console.log();
  const initialState = {
    user: this.user,
  };
    this.bsModalRefBuyer = this.modalService.show(EditEmployeeComponent, {
      initialState
    });

    this.bsModalRefBuyer.content.outputEmployee.subscribe(arg => {
      this.newEmployee.firstName = arg.firstName;
      this.newEmployee.lastName = arg.lastName;
      this.newEmployee.parentGuid = this.selectedCompany1.parentGuid;
      this.newEmployee.userAssignedToCompanyId = this.selectedCompany1.id;
      this.newEmployee.apiGuid = this.selectedCompany1.apiGuid;
      // this.newEmployee.companyProfileId = ThisReceiver.selectedCompany1.companyProfileId;
      this.newEmployee.tennantGuid = this.selectedCompany1.tennantGuid;
      this.accountSerice.registerNewEmployee(this.newEmployee).subscribe(data => {
         this.alertify.success('Employee successfully added and ready for edit.');
         this.loadMembers();
      });
    });
}

}
