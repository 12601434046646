import { Component, Input, OnInit } from '@angular/core';
import { PdfFieldNameType } from 'src/app/_models/PdfFieldNameType';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';

@Component({
  selector: 'app-pdffield-person',
  templateUrl: './pdffield-person.component.html',
  styleUrls: ['./pdffield-person.component.scss']
})
export class PdffieldPersonComponent implements OnInit {
@Input() pdfFieldNameTypes: PdfFieldNameType[];
@Input() personType: any;
@Input() person: PdfFieldNameType;

  constructor(private pdfService: PdfService) { }

  ngOnInit() {
    // console.log(this.pdfFieldNameTypes);
    // console.log(this.person);
  }

  selectedpdfFieldName(pdfFieldName) {
    console.log(pdfFieldName);
    this.pdfService.changePdfFIeldName(pdfFieldName);
  }

}
