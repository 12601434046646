import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { AmoLoan } from 'src/app/_models/amoloans';
import { AmoPayment } from 'src/app/_models/amopayments';
import { AmoLinqPayments } from 'src/app/_models/DTOS/amolinqpayments';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { TakePaymentDto } from 'src/app/_models/DTOS/takepaymentdto';
import { PaymentType } from 'src/app/_models/PaymentType';
import { Person } from 'src/app/_models/person';
import { Sale } from 'src/app/_models/sale';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AmoInstallmentService } from 'src/app/_services/amoinstallmentservices/amoinstallments.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { LoanService } from 'src/app/_services/loanservieces/loan.service';
import { AddPaymentModalComponent } from '../../add-payment-modal/add-payment-modal.component';
import { EditpaymentModalComponent } from '../../editpayment-modal/editpayment-modal.component';
import { PaymentReceiptDto } from '../../loan-list/printreceiptdto';
import { LoanAddpaymentComponent } from '../loan-addpayment/loan-addpayment.component';
import { LoanEditpaymentComponent } from '../loan-editpayment/loan-editpayment.component';



@Component({
  selector: 'app-loan-payments',
  templateUrl: './loan-payments.component.html',
  styleUrls: ['./loan-payments.component.css']
})
export class LoanPaymentsComponent implements OnInit {
  amoPayments = [] as AmoPayment[];
  amoPaymentsDueTillToday = [] as AmoPayment[];
  countPaymentsDueTillToday = 0 as number;
  @Output() updatePersons = new EventEmitter();
 @Input() sale = {} as Sale;
 @Input() person = {} as PersonLinqResultToReturnDto;
  paymentTypes = [] as PaymentType[];
  paymentType = {} as PaymentType;
 loans: AmoLinqPayments[];
ifIsDetails: boolean;
ifIscustomerInfo: boolean;
ifIsAmortization: boolean;
loan = {} as AmoLoan;
ifispickInfo: boolean;
amortization = [] as AmoAmortization[];
dueTodayDate = {} as AmoAmortization;
dueTodayasOfTodayDate = new Date();
selectedCompany1: UserAssignedToCompany;
invoiceId: number;
loanId: number;
invoiceAndAmoPayments: any;
bsModalRefAddPayment2: any;
numZero = 0;
paymentRecieptDto = {} as PaymentReceiptDto;
isLoaded = false;
todayDate =  Date.now;
todayPayoff: number;
amoPayment = { } as AmoPayment;
isEditPayment = false;
takePaymentDto: TakePaymentDto =  {
 datePaid: moment(Date.now()).toDate(),
  invoiceId: null,
  todayLoanPaid: 1,
  todayDeferredDown: 2,
  todayLateFee: 3,
  todayMisc: 4,
  sumPaidToday: 5,
  parentId: ' '

};
  constructor( private route: ActivatedRoute,
    private confirmService: ConfirmService,
    private loanService: AmoInstallmentService,
    private takePaymentLoan: LoanService,
    private router: Router,
    private alertify: ToastrService,
    private authService: AuthService,
    private modalService2: BsModalService) { const now = moment(); }

  ngOnInit() {
    this.ifIsDetails = true;
    this.ifispickInfo = false;
    this.ifIsAmortization = false;
    this.ifIscustomerInfo = false;
    this.authService.currentCompany.subscribe(company => {
      this.selectedCompany1 = company;
      this.getPaymentTypesForPayments();
    });

    this.loanId = this.person?.amoLoans[0].id;
    this.loan = this.person.amoLoans[0];
    this.amoPayments = this.person.amoLoans[0].amoPayments;
    // this.getAmortization();
  if (this.loanId > 0) {
    this.takePaymentLoan.getSaleByLoanId(this.loanId).subscribe((data: Sale) => {
      console.log(data);
      this.sale = data;
      this.invoiceId = data.id;
      this.dueTodayLoad();
      this.getCashPaymentType();
    });
  }
  }
  getCashPaymentType() {
    this.takePaymentLoan.getCashPaymentType(this.selectedCompany1.parentGuid).subscribe(data => {
      this.paymentType = data;
    });
  }
  getPaymentTypesForPayments() {
    this.takePaymentLoan.getPaymentTYpes(this.selectedCompany1.parentGuid).subscribe(data => {
      this.paymentTypes = data;
    });
  }
  isDetails(e) {
    if (e === 'loanInfo') {
      this.ifIsDetails = true;
      this.ifispickInfo = false;
      this.ifIscustomerInfo = false;
      this.ifIsAmortization = false;
    }
    if (e === 'pickInfo') {
      this.ifIsDetails = false;
      this.ifispickInfo = true;
      this.ifIscustomerInfo = false;
      this.ifIsAmortization = false;
    }
    if (e === 'customerInfo') {
      this.ifIsDetails = false;
      this.ifispickInfo = false;
      this.ifIscustomerInfo = true;
      this.ifIsAmortization = false;
    }
    if (e === 'amotization') {
      this.ifIsAmortization = true;
      this.ifIsDetails = false;
      this.ifispickInfo = false;
      this.ifIscustomerInfo = false;
    }
  }
  getAmortization() {
    this.loanService.getAmortization(this.sale.amoLoans.id).subscribe(data => {
      this.amortization = data;
    }, error => {
      this.alertify.error(error);
    }, () => { this.alertify.success('Amortization Recieved'); });
  }
  returnToLoans() {
    this.router.navigate(['/loan/list/'  + this.selectedCompany1.parentGuid]);
  }
  takePayment()  {
    const initialState = {
      sale: this.sale,
      takePaymentDto: this.takePaymentDto,
      paymentTypes: this.paymentTypes,
      paymentType: this.paymentType,
      termDue: this.countPaymentsDueTillToday,
      termPaid: this.loan?.termPaid
    };
    this.bsModalRefAddPayment2 = this.modalService2.show(LoanAddpaymentComponent, {initialState, class: 'modal-md'});
      this.bsModalRefAddPayment2.content.paymentEnetered.subscribe((values: AmoPayment) => {
        console.log(values);
        this.takePaymentDto.paymentTypesId = values.paymentTypesId;
        this.takePaymentDto.receiptNumber = values.recieptNumber;
        this.takePaymentDto.sumPaidToday = values.sumPaidToday;
        this.takePaymentDto.todayDeferredDown = values.todayDeferredDown;
        this.takePaymentDto.todayLateFee = values.todayLateFee;
        this.takePaymentDto.todayLoanPaid = values.todayLoanPaid;
        this.takePaymentDto.todayMisc = values.todayMisc;
        this.takePaymentDto.parentId = this.selectedCompany1.parentGuid;
        this.takePaymentDto.invoiceId = this.sale.id;
        this.takePaymentDto.amoLoanId = this.loan.id;
        this.takePaymentDto.datePaid = values.datePaid;
        this.takePaymentLoan.takePayment( this.takePaymentDto).subscribe(data => {
          this.sale = data.invoice;
          this.amoPayments = data.amoPayments;
          this.paymentRecieptDto = data.recieptFields;
          this.loan = data.loan;
          this.person = data.person;
          this.authService.setIsCurrentPerson(data.person);
          this.updatePersons.emit(true);
          this.alertify.success('Payment Applied Successullfy.');
        }, error => {
          this.alertify.error(error); console.log(error);
        }, () => { this.dueTodayLoad(); });
  });
  }
  async dueTodayLoad() {

    this.takePaymentDto.datePaid = moment(Date.now()).toDate();
    this.takePaymentDto.parentId = this.selectedCompany1.parentGuid;
    this.takePaymentDto.invoiceId = this.invoiceId;
    this.takePaymentDto.sumPaidToday = parseFloat(this.numZero.toFixed(2));
    this.takePaymentDto.todayNonAmortizedPaid = parseFloat(this.numZero.toFixed(2));
    this.takePaymentDto.todayDeferredDown = parseFloat(this.numZero.toFixed(2));
    this.takePaymentDto.todayLateFee = parseFloat(this.numZero.toFixed(2));
    this.takePaymentDto.todayMisc = parseFloat(this.numZero.toFixed(2));
    this.takePaymentDto.todayLoanPaid = parseFloat(this.numZero.toFixed(2));

    this.takePaymentLoan.getPaymentDueToday(this.takePaymentDto).subscribe(data => {
    //  console.log(moment(Date.now()).toDate());
    console.log(data);
    this.countPaymentsDueTillToday = data.countPaymentsDueTillToday;
    this.loan.currentDue = data.sumDueTOday;
    this.dueTodayasOfTodayDate = data?.dueTodayDate?.datePaid;
    this.amoPayments = data?.amoPayments;
    console.log( data?.dueTodayDate?.datePaid);
    this.isLoaded = true;
    if  (data.sumDueTOday < 0) { this.takePaymentDto.sumPaidToday = 0; } else {
      this.takePaymentDto.sumPaidToday = parseFloat(data.sumDueTOday.toFixed(2));
    }

    if  (data.nonAmortizedPaymentDue < 0) {
      this.takePaymentDto.todayNonAmortizedPaid = 0; }
      else {
      this.takePaymentDto.todayNonAmortizedPaid = parseFloat(data.nonAmortizedPaymentDue.toFixed(2));
    }
    if (data.deferredBalance < 0) {
      this.takePaymentDto.todayDeferredDown = 0;
    } else {
      this.takePaymentDto.todayDeferredDown = parseFloat(data.deferredBalance.toFixed(2));
    }
      this.takePaymentDto.todayLateFee = parseFloat(this.numZero.toFixed(2));
      this.takePaymentDto.todayMisc = parseFloat(this.numZero.toFixed(2));
      this.takePaymentDto.todayLoanPaid = data.dueToday;
      this.takePaymentDto.receiptNumber = data.receiptNumber;
if (this.amoPayments == null || this.amoPayments === undefined) {
  console.log(this.sale.amoLoan);
  if (this.sale.amoLoan) {
    this.amoPayments = this.sale.amoLoans.amoPayments;
  }
}
    });
  }
 setActiveRow(e: AmoPayment) {
   this.amoPayment = e;
  // tslint:disable-next-line:prefer-for-of
  for (let i = 0; i < this.amoPayments.length; i++) {
    if (e === this.amoPayments[i]) { e.selected = true; }
    else {this.amoPayments[i].selected = false; }
  }
  e.selected = true;
  this.isEditPayment = true;
 }
 editPaymentBtnClick() { this.editPayment(this.amoPayment); }
  editPayment(item: AmoPayment)  {
    //  item.paymentTypesId = item.paymentTypes.id;
    //  console.log(item.paymentTypes);
    this.setActiveRow(item);
    const initialState = {
      sale: this.sale,
      amoPayment: item,
      paymentTypes: this.paymentTypes,
      loan: this.loan
    };
    this.bsModalRefAddPayment2 = this.modalService2.show(LoanEditpaymentComponent, {initialState, class: 'myModal2'});
    this.bsModalRefAddPayment2.content.paymentEnetered.subscribe((values: AmoPayment) => {
        console.log(values);
      this.takePaymentDto.paymentTypesId =  this.amoPayment.paymentTypesId; // values.paymentTypes.id;
      this.takePaymentDto.receiptNumber = values.recieptNumber;
      this.takePaymentDto.sumPaidToday = values.sumPaidToday;
      this.takePaymentDto.todayDeferredDown = values.todayDeferredDown;
      this.takePaymentDto.todayLateFee = values.todayLateFee;
      this.takePaymentDto.todayLoanPaid = values.todayLoanPaid;
      this.takePaymentDto.todayMisc = values.todayMisc;
      this.takePaymentDto.parentId = this.selectedCompany1.parentGuid;
      this.takePaymentDto.invoiceId = this.sale.id;
      this.takePaymentDto.amoLoanId = this.loan.id;
      this.takePaymentDto.datePaid = values.datePaid;
        this.takePaymentLoan.editPayment(this.takePaymentDto, item.id).subscribe(data => {
       //   this.sale = data.sale;
       this.paymentRecieptDto = data.recieptFields;
       this.person = data.person;
       this.loan = data.loan;
       this.sale = data.invoice;
          this.amoPayments = data.amoPayments;
          this.authService.setIsCurrentPerson(data.person);
          this.alertify.success('Payment Applied Successullfy.');
        }, error => {
        //  this.alertify.error(error);
        }, () => { this.dueTodayLoad(); console.log(this.paymentRecieptDto); });
  });
  }
  printReceipt() {
    this.takePaymentLoan.printReceipt(this.loan.id, this.amoPayment.id).subscribe(data => {
      this.paymentRecieptDto = data;
      console.log(data);
       }, error => {
         this.alertify.error(error);
       }, () => {
         console.log(this.paymentRecieptDto);
        });
 }
  deletePayment(e) {
    console.log(e);
    this.confirmService.confirm('Confirm delete payment', 'Are you sure?').subscribe(result => {
      if (result) {
    this.loanService.deletePayment(this.amoPayment.id).subscribe(data => {
      this.person = data.person;
      this.loan = data.loan;
      this.sale = data.invoice;
         this.amoPayments = data.amoPayments;
         this.authService.setIsCurrentPerson(data.person);
         this.alertify.success('Payment Deleted Successullfy.');
    }, error => { this.alertify.error(); }, () => {
      this.dueTodayLoad();
      this.alertify.success('Payment Updated');
    });
  }
});
}
}

