import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FieldAlreadyExistsError,
  grayscale,
  PDFDocument,
  PDFTextField,
  TextAlignment,
} from 'pdf-lib';
import { PdfRenderService } from 'src/app/_services/PdfSErvices/pdf-render.service';
import { CdkDrag, CdkDragEnd } from '@angular/cdk/drag-drop';
import { AuthService } from 'src/app/_services/auth.service';
import { NavbarService } from 'src/app/_services/navbarservices/Navbar.service';
// import { ActivatedRoute } from '@angular/router';
import { PdfForms } from 'src/app/_models/PdfForms';
import { PdfFieldServer } from 'src/app/_models/PdfFieldServer';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { ToastrService } from 'ngx-toastr';
import { PdfFieldLocationClass } from 'src/app/_classes/pdffielddatats/pdffield-location';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Sale } from 'src/app/_models/sale';
import { PdfFieldNameType } from 'src/app/_models/PdfFieldNameType';
import { PdfFIeldName } from 'src/app/_models/PdfFiledName';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { Observable, of } from 'rxjs';
import { NgForm } from '@angular/forms';
import { PDFLabel } from 'src/app/_classes/pdfCreate/PDFLabel';
import { evaluate } from 'mathjs/lib/browser/math.js';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PdfnewuploadnameModalComponent } from 'src/app/modals/pdfnewuploadname-modal/pdfnewuploadname-modal.component';
import { CalculatorPdfComponent } from '../calculator-pdf/calculator-pdf.component';
import { Field } from 'src/app/_models/Field';
// import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { PdfSetEmbedPdfFieldLocationsResultDto } from 'src/app/_models/DTOS/pdfdtos/PdfSetEmbedPdfFieldLocationsResultDto';
import { ActivatedRoute } from '@angular/router';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-Pdf-View-Sized',
  templateUrl: './Pdf-View-Sized.component.html',
  styleUrls: ['./Pdf-View-Sized.component.scss'],
})
export class PdfViewSizedComponent implements OnInit {
  @Input() formUrl: any;
  @Input() pdfForm: PdfForms;
  @ViewChild('dealInfoForm') dealInfoForm: NgForm;
  pdfSrc: any;
  isSelected: any;
  isDivSelected = false;
  draggables: CdkDrag<any>[] = [];
  currentX: number;
  currentY: number;
  @Input() sale: Sale;
  fields: Field[] = [];
  newDragable: CdkDrag;
  pos = {} as any;
  serverPdfFields = [] as PdfFieldServer[];
  fieldsieldsCache = [] as Field[];
  selectedCompany1: UserAssignedToCompany;
  DocumentX: number;
  DocumentY: number;
  x: number;
  y: number;
  ctrlKey: boolean;
  top: number;
  formPdfBytes: any;
  DocumentTop: number;
  isBuyer = false;
  public isHidden = true;
  xPosTabMenu: number;
  yPosTabMenu: number;
  @Input() pdfFieldNameTypes: PdfFieldNameType[];
  pdfFieldNameType = {} as PdfFieldNameType;
  pdfFieldNames = [] as PdfFIeldName[];
  pdfFieldName = {} as PdfFIeldName;
  ctrlSelected = [] as Field[];
  // pdfFieldNameTypes = [] as PdfFieldNameType[];
  field = {} as Field;
  fieldIndex = 0 as number;
  isLeft = false;
  isRight = false;
  isCenter = false;
  varalignLRF = 0 as number;
  width = 0 as number;
  height = 0 as number;
  i: number;
  mouse: any;
  expr: any;
  delims: any;
  tokens: string[];
  sSellingPrice = 1000;
  sTag = 200;
  sTaxRate = 6;
  isCreateLabel = false;
  bsModalRef: any;
  isCalculator = false;
  constructor(
    public navService: NavbarService,
    private pdfFieldLocationClass: PdfFieldLocationClass,
    private route: ActivatedRoute,
    private pdfService: PdfService,
    private alertify: ToastrService,
    private confirmService: ConfirmService,
    private authSerive: AuthService,
    private pDFLabel: PDFLabel,
    private modalService: BsModalService
  ) {}
  // used in adding Zindex
  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    this.mouse = {
      x: event.clientX,
      y: event.clientY,
    };
  }
  async ngOnInit() {
    await this.init();
  }

  async init() {
    this.pdfService.isPdfCalculatorEmitted.subscribe((data) => {
      this.isCalculator = data;
    });
    this.pdfService.getIsWithData().subscribe((data) => {
      if (data) {
      }
    });
    this.subscribeToPdfFieldName();
    this.authSerive.company.subscribe((data) => {
      this.selectedCompany1 = data;
    });
    if (this.pdfForm) {
      this.formUrl = this.pdfForm.pdfFormUrl;
      this.pdfService.changePdfForm(this.pdfForm);
      this.fillForm();
      await this.getServerPdfFields(this.formUrl);
    }
    this.addClickEvent();
  }
  onError(error: any) {
    // do anything
  }
  /**
   * attempt to push all to top to not over flow on top of menus
   */
  assignZIndex() {
    this.i = 1;
    const test = Array.from(
      document.getElementsByClassName(
        'textField'
      ) as HTMLCollectionOf<HTMLElement>
    );
    test.forEach((element) => {
      this.i = this.i + 1;
      //  element.style.zIndex = (test.length + 8000 + this.i).toString();
    });
  }
  /**
   * when user selects Data Field and
   * then selects a data field re: firstName, lastName it changes field name on form
   * starts at ngOnIt by subscribing to the service.
   */
  subscribeToPdfFieldName() {
    this.pdfService.getCurrentPdfFieldName().subscribe((data) => {
      if (!this.isCalculator && data) {
        this.pdfFieldName = data;
        if (this.field.id >= 0) {
          this.fieldIndex = this.fields.findIndex(
            (obj) => obj.guid === this.field.guid
          );
          this.fields[this.fieldIndex].name = data?.value + this.field.id;
          this.fields[this.fieldIndex].width = data?.width;
          this.fields[this.fieldIndex].align = data?.align;
          this.fields[this.fieldIndex].calculation = data?.calculation;
          this.fields[this.fieldIndex].isCalculated = data?.isCalculated;
          this.fields[this.fieldIndex].parsedBy = data?.parseBy;
        }
      }
    });
  }
  calculator() {
    this.isCalculator = !this.isCalculator;
  }
  /**
   * returns right click event cordinates
   * @param event click event
   * @param e field?
   */
  rightClick(event, e: Field) {
    event.stopPropagation();
    this.xPosTabMenu = e.position.x; // event.clientX / .75;
    this.yPosTabMenu = e.position.y; // (event.clientY / 0.75 - (1056 - 22)) * -1;
    this.isHidden = false;
    console.log(e.position.x);
    this.isCalculator = !this.isCalculator;
  }
  onSelect(e) {
    this.isCalculator = false; // any other tab
    if (e === 'calculator') {
      this.isCalculator = true;
      this.pdfService.changeIsCalculator(true);
    }
    if (e === 'pdf') {
      this.isCalculator = true;
      this.pdfService.changeIsCalculator(false);
    }
  }
 /**  Math JS SAMPLE OF HOW Calculated fields work. */
  parseCalculation() {
    const sellingPrice = 0;
    // this.expr = evaluate('(1 + 2) * 3  / 9' );
    const expr2 = `((${this.sSellingPrice} + ${this.sTag}) * (${this.sSellingPrice} + 1))  / ${this.sSellingPrice}`;
    console.log(expr2);
    this.expr = evaluate(expr2);
    console.log(this.expr);
  }

  /**  adds click event to pdfform viewer for xy cords on clicks */
  addClickEvent() {
    document.getElementById('pdfViewer').addEventListener('click', (event) => {
      this.x = event.offsetX;
      if (this.x < 0) {
        this.x = 0;
      }
      this.y = event.offsetY;
    });
  }

  /** deletes all fields */
  async delteFIelds() {
    this.confirmService
      .confirm('Confirm delete message', 'Are you sure?')
      .subscribe((result) => {
        if (result) {
          this.fieldsieldsCache = this.fields;
          // this.pdfService.currentpdfForm.subscribe(data => { this.pdfForm = data; })
          this.fields = [];
          this.draggables = [];
          this.serverPdfFields = [];
          this.pdfFieldLocationClass
            .removePdfFieldsFromPdf(this.formUrl, this.pdfForm)
            .then((res) => {
              this.pdfService.currentpdfForm.subscribe((data) => {
                this.pdfForm = data;
                this.pdfForm.pdfFormUrl = data.pdfFormUrl;
                this.formUrl = data.pdfFormUrl;
                this.fillForm();
              });
            });
        }
      });
    await this.getServerPdfFields(this.formUrl);
  }

  /**
   *  double click of form
   * adds a field to that position on the form
   * @param e '' is regardless future place holder
   */
  isDblClick(e) {
    if (!this.isCreateLabel) {
      e = '';
      e = e + this.fields.length;
      e = e.toString() + 'New';
      this.addTextFieldClick(e);
    } else {
      const field = new Field();
      field.position = {
        x: this.x,
        y: this.y,
      };
      //  this.pDFLabel.createFieldLabel(this.pdfFor)
    }
  }

  createLabel() {
    this.isCreateLabel = !this.isCreateLabel;
  }

  /**
   * Translates form PDFField Postions to Html Form Positions and adds them to view
   * @param formUrl as stated
   */
  async getServerPdfFields(formUrl) {
    this.fields = [];
    this.draggables = [];
    this.serverPdfFields = [];
    // fields on pdf store certain things x y align however we need more.
    // we need to add namfriendly, calculation,.... to the div or to print and populate
    console.log('getfield locations');
    this.pdfFieldLocationClass.getFieldLocations(formUrl).then(
      (data) => {
        this.serverPdfFields = data;
        console.log(data);
        if (data.length > 0) {
          data.forEach((element) => {
            const nameFirstThree = element.name.substring(0, 3); // 'cst'
            // now get all custom PdfFieldName
            /// here we are going to add what the pdf does not store....
            if (nameFirstThree !== 'cst') {
              this.onTextFieldAdded(element);
            } else {
              const nameNoDigits = element.name.replace(/[0-9]/g, '');
              // first get all of fieldNameTypes from server that are type cst re custom;
              this.pdfFieldNameType = this.pdfFieldNameTypes.find(
                (x) => x.parseBy === 'cst'
              );
              this.pdfFieldName = this.pdfFieldNameType.pdfFieldNames.find(
                (x) => x.value === nameNoDigits
              );
              console.log(element);
              if (element.calculation !== undefined) {
                element.calculation = this.pdfFieldName.calculation;
                element.nameFriendly = this.pdfFieldName.nameFriendly;
              }
              this.onTextFieldAdded(element);
              console.log(element);
            }
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onRemoveButtonClick() {
    const positions: any[] = [];
    this.fields.find((f) => f.guid === this.isSelected.guid);
    this.fields = this.fields.filter((x) => x.guid !== this.isSelected.guid);
    this.isSelected = null;
  }

  onRemoveTextField(guid: string) {
    const positions: any[] = [];
    this.fields.find((f) => f.guid === guid);
    this.fields = this.fields.filter((x) => x.guid !== guid);
    this.isSelected = null;
  }

  setPdfToVIew(bytes) {
    this.pdfSrc = bytes;
  }

  textLayerRendered(e) {}

  pageRendered(e) {}
  /**
   * creating function to update view of pdf on reportsmanager change form click
   * add observable or event emitter
   */
  async changeOnReportClick(pdf: PdfForms) {
    this.pdfService.pdfForm.subscribe((data1) => {
      if (data1) {
        this.pdfForm = data1;
        this.formUrl = data1.pdfFormUrl;
      }
    });
    this.formUrl = pdf.pdfFormUrl;
    this.formPdfBytes = await fetch(this.formUrl).then((res) =>
      res.arrayBuffer()
    );
    // const pdfDoc =  await  PDFDocument.load(formPdfBytes, { ignoreEncryption: true });
    const pdfDoc = await PDFDocument.load(this.formPdfBytes);
    const pdfBytes64 = await pdfDoc.saveAsBase64();
    const pdfBytes = await pdfDoc.save();
    await this.init();
    this.setPdfToVIew(pdfBytes);
  }
  /**
   * requires this.pdfUrl to be set
   *  gets pdf from url and loads the Uint8Array to view.... load data here.
   */
  async fillForm() {
    this.formPdfBytes = await fetch(this.formUrl).then((res) =>
      res.arrayBuffer()
    );
    // const pdfDoc =  await  PDFDocument.load(formPdfBytes, { ignoreEncryption: true });
    const pdfDoc = await PDFDocument.load(this.formPdfBytes);
    const pdfBytes64 = await pdfDoc.saveAsBase64();
    const pdfBytes = await pdfDoc.save();
    this.pdfService.changeBase64Array(pdfBytes64);
    this.pdfService.changeUint8ByteArray(pdfBytes);
    this.setPdfToVIew(pdfBytes);
  }

  dragMoveListener(e) {}
  /**
   * CtrlClick and normal Click to select field condition
   * @param e field
   * @param event keyboard event
   */
  selectedField(e: Field, event: KeyboardEvent) {
    this.field = e;

    if (event.ctrlKey) {
      this.isSelected = null;
      this.controlClickAddFields(e).subscribe((data) => {});
    } else {
      this.isSelected = e;
      this.field = e;
      //  this.alignLRC();
    }
  }
  /** adds field to ctroSelected array */
  controlClickAddFields(e: Field): Observable<Field[]> {
    this.parseCalculation();
    e.selected = !e.selected;
    if (!e.selected) {
      this.ctrlSelected = this.ctrlSelected.filter(
        (item) => item.name !== e.name
      );
    } else {
      this.ctrlSelected.push(e);
    }
    return of(this.ctrlSelected);
  }
  alignItems(e) {
    const x = this.ctrlSelected[0]?.position.x; // left
    const y = this.ctrlSelected[0]?.position.y; // bottom
    this.fields.forEach((element) => {
      if (element.selected) {
        if (e === 'lefts') {
          const field = new Field();
          field.name = element.name;
          field.index = this.fields.length;
          field.align = element.align;
          field.width = element.width;
          field.height = element.height;
          field.id = this.fields.length;
          field.selected = false;
          field.guid = this.newGuid();
          field.zindex = 9000 + this.fields.length;
          if (x > 0 && y > 0) {
            field.position = {
              x,
              y: element.position.y,
            };
          }
          this.pushField(field);
          // removed old field
          this.field = this.fields.find((obj) => obj.id === element.id);
          this.fields.find((f) => f.guid === element.guid);
          this.fields = this.fields.filter((x1) => x1.guid !== element.guid);
        }
        if (e === 'rights') {
        }
        if (e === 'bottoms') {
          const field = new Field();
          field.name = element.name;
          field.index = this.fields.length;
          field.id = this.fields.length;
          field.align = element.align;
          field.height = element.height;
          field.width = element.width;
          field.selected = false;
          field.guid = this.newGuid();
          field.zindex = 9000 + this.fields.length;
          if (x > 0 && y > 0) {
            field.position = {
              x: element.position.x,
              y: y,
            };
          }
          this.pushField(field);
          // removed old field
          this.field = this.fields.find((obj) => obj.id === element.id);
          this.fields.find((f) => f.guid === element.guid);
          this.fields = this.fields.filter((x1) => x1.guid !== element.guid);
        }
      }
    });
    this.ctrlSelected = [];
  }

  pushField(e: Field) {
    this.fields.push(e);
  }
  getDragEndPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }
  onDragEnded(event: CdkDragEnd, e) {
    if (!this.draggables.find((f) => f === event.source)) {
      this.draggables.push(event.source);
      this.save('true');
    }
  }

  newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      // tslint:disable-next-line:only-arrow-functions
      function(c) {
        // tslint:disable-next-line:one-variable-per-declaration
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  addTextFieldClick(name) {
    const field = new Field();
    field.name = name;
    field.index = this.fields.length;
    field.id = this.fields.length;
    field.selected = false;
    field.guid = this.newGuid();
    field.zindex = 9000 + this.fields.length;
    if (this.x > 0 && this.y > 0) {
      field.position = {
        x: this.x,
        y: this.y,
      };
    } else {
      field.position = {
        x: 0,
        y: 100,
      };
    }
    this.fields.push(field);
    this.assignZIndex();
  }
  /**
   * Send fields to populate visible view of array fields
   * to create
   * @param e send a PdfFieldServer re a conversion of PDFTextField to PdfFieldServer model
   */
  onTextFieldAdded(e?: PdfFieldServer) {
    let field = new Field();
    if (e !== undefined) {
      field = new Field();
      field.name = e.name;
      field.align = e.align;
      field.height = e.height;
      field.selected = false;
      field.index = this.fields.length;
      field.id = this.fields.length;
      field.calculation = e.calculation;
      field.nameFriendly = e.nameFriendly;
      field.isCalculated = e.isCalculated;
      field.parsedBy = e.parsedBy;
      if (e.width) {
        field.width = e.width;
      } else {
        e.width = 50;
      }
      // field.width = e.width;
      field.zindex = 9000 + this.fields.length;
      field.guid = this.newGuid();
      field.position = {
        x: e.x,
        y: e.y,
      };
    } else {
      field = new Field();
      field.name = 'New Field' + this.fields.length;
      field.index = this.fields.length;
      field.selected = false;
      field.width = 35;
      field.zindex = 9000 + this.fields.length;
      field.guid = this.newGuid();
      field.position = {
        x: 0,
        y: 100,
      };
    }
    this.fields.push(field);
    this.assignZIndex();
  }

  save(isDrag) {
    //   this.getServerPdfFields(this.formUrl);
    const positions: any[] = [];
    this.draggables.forEach((drag) => {
      const pos = {
        //  id: drag.element.nativeElement.getAttribute('id'),
        guid: drag.element.nativeElement.getAttribute('data-guid'),
        pos: drag.getFreeDragPosition(),
      };
      const n = this.fields.includes(
        this.fields.find((el) => el.guid === pos.guid)
      );
      if (n) {
        positions.push(pos);
      }
    });
    positions.forEach((p, i) => {
      const newPos = { x: p.pos.x, y: p.pos.y };
      p.id = positions.length;
      this.currentX = newPos.x * 0.75;
      this.currentY = (newPos.y - 1056 + 22) * 0.75 * -1;
      if (p.guid) {
        this.fields.find((f) => f.guid === p.guid).position = newPos;
      }
    });
    if (isDrag === 'false') {
      this.pdfFieldLocationClass
        .setEmbedPdfFieldLocations(this.fields, this.pdfForm)
        .then((data) => {
          console.log(this.fields);
          // this form is updated in save setEmbededPDF
          this.pdfService.pdfForm.subscribe((data1) => {
            this.pdfForm = data1;
            this.alertify.success('Success');
          },  err => {}, () => { this.alertify.success('Success'); });
        })
        .catch((error) => {
          this.alertify.error(error);
          console.log(error);
        });
    }
  }
  private handleError(error: any): Promise<any> {
    let toast;
    toast = {
      type: 'info',
      title: 'Data Zone',
      body: 'Data Zone Failure!',
      showCloseButton: true,
    };
    this.alertify.error(toast);
    return Promise.reject(error.message || error);
  }
  onFieldHover(event: any) {}
  /** align left right center */
  alignLRC(alignment?: any) {
    this.isLeft = false;
    this.isRight = false;
    this.isCenter = false;
    if (alignment === 'left' || this.field.align === 0) {
      this.field.align = 0;
      this.isLeft = true;
    }
    if (alignment === 'center' || this.field.align === 1) {
      this.field.align = 1;
      this.isCenter = true;
    }
    if (alignment === 'right' || this.field.align === 2) {
      this.field.align = 2;
      this.isRight = true;
    }
  }

  alignLRCCtrlClick(alignment?: any) {
    if (alignment === 'left') {
      this.varalignLRF = 0;
      this.isLeft = true;
    }
    if (alignment === 'center') {
      this.varalignLRF = 1;
      this.isCenter = true;
    }
    if (alignment === 'right') {
      this.varalignLRF = 2;
      this.isRight = true;
    }
    this.fields.forEach((element) => {
      this.field = element;
      if (element.selected) {
        this.field.align = this.varalignLRF;
        this.controlClickAddFields(this.field);
      }
    });
  }
  widthMakeAllSame() {
    this.width = this.ctrlSelected[0].width;
    this.fields.forEach((element) => {
      this.field = element;
      if (element.selected) {
        this.field.width = this.width;
        this.controlClickAddFields(this.field);
      }
    });
  }
  heightMakeAllSame() {
    this.height = this.ctrlSelected[0].height;
    this.fields.forEach((element) => {
      this.field = element;
      if (element.selected) {
        this.field.height = this.height;
        this.controlClickAddFields(this.field);
      }
    });
  }
}

