import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../_modules/shared.module';
import { VideoviewComponent } from './videoview/videoview.component';
import { VideoRoutingModule } from './video-routing.module';


@NgModule({
    declarations: [
        VideoviewComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        VideoRoutingModule
    ]
})
export class VideoModule { }
