
<br><br><br>
<div class="customer-form-sec ">
  <div class="container-fluid">
<!-- <div class="md-col-12"> -->
  <div class="row">
        <div class="form-sec tab-content col-md-11">
            <div id="vehicle" class="tab-pane fade in active show">
            <form  id="editForm" novalidate #editForm="ngForm" (ngSubmit)="onSubmit(editForm)">
            <div class="row">
              <div class="col-md-6">
                  <div class="col-md-12">
                      <label>Large Description</label>
                    </div>
                    <div class="col-md-12">
                      <textarea name="Text1" cols="80" rows="19" 
                      [ngModel]="chartItem.vehicle?.largeDescription"
                      (ngModel)="chartItem.vehicle.largeDescription = $event"
                      name="largeDescription"
                      #largeDescription="ngModel"
                      required></textarea>
                    </div>
              </div>
              <div class="col-md-6">
                  <div class="col-md-12">
                      <label>Short Description</label>
                    </div>
                    <div class="col-md-12">
                      <textarea name="Text1" cols="80" rows="2"
                      [ngModel]="chartItem?.shortDescription"
                      (ngModel)="chartItem.shortDescription = $event"
                      name="shortDescription"
                      #shortDescription="ngModel"
                      ></textarea>
                    </div>
                  <div class="col-md-12">
                      <label>Small Sale Description</label>
                    </div>
                    <div class="col-md-12">
                      <textarea name="Text1" cols="80" rows="2"
                      [ngModel]="chartItem?.saleDescription"
                      (ngModel)="chartItem.saleDescription = $event"
                      name="saleDescription"
                      #saleDescription="ngModel"
                      ></textarea>
                    </div>
                    <div class="col-md-12">
                        <label>Purchase Description</label>
                      </div>
                      <div class="col-md-12">
                        <textarea name="Text1" cols="80" rows="2"
                        [ngModel]="chartItem?.purchaseDescription"
                        (ngModel)="chartItem.purchaseDescription = $event"
                        name="purchaseDescription"
                        #purchaseDescription="ngModel"
                        ></textarea>
                      </div>
              </div>
            </div>
          </form>
          </div>
          </div>
  
        <div  class="customer-sidebar sidebar-with-btns col-md-1  noselect" >
          <div  class="inner-sidebar ">
            <ul style="list-style-type:none; " class="noselect">

              <li>
                  <button form="ngForm" (click)="onSubmit(editForm)" class="cstm-btn img-blk-btn">
                    <img src="assets/images/ap-icon-1.png">
                    <span>SAVE</span>
                  </button>
                </li>
              <!-- <li>
                <button class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-6.png">
                  <span>Training Video </span>
                </button>
              </li>             -->
            </ul>         
          </div>
        </div>
     
  </div>

</div>
</div>


