import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SaleStatus } from 'src/app/_models/salestatus';

@Injectable({
  providedIn: 'root'
})
export class SaleStatusService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;

  getStatuses(guid: string): Observable<SaleStatus[]> {
    return this.http.get<SaleStatus[]>(this.baseUrl + 'salestatus/getstatuses/' + guid);
  }
  getActiveStatuses(guid: string): Observable<SaleStatus[]> {
    return this.http.get<SaleStatus[]>(this.baseUrl + 'salestatus/getactivestatuses/' + guid);
  }
  getStatus(id: number): Observable<SaleStatus> {
    return this.http.get<SaleStatus>(this.baseUrl + 'salestatus/getstatus/' + id);
  }

  deleteStatus(parentguid: string, id: number, isdelete: boolean): Observable<SaleStatus[]> {
    return this.http.get<SaleStatus[]>(this.baseUrl + 'salestatus/deletestatus/'
    + parentguid + '/' + id + '/' + isdelete);
  }
  editStatus(parentguid: string, saleStatus: SaleStatus): Observable<SaleStatus[]> {
    return this.http.put<SaleStatus[]>(this.baseUrl + 'salestatus/editstatus/', saleStatus);
  }
  addStatus(parentguid: string, value: string): Observable<SaleStatus[]> {
    return this.http.get<SaleStatus[]>(this.baseUrl + 'salestatus/addstatus/'
     + parentguid + '/' + value);
  }
}

