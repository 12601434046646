<!-- <ngx-spinner>
    <h3>This may take a minute... </h3>
</ngx-spinner> -->

<app-nav  ></app-nav>



    <router-outlet></router-outlet>

    <div class="customer-footer">
      <div class="container-fluid">
        <app-bottom-footer></app-bottom-footer>
      </div>
    </div>