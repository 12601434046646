import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WebsiteService } from 'src/app/advertising/website.service';
import { WebsiteMenuParagraphNoIdDto } from 'src/app/_models/DTOS/WebsiteMenuParagraphDto';
import { MenuReturnWebsiteMenuParagraphDto } from 'src/app/_models/DTOS/WebsiteMenuParagraphReturndto';
import { PdfFormTypes } from 'src/app/_models/PdfFormTypes';
import { WebsiteMenu } from 'src/app/_models/websites/WebsiteMenu';
import { WebsiteMenuParagraph } from 'src/app/_models/websites/WebsiteMenuParagraph ';
import { Website } from 'src/app/_models/websites/websites';
import { WebsiteMenuService } from 'src/app/_services/advertising/website-menu.service';


@Component({
  selector: 'app-websitemenu-paragraph',
  templateUrl: './websitemenu-paragraph.component.html',
  styleUrls: ['./websitemenu-paragraph.component.scss']
})
export class WebsitemenuParagraphComponent implements OnInit {
@Output()  websiteChanged: EventEmitter<MenuReturnWebsiteMenuParagraphDto> = new EventEmitter();
@Output() cancel: EventEmitter<void> = new EventEmitter();
//@Output(“parentFun”) parentFun: EventEmitter<any> = new EventEmitter();
@Input()  websiteMenuParagrahp = {} as WebsiteMenuParagraph;
@Input()  websiteMenuParagrahpPlaceHolder = {} as WebsiteMenuParagraph;
@Input() pdfFormTypes = [] as PdfFormTypes[];
@Input() website = null as Website;
@Input()  paragraphForm: FormGroup;
@Input() websiteMenu = {} as WebsiteMenu;
websiteMenuParagraphNoIdDto = {} as WebsiteMenuParagraphNoIdDto;
menuId = 0 as number;
isNewParagraph = false;
saveEditOrNew = 'Save Changes';

  constructor(private websiteService: WebsiteService, private websiteMenuService: WebsiteMenuService) { }

  ngOnInit() {
    this.menuId = this.websiteMenuParagrahp.websiteMenuId;
   // this.websiteMenuParagrahp = this.websiteMenu.websiteMenuParagraphs[0];
    this.isNewParagraph = false;
    console.log(this.website);
  }
  cancelUpdate() {
    this.cancel.emit();
  }
  updateAddparagraph() {
    console.log(this.websiteMenuParagrahp);
    if (this.website != null) {
      this.websiteMenuParagraphNoIdDto.description = this.websiteMenuParagrahp.description;
      this.websiteMenuParagraphNoIdDto.title = this.websiteMenuParagrahp.title;
      this.websiteMenuParagraphNoIdDto.paragraph = this.websiteMenuParagrahp.paragraph;
      this.websiteMenuParagrahp.websiteMenuId = this.websiteMenu.id;
      this.websiteMenuService.editWebsiteMenuParagraph(this.website.id, this.websiteMenuParagrahp).subscribe(data => {
          this.websiteChanged.emit(data);
        //  console.log(data);
           this.menuId = data.websiteMenu.id;
           this.websiteMenuParagrahp = data.websiteMenuParagraph;
      });

    }
    // if (!this.isNewParagraph && this.website != null) {
    //   this.websiteMenuService.editWebsiteMenuParagraph(this.website.id, this.websiteMenuParagrahp).subscribe(data => {
    //       this.websiteChanged.emit(data);
    //       console.log(data);
    //        this.isNewParagraph = false;
    //        this.menuId = data.websiteMenu.id;
    //        this.websiteMenuParagrahp = data.websiteMenuParagraph;
    //   });
    //}
    console.log(this.websiteMenuParagrahp);
  }

  addParagrah() {
    this.isNewParagraph = true;
    this.saveEditOrNew = 'Add New';
    this.websiteMenuParagrahpPlaceHolder = this.websiteMenuParagrahp;
    this.websiteMenuParagrahp = {id: null, deletedDate: null, paragraph: 'New', title: 'New', description: 'New', websiteMenuId: this.menuId, pdfId: null, youTubeId: null, itemId: null, videos: null, pdfs: null} ;
    this.websiteMenuParagraphNoIdDto = {deletedDate: null, paragraph: 'New', title: 'New', description: 'New', websiteMenuId: this.menuId};
    if (this.website != null) {
      this.websiteMenuParagraphNoIdDto.description = this.websiteMenuParagrahp.description;
      this.websiteMenuParagraphNoIdDto.title = this.websiteMenuParagrahp.title;
      this.websiteMenuParagraphNoIdDto.paragraph = this.websiteMenuParagrahp.paragraph;
      this.websiteMenuService.addWebsiteMenuParagraph(this.website.id, this.websiteMenuParagraphNoIdDto).subscribe(data => {
          this.websiteChanged.emit(data);
        //  console.log(data);
           this.menuId = data.websiteMenu.id;
           this.websiteMenuParagrahp = data.websiteMenuParagraph;
      });}
 
  }
  cancelNewParagraph() {
    this.isNewParagraph = false;
    this.saveEditOrNew = 'Save Changes';
    this.websiteMenuParagrahp = this.websiteMenuParagrahpPlaceHolder;
  }
}
