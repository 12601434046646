
<br><br>
<div style="margin-top: -30px;" class="container-fluid customer-tabs-sec">
    <ul class="nav nav-tabs">
      <li routerLinkActive="active">
        <a [ngClass]="{'active show' : isAll}" 
          data-toggle="tab"
          routerLinkActive="active show"
          (click)="filter('All')"
          >All</a
        >
      </li>  
      <div class="d-flex justify-content-right">
          <pagination
        [boundaryLinks]="true"
        pageBtnClass = "nav nav-tabs"
            [(ngModel)]="pagination.currentPage"
            [totalItems]="pagination.totalItems"
            (pageChanged)="pageChanged($event)"
            [itemsPerPage]="pagination.itemsPerPage"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;"
          >
          </pagination>
        </div>
  
  </ul>  
  </div>
  <!-- <app-date-filter *ngIf = "isFilterDates"></app-date-filter> -->
  <div class="customer-form-sec">
    <div class="container-fluid">
      <div class="row">
        <div class="form-sec tab-content col-md-11">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-row account-payable-row">          
                  
                  <div class="row">
                    <div class="col-md-12 table-sec">
                      <div id="printableTable">
                      <table border="1" borderColor="#ccc">
                        <tr>
                          <th>ID</th>
                          <th (click)="sort('ispropertyName')">Prop Name &nbsp;
                              <i [ngClass]="ispropertyName ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('isFirstNameAscending')">Monthly Gross&nbsp;
                              <i [ngClass]="isFirstNameAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('phone')">Monthly Paydown&nbsp;
                              
                          </th>
                          <th (click)="sort('isCityAscending')">Carrying Cost&nbsp;
                              <i [ngClass]="isCityAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('isCityAscending')">Escrows&nbsp;
                            <i [ngClass]="isCityAscending ? 'up': 'down'"></i>
                        </th>
                          <th (click)="sort('isStateAscending')">Equity Per Mth&nbsp;
                              <i [ngClass]="isStateAscending ? 'up': 'down'"></i>
                          </th>
                          <th (click)="sort('isCreatedDateAscnding')">Net $ Monthly&nbsp;
                              <i [ngClass]="isCreatedDateAscnding ? 'up': 'down'"></i>
                          </th>
                          <th>Pessimistic Equity&nbsp;</th>
                          <th>Purchased Price&nbsp;</th>
                          <th>Market Value&nbsp;</th>
                          <th>Square Ft&nbsp;</th>
                          <th>Balance Per Sq&nbsp;</th>
                        </tr>
                      
                        <tr class="td-row" *ngFor="let item of properties; let i=index">
                          <td *ngIf="i != properties.length - 1">{{ item.id }}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.propertyName }}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.projectedMonthlyIncome | currency}}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.payDownMonthly | currency}}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.carryingMonthly | currency}}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.escrows | currency}}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.equityPerMonth | currency}}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.monthlyNet  | currency}}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.equityAtClose  | currency}}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.purchasedPrice  | currency}}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.fairMarketValue  | currency}}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.squareFeet  }}</td>
                          <td *ngIf="i != properties.length - 1">{{ item?.owedPerSquare  | currency}}</td>
                          <td *ngIf="i != properties.length - 1">
                            <button
                              class="btn btn-outline-success"
                              (click)="edit(item)"
                            >
                              Edit
                            </button>
                          </td>
                        </tr>
                        <tr align="right" class="border_top" *ngFor="let item of properties; let i=index; last as isLast">
                          <td *ngIf="isLast && i !==0" class="charge-td">SUB Totals</td>
                          <td *ngIf="isLast && i !==0" class="charge-td"></td>
                          <td *ngIf="isLast && i !==0" class="charge-td">{{item.projectedMonthlyIncome | currency}}</td>
                          <td *ngIf="isLast && i !==0" class="charge-td">{{item.payDownMonthly | currency}}</td>
                          <td *ngIf="isLast && i !==0" class="charge-td">{{item.carryingMonthly | currency}}</td>
                          <td *ngIf="isLast && i !==0" class="charge-td">{{item.escrows | currency}}</td>
                          <td *ngIf="isLast && i !==0" class="charge-td">{{item.equityPerMonth | currency}}</td>
                          <td *ngIf="isLast && i !==0" class="charge-td">{{item.monthlyNet | currency}}</td>
                          <td *ngIf="isLast && i !==0"  class="charge-td">{{item.equityAtClose | currency}}</td>
                          <td *ngIf="isLast && i !==0"  class="charge-td">{{item.purchasedPrice | currency}}</td>
                          <td *ngIf="isLast && i !==0"  class="charge-td">{{item.fairMarketValue | currency}}</td>
                          <td *ngIf="isLast && i !==0"  class="charge-td">{{item.squareFeet }}</td>
                          <td *ngIf="isLast && i !==0"  class="charge-td">{{item.owedPerSquare | currency}}</td>
                        </tr>
                      </table>
                      </div>
                    </div>
                    <!-- /.table-sec -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.row -->
          </div>
        </div>
        <div class="customer-sidebar sidebar-with-btns col-md-1">
          <div class="inner-sidebar">
            <ul>
              <!-- [routerLink]="['vehicle/addnewvehicle/', selectedCompany1.parentGuid]" -->
              <li>
                <button (click) = "add()"  class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-1.png" />
                  <span>Add Person</span>
                </button>
              </li>
              <li *appHasRole="['Admin']">
                <button class="cstm-btn" (click) = "exportAsXLSX()">
                  <img src="assets/images/icons8-microsoft-excel-48.png" />
                  <span>Export Excel</span>
                </button>
              </li>
              <li>
                <button (click) = "printDiv()" class="cstm-btn">
                  <img src="assets/images/ap-icon-6.png" />
                  <span>Print Report</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>