import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VideoviewComponent } from './videoview/videoview.component';

// servicedashboard/serviceadd/

const routes: Routes = [

    {path: 'list', component: VideoviewComponent,
    resolve: {}
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class VideoRoutingModule {}

