import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PdfStateList } from 'src/app/_models/PDFentites/PdfStateList';
import { AccountService } from 'src/app/_services/account.service';
import { PatternValidate } from 'src/app/home/register/patternValidator';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MemberCardComponent } from 'src/app/members/member-card/member-card.component';
import { Member } from 'src/app/_models/member';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'app-add-employee-modal',
  templateUrl: './add-employee-modal.component.html',
  styleUrls: ['./add-employee-modal.component.scss']
})
export class AddEmployeeModalComponent implements OnInit {
  @Output() outputEmployee = new EventEmitter();
  @Input() user: User;
  registerForm: FormGroup;
  maxDate: Date;
  // stateList = [] as PdfStateList[];
  validationErrors: string[] = [];
  id: any;
  constructor(private accountService: AccountService, private toastr: ToastrService,
    private fb: FormBuilder, private router: Router,
    private pdfService: PdfService,
    private patternValidate: PatternValidate,
    public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    this.intitializeForm();
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    // this.pdfService.getPdfMasterStateList().subscribe(data => {
    //   this.stateList = data;
    // });
  }

  intitializeForm() {
    this.registerForm = this.fb.group({
      phone: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      zip: ['', Validators.required],
    });
    // email: ['', Validators.required],
    // password: ['', [Validators.required,
    //   Validators.minLength(6), Validators.maxLength(11),
    //   this.patternValidate.patternValidator(/\d/, { hasNumber: true }),
    //   this.patternValidate.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
    //   this.patternValidate.patternValidator(/[a-z]/, { hasSmallCase: true }),
    //   this.patternValidate.patternValidator(/[^A-Za-z0-9]/, { hasSpecialCharacters: true }),
    //   Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}') ]  ],
    // confirmPassword: ['', [Validators.required, this.matchValues('password')]]
  }
  // [^A-Za-z0-9||@||\.||_]
  matchValues(matchTo: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control?.value === control?.parent?.controls[matchTo].value
        ? null : {isMatching: true};
    };
  }
  stateSelected(e) {
    console.log(e);
    console.log(this.registerForm.value);
  }
  register() {
    this.outputEmployee.emit(this.registerForm.value);
     console.log(this.registerForm.value);
     this.bsModalRef.hide();
    // this.accountService.registerNewUser(this.registerForm.value)
    // .subscribe(response => {
    //  // this.router.navigateByUrl('/emailsent/emailcomfirm');
    // }, error => {
    //   this.validationErrors = error;
    // });
  }

  cancel() {
    this.bsModalRef.hide();
  }

}
