import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NewWebsiteMenuDto } from 'src/app/_models/DTOS/newWebsiteMenuDto';
import { Website } from 'src/app/_models/websites/websites';


@Component({
  selector: 'app-websitemenu-modal',
  templateUrl: './websitemenu-modal.component.html',
  styleUrls: ['./websitemenu-modal.component.scss']
})
export class WebsitemenuModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  website = {} as Website;
  entity = {} as NewWebsiteMenuDto;
  menuLevel = [{id: 1}, {id: 2}, {id: 3} ];
  menuLevels = {};
  groupLevel = [{id: 1}, {id: 2}, {id: 3} ];
  groupLevels = {};
  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {
    this.entity.className = '';
    this.entity.footer = false;
    this.entity.group = 0;
    this.entity.headerMenu = false;
    this.entity.iconName = '';
    this.entity.iconType = '';
    this.entity.iconUrl = '';
    this.entity.leftMenu = false;
    this.entity.level = 0;
    this.entity.name = '';
    this.entity.rightMenu = false;
    this.entity.url = '';
  }
  update() {
    this.emittedValues.emit(this.entity);
    this.modalRef.hide();
  }
  changeSubMenu(e) { this.entity.level = e; }

  changeGroupSubMenu(e) { this.entity.group = e; }

  checkMark(e: any) {
    if (e === 'headermenu') { this.entity.headerMenu = !this.entity.headerMenu; }
    if (e === 'footermenu') { this.entity.footer = !this.entity.footer; }
  }
}
