import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QbExpenseAccountsResolver } from '../_resolvers/qb-expenseaccounts.resolver';
import { QbDefaultsInventoriedAssetsResolver } from '../_resolvers/qb-InventoriedAssetAccounts.resolver';
import { QbDefaultsResolver } from '../_resolvers/qb-qbsaledefaults.resolver';
import { QuickbooksSaledefaultsComponent } from './quickbooks-saledefaults/quickbooks-saledefaults.component';

const routes: Routes = [
    // {path: 'vehiclecharts/:parrentGuid', component: InventoryGridComponent},

    {path: 'qbsaledefaultslist/:parentGuid/:realmId', component: QuickbooksSaledefaultsComponent, resolve: {
        inventoriedAssetAccounts: QbDefaultsInventoriedAssetsResolver,
        qbDefaults: QbDefaultsResolver,
        qbExpenseAccounts: QbExpenseAccountsResolver,
    }},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ListQbRoutingModule {}

// {path: 'productlist/aging/:parentGuid', component: AgingListComponent,
// resolve: {chartItems: VehicleGridResolver, bodyTypes: VehicleBodyClassTypesResolver
// }},
