<div class="customer-form-sec">
  <div class="container-fluid">
<div class="row">
<div class="form-sec tab-content col-md-12">
  <div id="vehicle" class="tab-pane fade in active show">
<div style="width: 550px" class="modal1" id="myModal">
  <div class="modal-dialog1 modal1" style="width:550px;">
     <div class="modal1-content modal1" style="width:450px;" >

<div  class="modal-header modal1">
  <h4 class="modal-title pull-left">Profit Items</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div  class="modal-body modal1">

<div class="form-sec tab-content col-md-12" style="width:550px !important;">
  <form id="editForm" #editForm="ngForm" >
    <!--creditlife-->
  <input
  type="checkbox"
  [ngModel]="programDefaults?.isCreditLifeProfit"
  (ngModelChange)="programDefaults.isCreditLifeProfit = $event"
  name="isCreditLifeProfit"
  #isCreditLifeProfit="ngModel"
  (change)="changed($event, 'isCreditLifeProfit')"
  id="filledInCheckboxDisabled"
  [checked]="programDefaults?.isCreditLifeProfit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  >Credit Life</label
><br>
<!--isDisabilityProfit-->
<input
  type="checkbox"
  [ngModel]="programDefaults?.isDisabilityProfit"
  (ngModelChange)="programDefaults.isDisabilityProfit = $event"
  name="isDisabilityProfit"
  #isDisabilityProfit="ngModel"
  (change)="changed($event, 'isDisabilityProfit')"
  id="isDisabilityProfit"
  [checked]="programDefaults?.isDisabilityProfit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  >Disability</label
><br>
<!--isDocFeesProfit-->
<input
  type="checkbox"
  [ngModel]="programDefaults?.isDocFeesProfit"
  (ngModelChange)="programDefaults.isDocFeesProfit = $event"
  name="isDocFeesProfit"
  #isDocFeesProfit="ngModel"
  (change)="changed($event, 'isDocFeesProfit')"
  id="isDocFeesProfit"
  [checked]="programDefaults?.isDocFeesProfit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  >Doc Fees</label
><br>
<!--isMiscNonTaxProfit-->
<input
  type="checkbox"
  [ngModel]="programDefaults?.isMiscNonTaxProfit"
  (ngModelChange)="programDefaults.isMiscNonTaxProfit = $event"
  name="isMiscNonTaxProfit"
  #isCreditLifeProfit="ngModel"
  (change)="changed($event, 'isMiscNonTaxProfit')"
  id="isMiscNonTaxProfit"
  [checked]="programDefaults?.isMiscNonTaxProfit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  >{{labelGap}}</label
><br>
<!--isMiscTaxableProfit-->
<input
  type="checkbox"
  [ngModel]="programDefaults?.isMiscTaxableProfit"
  (ngModelChange)="programDefaults.isMiscTaxableProfit = $event"
  name="isMiscTaxableProfit"
  #isCreditLifeProfit="ngModel"
  (change)="changed($event, 'isMiscTaxableProfit')"
  id="filledInCheckboxDisabled"
  [checked]="programDefaults?.isMiscTaxableProfit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  >{{labelExtra2}}</label
><br>
<!--isPredeliveryProfit-->
<input
  type="checkbox"
  [ngModel]="programDefaults?.isPredeliveryProfit"
  (ngModelChange)="programDefaults.isPredeliveryProfit = $event"
  name="isPredeliveryProfit"
  #isCreditLifeProfit="ngModel"
  (change)="changed($event, 'isPredeliveryProfit')"
  id="filledInCheckboxDisabled"
  [checked]="programDefaults?.isPredeliveryProfit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  > {{labelPredelivery}}</label
><br>
<!--isRegistrationProfit-->
<input
  type="checkbox"
  [ngModel]="programDefaults?.isRegistrationProfit"
  (ngModelChange)="programDefaults.isRegistrationProfit = $event"
  name="isRegistrationProfit"
  #isCreditLifeProfit="ngModel"
  (change)="changed($event, 'isRegistrationProfit')"
  id="filledInCheckboxDisabled"
  [checked]="programDefaults?.isRegistrationProfit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  >{{labelRegistration}}</label
><br>
<!--isSmogC1Profit-->
<input
  type="checkbox"
  [ngModel]="programDefaults?.isSmogC1Profit"
  (ngModelChange)="programDefaults.isSmogC1Profit = $event"
  name="isSmogC1Profit"
  #isSmogC1Profit="ngModel"
  (change)="changed($event, 'isSmogC1Profit')"
  id="filledInCheckboxDisabled"
  [checked]="programDefaults?.isSmogC1Profit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  >{{labelExtra1}}</label
><br>
<!--isTagProfit-->
<input
  type="checkbox"
  [ngModel]="programDefaults?.isTagProfit"
  (ngModelChange)="programDefaults.isTagProfit = $event"
  name="isTagProfit"
  #isTagProfit="ngModel"
  (change)="changed($event, 'isTagProfit')"
  id="filledInCheckboxDisabled"
  [checked]="programDefaults?.isTagProfit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  >{{labelTag}}</label
><br>

<!--isTitleProfit-->
<input
  type="checkbox"
  [ngModel]="programDefaults?.isTitleProfit"
  (ngModelChange)="programDefaults.isTitleProfit = $event"
  name="isTitleProfit"
  #isTitleProfit="ngModel"
  (change)="changed($event, 'isTitleProfit')"
  id="filledInCheckboxDisabled"
  [checked]="programDefaults?.isTitleProfit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  >{{labelTitle}}</label
><br>

<!--isWarantyProfit-->
<input
  type="checkbox"
  [ngModel]="programDefaults?.isWarantyProfit"
  (ngModelChange)="programDefaults.isWarantyProfit = $event"
  name="isWarantyProfit"
  #isWarantyProfit="ngModel"
  (change)="changed($event, 'isWarantyProfit')"
  id="filledInCheckboxDisabled"
  [checked]="programDefaults?.isWarantyProfit"
/>
<label
  class="form-check-label disabled"
  for="filledInCheckboxDisabled"
  >Warranty</label
><br>
<button class="btn btn-success" (click) = "onSubmit()">Submit</button>
</form>
</div>

</div>
</div>
  </div>
</div>
  </div>
</div>
</div>
</div>



