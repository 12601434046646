<div class="container-fluid" >
    <div >
        <div class="row">
            <div class="form-sec tab-content col-md-12">
               
<form  [formGroup]='registerForm' (ngSubmit)="registerForm.valid && register()" autocomplete="off">
    <h4 class="text-center text-primary">Sign up</h4>
    <hr>
    <!-- [(ngModel)] ="id" ngControl="selectedStateForm"  -->
    <!-- <div class="form-group">
        <label>State *</label>
        <select tabindex="-1" class="cstm-select"  
        formControlName="stateFormId"    
          
        (ngModelChange)="stateSelected($event)" >
        <option style="color: #bb400f;" [value]="-1">Select State</option>
          <option *ngFor="let item of stateList; index as i"   [value]="item.id">{{item?.fullName}}</option>
        </select>
    </div> -->

    <!-- <app-addemployee-textinput [formControl]='registerForm.controls["email"]' 
        [label]='"Email"'></app-addemployee-textinput> -->
    <app-addemployee-textinput [formControl]='registerForm.controls["phone"]' 
        [label]='"Phone"'></app-addemployee-textinput>
    <!-- <app-date-input [formControl]='registerForm.controls["dateOfBirth"]' 
        [label]='"Date Of Birth"' [maxDate]='maxDate'></app-date-input> -->
    <app-addemployee-textinput [formControl]='registerForm.controls["firstName"]' 
        [label]='"First Name"'></app-addemployee-textinput>
        <app-addemployee-textinput [formControl]='registerForm.controls["lastName"]' 
        [label] = '"Last Name"'></app-addemployee-textinput>
        <app-addemployee-textinput [formControl]='registerForm.controls["zip"]' 
        [label]='"Zip Code"'></app-addemployee-textinput>
   <!--  <app-text-input [formControl]='registerForm.controls["country"]' 
        [label]='"Country"'></app-text-input> -->
    <!-- <app-addemployee-textinput [formControl]='registerForm.controls["password"]' 
        [label]='"Password"' [type]='"password"'>
    </app-addemployee-textinput> -->
    <!-- <app-addemployee-textinput [formControl]='registerForm.controls["confirmPassword"]' 
        [label]='"Confirm Password"'
        [type]='"password"'></app-addemployee-textinput> -->
    <!-- <div>
        Password Must contain:
        <ul>
            <li *ngIf="registerForm.controls['password'].hasError('hasSpecialCharacters') == false" style="color: green;">A Special Character</li>
            <li *ngIf="registerForm.controls['password'].hasError('hasSpecialCharacters') == true " style="color: red;">A Special Character</li>
            <li *ngIf="registerForm.controls['password'].hasError('hasNumber') == false" style="color: green;"> 1 Number</li>
            <li *ngIf="registerForm.controls['password'].hasError('hasNumber') == true " style="color: red;"> 1 Number</li>
            <li *ngIf="registerForm.controls['password'].hasError('hasCapitalCase') == false" style="color: green;">1 Capital Letter</li>
            <li *ngIf="registerForm.controls['password'].hasError('hasCapitalCase') == true " style="color: red;">1 Capital Letter</li>
            <li *ngIf="registerForm.controls['password'].hasError('hasSmallCase') == false" style="color: green;"> 1 Lower Case Letter</li>
            <li *ngIf="registerForm.controls['password'].hasError('hasSmallCase') == true " style="color: red;"> 1 Lower Case Letter</li>
        </ul>
        </div> -->
    <!-- <div class="row" >
        <ul class="text-danger">
            <li *ngFor="let error of validationErrors">
                {{error}}
            </li>
            <li   *ngIf="registerForm.controls['password'].hasError('hasNumber')">
                does not have number
            </li>
        </ul>
    </div>
    <div class="row" *ngIf="registerForm.controls['password'].hasError('hasNumber')">
        <ul class="text-danger">
            <li>test</li>
            <li *ngFor="let error of validationErrors">   {{error}}
                Must have at least 1 number!
            </li>
        </ul>
    </div> -->

    <div class="form-group text-center">
        <button [disabled]='!registerForm.valid' class="btn btn-success mr-2" type="submit">Register</button>
        <button class="btn btn-default mr-2" (click)="cancel()" type="button">Cancel</button>
    </div>
</form>
</div>
</div>
</div>
</div>
