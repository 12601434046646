<div class="customer-form-sec" style="width:650px;">
  <div class="container-fluid">
<div class="row">
<div class="form-sec tab-content col-md-12">
  <div id="vehicle" class="tab-pane fade in active show">
<div style="width: 650px" class="modal1" id="myModal">
  <div class="modal-dialog1 modal1" style="width:650px;">
     <div class="modal1-content modal1" style="width:850px;" >

<div  class="modal-header modal1">
  <h4 class="modal-title pull-left">Add Service</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div  class="modal-body modal1">



<form  #editForm="ngForm" id="editForm" class="vehicle-form" (ngSubmit)="onUpdate(editForm)">
  <div class="form-group   form-inline">
    <label>Department: &nbsp;</label>
    <input type="text" class="form-control cstmnumeric-input" [ngModel]="serviceOffer?.departmentName" (ngModel)="serviceOffer.departmentName=$event"
      name="departmentName" #departmentName="ngModel">
  </div>
  <div class="form-group   form-inline">
    <label>Name: &nbsp;</label>
    <input type="text" class="form-control cstmnumeric-input" [ngModel]="serviceOffer?.name" (ngModel)="serviceOffer.name=$event"
      name="name" #name="ngModel">
  </div>
  <div class="form-group   form-inline">
      <label>Price: &nbsp;</label>
      <input type="text" class="form-control cstmnumeric-input" [ngModel]="serviceOffer?.price" (ngModel)="serviceOffer.price=$event"
        name="price" #price="ngModel"> 
    </div>
    <div class="form-group   form-inline">
      <label>Boolet One: &nbsp;</label>
      <input type="text" class="form-control cstboolet-input" [ngModel]="serviceOffer?.booletOne" (ngModel)="serviceOffer.booletOne=$event"
        name="booletOne" #booletOne="ngModel">
    </div>

    <div class="form-group   form-inline">
      <label>Boolet Two: &nbsp;</label>
      <input type="text" class="form-control cstboolet-input" [ngModel]="serviceOffer?.booletTwo" (ngModel)="serviceOffer.booletTwo=$event"
        name="booletTwo" #booletTwo="ngModel">
    </div>

    <div class="form-group   form-inline">
      <label>Boolet Three: &nbsp;</label>
      <input type="text" class="form-control cstboolet-input" [ngModel]="serviceOffer?.booletThree" (ngModel)="serviceOffer.booletThree=$event"
        name="booletThree" #booletThree="ngModel">
    </div>

    <div class="form-group   form-inline">
      <label>Boolet Four: &nbsp;</label>
      <input type="text" class="form-control cstboolet-input" [ngModel]="serviceOffer?.booletFour" (ngModel)="serviceOffer.booletFour=$event"
        name="booletFour" #booletFour="ngModel">
    </div>
    <div class="form-group   form-inline">
      <label>Small Description: &nbsp;</label>
      <textarea type="text" rows="10" class="form-control csttextarea-input" [ngModel]="serviceOffer?.smallDescription" (ngModel)="serviceOffer.smallDescription=$event"
        name="smallDescription" #smallDescription="ngModel"></textarea>
    </div>
    <div class= "form-group">
      <button class="btn-success" >SAVE</button>
    </div>
</form>

</div>
</div>
</div>
</div>
</div>
</div>
</div>
<!-- </div>
</div> -->
