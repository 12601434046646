import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-serviceofferssuboffermodal',
  templateUrl: './company-serviceofferssuboffermodal.component.html',
  styleUrls: ['./company-serviceofferssuboffermodal.component.css']
})
export class CompanyServiceofferssuboffermodalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
