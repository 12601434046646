import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormName } from '../_models/formName';
import { FormNameLabelsService } from '../_services/formnamelabels/form-name-labels.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class FormNameLabelListResolver implements Resolve<FormName> {
    constructor(private formNameService: FormNameLabelsService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<FormName> {
        return this.formNameService.getFormNameByName(route.params['parentGuid'], route.params['formName']).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving form name list.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}