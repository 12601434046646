import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventory-buyersguide',
  templateUrl: './inventory-buyersguide.component.html',
  styleUrls: ['./inventory-buyersguide.component.css']
})
export class InventoryBuyersguideComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
