import { Component, EventEmitter, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';
import { Sale } from 'src/app/_models/sale';
import { SaleDefault } from 'src/app/_models/SaleDefault';
import { SaleDefaultTemplate } from 'src/app/_models/SaleDefaultTemplate';
import { SaleDefaultTemplateField } from 'src/app/_models/SaleDefaultTemplateField';
import { SaleTaxDefault } from 'src/app/_models/SaleTaxDefault';

@Component({
  selector: 'app-edittaxdefaults-modal',
  templateUrl: './edittaxdefaults-modal.component.html',
  styleUrls: ['./edittaxdefaults-modal.component.scss']
})
export class EdittaxdefaultsModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  sale: Sale;
  bsModalRef2: any;
  isNewDefault = false;
  saleDefaultTemplate: SaleDefault;
  saleDefaultTemplateField: SaleTaxDefault;
  constructor(private modalService: BsModalService, public bsModalRef: BsModalRef, private format: DealInforFormat) { }

  ngOnInit() {
    this.format.formatTaxEditScreen(this.saleDefaultTemplate);
  }
  updateGap() {

    this.sale.saleDefaults = this.saleDefaultTemplate;
    console.log(this.saleDefaultTemplate);
    this.emittedValues.emit(this.saleDefaultTemplate);
    this.bsModalRef.hide();
  }
  userEnteredName() { this.isNewDefault = true; }
  closeFirstModal() {
    if (!this.bsModalRef) {
      return;
    }
    this.bsModalRef.hide();
    this.bsModalRef = null;
  }
  openModal2(template: TemplateRef<any>, e) {
    this.saleDefaultTemplateField = e;
    this.bsModalRef2 = this.modalService.show(template, {id: 2, class: 'second' });
  }

  onChange(e) {this.format.formatTaxEditScreen(this.saleDefaultTemplate); }
  closeModal(modalId?: number){
    this.modalService.hide(modalId);
  }
}
