import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RealPropertyEditDtoResolver } from '../_resolvers/RealPropertyEditDto.resolver';
import { RealPropertyLinqRepairsToReturnDtoResolver } from '../_resolvers/RealPropertyLinqRepairsToReturnDto.resolver';
import { RealPropertyLinqResultToReturnDtoResolver } from '../_resolvers/RealPropertyLinqResultToReturnDto.resolver';
import { EditRealpropertyComponent } from './edit-realproperty/edit-realproperty.component';
import { RealestateListComponent } from './realestate-list/realestate-list.component';




const routes: Routes = [

    {path: 'list/:parentGuid', component: RealestateListComponent, 
    resolve: { persons: RealPropertyLinqResultToReturnDtoResolver }},
    {path: 'edit/:propertyid/:parentGuid', component: EditRealpropertyComponent,
    resolve: { property: RealPropertyEditDtoResolver, repairs: RealPropertyLinqRepairsToReturnDtoResolver }},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RealEstateRoutingModule {}

