<ul >
  <li>
    <a class="item" href="javascript:void(0)" (click) ="addSale()" >
      <img src="assets/images/sidebar-icon-4.jpg">
      <span class="caption">Add Sale</span>
    </a>
  </li>
  <li>
    <a class="item" href="javascript:void(0)" (click) ="getDashBoard()">
      <img src="assets/images/sidebar-icon-4.jpg">
      <span class="caption">Export Excell</span>
    </a>
  </li>
  <li>
    <a class="item" href="javascript:void(0)" (click) ="getActiveSales()">
      <img src="assets/images/sidebar-icon-5.jpg">
      <span class="caption">Print</span>
    </a>
  </li>
  <li>
    <a class="item" href="javascript:void(0)" (click) ="getActiveSales()">
      <img src="assets/images/sidebar-icon-6.jpg">
      <span class="caption">FTP</span>
    </a>
  </li>

</ul>
