import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-deletedactive-option',
  templateUrl: './deletedactive-option.component.html',
  styleUrls: ['./deletedactive-option.component.css']
})
export class DeletedactiveOptionComponent implements OnInit {
@Input() isActive = true;
@Input() isDeleted = false;
isAll = false;
isExpandSearch = false;
@Output() isDeletedClick = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
    // console.log(this.isActive);
  }
  isActiveDeletedClick(e) {
    this.isDeleted = false;
    this.isActive = false;
    this.isAll = false;
    this.isExpandSearch = false;
    if (e === 'isActive') { this.isActive = true;  this.isDeletedClick.next('isActive'); }
    if (e === 'isDeleted') { this.isDeleted = true;  this.isDeletedClick.next('isDeleted'); }
    if (e === 'isAll') { this.isAll = true;  this.isDeletedClick.next('isAll'); }
    if (e === 'isExpandSearch') { this.isExpandSearch = true;  this.isDeletedClick.next('isExpandSearch'); }
  }
}
