
<div class="myDiv">
    <div *ngFor="let item of person?.pdfFieldNames" >
        <div class="col-md-12">
            <div class="row">
                <div [hidden] = "!item.isActive" class="col-md-6">
                    <button (click) = "selectedpdfFieldName(item)">{{item?.nameFriendly}}</button><br>
                </div>
            </div>
        </div>
    </div>
</div>

