import { Component, OnInit, EventEmitter, ViewChild, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-add-option-modal',
  templateUrl: './add-option-modal.component.html',
  styleUrls: ['./add-option-modal.component.css']
})
export class AddOptionModalComponent implements OnInit {
  @Output() addOption = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  option: any;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  update() {
    this.option = this.editForm.value;
    this.addOption.emit(this.option);
    this.bsModalRef.hide();
  }
}
