
// import * as moment from 'moment';

import moment from 'moment';
import { promise } from 'protractor';
import { BehaviorSubject } from 'rxjs';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { AmoLoan } from 'src/app/_models/amoloans';
import { CalculateTermDto } from 'src/app/_models/DTOS/CalculateTermDto';
import { Sale } from 'src/app/_models/sale';
export class AmortisationClass {
  savedAmortization = new BehaviorSubject<AmoAmortization[]>(null);
  currentSavedAmortization = this.savedAmortization.asObservable();
  moment = require('moment');
  saleEdited: Sale;
    todayDate = new Date();
    todayPayment = 0 as number;
    principleBalance = 0 as number;
    dailyFactor = 0 as number;
    paymentAmo  = 0 as number;
    paymentNoAmo = 0 as number;
    totalPrinciple = 0 as number;
    totalInterest = 0 as number;
    decliningBalance = 0 as number;
    nonAmortizedTotal = 0 as number;
    totalPaid = 0 as number;
    apr = 0 as number;
    i = 0 as number;
    dateF: Date;
    nextDate: Date;
    isComplete: any;
    loopedAmortizedAmount = 0 as number;
    loopedPayment = 0 as number;
    amoAmortization = {} as AmoAmortization;
    amoAmortizations = [] as AmoAmortization[];
    interestTotal = 0;
    principleTotal = 0;
    dsl: number = 0 as number;
    installment: string;
    loanTest: AmoLoan;
    calculateTermDto = {} as CalculateTermDto;
    todayInterest = 0 as number;
    todayPrinciple = 0 as number;
    tempFirstDueDate: Date;
    pmt = 0 as number;
    termIterateBalance = 0 as number;
    // constructor(loanIn: AmoLoan) {
    // this.loan = loanIn;
    // }
    ChangeAmortization(entity: AmoAmortization[]) {
      this.savedAmortization.next(entity);
    }
    async createAmortisation( sale: Sale): Promise<AmoAmortization[]>  {
      console.log(sale);
        this.amoAmortizations = [];
        this.decliningBalance = 0;
        this.principleBalance = 0;
        this.dailyFactor = 0;
        this.todayInterest = 0;
        this.interestTotal = 0;
        this.principleTotal = 0;
        this.todayPrinciple = 0 ;
        this.principleBalance = 0 ;
        this.totalInterest = 0;
        this.nonAmortizedTotal = 0 ;
        this.totalPaid = 0 ;
        this.paymentNoAmo = 0;
        this.todayPayment = sale.payment;
        this.apr = Number(sale.apr);
        sale.term = Number(sale.term);
        sale.amortized = Number(sale.amortized);
        sale.nonAmortized = Number(sale.nonAmortized);
        if (sale.dapr >> 0) {this.apr = Number(sale.dapr); }

        if (sale.nonAmortized > 0 && sale.term > 0) {
          this.paymentNoAmo = parseFloat((sale.nonAmortized / sale.term).toFixed(2)) ;
          if (sale.isPayment) { this.paymentNoAmo = parseFloat((sale.nonAmortized / (sale.term  )).toFixed(2)) ; }
        }
        this.decliningBalance = parseFloat((sale.amortized).toFixed(2)) ;
        this.principleBalance = parseFloat((sale.amortized).toFixed(2)) ;
        this.paymentAmo = parseFloat((this.todayPayment - this.paymentNoAmo).toFixed(2)) ;
        // -------------- TRANSFER LOAN INFORMATION TO AMORTIZATION MODEL LAYOUT --------------- //

      this.installment = sale.amoInstallments.value;
      this.dateF = moment(sale.soldDate).toDate();
      this.nextDate = moment(sale.firstDueDate).toDate();
      this.todayPrinciple = 0;
       this.dateDiffInDays( this.dateF, sale.firstDueDate);  // sets up dsl for first payment based on sold date - first due date. After first pay it cycles by installment
        this.i = 0;
                while ((sale.term - 1 ) > this.i) {
                  // this.dateF = this.amoAmortization.datePaid;
                  this.principleBalance = parseFloat((this.principleBalance - this.todayPrinciple).toFixed(2)) ;
                this.i += 1;
            //    console.log(((this.principleBalance * this.apr * .01) / 365));
                this.dailyFactor = ((this.principleBalance * this.apr * .01) / 365);
                this.todayInterest = parseFloat((((this.principleBalance * this.apr * .01) / 365) * this.dsl).toFixed(2));
                this.interestTotal = parseFloat((this.interestTotal + this.todayInterest).toFixed(2));

                this.todayPrinciple = parseFloat((this.paymentAmo - this.todayInterest).toFixed(2)) ;
                this.principleTotal = parseFloat((this.principleTotal + this.todayPrinciple).toFixed(2));
                this.decliningBalance = parseFloat((this.decliningBalance - this.todayPrinciple).toFixed(2)) ;
                this.totalInterest = parseFloat((this.totalInterest + this.todayInterest).toFixed(2));
                this.nonAmortizedTotal = parseFloat((this.nonAmortizedTotal + this.paymentNoAmo).toFixed(2)) ;
                this.totalPaid = parseFloat((Number(this.totalPaid)  + Number(this.todayPayment)).toFixed(2)) ;
                this.amoAmortization = {
                amoLoanId: sale.amoLoans.id,
                principleTotal: this.principleTotal,
                interestTotal: this.interestTotal,
                dsl: this.dsl,
                paymentAmo: this.paymentAmo,
                paymentNoAmo: this.paymentNoAmo,
                paymentTotal: this.todayPayment,
                decliningBalance: this.decliningBalance,
                nonAmortizedTotal: this.nonAmortizedTotal,
                isPaid: false,
                paymentNumber: this.i,
                sumPaid: this.totalPaid,
                principleBalance: this.principleBalance,
                datePaid: this.nextDate,
                paymentType: 'Payment',
                principle: this.todayPrinciple,
                interest:  this.todayInterest,
                dailyFactor: this.dailyFactor,
                daysPerYear: 365,
                contractRate: this.apr,
                aPR: this.apr
                 };
               await  this.insertToArray(this.amoAmortization);
                   this.dateF = await moment(this.nextDate).toDate();
               await   this.nextDueDate(moment(this.nextDate).toDate());
               await    this.dateDiffInDays(this.dateF, this.nextDate); // DSL
       }
        // ---------------------- FINAL PAYMENT ----------------------------------------

        this.dateF = this.amoAmortization.datePaid;
     //    this.nextDueDate(moment(this.nextDate).toDate());
        this.principleBalance = parseFloat((this.principleBalance - this.todayPrinciple).toFixed(2)) ;
                this.paymentNoAmo = sale.nonAmortized - this.nonAmortizedTotal;
                this.i += 1;
                this.dailyFactor = ((this.principleBalance * this.apr * .01) / 365);
                this.todayInterest = parseFloat((((this.principleBalance * this.apr * .01) / 365) * this.dsl).toFixed(2));
                this.interestTotal = parseFloat((this.interestTotal + this.todayInterest).toFixed(2)) ;
                this.todayPrinciple = parseFloat((this.principleBalance).toFixed(2)) ;
                this.principleTotal = parseFloat((this.principleTotal + this.todayPrinciple).toFixed(2));
                this.paymentAmo = parseFloat((this.principleBalance + this.todayInterest).toFixed(2));
                this.todayPayment = parseFloat((this.principleBalance + this.todayInterest + this.paymentNoAmo).toFixed(2));
                this.principleBalance = parseFloat((0).toFixed(2)) ;
                this.totalInterest = parseFloat((this.totalInterest + this.todayInterest).toFixed(2));
                this.nonAmortizedTotal = parseFloat((this.nonAmortizedTotal + this.paymentNoAmo).toFixed(2)) ;
                this.totalPaid = parseFloat((this.totalPaid + this.todayPayment).toFixed(2)) ;
                this.decliningBalance = parseFloat((this.decliningBalance - this.todayPrinciple).toFixed(2)) ;
                this.amoAmortization = {
                amoLoanId: sale.amoLoans.id,
                dsl: this.dsl,
                principleTotal: this.principleTotal,
                interestTotal: this.interestTotal,
                paymentAmo: this.paymentAmo,
                paymentNoAmo: this.paymentNoAmo,
                paymentTotal: this.todayPayment,
                decliningBalance: this.decliningBalance,
                nonAmortizedTotal: this.nonAmortizedTotal,
                isPaid: false,
                paymentNumber: this.i,
                sumPaid: this.totalPaid,
                principleBalance: this.principleBalance,
                datePaid: this.nextDate,
                paymentType: 'Payment',
                principle: this.todayPrinciple,
                interest:  this.todayInterest,
                dailyFactor: this.dailyFactor,
                daysPerYear: 365,
                contractRate: this.apr,
                aPR: this.apr
                 };
                 sale.finalPayment = parseFloat((this.todayPayment).toFixed(2)) ;
              //   loan.paymentTotalFinalPayment = parseFloat((this.todayPayment).toFixed(2)) ;
                 this.amoAmortizations.push(this.amoAmortization);
             //    console.log(this.amoAmortizations);
                 this.ChangeAmortization(this.amoAmortizations);
                 console.log(this.amoAmortizations);
                 return this.amoAmortizations;
    }
   async  insertToArray(amoAmortization: AmoAmortization) {
        this.amoAmortizations.push(amoAmortization);
        return this.amoAmortizations;
    }
  async  addDays(date: Date, days) {
        date.setDate(date.getDate() + parseInt(days, 10));
        return date;
      }
   async nextDueDate(date: Date) {
        switch (this.installment) {
          case 'Weekly':
              this.nextDate = await  this.addDays(new Date(date), 7);
              this.dsl = 7;
             // console.log('FROMCACLNEXTDATEFUNCTION: ' + this.nextDate);
            break;
            case 'Monthly':
                this.nextDate =    new Date(date.setMonth(date.getMonth() + 1));
            //  this.nextDate = moment(this.nextDate.setMonth( this.nextDate.getMonth() + 1 )).toDate();
           break;
            case 'Bi-Weekly':
                this.nextDate = await  this.addDays(new Date(date), 14);
                this.dsl = 14;
             //   console.log('FROMCACLNEXTDATEFUNCTION: ' + this.nextDate);
            break;
            case 'Semi-Monthly':
              this.nextDate = await  this.addDays(new Date(date), 15);
            break;
          default:
        }

      }
      async dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information. Use Moment JS to ensure dates are same date with no change of daylight saving time or time zones from utc
        a = moment(a).toDate();
        b = moment(b).toDate();
        // console.log('From DSL a: ' + a);
        // console.log('From DSL b: ' + b);
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        this.dsl = Math.floor((utc2 - utc1) / _MS_PER_DAY);
        // console.log(this.dsl);
        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
      }

      iterateTermAndAprGiven(loan: AmoLoan, chartInvoiceTransaction: Sale) {
        this.pmt = parseFloat((loan.amortized / loan.term).toFixed(2)) ;
        while (loan.term > this.i) {
               console.log(this.pmt);
        }
      }
      iterateTermForPayment(loan: AmoLoan) {
        this.installment = loan.amoInstallments.value;
        this.dateF = moment(loan.loanStartDate).toDate();
        this.nextDate = moment(loan.firstDueDate).toDate();
        this.apr = loan.contractRate;
        this.i = 0;
        if (loan.nonAmortized > 0 && loan.term > 0) {
            this.paymentNoAmo = parseFloat((loan.nonAmortized / loan.term).toFixed(2)) ;
            if (loan.isCalculateTerm) { this.paymentNoAmo = parseFloat((loan.nonAmortized / (loan.term + 1 )).toFixed(2)) ; }
        }
        this.todayPrinciple = parseFloat((this.paymentAmo - this.todayInterest).toFixed(2)) ;
        this.principleBalance = parseFloat((loan.amortized).toFixed(2)) ;
        this.totalInterest = parseFloat((this.totalInterest + this.todayInterest).toFixed(2));
        this.paymentAmo = parseFloat((this.todayPayment - this.paymentNoAmo).toFixed(2));
        while (loan.term > this.i) {
            this.i += 1;
            this.todayInterest = parseFloat((((this.principleBalance * this.apr * .01) / 365) * this.dsl).toFixed(2));
            this.todayPrinciple = parseFloat((this.paymentAmo - this.todayInterest).toFixed(2)) ;
            this.principleBalance = this.principleBalance - this.todayPrinciple;

            this.dateF = moment(this.nextDate).toDate();
            this.nextDueDate(moment(this.nextDate).toDate());
            this.dateDiffInDays(this.dateF, this.nextDate); // DSL
        }
        return this.principleBalance;
      }
      /// increases payment by .01 per loop
      async  saleLooopTermTillPayoffZero(sale: Sale): Promise<Sale> {
     //   console.log(sale);
        this.paymentNoAmo = 0;
        sale.amortized = Number(sale.amortized);
        sale.nonAmortized = Number(sale.nonAmortized);
        sale.term = Number(sale.term);
        this.saleEdited = sale;
        let paymentNoAmoIncrease = (sale.amortized / sale.term);
        if (sale.nonAmortized > 0 && sale.term > 0) {
          this.paymentNoAmo =  parseFloat(((sale.nonAmortized / sale.term)).toFixed(2)) ;
          paymentNoAmoIncrease = paymentNoAmoIncrease - this.paymentNoAmo;
      }
      this.saleEdited.payment = paymentNoAmoIncrease;
        let principle = (sale.term * .01);
       // console.log(principle);
        this.installment = sale.amoInstallments.value;
        // console.log('INSTALLMENT STRING: ' + sale.amoInstallments.value);
        // console.log('PAYMENT PRIORE TO STARTING LOOP: ' + paymentNoAmoIncrease);
        // console.log(sale.amortized);
        while (principle > 0) {
            paymentNoAmoIncrease = paymentNoAmoIncrease  + .01;
          principle =  await this.saleIterateTermForPayment(sale, paymentNoAmoIncrease);
          // if (principle << 0) {
          //   this.saleEdited.payment = paymentNoAmoIncrease;
          //   return  this.saleEdited; }
        }
      //  principle = await  this.saleIterateTermForPayment(sale, paymentNoAmoIncrease);
      console.log(paymentNoAmoIncrease);
      if ( this.paymentNoAmo >> 0) {paymentNoAmoIncrease = paymentNoAmoIncrease + this.paymentNoAmo; }
       this.saleEdited.payment = parseFloat(paymentNoAmoIncrease.toFixed(2)) ;
        return this.saleEdited;
      }
     async saleIterateTermForPayment(loan: Sale, paymentNoAmoIncrease: number) {
        loan.apr = Number(loan.apr);
        this.todayPayment = paymentNoAmoIncrease;
        this.installment = loan.amoInstallments.value;
        this.dateF = moment(loan.soldDate).toDate();
        this.nextDate = moment(loan.firstDueDate).toDate();
        this.saleEdited.nextDueDate = loan.firstDueDate;
        this.dateDiffInDays(this.dateF, this.nextDate); // DSL
        if (loan.dapr >> 0) {
          this.apr = loan.dapr;
        } else {
          this.apr = loan.apr;
        }
        this.i = 0;
        this.paymentAmo = paymentNoAmoIncrease;
        this.todayPrinciple = parseFloat((this.paymentAmo - this.todayInterest).toFixed(2)) ;
        if (this.todayPrinciple << 0) { this.todayPrinciple = 0; }
        this.principleBalance = parseFloat((loan.amortized).toFixed(2)) ;
        this.totalInterest = parseFloat((this.totalInterest + this.todayInterest).toFixed(2));
        this.paymentAmo = parseFloat((this.todayPayment - this.paymentNoAmo).toFixed(2));
        while (loan.term > this.i) {
            this.i += 1;
         //   console.log(this.i);
            this.todayInterest = parseFloat((((this.principleBalance * this.apr * .01) / 365) * this.dsl).toFixed(2));
            // console.log('todayInterest AFTER LOOP: ' + this.todayInterest);
            // console.log('paymentAmo PRIORE TO STARTING LOOP: ' + this.paymentAmo);
            this.todayPrinciple = parseFloat((this.paymentAmo - this.todayInterest).toFixed(2)) ;
         //   console.log('todayPrinciple AFTER LOOP: ' + this.todayPrinciple);
            this.principleBalance = this.principleBalance - this.todayPrinciple;
         //   console.log('Principle AFTER LOOP: ' + this.principleBalance);
            this.dateF = moment(this.nextDate).toDate();
          //  console.log('dateF AFTER LOOP: ' + this.dateF);
            await  this.nextDueDate(moment(this.nextDate).toDate());
         //   console.log('NEXT DATE AFTER LOOP: ' + this.nextDate);
            await this.dateDiffInDays(this.dateF, this.nextDate); // DSL
         //   console.log('Dsl after first loop: ' + this.dsl);
        }
     //   console.log(this.principleBalance);
    //    console.log(this.paymentAmo);
        return this.principleBalance;
      }



      async calculateTerm(sale: Sale, daysTillFIrstDue): Promise<Sale> {
        this.termIterateBalance = 0;
        console.log(sale.term);
        if (sale.term === 0) {
          sale.term = 2;
        }
        let i = 0;
        console.log(sale.payment);
        if (sale.payment >> sale.amortized) {
        //  sale.payment = sale.amortized;
          console.log(sale.payment);
        }
        let principle = sale.amortized / sale.term;
        sale.term = sale.amountFinanced / sale.payment;

      //  console.log('cacl term reached');
      if (sale.payment >> 0) {

        while ( principle > 0){
          principle = this.iterationForPaymentTogGetTerm(sale, daysTillFIrstDue, i);
          i = i + 1 ;
        }
      }
      sale.term = sale.term - 1;
     // sale.finalPayment = Number(sale.payment) + Number(principle);
      sale.finalPayment = parseFloat((Number(sale.payment) + Number(principle)).toFixed(2));
      console.log(sale);
       // this.createAmortisation(sale);
       return sale;
      }

       iterationForPaymentTogGetTerm(sale: Sale, daysTillFirstDue, term) {
      //  console.log(sale);
        sale.term = term;
        this.amoAmortizations = [];
        this.decliningBalance = 0;
        this.principleBalance = 0;
        this.dailyFactor = 0;
        this.todayInterest = 0;
        this.interestTotal = 0;
        this.principleTotal = 0;
        this.todayPrinciple = 0 ;
        this.principleBalance = 0 ;
        this.totalInterest = 0;
        this.nonAmortizedTotal = 0 ;
        this.totalPaid = 0 ;
        this.paymentNoAmo = 0;
        this.todayPayment = sale.payment;
        this.apr = Number(sale.apr);
        sale.term = Number(sale.term);
        sale.amortized = Number(sale.amortized);
        sale.nonAmortized = Number(sale.nonAmortized);
        if (sale.dapr >> 0) {this.apr = Number(sale.dapr); }

        if (sale.nonAmortized > 0 && sale.term > 0) {
          this.paymentNoAmo = parseFloat((sale.nonAmortized / sale.term).toFixed(2)) ;
          if (sale.isPayment) { this.paymentNoAmo = parseFloat((sale.nonAmortized / (sale.term + 1 )).toFixed(2)) ; }
        }
        this.decliningBalance = parseFloat((sale.amortized).toFixed(2)) ;
        this.principleBalance = parseFloat((sale.amortized).toFixed(2)) ;
        this.paymentAmo = parseFloat((this.todayPayment - this.paymentNoAmo).toFixed(2)) ;
        // -------------- TRANSFER LOAN INFORMATION TO AMORTIZATION MODEL LAYOUT --------------- //

      this.installment = sale.amoInstallments.value;
      this.dateF = moment(sale.soldDate).toDate();
      this.nextDate = moment(sale.firstDueDate).toDate();
      this.todayPrinciple = 0;
       this.dateDiffInDays( this.dateF, sale.firstDueDate);  // sets up dsl for first payment based on sold date - first due date. After first pay it cycles by installment
        this.i = 0;
                while ((sale.term   ) > this.i) {
                  // this.dateF = this.amoAmortization.datePaid;
                  this.principleBalance = parseFloat((this.principleBalance - this.todayPrinciple).toFixed(2)) ;
                this.i += 1;
            //    console.log(((this.principleBalance * this.apr * .01) / 365));
                this.dailyFactor = ((this.principleBalance * this.apr * .01) / 365);
                this.todayInterest = parseFloat((((this.principleBalance * this.apr * .01) / 365) * this.dsl).toFixed(2));
                this.interestTotal = parseFloat((this.interestTotal + this.todayInterest).toFixed(2));

                this.todayPrinciple = parseFloat((this.paymentAmo - this.todayInterest).toFixed(2)) ;
                this.principleTotal = parseFloat((this.principleTotal + this.todayPrinciple).toFixed(2));
                this.decliningBalance = parseFloat((this.decliningBalance - this.todayPrinciple).toFixed(2)) ;
                this.totalInterest = parseFloat((this.totalInterest + this.todayInterest).toFixed(2));
                this.nonAmortizedTotal = parseFloat((this.nonAmortizedTotal + this.paymentNoAmo).toFixed(2)) ;
                this.totalPaid = parseFloat((Number(this.totalPaid)  + Number(this.todayPayment)).toFixed(2)) ;
                this.amoAmortization = {
                amoLoanId: sale.amoLoans.id,
                principleTotal: this.principleTotal,
                interestTotal: this.interestTotal,
                dsl: this.dsl,
                paymentAmo: this.paymentAmo,
                paymentNoAmo: this.paymentNoAmo,
                paymentTotal: this.todayPayment,
                decliningBalance: this.decliningBalance,
                nonAmortizedTotal: this.nonAmortizedTotal,
                isPaid: false,
                paymentNumber: this.i,
                sumPaid: this.totalPaid,
                principleBalance: this.principleBalance,
                datePaid: this.nextDate,
                paymentType: 'Payment',
                principle: this.todayPrinciple,
                interest:  this.todayInterest,
                dailyFactor: this.dailyFactor,
                daysPerYear: 365,
                contractRate: this.apr,
                aPR: this.apr
                 };
             //  await  this.insertToArray(this.amoAmortization);
                   this.dateF =  moment(this.nextDate).toDate();
                  this.nextDueDate(moment(this.nextDate).toDate());
                   this.dateDiffInDays(this.dateF, this.nextDate); // DSL
       }
      //  return  this.principleBalance;
        // // ---------------------- FINAL PAYMENT ----------------------------------------
      //  if(sale.payment >> this.principleBalance ) {
      //    console.log(this.principleBalance);
      //   this.dateF = this.amoAmortization.datePaid;
      //   this.nextDueDate(moment(this.nextDate).toDate());
      //   this.principleBalance = parseFloat((this.principleBalance - this.todayPrinciple).toFixed(2)) ;
      //          this.paymentNoAmo = sale.nonAmortized - this.nonAmortizedTotal;
      //          this.i += 1;
      //          this.dailyFactor = ((this.principleBalance * this.apr * .01) / 365);
      //          this.todayInterest = parseFloat((((this.principleBalance * this.apr * .01) / 365) * this.dsl).toFixed(2));
      //          this.interestTotal = parseFloat((this.interestTotal + this.todayInterest).toFixed(2)) ;
      //          this.todayPrinciple = parseFloat((this.principleBalance).toFixed(2)) ;
      //          this.principleTotal = parseFloat((this.principleTotal + this.todayPrinciple).toFixed(2));
      //          this.paymentAmo = parseFloat((this.principleBalance + this.todayInterest).toFixed(2));
      //          this.todayPayment = parseFloat((this.principleBalance + this.todayInterest + this.paymentNoAmo).toFixed(2));
      //          this.principleBalance = parseFloat((0).toFixed(2)) ;
      //          this.totalInterest = parseFloat((this.totalInterest + this.todayInterest).toFixed(2));
      //          this.nonAmortizedTotal = parseFloat((this.nonAmortizedTotal + this.paymentNoAmo).toFixed(2)) ;
      //          this.totalPaid = parseFloat((this.totalPaid + this.todayPayment).toFixed(2)) ;
      //          this.decliningBalance = parseFloat((this.decliningBalance - this.todayPrinciple).toFixed(2)) ;
      //          this.amoAmortization = {
      //          amoLoanId: sale.amoLoans.id,
      //          dsl: this.dsl,
      //          principleTotal: this.principleTotal,
      //          interestTotal: this.interestTotal,
      //          paymentAmo: this.paymentAmo,
      //          paymentNoAmo: this.paymentNoAmo,
      //          paymentTotal: this.todayPayment,
      //          decliningBalance: this.decliningBalance,
      //          nonAmortizedTotal: this.nonAmortizedTotal,
      //          isPaid: false,
      //          paymentNumber: this.i,
      //          sumPaid: this.totalPaid,
      //          principleBalance: this.principleBalance,
      //          datePaid: this.nextDate,
      //          paymentType: 'Payment',
      //          principle: this.todayPrinciple,
      //          interest:  this.todayInterest,
      //          dailyFactor: this.dailyFactor,
      //          daysPerYear: 365,
      //          contractRate: this.apr,
      //          aPR: this.apr
      //           };
      //           sale.finalPayment = parseFloat((this.todayPayment).toFixed(2)) ;
      //        //   loan.paymentTotalFinalPayment = parseFloat((this.todayPayment).toFixed(2)) ;
      //       //    this.amoAmortizations.push(this.amoAmortization);
      //           console.log(this.amoAmortizations);
      //           this.ChangeAmortization(this.amoAmortizations);
      //           console.log(this.principleBalance);
      //  }
       return  this.principleBalance;
      }
    }
