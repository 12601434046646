import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { WebsiteCompany } from 'src/app/_models/company';
import { CompanyDepartment } from 'src/app/_models/CompanyDepartment';
import { CompanyDepartmentType } from 'src/app/_models/CompanyDepartmentType';
import { ServiceOffer } from 'src/app/_models/serviceoffer';
import { Website } from 'src/app/_models/website';
import { SubServiceOffer } from 'src/app/_models/websites/SubServiceOffer';
import { ServiceOffersService } from 'src/app/_services/advertising/serviceoffer.service';
import { WebsiteService } from 'src/app/_services/advertising/website.service';
import { CompanyDepartmentsService } from 'src/app/_services/companyDepartments/company-departments.service';
import * as _ from 'underscore';
import { CompanyServiceofferssuboffermodalComponent } from './company-serviceofferssuboffermodal/company-serviceofferssuboffermodal.component';
@Component({
  selector: 'app-company-serviceoffers',
  templateUrl: './company-serviceoffers.component.html',
  styleUrls: ['./company-serviceoffers.component.css']
})
export class CompanyServiceoffersComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() company: WebsiteCompany;
  @Input() website: Website;
  @Input() serviceOffers: ServiceOffer[];
  companyDepartment = {} as CompanyDepartment;

  companyDepartmentTypes = [] as CompanyDepartmentType[];
  companyDepartmentType = {} as CompanyDepartmentType;
  selectedCompanyDepartmentType = {} as CompanyDepartmentType;
  serviceOffer: ServiceOffer;
  SubServiceOffer: SubServiceOffer;
  SubServiceOffers = [] as SubServiceOffer[];
  selected: boolean;
  bsModalRef: any;
  selectedcompanyDepartmentTypeIsTrue: boolean;

  constructor(private websiteService: WebsiteService,
    private modalService: BsModalService,
     private serviceServiceOffer: ServiceOffersService,
     private companyDepartmentService: CompanyDepartmentsService,
     private alertify: ToastrService) { }

  ngOnInit() {
    this.websiteService.website.subscribe(data => {
      this.website = data;
      this.serviceOffers = _.sortBy(this.website?.company?.serviceOffers, 'companyDepartmentTypeId');
      this.serviceOffers.sort((a, b) => a.companyDepartmentTypeId >> b.companyDepartmentTypeId);
    });
    this.selected = false;
    this.getServiceOfferCompanyTypes();
    console.log(this.serviceOffers);
  }
  addServiceOfferListItem(e: ServiceOffer) {
    console.log(this.companyDepartmentService);
    console.log(this.serviceOffer);
  }
  deleteItem(e){
    if (e.companyDepartmentTypeId == null) {
      e.companyDepartmentTypeId = this.companyDepartmentTypes[0].id;
    }
    this.companyDepartmentService
      .deleteServiceOffer(e.id, e.websiteGuid)
      .subscribe(
        (data) => {
          // this.companyDepartment = data;
          // this.selectedcompanyDepartmentTypeIsTrue = false;
        },
        (error) => {
          this.alertify.error(error);
        },
        () => {
          this.alertify.success('Department and Service Offer deleted.');
          this.serviceOffer = e;
          this.selected = true;
        }
      );
  }
  seletedItem(e: ServiceOffer) {
    console.log(e);
    this.companyDepartmentService
      .getByDeptTypeId(e.companyDepartmentTypeId, e.websiteGuid)
      .subscribe(
        (data) => {
          this.companyDepartment = data;
          this.selectedcompanyDepartmentTypeIsTrue = false;
        },
        (error) => {
          this.alertify.error(error);
        },
        () => {
          this.alertify.success('Department and Service Offer retrieved.');
          this.serviceOffer = e;
          this.selected = true;
        }
      );

  }
  markFormGroupTouched(editForm: any) {
    if (editForm.valid) {
      Object.keys( editForm.controls).forEach(key => {
        editForm.controls[key].markAsDirty();
      });
      return;
    }
  }
  serviceOfferCompanyType(e: number) {
    console.log(e);
    this.selectedcompanyDepartmentTypeIsTrue = true;
    // this.selectedCompanyDepartmentType = e;
    // this.companyDepartmentType = e;
    // this.website.company.serviceOffers[this.serviceOffer[id]]
    this.serviceOffer.companyDepartmentTypeId = e;
  }
  getServiceOfferCompanyTypes() {
    this.companyDepartmentService.listCompanyDepartments(this.website.id, true, false).subscribe(data => {
      this.companyDepartmentTypes = data;
    }, error => { console.log(error); }, () => { this.alertify.success('Department Types Recieved'); 
    this.serviceOffers.forEach(element => {
      if (element.companyDepartmentTypeId == null) {
        element.companyDepartmentTypeId = this.companyDepartmentTypes[0].id;
      }
    });
  });
  }
  onUpdate(e?) {
    console.log(this.serviceOffer);
    this.serviceServiceOffer.edit(this.serviceOffer).subscribe(
      (data) => {
        console.log(data);
        this.websiteService.changeWebsite(data);
      },
      (error) => {
        this.alertify.error(error);
      },
      () => {
        this.alertify.success('Success');
        console.log(this.website);
      }
    );
  }

  addNewSubOffer() {
    this.bsModalRef = this.modalService.show(CompanyServiceofferssuboffermodalComponent);
    this.bsModalRef.content.updateData.subscribe((values: SubServiceOffer) => {
      values.serviceOfferId = this.serviceOffer.id;
      values.websiteGuid = this.website.websiteGuid;
      this.serviceServiceOffer.addSubServiceOffer(values).subscribe(data => {
        this.website = data;
      }, error => {this.alertify.error(error); }, () => {this.alertify.success('Payment Type Added'); });
    }, () => { this.websiteService.changeWebsite(this.website); });
  }
}
