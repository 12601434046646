import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
@Injectable({ providedIn: 'root' })
export class SaleDataSellingCompany {
    fnz02 = (num) => num.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
        })

          fnz04 = (num) => num.toLocaleString('en-US', {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
            useGrouping: false,
            })
            fnz0 = (num) => num.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
                })

      fillPdfFieldsWithSellingDealer(sale: Sale, dealer: CompanyProfile, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
        if (pdfFields.length > 0 ) {
        let  field = '';
      let newpdfField = {} as PdfSaleFields;
      const newPdfFields = [] as PdfSaleFields[];
        pdfFields.forEach(element => {
            field = element.field;
            const str = field;
            const matches = str.match(/\d+/g);
            let i;
            if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }
            field = field.replace(i.toString(), '');
            newpdfField.groupBy = 'company';
        // buyer Information
        switch (field) {
            case prefix + 'Name': {
                newpdfField  = {
                    field: prefix + 'Name' + i,
                    value: dealer?.companyName,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'IsTaxDeferred': {
                newpdfField  = {
                    field: 'coIsTaxDeferred' + i,
                    value: dealer?.isTexasDeferred.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'LicNumber': {
                newpdfField  = {
                    field: 'coLicNumber' + i,
                    value: dealer?.licenseNumber,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Address': {
                newpdfField  = {
                    field: prefix + 'Address' + i,
                    value: dealer?.street,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'CityStateZip': {
                newpdfField  = {
                    field: prefix + 'CityStateZip' + i,
                    value: dealer?.city + ' ' + dealer?.state + ' ' + dealer?.zip,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'State': {
                newpdfField  = {
                    field: prefix + 'State' + i,
                    value: dealer?.state,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'City': {
                newpdfField  = {
                    field: prefix + 'City' + i,
                    value: dealer?.city,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'County': {
                newpdfField  = {
                    field: prefix + 'County' + i,
                    value: dealer?.county,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Zip': {
                newpdfField  = {
                    field: prefix + 'Zip' + i,
                    value: dealer?.zip,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Email': {
                newpdfField  = {
                    field: prefix + 'Email' + i,
                    value: dealer?.email,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Phone': {
                newpdfField  = {
                    field: prefix + 'Phone' + i,
                    value: dealer?.phone,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'TaxNumber': {
                newpdfField  = {
                    field: prefix + 'TaxNumber' + i,
                    value: dealer?.taxNumber,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'SellerFinanceTexas': {
                if (sale.amountFinanced > 0 && dealer?.isTexasDeferred) {
                    newpdfField  = {
                        field: prefix + 'SellerFinanceTexas'  + i,
                        value: 'Seller Finance' + dealer?.taxNumber,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                } else {
                    newpdfField  = {
                        field: prefix + 'SellerFinanceTexas' + i,
                        value: 'N/A',
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                }
               break;
            }
            case prefix + 'Fax': {
                newpdfField  = {
                    field: prefix + 'Fax' + i,
                    value: dealer?.fax,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'County': {
                newpdfField  = {
                    field: prefix + 'County' + i,
                    value: dealer?.county,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'EFN': {
                newpdfField  = {
                    field: prefix + 'EFN' + i,
                    value: dealer?.efn,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'EIN': {
                newpdfField  = {
                    field: prefix + 'EIN' + i,
                    value: dealer?.ein,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            default: {
                break;
             }
          }
         });
         return of(newPdfFields);
        }
        return of(pdfFields);
    }


}
