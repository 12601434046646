<table border="1" borderColor="#ccc">
  <tr>
    <th>Id</th>
    <th>Created</th>
    <th>Sale ID:Item ID</th>
    <th>Description</th>
    <th>User</th>
    <th>Dealer Cost</th>
    <th>Customer Cost</th>
    <th>Profit</th>
    <th>Dollar Data Field</th>
    <th>Recievable</th>
    <th>Payments Paid</th>
    <th>Paid Date</th>
  </tr>
  <tr  (dblclick)="editItem(item)" align="right" class="td-row" *ngFor="let item of dollars; let i=index">
    <td  align="left" *ngIf="i != dollars.length - 1">{{ item.id }}</td>
    <td align="left" *ngIf="i != dollars.length - 1">{{ item?.dateCreated  | date: "shortDate" }}</td>
    <td align="left" *ngIf="i != dollars.length - 1">{{ item?.saleId }} : {{ item?.itemId }}</td>
    <td align="left" *ngIf="i != dollars.length - 1">{{ item?.description }}</td>
    <td align="left" *ngIf="i != dollars.length - 1">{{ item?.createdBy?.person?.firstName }} {{item?.createdBy?.person?.lastName}}</td>
    <td align="right" *ngIf="i != dollars.length - 1">{{ item?.credit | number: "1.2" }}</td>
    <td align="right" *ngIf="i != dollars.length - 1">{{ item?.customerCost | number: "1.2" }}</td>
    <td align="right" *ngIf="i != dollars.length - 1">{{ item?.profit | number: "1.2" }}</td>
    <td align="left" *ngIf="i != dollars.length - 1">{{ item?.valueCaptionField | HumanizePipes }}</td>              
   
    <td align="right" *ngIf="i != dollars.length - 1">{{ item?.recievableBalance | number: "1.2" }}</td>
    <td *ngIf="i != dollars.length - 1">{{ item?.incomeRecieved }}</td>
    <td *ngIf="i != dollars.length - 1">{{ item?.dateOfTransaction | date: "shortDate"}}</td>
    <!-- <td [hidden] = "isPrint" *ngIf="i != chartItems.length - 1">
      <button
        class="btn btn-outline-success"
        (click)="editVehicle(item)"
      >
        Edit
      </button>
    </td> -->
  </tr>
   <!-- footer -->
   <tr  align="right" class="border_top" *ngFor="let item of dollars; let i=index; last as isLast">
    <td *ngIf="isLast && i !==0"></td>
    <td *ngIf="isLast && i !==0"></td>
    <td *ngIf="isLast && i !==0"></td>
    <td *ngIf="isLast && i !==0"></td>
   
    <td *ngIf="isLast && i !==0">Sub Totals: </td>
    <td *ngIf="isLast && i !==0">{{ item?.credit | number: "1.2" }}</td>
   
    <td *ngIf="isLast && i !==0">{{ item?.customerCost | number: "1.2" }}</td>
    <td *ngIf="isLast && i !==0">{{ item?.profit  | number: "1.2" }} </td>
    <td *ngIf="isLast && i !==0"></td>
    <td *ngIf="isLast && i !==0">{{ item?.recievableBalance | number: "1.2" }}</td>
    <td *ngIf="isLast && i !==0">{{ item?.incomeRecieved | number: "1.2" }}</td>
    <td *ngIf="isLast && i !==0"></td>
    </tr>
</table>