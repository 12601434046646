import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { filter } from 'rxjs/operators';
import { AddVehicleVinModalComponent } from '../../modals/add-vehicle-vin-modal/add-vehicle-vin-modal.component';
import { SupportService } from '../../support/support.service';
// import { Company } from '../_models/company';
import { ExportDropDowns } from '../../_models/dropdowns';
import { Member } from '../../_models/member';
import { PdfFormPackage } from '../../_models/pdfformpackage';
import { User } from '../../_models/user';
import { UserAssignedToCompany } from '../../_models/userAssignedToCompany';
import { Website } from '../../_models/website';
import { VehicleNewDto } from '../../_models/_models/DTO/VehicleNewDto';
import { AccountService } from '../../_services/account.service';
import { AuthService } from '../../_services/auth.service';
import { FormNameLabelsService } from '../../_services/formLabelservices/form-name-labels.service';
import { MembersService } from '../../_services/members.service';
import { NavbarService } from '../../_services/navbarservices/Navbar.service';
import { PdfService } from '../../_services/PdfSErvices/pdf.service';
import { PresenceService } from '../../_services/presence.service';
import { VehicleService } from '../../_services/vehicleserv/vehicle.service';
import { VendorService } from '../../_services/vendorservices/vendor.service';
@Component({
  selector: 'app-navloggedouttop',
  templateUrl: './navloggedouttop.component.html',
  styleUrls: ['./navloggedouttop.component.css']
})
export class NavloggedouttopComponent implements OnInit {
  @Output() homeClick = new EventEmitter();
  model: any = {};
  emailStartPwReset: any = {};
  photoUrl: string;
  isPasswordReset = false;
  isSales = false;
  isService = false;
  isInventory = false;
  isAdvertising = false;
  isCredit = false;
  isReports = false;
  isAccounting = false;
  isPhoneBook = false;
  isPayments = false;
  isLoan = false;
  isCalculator = false;
  isSupport = false;
  isAdmin = false;
  isBuyersGuid = false;
  isAddVehicle = false;
  isRealEstate = false;
  isVideos = false;
  isCompanyProfile = false;
  websites: Website[];
  routeSub: string;
 // companies: Company[];
  userAssignedToCompany: UserAssignedToCompany[];
  isRoute: string;
  loggedInId: any;
  isHome = false;
  member: Member;
  user: User;
  bsModalRef: any;
  isNewSupport = false;
  list: ExportDropDowns;
  // chartItem: ChartItem;
  selectedCompany1: UserAssignedToCompany;
  title = 'Ng-Teams';
  loading = false;
  parentGuid: string;
  jwtHelper = new JwtHelperService();
  constructor(
    public presence: PresenceService,
    private route: ActivatedRoute, private formService: FormNameLabelsService,
              public authService: AuthService, private alertify: ToastrService,
              private pdfService: PdfService,
              public navService: NavbarService,
              public memberService: MembersService,
              private vehicleService: VehicleService,
              public accountService: AccountService, private venderService: VendorService,
              private router: Router, private modalService: BsModalService,
              private supportService: SupportService) {
        this.router.events.subscribe((event: any) => {
          switch (true) {
            case event instanceof NavigationStart: {
              this.loading = true;
              break;
            }

            case event instanceof NavigationEnd:
            case event instanceof NavigationCancel:
            case event instanceof NavigationError: {
              this.loading = false;
              break;
            }
            default: {
              break;
            }
          }
        });
       }
  ngOnInit() {
  }
  login() {
    console.log('login being used not login2');
    console.log(this.model);
    this.accountService.login(this.model).subscribe(next => {
      this.alertify.success('Logged in successfully');
      console.log(next);
    }, error => {
      this.alertify.error(error);
    }, () => {
      this.authService.currentUserAssignedCompanies.subscribe(data => {
        this.userAssignedToCompany = data;
        this.authService.changeCompany(data[0]);
        this.selectedCompany(data[0]);
        this.getFormLabels();
        console.log(data);
      }, error => {
        console.log(error);
      }, () => {
        this.accountService.currentUser$.subscribe(data4 => {  this.user = data4; console.log(data4); });
        this.selectedCompany(this.userAssignedToCompany[0]);
     //   this.getWebsites(this.userAssignedToCompany[0].parentGuid);
        this.authService.changeCompany(this.userAssignedToCompany[0]);
        this.authService.changeParentUserAssignedCompanies(this.userAssignedToCompany);
        localStorage.setItem('company', JSON.stringify(this.userAssignedToCompany[0]));
      //  this.router.navigate(['/seedcompany']);
      });
    });
  }
  isClick(e) {
    const token = localStorage.getItem('token');
    this.isSales = false;
    this.isHome = false;
    this.isInventory = false;
    this.isAdvertising = false;
    this.isCredit = false;
    this.isReports = false;
    this.isAccounting = false;
    this.isPhoneBook = false;
    this.isPayments = false;
    this.isLoan = false;
    this.isCalculator = false;
    this.isSupport = false;
    this.isAdmin = false;
    this.isService = false;
    this.isBuyersGuid = false;
    this.isAddVehicle = false;
    this.isRealEstate = false;
    this.isVideos = false;
    this.isCompanyProfile = false;
    // if (this.jwtHelper.isTokenExpired(token)) { }
    if (e === 'isRealEstate') { this.isRealEstate = true; }
    if (e === 'isHome') { this.isHome = true; this.homeClick.emit(); }
    if (e === 'isVideos') { this.isVideos = true; }
    if (e === 'isAddVehicle') { this.isAddVehicle = true; }
    if (e === 'isBuyersGuid') { this.isBuyersGuid = true; }
    if (e === 'isService') { this.isService = true; }
    if (e === 'isAdmin') { this.isAdmin = true; }
    if (e === 'isSupport') { this.isSupport = true; }
    if (e === 'isCalculator') { this.isCalculator = true; }
    if (e === 'sales') { this.isSales = true; }
    if (e === 'isInventory') { this.isInventory = true; }
    if (e === 'isAdvertising') { this.isAdvertising = true; }
    if (e === 'isCredit') { this.isCredit = true; }
    if (e === 'isReports') { this.isReports = true; }
    if (e === 'isAccounting') { this.isAccounting = true; }
    if (e === 'isPhoneBook') { this.isPhoneBook = true; }
    if (e === 'isPayments') { this.isPayments = true; }
    if (e === 'isLoan') { this.isLoan = true; }
    if (e === 'isCompanyProfile') { this.isCompanyProfile = true; }
  }
  getFormLabels() {
    this.formService.getFormNameList(this.userAssignedToCompany[0].parentGuid).subscribe(data => {
      localStorage.setItem('formsLabels', JSON.stringify(data));
      console.log(data);
      this.authService.changeFormLabels(data);
    }, err => {}, () => {
      if (!localStorage.getItem('foo')) {
        localStorage.setItem('foo', 'no reload');
        location.reload();
      } else {
        localStorage.removeItem('foo');
      }
    });
  }
  onPasswordRest() {
    console.log(this.isPasswordReset);
    this.isPasswordReset = !this.isPasswordReset;
    this.router.navigate(['/']);
  }
  onRequestNewPassword() {
      console.log(this.emailStartPwReset);
      this.router.navigate(['/home']);
    this.accountService.startResetPassword(this.emailStartPwReset.email).subscribe(data => {}, error => {
      console.log(error);
    }, () => {  this.router.navigate(['/emailsent/emailcomfirm']); this.isPasswordReset = !this.isPasswordReset; });
  }
  selectedCompany(e: UserAssignedToCompany) {
    this.selectedCompany1 = e;
  // this.getWebsites(e.parentGuid);
    localStorage.setItem('company', JSON.stringify(e));
    this.authService.changeCompany(e);
    this.router.navigate(['/home']);
  }
}
