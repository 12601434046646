<div class="customer-form-sec">
  <div class="container-fluid">
    <br>
    <div class="row">
      <div class="form-sec tab-content col-md-10">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row">
                <h2 class="veh-title">Active Companies</h2>

                <div class="row">
                  <div class="col-md-12 table-sec">
                    <table border="1" borderColor="#ccc">
                      <tr >
                        <th style = "width: 2%;">ID</th>
                          <th style="width: 30%;">Company Name</th>
                          <th style="width: 10%;">Phone</th>
                          <th style="width: 30%;">Address</th>
                          <th style="width: 40%;">Is Default</th>
                      </tr>
                      <tr *ngFor="let company of companyProfiles">
                        <td>{{ company.id }}</td>
                          <td>{{ company.companyName }}</td>
                          <td>{{ company.phone }}</td>
                          <td>{{ company?.street }}</td>
                          <td *ngIf="company?.IsDefaultCompany"><button (click)= "makeDefault(company)" class="btn btn-warning">Delete Default </button></td>
                          <td *ngIf="!company?.IsDefaultCompany"><button (click)= "deleteDefault(company)" class="btn btn-info">Make Defalut </button></td>
                          <td><button (click)= "edit(company)" class="btn btn-info">Edit </button></td>
                      </tr>
                    </table>
                  </div><!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div><!-- /.row -->
        </div>
      </div>
      <div class="customer-sidebar sidebar-with-btns col-md-2">
        <div class="inner-sidebar">
          <ul>
            <li>
              <button 
               class="cstm-btn img-blk-btn" (click)="addCompany()">
                <img src="assets/images/ap-icon-1.png">
                <span>Add New</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>