import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LabelDoubleClickModalComponent } from 'src/app/modals/LabelDoubleClickModal/LabelDoubleClickModal.component';
import { FormName } from 'src/app/_models/formName';
import { Label } from 'src/app/_models/label';
import { Sale } from 'src/app/_models/sale';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { VehicleMake } from 'src/app/_models/vehiclemakes';
import { VehicleYears } from 'src/app/_models/vehicleYears';
import { AuthService } from 'src/app/_services/auth.service';
import { FormNameLabelsService } from 'src/app/_services/formLabelservices/form-name-labels.service';
import { ListService } from 'src/app/_services/listServices/list.service';
import { VendorService } from 'src/app/_services/vendorservices/vendor.service';

@Component({
  selector: 'app-trade-vehicle',
  templateUrl: './trade-vehicle.component.html',
  styleUrls: ['./trade-vehicle.component.css']
})
export class TradeVehicleComponent implements OnInit, OnChanges {
  @Input() formName: FormName;
  // @Input() item: Item;
   labels = [] as Label[];
   label: Label;
   labelToDisplay: any;
   loading: boolean;

   labelStock: any;
   labelVin: any;
   labelYear: any;
   labelStatus: any;
   labelCountyCode: any;
   labelCategory: any;
   lableTitleNumber: any;
   labelPreviousTitleNumber: any;
   labelMake: any;
   labelPreviousState: any;
   labelModel: any;
   labelPurchasedAs: any;
   labelExertiorColor1: any;
   labelPurchasedPrice: any;
   labelStyle: any;
   labelRepairsDepreciated: any;
   labelDisplacement: any;
   labelTotalCost: any;
   labelCylinders: any;
   labelSuggestedPrice: any;
   labelDatePurchased: any;
   labelDecalNumber: any;
   labelTag: any;
   labelTagExpires: any;
   labelMileage: any;
   labelWeight: any;
   isLoaded: boolean;
   labelMileageCode: any;

   vehicleYears = [] as VehicleYears[];
   vehicleMakes = [] as VehicleMake[];

   bsModalRef1: any;
   selectedCompany1: UserAssignedToCompany;
   bodyTypes: any[] = [];
@Input() sale = {} as Sale;
  constructor(private listService: ListService,
    private formNameService: FormNameLabelsService,
    private alertify: ToastrService,
    private modalService: BsModalService,
    private authService: AuthService,
    private vendorService: VendorService) { }

    ngOnChanges() {
      this.getLabels();
      this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
      this.listService.vehicleLabelFormName.subscribe(data => { this.formName = data; });
    }
    getLabels(){
      this.authService.currentFormNames.subscribe(data => {
        data.forEach(element => {
          if (element.value === 'VehicleDetails'){ this.labels = element.labels; this.decipherLabels(element.labels); }
        });
      });
    }
    ngOnInit() {
      this.getLabels();
    }
    toggleValue2(e) {
      const initialState = {
        labelName:  e
      };
      this.bsModalRef1 = this.modalService.show(LabelDoubleClickModalComponent, {initialState});
      this.bsModalRef1.content.emittedValues.subscribe((values) => {
        this.formNameService.editFormLabelByFieldName(this.selectedCompany1.parentGuid, e, values, 'VehicleDetails').subscribe(data => {
          this.formName = data;
          this.labels = data.labels;
          }, (error) => {
            this.alertify.error(error);
          }, () => { this.formNameService.getFormNameList(this.selectedCompany1.parentGuid).subscribe(data => {
            this.authService.changeFormLabels(data);
          }, error => { this.alertify.error(error); }, () => {
            this.alertify.success('Success!');  this.getLabels(); });
          });
         });
    }
    addLabel(e) {
      this.formNameService.addFormLabel(this.selectedCompany1.parentGuid, e, this.formName.id).subscribe(data => {
        this.formName = data;
        this.labels = data.labels;
      }, error => {this.alertify.error(error); }, () => {this.decipherLabels(this.labels); } );
    }
    decipherLabels(e) {
      this.labels = e;

      if  (this.labels.length > 0) {
        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Vin');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Vin'); }
        this.labelVin = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'StockNumber');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('StockNumber'); }
        this.labelStock = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Year');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Year'); }
        this.labelYear = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Status');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Status'); }
        this.labelStatus = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'County Code');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('County Code'); }
        this.labelCountyCode = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Category');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Category'); }
        this.labelCategory = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Title Number');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Title Number'); }
        this.lableTitleNumber = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Year');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Year'); }
        this.labelYear = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Previous Title Number');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Previous Title Number'); }
        this.labelPreviousTitleNumber = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Make');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Make'); }
        this.labelMake = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Previous State');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Previous State'); }
        this.labelPreviousState = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Model');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Model'); }
        this.labelModel = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Purchased As');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Purchased As'); }
        this.labelPurchasedAs = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Ext Color 1');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Ext Color 1'); }
        this.labelExertiorColor1 = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Purchased Price');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Purchased Price'); }
        this.labelPurchasedPrice = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Style');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Style'); }
        this.labelStyle = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Repairs:Depreciated');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Repairs:Depreciated'); }
        this.labelRepairsDepreciated = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Displacement');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Displacement'); }
        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Displacement');
        this.labelDisplacement = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Total Cost');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Total Cost'); }
        this.labelTotalCost = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Cylinders');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Cylinders'); }
        this.labelCylinders = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Suggested Price');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Suggested Price'); }
        this.labelSuggestedPrice = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Date Purchased');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Date Purchased'); }
        this.labelDatePurchased = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Decal Number');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Decal Number'); }
        this.labelDecalNumber = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Tag');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Tag'); }
        this.labelTag = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Tag Expires');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Tag Expires'); }
        this.labelTagExpires = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Mileage');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Mileage'); }
        this.labelMileage = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Weight');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Weight'); }
        this.labelWeight = this.labelToDisplay[0].value;

        this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Mileage Code');
        if (this.labelToDisplay === undefined || this.labelToDisplay.length === 0) { this.addLabel('Mileage Code'); }
        this.labelMileageCode = this.labelToDisplay[0].value;
      }
    }
  }
