import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VehicleoptionsService } from '../_services/vehicleserv/vehicleoptions.service';
import { VehicleOptions } from '../_models/vehicleOptions';
import { ToastrService } from 'ngx-toastr';
@Injectable({
    providedIn: 'root'  // **** ADD THIS LINE ****
  })
export class VehicleOptionsResolver implements Resolve<VehicleOptions[]> {
    constructor( private vehicleOptionsService: VehicleoptionsService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<VehicleOptions[]> {
        // tslint:disable-next-line:no-string-literal
        return this.vehicleOptionsService.getOptions(route.params['parentGuid'], route.params['itemId']).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving vehicle options list.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
