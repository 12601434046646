import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SaleTypeService } from '../_services/saleservices/sale-types.service';
import { SaleType } from '../_models/saletype';
import { SaleStatus } from '../_models/salestatus';
import { SaleStatusService } from '../_services/saleservices/sale-status.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
    providedIn: 'root'
  })
export class SaleStatusResolver implements Resolve<SaleStatus[]> {
    constructor(private reppo: SaleStatusService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<SaleStatus[]> {
      //  console.log(route.params['parentGuid']);
        // tslint:disable-next-line:no-string-literal
        return this.reppo.getActiveStatuses(route.params['parentGuid']).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving invoice statuses.');
            //    this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
