import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { datepickerAnimation } from 'ngx-bootstrap/datepicker/datepicker-animations';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';

import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';
import { QbInvoiceDefault } from 'src/app/_models/DTOS/quickbooksdtos/QbInvoiceDefaultDto';
import { QuickBooksAccounts } from 'src/app/_models/DTOS/quickbooksdtos/QuickBooksAccounts';
@Component({
  selector: 'app-qbdefaults-modal',
  templateUrl: './qbdefaults-modal.component.html',
  styleUrls: ['./qbdefaults-modal.component.css']
})
export class QbdefaultsModalComponent implements OnInit {
  @Output() emittedValues = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  qbInvoiceDefault = {} as QbInvoiceDefault;
  dollar = {} as Dollars;
  dollarTypes: QuickBooksAccounts[];
  qbAccount = {} as QuickBooksAccounts;
  inventoriedAssetAccounts  = [] as any[];
  qbExpenseAccounts = [] as any[];
  selectedDollarType: any;
  selectedQuickBookAccount: any;
  accountType = [
    {id: 1, value: 'Inventory'},
    {id: 2, value: 'Expense'},
    {id: 3, value: 'Income'},
    {id: 4, value: 'Discount'},
    ];
  constructor(public bsModalRef: BsModalRef, private format: DealInforFormat) {
  }

  ngOnInit() {
    console.log(this.inventoriedAssetAccounts);
  }

  accountTypeChange(e) {
  //  this.qbInvoiceDefault.qbAccountType = e.name;
  //  this.qbInvoiceDefault.qbAccountId = e.id;
    console.log(this.qbInvoiceDefault);
  }
  update() {
    console.log(this.dollar);
    this.emittedValues.emit(this.qbInvoiceDefault);
    this.bsModalRef.hide();
  }
  selectedAccountChanged(e: any) {
    console.log(e);
    this.qbAccount = e;
    // this.qbInvoiceDefault.qbAccountName = e.name;
    // this.qbInvoiceDefault.qbAccountId = e.id;
    console.log(this.qbInvoiceDefault );
    console.log(this.qbExpenseAccounts);
  }
  selectChange(e) {

  }
}

