import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PdfNewfieldnameComponent } from 'src/app/modals/pdf-newfieldname/pdf-newfieldname.component';
import { PdfFieldLocationClass } from 'src/app/_classes/pdffielddatats/pdffield-location';
import { PdfFormCalculationNoIdDto } from 'src/app/_models/DTOS/PdfFormCalculationNoIdDto';
import { Field } from 'src/app/_models/Field';
import { PdfFIeldName } from 'src/app/_models/PdfFiledName';
import { PdfForms } from 'src/app/_models/PdfForms';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';

@Component({
  selector: 'app-calculator-pdf',
  templateUrl: './calculator-pdf.component.html',
  styleUrls: ['./calculator-pdf.component.scss']
})
export class CalculatorPdfComponent implements OnInit {
  constructor(private pdfService: PdfService,
    private alertify: ToastrService,
    private bsModalService: BsModalService) { }
  @Input() emittedValues = new EventEmitter();
  pdfForm = {} as PdfForms;
  field: any;
  str = '' as string;
  textAreaValue = {} as PdfFIeldName;
  @Input() customName = '' as string;
  pdfFieldName = {} as PdfFIeldName;
  fields = [] as Field[];
  fieldIndex = 0 as number;
  isNumberical = false;
  isCalculator = true;
  isLeft = false;
  isRight = false;
  isCenter = false;
  isSaved = false;
  isActive = false;
  isDelete = true;
  bsModalRef: any;
  ngOnInit() {
    this.isSaved = false;
    this.pdfService.currentpdfForm.subscribe(data => { this.pdfForm = data; });
    this.subscribeToPdfFieldName();
  // this.createNewField();
  this.isSaved = false;
  }
  createNewField() {
    this.bsModalRef =  this.bsModalService.show(PdfNewfieldnameComponent);
    this.bsModalRef.content.emittedValues.subscribe(data => {
      this.isActive = true;
      this.setFalse();
      this.textAreaValue = new PdfFIeldName();
      this.textAreaValue.id = null;
      this.textAreaValue.nameFriendly = data;
      this.textAreaValue.calculation = '';
      this.textAreaValue.align = 0;
      this.textAreaValue.height = 14;
      this.textAreaValue.width = 50;
      this.isLeft = true;
    });
  }
  formTypeSelected(e) {
  }
  save() {
    this.isSaved = true;
    // this stops the ` from b eing added to the textArea dom
   const textAreacalculation = '`' + this.textAreaValue.calculation + '`';
   const textAreaV = this.textAreaValue;
   textAreaV.calculation = textAreacalculation;
    // this.textAreaValue.nameFriendly = this.textAreaValue.nameFriendly;
    // this.textAreaValue.calculation = this.textAreaValue.calculation;
    console.log(this.textAreaValue.id);
      if (this.textAreaValue.id === null) {
        this.pdfService.createPdfFieldName(textAreaV).subscribe( data => {
          this.pdfService.setPdfFieldNameType(data); console.log(data);
          this.alertify.success('Caclulation Saved');
          this.isSaved = false;
      }, error => { this.alertify.error(error); });
    } else {
      this.pdfService.updatePdfFieldName(textAreaV).subscribe(data => {
        this.pdfService.setPdfFieldNameType(data);  console.log(data);
        this.alertify.success('Caclulation Updated');
        this.isSaved = false;
      }, error => { this.alertify.error(error); });
    }
 
    // this.pdfService.postPdfFormCalculation(entity, this.pdfForm.id).subscribe(data => {
    //   this.pdfService.changepdfFieldCstCalculation(data);
    //   this.alertify.success('Caclulation Saved');
    // }, error => { this.alertify.error('Error on calculations.'); });
    console.log(this.textAreaValue);
  }
  fresTextAreaValue() {
    this.setFalse();
    this.textAreaValue = new PdfFIeldName();
    this.textAreaValue.id = null;
    this.textAreaValue.nameFriendly = '';
    this.textAreaValue.calculation = '';
    this.textAreaValue.align = 0;
    this.textAreaValue.height = 14;
    this.textAreaValue.width = 50;
    this.isLeft = true;
  }
  deleteFieldName() {
    this.isDelete = false;
    this.pdfService.deletePdfFieldName(this.textAreaValue.id).subscribe(data => {
      this.pdfService.setPdfFieldNameType(data); console.log(data);
      this.alertify.success('Caclulation Marked Deleted');
      this.isActive = false;
      this.isDelete = true;
     this.fresTextAreaValue();
    }, error => { this.alertify.error(error); });
  }
  manualChange(event) {
    console.log(event);
  }
  addComma() {
   const e = ' ' + ' + ' + '", "' + ' + ';
    this.str = this.textAreaValue.calculation + ' ';
    this.textAreaValue.calculation = this.str + ' ' +  e ;
  }
  addSpace() {
    this.str = this.textAreaValue.calculation + ' ';
    this.textAreaValue.calculation = this.str + ' ' ;
  }
  doMath(e: string) {
      this.str =  this.textAreaValue.calculation;
      if (e.length > 1) {
        this.textAreaValue.calculation = this.str + ' ' +  ' ${' + e + '}';
      } else {
        this.textAreaValue.calculation = this.str + ' ' + e;
      }
    
  }
  cancelNewField() {
    this.isActive = false;
    this.setFalse();
    this.fresTextAreaValue();
  }
  clear() {
    this.textAreaValue.calculation = '';
  }
  update() {
 //  this.emittedValues.emit(this.field);
  }
  onChange(e) {}
  subscribeToPdfFieldName() {
    this.pdfService.getIsCalculator().subscribe(data => { this.isCalculator = data; });
    this.pdfService.getCurrentPdfFieldName().subscribe((data) => {
      if (this.isCalculator) {
      if (data !== null && data !== undefined) {
        const nameFirstThree = data.value.substring(0, 3); // 'cst'
        this.pdfFieldName = data;
        if (nameFirstThree === 'cst') {
          this.setFalse();
          if (data.align === 0) { this.isLeft = true; }
          if (data.align === 1) { this.isCenter = true; }
          if (data.align === 2) { this.isRight = true; }
          this.textAreaValue = data;
          this.textAreaValue.calculation = data.calculation.replace('`', '');
          this.textAreaValue.calculation = data.calculation.replace('`', '');
          this.textAreaValue.calculation = data.calculation.replace('`', '');
          this.textAreaValue.calculation = data.calculation.replace('`', '');
          this.isActive = true;
        } else {
          this.textAreaValue.calculation = this.textAreaValue.calculation  +  ' ${' + data.value + ' }';
         // this.textAreaValue.calculation =  this.textAreaValue.calculation  + data.value;
        }
        console.log(data);
      }
    }
    });
  }
  setFalse() {
    this.isLeft = false;
    this.isRight = false;
    this.isCenter = false;
  }
  alignLRCCtrlClick(alignment?: any) {
    this.setFalse();
    if (alignment === 'left' ) { this.textAreaValue.align = 0; this.isLeft = true; }
    if (alignment === 'center') { this.textAreaValue.align  = 1; this.isCenter = true; }
    if (alignment === 'right') {this.textAreaValue.align  = 2; this.isRight = true; }
    console.log(this.textAreaValue);
  }
}
