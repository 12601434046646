import { Injectable } from '@angular/core';
declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {
  constructor() {}

  confirm(title: string, message: string, okCallback: () => any) {
    alertify.defaults.glossary.title = title;
    // tslint:disable-next-line:only-arrow-functions
    alertify.confirm(  message, function(e) {
      if (e) {
        okCallback();
      } else {}
    });
  }
  comfirm2() {
  // tslint:disable-next-line:only-arrow-functions
  alertify.confirm('Confirm Title', 'Confirm Message', function(){ alertify.success('Ok'); }
  // tslint:disable-next-line:only-arrow-functions
  , function() { alertify.error('Cancel'); } );
}
  success(message: string) {
    alertify.success(message);
  }

  error(message: string) {
    alertify.error(message);
  }

  warning(message: string) {
    alertify.warning(message);
  }

  message(message: string) {
    alertify.message(message);
  }
}
