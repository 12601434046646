<div class="customer-form-sec" style="width: 1200px">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12 " style="width: 1000px;">
        <div id="customer" class="tab-pane fade in active show">
          <div class="modal-header form-sec tab-content">
            <h4 class="modal-title pull-left">Select Person</h4>
            
            <button
              type="button"
              class="close pull-right"
              aria-label="Close"
              (click)="bsModalRef.hide()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body " style="width: 1100px; ">
            <br><br>
            <div style="margin-top: -30px; margin-left: 500px !important; width: 1000px;" class="container-fluid customer-tabs-sec">
                <ul class="nav nav-tabs">
                  <li routerLinkActive="active">
                    <a
                      data-toggle="tab"  [ngClass]="{'active show' : isEmployee}" 
                      routerLinkActive="active show"
                      (click)="filter('Employee')"
                      >Employess</a
                    >
                  </li>
                  <li routerLinkActive="active">
                    <a
                      data-toggle="tab"  [ngClass]="{'active show' : isCustomer}" 
                      routerLinkActive="active show"
                      (click)="filter('Customer')"
                      >Customers</a
                    >
                  </li>
                  <li routerLinkActive="active">
                    <a [ngClass]="{'active show' : isBirthday}" 
                      data-toggle="tab"
                      routerLinkActive="active show"
                      (click)="filter('Birthday')"
                      >Birthday Near</a
                    >
                  </li>
                  <li routerLinkActive="active">
                    <a [ngClass]="{'active show' : isWebsiteLead}" 
                      data-toggle="tab"
                      routerLinkActive="active show"
                      (click)="filter('WebsiteLead')"
                      >Website Leads</a
                    >
                  </li>
                  <li routerLinkActive="active">
                    <a [ngClass]="{'active show' : isAll}" 
                      data-toggle="tab"
                      routerLinkActive="active show"
                      (click)="filter('All')"
                      >All</a
                    >
                  </li>  
                  <div class="d-flex justify-content-right">
                      <pagination
                    [boundaryLinks]="true"
                    pageBtnClass = "nav nav-tabs"
                        [(ngModel)]="pagination.currentPage"
                        [totalItems]="pagination.totalItems"
                        (pageChanged)="pageChanged($event)"
                        [itemsPerPage]="pagination.itemsPerPage"
                        previousText="&lsaquo;"
                        nextText="&rsaquo;"
                        firstText="&laquo;"
                        lastText="&raquo;"
                      >
                      </pagination>
                    </div>
              
              </ul>  
              </div>
              <!-- <app-date-filter *ngIf = "isFilterDates"></app-date-filter> -->
              <div class="customer-form-sec">
                <div class="container-fluid">
                  <div class="row">
                    <div class="form-sec tab-content col-md-11">
                      <div id="depreciate-costs" class="tab-pane fade in active show">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="inner-row account-payable-row">          
                              
                              <div class="row">
                                <div class="col-md-12 table-sec">
                                  <div id="printableTable">
                                  <table border="1" borderColor="#ccc">
                                    <tr>
                                      <th>ID</th>
                                      <th (click)="sort('lastName')">LastName &nbsp;
                                          <i [ngClass]="isLastNameAscending ? 'up': 'down'"></i>
                                      </th>
                                      <th (click)="sort('firstName')">FirstName&nbsp;
                                          <i [ngClass]="isFirstNameAscending ? 'up': 'down'"></i>
                                      </th>
                                      <th (click)="sort('phone')">Phone&nbsp;</th>
                                     
                                      <th (click)="sort('email')">Email&nbsp;
                                          <i [ngClass]="isEmailAscending ? 'up': 'down'"></i>
                                      </th>
                                      <th (click)="sort('personType')">Person Type&nbsp;
                                          <i [ngClass]="isCustomerAscending ? 'up': 'down'"></i>
                                      </th>
                                   
                                      <th></th>
                                    </tr>
                                    <tr  >
                                      <td></td>
                                      <td style="width: 76px"><input (keyup)="setSearchBy('searchLastName', $event.target.value)" >
                                      <td style="width: 76px"><input (keyup)="setSearchBy('searchFirstName', $event.target.value)" >
                                      </td>  
                                      <td><input style="width: 55px;" (keyup)="setSearchBy('searchPhone', $event.target.value)">
                                      </td>  
                                     
                                      <td><button class="btn-success" >Filter</button></td>  
                                      <td><input style="width: 96px;" (keyup)="setSearchBy('searchEmail', $event.target.value)"></td>  
                                      <td></td>  
                         
                                    </tr>
                                    <tr class="td-row" *ngFor="let item of persons">
                                      <td>{{ item.id }}</td>
                                      <td>{{ item?.lastName }}</td>
                                      <td>{{ item?.firstName }}</td>
                                      <td>{{ item?.phone }}</td>
                                      <td>{{ item?.email }}</td>
                                      <td>{{item?.personType}}</td>
                                      <td>
                                        <button
                                          class="btn btn-outline-success"
                                          (click)="selectPerson(item)"
                                        >
                                          Select
                                        </button>
                                      </td>
                                    </tr>
                                  </table>
                                  </div>
                                </div>
                                <!-- /.table-sec -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- /.row -->
                      </div>
                    </div>
                 
                  </div>
                </div>
              </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default"
              (click)="bsModalRef.hide()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

