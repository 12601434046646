import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddRepairDto } from 'src/app/_models/DTOS/addrepairdto';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { QbInvoiceDefault } from 'src/app/_models/DTOS/quickbooksdtos/QbInvoiceDefaultDto';
import { RealPropertyLinqListDto } from 'src/app/_models/DTOS/realpropertylinqlistdto';
import { RealPropertyRepairLinqDto } from 'src/app/_models/DTOS/RealPropertyRepairLinqDto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { Person } from 'src/app/_models/person';
import { RealProperty } from 'src/app/_models/RealProperty';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class QbDefaultsService {
    constructor(private http: HttpClient) { }
    baseUrl = environment.apiUrl;

    getDefaultValues(parentGuid: string): Observable<QbInvoiceDefault[]> {
      return this.http.get<QbInvoiceDefault[]>(this.baseUrl +
        'qbsales/getdefaultvalues/' + parentGuid);
   }
    getExpenseCogsAccounts(parentGuid: string, realmId: string): Observable<any> {
      return this.http.get<any>(this.baseUrl +
        'qbsales/getqbexpenseaccounts/' + parentGuid + '/' + realmId);
   }
   getInventoriedAssetAccounts(parentGuid: string, realmId: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      'qbsales/getqbinventoriedassetaccounts/' + parentGuid + '/' + realmId);
 }

  }
