import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AccountService } from '../../_services/account.service';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PdfStateList } from '../../_models/PDFentites/PdfStateList';
import { PdfService } from '../../_services/PdfSErvices/pdf.service';
import { PatternValidate } from './patternValidator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @Output() cancelRegister = new EventEmitter();
  registerForm: FormGroup;
  maxDate: Date;
  stateList = [] as PdfStateList[];
  validationErrors: string[] = [];
  id: any;
  constructor(private accountService: AccountService, private toastr: ToastrService,
    private fb: FormBuilder, private router: Router,
    private pdfService: PdfService,
    private patternValidate: PatternValidate) { }

  ngOnInit(): void {
    this.intitializeForm();
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    this.pdfService.getPdfMasterStateList().subscribe(data => {
      this.stateList = data;
    });
  }

  intitializeForm() {
    this.registerForm = this.fb.group({
      stateFormId:  ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      zip: ['', Validators.required],
      password: ['', [Validators.required,
        Validators.minLength(6), Validators.maxLength(11),
        this.patternValidate.patternValidator(/\d/, { hasNumber: true }),
        this.patternValidate.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        this.patternValidate.patternValidator(/[a-z]/, { hasSmallCase: true }),
        this.patternValidate.patternValidator(/[^A-Za-z0-9]/, { hasSpecialCharacters: true }),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{6,}') ]  ],
      confirmPassword: ['', [Validators.required, this.matchValues('password')]]
    });
  }
  // [^A-Za-z0-9||@||\.||_]
  matchValues(matchTo: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control?.value === control?.parent?.controls[matchTo].value
        ? null : {isMatching: true};
    };
  }
  stateSelected(e) {
    console.log(e);
    console.log(this.registerForm.value);
  }
  register() {
    console.log(this.registerForm.value);
    this.accountService.registerNewUser(this.registerForm.value)
    .subscribe(response => {
      this.router.navigateByUrl('/emailsent/emailcomfirm');
    }, error => {
      this.validationErrors = error;
    });
  }

  cancel() {
    this.cancelRegister.emit(false);
  }

}
