import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PdfMergePdfs } from 'src/app/_classes/pdffielddatats/merge-pdfdocuments';
import { PdfFormsPack } from 'src/app/_models/PdfFormPack';
import { PdfFormPackage } from 'src/app/_models/pdfformpackage';
import { PdfForms } from 'src/app/_models/PdfForms';
import { Sale } from 'src/app/_models/sale';
import { AuthService } from 'src/app/_services/auth.service';
import { PdfPackageService } from 'src/app/_services/PdfSErvices/pdf-package.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import printJS from 'print-js';
import { of, Observable } from 'rxjs';
import { Item } from 'src/app/_models/item';
@Component({
  selector: 'app-print-popup',
  templateUrl: './print-popup.component.html',
  styleUrls: ['./print-popup.component.css']
})
export class PrintPopupComponent implements OnInit {
  @Input() salePackages = [] as  PdfFormPackage[];
  @Input() salePackage: PdfFormPackage;
  @Input() inventoryPackages = [] as PdfFormPackage[];
  @Input() isPrintButtonPackage = false;
  @Input() buttonToPrint = '' as string;
  @Input() sale = {} as Sale;
  @Input() item = {} as Item;
  isWithData = true;
  disablePrint = false;
  isEmptyForm = false;
  list = [];
  urLList = [];
  base64: any;
  uint8array: any;
  pdfFormPack = [] as PdfFormsPack[];
  selectedPdfFormPack: PdfFormPackage;
  packageName = '';
  pdfToAddToPackage: PdfForms;
  constructor(private pdfService: PdfService,
    private pdfMergePdfs: PdfMergePdfs,
     private authService: AuthService,
     private pdfPackageService: PdfPackageService,
     private alertify: ToastrService,
     public bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log(this.packageName);
    //// MUST LOAD PDFFIELDNAME TYPES!!!!!!!!!!!!!!!!!!!!!!!!!!
    
    this.pdfService.changeIsEmpty(false);
    this.pdfService.changeIsWithData(true);
    if (this.salePackage === undefined) {
      // for (let i = 0; i < this.salePackages.length; ++i) {
      //  if (this.salePackages[i].defaultPack === true) {
      //    this.salePackage = this.salePackages[i];
      //    this.fillPacckage(this.salePackage);
      //  }
      //  }
        this.salePackages.forEach(element => {
          this.salePackage = element;    
         this.fillPacckage(element);
        });
        console.log(this.salePackage);
        this.salePackages.forEach(element => {
          if (element.packageName === this.packageName) {
            this.salePackage = element;
            this.fillPacckage(element);
          }
        });

        console.log(this.salePackage);
    }
  }
 async fillPacckage(package1: PdfFormPackage) {
    this.pdfFormPack = package1.pdfFormsPacks;
    this.selectedPdfFormPack = package1;

    this.urLList = [];
  if (package1?.pdfFormsPacks?.length > 1) {
    for await (const i of package1.pdfFormsPacks) {
      this.urLList.push({url: i.pdfForm.pdfFormUrl});
  }
 }
 // TEST IS IT LOADING THIS DATA FIRST?

  console.log(this.sale);
  console.log(this.urLList);
  
  this.pdfMergePdfs.copyPages(this.sale, package1.pdfFormsPacks[0].pdfForm.pdfFormUrl, this.urLList, this.isWithData, this.isEmptyForm)
  .then(data => { this.urLList = [];  this.alertify.success('Pdfs Populated.'); 
}, error => {console.log(error); }
  );

 
 console.log('FOO notloaded');



  }


  PrintPdf2(e) {
    this.pdfService.getBase64Array().subscribe(data1 => {
      this.base64 = data1;
    ///  console.log(data1);
     
      if(data1!=null){
        printJS({
          printable: data1, 
          type: 'pdf', 
  
        base64: true,
      });
      }
     
     }, err => { console.log(err); });

  }
  //        showModal: true,
  emptyPdf() {
    this.pdfService.changeIsWithData(false);
    this.pdfService.changeIsEmpty(true);
    this.pdfMergePdfs.copyPages(this.sale, this.salePackage.pdfFormsPacks[0].pdfForm.pdfFormUrl, this.urLList, false, true)
    .then(data => { this.urLList = [];  this.alertify.success('Pdfs Populated.'); });
  }
  dataPdf() {
    this.pdfService.changeIsWithData(true);
    this.pdfService.changeIsEmpty(false);
    this.pdfMergePdfs.copyPages(this.sale, this.salePackage.pdfFormsPacks[0].pdfForm.pdfFormUrl, this.urLList, true, false)
    .then(data => { this.urLList = [];  this.alertify.success('Pdfs Populated.'); });
  }
  downloadPdf(e) {
  this.pdfService.getUint8ByteArray().subscribe(data => {
    this.uint8array = data;
   });
   if(this.uint8array != null) {
    const blob = new Blob([this.uint8array], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const blobURL = URL.createObjectURL(blob);
      const fileName =  'Pdf File Download'; //  this.selectPdf.name;
      link.download = fileName;
      link.click();
   }

  }

}
