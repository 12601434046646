import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { datepickerAnimation } from 'ngx-bootstrap/datepicker/datepicker-animations';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';
import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';

@Component({
  selector: 'app-edit-vehicle-repair-modal',
  templateUrl: './edit-vehicle-repair-modal.component.html',
  styleUrls: ['./edit-vehicle-repair-modal.component.css']
})
export class EditVehicleRepairModalComponent implements OnInit {
  @Output() emittedValues = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  dollar = {} as Dollars;
  dollarTypes: DollarTypes[];
  selectedDollarType: any;
  constructor(public bsModalRef: BsModalRef, private format: DealInforFormat) {
    this.validateDate();
  }

  ngOnInit() {
    this.dollar.isPaid = true;
    this.selectedDollarType = this.dollar.dollarTypeId;
  }
  validateDate() {
    if (!this.dollar.dateOfTransaction) {
      this.dollar.dateOfTransaction = new Date();
    }
    if (this.dollar.credit === null || this.dollar.credit === undefined ) { this.dollar.credit = Number(this.format.fnz02(0)) ;
    } else {
      this.dollar.credit = Number(this.format.fnz02(this.dollar.credit)) ;
    }
  }
  dollarTypeChanged(e) {
    this.dollar.dollarTypeId = Number(e);
    // console.log(e);
  }
  update() {
   // console.log(this.dollar);
    this.emittedValues.emit(this.dollar);
    this.bsModalRef.hide();
  }

  selectChange(e) {

  }
}

