import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddRepairModalComponent } from 'src/app/modals/add-repair-modal/add-repair-modal.component';
import { EditVehicleRepairModalComponent } from 'src/app/modals/edit-vehicle-repair-modal/edit-vehicle-repair-modal.component';
import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';
import { Item } from 'src/app/_models/Item';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';

import { AuthService } from 'src/app/_services/auth.service';
import { DollarService } from 'src/app/_services/dollarservices/dollar.service';
import { ListService } from 'src/app/_services/listServices/list.service';

@Component({
  selector: 'app-inventory-repair',
  templateUrl: './inventory-repair.component.html',
  styleUrls: ['./inventory-repair.component.css']
})
export class InventoryRepairComponent implements OnInit, OnChanges {
@Input() item: Item;
@Input() dollars: Dollars[];
@Input() dollarTypes: DollarTypes[];
bsModalRef: any;
selectedCompany1: UserAssignedToCompany;
dollar = {} as Dollars;
  constructor(private listService: ListService,
     private modalService: BsModalService,
     private dollarService: DollarService,
     private authService: AuthService,
     private alertify: ToastrService) {
  }
  // (904) 422-6046 live in? 20
  ngOnInit() {
    this.authService.company.subscribe(data =>  { this.selectedCompany1 = data; });
  }
  ngOnChanges() {
    this.listService.currentItemDollars.subscribe(data => this.dollars = data );
    this.listService.currentItemDollarTypes.subscribe(data => this.dollarTypes = data);
  }
  addRepair() {
    const initialState = {
      dollarTypes:  this.dollarTypes
    };
   this.bsModalRef = this.modalService.show(AddRepairModalComponent, {initialState});
   this.bsModalRef.content.emittedValues.subscribe((data) => {
     this.dollar = data;
     this.dollarService.postNewIemRepair(this.selectedCompany1.parentGuid, data, this.item.id).subscribe((value) => {
       this.dollars = value.dollars;
       this.listService.changeItemVehicleEdit(value.item);
     }, error => { this.alertify.error(error); }, () => {
       this.alertify.success('Cost Added to veicle');
     });
     console.log(data);
   });
  }

  editRepair(item) {
    const initialState = {
      dollarTypes:  this.dollarTypes,
      dollar: item
    };
   this.bsModalRef = this.modalService.show(EditVehicleRepairModalComponent, {initialState});
   this.bsModalRef.content.emittedValues.subscribe((data) => {
     this.dollar = data;
     console.log(data);
     console.log(this.item.id);
     console.log(this.selectedCompany1.parentGuid);
     this.dollarService.editNewIemRepair(this.selectedCompany1.parentGuid, data, this.item.id).subscribe((value) => {
       this.dollars = value.dollars;
       this.listService.changeItemVehicleEdit(value.item);
     }, error => { this.alertify.error(error); }, () => {
       this.alertify.success('Cost Added to veicle');
     });
    //  console.log(data);
   });
  }
}
