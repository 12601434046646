import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddRepairDto } from 'src/app/_models/DTOS/addrepairdto';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { RealPropertyLinqListDto } from 'src/app/_models/DTOS/realpropertylinqlistdto';
import { RealPropertyRepairLinqDto } from 'src/app/_models/DTOS/RealPropertyRepairLinqDto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { Person } from 'src/app/_models/person';
import { RealProperty } from 'src/app/_models/RealProperty';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class QbSaleService {
    constructor(private http: HttpClient) { }
    baseUrl = environment.apiUrl;

    login(parentGuid: string): Observable<any> {
       return this.http.get<any>(this.baseUrl + 'quickbooks/login/' + parentGuid);
    }
    createSale(saleid: number, realmId: string): Observable<any> {
      return this.http.get<any>(this.baseUrl +
        'qbsales/addsalequickbooks/'  + saleid  + '/' + realmId);
   }

    connect(code, realmId, parentGuid: string): Observable<any> {
        return this.http.get<any>(this.baseUrl + 'quickbooks/getrealmid/'
      + code + '/' + realmId + '/' + parentGuid);
     }
  }
