import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { createPDFAcroField } from 'pdf-lib';
import { NewNameModel } from 'src/app/forms/pdf-grid/pdfnewuploadnamemodalmodel';
import { PackageButtonLocations } from 'src/app/_models/PackageButtonLocations';
import { PdfFormTypesCategory } from 'src/app/_models/PDFentites/PdfFormTypesCategory';
import { PdfForms } from 'src/app/_models/PdfForms';
import { PdfFormTypes } from 'src/app/_models/PdfFormTypes';
import { AuthService } from 'src/app/_services/auth.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';

@Component({
  selector: 'app-pdfnewuploadname-modal',
  templateUrl: './pdfnewuploadname-modal.component.html',
  styleUrls: ['./pdfnewuploadname-modal.component.css']
})
export class PdfnewuploadnameModalComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef,
    private pdfService: PdfService, private authService: AuthService) { }
  @Input() emittedValues = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() packageButtonLocations = [] as PackageButtonLocations[];
  @Input() pdfForm = {} as PdfForms;
  @Input() parentGuid: string;
  selectedType = {} as PdfFormTypes;
  @Input() pdfFormTypes = [] as PdfFormTypes[];
  @Input() pdfFormTypesCategorys = [] as PdfFormTypesCategory[];
  pdfFormTypesCategory = {} as PdfFormTypesCategory;
  isUseUniqueName = false;
  newNameModel = {} as NewNameModel;
  ngOnInit() {
    // console.log(this.selectedType);
    // console.log(this.packageButtonLocations);
    // if (this.selectedType) {
    //   this.pdfForm.pdfFormTypeId = this.selectedType.id;
    // }
    this.authService.company.subscribe(data => { this.parentGuid = data.parentGuid; });
  }
  onNgModelChange(e) { // here e is a boolean, true if checked, otherwise false
    if (e) {
      this.isUseUniqueName = true;
      this.newNameModel.isUseUniqueFileName = true;
    } else { this.isUseUniqueName = false; this.newNameModel.isUseUniqueFileName = false; }
  }

  updateGap() {
    this.newNameModel.name = this.pdfForm.name;
   console.log(this.pdfForm);
   this.emittedValues.emit(this.newNameModel);
   this.bsModalRef.hide();
  }
  onChange(e) {}

}
