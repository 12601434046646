
<br><br>
<button (click)="createDefaultAcount()">CreateDefaultaccount</button>
<button (click)="createDefaultItems()">CreateDefault Items</button>
<div  style="width: 90% !important; position: fixed;"> 
<div class="col-md-12">
  <div class="row">
      <div class="col-md-2 form-sec tab-content" >
       <app-qb-navlist></app-qb-navlist>
    </div>  
   
      <div class="form-sec tab-content col-md-9" >
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-12">
              <div class="inner-row account-payable-row" style="overflow: hidden;">
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <h2>Qb Invoice Defaults</h2>
                    <table border="1" borderColor="#ccc">
                      <tr>
                        <th width="4%">Id</th>
                        <th width="20%">Income Account</th>
                        <th width="20%">Expense Account</th>
                        <th width="20%">Asset Account</th>
                        <th width="20%">Deal Info Field</th>
                        <th width="10%">Edit</th>
                      </tr>
                    </table>
                    <div style="height:580px; overflow:auto;">
                    <table border="1" borderColor="#ccc" style=" height:480px; ">
                     
                      <tr class="td-row" *ngFor="let item of qbDefaults">
                            <td align ="left" width="4%">{{item.id}}</td>
                            <td align ="left"  width="20%">{{item?.incomeAccountName}}</td>
                            <td align ="left"  width="20%">{{item?.expenseAccountName}}</td>
                            <td align ="left"  width="20%">{{item?.assetAccountName}}</td>
                            <td align ="left"  width="20%">{{item?.dealInfoSaleItemName}}</td>
                            <td width="10%">
                                <a
                                class="btn btn-warning"
                                (click)="editStatus(item)"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit Sales Tax Setting"
                                ><i class="fa fa-fw fa-files-o"></i
                              ></a>
                            </td>
                      </tr>
                      <tr>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                        <td> </td>
                      </tr>
                    </table>
                    </div>
                  </div>
                  <!-- /.table-sec -->
                </div>
              </div>
            </div>
          </div>
          <!-- /.row -->
        </div>
      
    </div>
      <div style="padding-left: 90px;" class="customer-sidebar sidebar-with-btns col-md-1">
          <div class="inner-sidebar">
              <ul  style="list-style-type:none;">
                  <li>
                      <button  (click)="add()" class="cstm-btn img-blk-btn">
                        <img src="assets/images/ap-icon-1.png">
                        <span>Add New</span>
                      </button>
                    </li>

              </ul>
            </div>
        </div>
    </div>
  </div>

</div>