import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Dollars } from 'src/app/_models/dollars';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AccountingService } from 'src/app/_services/accountingservices/accounting.service';
import { AuthService } from 'src/app/_services/auth.service';
import { DollarService } from 'src/app/_services/dollarservices/dollar.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';

@Component({
  selector: 'app-general-ledger',
  templateUrl: './general-ledger.component.html',
  styleUrls: ['./general-ledger.component.css']
})
export class GeneralLedgerComponent implements OnInit {
dollars = [] as Dollars[];
dollar = {} as Dollars;
bsModalRef: any;
dateTime = new Date();
selectedCompany1: UserAssignedToCompany;
constructor(private modalService: BsModalService,
  private alertify: ToastrService,
  private accountingService: AccountingService, private authService: AuthService,
  private dollarService: DollarService ) { }
  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
    this.accountingService.retrievePagedRecievables(this.selectedCompany1.parentGuid, 1, 1000).subscribe(data => {
      console.log(data);
      this.dollars = data.result;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Recieved');
    });
  }
  editItem(e) {

  }
}
