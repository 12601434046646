<form #editForm="ngForm" id="editForm" (ngSubmit)="editForm.valid && register()" autocomplete="off">
  <h2 class="text-center text-primary">Reset password</h2>
  <hr>

  <div class="form-inline">
    <label for="city">Old Password</label>
    <input [(ngModel)]="user.password"   type="text" name="password" class="form-control mx-2">

    <label for="city">New password</label>
    <input
    [ngModel]="user?.newPassword"
    (ngModel)="user.newPassword = $event"
    (ngModelChange) = "user.newPassword = $event; "
    type="text" name="newPassword" class="form-control mx-2">
    </div>

 <div class="form-inline">
    <label for="city">Comfirm New Password </label>
    <input 
    [ngModel]="user?.comfirmPassword"
    (ngModel)="user.comfirmPassword = $event"
    (ngModelChange) = "user.comfirmPassword = $event; "
    type="text" name="comfirmPassword" 
    class="form-control mx-2">
  </div>

  <div class="form-group text-center">
      <button [disabled]='!editForm.valid' class="btn btn-success mr-2" type="submit">Reset Password</button>
  </div>
</form>