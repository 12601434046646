import { PipeTransform, Pipe } from '@angular/core';


    @Pipe({name: 'HumanizePipes'})


 export class HumanizePipe implements PipeTransform {
 transform(value: string) {
 // tslint:disable-next-line:curly
 if ((typeof value) !== 'string') {
 return value;
 }
 value = value.split(/(?=[A-Z])/).join(' ');
 value = value[0].toUpperCase() + value.slice(1);
 return value;
 }
}
