<div class="form-sec tab-content col-md-12">
  <div  class="card" style="background-color:#DBE9F4 !important;">

     <div class="col-sm-12">
         <div *ngIf="editForm.dirty" class="alert alert-info">
           <strong>Information:</strong> You have made changes.  Any unsaved changes will be lost!
         </div>
       </div>
    <form  #editForm="ngForm" id="editForm">
      <div class="row"> 
      <div class="col-8">  
           <div class="row">
       <!--FIRST NAME-->
             <div class="col-sm">
               <div class="form-group" [ngClass]="{
                 'has-success': firstName?.valid && (firstName?.touched  || firstName?.dirty),
                 'has-danger': firstName?.invalid && (firstName?.touched  || firstName?.dirty)
             }">
                 <label>First Name</label>
                 <div class="input-group">
                 <input type="text" class="form-control" [ngModel]="person?.firstName" (ngModel)="person.firstName=$event" name="firstName"
                   #firstName="ngModel" required>
                   <div *ngIf="!firstName.errors && (firstName?.touched  || firstName?.dirty)" class="input-group-prepend">
                       <button type="button" class="btn btn-outline-success">
                           <i class="fa fa-check" aria-hidden="true"></i>
                         </button>
                     </div>
                   </div>
                   <div class="form-group-feedback" *ngIf="firstName.errors && (firstName?.touched  || firstName?.dirty)">
                       <p style="color:red" *ngIf="firstName?.errors.required">First Name Is Required</p>
                     </div>
               </div>
             </div>   
             
             <!--last NAME-->
             <div class="col-sm">
                 <div class="form-group" [ngClass]="{
                   'has-success': lastName?.valid && (lastName?.touched  || lastName?.dirty),
                   'has-danger': lastName?.invalid && (lastName?.touched  || lastName?.dirty)
               }">
                   <label>Last Name</label>
                   <div class="input-group">
                   <input type="text" class="form-control" [ngModel]="person?.lastName" (ngModel)="person.lastName=$event" name="lastName"
                     #lastName="ngModel" required>
                     <div *ngIf="!lastName.errors && (lastName?.touched  || lastName?.dirty)" class="input-group-prepend">
                         <button type="button" class="btn btn-outline-success">
                             <i class="fa fa-check" aria-hidden="true"></i>
                           </button>
                       </div>
                     </div>
                     <div class="form-group-feedback" *ngIf="lastName.errors && (lastName?.touched  || lastName?.dirty)">
                         <p style="color:red" *ngIf="lastName?.errors.required">Last Name Is Required</p>
                       </div>
                 </div>
               </div>     
           </div>

           <div class="row">
              <!--PHONE-->
             <div class="col">
               <div class="form-group" [ngClass]="{
                                   'has-success': phone?.valid && (phone?.touched  || phone?.dirty),
                                   'has-danger': phone?.invalid && (phone?.touched  || phone?.dirty)
                               }">
                 <label>Phone</label>
                 <div class="input-group"> 
                 <input type="text" class="form-control" [ngModel]="person?.phone" (ngModel)="person.phone=$event" name="phone"
                   #phone="ngModel" required>
                   <div *ngIf="!phone.errors && (phone?.touched  || phone?.dirty)" class="input-group-prepend">
                       <button type="button" class="btn btn-outline-success">
                           <i class="fa fa-check" aria-hidden="true"></i>
                         </button>
                     </div>
                   </div>
                 <div class="form-group-feedback" *ngIf="phone.errors && (phone?.touched  || phone?.dirty)">
                   <p style="color:red" *ngIf="phone?.errors.required">Phone Is Required</p>
     
                 </div>
               </div>
             </div>
     <!--eMail-->
             <div class="col">
                 <div class="form-group" [ngClass]="{
                                     'has-success': email?.valid && (email?.touched  || email?.dirty),
                                     'has-danger': email?.invalid && (email?.touched  || email?.dirty)
                                 }">
                   <label>Email</label>
                   <div class="input-group"> 
                   <input type="text" class="form-control" [ngModel]="person?.email" (ngModel)="person.email=$event" name="email"
                     #email="ngModel" required>
                     <div *ngIf="!email.errors && (email?.touched  || email?.dirty)" class="input-group-prepend">
                         <button type="button" class="btn btn-outline-success">
                             <i class="fa fa-check" aria-hidden="true"></i>
                           </button>
                       </div>
                     </div>
                   <div class="form-group-feedback" *ngIf="email.errors && (email?.touched  || email?.dirty)">
                     <p style="color:red" *ngIf="email?.errors.required">Email Is Required</p>
       
                   </div>
                 </div>
               </div>
           </div>

           <fieldset ngModelGroup="addressDetail">
               <div class="col-md-4">
                   <div class="form-group" [ngClass]="{
                     'has-success': zip?.valid && (zip?.touched  || zip?.dirty),
                     'has-danger': zip?.invalid && (zip?.touched  || zip?.dirty)
                 }">
   <label>Zip</label>
   <div class="input-group">
   <input type="text" class="form-control" [ngModel]="person?.addressDetail?.zip" (ngModel)="person.addressDetail.zip=$event"
   name="zip" #zip="ngModel" minlength="2" required>
   <div *ngIf="!zip.errors && (zip?.touched  || zip?.dirty)" class="input-group-prepend">
   <button type="button" class="btn btn-outline-success">
       <i class="fa fa-check" aria-hidden="true"></i>
     </button>
   </div>
   </div>
   <div class="form-group-feedback" *ngIf="zip.errors && (zip?.touched  || zip?.dirty)">
   <p style="color:red" *ngIf="zip?.errors.required">Zip Is Required</p>
   <p style="color:red" *ngIf="zip?.errors.minlength">Zip requires at least 2 characters</p>
   </div>
                   </div>
                 </div>
               
             <div class="form-group" [ngClass]="{
                                       'has-success': street?.valid && (street?.touched  || street?.dirty),
                                       'has-danger': street?.invalid && (street?.touched  || street?.dirty)
                                   }">
               <label>Street</label>
               <div class="input-group">
               <input type="text" class="form-control" [ngModel]="person?.addressDetail?.street" (ngModel)="person.addressDetail.street=$event"
                 name="street" #street="ngModel" required>
                 <div *ngIf="!street.errors && (street?.touched  || street?.dirty)" class="input-group-prepend">
                     <button type="button" class="btn btn-outline-success">
                         <i class="fa fa-check" aria-hidden="true"></i>
                       </button>
                   </div>
                 </div>
               <div class="form-group-feedback" *ngIf="street.errors && (street?.touched  || street?.dirty)">
                 <p style="color:red" *ngIf="street?.errors.required">Street Is Required</p>
     
               </div>
             </div>
     
             <div class="row">
               <div class="col">
                 <div class="form-group" [ngClass]="{
                                       'has-success': street?.valid && (street?.touched  || street?.dirty),
                                       'has-danger': street?.invalid && (street?.touched  || street?.dirty)
                                   }">
                   <label>City</label>
                   <div class="input-group"> 
                   <input type="text" class="form-control" [ngModel]="person?.addressDetail?.city" (ngModel)="person.addressDetail.city=$event"
                     name="city" #city="ngModel" required>
                     <div *ngIf="!city.errors && (city?.touched  || city?.dirty)" class="input-group-prepend">
                         <button type="button" class="btn btn-outline-success">
                             <i class="fa fa-check" aria-hidden="true"></i>
                           </button>
                       </div>
                     </div>
                   <div class="form-group-feedback" *ngIf="city.errors && (city?.touched  || city?.dirty)">
                     <p style="color:red" *ngIf="city?.errors.required">City Is Required</p>
     
                   </div>
                 </div>
               </div>
               <div class="col">
                 <div class="form-group" [ngClass]="{
                                       'has-success': state?.valid && (state?.touched  || state?.dirty),
                                       'has-danger': state?.invalid && (state?.touched  || state?.dirty)
                                   }">
                   <label>State</label>
                   <div class="input-group">
                   <input type="text" class="form-control" [ngModel]="person?.addressDetail?.state" (ngModel)="person.addressDetail.state=$event"
                     name="state" #state="ngModel" required>
                     <div *ngIf="!state.errors && (state?.touched  || state?.dirty)" class="input-group-prepend">
                         <button type="button" class="btn btn-outline-success">
                             <i class="fa fa-check" aria-hidden="true"></i>
                           </button>
                       </div>
                     </div>
                   <div class="form-group-feedback" *ngIf="state.errors && (state?.touched  || state?.dirty)">
                     <p style="color:red" *ngIf="state?.errors.required">State Is Required</p>
     
                   </div>
                 </div>
               </div>
             </div>
             <div class="row">
              
               <div class="col">
                 <div class="form-group" [ngClass]="{
                                       'has-success': county?.valid && (county?.touched  || county?.dirty),
                                       'has-danger': county?.invalid && (county?.touched  || county?.dirty)
                                   }">
                   <label>County</label>
                   <div class="input-group"> 
                   <input type="text" class="form-control" [ngModel]="person?.addressDetail?.county" (ngModel)="person.addressDetail.county=$event"
                     name="county" #county="ngModel" required>
                     <div *ngIf="!county.errors && (county?.touched  || county?.dirty)" class="input-group-prepend">
                         <button type="button" class="btn btn-outline-success">
                             <i class="fa fa-check" aria-hidden="true"></i>
                           </button>
                       </div>
                     </div>
                   <div class="form-group-feedback" *ngIf="county.errors && (county?.touched  || county?.dirty)">
                     <p style="color:red" *ngIf="county?.errors.required">County Is Required</p>
                   </div>
                 </div>
               </div>
     
               <div class="col">
                 <!-- <div class="form-group" [ngClass]="{
                                       'has-success': country?.valid && (country?.touched  || country?.dirty),
                                       'has-danger': country?.invalid && (county?.touched  || country?.dirty)
                                   }">
                   <label>Country</label>
                   <div class="input-group"> 
                   <input type="text" class="form-control" [ngModel]="person?.addressDetail?.country" (ngModel)="person.addressDetail.country=$event"
                     name="country" #country="ngModel" required>
                     <div *ngIf="!country.errors && (country?.touched  || country?.dirty)" class="input-group-prepend">
                         <button type="button" class="btn btn-outline-success">
                             <i class="fa fa-check" aria-hidden="true"></i>
                           </button>
                       </div>
                     </div>
                   <div class="form-group-feedback" *ngIf="country.errors && (country?.touched  || country?.dirty)">
                     <p style="color:red" *ngIf="country?.errors.required">Country Is Required</p>
                   </div>
                 </div> -->
               </div>
             </div>
           </fieldset>
         </div>

         <div  class="col-4">
             <!-- More info -->
             <!--Middle Name-->
             <div class="col-sm">
                 <div class="form-group" [ngClass]="{
                   'has-success': middleName?.valid && (middleName?.touched  || middleName?.dirty),
                   'has-danger': middleName?.invalid && (lastName?.touched  || middleName?.dirty)
               }">
                   <label>Middle Name</label>
                   <div class="input-group">
                   <input type="text" class="form-control" [ngModel]="person?.middleName" (ngModel)="person.middleName=$event" name="middleName"
                     #middleName="ngModel" required>
                     <div *ngIf="!middleName.errors && (middleName?.touched  || middleName?.dirty)" class="input-group-prepend">
                         <button type="button" class="btn btn-outline-success">
                             <i class="fa fa-check" aria-hidden="true"></i>
                           </button>
                       </div>
                     </div>
                     <div class="form-group-feedback" *ngIf="middleName.errors && (middleName?.touched  || middleName?.dirty)">
                         <p style="color:red" *ngIf="middleName?.errors.required">Middle Name Is Required</p>
                       </div>
                 </div>
               </div>     
                        <!-- Date Of Birth -->
                       <div class="form-group" [ngClass]="{
                                            'has-success': dateOfBirth?.valid && (dateOfBirth?.touched  || dateOfBirth?.dirty),
                                            'has-danger': dateOfBirth?.invalid && (dateOfBirth?.touched  || dateOfBirth?.dirty)
                                        }">
                                                    <label>Date Of Birth</label>
                                                    <div class="input-group"> 
                                                    <input type="date" class="form-control" [ngModel]="person?.dateOfBirth | date:'yyyy-MM-dd'" (ngModel)="person.dateOfBirth=$event"
                                                    name="dateOfBirth" #dateOfBirth="ngModel" >
                                                    <div *ngIf="!dateOfBirth.errors && (dateOfBirth?.touched  || dateOfBirth?.dirty)" class="input-group-prepend">
                                                    <button type="button" class="btn btn-outline-success">
                                                    <i class="fa fa-check" aria-hidden="true"></i>
                                                    </button>
                                                    </div>
                                                    </div>
                                                    
                       </div>
                        <!-- INSURANCE Policy # -->
                        <div class="form-group" [ngClass]="{
                         'has-success': insurancePolicy?.valid && (insurancePolicy?.touched  || insurancePolicy?.dirty),
                         'has-danger': insurancePolicy?.invalid && (insurancePolicy?.touched  || insurancePolicy?.dirty)
                     }">
                                 <label>Insurance Policy #</label>
                                 <div class="input-group"> 
                                 <input type="text" class="form-control" [ngModel]="person?.insurancePolicy" (ngModel)="person.insurancePolicy=$event"
                                 name="insurancePolicy" #insurancePolicy="ngModel" >
                                 <div *ngIf="!insurancePolicy.errors && (insurancePolicy?.touched  || insurancePolicy?.dirty)" class="input-group-prepend">
                                 <button type="button" class="btn btn-outline-success">
                                 <i class="fa fa-check" aria-hidden="true"></i>
                                 </button>
                                 </div>
                                 </div>
                                 
                         </div>
                         <!--Insurance Expiration-->
                         <div class="form-group" [ngClass]="{
                           'has-success': insuranceExpires?.valid && (insuranceExpires?.touched  || insuranceExpires?.dirty),
                           'has-danger': insuranceExpires?.invalid && (insuranceExpires?.touched  || insuranceExpires?.dirty)
                       }">
                                   <label>Insurance Expiration </label>
                                   <div class="input-group"> 
                                   <input type="date" class="form-control" [ngModel]="person?.insuranceExpires | date:'yyyy-MM-dd'" (ngModel)="person.insuranceExpires=$event"
                                   name="insuranceExpires" #insuranceExpires="ngModel" >
                                   <div *ngIf="!insuranceExpires.errors && (insuranceExpires?.touched  || insuranceExpires?.dirty)" class="input-group-prepend">
                                   <button type="button" class="btn btn-outline-success">
                                   <i class="fa fa-check" aria-hidden="true"></i>
                                   </button>
                                   </div>
                                   </div> 
                           </div>
                <!-- end col 4--->                                
          </div>

 
 </div>
 </form>
 <button type="submit" (click) = "onSubmit()" class="cstm-btn img-blk-btn">
   <img src="assets/images/ap-icon-1.png">
   <span>Save</span>
 </button>
  </div>
 </div>
