import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { Person } from 'src/app/_models/person';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class PersonDataService {

    constructor(private http: HttpClient) { }
    baseUrl = environment.apiUrl;

      edit(parentGuid, person: Person): Observable<any>  {
        return this.http.put<any>(this.baseUrl + 'person/editperson/' + parentGuid, person);
      }
      add(parentGuid, person: Person): Observable<Person>  {
        return this.http.put<Person>(this.baseUrl + 'person/addwebsiteperson/' + parentGuid, person);
      }

      addInvoicedBuyerOrCoBUyer(invoiceid, buyercobuyer: string, parentGuid, person: Person): Observable<any>  {
        return this.http.put<any>(this.baseUrl + 'person/addnewcustomertoinvoice/' + invoiceid + '/' + buyercobuyer + '/' + parentGuid, person);
      }

      editInvoicedBuyerOrCoBUyerorReferences(invoiceid, buyercobuyerreference: string, parentGuid, person: Person): Observable<any>  {
        return this.http.put<any>(this.baseUrl + 'person/editpersonsinvoice/' + invoiceid + '/' + buyercobuyerreference + '/' + parentGuid, person);
      }
      getCustomers(parentGuid): Observable<Person[]>  {
        return this.http.get<Person[]>(this.baseUrl + 'person/getcustomersfordropdown/' + parentGuid);
      }
      getPerson(parentGuid, buyerid): Observable<Person> {
        return this.http.get<Person>(this.baseUrl + 'person/getcustomer/'  + buyerid);
      }

      getCustomerLinqList(parentGuid, page?, itemsPerPage?, userParams?, likesParam?): Observable<PaginatedResult<PersonLinqResultToReturnDto[]>> {
        const paginatedResult: PaginatedResult<PersonLinqResultToReturnDto[]> = new PaginatedResult<PersonLinqResultToReturnDto[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
          params = params.append('pageNumber', page);
          params = params.append('pageSize', itemsPerPage);
        }
        if  (userParams != null) {
          if (userParams.orderBy !== undefined) { params = params.append('orderBy', userParams.orderBy); }
          if (userParams.isAscending !== undefined) {  params = params.append('isAscending', userParams.isAscending); }
          if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
          if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
          if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
        }

        return this.http.get<PersonLinqResultToReturnDto[]>(this.baseUrl + 'person/pagedlist/' + parentGuid, { observe: 'response', params})
          .pipe(
            map(response => {
              paginatedResult.result = response.body;
              if (response.headers.get('Pagination') != null) {
                paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
              }
              return paginatedResult;
            })
          );
      }

      getEmployoeeLinqList(websiteGuid, page?, itemsPerPage?, userParams?, likesParam?): Observable<PaginatedResult<PersonLinqResultToReturnDto[]>> {
        const paginatedResult: PaginatedResult<PersonLinqResultToReturnDto[]> = new PaginatedResult<PersonLinqResultToReturnDto[]>();

        let params = new HttpParams();

        if (page != null && itemsPerPage != null) {
          params = params.append('pageNumber', page);
          params = params.append('pageSize', itemsPerPage);
        }
        if  (userParams != null) {
          if (userParams.orderBy !== undefined) { params = params.append('orderBy', userParams.orderBy); }
          if (userParams.isAscending !== undefined) {  params = params.append('isAscending', userParams.isAscending); }
          if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
          if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
          if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
        }

        return this.http.get<PersonLinqResultToReturnDto[]>(this.baseUrl + 'person/pagedlistemployees/' + websiteGuid, { observe: 'response', params})
          .pipe(
            map(response => {
              paginatedResult.result = response.body;
              if (response.headers.get('Pagination') != null) {
                paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
              }
              return paginatedResult;
            })
          );
      }

      addPeson(person, parentGuid): Observable<any> {
        return this.http.put<any>(this.baseUrl + 'person/addperson/' + parentGuid, person);
      }
  }
