import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ChartInvoiceStatusModaladdComponent } from './chart-invoice-status-modaladd/chart-invoice-status-modaladd.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SaleStatus } from 'src/app/_models/salestatus';
import { SaleStatusService } from 'src/app/_services/saleservices/sale-status.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-chart-invoice-status-list',
  templateUrl: './chart-invoice-status-list.component.html',
  styleUrls: ['./chart-invoice-status-list.component.css']
})
export class ChartInvoiceStatusListComponent implements OnInit {
  chartInvoiceStatuses: SaleStatus[];
  selectedCompany1: UserAssignedToCompany;
  isDelete: boolean;
  bsModalRef: any;

  constructor(private route: ActivatedRoute,
    private modalService: BsModalService,
    private chartInvoiceStatusesService: SaleStatusService, private authService: AuthService, 
    private alertify: ToastrService) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      const newLocal = 'chartInvoiceStatuses';
      this.chartInvoiceStatuses = data.chartInvoiceStatus;
    });
    console.log(this.chartInvoiceStatuses);
  }

  delete(e) {
    console.log(e);
    this.isDelete = false;
    if (e.deletedDate == null) {
      this.isDelete = true;
    }
    this.chartInvoiceStatusesService.deleteStatus(this.selectedCompany1.parentGuid, e.id, this.isDelete).subscribe(data => {
      console.log(data);
      this.chartInvoiceStatuses = data;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Payment Types Updated');
    });
}

editStatus(e: SaleStatus) {
  const initialState = {
    chartInvoiceStatus:  e
  };
  this.bsModalRef = this.modalService.show(ChartInvoiceStatusModaladdComponent, { initialState });
  this.bsModalRef.content.update.subscribe((values) => {
    e.value = values.value;
    this.chartInvoiceStatusesService.editStatus(this.selectedCompany1.parentGuid, e).subscribe(data => {
      this.chartInvoiceStatuses = data;
    }, error => {this.alertify.error(error); }, () => {this.alertify.success('Status  Updated'); });
  });
}

addStatus() {
  this.bsModalRef = this.modalService.show(ChartInvoiceStatusModaladdComponent);
  this.bsModalRef.content.update.subscribe((values) => {
    console.log(values.value);
    this.chartInvoiceStatusesService.addStatus(this.selectedCompany1.parentGuid, values.value).subscribe(data => {
      this.chartInvoiceStatuses = data;
    }, error => {this.alertify.error(error); }, () => {this.alertify.success('Status  Added'); });
  });
}
}
