import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { VehicleBodyClassTypes } from 'src/app/_models/VehicleBodyClassTypes';



@Injectable({
  providedIn: 'root'
})
export class VehicleBodytypeCategoriesService {
  bodyClassCached = [] as VehicleBodyClassTypes[];
  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;

  getList(guid: string): Observable<VehicleBodyClassTypes[]> {
    if (this.bodyClassCached.length > 0) { return of(this.bodyClassCached); }
     this.http.get<VehicleBodyClassTypes[]>(this.baseUrl + 'vehiclebodytypes/getbodytypes/' + guid).subscribe(
      data => {
        this.bodyClassCached = data;
        return of(data);
      }
    );
  }
  getLisResolverView(guid: string): Observable<VehicleBodyClassTypes[]> {
    return this.http.get<VehicleBodyClassTypes[]>(this.baseUrl + 'vehiclebodytypes/getbodytypes/' + guid);
  }
  getActiveList(guid: string): Observable<VehicleBodyClassTypes[]> {
    if (this.bodyClassCached.length > 0) { return of(this.bodyClassCached); }
     this.http.get<VehicleBodyClassTypes[]>(this.baseUrl + 'vehiclebodytypes/getactivebodytypes/' + guid).subscribe(
      data => {
        this.bodyClassCached = data;
        return of(data);
      }
    );
  }

  // getStatus(id: number): Observable<VehicleBodyClassTypes> {

  //   return this.http.get<VehicleBodyClassTypes>(this.baseUrl + 'vehiclebodytypes/getbodytype/' + id);
  // }

  delete(parentguid: string, id: number, isdelete: boolean): Observable<VehicleBodyClassTypes[]> {
    return this.http.get<VehicleBodyClassTypes[]>(this.baseUrl + 'vehiclebodytypes/deletebodytype/'
    + parentguid + '/' + id + '/' + isdelete);
  }

  edit(parentguid: string, chartInvoiceStatus: VehicleBodyClassTypes): Observable<VehicleBodyClassTypes[]> {
    return this.http.put<VehicleBodyClassTypes[]>(this.baseUrl + 'vehiclebodytypes/editbodytype/', chartInvoiceStatus);
  }

  add(parentguid: string, value: string): Observable<VehicleBodyClassTypes[]> {
    return this.http.get<VehicleBodyClassTypes[]>(this.baseUrl + 'vehiclebodytypes/addbodytype/'
    + parentguid + '/' + value);
  }
}
