import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PaymentReceiptDto } from 'src/app/loans/loan-list/printreceiptdto';
import { Item } from 'src/app/_models/item';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
import { AmoLoan } from 'src/app/_models/_models/amoloans';
import { AmoLoanDataFill } from './AmoLoanDataFill';

@Injectable({ providedIn: 'root' })
export class LoanData {
  constructor(
    private amoLoanData: AmoLoanDataFill,
  ) {}
  didSellingPrice = 0 as number;
  didTag = 0 as number;
  fnz02 = (num) =>
    num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    })

  fnz04 = (num) =>
    num.toLocaleString('en-US', {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
      useGrouping: false,
    })
  fnz0 = (num) =>
    num.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: false,
    })
  fillDollars(sale: Sale) {
    this.didSellingPrice = sale?.sellingPrice;
    this.didTag = sale?.tag;
  }
  fillPdfFieldsWithDAta(
    item: PaymentReceiptDto,
    pdfFields: PdfSaleFields[]
  ): Observable<PdfSaleFields[]> {
    console.log(item);
    console.log(pdfFields);
   /// this.fillDollars(item);
    let newPdfFields = [] as PdfSaleFields[];
     /** Fill Vehicle info */
     this.amoLoanData.fillPdfFieldsReceipt(item, 'amor', 'DealDollars', pdfFields).subscribe((data) => {
      if (data.length > 0) {
        newPdfFields = data;
      }
    });
    return of(newPdfFields);
  }
}
