import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { FormNameLabelsService } from 'src/app/_services/formnamelabels/form-name-labels.service';
import { FormName } from 'src/app/_models/formName';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { FormNameModalComponent } from '../label-modal/form-name-modal.component';
import { AddFormModalComponent } from '../add-form-modal/add-form-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-formname-grid',
  templateUrl: './formname-grid.component.html',
  styleUrls: ['./formname-grid.component.css']
})
export class FormnameGridComponent implements OnInit {
  selectedCompany1: UserAssignedToCompany;
  formNames: FormName[];
  isDelete: boolean;
  bsModalRef: any;

  constructor(private modalService: BsModalService, private authService: AuthService, private route: ActivatedRoute,
    private formNameService: FormNameLabelsService, private alertify: AlertifyService,
    private router: Router) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      this.formNames = data.formNames;
    });
  }

  editPaymentType(e: FormName) {
    this.router.navigate(['/list/formnamelabellist/' + this.selectedCompany1.parentGuid + '/' + e.value]);
  }

  add() {
  this.bsModalRef = this.modalService.show(AddFormModalComponent);
  this.bsModalRef.content.formName.subscribe((values) => {
    this.formNameService.addFormNameByName(this.selectedCompany1.parentGuid, values.value).subscribe(data => {
      this.formNames = data;
    }, error => {this.alertify.error(error); }, () => {this.alertify.success('Payment Type Added'); });
  });
}

  delete(e) {
  //     this.isDelete = false;
  //     if (e.deletedDate == null) {
  //       this.isDelete = true;
  //     }
  //     this.paymentTypeService.deletePaymentType(e.id, this.selectedCompany1.parentGuid, this.isDelete).subscribe(data => {
  //       this.formNames = data;
  //     }, error => { this.alertify.error(error); }, () => {
  //       this.alertify.success('Payment Types Updated');
  //     });
  // }
  }
}

