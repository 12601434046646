import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';
import { SaleType } from 'src/app/_models/saletype';


@Injectable({
  providedIn: 'root'
})
export class  SaleTypeService {

  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;

  getSaleTypes(guid: string): Observable<SaleType[]> {
    return this.http.get<SaleType[]>(this.baseUrl + 'saletype/getsaletypes/' + guid);
  }
  getActiveSaleTypes(guid: string): Observable<SaleType[]> {
    return this.http.get<SaleType[]>(this.baseUrl + 'saletype/getactivesaletypes/' + guid);
  }
  getSaleType(id: number): Observable<SaleType> {
    return this.http.get<SaleType>(this.baseUrl + 'saletype/getsaletype/' + id);
  }

  deleteSaleType(parentguid: string, id: number, isdelete: boolean): Observable<SaleType[]> {
    return this.http.get<SaleType[]>(this.baseUrl + 'saletype/deletesaletype/'
    + parentguid + '/' + id + '/' + isdelete);
  }
  editSaleType(parentguid: string, saleType: SaleType): Observable<SaleType[]> {
    return this.http.put<SaleType[]>(this.baseUrl + 'saletype/editsaletype/', saleType);
  }
  addSaleType(parentguid: string, value: string): Observable<SaleType[]> {
    return this.http.get<SaleType[]>(this.baseUrl + 'saletype/addsaletype/'
     + parentguid + '/' + value);
  }
}
