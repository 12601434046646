import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AmoPayment } from 'src/app/_models/amopayments';
import { AmoLinqPayments } from 'src/app/_models/DTOS/amolinqpayments';
import { TakePaymentDto } from 'src/app/_models/DTOS/takepaymentdto';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AmoInstallmentService } from 'src/app/_services/amoinstallmentservices/amoinstallments.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { ExcelService } from 'src/app/_services/exportservices/excel.service';
import { LoanService } from 'src/app/_services/loanservieces/loan.service';
import { AddPaymentModalComponent } from '../add-payment-modal/add-payment-modal.component';
import moment from 'moment';
import { AmoLoanService } from 'src/app/_services/amoloanservices/amoloan.service';
import { PaymentReceiptDto } from '../loan-list/printreceiptdto';
@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css']
})
export class PaymentListComponent implements OnInit {
  selectedCompany: UserAssignedToCompany;
  pagination: Pagination;
  loan = {} as AmoLinqPayments;
  loans: AmoLinqPayments[];
  payments = [] as AmoPayment[];
  userParams = {} as any;
  isFilterDates = true;
  isPrint = false;
  isPaymentList = false;
  isLoanList = false;

  isDatePaid = false;
  isLoanId = false;
  isTodayPaid = false;
  isTodayNonAmortized = false;
  isTodayInterest = false;
  isTodayPrinciple = false;
  isTodayLateFee = false;
  isTodayMisc = false;
  isTodayDeferred = false;
  isBuyer = false;
  isSaleId = false;
  isVehicle = false;
  isVin = false;
  isPrincipleBalance = false;
  bsModalRefAddPayment: any;
  paymentRecieptDto = {} as PaymentReceiptDto;
  numZero = 0 as number ;
  isAddPayment = false;
  invoiceId = 0;
  takePaymentDto: TakePaymentDto =  {
    datePaid: moment(Date.now()).toDate(),
    invoiceId: null,
    todayLoanPaid: 1,
    todayDeferredDown: 2,
    todayLateFee: 3,
    todayMisc: 4,
    sumPaidToday: 5,
    parentId: ' '
  };

  constructor(private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private authService: AuthService,
     private alertify: ToastrService,
    private confirmService: ConfirmService,
    private loanService: AmoInstallmentService,
    private loansDue: LoanService,
    private excelService: ExcelService,
    private takePaymentLoan: AmoLoanService,) { }
  loadPayments() {
    this.loanService.retrievePagedPayments(this.selectedCompany.parentGuid, this.pagination.currentPage,
      this.pagination.itemsPerPage, this.userParams)
    .subscribe((data: PaginatedResult<AmoPayment[]>) => {
      this.payments = data.result;
      this.pagination = data.pagination;
      console.log(data.result);
    }, error => {
      this.alertify.error(error);
    }, () => {
      this.alertify.success('Payments Retrieved.');
    });
  }
  deletePayment(e) {
    console.log(e);
    const deleteMsage = 'Are you sure you want to delete a payment amount of $' + e.todayLoanPaid + 'from ' +
    e.buyerName;
    this.confirmService.confirm('Confirm delete payment', deleteMsage, 'Delete').subscribe(result => {
      if (result) {
    this.loanService.deletePayment(e.id).subscribe(data => {
      this.payments = data;
    }, error => { this.alertify.error(); }, () => {this.alertify.success('Payment Updated'); });
  }
});
  }
  sort(e) {
    this.isDatePaid = false;
    this.isLoanId = false;
    this.isTodayPaid = false;
    this.isTodayNonAmortized = false;
    this.isTodayInterest = false;
    this.isTodayPrinciple = false;
    this.isTodayLateFee = false;
    this.isTodayMisc = false;
    this.isBuyer = false;
    this.isSaleId = false;

    this.isVin = false;
    this.isVehicle = false;
    this.isPrincipleBalance = false;
    if (e === 'isDatePaid') { this.isDatePaid = true; }
    if (e === 'isLoanId') { this.isLoanId = true; }
    if (e === 'isTodayPaid') { this.isTodayPaid = true; }
    if (e === 'isTodayNonAmortized') { this.isTodayNonAmortized = true; }
    if (e === 'isTodayInterest') { this.isTodayInterest = true; }
    if (e === 'isTodayPrinciple') { this.isTodayPrinciple = true; }
    if (e === 'isTodayLateFee') { this.isTodayLateFee = true; }
    if (e === 'isTodayMisc') { this.isTodayMisc = true; }
    if (e === 'isBuyer') { this.isBuyer = true; }
    if (e === 'isTodayDeferred') { this.isTodayDeferred = true; }
    if (e === 'isSaleId') { this.isSaleId = true; }
    if (e === 'isTodayMisc') { this.isTodayMisc = true; }
    if (e === 'isVin') { this.isSaleId = true; }
    if (e === 'isVehicle') { this.isTodayMisc = true; }
    if (e === 'isPrincipleBalance') { this.isPrincipleBalance = true; }
  }

  ngOnInit() {
    this.isPaymentList = true;
    this.authService.company.subscribe(data => this.selectedCompany = data);
    this.route.data.subscribe(data => {
      const newLocal = 'payments';
      this.payments = data[newLocal].result;
      this.pagination =  data[newLocal].pagination;
    });
    console.log(this.payments);
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadPayments();
  }

  filter(e) {}

  setSearchBy(e, a) {}

  selectGrid(e) {
    this.isPaymentList = false;
    this.isLoanList = false;
    if (e === 'isLoanList') { this.isLoanList = true;  this.router.navigate(['/dashboardloans/loanlist/' + this.selectedCompany.parentGuid]); }
    if (e === 'isPaymentList') { this.isPaymentList = true; this.router.navigate(['/dashboardloans/paymentlist/' + this.selectedCompany.parentGuid]); }
  }

  updateDates(event) {
    // if (this.firstLoad) {
    this.userParams.startDate = event.startDate;
    this.userParams.endDate = event.endDate;
    this.userParams.monthOfYear = event.month;
    this.userParams.yearForDate = event.year;
    this.userParams.isAll = event.isAll;
    this.userParams.isFullYear = event.isFullYear;
    //   this.userParams.isDeleted = this.isDeleted;
    this.loadPayments();
    console.log('thisupdatedates hit');
  //  }
  }

  printDiv() {}

  dueTodayLoad(e) {
    console.log(e);
       const initialState = {
         takePaymentDto: e
       };
       this.bsModalRefAddPayment = this.modalService.show(AddPaymentModalComponent, {initialState, class: 'modal-md'});
       this.bsModalRefAddPayment.content.paymentEnetered.subscribe((values) => {
         //  console.log(values);
           this.takePaymentDto = values;
           this.takePaymentDto.invoiceId = e.invoiceNumber;
           this.takePaymentLoan.editPaymentByAmoLoanId(values, e.amoLoanId, e.id).subscribe(data => {
          //   this.chartInvoice = data.invoice;
             this.payments = data.amoPayments;
             this.alertify.success('Payment Applied Successullfy.');
           }, error => {
           //  this.alertify.error(error);
           });
    });
  }

  setAddPaymentButton(e) {
    this.takePaymentDto = e;
    this.isAddPayment = true;

    console.log(this.takePaymentDto);
  }
  addPayment() {
    this.getDueTodayLoad(this.takePaymentDto);
  }
  takePayment(item)  {
    console.log(this.takePaymentDto);
    const initialState = {
      chartInvoiceTransaction: this.loans,
      takePaymentDto: this.takePaymentDto
    };
    this.bsModalRefAddPayment = this.modalService.show(AddPaymentModalComponent, {initialState, class: 'modal-md'});
      this.bsModalRefAddPayment.content.paymentEnetered.subscribe((values) => {
      //  console.log(values);
        this.takePaymentDto = values;
        this.takePaymentLoan.takePayment(values).subscribe(data => {
       //   this.chartInvoice = data.invoice;
          this.payments = data.amoPayments;
          this.paymentRecieptDto = data.recieptFields;
          console.log(data);
          this.alertify.success('Payment Applied Successullfy.');
        }, error => {
      //    this.alertify.error(error);
        });
  });
  }
  async getDueTodayLoad(item) {
    console.log(item.saleId);
    this.invoiceId = item.invoiceNumber;
    this.takePaymentDto.datePaid = moment(Date.now()).toDate();
    this.takePaymentDto.parentId = this.selectedCompany.parentGuid;
    this.takePaymentDto.invoiceId = item.invoiceNumber;
    this.takePaymentLoan.getPaymentDueToday(this.takePaymentDto).subscribe( (data) => {
      console.log(data);
    //  console.log(moment(Date.now()).toDate());
      this.takePaymentDto.sumPaidToday = parseFloat(data.sumDueTOday.toFixed(2));
      this.takePaymentDto.todayNonAmortizedPaid = parseFloat(data.nonAmortizedPaymentDue.toFixed(2));
      this.takePaymentDto.todayDeferredDown = parseFloat(data.deferredBalance.toFixed(2));
      this.takePaymentDto.todayLateFee = parseFloat(this.numZero.toFixed(2));
      this.takePaymentDto.todayMisc = parseFloat(this.numZero.toFixed(2));
      this.takePaymentDto.todayLoanPaid = data.dueToday;
    }, (error) => {
    //  this.alertify.error(error);
    }, () => {
      this.takePayment(item); }
      );
  }
}
