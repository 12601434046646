import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SaleStatus } from 'src/app/_models/salestatus';

@Component({
  selector: 'app-chart-invoice-status-modaladd',
  templateUrl: './chart-invoice-status-modaladd.component.html',
  styleUrls: ['./chart-invoice-status-modaladd.component.css']
})
export class ChartInvoiceStatusModaladdComponent implements OnInit {
  @Output() update = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  chartInvoiceStatus: SaleStatus;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  updateStatus() {
    this.chartInvoiceStatus = this.editForm.value;
    this.update.emit(this.chartInvoiceStatus);
    this.bsModalRef.hide();
  }
}
