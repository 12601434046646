import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import { CompanyDepartmentType } from '../_models/CompanyDepartmentType';
import { PersonType } from '../_models/personType';
import { Password } from '../_models/password';
import { AdminService } from './admin.service';
import { CompaniesService } from './companydataservice/companies.service';
import { UserAssignedToCompany } from '../_models/userAssignedToCompany';
import { Website } from '../_models/website';
import { FormName } from '../_models/formName';
import { CompanyProfile } from '../_models/companyProfile';
import { Router } from '@angular/router';
import { Person } from '../_models/person';
import { PersonLinqResultToReturnDto } from '../_models/DTOS/PersonLinqResultToReturnDto';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl + 'auth/';
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  currentUser: User;

  photoUrl = new BehaviorSubject<string>('../../assets/user.png');
  companyDepartmentsTypes = new BehaviorSubject<CompanyDepartmentType[]>(null) ;

  parentCompanies = new BehaviorSubject<CompanyProfile[]>(null);
  currentParentCompanies = this.parentCompanies.asObservable();

  company = new BehaviorSubject<UserAssignedToCompany>(null);
  currentCompany = this.company.asObservable();

  formNames = new BehaviorSubject<FormName[]>(null);
  currentFormNames = this.formNames.asObservable();

  parentUserAssignedCompanies = new BehaviorSubject<UserAssignedToCompany[]>(null);
  currentUserAssignedCompanies = this.parentUserAssignedCompanies.asObservable();

  users = new BehaviorSubject<User[]>(null);
  currentUsers = this.users.asObservable();

  personTypes = new BehaviorSubject<PersonType[]>(null);
  currentPhotoUrl = this.photoUrl.asObservable();

  isCompanySubscriptionActivelyPaid = new BehaviorSubject<boolean>(null);
  currentIsCompanySubscriptionActivelyPaid = this.isCompanySubscriptionActivelyPaid.asObservable();

  person = new BehaviorSubject<PersonLinqResultToReturnDto>(null);
  currentPerson = this.person.asObservable();

  paymentTaken = new BehaviorSubject<boolean>(false);
  currentPaymentTaken = this.paymentTaken.asObservable();

  constructor(private http: HttpClient,  private router: Router, private adminService: AdminService, private companyService: CompaniesService) {}

  setPaymentTaken(e: boolean) {
    this.paymentTaken.next(e);
  }
  setIsCurrentPerson(person: PersonLinqResultToReturnDto) {
    this.person.next(person);
  }
  getCurrentPerson(){
    return this.currentPerson;
  }

  setIsActivelyPaid(value: boolean) {
    this.isCompanySubscriptionActivelyPaid.next(value);
  }

  setMainPhoto(userId: number, id: number) {
    return this.http.post(this.baseUrl + 'users/' + userId + '/photos/' + id + '/setMain', {});
  }

  changeMemberPhoto(photoUrl: string) {
    this.photoUrl.next(photoUrl);
  }

  changeChartItem() {

  }

  changeCompany(company: UserAssignedToCompany) {
    this.company.next(company);
  }

  changeFormLabels(formName: FormName[]) {

    if (localStorage.getItem('formsLabels') != null && formName.length > 0) { localStorage.removeItem('formsLabels'); }
    localStorage.setItem('formsLabels', JSON.stringify(formName));
    this.formNames.next(formName);
  }

  changeParentUserAssignedCompanies(userAssignedToCompany: UserAssignedToCompany[]) {
    this.parentUserAssignedCompanies.next(userAssignedToCompany);
  }
 getUserAssignedToCompanies() {
   return this.getUserAssignedToCompanies;
 }
  changeUsers(users: User[]) {
    this.users.next(users);
  }

  changeParentComanies(parentComanies: CompanyProfile[]) {
    this.parentCompanies.next(parentComanies);
  }
  changeCompanyDepartmentTypes(companyDpeartmentTypes: CompanyDepartmentType[]) {
    this.companyDepartmentsTypes.next(companyDpeartmentTypes);
  }
  changePersonTypes(personTypes: PersonType[]) {
    this.personTypes.next(personTypes);
  }

  retriveUsersFromApi() {
   this.adminService.getUsersWithRoles().subscribe((users: User[]) => {
    localStorage.setItem('users', JSON.stringify(users) );
    this.changeUsers(users);
    }, error => {
      console.log(error);
    });
  }

  login(model: any) {
    return this.http.post(this.baseUrl + 'login', model).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          localStorage.setItem('token', user.token);
          localStorage.setItem('user', JSON.stringify(user.user));
          this.decodedToken = this.jwtHelper.decodeToken(user.token);
          this.currentUser = user.user;
          this.changeMemberPhoto(this.currentUser.photoUrl);
          localStorage.setItem('userAssignedToCompany', JSON.stringify(user.user.userAssignedToCompany));
          this.changeParentUserAssignedCompanies(user.user.userAssignedToCompany);
          localStorage.setItem('company', JSON.stringify(user.user.userAssignedToCompany[0]));
               this.changeCompany(user.user.userAssignedToCompany[0]);
        }
      })
    );
  }

  updatePassword(id, password: Password) {
    return this.http.put(this.baseUrl + 'updateuserpassword/' + id, password);
  }
  register(user: User) {
    console.log(user);
    console.log(this.baseUrl + 'register');
    return this.http.post(this.baseUrl + 'register', user);
  }

  loggedIn(){
    const token = localStorage.getItem('token');
    console.log(token);
    console.log(!this.jwtHelper.isTokenExpired(token));
    if (!this.jwtHelper.isTokenExpired(token)) {
      this.router.navigate(['home']);
    }
    return this.jwtHelper.isTokenExpired(token);
  }

  roleMatch(allowedRoles): boolean {
    let isMatch = false;
    const userRoles = this.decodedToken.role as Array<string>;
    allowedRoles.forEach(element => {
      if (userRoles.includes(element)) {
        isMatch = true;
        return;
      }
    });
    return isMatch;
  }

  deletePhoto(userId: number, id: number) {
    return this.http.delete(this.baseUrl + 'users/' + userId + '/photos/' + id);
  }
}
