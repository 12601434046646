import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { AdminPanelComponent } from './admin-panel/admin-panel.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { PhotoManagementComponent } from './photo-management/photo-management.component';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from '../_modules/shared.module';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { MemberCardComponent } from '../members/member-card/member-card.component';
import { MemberModule } from '../members/member.module';
import { MemberListComponent } from '../members/member-list/member-list.component';
import { EmployeeManagementComponent } from './employee-management/employee-management.component';
import { EmployeeAssigncompanyComponent } from './employee-management/employee-assigncompany/employee-assigncompany.component';
import { AddEmployeeModalComponent } from './employee-management/add-employee-modal/add-employee-modal.component';
import { TextInputComponent } from '../_forms/text-input/text-input.component';
import { AddemployeeTextinputComponent } from './employee-management/add-employee-modal/addemployee-textinput/addemployee-textinput.component';
import { EditEmployeeComponent } from './employee-management/edit-employee/edit-employee.component';
import { CompanyGridComponent } from './company-grid/company-grid.component';
import { AddCompanyprofilepopupComponent } from './company-grid/add-companyprofilepopup/add-companyprofilepopup.component';
import { EditCompanyprofilepopupComponent } from './company-grid/edit-companyprofilepopup/edit-companyprofilepopup.component';

@NgModule({
    declarations: [
        AdminDashboardComponent,
        AdminPanelComponent,
        UserManagementComponent,
        PhotoManagementComponent,
        EmployeeManagementComponent,
        EmployeeAssigncompanyComponent,
        AddEmployeeModalComponent,
        AddemployeeTextinputComponent,
        EditEmployeeComponent,
        CompanyGridComponent,
        AddCompanyprofilepopupComponent,
        EditCompanyprofilepopupComponent,
    ],
    imports: [
        ReactiveFormsModule,
        MemberModule,
        FormsModule,
        CommonModule,
        SharedModule,
        AdminRoutingModule,
        AgChartsAngularModule,
        AgGridModule.withComponents([]),
    ]
})
export class AdminModule { }

