import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
@Injectable({ providedIn: 'root' })
export class SaleDataVendors {

      fillPdfFieldsWithVendors(sale: Sale, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
        if (pdfFields.length > 0 ) {
        let  field = '';
      let newpdfField = {} as PdfSaleFields;
      const newPdfFields = [] as PdfSaleFields[];
        pdfFields.forEach(element => {
            field = element.field;
            const str = field;
            const matches = str.match(/\d+/g);
            let i;
            if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }
            field = field.replace(i.toString(), '');
            newpdfField.groupBy = 'company';
            switch (field) {
                case prefix + 'Name': {
                    newpdfField  = {
                        field: prefix + 'Name' + i,
                        value: sale?.lienHolder?.name,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Address': {
                    newpdfField  = {
                        field: prefix + 'Address' + i,
                        value: sale?.lienHolder?.addressDetail?.street,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'City': {
                    newpdfField  = {
                        field: prefix + 'City' + i,
                        value: sale?.lienHolder?.addressDetail?.city,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'State': {
                    newpdfField  = {
                        field: prefix + 'State' + i,
                        value: sale?.lienHolder?.addressDetail?.state,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Zip': {
                    newpdfField  = {
                        field: prefix + 'Zip' + i,
                        value: sale?.lienHolder?.addressDetail?.zip,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'County': {
                    newpdfField  = {
                        field: prefix + 'County' + i,
                        value: sale?.lienHolder?.addressDetail?.county,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Email': {
                    newpdfField  = {
                        field: prefix + 'Email' + i,
                        value: sale?.lienHolder?.email,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Fax': {
                    newpdfField  = {
                        field: prefix + 'Fax' + i,
                        value: sale?.lienHolder?.fax,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Phone': {
                    newpdfField  = {
                        field: prefix + 'Phone' + i,
                        value: sale?.lienHolder?.phone,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'LicNumber': {
                    newpdfField  = {
                        field: prefix + 'LicNumber' + i,
                        value: sale?.lienHolder?.licenseNumber,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'TaxNumber': {
                    newpdfField  = {
                        field: prefix + 'TaxNumber' + i,
                        value: sale?.lienHolder?.taxNumber,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'EIN': {
                    newpdfField  = {
                        field: prefix + 'EIN' + i,
                        value: sale?.lienHolder?.ein,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'EFN': {
                    newpdfField  = {
                        field: prefix + 'EFN' + i,
                        value: sale?.lienHolder?.efn,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
            }
        });
        return of(newPdfFields);
    }
    return of(pdfFields);
   }
}
