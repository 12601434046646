<div class="card mb-4">
    <div class="card-img-wrapper">
        <img src="{{member.photoUrl || './assets/user.png'}}" alt="{{member.knownAs}}" class="card-img-top">
        <ul class="list-inline member-icons animate text-center">
            <li class="list-inline-item"><button routerLink='/members/{{member.username}}' class="btn btn-primary">
                <i class="fa fa-user"></i></button></li>
            <li class="list-inline-item"><button (click)="addLike(member)" class="btn btn-primary">
                <i class="fa fa-heart"></i></button></li>
            <li class="list-inline-item">
                <button 
                    routerLink='/members/{{member.username}}' 
                    [queryParams]='{tab: 3}'
                    class="btn btn-primary">
                <i class="fa fa-envelope"></i>
            </button></li>
        </ul>
    </div>
    <div class="card-body p-1">
        <h6 class="card-title text-center mb-1">
            <span [class.is-online]="(presence.onlineUsers$ | async).includes(member?.username)">
                <i class="fa fa-user mr-2"></i>
            </span>
            
            {{member.knownAs}}, {{member.age}}
        </h6>
        <p class="card-text text-muted text-center">{{member.city}}</p>
    </div>
</div>
