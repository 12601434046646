import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { ClmVehicleService } from 'src/app/_services/clmvehiclesservices/clmvehicles.service';

@Component({
    moduleId: module.id,
    selector: 'app-clmsimpleinterest-grid',
    templateUrl: 'clmsimpleinterest-grid.component.html',
    styleUrls: ['clmsimpleinterest-grid.component.scss']
})
export class ClmsimpleinterestGridComponent implements OnInit {
    isNotClicked = false;
    entity = {} as any;
    entities = [] as any[];
    selectedCompany1 = {} as UserAssignedToCompany;

    constructor(private vehicleService: ClmVehicleService,
        private authService: AuthService,
        private alertify: ToastrService) { }
    
    ngOnInit(): void {
        this.authService.currentCompany.subscribe(data => { this.selectedCompany1 = data; this.list(); });

    }
    list() {
        this.vehicleService.listPrecomputedPayments(this.selectedCompany1.parentGuid).
        subscribe((data: any[]) => {
          console.log(data);
          this.entities = data;
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Success');
        });
    }
    post() {
        this.isNotClicked = true;
                this.vehicleService.listPrepcomputedKods().subscribe(data => {

                    console.log(data);
                }, error => {}, ()=>{
                    this.list();
                    this.alertify.success('Success');
                });
        
    }
}
