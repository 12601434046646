import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProgramDefaults } from 'src/app/_models/ProgramDefaults';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProgramDefaultService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getProgramDefaults() {
    return this.http.get<Partial<ProgramDefaults>>(this.baseUrl + 'programdefaults/getprogramdefaults');
  }

  updateProgramDefaults(parentguid: string, defaults: ProgramDefaults) {
    return this.http.post(this.baseUrl + 'programdefault/updateprogramdefaults/' + parentguid, defaults);
  }
}
