import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Label } from 'src/app/_models/label';
import { ProgramDefaults } from 'src/app/_models/ProgramDefaults';
import { AuthService } from 'src/app/_services/auth.service';
import { ProgramDefaultService } from 'src/app/_services/programdefaultsservices/program-defaults.service';

@Component({
  selector: 'app-profit-items',
  templateUrl: './profit-items.component.html',
  styleUrls: ['./profit-items.component.css']
})
export class ProfitItemsComponent implements OnInit {
  @Output() emittedValues = new EventEmitter();
  @Input() parentGuid: string;
  @Input() programDefaults: ProgramDefaults;
  isChecked: boolean;
  labels = [] as Label[];
  label: Label;
  labelToDisplay: any;

  labelPredelivery: any;
  labelTag: any;
  labelRegistration: any;
  labelTitle: any;
  labelExtra2: any;
  labelExtra1: any;
  labelGap: any;
  constructor(public bsModalRef: BsModalRef,
     private authService: AuthService) { }

  ngOnInit() {
    this.authService.currentFormNames.subscribe(data => {
      data.forEach(element => {
        if (element.value === 'DealInfo') { this.labels = element.labels;  this.decipherLabels(element.labels);}
      });
    });
  }
  decipherLabels(e) {
    if  (this.labels.length > 0) {
      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Predelivery');
      this.labelPredelivery = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Title');
      this.labelTitle = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'TagSale');
      this.labelTag = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Registration');
      this.labelRegistration = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Extra 1');
      this.labelExtra1 = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Extra 2');
      this.labelExtra2 = this.labelToDisplay[0].value;

      this.labelToDisplay =  this.labels.filter(v => v.fieldName === 'Gap');
      this.labelGap = this.labelToDisplay[0].value;
      }
  }

  changed(evt,  name) {

   // console.log(this.programDefaults);
 // alert(name);
 // console.log(this.programDefaults);
 }
 onSubmit() {
   this.emittedValues.emit(this.programDefaults);
   this.bsModalRef.hide();
 }
}
