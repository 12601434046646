import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { VehicleBuyersGuide } from 'src/app/_models/buyersguidemodels/VehicleBuyersGuide';
import { BuyersGuideAndSystemsListReturned } from 'src/app/_models/DTOS/buyersguiddtos/BuyersGuideAndSystemsListReturned';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { BuyersGuidService } from '../../buyersguid-service';
import { EditbuyersguideComponent } from './editbuyersguide/editbuyersguide.component';

@Component({
  selector: 'app-buyersguide-grid',
  templateUrl: './buyersguide-grid.component.html',
  styleUrls: ['./buyersguide-grid.component.css']
})
export class BuyersguideGridComponent implements OnInit {
  vehicleBuyersGuide = [] as VehicleBuyersGuide[];
  bsModalRef1: any;
  selectedCompany: UserAssignedToCompany;
  buyersGuideAndSystemsListReturned = {} as BuyersGuideAndSystemsListReturned;
  constructor(private repo: BuyersGuidService,
    private authService: AuthService,
    private modalService: BsModalService,
    private alertify: ToastrService,
    private repoBuyerGuid: BuyersGuidService) { }

  ngOnInit() {
    this.authService.company.subscribe(data => {
      this.selectedCompany = data;
      this.repo.getBuyersGuideList(this.selectedCompany.parentGuid).subscribe(data1 => {
        this.vehicleBuyersGuide = data1; });
    });
  }

  delete(e: VehicleBuyersGuide) {
    this.repo.deletBuyersGuide(e.id, this.selectedCompany.parentGuid).subscribe(data => {
      this.vehicleBuyersGuide = data.bguides;
    });
  }

  editNewBuyersGuid(e) {
    const initialState = {
      vehicleBuyersGuide: e
    };
    this.bsModalRef1 = this.modalService.show(EditbuyersguideComponent, {
      initialState,
    });
    this.bsModalRef1.content.emittedValues.subscribe(
      (values: VehicleBuyersGuide) => {
        values.parentId = this.selectedCompany.parentGuid;
        this.repoBuyerGuid.editBuyersGuide(values).subscribe(
          (data) => {
            this.buyersGuideAndSystemsListReturned = data;
          },
          (err) => {
            console.log(err);
            this.alertify.success('Error adding Systems');
          },
          () => {
            this.alertify.success('Added Buyers Guide');
          }
        );
      }
    );
  }
}
