
    <div class="customer-form-sec">
      <div class="container-fluid">
    <div class="row">
    <div class="form-sec tab-content col-md-12">
      <div id="vehicle" class="tab-pane fade in active show">
    <div style="width: 550px" class="modal1" id="myModal">
      <div class="modal-dialog1 modal1" style="width:550px;">
         <div class="modal1-content modal1" style="width:450px;" >
    <div  class="modal-body modal1">
    <div class="form-sec tab-content col-md-12" style="width:550px !important;">
    <form id="editForm" #editForm="ngForm" (ngSubmit)="updateCall()">
        <div class="row vehicle-form">
            <div class="col-md-12 cstm-col">
                <div *ngIf="editForm.dirty" class="alert alert-info">
                    <strong>Information:</strong> You have made changes. Any unsaved changes
                    will be lost!
                  </div>
            </div>
      <!--STOCK #-->

        <!--END STOCK #-->
    
         <!--VIN #-->
         
           <div class="col-md-12 cstm-col">
            <div  >
              <label>{{ chartItem?.vehiclePurchased?.vehicle?.vin }}</label>
            </div>
          </div>
            <!--END VIN #-->
     <!-- Suggested PRice-->  
     <div class="col-md-12 rw">
       <div class="row">
       <div class="col-md-6">
        <label>Suggested Price:</label>
       </div>
       <div class="col-md-6">
      <input *appHasRole="['Admin']"  autocomplete="puchasedAs-name" class="cstmnumeric-input"  type="text"
      [ngModel]="chartItem?.itemAmountSums?.suggestedPrice || 0  | number : '1.2'"
      (ngModel)="chartItem.itemAmountSums.suggestedPrice = $event "
      #suggestedPrice="ngModel"
      name="suggestedPrice"
      onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)" 
      >
    </div>
  </div>
    </div>
   
             <!-- purchasedPrice-->  
      <div class="col-md-12 cstm-col">
          <label>Cost</label>
          <input  *appHasRole="['Admin']"  autocomplete="puchasedAs-name" class="cstmnumeric-input"  type="text"
          [ngModel]="chartItem?.itemAmountSums?.purchasePrice || 0  | number : '1.2'"
          (ngModel)="chartItem.itemAmountSums.purchasePrice = $event "
          #purchasePrice="ngModel"
          name="purchasePrice"
          onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)" 
          >
      </div>
    
        <!--MILEAGE-->
        <div class="col-md-12 cstm-col">
            <!-- <div class="input-sec veh-mile-sec">
              <div class="rw"> -->
                <label>Mileage</label>
                <input align="right" autocomplete="puchasedAs-name" class="cstmnumeric-input"  type="text"
                [ngModel]="chartItem?.vehiclePurchased?.itemVehicleTransientDetails?.mileage || 0  | number : '1.2'"
                (ngModel)="chartItem.vehiclePurchased.itemVehicleTransientDetails.mileage = $event "
                #mileage="ngModel"
                name="mileage"
                 >
              <!-- </div>
            </div> -->
        </div>
            <!--button save-->
          <div class="col-md-12 cstm-col ">
            <button [disabled]="!editForm.valid" type="submit" class="btn btn-primary ">
              SAVE
            </button>
            <p *ngIf="!editForm.valid" style="color: red">
              Save Button Will be enabled when all fields entered!
            </p>
          </div>
      
        </div>
    </form>
    </div>
    
    </div>
    </div>
      </div>
    </div>
      </div>
    </div>
    </div>
    </div>
    </div>

