import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
import moment from 'moment';
@Injectable({ providedIn: 'root' })
export class SaleDataDealLoan {
    moment = require('moment');
    convertDate(date) {
        const momentDate = moment().format('L').toString();
        return momentDate;
}
      fillPdfFieldsLoan(sale: Sale, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
      let  field = '';
     // console.log(sale);
      let newpdfField = {} as PdfSaleFields;
      const newPdfFields = [] as PdfSaleFields[];
        pdfFields.forEach(element => {
            field = element.field;
            const str = field;
            const matches = str.match(/\d+/g);
        let i;
        if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }
            field = field.replace(i.toString(), '');
            newpdfField.groupBy = 'company';
            switch (field) {
                case prefix + 'Balance': {
                    newpdfField  = {
                        field: prefix + 'Balance' + i,
                        value: sale?.balance.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'FinalDueDate': {
                    newpdfField  = {
                        field: prefix + 'FinalDueDate' + i,
                        value: this.convertDate(sale?.finalDueDate),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'NextDueDate': {
                    newpdfField  = {
                        field: prefix + 'NextDueDate' + i,
                        value: this.convertDate(sale?.nextDueDate),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'PayOff': {
                    newpdfField  = {
                        field: prefix + 'PayOff' + i,
                        value: sale?.payOff.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'LastPaidDate': {
                    newpdfField  = {
                        field: prefix + 'LastPaidDate' + i,
                        value: this.convertDate(sale?.lastPaidDate),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'InterestDays': {
                    newpdfField  = {
                        field: prefix + 'InterestDays' + i,
                        value: sale?.interestDays.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'PrepaidFee': {
                    newpdfField  = {
                        field: prefix + 'PrepaidFee' + i,
                        value: sale?.prepaidFee.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Reserve': {
                    newpdfField  = {
                        field: prefix + 'Reserve' + i,
                        value: sale?.reserve.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'AmountFinanced': {
                    newpdfField  = {
                        field: prefix + 'AmountFinanced' + i,
                        value: sale?.amountFinanced.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'SoldDate': {
                    newpdfField  = {
                        field: prefix + 'SoldDate' + i,
                        value: this.convertDate(sale?.soldDate),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Amortized': {
                    newpdfField  = {
                        field: prefix + 'Amortized' + i,
                        value: sale?.amortized.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'NonAmortized': {
                    newpdfField  = {
                        field: prefix + 'NonAmortized' + i,
                        value: sale?.nonAmortized.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'CurrentDue': {
                    newpdfField  = {
                        field: prefix + 'CurrentDue' + i,
                        value: sale?.currentDue.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Installment': {
                    newpdfField  = {
                        field: prefix + 'Installment' + i,
                        value: sale?.amoInstallments?.value,
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Apr': {
                    newpdfField  = {
                        field: prefix + 'Apr' + i,
                        value: sale?.apr.toFixed(4),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Term': {
                    newpdfField  = {
                        field: prefix + 'Term' + i,
                        value: sale?.term.toString(),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Payment': {
                    newpdfField  = {
                        field: prefix + 'Payment' + i,
                        value: sale?.payment.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'FinalPayment': {
                    newpdfField  = {
                        field: prefix + 'FinalPayment' + i,
                        value: sale?.finalPayment.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'FirstDueDate': {
                    newpdfField  = {
                        field: prefix + 'FirstDueDate' + i,
                        value: this.convertDate(sale?.firstDueDate),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'DAPR': {
                    newpdfField  = {
                        field: prefix + 'DAPR' + i,
                        value: sale?.dapr.toFixed(4),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'AddOn': {
                    newpdfField  = {
                        field: prefix + 'AddOn' + i,
                        value: sale?.addOn.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'NetReservePrc': {
                    newpdfField  = {
                        field: prefix + 'NetReservePrc' + i,
                        value: sale?.netReservePrc.toFixed(4),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
                case prefix + 'Interest': {
                    newpdfField  = {
                        field: prefix + 'Interest' + i,
                        value: sale?.interest.toFixed(2),
                        calculate: 'false',
                        groupBy: groupByField
                    };
                    newPdfFields.push(newpdfField);
                   break;
                }
            }
        });
        return of(newPdfFields);
   }
}
