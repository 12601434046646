import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Person } from 'src/app/_models/person';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Item } from 'src/app/_models/Item';
import { ChartInvoiceTransactionGridTOReturnDto } from 'src/app/_models/DTOS/ChartInvoiceTransactionGridTOReturnDto';
import { Sale } from 'src/app/_models/sale';


@Component({
  selector: 'app-vehicle-card-grid',
  templateUrl: './vehicle-card-grid.component.html',
  styleUrls: ['./vehicle-card-grid.component.css']
})
export class VehicleCardGridComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() chartItem: Sale;
  @Output() update = new EventEmitter();
  @Output() userWebsiteToReturnDto = new EventEmitter();
  @Input() selectedCompany: UserAssignedToCompany;
  constructor(private authService: AuthService,
    private alertify: AlertifyService) { }

  ngOnInit() {
    console.log(this.chartItem);
  }
 updateCall() { 
   console.log(this.editForm.value);
  }
}
