import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Website } from 'src/app/_models/website';
@Component({
  selector: 'app-website-newwebsite',
  templateUrl: './website-newwebsite.component.html',
  styleUrls: ['./website-newwebsite.component.scss']
})
export class WebsiteNewwebsiteComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  @ViewChild('websiteForm') websiteForm: NgForm;
  website = {} as NewWebsite;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  updateGap() {

    console.log(this.website);
    this.emittedValues.emit(this.website);
    this.bsModalRef.hide();
  }
  onChange(e) {}
}
export class NewWebsite {
  url: string;
  name: string;
}
