import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';
import { FormName } from 'src/app/_models/formName';
import { Item } from 'src/app/_models/item';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { VehicleOptions } from 'src/app/_models/vehicleOptions';
import { AuthService } from 'src/app/_services/auth.service';
import { ItemService } from 'src/app/_services/itemservices/item.service';
import { ListService } from 'src/app/_services/listServices/list.service';
import { VehicleService } from 'src/app/_services/vehicleserv/vehicle.service';


@Component({
  selector: 'app-inventory-desk',
  templateUrl: './inventory-desk.component.html',
  styleUrls: ['./inventory-desk.component.css'],
})
export class InventoryDeskComponent implements OnInit {
  // edit vehicle fields
  formName: FormName;
  item: any = {soldDate: ''};
  item2: Item;
  // Desk Fields
  isVehicle = true;
  isRepairs = false;
  isImages = false;
  isDescription = false;
  isOptions = false;
  selectedCompany1: UserAssignedToCompany;

  /// veicle options
  vehicleOptionList: VehicleOptions[];
  defaultOptionList: VehicleOptions[];
  dollars: Dollars[];
  dollarTypes: DollarTypes[];
  optionResult: any;
  constructor(private authService: AuthService,
    private router: Router, private vehicleService: VehicleService,
    private route: ActivatedRoute, private listService: ListService,
    private itemService: ItemService) {
    this.authService.company.subscribe((data) => {
      this.selectedCompany1 = data;
    });
  }

  ngOnInit() {
    this.authService.company.subscribe(data =>  { this.selectedCompany1 = data; });
    this.route.data.subscribe(data => {
     this.item = data.item;
     this.item2 = data.item;
      this.optionResult = data.vehicleOptions;
      this.dollars = data.dollars.dollars;
      this.dollarTypes = data.dollars.dollarTypes;
    });
    this.authService.currentFormNames.subscribe(data => {
      data.forEach(element => {
        if (element.value === 'VehicleDetails'){ this.formName = element; }
      });
    });
    this.vehicleService.setItemToCache(this.item2);
    this.listService.changeItemVehicleEdit(this.item);
    this.listService.changeDollars(this.dollars);
    this.listService.changeDollarTypes(this.dollarTypes);
  //  this.listService.changeVehicleLavelFormName(this.formName);
    this.defaultOptionList = this.optionResult.defaultOptionList;

  }
  tabClicked(e) {
    this.isVehicle = false;
    this.isRepairs = false;
    this.isImages = false;
    this.isDescription = false;
    this.isOptions = false;
    if (e === 'isVehicle') {
      this.isVehicle = true;
      // this.router.navigate([
      //   'vehicledashboard/editvehicle/' +
      //     this.selectedCompany1.parentGuid + '/' + this.item.id,
      // ]);
    }
    if (e === 'isRepairs') {
      this.isRepairs = true;
      // this.router.navigate([
      //   'vehicledashboard/addvehicle/' + this.selectedCompany1.parentGuid + '/' + this.item.id,
      // ]);
    }
    if (e === 'isImages') {
      this.isImages = true;
      // this.router.navigate([
      //   'vehicledashboard/addvehicle/' + this.selectedCompany1.parentGuid + '/' + this.item.id,
      // ]);
    }
    if (e === 'isOptions') {
      this.isOptions = true;
      // this.router.navigate([
      //   'vehicledashboard/addvehicle/' +
      //     this.selectedCompany1.parentGuid + '/' + this.item.id,
      // ]);
    }
    if (e === 'isDescription') {
      this.isDescription = true;
      // this.router.navigate([
      //   'vehicledashboard/addvehicle/' + this.selectedCompany1.parentGuid + '/' + this.item.id,
      // ]);
    }
  }
}
