import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Website } from 'src/app/_models/website';
import { Setting } from 'src/app/_models/setting';
import { ToastrService } from 'ngx-toastr';
import { CompanySettingsService } from 'src/app/_services/advertising/company-settings.service';
import { WebsiteService } from 'src/app/_services/advertising/website.service';

@Component({
  selector: 'app-website-settings',
  templateUrl: './website-settings.component.html',
  styleUrls: ['./website-settings.component.css']
})
export class WebsiteSettingsComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() website: Website;
  companyGuid: string;
  settings: Setting;
  isSelected: boolean;
  constructor(private alertify: ToastrService,
    private websiteService: WebsiteService,
    private companySettings: CompanySettingsService) { }

  ngOnInit() {
  //  
  this.websiteService.website.subscribe(data => {
    this.website = data;
    this.settings = data.settings;
  });
  this.getSettings();
  }
  getSettings() {
    this.companySettings.get(this.website.settingsId).subscribe(data => { this.settings = data; },
      error => { console.log(error); }, () => { this.alertify.success('Settings Recived');  this.editForm.form.markAsPristine();  });
  }

  onSubmit(e) {
    this.companySettings.edit(this.settings).subscribe(data => { }, error => {
      this.alertify.error(error);
    }, () => { this.alertify.success('Success'); this.editForm.form.markAsPristine(); });
  }
}
