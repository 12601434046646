import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { QbInvoiceDefault } from 'src/app/_models/DTOS/quickbooksdtos/QbInvoiceDefaultDto';
import { QuickBooksAccounts } from 'src/app/_models/DTOS/quickbooksdtos/QuickBooksAccounts';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { QbDefaultsService } from 'src/app/_services/quickbooksservices/qb-defaults.service';
import { QuickBooksService } from 'src/app/_services/quickbooksservices/quickbooksservice';
import { QbdefaultsModalComponent } from './qbdefaults-modal/qbdefaults-modal.component';

@Component({
  selector: 'app-quickbooks-saledefaults',
  templateUrl: './quickbooks-saledefaults.component.html',
  styleUrls: ['./quickbooks-saledefaults.component.css']
})
export class QuickbooksSaledefaultsComponent implements OnInit {
  realmId: string;
  bsModalRef: any;
  qbDefaults = [] as QbInvoiceDefault[];
  qbDefault = {} as QbInvoiceDefault;
  userAssignedToCompany: UserAssignedToCompany;
  inventoriedAssetAccounts = [] as QuickBooksAccounts[];
  qbExpenseAccounts = [] as QuickBooksAccounts[];
  constructor(private qbDefaultsService: QbDefaultsService,
   private qbService: QuickBooksService,
   private authService: AuthService,
   private route: ActivatedRoute,
   private alertify: ToastrService,
   private modalService: BsModalService) { }

  ngOnInit() {
    this.qbService.getRealmId().subscribe(data => {
      this.realmId = data;
      this.authService.company.subscribe(data2 => {
        this.userAssignedToCompany = data2;
      });
    });
    this.route.data.subscribe(data => {
      this.inventoriedAssetAccounts = data.inventoriedAssetAccounts;
      this.qbDefaults = data.qbDefaults;
      this.qbExpenseAccounts = data.qbExpenseAccounts;
    });
    console.log(this.inventoriedAssetAccounts);
    console.log(this.qbDefaults);
    console.log(this.qbExpenseAccounts);
  }
  createDefaultAcount() {
    this.qbService.createDefaultAccount(this.userAssignedToCompany.parentGuid, this.realmId).subscribe(data => {console.log(data); });
  }
  createDefaultItems() {
    this.qbService.createDefaultItems(this.userAssignedToCompany.parentGuid, this.realmId).subscribe(data => {console.log(data); });
  }
  add() {}
  editStatus(e: QbInvoiceDefault) {
    const initialState = {
      qbInvoiceDefault: e,
      inventoriedAssetAccounts: this.inventoriedAssetAccounts,
      qbExpenseAccounts: this.qbExpenseAccounts
    };
    this.bsModalRef = this.modalService.show(QbdefaultsModalComponent, {initialState, class: 'my-class'});
    this.bsModalRef.content.emittedValues.subscribe((values) => {
      console.log(values);
        this.alertify.success('Success');
      }, error => { this.alertify.error(error); });
    console.log(e);
  }
}
