import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AccountService } from '../../_services/account.service';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/_models/user';
import { LoginResetPasswordDto } from 'src/app/_models/DTOS/LoginResetPasswordDto';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;
  @Input() appUser: User;
  maxDate: Date;
  validationErrors: string[] = [];
  user = {} as  any;
  toSubmit: LoginResetPasswordDto = {
    comfirmPassword: '',
    email: '',
    newPassword: '',
    oldPassword: '',
    password: ''
  };
  constructor(private accountService: AccountService, private toastr: ToastrService,
    private fb: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.accountService.currentUser$.pipe(take(1)).subscribe(user => this.appUser = user);
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
  }


  matchValues(matchTo: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control?.value === control?.parent?.controls[matchTo].value
        ? null : {isMatching: true};
    };
  }

  register() {
    // console.log(this.editForm.value);
    // this.user = this.editForm.value;
    // this.toSubmit.newPassword = this.user.newPassword;
    // this.toSubmit.oldPassword = this.user.oldPassword;
     this.user.email = this.appUser.email;
    console.log(this.user);
    if (this.user.comfirmPassword !== this.user.newPassword) {
      this.toastr.error('Passwords do not match.');
    }
    if (this.user.comfirmPassword === this.user.newPassword) {
      // this.accountService.resetPassword(this.user).subscribe(response => {
      //  }, error => {
      //    this.validationErrors = error;
      //  });
    }
  }

  cancel() {
    // this.cancelRegister.emit(false);
  }

}
