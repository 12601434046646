import { Component, OnInit } from '@angular/core';
import { VideoService } from 'src/app/video/video.service';
import { SupportSubMenus } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/SupportSubMenus';
import { SupportSubMenuVideo } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/SupportSubMenuVideo';
import { SupportMenu } from 'src/app/_models/SupportMenus/SupportMenu';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';

@Component({
  selector: 'app-videonotlogged',
  templateUrl: './videonotlogged.component.html',
  styleUrls: ['./videonotlogged.component.css']
})
export class VideonotloggedComponent implements OnInit {
  spanishLabel: string;
  userAssignedToCompany: UserAssignedToCompany;
  supportSubMenuVideos = [] as SupportSubMenuVideo[];
  subMenu = [] as SupportSubMenus[];
  supportVideo = {} as SupportMenu;
  selectedIndex: any;
  isSpanish = false;
  baseUrl = 'https://www.youtube.com/embed/';
  constructor(private videoService: VideoService) { }

  ngOnInit() {
    this.spanishLabel = 'Spanish Videos';
    this.getMenus();
  }
  setIndex(index: number, item: SupportSubMenus) {
    this.selectedIndex = index;
    this.supportSubMenuVideos = item.supportSubMenuVideos;
    console.log(item.supportSubMenuVideos);
 }
  getMenus() {
    this.videoService.getVideosMenusnotLogged().subscribe((data: SupportSubMenus[]) => {
      this.subMenu = data;
      console.log(data);
      this.setIndex(0, data[0]);
    }, error => {
      console.log(error);
    }, () => {

    });
  }
  filterSpanish() {
    this.isSpanish = !this.isSpanish;
    if (!this.isSpanish) { this.spanishLabel = 'Spanish Videos'; }
    if (this.isSpanish) { this.spanishLabel = 'English Videos'; }
    this.supportSubMenuVideos = this.supportSubMenuVideos.filter(
      video => video.spanish === this.isSpanish);
      console.log(this.isSpanish);
      console.log(this.supportSubMenuVideos);
  }
   getSubMenusByLanguage(){
    // this.videoService.getSubMenuById(2).subscribe(data => {
    // //  this.subMenu = data;
    //   this.supportSubMenuVideos = data[this.selectedIndex].supportSubMenuVideos;
    //   console.log(data[this.selectedIndex].supportSubMenuVideos);
    //  // this.videoService.supportSubMenusCache = data;
    // }, error => {}, () => {
    //   this.filterSpanish();
    // });
  }
}
