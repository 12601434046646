import { IfStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddsalenoteModalComponent } from 'src/app/modals/addsalenote-modal/addsalenote-modal.component';
import { MiscchargAddComponent } from 'src/app/modals/misccharg-add/misccharg-add.component';
import { MiscchargeEditComponent } from 'src/app/modals/misccharge-edit/misccharge-edit.component';
import { ProfitItemsComponent } from 'src/app/modals/profit-items/profit-items.component';
import { AmortisationClass } from 'src/app/_classes/dealinfoclasses/amortizatincl';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';
import { ProgramDefaults } from 'src/app/_models/ProgramDefaults';
import { Sale } from 'src/app/_models/sale';
import { SaleNotes } from 'src/app/_models/SaleNote';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { UserParams } from 'src/app/_models/userParams';
import { AccountingService } from 'src/app/_services/accountingservices/accounting.service';
import { AuthService } from 'src/app/_services/auth.service';
import { DollarService } from 'src/app/_services/dollarservices/dollar.service';
import { ProgramDefaultService } from 'src/app/_services/programdefaultsservices/program-defaults.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';

@Component({
  selector: 'app-sale-washout',
  templateUrl: './sale-washout.component.html',
  styleUrls: ['./sale-washout.component.css']
})
export class SaleWashoutComponent implements OnInit {
  dollars = [] as Dollars[];
  @Input() sale: Sale;
  dollar = {} as Dollars;
  amoAmortization = [] as AmoAmortization[];
  bsModalRef: any;
  dateTime = new Date();
  selectedCompany1: UserAssignedToCompany;
  isWashout = false;
  isMiscCharges = false;
  isAmortization = false;
  isPaymentHistory = false;
  dollarTypes = [] as DollarTypes[];
  dollarTypesAll = [] as DollarTypes[];
  miscChargeDollarTypes = [] as DollarTypes[];
  isNotes = true;
  programDefaults: ProgramDefaults;
  userParams = {} as UserParams;
  saleNotes = [] as SaleNotes[];
  constructor(private modalService: BsModalService,
    private calcAmo: AmortisationClass,
    private router: Router,
    private alertify: ToastrService, private saleService: SaleService,
    private accountingService: AccountingService, private authService: AuthService,
    private dollarService: DollarService, private prgramDefaultService: ProgramDefaultService ) { }
    ngOnInit() {
      this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
      this.dollarService.getSaleDollarsForMiscCharges(this.selectedCompany1.parentGuid, this.sale.id).subscribe(data => {
        this.dollarService.setDollarsNotesDollarTypesForMiscCharges(data); // sets data so not needed again?
        this.dollars = data.dollars;
        this.dollarTypes = data.dollarTypes;
        this.saleNotes = data.saleNotes;
      }, error => { this.alertify.error(error); }, () => {
        this.alertify.success('Recieved');
      });
    }

    tabClick(e) {
      this.isMiscCharges = false;
      this.isWashout = false;
      this.isPaymentHistory = false;
      this.isNotes = false;
      this.isAmortization = false;
      if (e === 'isAmortization') { this.isAmortization = true;
        this.calcAmo.savedAmortization.subscribe(data => { this.amoAmortization = data; });
      }
      if (e === 'isNotes') { this.isNotes = true;
        this.dollarService.getSaleDollarsForMiscCharges(this.selectedCompany1.parentGuid, this.sale.id).subscribe(data => {
          this.dollarService.setDollarsNotesDollarTypesForMiscCharges(data); // sets data so not needed again?
          this.dollars = data.dollars;
          this.dollarTypes = data.dollarTypes;
          this.saleNotes = data.saleNotes;
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Recieved');
        });
      }
      if (e === 'isWashout') { this.isWashout = true;
        this.userParams.searchFor = this.sale?.vehiclePurchased?.id.toString();
        this.accountingService.retrievePagedRecievables(this.selectedCompany1.parentGuid, 1, 1000, this.userParams).subscribe(data => {
          this.dollars = data.result;
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Recieved');
          this.dollarService.getDollarTypesForSale(this.selectedCompany1.parentGuid, this.sale.vehiclePurchased.id).subscribe(data => {
            this.dollarTypes = data.dollarTypes;
            this.dollarTypesAll = data.dollarTypesAll;
             this.programDefaults = data.programDefaults;
          });
        });
      }

      if (e === 'isMiscCharges' ) { this.isMiscCharges = true;
        this.dollarService.getSaleDollarsForMiscCharges(this.selectedCompany1.parentGuid, this.sale.id).subscribe(data => {
          this.dollarService.setDollarsNotesDollarTypesForMiscCharges(data); // sets data so not needed again?
          this.dollars = data.dollars;
          this.miscChargeDollarTypes = data.dollarTypes;
          this.dollarTypes = data.dollarTypes;
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Recieved');
          // this.dollarService.getDollarTypesForSale(this.selectedCompany1.parentGuid, this.sale.vehiclePurchased.id).subscribe(data => {
          //   this.dollarTypes = data;
          // });
        });
      }
      if (e === 'isPaymentHistory') {
        this.isPaymentHistory = true;
        console.log(this.sale.amoLoans.amoPayments);
      }
    }

    addSaleCost() {
      console.log(this.sale);
      const config = {
        class: 'modal-lg',
        initialState: {
         sale: this.sale,
         dollarTypes: this.dollarTypes,
        }
      };
      this.bsModalRef = this.modalService.show(MiscchargAddComponent, config);
      this.bsModalRef.content.emittedValues.subscribe(values => {
        this.dollarService.postNewMiscCost(this.selectedCompany1.parentGuid, values, this.sale.id, this.sale.vehiclePurchased.id).subscribe(data => {
          this.dollars = data.dollars;
          this.dollarService.setDollarsNotesDollarTypesForMiscCharges(data);
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Note Saved');
          console.log(this.dollars);
        });
      });
    }

    addNote() {
      console.log('reached gap');
      const config = {
        class: 'modal-lg',
        initialState: {
         sale: this.sale
        }
      };
      this.bsModalRef = this.modalService.show(AddsalenoteModalComponent, config);
      this.bsModalRef.content.emittedValues.subscribe(values => {
        this.saleService.addSaleNote(this.selectedCompany1.parentGuid, values, this.sale.id).subscribe(data => {
          this.saleNotes = data;
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Note Saved');
          console.log(this.sale.saleNotes);
        });
      });
    }
    addMiscCharge() {
        const config = {
          class: 'modal-lg',
          initialState: {
          sale: this.sale,
          dollarTypes: this.miscChargeDollarTypes,
          }
        };
        this.bsModalRef = this.modalService.show(MiscchargAddComponent, config);
        this.bsModalRef.content.emittedValues.subscribe(values => {
          this.dollarService.postNewMiscCost(this.selectedCompany1.parentGuid, values, this.sale.id, this.sale.vehiclePurchased.id).subscribe(data => {
            this.dollars = data.dollars;
            this.dollarService.setDollarsNotesDollarTypesForMiscCharges(data);
          }, error => { this.alertify.error(error); }, () => {
            this.alertify.success('Note Saved');
          });
        });
      }

  showProfitItems() {
    console.log('reached gap');
    const initialState = {
       parentGuid: this.selectedCompany1.parentGuid,
       programDefaults: this.programDefaults,
    };
  this.bsModalRef = this.modalService.show(ProfitItemsComponent, {initialState});
    this.bsModalRef.content.emittedValues.subscribe(values => {
      this.prgramDefaultService.updateProgramDefaults(this.selectedCompany1.parentGuid, values).subscribe(data => {
      }, error => { this.alertify.error(error); }, () => {
        this.alertify.success('Defaults Saved');
      });
    });
  }

  tabelViewClick(){
    this.router.navigate(['saledashboard/saledashboard/' + this.selectedCompany1.parentGuid]);
  }
  }
