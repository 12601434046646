import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormName } from 'src/app/_models/formName';

@Component({
  selector: 'app-add-form-modal',
  templateUrl: './add-form-modal.component.html',
  styleUrls: ['./add-form-modal.component.css']
})
export class AddFormModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Output() formName = new EventEmitter();
  formNameToCreate: FormName;
  ngOnInit() {
  }

  update() {
    this.formName.emit(this.editForm.value);
    this.bsModalRef.hide();
  }
}
