import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SaleType } from '../_models/saletype';
import { SaleTypeService } from '../_services/saleservices/sale-types.service';
import { QbDefaultsService } from '../_services/quickbooksservices/qb-defaults.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class QbDefaultsInventoriedAssetsResolver implements Resolve<any[]> {
    constructor(private repo: QbDefaultsService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
      //  console.log(route.params['parentGuid']);
        return this.repo.getInventoriedAssetAccounts(route.params['parentGuid'], route.params['realmId']).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving inventoried QB Accounts.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}