import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';
import { PeopleRoutingModule } from './people-routing.module';
import { PeopleListComponent } from './people-list/people-list.component';
import { AddPersonModalComponent } from './add-person-modal/add-person-modal.component';
import { PersonGridCardComponent } from './person-grid-card/person-grid-card.component';
import { AddnewPersonmodalComponent } from './addnew-personmodal/addnew-personmodal.component';
import { PeopleQblistComponent } from './people-qblist/people-qblist.component';

@NgModule({
    declarations: [
        PeopleListComponent,
        AddPersonModalComponent,
        PersonGridCardComponent,
        AddnewPersonmodalComponent,
        PeopleQblistComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        AgChartsAngularModule,
        PeopleRoutingModule,
        AgGridModule.withComponents([]),
    ]
})
export class PeopleModule { }
