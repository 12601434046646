import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { VehicleBuyersGuideSystemsCovered } from 'src/app/_models/buyersguidemodels/VehicleBuyersGuideSystemsCovered';


@Component({
  selector: 'app-systemcoverededit',
  templateUrl: './systemcoverededit.component.html',
  styleUrls: ['./systemcoverededit.component.css']
})
export class SystemcoverededitComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Output() emittedValues = new EventEmitter();
  @Input() vehicleBuyersGuideSystemsCovered = {} as VehicleBuyersGuideSystemsCovered;
  id: number;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    console.log(this.vehicleBuyersGuideSystemsCovered);
    this.id = this.vehicleBuyersGuideSystemsCovered.id;
  }
  close() {
    this.bsModalRef.hide();
  }
  save() {
    console.log(this.editForm.value);
    this.vehicleBuyersGuideSystemsCovered.id = this.id;
    this.emittedValues.next(this.vehicleBuyersGuideSystemsCovered);
    this.bsModalRef.hide();
  }
}
