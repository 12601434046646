import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PdfForms } from 'src/app/_models/PdfForms';
import { PdfFormTypes } from 'src/app/_models/PdfFormTypes';
import { PdfFormPackages } from 'src/app/_models/pdfformpackages';
import { PdfFieldServer } from 'src/app/_models/PdfFieldServer';
import { PdfForCreationCustom } from 'src/app/_models/DTOS/PdfForCreationCustom';
import { map } from 'rxjs/operators';
import { PdfFieldNameType } from 'src/app/_models/PdfFieldNameType';
import { PdfFIeldName } from 'src/app/_models/PdfFiledName';
import { PdfFormCalculationNoIdDto } from 'src/app/_models/DTOS/PdfFormCalculationNoIdDto';
import { PdfFormCalculation } from 'src/app/_models/PdfFormCalculation';
import { AuthService } from '../auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { ShopParams } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/ShopParams';
import { IPdfPagination } from 'src/app/_models/DTOS/pdfdtos/pdfimportpdfsdtos/IPdfPagination';
import { PdfSetEmbedPdfFieldLocationsResultDto } from 'src/app/_models/DTOS/pdfdtos/PdfSetEmbedPdfFieldLocationsResultDto';
import { PdfFormTypesCategory } from 'src/app/_models/PDFentites/PdfFormTypesCategory';
import { PdfStateList } from 'src/app/_models/PDFentites/PdfStateList';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  @Output() emittedVieldName = new EventEmitter();
  baseUrl = environment.apiUrl;
  masterParent = environment.masterParentGuid;
  pdfFormsCached = [] as PdfForms[];
  pdfFormTypesCached = [] as PdfFormTypes[];

  pdfForm = new BehaviorSubject<PdfForms>(null);
  currentpdfForm = this.pdfForm.asObservable();

  pdfFormType = new BehaviorSubject<PdfFormTypes>(null);
  currentpdfFormType = this.pdfFormType.asObservable();

  isPdfCalculator = new BehaviorSubject<boolean>(false);
  currentIsPdfCalculator = this.isPdfCalculator.asObservable();
  @Output() isPdfCalculatorEmitted = new EventEmitter();

  isWithData = new BehaviorSubject<boolean>(null);
  currentisWithData = this.isWithData.asObservable();

  isEmpty = new BehaviorSubject<boolean>(null);
  currentisEmpty = this.isEmpty.asObservable();

  serverPdfFields = new BehaviorSubject<PdfFieldServer[]>(null);
  currentserverPdfFields = this.serverPdfFields.asObservable();

  serverUint8ByteArray = new BehaviorSubject<any>(null);
  currentUint8ByteArray = this.serverUint8ByteArray.asObservable();

  base64Array = new BehaviorSubject<any>(null);
  currentbase64Array = this.base64Array.asObservable();

  saleAccountId = new BehaviorSubject<number>(null);
  currentsaleAccountId = this.saleAccountId.asObservable();

  pdfFieldNameTypes = new BehaviorSubject<PdfFieldNameType[]>(null);
  currentpdfFieldNameTypes = this.pdfFieldNameTypes.asObservable();
  pdfFieldNameTypesCached = [] as PdfFieldNameType[];
  @Output() emittedpdfFieldNameTypes = new EventEmitter();

  pdfFieldName = new BehaviorSubject<PdfFIeldName>(null);
  currentpdfFieldName = this.pdfFieldName.asObservable();

  pdfFieldCstCalculation = new BehaviorSubject<PdfFormCalculation[]>(null);
  currentpdfFieldCstCalculation = this.pdfFieldCstCalculation.asObservable();
  userAssignedToCompany: UserAssignedToCompany;
  parentId: string;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.company.subscribe(data => { this.parentId = data?.parentGuid; });
  }
  setSaleAccountId(id) { this.saleAccountId.next(id); }
  getSaleAccountId() {
    console.log(this.currentsaleAccountId);
    return this.currentsaleAccountId;
  }
  setPdfFieldNameType(item) {
    this.emittedpdfFieldNameTypes.emit(item);
    this.pdfFieldNameTypes.next(item);
  }
  getPdfFIeldNameTypes() {
    return this.currentpdfFieldNameTypes;
  }
  changepdfFieldCstCalculation(item) {
   // this.pdfFieldCstCalculation.emit(item);
    this.pdfFieldCstCalculation.next(item);
  }
  getpdfFieldCstCalculation(){
    return this.currentpdfFieldCstCalculation;
  }

  changeIsCalculator(item) {
    this.isPdfCalculatorEmitted.emit(item);
    this.isPdfCalculator.next(item);
  }
  getIsCalculator(){
    return this.currentIsPdfCalculator;
  }


  changePdfFIeldName(item) {
    this.emittedVieldName.emit(item);
    this.pdfFieldName.next(item);
  }
  getCurrentPdfFieldName(){
    return this.currentpdfFieldName;
  }
    /** if pdf form is filled nothing empty form */
  changeIsEmpty(isEmpty) {
    this.isEmpty.next(isEmpty);
  }
  getIsEmpty() {
    return this.currentisEmpty;
  }
  /** if pdf form is filled with live data
   * if true and if empty is false live data; if both false fills form field name
   */
  changeIsWithData(isWithData) {
    this.isWithData.next(isWithData);
  }
  getIsWithData() {
    return this.currentisWithData;
  }
  /**
   * SET global base65
   * @param base64 as it says
   */
  changeBase64Array(base64) {
    this.base64Array.next(base64);
  }
  /** retrieve glogal base64 */
  getBase64Array() {
    return this.currentbase64Array;
  }
  changeUint8ByteArray(byte) {
    this.serverUint8ByteArray.next(byte);
  }
  getUint8ByteArray() {
    return this.currentUint8ByteArray;
  }
  changePdfFormType(pdfFormType: PdfFormTypes) {
    this.pdfFormType.next(pdfFormType);
  }
  getPdfFormType() {
    return this.currentpdfFormType;
  }
  changePdfForm(pdfForm: PdfForms) {
    this.pdfForm.next(pdfForm);
  }
  /** used on EDIT mode of pdf!
   * pdfFieldServer are preset field names to be added to a pdf
   * they are viewable under tabs in edit mode such as Buyer, CoBuyer tab
   * @param data pdfFieldServer is used to transfer FIELD of pdf
   * to the pdfFieldServer
   */
  setPdfFieldServer(data: PdfFieldServer[]) {
    this.serverPdfFields.next(data);
  }

  /**
   * used on EDIT mode of pdf!
   * pdfFieldServer are preset field names to be added to a pdf
   * they are viewable under tabs in edit mode such as Buyer, CoBuyer tab
   *  pdfFieldServer is used to transfer FIELD of pdf
   * to the pdfFieldServer
   */
  getPdfFieldServer(): Observable<PdfFieldServer[]> {
    return this.currentserverPdfFields;
  }
  /** TREE node of REPORTS MANAGER
   * @param guid parent guid
   */
  getPdfFormList(guid: string): Observable<PdfForms[]> {
    if (this.pdfFormsCached.length > 0) {
      return of(this.pdfFormsCached);
    }
    return this.http.get<PdfForms[]>(
      this.baseUrl + 'pdf/getpdfformsforuser/' + guid
    );
  }
  setPdfFormList(data: PdfForms[]) {
    this.pdfFormsCached = data;
  }

  setPdfFormTypes(data: PdfFormTypes[]) {
    this.pdfFormTypesCached = data;
  }
  createPdfType(formTypeName: string, pdfCategoryTypesId, parentGuid: string): Observable<PdfFormTypes>{
    return this.http.get<PdfFormTypes>(
      this.baseUrl + 'pdf/createpdfformtypebystateandcategory/' + parentGuid + '/'
      + pdfCategoryTypesId  + '/' + formTypeName);
  }
  getPdfStateList(guid: string): Observable<PdfStateList[]> {
    return this.http.get<PdfStateList[]>(
      this.baseUrl + 'pdf/getpdfstatelist/' + guid
    );
  }
  getPdfMasterStateList(): Observable<PdfStateList[]> {
    return this.http.get<PdfStateList[]>(
      this.baseUrl + 'pdf/getpdfstatelist/' + this.masterParent
    );
  }
  getPdfFormTypeCategoryListByStateId(guid: string, stateid: number): Observable<PdfFormTypesCategory[]> {
    return this.http.get<PdfFormTypesCategory[]>(
      this.baseUrl + 'pdf/getpdfformtypescategoryswithstateid/' + guid + '/' + stateid
    );
  }
  getPdfFormTypeCategoryList(guid: string): Observable<PdfFormTypesCategory[]> {
    return this.http.get<PdfFormTypesCategory[]>(
      this.baseUrl + 'pdf/getpdfformtypescategorysforuser/' + guid
    );
  }
  getPdfFormTypeList(guid: string): Observable<PdfFormTypes[]> {
    return this.http.get<PdfFormTypes[]>(
      this.baseUrl + 'pdf/getpdfformtypesforuser/' + guid
    );
  }
  getPdfFormTypeListByCategoryId(guid: string, pdfFormTypeCategoryId): Observable<PdfFormTypes[]> {
    return this.http.get<PdfFormTypes[]>(
      this.baseUrl + 'pdf/getpdfformtypesforuserbycategoryid/' + guid + '/' + pdfFormTypeCategoryId
    );
  }
  /** set pdffieldname.deleteddate = today */
  deletePdfFieldName(pdffieldid: number): Observable<PdfFormTypes[]> {
    return this.http.get<PdfFormTypes[]>(
      this.baseUrl + 'pdf/deletefieldname/' + this.parentId + '/' + pdffieldid
    );
  }
/** Creates custom calculation PdfFieldName */
  createPdfFieldName(pdfFormCalculationNoIdDto: PdfFIeldName): Observable<PdfFieldNameType[]> {
    return this.http.post<PdfFieldNameType[]>(
      this.baseUrl + 'pdf/createpdffieldName/' + this.parentId, pdfFormCalculationNoIdDto
    );
  }
 /** updates custom calculation PdfFieldName */
  updatePdfFieldName(pdfFieldName: PdfFIeldName): Observable<PdfFieldNameType[]> {
    return this.http.post<PdfFieldNameType[]>(
      this.baseUrl + 'pdf/updatecalculation/' + this.parentId, pdfFieldName
    );
  }
  getPdfForm(pdfFormId): Observable<PdfForms> {
    return this.http.get<PdfForms>(
      this.baseUrl + 'pdf/getpdfform/' + pdfFormId
    );
  }
  setDefaultStates(guid: string, stateId: number) {
    return this.http.get(this.baseUrl + 'pdf/setdfaultstate/' + stateId + '/'
    + guid);
  }
  deleletePdf(guid: string, pdfid: number): Observable<any> {
    return this.http.get<any>(
      this.baseUrl + 'pdf/deletepdf/' + guid + '/' + pdfid
    );
  }
  renameTreeView(pdfname: string, pdfid: number, isUseUniqueName: boolean) {
    return this.http.get(this.baseUrl + 'pdf/renametreeview/' + pdfname + '/'
    + pdfid + '/' + isUseUniqueName);
  }
  renamePdf(shopParams: ShopParams, pdfname: string, pdfid: number, isUseUniqueName: boolean) {
    let params = new HttpParams();
    if (shopParams.categoryId !== 0) {
      params = params.append('categoryId', shopParams.categoryId.toString());
    }
    if (shopParams.typeId !== 0) {
      params = params.append('typeId', shopParams.typeId.toString());
    }
    if (shopParams.search) {
      params = params.append('search', shopParams.search);
    }
    params = params.append('sort', shopParams.sort.toString());
    params = params.append('pageIndex', shopParams.pageNumber.toString());
    params = params.append('pageSize', shopParams.pageSize.toString());
    return this.http.get<IPdfPagination>(this.baseUrl + 'pdf/renamepdfnoguid/' + pdfname + '/'
    + pdfid + '/' + isUseUniqueName, {observe: 'response', params})
    .pipe(
      map(response => {
        return response.body;
      })
    );
  }
  public uploadPdfNewlyCreated(file: any, pdfid, pdfName): Observable<any> {
    const formData = new FormData();
    for (const prop in file) {
      if (!file.hasOwnProperty(prop)) { continue; }
      formData.append(prop , file[prop]);
    }
    return this.http.post<any>(`${this.baseUrl}pdfupload/pdfformscreatepdf/${pdfid}/${pdfName}`, formData);
  }

  public uploadPdfDeleteFields(file: any, pdfid): Observable<any> {
    const formData = new FormData();
    for (const prop in file) {
      if (!file.hasOwnProperty(prop)) { continue; }
      formData.append(prop , file[prop]);
    }
    return this.http.post<any>(`${this.baseUrl}pdfupload/pdfformsdeletefieldsonpdf/${pdfid}`, formData);
  }

  public updatePdfFIelds(file: any, pdfid): Observable<PdfSetEmbedPdfFieldLocationsResultDto> {
    const formData = new FormData();
    for (const prop in file) {
      if (!file.hasOwnProperty(prop)) { continue; }
      formData.append(prop , file[prop]);
    }
   // .pipe(map(x => true))
    return this.http.post<PdfSetEmbedPdfFieldLocationsResultDto>(`${this.baseUrl}pdfupload/pdfformsupdatepdffields/${pdfid}`, formData);
  }


  getPdfFieldNameTypes(): Observable<PdfFieldNameType[]> {
    if (this.pdfFieldNameTypesCached.length > 0) {
      return of(this.pdfFieldNameTypesCached);
    }
    return this.http.get<PdfFieldNameType[]>(
      this.baseUrl + 'pdf/getpdffieldnametypes/' + this.parentId
    );
  }


  deletePdf(shopParams: ShopParams, id: number){
    let params = new HttpParams();
   if (shopParams.categoryId !== 0) {
     params = params.append('categoryId', shopParams.categoryId.toString());
   }
   if (shopParams.typeId !== 0) {
     params = params.append('typeId', shopParams.typeId.toString());
   }
   if (shopParams.search) {
     params = params.append('search', shopParams.search);
   }
   params = params.append('sort', shopParams.sort.toString());
   params = params.append('pageIndex', shopParams.pageNumber.toString());
   params = params.append('pageSize', shopParams.pageSize.toString());

   return this.http.get<IPdfPagination>(this.baseUrl + 'pdf/markPdfdeleted/' + id, {observe: 'response', params})
   .pipe(
     map(response => {
       return response.body;
     })
   );
  // return this.http.get<PdfForms[]>(this.baseUrl + 'pdf/markPdfdeleted/' + id);
 }
 getPdfsPaged(shopParams: ShopParams): Observable<IPdfPagination> {
  let params = new HttpParams();
  if (shopParams.categoryId !== 0) {
    params = params.append('categoryId', shopParams.categoryId.toString());
  }
  if (shopParams.typeId !== 0) {
    params = params.append('typeId', shopParams.typeId.toString());
  }
  if (shopParams.search) {
    params = params.append('search', shopParams.search);
  }
  shopParams.pageSize = 50;
  params = params.append('sort', shopParams.sort.toString());
  params = params.append('pageIndex', shopParams.pageNumber.toString());
  params = params.append('pageSize', shopParams.pageSize.toString());
console.log(params);
  return this.http.get<IPdfPagination>(this.baseUrl + 'pdf/getpdfs/' + this.parentId, {observe: 'response', params})
  .pipe(
    map(response => {
      return response.body;
    })
  );
 }


}
