import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { PaymentType } from 'src/app/_models/paymenttype';
import { ActivatedRoute } from '@angular/router';

import { AlertifyService } from 'src/app/_services/alertify.service';

import { PaymentTypeModalComponent } from '../payment-type-modal/payment-type-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PaymentTypeService } from 'src/app/_services/paymenttypeservices/payment-type.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-paymenttype-grid',
  templateUrl: './paymenttype-grid.component.html',
  styleUrls: ['./paymenttype-grid.component.css']
})
export class PaymenttypeGridComponent implements OnInit {
  selectedCompany1: UserAssignedToCompany;
  chartPaymentTypes: PaymentType[];
  isDelete: boolean;
  bsModalRef: any;
  constructor(private modalService: BsModalService, private authService: AuthService, private route: ActivatedRoute,
    private paymentTypeService: PaymentTypeService, private alertify: ToastrService) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      const newLocal = 'chartPaymentTypes';
      this.chartPaymentTypes = data[newLocal];
    });
  }
  editPaymentType(e: PaymentType) {
    const initialState = {
     paymentType:  e
    };
    this.bsModalRef = this.modalService.show(PaymentTypeModalComponent, { initialState });
    this.bsModalRef.content.updatePaymentType.subscribe((values) => {
      e.value = values.value;
      this.paymentTypeService.editPaymentType(this.selectedCompany1.parentGuid, e).subscribe(data => {
        this.chartPaymentTypes = data;
      }, error => {this.alertify.error(error); }, () => {this.alertify.success('Payment Type Updated'); });
    });
}
addPaymentType() {
  this.bsModalRef = this.modalService.show(PaymentTypeModalComponent);
  this.bsModalRef.content.updatePaymentType.subscribe((values) => {
    this.paymentTypeService.addPaymentType(this.selectedCompany1.parentGuid, values).subscribe(data => {
      this.chartPaymentTypes = data;
    }, error => {this.alertify.error(error); }, () => {this.alertify.success('Payment Type Added'); });
  });
}

  delete(e) {
      this.isDelete = false;
      if (e.deletedDate == null) {
        this.isDelete = true;
      }
      this.paymentTypeService.deletePaymentType(e.id, this.selectedCompany1.parentGuid, this.isDelete).subscribe(data => {
        this.chartPaymentTypes = data;
      }, error => { this.alertify.error(error); }, () => {
        this.alertify.success('Payment Types Updated');
      });
  }
}
