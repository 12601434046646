import { Injectable } from '@angular/core';
import { promise } from 'protractor';
import { Observable, of } from 'rxjs';

import { PdfFieldNameType } from 'src/app/_models/PdfFieldNameType';
import { PdfFIeldName } from 'src/app/_models/PdfFiledName';
import { Person } from 'src/app/_models/person';
import { PDFDocument, StandardFonts, rgb, PDFPage } from 'pdf-lib'


@Injectable({ providedIn: 'root' })
export class PDFLabel {
    buyerFieldNameType = {} as PdfFieldNameType;
    buyerField = {} as PdfFIeldName;

 constructor() {}

  async createFieldLabel(page: PDFPage, x: number, y, label: string): Promise<PDFPage> {
  const fontSize = 14;
  const { width, height } = page.getSize();
      page.drawText(label, {
        x,
        y: height - 4 * fontSize,
        size: fontSize,
        font: undefined,
        color: rgb(0, 0.53, 0.71),
      });
      return (page);
    }
}
