import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Person } from 'src/app/_models/person';
import moment from 'moment';
@Injectable({ providedIn: 'root' })
export class SaleDataBuyer {
    moment = require('moment');
    convertDate(date) {
        const momentDate = moment().format('L').toString();
        return momentDate;
}
    fnz02 = (num) => num.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
        })

          fnz04 = (num) => num.toLocaleString('en-US', {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
            useGrouping: false,
            })
            fnz0 = (num) => num.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
                })

      fillPdfFieldsWithPerson(person: Person, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
     // console.log(pdfFields);
     if (pdfFields.length > 0 ) {
        let  field = '';
      let newpdfField = {} as PdfSaleFields;
      const newPdfFields = [] as PdfSaleFields[];
        pdfFields.forEach(element => {
         //   console.log(element.field);
            field = element.field;
            const str = field;
            const matches = str.match(/\d+/g);
            let i;
            if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }
            field = field.replace(i.toString(), '');
        switch (field) {
            case prefix + 'FirstName': {
                if (person?.firstName === null) {person.firstName = ''; }
                newpdfField  = {
                    field: prefix + 'FirstName' + i,
                    value: person?.firstName,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'LastName': {
                if (person?.lastName === null) {person.lastName = ''; }
                newpdfField  = {
                    field: prefix + 'LastName' + i,
                    value: person?.lastName,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'MiddleName': {
                if (person?.middleName === null) {person.middleName = ''; }
                newpdfField  = {
                    field:  prefix + 'MiddleName' + i,
                    value: person?.middleName,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'FullName': {
                if (person?.lastName === null) {person.lastName = ''; }
                if (person?.middleName === null) {person.middleName = ''; }
                if (person?.firstName === null) {person.firstName = ''; }
                newpdfField  = {
                    field:  prefix + 'FullName' + i,
                    value: person?.lastName + ' ' + person?.firstName + ', ' + person?.middleName,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Address': {
                if (person?.addressDetail?.street == null) {person.addressDetail.street = ''; }
                newpdfField  = {
                    field:  prefix +  'Address' + i,
                    value: person?.addressDetail?.street,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'CityStateZip': {
                if (person?.addressDetail?.state == null) {person.addressDetail.state = ''; }
                if (person?.addressDetail?.city == null) {person.addressDetail.city = ''; }
                if (person?.addressDetail?.zip == null) {person.addressDetail.zip = ''; }
                newpdfField  = {
                    field: prefix + 'CityStateZip' + i,
                    value: person?.addressDetail?.city + ' ' + person?.addressDetail?.state + ' ' + person?.addressDetail?.zip,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'State': {
                if (person?.addressDetail?.state == null) {person.addressDetail.state = ''; }
                newpdfField  = {
                    field:  prefix +  'State' + i,
                    value: person?.addressDetail?.state,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'City': {
                if (person?.addressDetail?.city == null) {person.addressDetail.city = ''; }
                newpdfField  = {
                    field:  prefix +  'City' + i,
                    value: person?.addressDetail?.city,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'County': {
                if (person?.addressDetail?.county == null) {person.addressDetail.county = ''; }
                newpdfField  = {
                    field:  prefix +  'County' + i,
                    value: person?.addressDetail?.county,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Zip': {
                if (person?.addressDetail?.zip == null) {person.addressDetail.zip = ''; }
                newpdfField  = {
                    field:  prefix +  'Zip' + i,
                    value: person?.addressDetail?.zip,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Email': {
                if (person?.email == null) {person.email = ''; }
                newpdfField  = {
                    field:  prefix +  'Email' + i,
                    value: person?.email,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Phone': {
                if (person?.phone == null) {person.phone = ''; }
                newpdfField  = {
                    field:  prefix +  'Phone' + i,
                    value: person?.phone,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'InsuranceCollision': {
                if (person?.insuranceCollision == null) {person.insuranceCollision = false; }
                newpdfField  = {
                    field:  prefix +  'InsuranceCollision' + i,
                    value: person?.insuranceCollision.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'InsuranceDeductible': {
                if (person?.insuranceDeductible == null) {person.insuranceDeductible = 0; }
                newpdfField  = {
                    field:  prefix +  'InsuranceDeductible' + i,
                    value: person?.insuranceDeductible.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'BirthDay': {
                if (person?.dateOfBirth == null) {person.dateOfBirth =  new Date(Date.now()); }
                newpdfField  = {
                    field:  prefix + 'BirthDay' + i,
                    value: this.convertDate(person?.dateOfBirth),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'InsCompany': {
                if (person?.insuranceCompany == null) {person.insuranceCompany = ''; }
                newpdfField  = {
                    field:  prefix + 'InsCompany' + i,
                    value: person?.insuranceCompany.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'EmployerName': {
                if (person?.employer?.name == null) {person.employer.name  = ''; }
                newpdfField  = {
                    field:  prefix + 'EmployerName' + i,
                    value: person?.employer?.name.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'EmployerPhone': {
                if (person?.employer?.phone == null) {person.employer.phone  = ''; }
                newpdfField  = {
                    field:  prefix + 'EmployerPhone' + i,
                    value: person?.employer?.phone.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'InsExpires': {
                if (person?.insuranceExpires == null) {person.insuranceExpires  =  new Date(Date.now()); }
                newpdfField  = {
                    field:  prefix +  'InsExpires' + i,
                    value: this.convertDate(person?.insuranceExpires),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'InsPolicy': {
                if (person?.insurancePolicy == null) {person.insurancePolicy  = ''; }
                newpdfField  = {
                    field:  prefix + 'InsPolicy' + i,
                    value: person?.insurancePolicy.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'InsDeductible': {
                if (person?.insuranceDeductible == null) {person.insuranceDeductible  = 0; }
                newpdfField  = {
                    field:  prefix + 'InsDeductible' + i,
                    value: person?.insuranceDeductible.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'DlNumber': {
                if (person?.driversLicense == null) {person.driversLicense  = ''; }
                newpdfField  = {
                    field:  prefix + 'DlNumber' + i,
                    value: person?.driversLicense.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'DlState': {
                if (person?.driversLicenseState == null) {person.driversLicenseState  = ''; }
                newpdfField  = {
                    field:  prefix + 'DlState' + i,
                    value: person?.driversLicenseState.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'DlExpires': {
                if (person?.driverLicenseExpires == null) {person.driverLicenseExpires   =  new Date(Date.now());  }
                newpdfField  = {
                    field:  prefix + 'DlExpires' + i,
                    value: this.convertDate(person?.driverLicenseExpires),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'PhotoUrl': {
                if (person?.photoUrl == null) {person.photoUrl  = ''; }
                newpdfField  = {
                    field:  prefix + 'PhotoUrl' + i,
                    value: person?.photoUrl.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Extra': {
                if (person?.extraNumber == null) {person.extraNumber  = ''; }
                newpdfField  = {
                    field:  prefix + 'Extra' + i,
                    value: person?.extraNumber.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            default: {
                break;
             }
          }
       // }
         });
         return of(newPdfFields);
        } {
            return of(pdfFields);
        }
    }


}
