import { Injectable } from '@angular/core';
import { AmoLoan } from 'src/app/_models/amoloans';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { Person } from 'src/app/_models/person';
import { Sale } from 'src/app/_models/sale';
import { AmoLinqPaymentsResolver } from 'src/app/_resolvers/amo-retrievepayments.resolver';
import { AmoLoanService } from 'src/app/_services/amoloanservices/amoloan.service';
@Injectable({
    providedIn: 'root'
  })
 export class PersonToLinqResultToReturnDto
{

    async convertPersonToLinq(person: Person, sale: Sale): Promise<PersonLinqResultToReturnDto>{
        console.log('convert person reached');
        const linq = {} as PersonLinqResultToReturnDto;
        const amoLoans = [] as AmoLoan[];
    amoLoans.push(sale.amoLoans);
    linq.amoLoans = amoLoans;
    linq.parentGuid = person.parentGuid;
    linq.phone = person.phone;
    linq.photoUrl = person.photoUrl;
    linq.selected = true;
    linq.state = person.addressDetail.state;
    linq.street = person.addressDetail.street;
    linq.zip = person.addressDetail.zip;
        console.log(linq);
    return linq;
    }

    async convertPersonToLinqByLoan(person: Person, amoLoan: AmoLoan): Promise<PersonLinqResultToReturnDto>{
        console.log('convert person reached');
        const linq = {} as PersonLinqResultToReturnDto;
        const amoLoans = [] as AmoLoan[];
    amoLoans.push(amoLoan);
    linq.amoLoans = amoLoans;
    linq.parentGuid = person.parentGuid;
    linq.phone = person.phone;
    linq.photoUrl = person.photoUrl;
    linq.selected = true;
    linq.state = person.addressDetail.state;
    linq.street = person.addressDetail.street;
    linq.zip = person.addressDetail.zip;
        console.log(linq);
    return linq;
    }
}
