import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { QuickBooksService } from 'src/app/_services/quickbooksservices/quickbooksservice';

@Component({
  selector: 'app-qb-connect',
  templateUrl: './qb-connect.component.html',
  styleUrls: ['./qb-connect.component.css']
})
export class QbConnectComponent implements OnInit {
userAssignedToCompany: UserAssignedToCompany;
state: any;
code: any;
realmId: any;

  constructor(private quickBooksService: QuickBooksService,
     private authService: AuthService, private router: Router,
     private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params.code;
      this.state = params.state;
      this.realmId = params.realmId;
      console.log(params.state);
    });
   this.authService.company.subscribe(data => {
     this.userAssignedToCompany = data;
     if (this.realmId && this.realmId) {
       localStorage.setItem('realmId', this.realmId);
       this.quickBooksService.setRealmId(this.realmId);
      this.quickBooksService.connect(this.code, this.realmId, this.userAssignedToCompany.parentGuid).subscribe(
        data2 => {
          console.log(data2);
          this.router.navigate(['/qbmanager/connected']);
        });
    }
   // this.connectQb();
  });
  }
  connectQb() {
    this.quickBooksService.login(this.userAssignedToCompany.parentGuid)
    .subscribe(data => {
      window.location.href = data.url;
      console.log(data);
    }, err => {}, () => {
      if (this.code && this.realmId && this.state) {
      }
    });
  }
}
