import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { WebsiteNewwebsiteComponent } from 'src/app/modals/website-newwebsite/website-newwebsite.component';
import { WebsiteListToReturnDto } from 'src/app/_models/DTOS/WebsiteListToReturnDto';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Website } from 'src/app/_models/website';
import { WebsiteService } from 'src/app/_services/advertising/website.service';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-website-list',
  templateUrl: './website-list.component.html',
  styleUrls: ['./website-list.component.scss']
})
export class WebsiteListComponent implements OnInit {
  websites = [] as Website[];
  website = {} as Website;
  WebsiteListToReturnDto = [] as WebsiteListToReturnDto[];
  parentGuid: string;
  companyGuid: string;
  bsModalRef: any;
  userAssignedToCompany: UserAssignedToCompany;
    constructor(private websiteService: WebsiteService,
      private altertify: ToastrService,
      private authService: AuthService,
      private router: Router,
      private route: ActivatedRoute,
      private modalService: BsModalService
      ) { }

    ngOnInit() {
      this.authService.company.subscribe(data => { this.parentGuid = data.parentGuid; });
      this.getWebsiteList();
    }
    makePrimarySite(i) {
      console.log(i);
      this.websiteService.updateIsDefault(i.websiteGuid, i.parentGuid).subscribe(data =>{
        this.website = data;
      });
    }
    getWebsiteList() {
      this.websiteService.getWebsites().subscribe(data => { 
        this.WebsiteListToReturnDto = data;
      });
    }
    navigateCompany(website: WebsiteListToReturnDto) {
      this.router.navigate(['advertising/dashboard/' + website.websiteGuid]);
    }
    addNew() {
      const config = {
        initialState: {
         website: this.website
        }
      };
      this.bsModalRef = this.modalService.show(WebsiteNewwebsiteComponent, config);
      this.bsModalRef.content.emittedValues.subscribe(values => {
        let newWebsite = new NewWebsite();
        newWebsite = values;
        newWebsite.parentGuid = this.parentGuid;
        this.websiteService.addNewWebsite(newWebsite).subscribe(data => {
          this.altertify.success('Website added');
          console.log(data);
          this.WebsiteListToReturnDto = data;
        }, error => { this.altertify.error(error); });
        newWebsite = values;
      });
    }
  }
export class NewWebsite {
  url: string;
  name: string;
  parentGuid: string;
}
