import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/_models/user';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AccountService } from 'src/app/_services/account.service';
import { AuthService } from 'src/app/_services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bottom-footer',
  templateUrl: './bottom-footer.component.html',
  styleUrls: ['./bottom-footer.component.css']
})
export class BottomFooterComponent implements OnInit {
  today: Date;
  version = environment.version;
  user = {} as User;
  selectedCompany1: UserAssignedToCompany;
  constructor(public authService: AuthService,
    private accountService: AccountService,
    private alertify: ToastrService,
    private router: Router) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.today = new Date();
    this.accountService.currentUser$.subscribe(data4 => {  this.user = data4;  });
  }
  onRightClick() {
    return false;
  }
  logout() {
    if (localStorage.getItem('formsLabels') != null) { localStorage.removeItem('formsLabels'); }
    if (localStorage.getItem('token') != null) { localStorage.removeItem('token'); }
    if (localStorage.getItem('user') != null) { localStorage.removeItem('user'); }
    // if (localStorage.getItem('companyDepartmentTypes') != null) { localStorage.removeItem('companyDepartmentTypes'); }
    // if (localStorage.getItem('personTypes') != null) { localStorage.removeItem('personTypes'); }
    if (localStorage.getItem('userAssignedToCompany') != null) { localStorage.removeItem('userAssignedToCompany'); }
    // if (localStorage.getItem('users') != null) { localStorage.removeItem('users'); }
    // if (localStorage.getItem('website') != null) { localStorage.removeItem('website'); }
    // if (localStorage.getItem('websites') != null) { localStorage.removeItem('websites'); }
    if (localStorage.getItem('company') != null) { localStorage.removeItem('company'); }
   // localStorage.removeItem('parentComanies');
    this.authService.decodedToken = null;
    this.authService.currentUser = null;
    this.alertify.success('logged out');
    this.accountService.logout();
    this.router.navigate(['/']);
  }
}
