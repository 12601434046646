import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentType } from 'src/app/_models/PaymentType';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PaymentTypeService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getPaymentType(id: number): Observable<PaymentType> {
    return this.http.get<PaymentType>(this.baseUrl + 'sale/getpaymenttype/' + id);
  }

  getPaymentTypes(parentguid: string): Observable<PaymentType[]> {
    return this.http.get<PaymentType[]>(this.baseUrl + 'sale/getpaymenttypes/' + parentguid);
  }



  deletePaymentType(id, parentId, isdelete): Observable<PaymentType[]> {
    return this.http.get<PaymentType[]>(this.baseUrl + 'paymenttype/deletepaymenttype/' + parentId + '/' + id + '/' + isdelete);
  }

  addPaymentType(parentId, paymentType: PaymentType): Observable<PaymentType[]> {
    return this.http.put<PaymentType[]>(this.baseUrl + 'paymenttype/addpaymenttype/' + parentId, paymentType);
  }

  editPaymentType(parentId, paymentType: PaymentType): Observable<PaymentType[]> {
    return this.http.put<PaymentType[]>(this.baseUrl + 'paymenttype/editpaymenttype/' + parentId, paymentType);
  }
}
