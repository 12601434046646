import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StoreHour } from 'src/app/_models/StoreHour';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyStoreHoursService {
  baseUrl = environment.apiUrl;
  companyId: number;
constructor(private http: HttpClient) { }


edit(storehour): Observable<StoreHour> {
  return this.http.put<StoreHour>(this.baseUrl + 'companystorehours/updatecompanystorehours/', storehour);
}
}
