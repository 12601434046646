import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Website } from 'src/app/_models/website';
import { Company } from 'src/app/_models/_models/company';
import { SettingsReturned } from 'src/app/_models/DTOS/websitedtos/settingsreturned';

@Injectable({
  providedIn: 'root'
})
export class WebsitePublicService {
  baseUrl = environment.apiUrl;
  websiteId = environment.websiteId;

  constructor(private http: HttpClient) {}

  getWebsite(): Observable<Website> {
    return this.http.get<Website>(
      this.baseUrl + 'websitepublic/getwebsitebywebsiteguid/' + this.websiteId
    );
  }

  getCompany(parent?): Observable<Company> {
    return this.http.get<Company>(
      this.baseUrl + 'websitecompany/getdefaultcompanywebsiteview/' + this.websiteId
    );
  }
  getSiteVerification(): Observable<SettingsReturned> {
    return this.http.get<SettingsReturned>(
      this.baseUrl + 'settings/getgooglesiteverificationbywebsiteguid/' + this.websiteId
    );
  }
}
