import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VehicleOptions } from 'src/app/_models/vehicleOptions';
import { AuthService } from 'src/app/_services/auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { VehicleoptionsService } from 'src/app/_services/vehicleserv/vehicleoptions.service';
import { AddOptionModalComponent } from './add-option-modal/add-option-modal.component';
// 

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Item } from 'src/app/_models/item';

@Component({
  selector: 'app-addnewoptions-vehicle',
  templateUrl: './addnewoptions-vehicle.component.html',
  styleUrls: ['./addnewoptions-vehicle.component.css']
})
export class AddnewoptionsVehicleComponent implements OnInit {
@Input() vehicleDefaultOptions: VehicleOptions[];
@Input() chartItem: Item;
bsModalRef: any;
itemId: any;
vehicleOption: VehicleOptions;
selectedCompany1: UserAssignedToCompany;

  constructor(private modalService: BsModalService, private authService: AuthService,
    private route: ActivatedRoute,
    private vehicleOptionService: VehicleoptionsService,
    private alertify: ToastrService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.itemId = params.itemId;
    });
    this.authService.currentCompany.subscribe(company => { this.selectedCompany1 = company; });
    this.vehicleOptionService.currentOptionList.subscribe(data => {
      if ( data != null ) {
        this.vehicleDefaultOptions = data;
      }
    });
    }
      addOption(i: VehicleOptions) {
        this.vehicleOption = i;
        console.log(i);
        this.vehicleOptionService.addOption(i.vehicleId, i.id, i.checked).subscribe(data => {
        this.vehicleDefaultOptions = data.defaultList;
       // this.vehicleOptionService.changeVechicleOptionList(data.defaultList);
      }, error => {
        this.alertify.error(error);
      }, () => {
        if (i.checked) {
          this.alertify.success('Options Added');
        }
        if (!i.checked) {
          this.alertify.success('Option Removed');
        }
      } );
    }

    // addOptionDefault(e) {
    //   this.bsModalRef = this.modalService.show(AddOptionModalComponent);
    //   this.bsModalRef.content.addOption.subscribe((values) => {
    //     this.vehicleOptionService
    //     .addTennantCustomDefaultOption(this.selectedCompany1.parentGuid, values.optionName, this.itemId).subscribe(data => {
    //        this.vehicleDefaultOptions = data.defaultList;
    //      }, error => {
    //        this.alertify.error(error);
    //      }, () => { this.alertify.success('Default Option added'); } );
    //   });
    // }

    deleteDefaultOption(i) {
      this.vehicleOption = i;
      this.vehicleOptionService
      .deleteDefaultOption(this.selectedCompany1.parentGuid, this.vehicleOption.id, this.itemId).subscribe(data => {
        this.vehicleDefaultOptions = data.defaultList;
        this.vehicleOptionService.changeVechicleOptionList(data.optionList);
      }, error => {
        this.alertify.error(error);
      }, () => { this.alertify.success('Option Remoeved'); } );
    }
}
