<div class="customer-form-sec" style="width: 890px">
  <div class="container-fluid">
    <div class="row">
      <div class="form-sec tab-content col-md-12" style="height: 610px">
        <div id="customer" class="tab-pane fade in active show">
          <!-- <div class="modal-header form-sec tab-content ">
	  <h4 class="modal-title pull-left">Tax Defaults</h4>
	  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
		<span aria-hidden="true">&times;</span>
	  </button>
	</div> -->
          <div class="modal-body">
            <!-- <div class="customer-form-sec">
              <div class="container-fluid"> -->
            <div class="row" style="height: 750px">
              <div class="form-sec tab-content col-md-12">
                <div id="misc-info" class="tab-pane fade in active show">
                  <div class="row">
                    <div class="col-md-12">
						<div class="rw">
							<label>Name?</label>
							<input
							  style="width: 400px;"
							  type="text"
							  class="cstm-input"
							  name="name"
							  [ngModel]="saleDefaultTemplate?.name"
							  (ngModel)="
								saleDefaultTemplate.name = $event
							  "
							  (keydown) = "userEnteredName()"
							  (ngModelChange)="
								saleDefaultTemplate.name = $event
							  "
							  (blur)="onChange($event)"
							/>
						  </div>
						  <br>
                      <div class="inner-misc">
                        <div class="misc-grid col-md-4">
							
                          <h3 class="misc-title">Tax Values</h3>
                          <div class="row">
                            <div class="col-md-12">

                              <div class="rw">
                                <label>Tax % #1</label>
                                <input
                                  style="width: 100px; text-align: right"
                                  type="number"
                                  class="cstm-input"
                                  name="taxRate1"
                                  [ngModel]="saleDefaultTemplate?.taxRate1"
                                  (ngModel)="
                                    saleDefaultTemplate.taxRate1 = $event
                                  "
                                  (ngModelChange)="
                                    saleDefaultTemplate.taxRate1 = $event
                                  "
                                  (blur)="onChange($event)"
                                />
                              </div>
                              <div class="rw">
                                <label>Tax  % #2</label>
                                <input
                                  style="width: 100px; text-align: right"
                                  type="number"
                                  class="cstm-input"
                                  name="taxRate2"
                                  [ngModel]="saleDefaultTemplate?.taxRate2"
                                  (ngModel)="
                                    saleDefaultTemplate.taxRate2 = $event
                                  "
                                  (ngModelChange)="
                                    saleDefaultTemplate.taxRate2 = $event
                                  "
                                  (blur)="onChange($event)"
                                />
                              </div>
                              <div class="rw">
                                <label>Tax % #3</label>
                                <input
                                  style="width: 100px; text-align: right"
                                  type="number"
                                  class="cstm-input"
                                  name="taxRate3"
                                  [ngModel]="saleDefaultTemplate?.taxRate3"
                                  (ngModel)="
                                    saleDefaultTemplate.taxRate3 = $event
                                  "
                                  (ngModelChange)="
                                    saleDefaultTemplate.taxRate3 = $event
                                  "
                                  (blur)="onChange($event)"
                                />
                              </div>
                              <div class="rw">
                                <label>Tax % #4</label>
                                <input
                                  style="width: 100px; text-align: right"
                                  type="number"
                                  class="cstm-input"
                                  name="taxRate4"
                                  [ngModel]="saleDefaultTemplate?.taxRate4"
                                  (ngModel)="
                                    saleDefaultTemplate.taxRate4 = $event
                                  "
                                  (ngModelChange)="
                                    saleDefaultTemplate.taxRate4 = $event
                                  "
                                  (blur)="onChange($event)"
                                />
                              </div>
                              <div class="rw">
                                <label>Max Taxable #1</label>
                                <input
                                  style="width: 100px; text-align: right"
                                  type="number"
                                  class="cstm-input"
                                  name="maxTaxableValue"
                                  [ngModel]="
                                    saleDefaultTemplate?.maxTaxableValue
                                  "
                                  (ngModel)="
                                    saleDefaultTemplate.maxTaxableValue = $event
                                  "
                                  (ngModelChange)="
                                    saleDefaultTemplate.maxTaxableValue = $event
                                  "
                                  (blur)="onChange($event)"
                                />
                              </div>
                              <div class="rw">
                                <label>Min Taxable #2</label>
                                <input
                                  style="width: 100px; text-align: right"
                                  type="number"
                                  class="cstm-input"
                                  name="minMaxTaxableValue2"
                                  [ngModel]="
                                    saleDefaultTemplate?.minMaxTaxableValue2
                                  "
                                  (ngModel)="
                                    saleDefaultTemplate.minMaxTaxableValue2 = $event
                                  "
                                  (ngModelChange)="
                                    saleDefaultTemplate.minMaxTaxableValue2 = $event
                                  "
                                  (blur)="onChange($event)"
                                />
                              </div>
                              <div class="rw">
                                <label>Max Taxable #2</label>
                                <input
                                  style="width: 100px; text-align: right"
                                  type="number"
                                  class="cstm-input"
                                  name="maxTaxableValue2"
                                  [ngModel]="
                                    saleDefaultTemplate?.maxTaxableValue2
                                  "
                                  (ngModel)="
                                    saleDefaultTemplate.maxTaxableValue2 = $event
                                  "
                                  (ngModelChange)="
                                    saleDefaultTemplate.maxTaxableValue2 = $event
                                  "
                                  (blur)="onChange($event)"
                                />
                              </div>
                              <div class="modal-footer">
                                <button
                                  type="button"
                                  class="btn btn-default"
                                  (click)="bsModalRef.hide()"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-success"
                                  (click)="updateGap()"
                                >
                                  Submit
                                </button>
                              </div>
                             
                            </div>
                          </div>
                        </div>
                        <div class="misc-grid col-md-8" style="height: 500px">
                          <h3 class="misc-title">Field Taxes</h3>
                          <div class="table-sec" style="height: 500px">
                            <table id="customers" style="width: 500">
                              <tr>
                                <th class="date-th">Field</th>
                                <th class="charges-th">Tax 1</th>
                                <th class="charges-th">Tax 2</th>
                                <th class="charges-th">Tax 3</th>
                                <th class="charges-th">Tax 4</th>
                                <th class="charges-th">Default Value</th>
                              </tr>
                              <tr
                                (dblclick)="openModal2(templateNested, item)"
                                class="td-rw"
                                *ngFor="
                                  let item of saleDefaultTemplate?.saleTaxDefaults
                                "
                              >
                                <td class="date-td">
                                  {{ item?.fieldName }}
                                </td>
                                <td>{{ item?.isTaxed1 }}</td>
                                <td>{{ item?.isTaxed2 }}</td>
                                <td>{{ item?.isTaxed3 }}</td>
                                <td>{{ item?.isTaxed4 }}</td>
                                <td class="charge-td">
                                  {{ item.defaultValue | currency }}
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!-- </div>
                  </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <ng-template #templateNested>
      <div class="modal-header">
        <h4 class="modal-title pull-left">Setings For Field {{saleDefaultTemplateField?.fieldName}}!</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="bsModalRef2.hide()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #rolesForm="ngForm" id="rolesForm">
          <div id="misc-info" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-misc">
                  <div class="misc-grid">
                    <div class="row">
                      <div class="col-md-12 mise-left-grid">
						<div class="rw">
							<label>Default Value</label>
							<input
							  style="width: 100px; text-align: right"
							  type="number"
							  class="cstm-input"
							  name="defaultValue"
							  [ngModel]="
							  saleDefaultTemplateField?.defaultValue
							  "
							  (ngModel)="
							  saleDefaultTemplateField.defaultValue = $event
							  "
							  (ngModelChange)="
							  saleDefaultTemplateField.defaultValue = $event
							  "
							  
							  (blur)="onChange($event)"
							/>
						  </div>
						  <div class="row">
						  <div class="col-md-6 mise-left-grid">
							  
							<div class="rw">
								<label>Tax #1</label>
								<input
								  name="isTaxed1"
								  type="checkbox"
								  class="form-check-input filled-in"
								  id="filledInCheckboxDisabled"
								  [ngModel]="saleDefaultTemplateField?.isTaxed1"
								  (ngModel)="
									saleDefaultTemplateField.isTaxed1 = $event
								  "
								  [checked]="saleDefaultTemplateField?.isTaxed1"
								  (ngModelChange)="
									saleDefaultTemplateField.isTaxed1 = $event
								  "
								/>
							  </div>
							  <div class="rw">
								<label>Tax #2</label>
								<input
								  name="isTaxed2"
								  type="checkbox"
								  class="form-check-input filled-in"
								  id="filledInCheckboxDisabled"
								  [ngModel]="saleDefaultTemplateField?.isTaxed2"
								  (ngModel)="
									saleDefaultTemplateField.isTaxed2 = $event
								  "
								  [checked]="saleDefaultTemplateField?.isTaxed2"
								  (ngModelChange)="
									saleDefaultTemplateField.isTaxed2 = $event
								  "
								/>
							  </div>
						 
						</div>
						  <div class="col-md-6 mise-left-grid">
							<div class="rw">
								<label>Tax #3</label>
								<input
								  name="isTaxed3"
								  type="checkbox"
								  class="form-check-input filled-in"
								  id="filledInCheckboxDisabled"
								  [ngModel]="saleDefaultTemplateField?.isTaxed3"
								  (ngModel)="
									saleDefaultTemplateField.isTaxed3 = $event
								  "
								  [checked]="saleDefaultTemplateField.isTaxed3"
								  (ngModelChange)="
									saleDefaultTemplateField.isTaxed3 = $event
								  "
								/>
							  </div>
							  <div class="rw">
								<label>Tax #4</label>
								<input
								  name="isTaxed4"
								  type="checkbox"
								  class="form-check-input filled-in"
								  id="filledInCheckboxDisabled"
								  [ngModel]="saleDefaultTemplateField?.isTaxed4"
								  (ngModel)="
									saleDefaultTemplateField.isTaxed4 = $event
								  "
								  [checked]="saleDefaultTemplateField?.isTaxed4"
								  (ngModelChange)="
									saleDefaultTemplateField.isTaxed4 = $event
								  "
								/>
							  </div>
						  </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <!-- <button type="button" class="btn btn-danger" (click)="closeModal(2)">
          Close self
        </button> -->
      </div>
    </ng-template>
  </div>
</div>
