import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CompanyDepartment } from 'src/app/_models/CompanyDepartment';
import { CompanyPhoto } from 'src/app/_models/companyphoto';
import { CompanyDepartmentType } from 'src/app/_models/CompanyDepartmentType';

@Injectable({
  providedIn: 'root'
})
export class CompanyDepartmentsService {
  baseUrl = environment.apiUrl;
  companyId: number;
constructor(private http: HttpClient) { }
listCompanyDepartments(websiteId: number, isactive: boolean, isall: boolean): Observable<CompanyDepartmentType[]>  {
  return this.http.get<CompanyDepartmentType[]>(
    this.baseUrl + 'websitecompanydepartment/listcompanydepartmenttypesisactiveisall/' + websiteId  + '/' + isactive + '/' + isall)
}
getByDeptTypeId(deptTypeId, websiteguid): Observable<CompanyDepartment> {
  return this.http.get<CompanyDepartment>(this.baseUrl + 'websitecompanydepartment/getdepartment/' + deptTypeId + '/' + websiteguid);
}
//[HttpGet("editsortorder/{departmentId}/{sortOrder}")]
editSortOrder(entity: CompanyDepartment): Observable<any> {
  return this.http.get<any>(this.baseUrl + 'websiteserviceoffers/postserviceoffer/' + entity.id + '/' + entity.sortOrder + '/' + entity.websiteCompanyId);
}
edit(entity, id): Observable<CompanyDepartment> {
  return this.http.put<CompanyDepartment>(this.baseUrl + 'websitecompanydepartment/updatecompanydepartments/' + id, entity);
}
delete(id, parent): Observable<CompanyDepartment[]> {
  return this.http.get<CompanyDepartment[]>(this.baseUrl + 'websitecompanydepartment/deletecompanydepartments/' + id + '/' + parent);
}
add(guid, value, websiteguid): Observable<CompanyDepartment[]> {
  console.log(this.baseUrl + 'websitecompanydepartment/addcompanydepartments/' + guid + '/' + value);
  return this.http.get<CompanyDepartment[]>(this.baseUrl + 'websitecompanydepartment/addcompanydepartments/' + guid + '/' + value + '/' + websiteguid);
}
getDepartmentPhotos(companyid, departmentid): Observable<CompanyPhoto[]> {
  return this.http.get<CompanyPhoto[]>(this.baseUrl + 'companyphoto/getlistofdepartmentphotos/' + companyid + '/' + departmentid);
}
deleteServiceOffer(id, parent): Observable<CompanyDepartment[]> {
  return this.http.get<CompanyDepartment[]>(this.baseUrl + 'websitecompany/deleteserviceoffer/' + parent  + '/' + id);
}
}
