import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Item } from 'src/app/_models/Item';
import { VehicleGridVITTAXToReturnDto } from 'src/app/_models/DTOS/VehicleGridVITTAXToReturnDto';
import { map } from 'rxjs/operators';
import { PaginatedResult } from 'src/app/_models/pagination';
import { VehicleGridToReturnDto } from 'src/app/_models/DTOS/vehiclegridtoreturndto';
import { ItemType } from 'src/app/_models/ItemType';
import { InventoryVehicleGridToReturnDto } from 'src/app/_models/DTOS/inventoryvehiclegriddto';


@Injectable({
  providedIn: 'root'
})
export class ItemService {
  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;
  itemsCached = [] as Item[];
item: Item;
item2: Item;
    // for future USE vehicle controller for now under verhicle service
  getItem(id: number): Observable<Item> {
  if (this.item) { console.log('returned cached vehicle'); return of(this.item);   }
    return this.http.get<Item>(this.baseUrl + 'item/getitembyid/');
  }

  addChartItem(chartItem: Item, parentGuid: string): Observable<Item> {
    return this.http.put<Item>(this.baseUrl + 'chartitem/addchartitem/' + parentGuid, chartItem);
  }
  updateChartItemDescriptions(chartitemid, chartItem: Item): Observable<Item> {
    return this.http.put<Item>(this.baseUrl + 'chartitemdescriptions/updatechartitemdescriptions/' + chartitemid, chartItem);
  }

  getChartItem(id): Observable<Item> {
  if (this.item) { console.log('returned cached vehicle'); return of(this.item);   }
    return this.http.get<Item>(this.baseUrl + 'chartitem/getchartitem/' + id);
  }

  // getChartOfAccountLegend(): Observable<ChartOfAccountLegend[]> {
  //   return this.http.get<ChartOfAccountLegend[]>(this.baseUrl + 'chartitem/getchartofaccountlegend/');
  // }
 setIsPublished(parentGuid, itemId, isPublished) {
  return this.http.get<Item[]>(this.baseUrl + 'chartitem/isPublished/' + parentGuid + '/' +  itemId + '/' + isPublished);
 }

 getSaleByItemId(itemId) {
  return this.http.get<Item[]>(this.baseUrl + 'chartitem/getsaleidbyitemid/' +  itemId);
 }
  getChartItems(parentGuid, page?, itemsPerPage?, userParams?): Observable<PaginatedResult<VehicleGridToReturnDto[]>> {
    const paginatedResult: PaginatedResult<VehicleGridToReturnDto[]> = new PaginatedResult<VehicleGridToReturnDto[]>();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (userParams != null) {
      if (userParams.year > 0) { params  = params.append('year', userParams.year); }
      if (userParams.make !== undefined) { params  = params.append('make', userParams.make); }
      if (userParams.model !== undefined) { params  = params.append('model', userParams.model); }
      if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
      if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
      if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
      if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
      if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
      if (userParams.isDeleted !== undefined) { params  = params.append('isDeleted', userParams.isDeleted); }
      if (userParams.isDeleted === undefined) {
        userParams.isDeleted = 'isAll';
        params  = params.append('isDeleted', userParams.isDeleted); }
      if (userParams.returnByBodyTypeId !== undefined) { params  = params.append('returnByBodyTypeId', userParams.returnByBodyTypeId); }
    }
    return this.http.get<VehicleGridToReturnDto[]>(this.baseUrl + 'chartitem/getchartitemsvehicles/' + parentGuid, {observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  getChartVITItems(parentGuid, page?, itemsPerPage?, userParams?): Observable<PaginatedResult<VehicleGridVITTAXToReturnDto[]>> {
    const paginatedResult: PaginatedResult<VehicleGridVITTAXToReturnDto[]> = new PaginatedResult<VehicleGridVITTAXToReturnDto[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (userParams != null) {
       if (userParams.monthOfYear !== undefined) { params = params.append('monthOfYear', userParams.monthOfYear); }
       if (userParams.yearForDate !== undefined) { params = params.append('yearForDate', userParams.yearForDate); }
      if (userParams.startDate !== undefined) { params  = params.append('startDate', userParams.startDate); }
      if (userParams.endDate !== undefined) { params  = params.append('endDate', userParams.endDate); }
      if (userParams.year > 0) { params  = params.append('year', userParams.year); }
      if (userParams.make !== undefined) { params  = params.append('make', userParams.make); }
      if (userParams.model !== undefined) { params  = params.append('model', userParams.model); }
      if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
      if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
      if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
      if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
      if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
      if (userParams.returnByBodyTypeId !== undefined) { params  = params.append('returnByBodyTypeId', userParams.returnByBodyTypeId); }

    }
    console.log(this.baseUrl + 'chartinvoicetransaction/gettransactioninvoicevit/' + parentGuid, {observe: 'response', params});
    return this.http.get<VehicleGridVITTAXToReturnDto[]>(this.baseUrl + 'chartinvoicetransaction/gettransactioninvoicevit/' + parentGuid, {observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }
  getAllChartItems(parentGuid): Observable<Item[]> {
    return this.http.get<Item[]>(this.baseUrl + 'chartitem/getchartitems/' + parentGuid);
  }
  getChartParentItems(parentGuid): Observable<Item[]> {
    return this.http.get<Item[]>(this.baseUrl + 'chartitem/getchartparentitems/' + parentGuid);
  }
  getItemTypes(parentguid): Observable<ItemType[]> {
    return this.http.get<ItemType[]>(this.baseUrl + 'chartitem/getitemtypes/' + parentguid );
  }

  getItemsByItemTypeId(parentGuid, itemtypeid): Observable<Item[]> {
    return this.http.get<Item[]>(this.baseUrl + 'chartitem/getitembyitemtype/' + parentGuid + '/itemtype/' + itemtypeid);
  }
  updateChartItemVit(parentguid, chartitemid, vitname) {
    return this.http.get(this.baseUrl + 'chartitem/updatechartitemvit/' + parentguid +  '/' + chartitemid +  '/' + vitname);
  }
  editDealInfoItem(item): Observable<Item>{
    return this.http.post<Item>(this.baseUrl + 'item/editdealinfoitem/', item);
  }


  getVehicleListGrid(parentGuid, page?, itemsPerPage?, userParams?): Observable<PaginatedResult<InventoryVehicleGridToReturnDto[]>> {
    const paginatedResult: PaginatedResult<InventoryVehicleGridToReturnDto[]> = new PaginatedResult<InventoryVehicleGridToReturnDto[]>();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if (userParams != null) {
      if (userParams.year > 0) { params  = params.append('year', userParams.year); }
      if (userParams.make !== undefined) { params  = params.append('make', userParams.make); }
      if (userParams.model !== undefined) { params  = params.append('model', userParams.model); }
      if (userParams.orderBy !== undefined) { params  = params.append('orderBy', userParams.orderBy); }
      if (userParams.isAscending !== undefined) { params  = params.append('isAscending', userParams.isAscending); }
      if (userParams.filter !== undefined) { params  = params.append('filter', userParams.filter); }
      if (userParams.searchBy !== undefined) { params  = params.append('searchBy', userParams.searchBy); }
      if (userParams.searchFor !== undefined) { params  = params.append('searchFor', userParams.searchFor); }
      if (userParams.returnByBodyTypeId !== undefined) { params  = params.append('returnByBodyTypeId', userParams.returnByBodyTypeId); }
    }
    return this.http.get<InventoryVehicleGridToReturnDto[]>(this.baseUrl + 'chartitem/getchartitemsvehiclesinventorylist/' + parentGuid, {observe: 'response', params})
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }
}

