import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Person } from 'src/app/_models/person';
import { ContactForm } from 'src/app/_models/websites/contactform';
import { User } from 'src/app/_models/user';


@Injectable({
  providedIn: 'root'
})
export class PersonService {
  baseUrl = environment.apiUrl;
  webisteId = environment.websiteId;
  // passwordCode: PasswordCode;
  constructor(private http: HttpClient) {}

  // getEmployees(position): Observable<WebsiteEmployee[]> {
  //   return this.http.get<WebsiteEmployee[]>(
  //     this.baseUrl + 'websiteemployee/getwebsiteemployes/' + this.webisteId + '/' + position
  //   );
  // }

  // getAllEmployees(position): Observable<Person[]> {
  //   return this.http.get<Person[]>(
  //     this.baseUrl + 'person/employeesallforwebsite/' + this.webisteId
  //   );
  // }

  saveContact(person: ContactForm): Observable<Person> {
    person.messageType = 'Contact Form';
    return this.http.post<Person>(
      this.baseUrl + 'person/addpersoncontactwebsite/' + this.webisteId, person
    );
  }

  saveApplyOnline(person: ContactForm): Observable<Person> {
    person.messageType = 'Apply Online';
    return this.http.post<Person>(
      this.baseUrl + 'person/addpersoncontactwebsite/' + this.webisteId, person
    );
  }

  saveVehiclePage(person: ContactForm): Observable<Person> {
    person.messageType = 'Vehicle Page';
    return this.http.post<Person>(
      this.baseUrl + 'person/addpersoncontactwebsite/' + this.webisteId, person
    );
  }

  validatePasswordGuid( password: any, id: any) {
    return this.http.get<User>(
      this.baseUrl + 'person/changepassword/' + this.webisteId +  '/' + id  + '/' + password
    );
  }

  sendPasswordRequestEmail(email: any) {
    return this.http.get<Person>(
      this.baseUrl + 'person/passwordresethasbegun/' + this.webisteId + '/' + email
    );
  }
}
