import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from 'src/app/_models/user';


@Component({
  selector: 'app-add-vehicle-vin-modal',
  templateUrl: './add-vehicle-vin-modal.component.html',
  styleUrls: ['./add-vehicle-vin-modal.component.css']
})
export class AddVehicleVinModalComponent implements OnInit {
  @Output() updateVehicle = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  user: User;
  vehicle: any;
  passwords: any;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  update() {
    this.vehicle = this.editForm.value;
    this.updateVehicle.emit(this.vehicle);
    this.bsModalRef.hide();
  }
  updateValues() {
    this.vehicle.vehicleAmounts.purchasePrice = this.vehicle.vehicleAmounts.suggestedPrice.toString();
    this.vehicle.vehicleAmounts.suggestedPrice = this.vehicle.vehicleAmounts.suggestedPrice.toString();
    this.vehicle.vehicleAmounts.mileage = this.vehicle.vehicleAmounts.suggestedPrice.toString();
  }
  selectChange(e) {

  }
}

