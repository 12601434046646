import { Console } from 'console';

import moment from 'moment';
import { Observable } from 'rxjs';
import { Sale } from 'src/app/_models/sale';
import { SaleActiveTypeResolver } from 'src/app/_resolvers/sale-activetype.resolver';
export class LoanCalculations {
    dsl: number = 0 as number;
    moment = require('moment');
      msDay = 60 * 60 * 24 * 1000;
async XSeries(i, N) {
    let res = 1;
    for (let k = 1; k < N; k++) {
        res = res + 1 / Math.pow(1 + i, k);
    }
    return res;
}
fnz02 = (num) => num.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
    })
// async figure_Payment(termOrPayment, amountFinanced, APRPrc, Term, dtJsStartDate, Installment, jsDate,
//     jsDateFirstDue,  dtjsFirstDueDate: Date, jsTimeFirstDue: Date, jsTimeSoldDate ) {
//         const ONE_DAY = 1000 * 60 * 60 * 24;
//     let t;
//     let f;
//     let days;
//    ///// let finalPayment = spjsFinalPayment.GetValue();
//   //  let termOrPayment = cmbCalculateWhich.GetValue();
//   //  let AmountFinanced = Pricing17.GetValue();
//  //   AmountFinanced = Pricing17.GetValue();
//   //  let Installment = cmbjsInstallment.GetValue();
//   //  let APRPrc = spjsRate.GetValue();
//    // let Term = spjsTerm.GetValue();
//    //  if (jsDate.GetDate() != null) {
//       //   jsDate = dtjsStartDate.GetDate();
//         const soldDateDay = jsDate.getDate();
//         const soldDateMonth = jsDate.getMonth();
//         const soldDateYear = jsDate.getFullYear();
//    // }
//    // if (dtjsFirstDueDate.GetDate() != null) {
//         // jsDateFirstDue = dtjsFirstDueDate.GetDate();
//         const firstDueDateDay = jsDateFirstDue.getDate();
//         const FirstDueDateMonth = jsDateFirstDue.getMonth();
//         const FirstDueDateYear = jsDateFirstDue.getFullYear();
//    // }
//     const FirstDueDate = new Date(FirstDueDateYear, FirstDueDateMonth, firstDueDateDay);
//     const SoldDate = new Date(soldDateYear, soldDateMonth, soldDateDay);
//     let w = 1;
//     let d1;
//     let d2;
//     //   debugger;
//     switch (Installment) {
//         case 1: // "weekly"
//             w = 52.1428;
//           //  let jsTimeFirstDue = dtjsFirstDueDate.GetDate();
//             d1 = jsTimeFirstDue.getTime() / ONE_DAY;
//           //  let jsTimeSoldDate = dtjsStartDate.GetDate();
//             d2 = jsTimeSoldDate.getTime() / ONE_DAY;
//             t = Math.floor((d1 - d2) / 7);
//             f = d1 - d2 - t * 7;
//             f = f / 7;
//             Installment = 0;
//             break;
//         case 2: // "bi-weekly"
//             w = 26.0714;
//          //   let jsTimeFirstDue = dtjsFirstDueDate.GetDate();
//             d1 = jsTimeFirstDue.getTime() / ONE_DAY;
//          //   let jsTimeSoldDate = dtjsStartDate.GetDate();
//             d2 = jsTimeSoldDate.getTime() / ONE_DAY;
//             t = Math.floor((d1 - d2) / 14);
//             f = d1 - d2 - t * 14;
//             f = f / 14;
//             Installment = 0;
//             break;
//         case 3: // "monthly"
//             w = 12;
//             d1 = FirstDueDate;
//             d2 = SoldDate;
//             t = Math.floor(get_MonthDiff(d1, d2));
//             f = get_MonthDiffDays(d1, d2);
//             f = f / 30;
//             break;
//         case 4: // "semi-monthly"
//             w = 24;
//             d1 = FirstDueDate;
//             d2 = SoldDate;
//             t = Math.floor(this.get_MonthDiff(d1, d2) * 2);
//             days = this.get_MonthDiffDays(d1, d2);
//             if (days >= 15) {
//                 t = t + 1;
//                 days = days - 15;
//             }
//             f = days;
//             f = f / 15;
//             break;
//     }
//     const AF = amountFinanced;
//     const i = APRPrc / (100 * w);
//     const N = Term;
//     const v1 = AF * (1 + f * i) * (Math.pow(1 + i, t));
//     const v2 = this.XSeries(i, N);
//     let V = 0;
//     if (Math.abs(v2) > 0.0001) { V = v1 / v2; }
//     V = Math.round(V * 100) / 100;
//     //   alert(V); //payment result if
//     //   FrCalc.Payment.value = V;
//     let interest1 = 0;
//     interest1 = (Term * V) - amountFinanced;
//     if (isNaN(V)) { V = 0; interest1 = 0; }
//   //  spjsPayment.SetValue(V);
//   //  spInterest.SetValue(interest1);
//    // let ttlPayments = interest1 + amountFinanced;
//    // spTotalOfPayments.SetValue(ttlPayments);
//    // spjsFinalPayment.SetValue(0.00);
//     LoopDates(jsTimeSoldDate, jsTimeFirstDue, jsTimeFirstDue, Term, APRPrc, Installment);
// }
// async LoopDates(jsTimeSoldDate, jsTimeFirstDue, jsDateNext, Term, apr, installment) {

//   //  var jsTimeSoldDate = dtjsStartDate.GetDate();
//   //  var jsTimeFirstDue = dtjsFirstDueDate.GetDate();
//   //  var jsDateNext = dtjsFirstDueDate.GetDate();
//    // var Term = spjsTerm.GetValue() + 1;
//     let dsl = get_MonthDiffDays(jsTimeFirstDue, jsTimeSoldDate);
//     let i = 1;
//     while (Term > i) {
//         i += 1;
//         jsDateNext = jsTimeFirstDue;
//         jsTimeFirstDue = dateNext(jsTimeFirstDue, apr, Term, installment, jsDateNext, jsTimeSoldDate);
//       //  let dsl = get_MonthDiffDays(jsTimeFirstDue, jsDateNext);
//         console.log(jsTimeFirstDue);
//     }
//   //  dtjsFinalDueDate.SetValue(jsTimeFirstDue);
// }
async dateNext(currentDuedate, APRPrc, Term, Installment, jsDateFirstDue, soldDate) {
    // var AF = AmountFinanced;
  //  var APRPrc = spjsRate.GetValue();
   // var Term = spjsTerm.GetValue();
  //  var Installment = cmbjsInstallment.GetValue();
  //  var jsDateFirstDue = currentDuedate;
//   const nextCurrentDueDate = jsDateFirstDue;
//   const nextInstallmentDave = jsDateFirstDue;
//         const myDate = new Date(jsDateFirstDue);

//     switch (Installment) {
//         case 1: // "weekly"
//           const dateToReturn =  myDate.setDate(myDate.getDate() + 7);
//             return myDate;
//             break;
//         case 2: // "bi-weekly"
//             myDate.setDate(myDate.getDate() + 14);
//             return myDate;
//             break;
//         case 3: // "monthly"
//         const NextCurrentDueDate = jsDateFirstDue;
//             myDate.setMonth(myDate.getMonth() + 1);
//             return myDate;
//             break;
//         case 4: // "semi-monthly"
//           //  w = 24;
//           const  d1 = nextCurrentDueDate;
//           const d2 = soldDate;
//           let  t = Math.floor(get_MonthDiff(d1, d2) * 2);
//           let  days = get_MonthDiffDays(d1, d2);
//             if (days >= 15) {
//                 t = t + 1;
//                 days = days - 15;
//             }
//           let  f = days;
//             f = f / 15;
//             break;
//     }
}
async get_MonthDiff(dd1, dd2) {
    // let k = 1;
    // let i;
    // let days;
    // let d1 = dd1.getTime();
    // let d2 = dd2.getTime();
    // if (d1 < d2) {
    //     k = -1;
    //     d1 = dd2.getTime();
    //     d2 = dd1.getTime();
    // }
    // i = 0;
    // while (d2 <= DateAdd(-i, d1)) {
    //     i = i + 1;
    // }
    // days = (DateAdd(-i + 1, d1) - d2) / ONE_DAY;
    // days = k * days;
    // return (k * (i - 1));
}
async DateAdd(i, dms) {
    const d = new Date(dms);
    const d2 = new Date(d.getFullYear(), d.getMonth() + i, d.getDate());
    return d2.getTime();
}
async get_MonthDiffDays(dd1, dd2) {
    // let k = 1;
    // let i;
    // let days;
    // let d1 = dd1.getTime();
    // let d2 = dd2.getTime();
    // if (d1 < d2) {
    //     k = -1;
    //     d1 = dd2.getTime();
    //     d2 = dd1.getTime();
    // }
    // i = 0;
    // while (d2 <= DateAdd(-i, d1)) {
    //     i = i + 1;
    // }
    // days = (DateAdd(-i + 1, d1) - d2) / ONE_DAY;
    // return Math.round(k * days);
}
 isLeap(year) {
    if (year % 4 === 0) {
       if (year % 100 === 0){
          if (year % 400 === 0){
             return (1);
          } else {
             return (0);
          }
       } else {
          return (1);
       }
    } else{
       return (0);
    }
 }
 weeksBetween(d1, d2) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
}
getDaysPerMonth(month){
    month += 1;
    switch (month) {
        case 1: // jan
        return 31;
            break;
            case 2: // feb
            return 28;
                break;
                case 3: // march
            return 31;
            break;
            case 4: // april
            return 30;
            break;
            case 5:
            return 31; // may

            break;
            case 6: // june
            return 30;
            break;
            case 7: // jully
            return 31;
            break;
            case 8: // august
            return 31;
            break;
            case 9: // septemmber
            return 30;
            break;
            case 10: // october
            return 31;
            break;
            case 11: // november
            return 30;
                break;
                case 12:
             return 31;
            break;
        default:
            break;
    }
}
async dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information. Use Moment JS to ensure dates are same date with no change of daylight saving time or time zones from utc
    a = moment(a).toDate();
    b = moment(b).toDate();
    // console.log('From DSL a: ' + a);
    // console.log('From DSL b: ' + b);
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  //  console.log('Date1: ' + utc1);
 
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
   // console.log('Date2: ' +utc2);
 //   console.log('UTC1: ' + (utc2 - utc1) / _MS_PER_DAY);
    this.dsl = Math.floor((utc2 - utc1) / _MS_PER_DAY);
  //   console.log(this.dsl);
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
 async  regZ_GetPayment(sale: Sale, fullInterval: number, extraDays: number): Promise<Sale>{
 const a = 0 as number;
 let   aPR = 0 as number;
 let Tm  = 0 as number ;
 let v  = 0 as number;
 let V1  = 0 as number;
 let V2  = 0 as number;
 let AF  = 0 as number;
//  let s = '' as string;  // changeable variable through code
//  let SF = '' as string;
//  let i = 0 as number;
//  let j = 0 as number;
 let n = 0 as number;
 // let n1 = 0 as number;
 const k = sale.amoInstallments.value as string; // Installment
//  let I1 = 0 as number;
//  let Fpm   = 0 as number;
 let L = 0 as number;
 let V33 = 0 as number;
 let V44 = 0 as number;
 let CDn = 0 as number;
 let Dx = 0 as number;
 let ti = 0 as number;
 const d1 = new Date(sale.soldDate);
 const d2 = new Date(sale.firstDueDate);
 if (d1.getFullYear() === 1 && d1.getMonth() === 1) {
console.log('Is Leap Year');
}
 const d1M = d1.getMonth();
 const d2M = d2.getMonth();
 let Dint = 0 as number;
 let TDT = 0 as number;
 let TD1 = 0 as number;
 let v3 = 0 as number;
 let v4 = 0 as number;
 let paymentNoAmo = 0 as number;
 if (sale.nonAmortized > 0 && sale.term > 0) {
      paymentNoAmo = parseFloat((sale.nonAmortized / sale.term).toFixed(2)) ;
  // if (sale.isPayment) { paymentNoAmo = parseFloat((sale.nonAmortized / (sale.term  )).toFixed(2)) ; }
}
   // On Error GoTo ErrGetFieldValuePayment
    AF = sale.amountFinanced;           // 'Amount Financed
    CDn = sale.cashDown; // Cash Down
    CDn = CDn + sale.pickupsTotal;                          // ' '+ Pickup Payments
    if (AF + CDn < 0.01) {
        V33 = 0;
    }{
        if (sale.nonAmortized > 0) {
            V33 = sale.nonAmortized / (AF + CDn);
        }
    }
    // V44 = CSngVal(SSales.Page(1).Field(40).V1)                    //''TaxRateD
    TDT = sale.nonAmortized; // CDbl(s) * 0.01 * V44 //''Deferred tax
    AF = AF - TDT;  // ''AF - Deferred Tax
    V33 = 0;
    V44 = 0;
    L = (1.0 - V33 * 0.01 * V44);
    // Installment code  Field #20
    // APR  Field #22
    aPR = 0.01 * sale.apr;
    console.log('sale apr:  ' + sale.apr)
    // Dx = First Pmt Due Date - Date Sold (days)
    Dx = 0;   // days exceed ti full intervals
    ti = 0;   // Number of full intervals
    console.log(k);
    if (d1 instanceof Date && d2 instanceof Date) {
    }

  // ''Term Field #23
  Tm = sale.term; // term
  TD1 = 0;
  if (TDT > 0 && Tm > 0) {
    TD1 = TDT / Tm;
    // format to .00 TD1  did not add yet
  }
  switch (k) {
    case 'None':
    V2 = 0;
    Dint = 0;
    break;
    case 'Weekly':
    V2 = aPR / 52.143;
    Dint = 7;
    break;
    case 'Bi-Weekly':
    V2 = aPR / 26.071;
    Dint = 14;
    break;
    case  'Monthly':
    V2 = aPR / 12;
    Dint = 30;
    break;
    case 'Semi-Monthly':
    V2 = aPR / 24.333;
    Dint = 15;
    break;
            default:
                V2 = 0;
                Dint = 0;
  }
  V2 = V2 * L; //   '!!!!!!
  // Calculating
  if (V2 > 1E-30) {
    n = Tm;
    V1 = Tm - n;
    v = Math.pow((1 + V2), n);
    v3 = (n - 1 + ti);
    let pmt = 0 as number;
    const smthing =  (1.0 + V2) as number;
    v4 =  Math.pow(smthing, v3);
    pmt = (AF * V2 * (1 + (Dx / Dint) * V2) * v4) / (V1 * v * V2 + v - 1);
    Dint = 30;
  
    
    pmt = AF * V2 * (1.0 + (extraDays / Dint) * V2) * Math.pow(smthing, (sale.term - 1.0 + fullInterval)) / (V1 * v * V2 + v - 1.0);
    if (sale.term >> 0 && TDT >> 0) {
      pmt = pmt + (TDT / sale.term);
    }
    console.log(pmt);
  //  console.log('PAYMENT: ' + pmt);
    sale.payment =  parseFloat((pmt).toFixed(2)) ;
   }
   return sale;
}
}

