import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { QuickBooksService } from 'src/app/_services/quickbooksservices/quickbooksservice';

@Component({
  selector: 'app-nav-list',
  templateUrl: './nav-list.component.html',
  styleUrls: ['./nav-list.component.css']
})
export class NavListComponent implements OnInit {
  selectedCompany1: UserAssignedToCompany;
  realmId: string;

  constructor(private authService: AuthService,
     private qbService: QuickBooksService, private router: Router
   ) { }

  ngOnInit() {
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.qbService.getRealmId().subscribe(data => {
      this.realmId = data;
    });
  }
  buttonClick() {
    console.log('button click');
  }
  getQuickBooksDefaults() {
    this.router.navigate(['/list/qblist/' + this.selectedCompany1.parentGuid + '/' + this.realmId]);
  }
}
