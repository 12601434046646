import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SaleStatus } from '../_models/salestatus';
import { SaleStatusService } from '../_services/saleservices/sale-status.service';
import { ToastrService } from 'ngx-toastr';


@Injectable({
    providedIn: 'root'
})
export class SaleStatusesResolver implements Resolve<SaleStatus[]> {
    constructor(private chartInvoiceStatusService: SaleStatusService, private router: Router,
        private alertify: ToastrService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<SaleStatus[]> {
        const newLocal = 'parentGuid';
      //  console.log(route.params['parentGuid']);
        return this.chartInvoiceStatusService.getStatuses(route.params[newLocal]).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving invoice statuses.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
