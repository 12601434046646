import { Component, Input, OnInit, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'app-addemployee-textinput',
  templateUrl: './addemployee-textinput.component.html',
  styleUrls: ['./addemployee-textinput.component.scss']
})
export class AddemployeeTextinputComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() type = 'text';
  expression = false;
  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  writeValue(obj: any): void {
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }
}
