import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { PreventUnsavedChangesGuard } from '../_guards/prevent-unsaved-changes.guard';
import { MemberDetailedResolver } from '../_resolvers/member-detailed.resolver';
import { MemberDetailComponent } from './member-detail/member-detail.component';
import { MemberEditComponent } from './member-edit/member-edit.component';
import { MemberListComponent } from './member-list/member-list.component';




// reportsmanager/ + route below TesthtmlpdfComponent
const routes: Routes = [
    {path: 'members', component: MemberListComponent},
    {path: 'members/:username', component: MemberDetailComponent, resolve: {member: MemberDetailedResolver}},
    {path: 'edit', component: MemberEditComponent, canDeactivate: [PreventUnsavedChangesGuard]},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MemberRoutingModule {}
