import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { InventoryAddComponent } from './inventory-add/inventory-add.component';
import { InventoryDashboardComponent } from './inventory-dashboard/inventory-dashboard.component';
import { InventoryDeskComponent } from './inventory-desk/inventory-desk.component';
import { InventoryEditComponent } from './inventory-edit/inventory-edit.component';
import { InentoryRoutingModule } from './inventory-routing.module';
import { InventoryRepairComponent } from './inventory-repair/inventory-repair.component';
import { AgingListComponent } from './aging-list/aging-list.component';
import { ProdutsFiltersComponent } from './produts-filters/produts-filters.component';
import { SharedModule } from '../_modules/shared.module';
import { AddnewoptionsVehicleComponent } from './addnewoptions-vehicle/addnewoptions-vehicle.component';
import { AddOptionModalComponent } from './addnewoptions-vehicle/add-option-modal/add-option-modal.component';
import { AddnewdescriptionVehicleComponent } from './addnewdescription-vehicle/addnewdescription-vehicle.component';
import { AddnewphotosVehicleComponent } from './addnewphotos-vehicle/addnewphotos-vehicle.component';
import { ProductListComponent } from './product-list/product-list.component';
import { BuyersguideComponent } from '../modals/buyersguide/buyersguide.component';
import { QuickbookproductlisltComponent } from './quickbookproductlislt/quickbookproductlislt.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';





@NgModule({
    declarations: [
        QuickbookproductlisltComponent,
        BuyersguideComponent,
        InventoryAddComponent,
        InventoryDashboardComponent,
        InventoryDeskComponent,
        AddnewdescriptionVehicleComponent,
        InventoryEditComponent,
        InventoryRepairComponent,
        AddnewoptionsVehicleComponent,
        AddOptionModalComponent,
        AddnewphotosVehicleComponent,
        AgingListComponent,
        ProdutsFiltersComponent,
        ProductListComponent,
    ],
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        InentoryRoutingModule,
        AgChartsAngularModule,
        AgGridModule.withComponents([]),
        PaginationModule.forRoot(),
    ],
    providers: []
})
export class InventoryModule { }
