import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { Person } from 'src/app/_models/person';
import { PersonType } from 'src/app/_models/personType';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { PersonDataService } from 'src/app/_services/personinfo/person-data.service';
@Component({
  selector: 'app-selectexistingperson-modal',
  templateUrl: './selectexistingperson-modal.component.html',
  styleUrls: ['./selectexistingperson-modal.component.css']
})
export class SelectexistingpersonModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  @ViewChild('websiteForm') websiteForm: NgForm;
  @Input() personTypes = {} as PersonType;
  @Input() personType = {} as PersonType;
  @Input() persons = [] as PersonLinqResultToReturnDto[];
  selectedCompany: UserAssignedToCompany;
  pagination = {} as Pagination;
  userParams = {} as any;
  isHasPurchased = true;
  isLastNameAscending = true;
  isFirstNameAscending = true;
  isCityAscending = true;
  isStateAscending = true;
  isBirthDateAscending = true;
  isInsuranceExpire = true;
  isEmailAscending = true;
  bsModalRefBuyer: any;
  isAscending = true;
  isCustomerAscending = true;
  isWebleadAscending = true;
  isFilterDates = false;
  personToEdit: Person;
  isEmployee = false;
  isCustomer = false;
  isBirthday = false;
  isAll = false;
  isWebsiteLead = false;
  constructor( public bsModalRef: BsModalRef,
    private personService: PersonDataService,
    private authService: AuthService,
    private alertify: ToastrService) { }

  ngOnInit() {
    this.pagination.currentPage = 1;
    this.isEmployee = true;
    this.userParams.filter = 'Employee';
    this.authService.currentCompany.subscribe(company => this.selectedCompany = company);
    this.loadPeople();
    if (this.personType.type === 'Employee') {

    }
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadPeople();
  }
  selectPerson(e) {
    this.emittedValues.emit(e);
    this.bsModalRef.hide();
  }
  loadPeople() {
    this.personService.getCustomerLinqList(this.selectedCompany.parentGuid, this.pagination.currentPage,
      this.pagination.itemsPerPage, this.userParams)
    .subscribe((data: PaginatedResult <PersonLinqResultToReturnDto[]>) => {
      this.persons = data.result;
      this.pagination = data.pagination;
    }, error => { this.alertify.error(error); }, () => {
      this.alertify.success('Persons Retrieved.');
    });
  }
  sort(e) {
    console.log(e);
    this.isHasPurchased = true;
    this.isWebleadAscending = true;
    this.isLastNameAscending = true;
    this.isFirstNameAscending = true;
    this.isCityAscending = true;
    this.isStateAscending = true;
    this.isBirthDateAscending = true;
    this.isInsuranceExpire = true;
    this.isEmailAscending = true;
    this.isCustomerAscending = true;
    this.isAscending = !this.isAscending;
    this.userParams.isAscending = this.isAscending;
    this.userParams.itemsPerPage = 15;
    this.userParams.orderBy = e;
    this.loadPeople();
    if (e === 'lastName') {this.isLastNameAscending = this.isAscending; }
    if (e === 'firstName') {this.isFirstNameAscending = this.isAscending; }
    if (e === 'city') {this.isCityAscending = this.isAscending; }
    if (e === 'state') {this.isStateAscending = this.isAscending; }
    if (e === 'bithday') {this.isBirthDateAscending = this.isAscending; }
    if (e === 'insExpires') {this.isInsuranceExpire = this.isAscending; }
    if (e === 'email') {this.isEmailAscending = this.isAscending; }
    if (e === 'personType') {this.isCustomerAscending = this.isAscending; }
    if (e === 'weblead') {this.isWebleadAscending = this.isAscending; }

  }
  filter(e) {
    console.log(e);
    this.isEmployee = false;
    this.isCustomer = false;
    this.isBirthday = false;
    this.isAll = false;
    this.isWebsiteLead = false;
    if (e === 'Customer') {this.isCustomer = true; }
    if (e === 'Employee') { this.isEmployee = true; }
    if (e === 'Birthday') { this.isBirthday = true; }
    if (e === 'All') { this.isAll = true; }
    if (e === 'WebsiteLead') { this.isWebsiteLead = true; }
    this.userParams.filter = e;
    this.loadPeople();
  }
  setSearchBy(e, event) {
    setTimeout(() => {
      console.log(event);
      console.log(e);
      this.userParams.searchBy = e;
      this.userParams.searchFor = event;
      this.loadPeople();
    }, 2000);
  }
}
