
<div class="container-fluid customer-tabs-sec">
<ul class="nav nav-tabs">
  <li><a [ngClass] = "isNotes ? 'active show' : ''" (click) ="tabClick('isNotes')"  href="javascript:void(0)">Active</a></li>
  <li><a [ngClass] = "isMiscCharges ? 'active show' : ''" (click) ="tabClick('isMiscCharges')"  href="javascript:void(0)">Misc Charges</a></li>
  <li  ><a [ngClass] = "isWashout ? 'active show' : ''" (click) ="tabClick('isWashout')"  href="javascript:void(0)">Washout/Recap</a></li>
 
  <!-- <li><a (click) ="tabClick(isWashout)" href="javascript:void(0)">Payables</a></li>
  <li><a (click) ="tabClick(isWashout)" href="javascript:void(0)">Recievable</a></li> -->
  <!-- <li><a  [ngClass] = "isAmortization ? 'active show' : ''" (click) ="tabClick('isAmortization')" href="javascript:void(0)">Amortization</a></li> -->
</ul>
</div>