import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneralLedgerComponent } from './general-ledger/general-ledger.component';




const routes: Routes = [
    {path: 'generalledger/:parrentGuid', component: GeneralLedgerComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountingRoutingModule {}


