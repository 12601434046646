import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { AgGridModule } from '@ag-grid-community/angular';
import { SharedModule } from '../_modules/shared.module';

import { LoanListComponent } from './loan-list/loan-list.component';
import { AddPaymentModalComponent } from './add-payment-modal/add-payment-modal.component';
import { LoanRoutingModule } from './loan-routing.module';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { LoanPaymentsComponent } from './legacy/loan-payments/loan-payments.component';
import { LoanAddpaymentComponent } from './legacy/loan-addpayment/loan-addpayment.component';
import { NavredirectmodalpaymentComponent } from './legacy/navredirectmodalpayment/navredirectmodalpayment.component';
import { EditpaymentModalComponent } from './editpayment-modal/editpayment-modal.component';
import { LoanEditpaymentComponent } from './legacy/loan-editpayment/loan-editpayment.component';



@NgModule({
    declarations: [
        EditpaymentModalComponent,
        LoanPaymentsComponent,
        LoanListComponent,
        LoanAddpaymentComponent,
        AddPaymentModalComponent,
        PaymentListComponent,
        NavredirectmodalpaymentComponent,
        LoanAddpaymentComponent,
        LoanEditpaymentComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        AgChartsAngularModule,
        LoanRoutingModule,
        AgGridModule.withComponents([]),
    ],
    providers: []
})

export class LoanModule { }
