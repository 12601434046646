<br><br>
<div class="col-md-12">
    <div class="row">
        <div class="col-md-3">
            <div class="row mt-3">

                <div class="col-md-3">
            
                    <h3>Add Photos</h3>
            
                    <div ng2FileDrop
                         [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
                         (fileOver)="fileOverBase($event)"
                         [uploader]="uploader"
                         class="card bg-faded p-3 text-center mb-3 my-drop-zone">
                         <i class="fa fa-upload fa-3x"></i>
                        Drop Photos Here
                    </div>
            
                    Multiple
                    <input type="file" ng2FileSelect [uploader]="uploader" multiple  /><br/>
            
                    Single
                    <input type="file" ng2FileSelect [uploader]="uploader" />
                </div>
            
                <div class="col-md-9" style="margin-bottom: 40px" *ngIf="uploader?.queue?.length">
            
                    <h3>Upload queue</h3>
                    <p>Queue length: {{ uploader?.queue?.length }}</p>
            
                    <table class="table">
                        <thead>
                        <tr>
                            <th width="50%">Name</th>
                            <th>Size</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let item of uploader.queue">
                            <td><strong>{{ item?.file?.name }}</strong></td>
                            <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
                        </tr>
                        </tbody>
                    </table>
            
                    <div>
                        <div>
                            Queue progress:
                            <div class="progress mb-4">
                                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-success btn-s"
                                (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
                            <span class="fa fa-upload"></span> Upload
                        </button>
                        <button type="button" class="btn btn-warning btn-s"
                                (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
                            <span class="fa fa-ban"></span> Cancel
                        </button>
                        <button type="button" class="btn btn-danger btn-s"
                                (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                            <span class="fa fa-trash"></span> Remove
                        </button>
                    </div>
            
                </div>
            
            </div>
        </div>
        <div class="col-md-9">
            <div class="row">
                <div class="col-sm-2 img-wrapper" *ngFor="let photo of photos">
                  <img src="{{photo.url}}" class="img-thumbnail p-1" alt=""
                    >
              
                  <!-- <div class="text-center img-text">
                      <span class="text-danger">Awaiting approval</span>
                  </div> -->
              
                  <div class="text-center">
                    <button type="button" class="btn btn-sm mr-1"
                      [ngClass]="photo.isAvatar ? 'btn-success active' : 'btn-secondary'"
                      (click)="setMainPhoto(photo)"
                      [disabled]="photo.isAvatar">Main</button>
                    <button type="button" class="btn btn-sm btn-danger"
                      [disabled]="photo.isAvatar"
                      (click)="deletePhoto(photo.id)"><i class="fa fa-trash-o"></i></button>
                  </div>
                </div>
              </div>
        </div>
    </div>
</div>




