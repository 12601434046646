


<ul >
  <li>
    <a [hidden] = "disablePrint" class="item" href="javascript:void(0)" (click) ="PrintPdf2('data')" >
      <img src="assets/images/sidebar-icon-4.jpg">
      <span class="caption">Print </span>
    </a>
  </li>
  <li>
    <a class="item" href="javascript:void(0)" (click) ="downloadPdf('empty')">
      <img src="assets/images/sidebar-icon-6.jpg">
      <span class="caption">Download</span>
    </a>
  </li>
  <li>
    <a class="item" href="javascript:void(0)" (click) ="dataPdf()">
      <img src="assets/images/sidebar-icon-4.jpg">
      <span class="caption">With Data</span>
    </a>
  </li>
  <li>
    <a class="item" href="javascript:void(0)" (click) ="emptyPdf()">
      <img src="assets/images/sidebar-icon-5.jpg">
      <span class="caption"> Empty</span>
    </a>
  </li>
  

</ul>