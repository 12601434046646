<div class="form-group ">
    <!-- placeholder={{label}} -->
    <input 
        [class.is-invalid]="ngControl.touched && ngControl.invalid"
        type={{type}}
        class="form-control go-bottom" 
        [formControl]="ngControl.control"
        placeholder={{label}}
        >
    <div style="right: 0px; " *ngIf="ngControl.control.errors?.required" class="invalid-feedback">Please enter a {{label}}</div>
    <div style="left: 100px;" *ngIf="ngControl.control.errors?.minlength" class="invalid-feedback">
        {{label}} must be at least {{ngControl.control.errors.minlength['requiredLength']}}
    </div>
    <div style="left: 100px;" *ngIf="ngControl.control.errors?.maxlength" class="invalid-feedback">
        {{label}} must be at most {{ngControl.control.errors.maxlength['requiredLength']}}
    </div>
    <div style="left: 100px;" *ngIf="ngControl.control.errors?.isMatching" class="invalid-feedback">
        Passwords do not match
    </div>
</div>