import { Component, OnInit } from '@angular/core';
import * as agCharts from 'ag-charts-community';
@Component({
  selector: 'app-sale-charts',
  templateUrl: './sale-charts.component.html',
  styleUrls: ['./sale-charts.component.css']
})
export class SaleChartsComponent implements OnInit {
  public options: any;
  public options2: any;
   myTheme = {
    baseTheme: 'ag-material-dark',
    palette: {
        fills: [
            '#000000',
            '#d9ffcc',
            '#21B372',
            '#FDDE02',
            '#F76700',
            '#D30018'
        ],
        strokes: ['green']
    },
    defaults: {
        cartesian: {
            title: {
                fontSize: 24
            },
            series: {
                column: {
                    label: {
                        enabled: true,
                        color: 'green'
                    }
                }
            }
        }
    }
};
  data = [
      {
          quarter: 'Q1',
          spending: 450,
      },
      {
          quarter: 'Q2',
          spending: 560,
      },
      {
          quarter: 'Q3',
          spending: 600,
      },
      {
          quarter: 'Q4',
          spending: 700,
      },
  ];

  beverageSpending = [
      {
          sale: 'Sport Utility',
          Q1: 450,
          Q2: 560,
          Q3: 600,
          Q4: 700,
      },
      {
        sale: 'Cars',
          Q1: 270,
          Q2: 380,
          Q3: 450,
          Q4: 520,
      },
      {
        sale: 'Service',
          Q1: 180,
          Q2: 170,
          Q3: 190,
          Q4: 200,
      },
  ];
  constructor() {
    this.options = {
        theme: this.myTheme,
          data: this.beverageSpending,
          title: {
              text: 'Sales',
          },
          subtitle: {
              text: 'per quarter',
          },
          series: [{
              type: 'column',
              xKey: 'sale',
              yKeys: ['Q1', 'Q2', 'Q3', 'Q4'],
              label: {},
          }],
      };

      this.options2 = {
        theme: this.myTheme,
        data: this.data,
        series: [{
            xKey: 'quarter',
            yKey: 'spending',
        }],
    };
  }
  ngOnInit() {


  }


}
