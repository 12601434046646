import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VehicleMileageCodes } from '../_models/VehicleMileageCodes';
import { VehicleMileagecodesService } from '../_services/vehicleserv/vehicle-mileagecodes.service';
@Injectable({
    providedIn: 'root'  // **** ADD THIS LINE ****
  })
export class VehicleMileageCodesResolver implements Resolve<VehicleMileageCodes[]> {
    constructor( private vehicleMileageCodesService: VehicleMileagecodesService, private router: Router,
        private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<VehicleMileageCodes[]> {
        // tslint:disable-next-line:no-string-literal
        return this.vehicleMileageCodesService.getList().pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving vehicle Mileage Codes.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
