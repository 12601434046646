import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { PaymentType } from '../_models/PaymentType';
import { PdfStateList } from '../_models/PDFentites/PdfStateList';
import { PaymentTypeService } from '../_services/paymenttypeservices/payment-type.service';
import { PdfService } from '../_services/PdfSErvices/pdf.service';

@Injectable({
    providedIn: 'root'
})
export class PdfStateListsResolver implements Resolve<PdfStateList[]> {

    constructor(private entityService: PdfService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<PdfStateList[]> {
        return this.entityService.getPdfStateList(route.paramMap.get('parentGuid'));
    }

}
