
<br>
<div class="col-md-12" *ngIf="selectedIndex >= 0" >
  <div class="row">
  <div class="col-md-2">
    <ul class="list-group my-3" >
      <!-- <li  class="list-group-item btn-primary active" style="cursor: pointer;" (click) ="openCategoryModal()" >
        Add Video Category
      </li> -->
      <li class="list-group-item " ></li>
      <li class="list-group-item " 
      *ngFor="let item of subMenu; let i = index"
      [class.active]="selectedIndex === i" 
      (click)="setIndex(i, item)" 
      >{{ item.name}}</li>
    </ul>
  </div>
  <div class="col-md-10">
    <div class="row">
      <div class="col-md-10">
        <button (click) = "getSubMenusByLanguage()" type="button" class="btn btn-primary">{{spanishLabel}}</button>
        <div class="row">
          <div class="col-md-4 mb-4" *ngFor="let item of supportSubMenuVideos">
            <div class="card h-100 shadow-sm">
              <div class="image position-relative" style="cursor: pointer;">
                <iframe style="position: relative;" width="300px" height="240px" [src]= "(baseUrl + item.youTubeLinkId) | safe: 'url'" frameborder="0" allowfullscreen></iframe>
              </div>
              <div class="card-body d-flex flex-column">
                  <div >
                      <h6 class="text-uppercase">{{item.videoName}}</h6>
                      <!-- <button  type="button" class="btn btn-primary">Deactivate</button>
                      <button type="button" class="btn btn-primary">Delete</button> -->
                  </div>
              </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  
