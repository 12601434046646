import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import moment from 'moment';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
@Injectable({ providedIn: 'root' })
export class SaleDataDealInfo {
    moment = require('moment');
    convertDate(date) {
        const momentDate = moment().format('L').toString();
        return momentDate;
    }
    convertGetDay(date: Date) {
        return date.getDate();
    }
    stringLeft(input: string, position: number, count: number)
    {
        return input.substr(position, Math.min(input.length, 1));
    }
    fnz02 = (num) => num.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
        })

          fnz04 = (num) => num.toLocaleString('en-US', {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
            useGrouping: false,
            })
            fnz0 = (num) => num.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
                })

      fillPdfFieldsDealInfo(sale: Sale, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
      let  field = '';
      let newpdfField = {} as PdfSaleFields;
      const newPdfFields = [] as PdfSaleFields[];
        pdfFields.forEach(element => {
        field = element.field;
        const str = field;
        const matches = str.match(/\d+/g);
        let i;
        if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }
        field = field.replace(i.toString(), '');
        switch (field) {
            case prefix + 'CashDown': {
                newpdfField  = {
                    field: prefix + 'CashDown' + i,
                    value: sale.cashDown.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'FinalPayment': {
                newpdfField  = {
                    field: prefix + 'FinalPayment' + i,
                    value: sale.finalPayment.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'NonAmortized': {
                newpdfField  = {
                    field: prefix + 'NonAmortized' + i,
                    value: sale.nonAmortized.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Amortized': {
                newpdfField  = {
                    field: prefix + 'Amortized' + i,
                    value: sale.amortized.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'SaleType': {
                newpdfField  = {
                    field: prefix + 'SaleType' + i,
                    value: sale.saleTypes?.value,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'SaleStatus': {
                newpdfField  = {
                    field: prefix + 'SaleStatus' + i,
                    value: sale.saleStatus?.value,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'InsuranceCode': {
                newpdfField  = {
                    field: prefix + 'InsuranceCode' + i,
                    value: sale.insuranceCode,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'SoldDate': {
               
                newpdfField  = {
                    field: prefix + 'SoldDAte' + i,
                    value: this.convertDate(sale?.soldDate).toString(),

                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'SoldDateDay': {
                const date = this.moment(sale?.soldDate);
                newpdfField  = {
                    field: prefix + 'SoldDateDay' + i,
                    value: (date.date()).toString(),

                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'SoldDateMonth': {
                const date = this.moment(sale?.soldDate);
                newpdfField  = {
                    field: prefix + 'SoldDateMonth' + i,
                    value: (date.month()).toString(),

                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'SoldDateYear': {
                const date = this.moment(sale?.soldDate);
                newpdfField  = {
                    field: prefix + 'SoldDateYear' + i,
                    value: (date.year()).toString(),

                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Comments': {
                newpdfField  = {
                    field: prefix + 'Comments' + i,
                    value: sale.comments,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'ACV': {
                newpdfField  = {
                    field: prefix + 'ACV' + i,
                    value: sale.aCV.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'ACV2': {
                newpdfField  = {
                    field: prefix + 'ACV2' + i,
                    value: sale.aCV2.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'WarrantyCost': {
                newpdfField  = {
                    field: prefix + 'WarrantyCost' + i,
                    value: sale.warrantyCost.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'GapCost': {
                newpdfField  = {
                    field: prefix + 'GapCost' + i,
                    value: sale.gapCost.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Reserve': {
                newpdfField  = {
                    field: prefix + 'Reserve' + i,
                    value: sale.reserve.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Commission2': {
                newpdfField  = {
                    field: prefix + 'Commission2' + i,
                    value: sale.commission2.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Commission1': {
                newpdfField  = {
                    field: prefix + 'Commission1' + i,
                    value: sale.commission1.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'ReservePrc': {
                newpdfField  = {
                    field: prefix + 'ReservePrc' + i,
                    value: sale.reservePrc.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Discount': {
                newpdfField  = {
                    field: prefix + 'Discount' + i,
                    value: sale.discount.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'TotalProfit': {
                newpdfField  = {
                    field: prefix + 'TotalProfit' + i,
                    value: sale.totalProfit.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'ActionDate': {
                newpdfField  = {
                    field: prefix + 'ActionDate' + i,
                    value: sale.actionDate.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Status': {
                newpdfField  = {
                    field: prefix + 'Status' + i,
                    value: sale.status.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Expense': {
                newpdfField  = {
                    field: prefix + 'Expense' + i,
                    value: sale.expense.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'TPKod': {
                newpdfField  = {
                    field: prefix + 'TPKod' + i,
                    value: sale.tPKod.toString(),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Fn1': {
                newpdfField  = {
                    field: prefix + 'Fn1' + i,
                    value: sale.fn1.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Fn2': {
                newpdfField  = {
                    field: prefix + 'Fn2' + i,
                    value: sale.fn2.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Fn3': {
                newpdfField  = {
                    field: prefix + 'Fn3' + i,
                    value: sale.fn3.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Fn4': {
                newpdfField  = {
                    field: prefix + 'Fn4' + i,
                    value: sale.fn4.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Fn5': {
                newpdfField  = {
                    field: prefix + 'Fn5' + i,
                    value: sale.fn5.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Fs6': {
                newpdfField  = {
                    field: prefix + 'Fs6' + i,
                    value: sale.fs6,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Fs7': {
                newpdfField  = {
                    field: prefix + 'Fs7' + i,
                    value: sale.fs7,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Fs8': {
                newpdfField  = {
                    field: prefix + 'Fs8' + i,
                    value: sale.fs8,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Fs9': {
                newpdfField  = {
                    field: prefix + 'Fs9' + i,
                    value: sale.fs9,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'Fs10': {
                newpdfField  = {
                    field: prefix + 'Fs10' + i,
                    value: sale.fs10,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'NetReserve': {
                newpdfField  = {
                    field: prefix + 'NetReserve' + i,
                    value: sale.netReserve.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'NetReservePrc': {
                newpdfField  = {
                    field: prefix + 'NetReservePrc' + i,
                    value: sale.netReservePrc.toFixed(4),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'GAP': {
                newpdfField  = {
                    field: prefix + 'GAP' + i,
                    value: sale.gAP.toFixed(4),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'DownPaymentMethods': {
                newpdfField  = {
                    field: prefix + 'DownPaymentMethods' + i,
                    value: sale?.downPaymentMethods?.value,
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
          default: {
                break;
             }
          }
         });
         return of(newPdfFields);
    }
}
