import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';

import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-companydept-modal',
  templateUrl: './companydept-modal.component.html',
  styleUrls: ['./companydept-modal.component.css']
})
export class CompanydeptModalComponent implements OnInit {
  @Output() updateData = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  department: any;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  update() {
    this.department = this.editForm.value;
    this.updateData.emit(this.department.name);
    this.bsModalRef.hide();
  }

}
