import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSubscriptionPlan } from 'src/app/_models/UserSubscriptionPlan';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionServiceService {

constructor(private http: HttpClient) { }
baseUrl = environment.apiUrl;

getSubscriptions(): Observable<UserSubscriptionPlan[]> {
  return this.http.get<UserSubscriptionPlan[]>(this.baseUrl + 'UserSubscriptions/subscriptions/' );
}
}
