import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { ClmVehicleService } from 'src/app/_services/clmvehiclesservices/clmvehicles.service';

@Component({
    moduleId: module.id,
    selector: 'app-clmperson-grid',
    templateUrl: 'clmperson-grid.component.html',
    styleUrls: ['clmperson-grid.component.scss']
})
export class ClmpersonGridComponent implements OnInit {
    isNotClicked = false;
    clmPerson = {} as any;
    clmPersons = [] as any[];
    selectedCompany1 = {} as UserAssignedToCompany;

    constructor(private vehicleService: ClmVehicleService,
        private authService: AuthService,
        private alertify: ToastrService) { }
    
    ngOnInit(): void {
        this.authService.currentCompany.subscribe(data => { this.selectedCompany1 = data; this.list(); });

    }
    list() {
        this.vehicleService.listPersons(this.selectedCompany1.parentGuid).
        subscribe((data: any[]) => {
          console.log(data);
          this.clmPersons = data;
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Success');
        });
    }

    post() {
        this.isNotClicked = true;
        this.vehicleService.getPeopleKodList(this.selectedCompany1.parentGuid).subscribe((data: any[]) => {
            console.log(data);
            data.forEach(element => {
                this.vehicleService.postPersons(element, this.selectedCompany1.parentGuid).subscribe(data => {  }, error => { console.log(error);  this.alertify.error('Error');}, ()=>{
                    this.clmPersons = data;
                    this.list();
                  //  this.alertify.success('Success');
                });
            });
        }, error => { this.alertify.error(error); }, () => {
            this.alertify.success('Success');
            this.list();
          });
    }
}