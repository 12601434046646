import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddServiceComponent } from '../add-service/add-service.component';

@Component({
  selector: 'app-service-grid',
  templateUrl: './service-grid.component.html',
  styleUrls: ['./service-grid.component.css']
})
export class ServiceGridComponent implements OnInit {

  constructor(private router: Router, private modalService: BsModalService) { }
  bsModalRef: any;
  ngOnInit() {
  }
  openNewInvoice(){
    const initialState = {
     
    };
     this.bsModalRef = this.modalService.show(AddServiceComponent, {backdrop: 'static', keyboard: false, initialState});
  }
}
