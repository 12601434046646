
<div class="customer-form-sec">
    <div class="container-fluid">
  <br><br>
      <div class="row">
        
        <div class="form-sec tab-content col-md-10">
          <div id="customer" class="tab-pane fade in active show">

<div class="row">
    <table class="table">
        <tr>
            <th style="width: 30%;">Username</th>
            <th style="width: 40%;">Active roles</th>
            <th style="width: 30%;"></th>
        </tr>
        <tr *ngFor="let user of users">
            <td>{{user.username}}</td>
            <td>{{user.person?.firstName + ' ' + user?.person?.lastName }}</td>
            <td>{{user.roles}}</td>
            <td><button (click)="openRolesModal(user)" class="btn btn-info">Edit Roles</button></td>
        </tr>
    </table>
</div>
</div>
</div>
</div>
</div>
</div>

