<div class="modal-header">
  <h4 class="modal-title pull-left">Edit Website Menu</h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form id="editForm" #editForm="ngForm" (ngSubmit)="update()">
  
    <div class="row vehicle-form">
        <div class="col-md-12 cstm-col">
            <div *ngIf="editForm.dirty" class="alert alert-info">
                <strong>Information:</strong> You have made changes. Any unsaved changes
                will be lost!
              </div>
        </div>
                          <!--Footer Menu bool#-->
  <div class="col-md-12 cstm-col">
    <div class="inline-blk"
    >
      <label>Footer Menu</label>
      <input class="cstm-input" value=""
      type="checkbox"
      autocomplete="off"
      [ngModel]="entity.footer"
      (ngModel)="entity.footer= $event"
      (ngModelChange)="entity.footer= !entity.footer"
      name="footer"
      #footer="ngModel"
      >
    </div>
   
  </div>
    <!--END footer menu bool #-->
          <!--Header Menu bool#-->
  <div class="col-md-12 cstm-col">
    <div class="inline-blk"
    >
      <label>Header Menu</label>
      <input class="cstm-input" value=""
      type="checkbox"
      autocomplete="off"
      [ngModel]="entity.headerMenu"
      (ngModel)="entity.headerMenu= $event"
      (ngModelChange)="checkMark('headermenu')"
      name="headerMenu"
      #headerMenu="ngModel"
      >
    </div>
   
  </div>
    <!--END header menu bool #-->
  <!--Menu Name #-->
  <div class="col-md-12 cstm-col">
    <div class="inline-blk"
    [ngClass]="{
      'has-success': name?.valid && (name?.touched || name?.dirty),
      'has-danger': name?.invalid && (name?.touched || name?.dirty)
    }">
      <label>Menu Name</label>
      <input class="cstm-input" value=""
      type="text"
      autocomplete="off"
      [ngModel]="entity.name"
      (ngModel)="entity.name= $event"
      (ngModelChange)="entity.name= $event"
      name="name"
      #name="ngModel"
      required>
    </div>
    <div
    *ngIf="name.errors && (name?.touched || name?.dirty)">
    <p *ngIf="name?.errors?.required">Category Is Required</p>
  </div>
  </div>
    <!--END name #-->
   <!--className #-->
   <div class="col-md-12 cstm-col">
    <div class="inline-blk"
    [ngClass]="{
      'has-success': className?.valid && (className?.touched || className?.dirty),
      'has-danger': className?.invalid && (className?.touched || className?.dirty)
    }">
      <label>Class Name</label>
      <input class="cstm-input" value=""
      type="text"
      autocomplete="off"
      [ngModel]="entity.className"
      (ngModel)="entity.className= $event"
      (ngModelChange)="entity.className= $event"
      name="className"
      #className="ngModel"
      >
    </div>
  </div>
    <!--END className #-->
     <!--Icon Name #-->
  <div class="col-md-12 cstm-col">
    <div class="inline-blk"
    [ngClass]="{
      'has-success': iconName?.valid && (iconName?.touched || iconName?.dirty),
      'has-danger': iconName?.invalid && (iconName?.touched || iconName?.dirty)
    }">
      <label>Icon Name</label>
      <input class="cstm-input" value=""
      type="text"
      autocomplete="off"
      [ngModel]="entity.iconName"
      (ngModel)="entity.iconName= $event"
      (ngModelChange)="entity.iconName= $event"
      name="iconName"
      #iconName="ngModel"
      >
    </div>
  </div>
    <!--END Icon name #-->
    
    <!--Icon TYpe #-->
  <div class="col-md-12 cstm-col">
    <div class="inline-blk"
    [ngClass]="{
      'has-success': iconType?.valid && (iconType?.touched || iconType?.dirty),
      'has-danger': iconType?.invalid && (iconType?.touched || iconType?.dirty)
    }">
      <label>Icon Type</label>
      <input class="cstm-input" value=""
      type="text"
      autocomplete="off"
      [ngModel]="entity.iconType"
      (ngModel)="entity.iconType= $event"
      (ngModelChange)="entity.iconType= $event"
      name="iconType"
      #iconType="ngModel"
      >
    </div>
  </div>
    <!--END Icon name #-->
    
     <!--Icon Url #-->
  <div class="col-md-12 cstm-col">
    <div class="inline-blk"
    [ngClass]="{
      'has-success': iconUrl?.valid && (iconUrl?.touched || iconUrl?.dirty),
      'has-danger': iconUrl?.invalid && (iconUrl?.touched || iconUrl?.dirty)
    }">
      <label>Icon URL</label>
      <input class="cstm-input" value=""
      type="text"
      autocomplete="off"
      [ngModel]="entity.iconUrl"
      (ngModel)="entity.iconUrl= $event"
      (ngModelChange)="entity.iconUrl= $event"
      name="iconUrl"
      #iconUrl="ngModel"
      >
    </div>
  </div>
    <!--END Icon url #-->

  <!--Menu Level #-->
  <div class="col-md-12 cstm-col">
    <div class="inline-blk"
    [ngClass]="{
      'has-success': level?.valid && (level?.touched || level?.dirty),
      'has-danger': level?.invalid && (level?.touched || level?.dirty)
    }">
      <label>Menu Level</label>
      <select tabindex="-1" class="cstm-select" 
        #level="ngModel"  
        name="level"  
        [(ngModel)]="entity.level"							 
        (ngModelChange)="changeSubMenu($event)" >
        <option style="color: #bb400f;" [value]="-1">None</option>
        <option *ngFor="let item of menuLevel"     [ngValue]="item.id">{{item?.id}}</option>
        </select>
    </div>
  </div>
    <!--END Menu Level #-->

 <!-- Url #-->
 <div class="col-md-12 cstm-col">
  <div class="inline-blk"
  [ngClass]="{
    'has-success': url?.valid && (url?.touched || url?.dirty),
    'has-danger': url?.invalid && (url?.touched || url?.dirty)
  }">
    <label>Icon URL</label>
    <input class="cstm-input" value=""
    type="text"
    autocomplete="off"
    [ngModel]="entity.url"
    (ngModel)="entity.url= $event"
    (ngModelChange)="entity.url= $event"
    name="url"
    #url="ngModel"
    >
  </div>
</div>
  <!--END url #-->
    <!--Grouped gruop# -->
    <div class="col-md-12 cstm-col">
      <div class="inline-blk"
      [ngClass]="{
        'has-success': group?.valid && (group?.touched || group?.dirty),
        'has-danger': group?.invalid && (group?.touched || group?.dirty)
      }">
        <label>Group Level</label>
        <select tabindex="-1" class="cstm-select" 
          #group="ngModel"  
          name="group"  
          [(ngModel)]="entity.group"							 
          (ngModelChange)="changeGroupSubMenu($event)" >
          <option style="color: #bb400f;" [value]="-1">None</option>
          <option *ngFor="let item of groupLevel"     [ngValue]="item.id">{{item?.id}}</option>
          </select>
      </div>
    </div>
      <!--END Grouped gruop# -->
        <!--button save-->
      <div class="col-md-12 cstm-col ">
        <button   [disabled]="!editForm.valid" type="submit" class="btn btn-primary ">
          SAVE
        </button>
        <p *ngIf="!editForm.valid" style="color: red">
          Save Button Will be enabled when all fields entered!
        </p>
      </div>
  
    </div>
</form>
</div>
