
  
   <!-- <button *ngIf="isNewParagraph" (click) = "cancelNewParagraph()" style="width: 200px;" class="btn btn-success">Return To Menu</button> -->



<div class="customer-form-sec">
  <div>
    <br>
    <div class="row">
      <div class="form-sec tab-content col-md-12">
        <div id="depreciate-costs" class="tab-pane fade in active show">
          <div class="row">
            <div class="col-md-10">
              <div class="inner-row account-payable-row">
                <!-- <h2 class="veh-title">Vehicle Added Costs</h2> -->
                <div class="row">
                </div>
                <div class="row">
                  <div class="col-md-12 table-sec">
                    <form id="editForm" #editForm="ngForm" (ngSubmit)="updateAddparagraph()"> 
                      <div class="row vehicle-form">
                          <div class="col-md-12 cstm-col">
                              <div *ngIf="editForm.dirty" class="alert alert-info">
                                  <strong>Information:</strong> You have made changes. Any unsaved changes
                                  will be lost!
                                </div>
                          </div>
                    
                   
                          <!--  Website Paragraph --> 
                    <div class="col-md-12 cstm-col">
                      <div class="inline-blk"
                      [ngClass]="{
                        'has-success': title?.valid && (title?.touched || title?.dirty),
                        'has-danger': title?.invalid && (title?.touched || title?.dirty)
                      }">
                        <label>Title</label>
                        <input class="cstm-input" value=""
                        type="text"
                        autocomplete="off"
                        [ngModel]="websiteMenuParagrahp?.title"
                        (ngModel)="websiteMenuParagrahp.title= $event"
                        (ngModelChange)="websiteMenuParagrahp.title= $event"
                        name="title"
                        #title="ngModel"
                        required>
                      </div>
                      <div
                      *ngIf="title.errors && (title?.touched || title?.dirty)">
                      <p *ngIf="title?.errors?.required">Title Is Required</p>
                    </div>
                    </div>
                      <!--END Paragraph #-->
                         <!--  Website Paragraph --> 
                         <div class="col-md-12 cstm-col">
                          <div class="inline-blk"
                          [ngClass]="{
                            'has-success': title?.valid && (title?.touched || title?.dirty),
                            'has-danger': title?.invalid && (title?.touched || title?.dirty)
                          }">
                            <label>Title</label>
                            <input class="cstm-input" value=""
                            type="text"
                            autocomplete="off"
                            [ngModel]="websiteMenuParagrahp?.title"
                            (ngModel)="websiteMenuParagrahp.title= $event"
                            (ngModelChange)="websiteMenuParagrahp.title= $event"
                            name="title"
                            #title="ngModel"
                            required>
                          </div>
                          <div
                          *ngIf="title.errors && (title?.touched || title?.dirty)">
                          <p *ngIf="title?.errors?.required">Title Is Required</p>
                        </div>
                        </div>
                          <!--END Paragraph #-->
                        <!--Description #-->
                    <div class="col-md-12 cstm-col">
                      <div class="inline-blk"
                      [ngClass]="{
                        'has-success': description?.valid && (description?.touched || description?.dirty),
                        'has-danger': description?.invalid && (description?.touched || description?.dirty)
                      }">
                        <label>Description</label>
                        <input class="cstm-input2" value=""
                        type="text"
                        autocomplete="off"
                        [ngModel]="websiteMenuParagrahp?.description"
                        (ngModel)="websiteMenuParagrahp.description= $event"
                        (ngModelChange)="websiteMenuParagrahp.description= $event"
                        name="description"
                        #description="ngModel"
                        required>
                      </div>
                      <div
                      *ngIf="description.errors && (description?.touched || description?.dirty)">
                      <p *ngIf="description?.errors?.required">Description Is Required</p>
                    </div>
                    </div>
                      <!--END Description #-->
                  
                            <!--paragraph #-->
                    <div class="col-md-12 cstm-col">
                      <div class="inline-blk"
                      [ngClass]="{
                        'has-success': paragraph?.valid && (paragraph?.touched || paragraph?.dirty),
                        'has-danger': paragraph?.invalid && (paragraph?.touched || paragraph?.dirty)
                      }">
                        <label>Paragraph</label>
                        <textarea rows="9" cols="90"  value=""
                        type="text"
                        autocomplete="off"
                        [ngModel]="websiteMenuParagrahp?.paragraph"
                        (ngModel)="websiteMenuParagrahp.paragraph= $event"
                        (ngModelChange)="websiteMenuParagrahp.paragraph= $event"
                        name="paragraph"
                        #paragraph="ngModel"
                        required></textarea>
                      </div>
                      <div
                      *ngIf="paragraph.errors && (paragraph?.touched || paragraph?.dirty)">
                      <p *ngIf="paragraph?.errors?.required">Description Is Required</p>
                    </div>
                    </div>
                      <!--END paragraph #-->
                          <!--button save-->
                
                    
                      </div>
                  </form>
                  </div><!-- /Body -->
                </div>
              </div>
            </div>
            <div class="customer-sidebar sidebar-with-btns col-md-2">
            <div class="inner-sidebar">
              <ul>
                <li>
                  <button class="cstm-btn img-blk-btn"  (click) = "updateAddparagraph()">
                   
                    <img src="assets/images/ap-icon-1.png">
                    <span>Save</span>
                  </button>
                </li>
                <li>
                  <button class="cstm-btn img-blk-btn" (click)="addParagrah()">
                    <img src="assets/images/ap-icon-1.png">
                    <span>Add New</span>
                  </button>
                </li>
                <li>
                  <button class="cstm-btn img-blk-btn" (click)="cancelUpdate()">
                    <img src="assets/images/ap-icon-1.png">
                    <span>Cancel</span>
                  </button>
                </li>
                <li>
                  <button class="cstm-btn img-blk-btn" (click)="cancelUpdate()">
                    <img src="assets/images/ap-icon-1.png">
                    <span>Menus</span>
                  </button>
                </li>
              </ul>
            </div>
            </div>
          </div><!-- /.row -->
        </div>
      </div>
    </div>
  </div>
</div>
