<div class="customer-form-sec">
  <div class="container-fluid">
<div class="row">
<div class="form-sec tab-content col-md-12">
  <div id="vehicle" class="tab-pane fade in active show">
<div style="width: 550px" class="modal1" id="myModal">
  <div class="modal-dialog1 modal1" style="width:550px;">
     <div class="modal1-content modal1" style="width:450px;" >

<div  class="modal-header modal1">
  <h4 class="modal-title pull-left">Add Vehicle</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div  class="modal-body modal1">


<div class="form-sec tab-content col-md-12" style="width:550px !important;">
<form id="editForm" #editForm="ngForm" (ngSubmit)="update()">
  
    <div class="row vehicle-form">
        <div class="col-md-12 cstm-col">
            <div *ngIf="editForm.dirty" class="alert alert-info">
                <strong>Information:</strong> You have made changes. Any unsaved changes
                will be lost!
              </div>
        </div>
  <!--STOCK #-->
  <div class="col-md-12 cstm-col">
    <div class="inline-blk"
    [ngClass]="{
      'has-success': stockNumber?.valid && (stockNumber?.touched || stockNumber?.dirty),
      'has-danger': stockNumber?.invalid && (stockNumber?.touched || stockNumber?.dirty)
    }">
      <label>Stock #</label>
      <input class="cstm-input" value=""
      type="text"
      autocomplete="off"
      [ngModel]="vehicle?.stockNumber"
      (ngModel)="vehicle.stockNumber = $event"
      name="stockNumber"
      #stockNumber="ngModel"
      required>
    </div>
    <div
    *ngIf="stockNumber.errors && (stockNumber?.touched || stockNumber?.dirty)">
    <p *ngIf="stockNumber?.errors?.required">Stock Is Required</p>
  </div>
  </div>
    <!--END STOCK #-->

     <!--VIN #-->
     
       <div class="col-md-12 cstm-col">
        <div class="inline-blk"
        [ngClass]="{
          'has-success': vin?.valid && (vin?.touched || vin?.dirty),
          'has-danger': vin?.invalid && (vin?.touched || vin?.dirty)
        }">
          <label>VIN</label>
          <input class="cstm-input" value=""
          type="text"
          autocomplete="off"
          [ngModel]="vehicle?.vin"
          (ngModel)="vehicle.vin = $event"
          name="vin"
          #vin="ngModel"
          required>
        </div>

        <div
        *ngIf="vin.errors && (vin?.touched || vin?.dirty)">
        <p *ngIf="vin?.errors?.required">VIN Is Required</p>
      </div>
      </div>
        <!--END VIN #-->
 <!-- purchasedPrice-->  
 <div class="col-md-12 cstm-col">
  <label>Suggested Price</label>
  <input  autocomplete="puchasedAs-name" class="cstmnumeric-input"  type="number"
  [ngModel]="vehicle?.vehicleAmounts?.suggestedPrice || 0  | number : '1.2'"
#suggestedPrice="ngModel"
  (ngModelchange)="vehicle.vehicleAmounts.suggestedPrice = $event"
  (onblur)="updateValues()"
 
  name="suggestedPrice"
  >
</div>
         <!-- purchasedPrice-->  
  <div class="col-md-12 cstm-col">
      <label>Cost</label>
      <input  autocomplete="puchasedAs-name" class="cstmnumeric-input"  type="number"
      [ngModel]="vehicle?.vehicleAmounts?.purchasedPrice || 0  | number : '1.2'"
    #purchasedPrice="ngModel"
      (ngModelchange)="vehicle.vehicleAmounts.purchasedPrice = $event"
      (onblur)="updateValues()"
      name="purchasedPrice"
      >
  </div>

    <!--MILEAGE-->
    <div class="col-md-12 cstm-col">
        <div class="input-sec veh-mile-sec">
          <div class="rw">
            <label>Mileage</label>
            <input autocomplete="puchasedAs-name" type="number" value="0" class="cstmnumeric-input" 
            [ngModel]="vehicle?.vehicleDetails?.mileage || 0"
            (ngModel)="vehicle.vehicleDetails.mileage = $event"
            (onblur)="updateValues()"
            name="mileage"
            #mileage="ngModel">
          </div>
        </div>
    </div>
        <!--button save-->
      <div class="col-md-12 cstm-col ">
        <button [disabled]="!editForm.valid" type="submit" class="btn btn-primary ">
          SAVE
        </button>
        <p *ngIf="!editForm.valid" style="color: red">
          Save Button Will be enabled when all fields entered!
        </p>
      </div>
  
    </div>
</form>
</div>

</div>
</div>
  </div>
</div>
  </div>
</div>
</div>
</div>


