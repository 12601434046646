import {Injectable} from '@angular/core';
import {Resolve, Router, ActivatedRouteSnapshot} from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PdfForms } from '../_models/PdfForms';
import { PdfService } from '../_services/PdfSErvices/pdf.service';

@Injectable({
    providedIn: 'root'
})
export class PdfFormByIdResolver implements Resolve<PdfForms> {
    constructor(private pdfService: PdfService,
        private router: Router, private alertify: ToastrService
        ) {}
    //    private alertify: AlertifyService
    resolve(route: ActivatedRouteSnapshot): Observable<PdfForms> {
        const newLocal = 'pdfformid';
        return this.pdfService.getPdfForm(route.params[newLocal]).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving pdf file.');
                this.router.navigate(['']);
                return of(null);
            })
        );
    }
}
