<p *ngIf = "!isGuidVerified && isStartVerificationTrue"> 
  {{passwordResetGuid}}
</p>
<div *ngIf = "isStartVerificationTrue">
  <p> Please check your email for a verification link.</p>
</div>
<div class="container-fluid">
  <div class="row">

 
  <div class="col-md-12">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <form *ngIf = "isGuidVerified" #editForm="ngForm" id="editForm" (ngSubmit)="editForm.valid && register()" autocomplete="off">
        <h2 class="text-center text-primary">Reset password</h2>
        <hr>
      
        <div class="form-inline">
      
          <label for="city">New password</label>
          <input
          [ngModel]="user?.newPassword"
          (ngModel)="user.newPassword = $event"
          (ngModelChange) = "user.newPassword = $event; "
          type="text" name="newPassword" class="form-control mx-2">
          </div>
      
       <div class="form-inline">
          <label for="city">Comfirm New Password </label>
          <input 
          [ngModel]="user?.comfirmPassword"
          (ngModel)="user.comfirmPassword = $event"
          (ngModelChange) = "user.comfirmPassword = $event; "
          type="text" name="comfirmPassword" 
          class="form-control mx-2">
        </div>
      
        <div class="form-group text-center" *ngIf="isSubmitted">
            <button [disabled]='!editForm.valid' (click) = "register()" class="btn btn-success mr-2" type="submit">Reset Password</button>
        </div>
      </form>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
</div>
