import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as agCharts from 'ag-charts-community';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
// import 'ag-chart-community/dist/styles/dark';
// import 'ag-chart-community/dist/styles/ag-default-dark.css';

@Component({
  selector: 'app-sale-dashboard',
  templateUrl: './sale-dashboard.component.html',
  styleUrls: ['./sale-dashboard.component.css']
})
export class SaleDashboardComponent implements OnInit {
  isDashboard = true;
  isAll = false;
  isActive = false;
  isPaid = false;
  isLease = false;
  isRental = false;
  selectedCompany1: UserAssignedToCompany;
  constructor(private authService: AuthService, private router: Router) {}
  ngOnInit() {
    this.authService.company.subscribe(data => { this.selectedCompany1 = data; });
  }

  tabClicked(e) {
    this.isDashboard = false;
    this.isAll = false;
    this.isActive = false;
    this.isPaid = false;
    this.isLease = false;
    this.isRental = false;
    if (e === 'isDashboard') { this.isDashboard = true;  this.router.navigate(['saledashboard/saledashboard/' +   this.selectedCompany1.parentGuid]); }
    if (e === 'isAll') { this.isAll = true;   this.router.navigate(['saledashboard/salegrid/' +   this.selectedCompany1.parentGuid] ); }
    if (e === 'isActive') { this.isActive = true;  this.router.navigate(['saledashboard/salegrid/' +   this.selectedCompany1.parentGuid] ); }
    if (e === 'isPaid') { this.isPaid = true;  this.router.navigate(['saledashboard/salegrid/' +   this.selectedCompany1.parentGuid] ); }
    if (e === 'isLease') { this.isLease = true;  this.router.navigate(['saledashboard/salegrid/' +   this.selectedCompany1.parentGuid] ); }
    if (e === 'isRental') { this.isRental = true;  this.router.navigate(['saledashboard/salegrid/' +   this.selectedCompany1.parentGuid] ); }
  }
}
