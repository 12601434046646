import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SocialMedia } from 'src/app/_models/socialMedia';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Website } from 'src/app/_models/website';
import { WebsiteService } from 'src/app/_services/advertising/website.service';
import { WebsiteSocialmediaService } from 'src/app/_services/advertising/websitesocialmedia.service';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  socialMedia = {} as SocialMedia;
  socialMediaId: any;
  website = {} as Website;
  companyGuid: string;
  userAssignedToCompany: UserAssignedToCompany;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.editForm.dirty) {
      $event.returnValue = true;
    }
  }
  constructor(private route: ActivatedRoute,
     private authService: AuthService,
     private alertify: ToastrService,
     private websiteSocialmediaService: WebsiteSocialmediaService,
    private websiteService: WebsiteService) { }

  ngOnInit() {
    this.websiteService.website.subscribe(data => {
      this.website = data;
      this.socialMedia = data.socialMedia;
    });
    this.onSocialMediaGet();
    console.log(this.socialMedia);
  }

  onSubmitSocial(e) {
    console.log(this.editForm.value);
    this.socialMedia = this.editForm.value;
    this.socialMedia.id = this.website.socialMediaId;
    this.websiteSocialmediaService.updateSocialMedia(this.socialMedia).subscribe(data => {
      this.socialMedia = data; }, error => {
      this.alertify.error(error);
    }, () => { this.alertify.success('Success'); this.editForm.form.markAsPristine(); }  );
        console.log(this.socialMedia);
  }

  onSubmit() {
    console.log(this.editForm.value);
  }

  getWebiste(id) {
    this.websiteService.getWebsiteByParentGuid(id).subscribe(data => {
      this.socialMedia = data.socialMedia;
    }, error => {
      this.alertify.error(error);
    }, () => {
      this.alertify.success('Social Media Retrieved');
    } );
  }

  onSocialMediaGet() {
    this.websiteService.getSocialMedia(this.website.socialMediaId).subscribe(data => {
      this.socialMedia = data;
    }, error => {}, () => {
      if (this.socialMedia.companyWebsite === null) { this.socialMedia.companyWebsite = ''; }
      if (this.socialMedia.facebookUrl === null) { this.socialMedia.facebookUrl = ''; }
      if (this.socialMedia.googlePlusUrl === null) { this.socialMedia.googlePlusUrl = ''; }
      if (this.socialMedia.instagramUrl === null) { this.socialMedia.instagramUrl = ''; }
      if (this.socialMedia.personalFaceBookLink === null) { this.socialMedia.personalFaceBookLink = ''; }
      if (this.socialMedia.reviewsUrl === null) { this.socialMedia.reviewsUrl = ''; }
      if (this.socialMedia.rssFeed === null) { this.socialMedia.rssFeed = ''; }
      if (this.socialMedia.twitterUrl === null) { this.socialMedia.twitterUrl = ''; }
      if (this.socialMedia.youTubeUrl === null) { this.socialMedia.youTubeUrl = ''; }
    });
  }

}
