import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { ToastrService } from 'ngx-toastr';
import { WebsiteCompany } from 'src/app/_models/company';
import { CompanyDepartment } from 'src/app/_models/CompanyDepartment';
import { CompanyPhoto } from 'src/app/_models/companyphoto';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Website } from 'src/app/_models/website';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyPhotoService } from 'src/app/_services/CompanyPhoto/company-photo.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-companyservice-photo',
  templateUrl: './companyservice-photo.component.html',
  styleUrls: ['./companyservice-photo.component.css']
})
export class CompanyservicePhotoComponent implements OnInit {
  @Input() photos: CompanyPhoto[] = [];
  @Input() company: WebsiteCompany;
  @Input() website: Website;
  @Input() serviceOffer: Website;
  @Input() companyDepartment: CompanyDepartment;
  @Output() getMemberPhotoChange = new EventEmitter<string>();
  uploader: FileUploader;
  hasBaseDropZoneOver = false;
  baseUrl = environment.apiUrl;
  currentMain: CompanyPhoto;
  userAssignedToCompaney: UserAssignedToCompany;
  constructor(private authService: AuthService,
    private alertify: ToastrService,
    private confirmService: ConfirmService,
     private photoService: CompanyPhotoService) { }

  ngOnInit() {
    console.log(this.companyDepartment);
    this.company = this.website?.company;
    this.authService.company.subscribe(data => { this.userAssignedToCompaney = data; });
    if (this.companyDepartment != null) {
      this.initializeUploader();
    }

  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  initializeUploader() {
    console.log(localStorage.getItem('token'));
   // console.log(this.companyDepartment.id);
    // url is url to post like a service
    this.uploader = new FileUploader({
      url: this.baseUrl + 'companyphoto/addcompanydepartmentservicephoto/' + this.website.websiteGuid + '/' + this.serviceOffer.id,
      authToken: 'Bearer ' + localStorage.getItem('token'),
      isHTML5: true,
      allowedFileType: ['image'],
      removeAfterUpload: true,
      autoUpload: false,
      maxFileSize: 10 * 1024 * 1024
    });

    this.uploader.onAfterAddingFile = (file) => {file.withCredentials = false; };

    this.uploader.onSuccessItem = (item, response, status, headers) => {
      if (response) {
        const res: CompanyPhoto = JSON.parse( response);
        const photo = {
          id: res.id,
          url: res.url,
          dateAdded: res.dateAdded,
          isAvatar: res.isAvatar,
          sortOrder: res.id,
          fileName: res.fileName,
          companyId: res.companyId,
          alt: res.alt,
          originalName: res.originalName,
          publicId: res.publicId
        };
        this.photos.push(photo);
      }
    };
  }

  deletePhoto(id: number) {
    console.log(id);
    this.confirmService.confirm('Confirm delete message', 'Are you sure?').subscribe(result => {
      if (result) {
      this.photoService.deletePhoto(this.company.id, id, this.companyDepartment.id).subscribe(() => {
        this.photos.splice(this.photos.findIndex(p => p.id === id), 1);
        this.alertify.success('Photo has been deleted');
      }, error => {
        this.alertify.error('Failed to delete the photo');
      });
    }
    });
  }
}

