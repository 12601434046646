
<div class="container-fluid customer-tabs-sec">
  <ul class="nav nav-tabs">
    <li><a [ngClass]="{'active show' : isVehicle }"  (click)="tabClicked('isVehicle')" href="javascript:void(0)">Vehicle</a></li>
    <li><a [ngClass]="{'active show' : isRepairs }"  (click)="tabClicked('isRepairs')" href="javascript:void(0)">Added Cost / Depreciation</a></li>
    <li><a [ngClass]="{'active show' : isOptions }"  (click)="tabClicked('isOptions')" href="javascript:void(0)">Misc / Options</a></li>
    <li><a [ngClass]="{'active show' : isImages }"  (click)="tabClicked('isImages')" href="javascript:void(0)">Images</a></li>
    <li><a [ngClass]="{'active show' : isDescription }"  (click)="tabClicked('isDescription')" href="javascript:void(0)">Description</a></li>
  </ul>
</div>
<br>
<app-inventory-edit [formName] = "formName" [chartItem] = "item" [hidden] = "!isVehicle"></app-inventory-edit>
<app-addnewoptions-vehicle [hidden] = "!isOptions"  [vehicleDefaultOptions]="defaultOptionList"></app-addnewoptions-vehicle>
<app-addnewdescription-vehicle [chartItem] = "item"  [hidden] = "!isDescription"></app-addnewdescription-vehicle>
<app-addnewphotos-vehicle  [hidden] = "!isImages" [item] = "item" ></app-addnewphotos-vehicle>
<app-inventory-repair [dollars] = "dollars" [hidden] = "!isRepairs" [item] = "item"></app-inventory-repair>
<!-- [photos] = "photos" -->
<!-- <app-inventory-add [hidden] = "!isVehicle"></app-inventory-add> -->
<!-- <app-inventory-descripton [hidden] = "!isDescription"></app-inventory-descripton>
<app-inventory-images [hidden] = "!isImages"></app-inventory-images>

<app-inventory-options [hidden] = "!isOptions"></app-inventory-options> -->
