import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { AuthService } from 'src/app/_services/auth.service';
import { ClmVehicleService } from 'src/app/_services/clmvehiclesservices/clmvehicles.service';

@Component({
    moduleId: module.id,
    selector: 'app-clminsurance-grid',
    templateUrl: 'clminsurance-grid.component.html',
    styleUrls: ['clminsurance-grid.component.scss']
})
export class ClminsuranceGridComponent implements OnInit {
    isNotClicked = false;
    entity = {} as any;
    entities = [] as any[];
    selectedCompany1 = {} as UserAssignedToCompany;

    constructor(private vehicleService: ClmVehicleService,
        private authService: AuthService,
        private alertify: ToastrService) { }
    
    ngOnInit(): void {
        this.authService.currentCompany.subscribe(data => { this.selectedCompany1 = data; this.list(); });

    }
    list() {
        this.vehicleService.listInsurances(this.selectedCompany1.parentGuid).
        subscribe((data: any[]) => {
          console.log(data);
          this.entities = data;
        }, error => { this.alertify.error(error); }, () => {
          this.alertify.success('Success');
        });
    }
    postInsuranceCompanies() {
        this.isNotClicked = true;
                this.vehicleService.postInsuranceComanies( this.selectedCompany1.parentGuid).subscribe(data => {}, error => {}, ()=>{
                    this.list();
                    this.alertify.success('Success');
                });
        
    }
}
