import { Component, Input, OnChanges, OnInit } from '@angular/core';
// / <reference path ="../../node_modules/@types/jquery/index.d.ts"/>
// declare var $: any;
// import * as $ from 'jquery';

import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PdfMergingitemsComponent } from 'src/app/forms/pdf-mergingitems/pdf-mergingitems.component';
import { BuyersguideComponent } from 'src/app/modals/buyersguide/buyersguide.component';
import { LabelDoubleClickModalComponent } from 'src/app/modals/LabelDoubleClickModal/LabelDoubleClickModal.component';
import { PrintPopupComponent } from 'src/app/sales/print-popup/print-popup.component';
import { DealInforFormat } from 'src/app/_classes/dealinfoclasses/dealinfoformat';
import { PdfMergeInventoryPdfs } from 'src/app/_classes/pdffielddatats/merge-inventorydocuments';
import { PdfMergePdfs } from 'src/app/_classes/pdffielddatats/merge-pdfdocuments';
import { PdfFormPackageFullDto } from 'src/app/_models/DTOS/PdfFormPackageFullDto';
import { FormName } from 'src/app/_models/formName';
import { Item } from 'src/app/_models/Item';
import { Label } from 'src/app/_models/label';
import { PdfFormPackage } from 'src/app/_models/pdfformpackage';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { VehicleMileageCodes } from 'src/app/_models/VehicleMileageCodes';
import { AuthService } from 'src/app/_services/auth.service';
import { DollarService } from 'src/app/_services/dollarservices/dollar.service';
import { FormNameLabelsService } from 'src/app/_services/formLabelservices/form-name-labels.service';
import { ItemService } from 'src/app/_services/itemservices/item.service';
import { ListService } from 'src/app/_services/listServices/list.service';
import { PdfPackageService } from 'src/app/_services/PdfSErvices/pdf-package.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';
import { VehicleService } from 'src/app/_services/vehicleserv/vehicle.service';
import printJS from 'print-js';
import { AddVehicleVinModalComponent } from 'src/app/modals/add-vehicle-vin-modal/add-vehicle-vin-modal.component';
import { VehicleNewDto } from 'src/app/_models/_models/DTO/VehicleNewDto';
import { Vehicle } from 'src/app/_models/vehicle';
import { ItemVehicleTransientDetails } from 'src/app/_models/ItemVehicleTransientDetails';
import { VehicleBodyTypeListComponent } from 'src/app/lists/vehicle-body-type-list/vehicle-body-type-list.component';

// INPUTs from Inventory Desk

@Component({
  selector: 'app-inventory-add',
  templateUrl: './inventory-add.component.html',
  styleUrls: ['./inventory-add.component.css'],
})
export class InventoryAddComponent implements OnInit, OnChanges {
  @Input() formName = {} as FormName;
  @Input() chartItem = {} as Item;
  decodeVinShow = false;
  // drop down fields move these to? Inentory desk....
  mileageCodes = [] as VehicleMileageCodes[];
  vehicleYears: any[] = [];
  bodyTypes: any[] = [];
  bsModalRef: any;
  base64: any;
  // form labesls
  labels = [] as Label[];
  label: Label;
  labelToDisplay: any;
  loading: boolean;
  // primary company
  selectedCompany1: UserAssignedToCompany;
  isPrintButtonPackage: any;
  // label variables
  labelStock: any;
  labelVin: any;
  labelYear: any;
  labelStatus: any;
  labelCountyCode: any;
  labelCategory: any;
  lableTitleNumber: any;
  labelPreviousTitleNumber: any;
  labelMake: any;
  labelPreviousState: any;
  labelModel: any;
  labelPurchasedAs: any;
  labelExertiorColor1: any;
  labelPurchasedPrice: any;
  labelStyle: any;
  labelRepairsDepreciated: any;
  labelDisplacement: any;
  labelTotalCost: any;
  labelCylinders: any;
  labelSuggestedPrice: any;
  labelDatePurchased: any;
  labelDecalNumber: any;
  labelTag: any;
  labelTagExpires: any;
  labelMileage: any;
  labelWeight: any;
  isLoaded: boolean;
  labelMileageCode: any;
  isEdit: boolean;
  avatar: any;
  newChartInvoicedItem: any;

  inventoryPackages = [] as PdfFormPackage[];

  chartInvoicedItems: any[];
  chartSalesTaxes: any[];
  chartInvoiceStatuses: any[];
  chartInvoiceSaleTypes: any[];
  chartTransaction: any;
  chartInvoiceCreateNewCarSaleDto: any;
  selectedCategory: any;
  selectedBodyType: any;
  vehicle = {} as any;
  itemVehicleTransientDetails = {} as ItemVehicleTransientDetails;
  vehicleMakes: any;
  
  vehicleModels: any;
  bsModalRef1: any;
  bsModalRef1BuyersGuide: any;
  constructor(
    private modalService: BsModalService,
    private pdfMergeItemsPdfs: PdfMergeInventoryPdfs,
    private pdfService: PdfService,
    private route: ActivatedRoute,
    private itemService: ItemService,
    private dollarService: DollarService,
    private authService: AuthService,
    private formNameService: FormNameLabelsService,
    private router: Router,
    private saleService: SaleService,
    private alertify: ToastrService,
    private format: DealInforFormat,
    private listService: ListService,
    private pdfPackageService: PdfPackageService,
    private vehicleService: VehicleService, private formService: FormNameLabelsService
  ) { }
  ngOnChanges() {
    // this.getLabels();
    this.listService.currentItemVehicleEdit.subscribe(data => {
    //  this.chartItem = data;
    });
    this.selectedBodyType = this.chartItem.vehicle.vehicleBodyClassTypes.id;
    this.selectedCategory = this.chartItem.itemVehicleTransientDetails.vehicleMileageCodesId;
  }
  ngOnInit() {
    this.authService.company.subscribe(data => {this.selectedCompany1 = data;
      this.getFormLabels();
    //  this.vehicle.itemVehicleTransientDetails = this.itemVehicleTransientDetails;
    });
    this.route.data.subscribe(data => {
      this.chartItem = data.newVehicle;
    //   this.formName = data.formName;
    });
    console.log(this.chartItem);
    this.selectedBodyType = this.chartItem.vehicle.vehicleBodyClassTypes.id;
    this.selectedCategory = this.chartItem.itemVehicleTransientDetails.vehicleMileageCodes.id;
  //  this.validateDataFields();
    // bodyTypes
  //  console.log(this.chartItem);
    this.getBodyTypes();
    this.getMileageCodes();
    this.getYears();
  

  }
  onSubmit(e) {
    const itemAdded = this.chartItem;
   const seq = (Math.floor(Math.random() * 100000) + 10000).toString().substring(1);
    if (this.chartItem.vehicle.vin.length < 7) {
      this.chartItem.vehicle.vin = this.chartItem.vehicle.vin + seq;
    }
    console.log(e);
    console.log(this.chartItem);
    this.vehicleService.saveNonDecodedItem(this.chartItem).subscribe(data => {
      console.log(data);
      this.chartItem =  data;

    }, error => { this.alertify.error('Vehicle Not Saved'); }, () =>
    { this.alertify.success('Vehicle Saved');
    this.router.navigate(['vehicledashboard/editvehicle/' + this.selectedCompany1.parentGuid + '/' +  this.chartItem.id]);
  });
  }
  // ********************************************
  // ***LABELS OF CAPTIONS START ****************
  // ********************************************
  getFormLabels() {
    // this.formService.getFormNameList(this.selectedCompany1.parentGuid).subscribe(data => {
    //   localStorage.setItem('formsLabels', JSON.stringify(data));
    //   console.log(data);
    //   this.authService.changeFormLabels(data);
    //   this.getLabels();
    this.getLabels();   
    // });
  }
  getLabels() {
    this.authService.currentFormNames.subscribe((data) => {
      data.forEach((element) => {
        if (element.value === 'VehicleDetails') {
          this.labels = element.labels;
          console.log(data);
          this.decipherLabels();
        }
      });
    });
  }
  toggleValue2(e) {
    const initialState = {
      labelName: e,
    };
    this.bsModalRef1 = this.modalService.show(LabelDoubleClickModalComponent, {
      initialState,
    });
    this.bsModalRef1.content.emittedValues.subscribe((values) => {
      this.formNameService
        .editFormLabelByFieldName(
          this.selectedCompany1.parentGuid,
          e,
          values,
          'VehicleDetails'
        )
        .subscribe(
          (data) => {
            this.formName = data;
            this.labels = data.labels;
          },
          (error) => {
            this.alertify.error(error);
          },
          () => {
            this.formNameService
              .getFormNameList(this.selectedCompany1.parentGuid)
              .subscribe(
                (data) => {
                  this.authService.changeFormLabels(data);
                },
                (error) => {
                  this.alertify.error(error);
                },
                () => {
                  this.alertify.success('Success!');
                  this.getLabels();
                }
              );
          }
        );
    });
  }
  addLabel(e) {
    this.formNameService
      .addFormLabel(this.selectedCompany1.parentGuid, e, this.formName.id)
      .subscribe(
        (data) => {
          this.formName = data;
          this.labels = data.labels;
        },
        (error) => {
          this.alertify.error(error);
        },
        () => {
          this.decipherLabels();
        }
      );
  }
  decipherLabels() {
  //  this.labels = this.formName.labels;
    if (this.labels.length > 0) {
      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Vin');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Vin');
      }
      this.labelVin = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'StockNumber'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('StockNumber');
      }
      this.labelStock = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Year');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Year');
      }
      this.labelYear = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Status');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Status');
      }
      this.labelStatus = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'County Code'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('County Code');
      }
      this.labelCountyCode = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Category'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Category');
      }
      this.labelCategory = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Title Number'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Title Number');
      }
      this.lableTitleNumber = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Year');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Year');
      }
      this.labelYear = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Previous Title Number'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Previous Title Number');
      }
      this.labelPreviousTitleNumber = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Make');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Make');
      }
      this.labelMake = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Previous State'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Previous State');
      }
      this.labelPreviousState = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Model');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Model');
      }
      this.labelModel = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Purchased As'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Purchased As');
      }
      this.labelPurchasedAs = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Ext Color 1'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Ext Color 1');
      }
      this.labelExertiorColor1 = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Purchased Price'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Purchased Price');
      }
      this.labelPurchasedPrice = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Style');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Style');
      }
      this.labelStyle = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Repairs:Depreciated'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Repairs:Depreciated');
      }
      this.labelRepairsDepreciated = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Displacement'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Displacement');
      }
      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Displacement'
      );
      this.labelDisplacement = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Total Cost'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Total Cost');
      }
      this.labelTotalCost = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Cylinders'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Cylinders');
      }
      this.labelCylinders = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Suggested Price'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Suggested Price');
      }
      this.labelSuggestedPrice = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Date Purchased'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Date Purchased');
      }
      this.labelDatePurchased = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Decal Number'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Decal Number');
      }
      this.labelDecalNumber = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Tag');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Tag');
      }
      this.labelTag = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Tag Expires'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Tag Expires');
      }
      this.labelTagExpires = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Mileage'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Mileage');
      }
      this.labelMileage = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter((v) => v.fieldName === 'Weight');
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Weight');
      }
      this.labelWeight = this.labelToDisplay[0].value;

      this.labelToDisplay = this.labels.filter(
        (v) => v.fieldName === 'Mileage Code'
      );
      if (
        this.labelToDisplay === undefined ||
        this.labelToDisplay.length === 0
      ) {
        this.addLabel('Mileage Code');
      }
      this.labelMileageCode = this.labelToDisplay[0].value;
    }
  }
  // ********************************************
  // *** DROP DOWN YEARS< MAKES < MILEAGE CODES < BODY TYPES
  // ********************************************
  getYears() {
    this.loading = true;
    this.vehicleService.getYearsPublic().subscribe(
      (data) => {
        this.vehicleYears = data;
        this.vehicleService.setYearsPublic(data);
        this.loading = false;
      },
      (error) => {
        this.alertify.error(error);
        this.loading = false;
      },
      () => {
        this.alertify.success('Years Retrieved');
      }
    );
  }
  getBodyTypes() {
    this.loading = true;
    this.vehicleService.getBodyTypesPublic().subscribe(data => {
      this.bodyTypes = data;
      this.loading = false;
    }, error => {this.alertify.error(error); this.loading = false; }, () => {this.alertify.success('BodyTypes Retrieved'); });
  }
  getMakes() {
    this.loading = true;
    this.vehicleService.getMakesPublic().subscribe(
      (data) => {
        this.vehicleMakes = data;
        this.vehicleService.setMakesPublic(data);
        this.loading = false;
      },
      (error) => {
        this.alertify.error(error);
        this.loading = false;
      },
      () => {
        this.alertify.success('Makes  Retrieved');
      }
    );
  }
  mileageCodeChanged(e) {
    // console.log(e);
    // this.chartItem.itemVehicleTransientDetails.mileageCode =e;
  //  this.chartItem.itemVehicleTransientDetails.vehicleMileageCodes = this.mileageCodes.find(f => f.value === e);
  //  this.chartItem.itemVehicleTransientDetails.vehicleMileageCodesId = this.chartItem.itemVehicleTransientDetails.vehicleMileageCodes.id;
  }
  getMileageCodes() {
    this.loading = true;
    this.vehicleService.getmileagecodesPublic().subscribe(data => {
      this.vehicleService.setMileageCodes(data);
      this.mileageCodes = data;
      this.loading = false;
    }, error => { this.alertify.error(error); this.loading = false;  }, () => { this.alertify.success('Mileage Codes Retrieved'); });
  }

  // ********************************************
  // *** DOLLAR MATH
  // ********************************************
  onChange(e) {
    if (this.chartItem.itemAmountSums.suggestedPrice === null || this.chartItem.itemAmountSums.suggestedPrice === undefined ) { this.chartItem.itemAmountSums.suggestedPrice = this.format.fnz02(0);
    } else {
      this.chartItem.itemAmountSums.suggestedPrice = this.format.fnz02(this.chartItem.itemAmountSums.suggestedPrice);
    }
    if (this.chartItem.itemAmountSums.purchasedPrice === null || this.chartItem.itemAmountSums.purchasedPrice === undefined ) { this.chartItem.itemAmountSums.purchasedPrice = this.format.fnz02(0);
    } else {
      this.chartItem.itemAmountSums.purchasedPrice = this.format.fnz02(this.chartItem.itemAmountSums.purchasedPrice);
    }
  }

  viewInventory() {
    this.router.navigate(['vehicledashboard/productlist/' + this.selectedCompany1.parentGuid]);
  }
  vinLength(e) {
    console.log(e);
    if (this.chartItem.vehicle.vin.length > 16) {
      this.decodeVinShow = true;
    } else { this.decodeVinShow = false; }
  }
  decodeVin() {
      // this.bsModalRef = this.modalService.show(AddVehicleVinModalComponent);
      const initialState = {
      };
      this.bsModalRef = this.modalService.show(AddVehicleVinModalComponent, {initialState});
      this.bsModalRef.content.updateVehicle.subscribe((values: any) => {
        const vehicle3 = {} as VehicleNewDto;
        console.log(values);
        vehicle3.mileage = values.vehicleDetails?.mileage;
        vehicle3.purchasedPrice = values?.vehicleAmounts?.purchasedPrice;
        vehicle3.stockNumber = values?.stockNumber;
        vehicle3.suggestedPrice = values?.vehicleAmounts?.suggestedPrice;
        vehicle3.vin = values?.vin;
        console.log(vehicle3);
          this.vehicleService.addVehicle(vehicle3).subscribe(data => {
            console.log(data);
          });
         });
     // this.router.navigate(['vehicledashboard/editvehicle/' +   this.selectedCompany1.parentGuid + '/269']);
  }
  validateDataFields() {

    if (!this.chartItem?.itemVehicleTransientDetails.tagExpires ) {
      this.chartItem.itemVehicleTransientDetails.tagExpires = new Date();
    }
    if (!this.chartItem.itemVehicleTransientDetails.datePurchased ) {
      this.chartItem.itemVehicleTransientDetails.datePurchased = new Date();
    }
    if (this.chartItem.itemAmountSums.suggestedPrice === null || this.chartItem.itemAmountSums.suggestedPrice === undefined ) { this.chartItem.itemAmountSums.suggestedPrice = this.format.fnz02(0);
    } else {
      this.chartItem.itemAmountSums.suggestedPrice = this.format.fnz02(this.chartItem.itemAmountSums.suggestedPrice);
    }
    if (this.chartItem.itemAmountSums.purchasedPrice === null || this.chartItem.itemAmountSums.purchasedPrice === undefined ) { this.chartItem.itemAmountSums.purchasedPrice = this.format.fnz02(0);
    } else {
      this.chartItem.itemAmountSums.purchasedPrice = this.format.fnz02(this.chartItem.itemAmountSums.purchasedPrice);
    }
  }
}
