import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/_models/user';
import { AccountService } from 'src/app/_services/account.service';

@Component({
  selector: 'app-newuser',
  templateUrl: './newuser.component.html',
  styleUrls: ['./newuser.component.css']
})
export class NewuserComponent implements OnInit {
  @ViewChild('editForm') editForm: NgForm;
  @Input() appUser: User;
  maxDate: Date;
  validationErrors: string[] = [];
  user = {} as  any;
  passwordResetGuid = {} as any;
  isGuidVerified = false;
  isStartVerfication: any;
  isStartVerificationTrue = false;
  isSubmitted = true;
  constructor(private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private toastr: ToastrService,
    private fb: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.passwordResetGuid = this.activatedRoute.snapshot.params.passwordResetGuid;
    this.verifyPasswordResetGuid();
  }
  verifyPasswordResetGuid() {
    console.log(this.passwordResetGuid);
    if (this.passwordResetGuid === 'emailcomfirm') {
      this.isStartVerificationTrue = !this.isStartVerificationTrue;
    } else {
      this.accountService.register(this.passwordResetGuid).subscribe(data => {
        this.isGuidVerified = true;
        console.log(data);
        this.user = data;
      }, errr => {
        this.toastr.error(errr);
        }, () => {
          this.toastr.success('GUID VERIFIED');
          this.router.navigate(['/aftercompanyreload']);
        });
    }
    console.log(this.isStartVerificationTrue);
  }
}
