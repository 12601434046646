import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PaymentType } from 'src/app/_models/PaymentType';
import { Sale } from 'src/app/_models/sale';

@Component({
  selector: 'app-editcashdowntype-modal',
  templateUrl: './editcashdowntype-modal.component.html',
  styleUrls: ['./editcashdowntype-modal.component.scss']
})
export class EditcashdowntypeModalComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  sale: Sale;
  paymentType: PaymentType;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  updateGap() {
    /// Create Paymenbt Type here then insert  to array on return
    // and insert to sales.cashcownmethod before returning it...
    console.log(this.sale);
    this.emittedValues.emit(this.sale);
    this.bsModalRef.hide();
  }
  onChange(e) {}
}
