<div class="form-sec tab-content col-md-12">
  <div class="card" style="background-color: #DBE9F4 !important">
    <div class="col-sm-12">
      <div *ngIf="editForm.dirty" class="alert alert-info">
        <strong>Information:</strong> You have made changes. Any unsaved changes
        will be lost!
      </div>
    </div>
    <form #editForm="ngForm" id="editForm">
    <!-- Typeof Repair-->  
 <div class="col-md-12 cstm-col">
   <div  class="form-group form-inline">
  <label>Suggested Price</label>
  <select class="cstm-select" #realPropertyRepairTypesId="ngModel" ngControl="selectedCategory"  name="realPropertyRepairTypesId"    [(ngModel)] ="repair.realPropertyRepairTypesId"  
  (ngModelChange)="selected($event)" >
  <option style="color: #bb400f;" [value]="-1">None</option>
    <option *ngFor="let item of repairTypes; index as i"   [value]="item.id">{{item?.value}}</option>
  </select>
</div>
</div>
 <!-- Purchased From-->  
<div class="col-md-12">
  <div
    class="form-group form-inline"
    [ngClass]="{
      'has-success':
      purchasedFrom?.valid &&
        (purchasedFrom?.touched ||
        purchasedFrom?.dirty),
      'has-danger':
      purchasedFrom?.invalid &&
        (purchasedFrom?.touched || purchasedFrom?.dirty)
    }"
  >
    <label class="label">Purchased From:</label>
    <div class="input-group">
      <input
        style="width: 70%"
        type="text"
        class="form-control"
        [ngModel]="repair?.purchasedFrom"
        (ngModelChange)="repair.purchasedFrom = $event"
        name="purchasedFrom"
        #purchasedFrom="ngModel"
        required
      />
      <div
        *ngIf="
          !purchasedFrom.errors &&
          (purchasedFrom?.touched ||
          purchasedFrom?.dirty)
        "
        class="input-group-prepend"
      >
        <button
          type="button"
          class="btn btn-outline-success"
        >
          <i
            class="fa fa-check"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
    <div
      class="form-group-feedback"
      *ngIf="
      purchasedFrom.errors &&
        (purchasedFrom?.touched || purchasedFrom?.dirty)
      "
    >
      <p
        style="color: red"
        *ngIf="purchasedFrom?.errors.required"
      >
      Purchased From  Required
      </p>
    </div>
  </div>
</div>

 <!-- Purchased From-->  
 <div class="col-md-12">
  <div
    class="form-group form-inline"
    [ngClass]="{
      'has-success':
      description?.valid &&
        (description?.touched ||
        description?.dirty),
      'has-danger':
      description?.invalid &&
        (description?.touched || description?.dirty)
    }"
  >
    <label class="label">Description:</label>
    <div class="input-group">
      <input
        style="width: 70%"
        type="text"
        class="form-control"
        [ngModel]="repair?.description"
        (ngModelChange)="repair.description = $event"
        name="description"
        #description="ngModel"
        required
      />
      <div
        *ngIf="
          !description.errors &&
          (description?.touched ||
          description?.dirty)
        "
        class="input-group-prepend"
      >
        <button
          type="button"
          class="btn btn-outline-success"
        >
          <i
            class="fa fa-check"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
    <div
      class="form-group-feedback"
      *ngIf="
      description.errors &&
        (description?.touched || description?.dirty)
      "
    >
      <p
        style="color: red"
        *ngIf="description?.errors.required"
      >
      description   Required
      </p>
    </div>
  </div>
</div>

 <!-- URL From-->  
 <div class="col-md-12">
  <div
    class="form-group form-inline"
    [ngClass]="{
      'has-success':
      url?.valid &&
        (url?.touched ||
        url?.dirty),
      'has-danger':
      url?.invalid &&
        (url?.touched || url?.dirty)
    }"
  >
    <label class="label">URL:</label>
    <div class="input-group">
      <input
        style="width: 70%"
        type="text"
        class="form-control"
        [ngModel]="repair?.url"
        (ngModelChange)="repair.url = $event"
        name="url"
        #url="ngModel"
        required
      />
      <div
        *ngIf="
          !url.errors &&
          (url?.touched ||
          url?.dirty)
        "
        class="input-group-prepend"
      >
        <button
          type="button"
          class="btn btn-outline-success"
        >
          <i
            class="fa fa-check"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>

  </div>
</div>
 <!-- SKU -->  
 <div class="col-md-12">
  <div
    class="form-group form-inline"
    [ngClass]="{
      'has-success':
      sku?.valid &&
        (sku?.touched ||
        sku?.dirty),
      'has-danger':
      sku?.invalid &&
        (sku?.touched || sku?.dirty)
    }"
  >
    <label class="label">SKU:</label>
    <div class="input-group">
      <input
        style="width: 70%"
        type="text"
        class="form-control"
        [ngModel]="repair?.sku"
        (ngModelChange)="repair.sku = $event"
        name="sku"
        #sku="ngModel"
        required
      />
      <div
        *ngIf="
          !sku.errors &&
          (sku?.touched ||
          sku?.dirty)
        "
        class="input-group-prepend"
      >
        <button
          type="button"
          class="btn btn-outline-success"
        >
          <i
            class="fa fa-check"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
    <div
      class="form-group-feedback"
      *ngIf="
      sku.errors &&
        (sku?.touched || sku?.dirty)
      "
    >
      <p
        style="color: red"
        *ngIf="sku?.errors.required"
      >
      SKU  Required
      </p>
    </div>
  </div>
</div>


<!-- COUNT -->  
<div class="col-md-12">
  <div
    class="form-group form-inline"
    [ngClass]="{
      'has-success':
      count?.valid &&
        (count?.touched ||
        count?.dirty),
      'has-danger':
      count?.invalid &&
        (count?.touched || count?.dirty)
    }"
  >
    <label class="label">count:</label>
    <div class="input-group">
      <input
        style="width: 70%"
        type="number"
        class="form-control"
        [ngModel]="repair?.count"
        (ngModelChange)="repair.count = $event"
        (blur) = "blur()"
        name="count"
        #count="ngModel"
        required
      />
      <div
        *ngIf="
          !count.errors &&
          (count?.touched ||
          count?.dirty)
        "
        class="input-group-prepend"
      >
        <button
          type="button"
          class="btn btn-outline-success"
        >
          <i
            class="fa fa-check"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
    <div
      class="form-group-feedback"
      *ngIf="
      count.errors &&
        (count?.touched || count?.dirty)
      "
    >
      <p
        style="color: red"
        *ngIf="count?.errors.required"
      >
      COunt  Required
      </p>
    </div>
  </div>
</div>


<!-- Item Amount -->  
<div class="col-md-12">
  <div
    class="form-group form-inline"
    [ngClass]="{
      'has-success':
      itemAmount?.valid &&
        (itemAmount?.touched ||
        itemAmount?.dirty),
      'has-danger':
      itemAmount?.invalid &&
        (itemAmount?.touched || itemAmount?.dirty)
    }"
  >
    <label class="label">Item Amount:</label>
    <div class="input-group">
      <input
        style="width: 70%"
        type="number"
        class="form-control"
        [ngModel]="repair?.itemAmount"
        (ngModelChange)="repair.itemAmount = $event"
        (blur) = "blur()"
        name="itemAmount"
        #itemAmount="ngModel"
        required
      />
      <div
        *ngIf="
          !itemAmount.errors &&
          (itemAmount?.touched ||
          itemAmount?.dirty)
        "
        class="input-group-prepend"
      >
        <button
          type="button"
          class="btn btn-outline-success"
        >
          <i
            class="fa fa-check"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
    <div
      class="form-group-feedback"
      *ngIf="
      itemAmount.errors &&
        (itemAmount?.touched || itemAmount?.dirty)
      "
    >
      <p
        style="color: red"
        *ngIf="itemAmount?.errors.required"
      >
      Item Amount  Required
      </p>
    </div>
  </div>
</div>


<!-- Total Amount -->  
<div class="col-md-12">
  <div
    class="form-group form-inline"
    [ngClass]="{
      'has-success':
      totalAmount?.valid &&
        (totalAmount?.touched ||
        totalAmount?.dirty),
      'has-danger':
      totalAmount?.invalid &&
        (totalAmount?.touched || totalAmount?.dirty)
    }"
  >
    <label class="label">Total Amount:</label>
    <div class="input-group">
      <input
        style="width: 70%"
        type="number"
        class="form-control"
        [ngModel]="repair?.totalAmount"
        (ngModelChange)="repair.totalAmount = $event"
        (blur) = "blur()"
        name="totalAmount"
        #totalAmount="ngModel"
        required
      />
      <div
        *ngIf="
          !totalAmount.errors &&
          (totalAmount?.touched ||
          totalAmount?.dirty)
        "
        class="input-group-prepend"
      >
        <button
          type="button"
          class="btn btn-outline-success"
        >
          <i
            class="fa fa-check"
            aria-hidden="true"
          ></i>
        </button>
      </div>
    </div>
    <div
      class="form-group-feedback"
      *ngIf="
      totalAmount.errors &&
        (totalAmount?.touched || totalAmount?.dirty)
      "
    >
      <p
        style="color: red"
        *ngIf="totalAmount?.errors.required"
      >
      TOtal Amount  Required
      </p>
    </div>
  </div>
</div>
    </form>
    <button type="submit" (click)="onSubmit()" class="cstm-btn img-blk-btn">
      <img src="assets/images/ap-icon-1.png" />
      <span>Save</span>
    </button>
  </div>
</div>
