
<table class="table table-hover" style="cursor: pointer;">
  <thead class="thead-light">
  <tr>
    <th>ID</th>
    <th>Name</th>
    <th>System 1</th>
    <th>System 2</th>
    <th>System 3</th>
    <th>Duration 1</th>                        
    <th>Duration 2</th>
    <th>Duration 3</th>
    <th>Duration 4</th>
    
  </tr>
  </thead>
  <tbody>
    <tr class="td-row" *ngFor="let data of vehicleBuyersGuideSystemsCovered">
      <td>{{data.id}}</td>
      <td>{{data.name}}</td>
      <td>{{data.systemCovered1}}</td>
      <td>{{data.systemCovered2}}</td>
      <td>{{data.systemCovered3}}</td>
      <td>{{data.durationCovered1}}</td>
      <td>{{data.durationCovered2}}</td>
      <td>{{data.durationCovered3}}</td>
      <td>{{data.durationCovered4}}</td>
      <td >
          <a class="btn btn-info" (click)="edit(data)" data-toggle="tooltip" data-placement="top" title="View Website Detail"><i class="fa fa-fw fa-edit"></i></a>
        </td>
        <td >
          <a class="btn btn-danger" (click)="deleteSystem(data)" data-toggle="tooltip" data-placement="top" title="View Website Detail"><i class="fa fa-fw fa-edit"></i></a>
        </td>
    </tr>
  </tbody>
</table>

