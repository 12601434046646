<header>
  <span *ngIf="this.totalCount && this.totalCount > 0">Showing <strong>
    {{(this.pageNumber -1) * pageSize + 1}} - 
    {{this.pageNumber*this.pageSize
    > this.totalCount
    ? this.totalCount
    : this.pageNumber*this.pageSize}}
  
  </strong> of <strong>{{this.totalCount}}</strong> results</span>
  <span  *ngIf="totalCount ===0">
    There are <strong>0</strong> results for this filter.
  </span>
</header>
