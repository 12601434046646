import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-pdf-newfieldname',
  templateUrl: './pdf-newfieldname.component.html',
  styleUrls: ['./pdf-newfieldname.component.css']
})
export class PdfNewfieldnameComponent implements OnInit {
  @Input() emittedValues = new EventEmitter();
  constructor(public bsModalRef: BsModalRef) { }
  modalPdfFIeldName = {} as ModalPdfFIeldName;
  ngOnInit() {
  }
  updateGap() {

    console.log(this.modalPdfFIeldName);
    this.emittedValues.emit(this.modalPdfFIeldName?.name);
    this.bsModalRef.hide();
  }
  onChange(e) {}
}
export interface ModalPdfFIeldName {
  name: string;
}
