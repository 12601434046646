import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
 import { PDFAcroField, PDFDocument, PDFPage, PDFTextField, rgb, TextAlignment } from 'pdf-lib';
import { PdfSetEmbedPdfFieldLocationsResultDto } from 'src/app/_models/DTOS/pdfdtos/PdfSetEmbedPdfFieldLocationsResultDto';
// import { PDFDocument, PDFForm, PDFPage, PDFTextField, rgb, TextAlignment } from 'src/app/_modules/pdf-lib';
import { PdfForCreationCustom } from 'src/app/_models/DTOS/PdfForCreationCustom';
import { PdfFormCalculationNoIdDto } from 'src/app/_models/DTOS/PdfFormCalculationNoIdDto';
import { Field } from 'src/app/_models/Field';
import { PdfFieldServer } from 'src/app/_models/PdfFieldServer';
import { PdfForms } from 'src/app/_models/PdfForms';
import { PdfFormTypes } from 'src/app/_models/PdfFormTypes';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { ConvertPdfToArraysAndBuffers } from './convertpdf-toarrays';
@Injectable({ providedIn: 'root' })
export class  PdfFieldLocationClass {
  constructor(private pdfService: PdfService,
    private convertPdfToArraysAndBuffers: ConvertPdfToArraysAndBuffers,
    private alertify: ToastrService) {}
  arrayBuffer: any;
  pdf: any;
  pdfForm: PdfForms;
  pdfFormTypes = [] as PdfFormTypes[];
  pdfToUpload: PdfForCreationCustom;
  indoc: any;
  pdfSaleFields = [] as PdfSaleFields[];
  pdfSaleField = {} as PdfSaleFields;
  blob: Blob;
  file: File;
  field: Field;

  // this gets all locations of pdf fields on a pdf.
  // EXAMPLE BELOW of Draggable FIelds
  // async getServerPdfFields(formUrl) {
  //   this.fields = [];
  //   this.draggables = [];
  //   this.serverPdfFields = [];
  //   this.pdfFieldLocationClass.getFieldLocations(formUrl)
  //     .then(
  //        data => {
  //          this.serverPdfFields = data;
  //           console.log(data);
  //         if (data.length > 0) {
  //           data.forEach((element) => {
  //             this.onTextFieldAdded(element);
  //           });
  //         }
  //       },
  //        err => { console.log(err); }
  //   );
  // }
  /**
   * ON FIRST RUN save field.GetText() result to pdfForm.PdfFormCalculation[] db table array!!!
   * on future runs this form can not calculate the math of those fields from that table searching for
   * cst as first three digits then getting math field by full pdfFieldName....
   * that calculation is done in _classes.customData.ts
   *
   * this function retrieves pdf from serve
   * then gets fields and translates them to html cordinates
   * it includes height, width, alignment of fields and more.
   * anything y ou wish to view on html must be retrieved in this sequence.
   * @param formUrl pdf url...
   */
  async getFieldLocations(formUrl: any): Promise<PdfFieldServer[]> {
    const LogPdfFields = [] as any[];
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const form = pdfDoc.getForm();
    const fields = form.getFields();
    console.log(fields);
    // designate field variable outside of loop
    let fieldToSet = {} as PDFTextField;
    // serverPdfFIeld DTO
    let serverPdfField = {} as PdfFieldServer;
    // array of
    const serverPdfFields = [] as PdfFieldServer[];
    // loop fields
    let heightGiven = 22;
    fields.forEach(field => {
        const type = field.constructor.name;
        const name = field.getName();
        const nameFirstThree = name.substring(0, 3);
        let fieldText;
     //   LogPdfFields.push( name.toString());  not sure what this does yet
     // PDFTextField
     //   if (type === 'PDFTextField') 
     if (field instanceof PDFTextField) {
      // Do text field stuff
    }
    if (field instanceof PDFTextField) {
            fieldToSet = form.getTextField(name);
            if (nameFirstThree === 'cst') {
              /// here save to data base on first run??
              fieldText = fieldToSet.getText();
              if (fieldText !== '') {
                // here update data table of pdfform with new calculated field.
              }
            }
            const quading = fieldToSet.getAlignment();
            const widgets = fieldToSet.acroField.getWidgets();
            widgets.forEach((w) => {
              serverPdfField = {};
              const rect = w.getRectangle();
              if (rect.height > 0 ) { heightGiven = rect.height; }
              serverPdfField.align = quading;
              serverPdfField.height = heightGiven;
              serverPdfField.width = rect.width;
              serverPdfField.x = rect.x / 0.75;
              if (rect.y === 0) {
                serverPdfField.y = 1056 - 22;
              } else {
                serverPdfField.y = (rect.y / 0.75 - (1056 -  5 -  heightGiven)) * -1;
              }
              serverPdfField.name = name;
            });
            serverPdfFields.push(serverPdfField);
          //  console.log(serverPdfFields);
        }
    });
    this.pdfService.setPdfFieldServer(serverPdfFields);
    return serverPdfFields;
  }
  async removePdfFieldsFromPdf(formUrl: any, pdfForm: PdfForms): Promise<Uint8Array>{
    const pdfBytes =  await fetch(formUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(pdfBytes);
    console.log('did it reach here');
    /**
     * get form then feilds
     * remove all fields
     */
    const pdfFormFromPdfLib = pdfDoc.getForm();
    const pdfFields = pdfFormFromPdfLib.getFields();
    const form = pdfDoc.getForm();
    console.log('did  i reach here?');
    console.log(pdfFields);
    pdfFields.forEach((element1) => {
      const textFieldName = element1.getName();
      const tFIeld = form.getField(textFieldName);
      console.log(tFIeld);
     // pdfFormFromPdfLib.removeField(tFIeld);
    });
   /**
    * Save Changes
    */
    const u8 = await pdfDoc.save();
    const n64 = await pdfDoc.saveAsBase64();
   // make a blob
     this.convertPdfToArraysAndBuffers.createBlob(u8, 'application/pdf').subscribe(data => {
      this.blob = data;
    });
    // convert blob to file that matches IFile of .net core model
    this.convertPdfToArraysAndBuffers.blobToFile(this.blob, 'tempname').subscribe(data => {
      this.file = data;
    }, error => {
      this.alertify.error(error);
    });
    this.pdfToUpload = {file: this.file};
    this.pdfService.uploadPdfDeleteFields(this.pdfToUpload, pdfForm?.id).subscribe(data => {
      formUrl = data?.pdfForm?.pdfFormUrl;
      this.pdfForm = data?.pdfForm;
      this.updateGlobals(formUrl, data.pdfForm);
    }, error => {
      this.alertify.error(error);
    }, () => {   });
    return await pdfDoc.save();
  }
 async updateGlobals(formUrl, pdfForm?: PdfForms) {
    const  pdfBytes =  await fetch(formUrl).then(res => res.arrayBuffer());
    const  pdfDoc = await PDFDocument.load(pdfBytes);
    const u8 = await pdfDoc.save();
    const n64 = await pdfDoc.saveAsBase64();
    this.pdfService.changePdfForm(pdfForm);
   this.pdfService.changeUint8ByteArray(u8);
   this.pdfService.changeBase64Array(n64);
  }
  async setTextAlignmentEnum(textAlignement: any): Promise<any>{
    switch (textAlignement) {
      case 0:
        return 0;
      case 1:
        return 1;
      case 2:
        return 2;
      default:
        return 0;
    }
  }
  /**
   * remove all data fields on pdf. Re Add All fields to pdf from pdfSaleFields structure
   * @param pdfSaleFields array of FIELD internal pdffield structure built on editors
   * @param pdfFormRecieved the internal form from api not the library PDFForm
   * ALSO:
   * this as well uploads the form to api server. It is tightly bound to this api post
   * when posting creates new urls, new file on server
   * on updating server it updates locals in pdfService
   * so can call new forms using
   *  this.pdfService.pdfForm.subscribe(data1 => { this.pdfForm = data1; });
   */
  async setEmbedPdfFieldLocations(pdfSaleFields: Field[], pdfFormRecieved: PdfForms): Promise<Uint8Array>  {
    console.log(pdfSaleFields);
    const pdfBytes =  await fetch(pdfFormRecieved.pdfFormUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(pdfBytes);

    const pdfForm =  pdfDoc.getForm();
    const page = await pdfDoc.getPage(0);
    const pdfFields = await pdfForm.getFields();
    let fieldText;
    const pdfFormCalculationNoIdDtoArray = [] as PdfFormCalculationNoIdDto[];
    /**
     * remove all feilds first
     */
     pdfForm.deleteXFA();
    pdfFields.forEach((pdfField) => {
      const type = pdfField.constructor.name;

      const textFieldName = pdfField.getName();
      const nameFirstThree = textFieldName.substring(0, 3); // 'cst'
      /**
       * test to fix problem of N type field
       */
       const widget0 = pdfField.acroField.getWidgets()[0];
      const AP = widget0.ensureAP();
      //  if (type === 'PDFTextField')
     // if (field instanceof PDFTextField)
     if (pdfField instanceof PDFTextField){
          const tFIeld = pdfForm.getTextField(textFieldName);


          if (nameFirstThree === 'cst') {
            /// here save to data base on first future runs??
            // must be an array of PdfFormCalculationNoIdDto[].
            // at same time must create fields in pdfServerFields[] on save of pdf?
            fieldText = tFIeld.getText();
            if (fieldText !== '') {
              const pdfFormCalculationNoIdDto = new PdfFormCalculationNoIdDto();
              pdfFormCalculationNoIdDto.caclulationFieldName = textFieldName;
              pdfFormCalculationNoIdDto.calculation = fieldText;
              pdfFormCalculationNoIdDto.pdfFormId = pdfFormRecieved?.id;
              pdfFormCalculationNoIdDtoArray.push(pdfFormCalculationNoIdDto);
              console.log(pdfFormCalculationNoIdDtoArray);
              // After upload of PDF this is uploaded as round trip with new pdfFormId;
            }
          }

          pdfForm.removeField(tFIeld);
          const widgets = tFIeld.acroField.getWidgets();
          widgets.forEach((widgetReference) => {
          const widgetRef = pdfDoc.context.getObjectRef(widgetReference.dict);
          page.node.removeAnnot(widgetRef);
        });
      }
    });
     let u8 = await pdfDoc.save();
    /**
     * this is built based on always edting single page so always page 0
     */

   // let alignmentCase = 0; sets
    let widthGiven = 50;
    let heightGiven = 15;
    console.log(pdfSaleFields);
    pdfSaleFields.forEach(field => {
      // ADOBE Acrofield class
      if (field.width > 0) { widthGiven = field.width; }
      if (field.height > 0) { heightGiven = field.height; }
        const acrofield = pdfForm.createTextField(field.name);
       if (field.align === 0) {acrofield.setAlignment(TextAlignment.Left); }
       if (field.align === 1) {acrofield.setAlignment(TextAlignment.Center); }
       if (field.align === 2) {acrofield.setAlignment(TextAlignment.Right); }
         acrofield.addToPage(page, {
          x:  field.position.x * 0.75,
          y:  (field.position.y - 1056 + 5 + heightGiven) * 0.75 * -1,
          width: widthGiven,
          height: heightGiven,
          textColor: rgb(0, 0, 0),
          borderColor: undefined,
          backgroundColor: undefined,
          borderWidth: 0,
        });
       if (field.length > 0 ) {acrofield.setMaxLength(field.length); }
    //   console.log(acrofield);

    // have to create calculations here?? how???/ WTF
    });
    /**
     * start the upload. Save, then create blob;
     */
     u8 = await pdfDoc.save();
    this.convertPdfToArraysAndBuffers.createBlob(u8, 'application/pdf').subscribe(data => {
      this.blob = data;
    });
    /**
     * as states in name blob to IFile for upload
     */
    this.convertPdfToArraysAndBuffers.blobToFile(this.blob, 'tempname').subscribe(data => {
      this.file = data;
    }, error => {
      this.alertify.error(error);
    });
    /**
     * now send pdf file to save on serve and id of original pdf to delete
     * TODO must edit api sense adding form.removeField();
     */
    this.pdfToUpload = {file: this.file};
    this.pdfService.updatePdfFIelds(this.pdfToUpload, pdfFormRecieved?.id).subscribe((data: PdfSetEmbedPdfFieldLocationsResultDto) => {
      this.pdfForm = data?.pdfForm;
      this.pdfService.changePdfForm(data?.pdfForm);
      this.pdfService.pdfFormTypesCached = data?.pdfFormTypes;
      console.log(data?.pdfForm?.id);
      // here add round trip to server uploading pdfForm.PdfFOrmCalculations array.
      // here return ANY including list to repop tree, pdfform list and pdf form.
      // const pdfFormCalculationNoIdDto = new PdfFormCalculationNoIdDto();
      // pdfFormCalculationNoIdDto.caclulationFieldName = '';
      // pdfFormCalculationNoIdDto.calculation = fieldText;
      // pdfFormCalculationNoIdDto.pdfFormId = data?.pdfForm.id;
     // this.pdfService.setPdfFormCalculation(pdfFormCalculationNoIdDtoArray, data?.pdfForm?.id).subscribe(data1 => {

       // this.pdfFormTypes = data1.pdfFormTypes;
       // this.pdfService.pdfFormTypesCached = data1.pdfFormTypes;
         // having issues here i think. Do not think globals are updating the dom
         // trying return any with form list of types to refresh tree...
        this.updateGlobals(data?.pdfForm?.pdfFormUrl, data.pdfForm);
     // });
    }, error => {
      this.alertify.error(error);
    }, () => {   });
    /**
     * update globals and save
     */
     u8 = await pdfDoc.save();
     const n64 = await pdfDoc.saveAsBase64();
     this.pdfService.changeUint8ByteArray(u8);
     this.pdfService.changeBase64Array(n64);
    return await u8;
  }









  // async splitPdfFiles(formUrl: any): Promise<PdfFieldServer[]> {
  //   const LogPdfFields = [] as any[];
  //   const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
  //   const pdfDoc = await PDFDocument.load(formPdfBytes);
  // }


// getPAGE COUNT:
// example:
//  this.pdfFieldLocationClass.getPageCount(pdfData).then(data => { console.log(data); });
  async getPageCount(formUrl: any): Promise<number>{
    const LogPdfFields = [] as any[];
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const pageCount = pdfDoc.getPageCount();
    return pageCount;
  }



 async  base64ToArrayBufferToUint8(base64): Promise<any>{
 // const binaryString = atob(base64);
 const buffer = Buffer.from(base64);
  const len = base64.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
      bytes[i] = base64.charCodeAt(i);
  }
  return bytes.buffer;
}
//   const buffer = Buffer.from(base64);

// const base64String = buffer.toString('base64');
//   return base64String;



// create New Page from bytes sent
// ALWAYS SEND INDEX OF PAGE to create page out of the donor as new document
// so document has 5 pages... loop 5 times to create 5 documents.
async createPdfFromBytes(firstDonorPdfBytes: any, pageIndexOfDocument: number): Promise<Uint8Array> {

// Create a new PDFDocument
const pdfDoc = await PDFDocument.create();

// These should be Uint8Arrays or ArrayBuffers
// This data can be obtained in a number of different ways
// If your running in a Node environment, you could use fs.readFile()
// In the browser, you could make a fetch() call and use res.arrayBuffer()
// firstDonorPdfBytes = ...

// Load a PDFDocument
const firstDonorPdfDoc = await PDFDocument.load(firstDonorPdfBytes);

// Copy page to the new pdf document
const [firstDonorPage] = await pdfDoc.copyPages(firstDonorPdfDoc, [pageIndexOfDocument]);

// Add the first copied page
pdfDoc.addPage(firstDonorPage);

// Serialize the PDFDocument to bytes (a Uint8Array)
const pdfBytes = await pdfDoc.save();
return pdfBytes;
}


  async getPage(formUrl: any, pageNumber: number): Promise<PDFPage>{
    const LogPdfFields = [] as any[];
    const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(formPdfBytes);
    const page = pdfDoc.getPage(pageNumber);
    return page;
  }





  readFile(file){
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
    reader.readAsArrayBuffer(file);
  });
}










// Noticed this code from founder for insert
// doc.insertPage(
//   Math.min(i * 2 + 1, doc.getPageCount()),
//   lastPage,
// );
// p = new pdflib();

// /* Open the input PDF */
// indoc = p.open_pdi_document("myTestFile.pdf", "");
// pageCount = (int) p.pcos_get_number(indoc, "length:pages");
    async  getNumPages(file) {
      this.arrayBuffer = await this.readFile(file);

      this.pdf = await PDFDocument.load(this.arrayBuffer);

      return this.pdf.getPages();
    }
  }
