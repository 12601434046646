import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddRepairDto } from 'src/app/_models/DTOS/addrepairdto';
import { PersonLinqResultToReturnDto } from 'src/app/_models/DTOS/PersonLinqResultToReturnDto';
import { QbInvoiceDefault } from 'src/app/_models/DTOS/quickbooksdtos/QbInvoiceDefaultDto';
import { RealPropertyLinqListDto } from 'src/app/_models/DTOS/realpropertylinqlistdto';
import { RealPropertyRepairLinqDto } from 'src/app/_models/DTOS/RealPropertyRepairLinqDto';
import { PaginatedResult } from 'src/app/_models/pagination';
import { Person } from 'src/app/_models/person';
import { RealProperty } from 'src/app/_models/RealProperty';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class QuickBooksService {
    constructor(private http: HttpClient) { }
    baseUrl = environment.apiUrl;
    realmId = new BehaviorSubject<string>('');
    currentrealmId = this.realmId.asObservable();

    redirectRoute = new BehaviorSubject<string>('');
    currentredirectRoute = this.redirectRoute.asObservable();

  /*
   * set the redirect url as case switch for login connect restult
   * one page gets landing it uses this switch to send back to correct url
   * @params lands on component quickbooks-connected
   */
    setRedirectRoute(id) { this.redirectRoute.next(id); }
    getRedirectRoute() { return this.currentredirectRoute; }

    setRealmId(id) { this.realmId.next(id); }
    getRealmId() { return this.currentrealmId; }
    login(parentGuid: string): Observable<any> {
       return this.http.get<any>(this.baseUrl + 'quickbooks/login/' + parentGuid);
    }
    createCustomer(parentGuid: string, realmid: string): Observable<any> {
      return this.http.get<any>(this.baseUrl +
      'quickbookscustomer/createcustomer/' + parentGuid + '/' + realmid);
   }

    connect(code, realmId, parentGuid: string): Observable<any> {
        return this.http.get<any>(this.baseUrl + 'quickbooks/getrealmid/'
      + code + '/' + realmId + '/' + parentGuid);
     }

     createDefaultAccount(parentGuid: string, realmId): Observable<QbInvoiceDefault[]> {
      return this.http.get<QbInvoiceDefault[]>(this.baseUrl +
        'quickbooks/createcostofsales/' + realmId + '/' + parentGuid);
   }

   createDefaultItems(parentGuid: string, realmId): Observable<QbInvoiceDefault[]> {
    return this.http.get<QbInvoiceDefault[]>(this.baseUrl +
      'quickBooksdefaultitems/createserviceitems/' + realmId + '/' + parentGuid);
 }
  }
