import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CompanyProfile } from 'src/app/_models/companyProfile';

@Component({
  selector: 'app-edit-companyprofilepopup',
  templateUrl: './edit-companyprofilepopup.component.html',
  styleUrls: ['./edit-companyprofilepopup.component.css']
})
export class EditCompanyprofilepopupComponent implements OnInit {
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() company = {} as CompanyProfile;
  @Output() update = new EventEmitter();
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
 saveChanges() {
  console.log(this.company);
  this.update.emit(this.company);
 }
 cancel() {
   this.bsModalRef.hide();
 }
}
