import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Pagination, PaginatedResult } from 'src/app/_models/pagination';
import { ChartInvoiceTransactionGridTOReturnDto } from 'src/app/_models/DTOS/ChartInvoiceTransactionGridTOReturnDto';
import { ExcelService } from 'src/app/_services/exportservices/excel.service';
import { ToastrService } from 'ngx-toastr';
import { SharedMonthDateFilterService } from 'src/app/shared/date-filter/date-filter.component';

import { SaleService } from 'src/app/_services/saleservices/sale.service';
import { SaleStatus } from 'src/app/_models/salestatus';
import { SaleType } from 'src/app/_models/saletype';


@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.css']
})
export class AdvancedComponent implements OnInit {
  selectedCompany1: UserAssignedToCompany;
  sales: ChartInvoiceTransactionGridTOReturnDto[];
  pagination: Pagination;
  isDateAscending = true;
  isCustomerAscending = true;
  isIdAscending = true;
  isCountyAscending = true;
  isLoanAmountAscending = true;
  isSaleTypeAscending = true;
  isSaleSTatusAscending = true;
  isVehicleAscending = true;
  isInvoiceAscending = true;
  isAscending = true;
  userParams = {} as any;
  showDates = false;
  selectType;
  selectStatus;
  isAllSales = false;
  isSalesTax = false;
  isSalesJournal = false;
  isVehicleSoldList = false;

  isAmountTaxed1 = false;
  isTaxRate1 = false;
  isTax1 = false;
  isAmountTaxed2 = false;
  isTaxRate2 = false;
  isTax2 = false;
  isAmountTaxed3 = false;
  isTaxRate3 = false;
  isTax3 = false;
  isAmountTaxed4 = false;
  isTaxRate4 = false;
  isTax4 = false;
  isTax = false;
  isPrint = false;
  chartInvoiceStatuses: SaleStatus[];
  chartInvoiceSaleTypes: SaleType[];
  constructor(private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private alertify: ToastrService,
    private chartInvoiceService: SaleService,
    private saleDateService: SharedMonthDateFilterService) { }

  ngOnInit() {
    this.isSalesTax = true;
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      this.sales = data.sales.result;
      this.pagination = data.sales.pagination;

      this.chartInvoiceStatuses = data.chartInvoiceStatuses;
      this.chartInvoiceSaleTypes = data.chartInvoiceSaleTypes;
    });

  }
  updateDates(event) {
    this.userParams.startDate = event.startDate;
    this.userParams.endDate = event.endDate;
    this.userParams.monthOfYear = event.month;
    this.userParams.yearForDate = event.year;
    this.userParams.isAll = event.isAll;
    this.userParams.isFullYear = event.isFullYear;
  this.loadInvoices();
  }

  selectedTypeChanged(e) {
    this.userParams.saleTypeId = '';
    console.log(this.selectType);
    if (this.selectType === 0) { this.userParams.saleTypeId = undefined; }
    if (this.selectType === null) { this.userParams.saleTypeId = undefined; }
    if (e > 0) {this.userParams.saleTypeId = e; }

    this.loadInvoices();
  }
  selectedStatusChanged(e) {
    this.userParams.saleStatusId = '';
    console.log(this.selectStatus);
    if (this.selectStatus === 0) { this.userParams.saleStatusId = undefined; }
    if (this.selectStatus === null) { this.userParams.saleStatusId = undefined; }
    if (e > 0) {this.userParams.saleStatusId = e; }

    this.loadInvoices();
  }
navigateInvoice(e) {
  this.router.navigate(['saledashboard/editsale/' + this.selectedCompany1.parentGuid + '/' +  e.id]);
}
setSearchBy(e, event) {
  setTimeout(() => {
    console.log(event);
    console.log(e);
    this.userParams.searchBy = e;
    this.userParams.searchFor = event;
    this.loadInvoices();
  }, 2000);
}
showDatesTrue() {
  this.showDates = !this.showDates;
}
loadInvoices() {
  this.chartInvoiceService.getSaleInvoice2(this.selectedCompany1.parentGuid, this.pagination.currentPage,
         this.pagination.itemsPerPage, this.userParams).subscribe((data: PaginatedResult<any[]>) => {
         this.sales = data.result;
         console.log(data.result);
         this.pagination = data.pagination;
      }, error => { this.alertify.error(error); }, () => { this.alertify.success('Your Sales have Loaded.'); } );
  }

sortDescription() {
}

pageChanged(event: any): void {
  this.pagination.currentPage = event.page;
  this.loadInvoices();
}

filter(e) {
  this.isAllSales = false;
  this.isSalesTax = false;
  this.isSalesJournal = false;
  this.isVehicleSoldList = false;
  this.userParams.filter = e;
  if (e === 'isAllSales') {
    this.router.navigate(['/saledashboard/saledashboard/' + this.selectedCompany1.parentGuid]);
    this.isAllSales = true;
  }
  if (e === 'isSalesTax') { this.isSalesTax = true;  this.router.navigate(['/saledashboard/advancetax/' + this.selectedCompany1.parentGuid]);}
  if (e === 'isSalesJournal') {
    this.isSalesJournal = true;
    this.router.navigate(['/saledashboard/salesjournal/' + this.selectedCompany1.parentGuid]);
  }
  if (e === 'isVehicleSoldList') { this.isVehicleSoldList = true; }
  this.loadInvoices();
}
resetAscending() {
  this.isDateAscending = true;
  this.isCustomerAscending = true;
  this.isIdAscending = true;
  this.isLoanAmountAscending = true;
  this.isSaleTypeAscending = true;
  this.isSaleSTatusAscending = true;
  this.isVehicleAscending = true;
  this.isInvoiceAscending = true;
  this.isCountyAscending = true;
}
sort(e) {
  console.log(e);
  this.isDateAscending = true;
  this.isCustomerAscending = true;
  this.isIdAscending = true;
  this.isLoanAmountAscending = true;
  this.isSaleTypeAscending = true;
  this.isSaleSTatusAscending = true;
  this.isVehicleAscending = true;
  this.isInvoiceAscending = true;
  this.isCustomerAscending = true;
  this.isCountyAscending = true;
  this.isAmountTaxed1 = false;
  this.isTaxRate1 = false;
  this.isTax1 = false;
  this.isAmountTaxed2 = false;
  this.isTaxRate2 = false;
  this.isTax2 = false;
  this.isAmountTaxed3 = false;
  this.isTaxRate3 = false;
  this.isTax3 = false;
  this.isTax = false;

  this.isAscending = !this.isAscending;
  this.userParams.isAscending = this.isAscending;
  this.userParams.itemsPerPage = 15;
  this.userParams.orderBy = e;
  this.loadInvoices();
  if (e === 'id') {this.isIdAscending = this.isAscending; }
  if (e === 'date') {this.isDateAscending = this.isAscending; }
  if (e === 'invoiceNumber') {this.isInvoiceAscending = this.isAscending; }
  if (e === 'customer') {this.isCustomerAscending = this.isAscending; }
  if (e === 'loanAmount') {this.isLoanAmountAscending = this.isAscending; }
  if (e === 'saleType') {this.isSaleTypeAscending = this.isAscending; }
  if (e === 'saleStatus') {this.isSaleSTatusAscending = this.isAscending; }
  if (e === 'personType') {this.isCustomerAscending = this.isAscending; }
  if (e === 'vehicle') {this.isVehicleAscending = this.isAscending; }
  if (e === 'isCountyAscending') {this.isCountyAscending = this.isAscending; }
  if (e === 'isAmountTaxed1') {this.isAmountTaxed1 = this.isAscending; }
  if (e === 'isTaxRate1') { this.isTaxRate1 = this.isAscending; }
  if (e === 'isTax1') {this.isTax1 = this.isAscending; }

  if (e === 'isAmountTaxed2') {this.isAmountTaxed2 = this.isAscending; }
  if (e === 'isTaxRate2') { this.isTaxRate2 = this.isAscending; }
  if (e === 'isTax2') {this.isTax2 = this.isAscending; }

  if (e === 'isAmountTaxed3') {this.isAmountTaxed3 = this.isAscending; }
  if (e === 'isTaxRate3') { this.isTaxRate3 = this.isAscending; }
  if (e === 'isTax3') {this.isTax3 = this.isAscending; }
}
exportAsXLSX(): void {
  this.pagination.itemsPerPage = 1000000;
  this.chartInvoiceService.getSaleInvoices(this.selectedCompany1.parentGuid, this.pagination.currentPage,
    this.pagination.itemsPerPage, this.userParams)
  .subscribe((data: PaginatedResult <ChartInvoiceTransactionGridTOReturnDto[]>) => {
    this.excelService.exportAsExcelFile(data.result, 'Sales Recieved');
//    this.pagination = data.pagination;
    this.pagination.itemsPerPage = 15;
  }, error => { this.alertify.error(error); }, () => { this.alertify.success('Your Export is Ready'); } );
}
async printDiv() {
  await this.hideDiv().then(() => {
   setTimeout(() => {
     this.print();
   }, 2000);
    });
 }
 async hideDiv() {
   this.isPrint = true;
 }
 async print() {
   // tslint:disable-next-line:no-string-literal
   window.frames['print_frame'].document.body.innerHTML = document.getElementById('printableTable').innerHTML;
   // tslint:disable-next-line:no-string-literal
   window.frames['print_frame'].window.focus();
   // tslint:disable-next-line:no-string-literal
   window.frames['print_frame'].window.print();
   this.isPrint = false;
 }

}
