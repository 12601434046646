import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { CompaniesService } from 'src/app/_services/companydataservice/companies.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  isEmployees = true;
  isCompany = false;
  isRoles = false;
  isCompanies = false;
  tennantGuid: any;
  companyProfiles = [] as CompanyProfile[];
  selectedCompany1 = {} as UserAssignedToCompany;
  constructor(private router: ActivatedRoute,
    private companiesService: CompaniesService) { }

  ngOnInit() {
    this.tabClicked('isEmployees');
    
  }
  tabClicked(e) {
    console.log(e);
    this.isEmployees = false;
    this.isCompany = false;
    this.isRoles = false;
    this.isCompanies = false;
    if (e === 'isEmployees') { this.isEmployees = true; }
    if (e === 'isRoles') { this.isRoles = true; }
    if (e === 'isCompany') { this.isCompany = true; }
    if (e === 'isCompanies') { this.isCompanies = true; 
      const newLocal = 'tennantGuid';
      this.tennantGuid = this.router.snapshot.data[newLocal];
       this.companiesService.getCompanyProfilesByTennantGuid(this.tennantGuid).subscribe(data => {
         console.log(data);
         this.companyProfiles = data;
       });
    }
  }
}
