import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SocialMedia } from 'src/app/_models/socialMedia';


@Injectable({
  providedIn: 'root'
})
export class WebsiteSocialmediaService {

constructor(private http: HttpClient) { }
baseUrl = environment.apiUrl;

  updateSocialMedia(socialMedia: SocialMedia): Observable<SocialMedia> {
    return this.http.put<SocialMedia>(this.baseUrl + 'websitesocialmedia/updatewebsitesocialmedia/', socialMedia);
  }
}
