
  <br><br>
  <div class="customer-form-sec">
    <div class="container-fluid">
      <div class="row">
        <div class="form-sec tab-content col-md-11">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
          
              <!-- <img style="display: block; width: 816px; min-height: 1056;" [src] = "imgURl"> -->
              <div class="col-md-12">
                <div class="inner-row account-payable-row">          
                  <!-- [hidden] = "true" -->
                  <div class="row" >
                    <pdf-viewer   id="pdfViewer"  [src]="pdfSrc" 
                    [render-text]="true"
                    [original-size]="true"
                    [render-text-mode]="2"
                    style="display: block; width: 816px; min-height: 1056;"
                    (page-rendered)="pageRendered($event)"
                    (click) = "dragMoveListener($event)" 
                    (text-layer-rendered)="textLayerRendered($event)"
        ></pdf-viewer> 

       
                    <div class="col-md-12 table-sec"  >
                      <!-- <app-Pdf-View-Sized></app-Pdf-View-Sized> -->
                      <!-- <app-addpdf-fieldstopdf ></app-addpdf-fieldstopdf> -->
                      <!-- (click) = "dragMoveListener($event)"         -->
            <input id="pdfBase64" type="hidden" value="byteBase64Array">
            <!-- <ngx-extended-pdf-viewer 
            [showPropertiesButton]="false" 
            *ngIf="isWithData"  
            [base64Src]  = "pdfSrcBase64" 
            [filenameForDownload] = ''
            useBrowserLocale="true" 
            height="80vh"></ngx-extended-pdf-viewer> -->
                    </div>
                    <!-- /.table-sec -->
                  </div>
                </div>
              </div>
            </div>
            <!-- /.row -->
          </div>
        </div>
        <div class="customer-sidebar sidebar-with-btns col-md-1">
          <div class="inner-sidebar">
            <ul>
              <li>
                <button (click) = "PrintPdf('print')" class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-6.png" />
                  <span>Print PDF</span>
                </button>
              </li>
              <li>
                <button (click) = "PrintPdf('view')" class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-6.png" />
                  <span>View PDF</span>
                </button>
              </li>
              <li>
                <button (click) = "PrintPdf('download')" class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-6.png" />
                  <span>Download</span>
                </button>
              </li>
              <li>
                <button (click) = "changeIsWithData()"  class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-1.png" />
                  <span >
                    <div *ngIf="!isWithData">With Data</div>
                    <div *ngIf="isWithData">With Fields</div>
                 
                   </span>
                </button>
              </li>
              <li>
                <button (click) = "changeIsWithData('emptyform')"  class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-1.png" />
                  <span >
                    <div>Empty PDF</div>
                   </span>
                </button>
              </li>
              <li *appHasRole="['Admin']">
                <button (click) = "deletePdf()" class="cstm-btn img-blk-btn" >
                  <img src="assets/images/icons8-microsoft-excel-48.png" />
                  <span>Delete</span>
                </button>
              </li>
              <li>
                <button (click) = "uploadPdf()" class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-6.png" />
                  <span>Upload PDF</span>
                </button>
              </li>
              <li>
                <button (click) = "editPdf()"  class="cstm-btn img-blk-btn">
                  <img src="assets/images/ap-icon-6.png" />
                  <span>Edit Pdf </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <object id="obj" type="application/pdf" ></object>
  <iframe name="print_frame" width="0" height="0" frameborder="0" src="about:blank"></iframe>