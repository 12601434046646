import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AmoAmortization } from 'src/app/_models/AmoAmortization';
import { AmoInstallment } from 'src/app/_models/AmoInstallment';
import { AmoLoanType } from 'src/app/_models/amoLoanType';
import { Dollars } from 'src/app/_models/dollars';
import { DollarTypes } from 'src/app/_models/dollartypes';
import { ExportDropDowns } from 'src/app/_models/dropdowns';
import { FormName } from 'src/app/_models/formName';
import { Label } from 'src/app/_models/label';
import { PaymentType } from 'src/app/_models/PaymentType';
import { PdfFormPackage } from 'src/app/_models/pdfformpackage';
import { Sale } from 'src/app/_models/sale';
import { SaleDefault } from 'src/app/_models/SaleDefault';
import { SaleDefaultTemplate } from 'src/app/_models/SaleDefaultTemplate';
import { SaleNotes } from 'src/app/_models/SaleNote';
import { SaleTaxDefault } from 'src/app/_models/SaleTaxDefault';
import { SaleType } from 'src/app/_models/saletype';
import { PaymentTypesResolver } from 'src/app/_resolvers/payment-types.resolver';
import { ListService } from 'src/app/_services/listServices/list.service';
import { PdfPackageService } from 'src/app/_services/PdfSErvices/pdf-package.service';
import { PdfService } from 'src/app/_services/PdfSErvices/pdf.service';
import { SaleService } from 'src/app/_services/saleservices/sale.service';
import { VendorService } from 'src/app/_services/vendorservices/vendor.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-Sale-Desk',
  templateUrl: './Sale-Desk.component.html',
  styleUrls: ['./Sale-Desk.component.css']
})
export class SaleDeskComponent implements OnInit {
@ViewChild('editForm', {static: true}) editForm: NgForm;
isDealInfo = true;
isDealInfoDirty = true;
base64: any;
isLoan = false;
isVehicle = false;
isWashout = false;
isMisc = false;
isCustomer = false;
isCobuyer = false;
isNotes = false;
isPdfForms = false;
sale: Sale;
list: ExportDropDowns;
selectedPackage = {} as PdfFormPackage;
salePackages = [] as PdfFormPackage[];
paymentTypes  = [] as  PaymentType[];
saleTaxDefaults  = [] as  SaleDefault[];
amoLoanMethods = [] as AmoLoanType[];
installments = [] as AmoInstallment[];
saleDefaultTemplate = {} as SaleDefaultTemplate;
saleTypes = [] as SaleType[];
saleLabelFormName: FormName;
vehicleLableFormName: FormName;
dollars = [] as Dollars[];
dollarTypes = [] as DollarTypes[];
labels = [] as Label[];
saleNotes = [] as SaleNotes[];
amoAmortization = [] as AmoAmortization[];
isWashoutLive = false;
isAmortisation = false;
inventoryPackages = [] as PdfFormPackage[];
// @HostListener('window:beforeunload', ['$event']) unloadNotification($event: any) {
//   if (this.isDealInfo) {
//     $event.returnValue = true;
//   }
// }
  constructor(private saleService: SaleService,
     private route: ActivatedRoute,
     private pdfService: PdfService,
     private alertify: ToastrService,
     private pdfPackages: PdfPackageService,
      private listService: ListService, private vendorService: VendorService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.list = data.dropdowns;
      this.sale = data.sale;
     console.log(data);
      this.pdfService.setSaleAccountId(data.sale?.id);
      // this.vehicleLableFormName = data.dropdowns?.formNameVehicle;
      // this.saleLabelFormName = data.dropdowns?.formNameDealInfo;
    }, error => { console.log(error); }, () => { console.log(this.sale); this.alertify.success('Drop Down Data Retrieved.');
   if (this.sale && this.list) {
     console.log('sale id: ' + this.sale.id);
     this.pdfService.setSaleAccountId(this.sale.id);
     this.salePackages =  this.list.pdfFormPackages;
     this.pdfPackages.changeSalePackages(this.list.pdfFormPackages);
    console.log( this.salePackages );
   }
   this.salePackages =  this.list.pdfFormPackages;
  });

  }
   fnZ02 = (num, decimals) => num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

tabClicked(e) {
  this.isDealInfo = false;
  this.isLoan = false;
  this.isVehicle = false;
  this.isWashout = false;
  this.isMisc = false;
  this.isCustomer = false;
  this.isNotes = false;
  this.isCobuyer = false;
  this.isWashout = false;
  this.isWashoutLive = false;
  this.isAmortisation = false;
  this.isPdfForms = false;
  if (e === 'isDealInfo') { this.isDealInfo = true;  }
  if (e === 'isAmortisation') { this.isAmortisation = true;  }
  if (e === 'isLoan') { this.isLoan = true; }
  if (e === 'isCustomer') { this.isCustomer = true; }
  if (e === 'isCobuyer') { this.isCobuyer = true; }
  if (e === 'isVehicle') { this.isVehicle = true; }
  if (e === 'isWashout') { this.isWashout = true; }
  if (e === 'isMisc') { this.isMisc = true; }
  if (e === 'isNotes') { this.isNotes = true; }
  if (e === 'isPdfForms') { this.isPdfForms = true; console.log('tab click');
//  console.log( this.salePackages );
  // this.pdfPackages.currentSalePackages.subscribe(data => {
  //   this.salePackages = data;
  //   console.log(data);
  // });
  }
}

}
