
<br>
  <div class="customer-form-sec">
    <div class="container-fluid">
      <div class="row">
        <div class="form-sec tab-content col-md-11">
          <div id="depreciate-costs" class="tab-pane fade in active show">
            <div class="row">
              <div class="col-md-12">
                <div class="inner-row account-payable-row">          
                  
                  <div class="row">
                    <div class="col-md-12 table-sec">
                      <div id="printableTable">
<table  >
  <tr>
    <th>Pmt#:</th>
    <th>Date</th>
    <th>Pmt Amortized</th>
    <th>Princple</th>
    <th>Interest</th>
    <th>NonAmortized</th>
    <th>Payment</th>
    <th>Declining</th>
   
    <th>Sum Paid</th>
    <th>Pmt Type</th>
    <th>Non Amortized Total</th>
    <th>Apr</th>
    <th>Contact Rate</th>
    <th>Principle Balance</th>
    <th>DSL</th>
    <th>Daily Factor</th>
    <th>Days Per Year</th>
    <th>Int Total</th>
    <th>P Total</th>

    
   
    <!-- | orderBy: '+paymentNumber' -->
  </tr>
  <tr   *ngFor = "let items of amoAmortization ">
    <td  >{{items.paymentNumber}}</td>
    <td >{{items.datePaid | date: 'shortDate'}}</td>
    <td>{{items.paymentAmo | number:'1.2-2'}}</td>
    <td>{{items.principle | number:'1.2-2'}}</td>
    <td>{{items?.interest | number:'1.2-2'}}</td>
    <td>{{items.paymentNoAmo | number:'1.2-2'}}</td>
    <td>{{items?.paymentTotal | number:'1.2-2'}}</td>    
    <td>{{items?.decliningBalance | number:'1.2-2'}}</td>  
    <td>{{items?.sumPaid }}</td>
   
    <td>{{items?.paymentType }}</td>
    <td>{{items?.nonAmortizedTotal | number:'1.2-2'}}</td>
    <td>{{items.aPR | number:'1.4-4'}}</td>
    <td>{{items?.contractRate | number:'1.4-4'}}</td>
    <td>{{items?.principleBalance | number:'1.2-2'}}</td>          
    <td>{{items?.dsl | number:'1.0-0'}}</td>                  
    <td>{{items?.dailyFactor | number:'1.6-6'}}</td>
    <td >{{items?.daysPerYear}}</td>
    <td >{{items?.interestTotal | number:'1.2-2'}}</td>
    <td >{{items?.principleTotal | number:'1.2-2'}}</td>
  </tr>
 
</table>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

</div>
</div>
</div>