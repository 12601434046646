import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { AmoLoan } from 'src/app/_models/amoloans';
import { AmoPayment } from 'src/app/_models/amopayments';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Sale } from 'src/app/_models/sale';
import { TakePaymentDto } from 'src/app/_models/DTOS/takepaymentdto';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoanService } from 'src/app/_services/loanservieces/loan.service';

@Component({
  selector: 'app-editpayment-modal',
  templateUrl: './editpayment-modal.component.html',
  styleUrls: ['./editpayment-modal.component.css']
})
export class EditpaymentModalComponent implements OnInit {
  @Input() chartInvoiceTransaction: any;
  @Output() paymentEnetered = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  public now: Date = new Date();
  loan: AmoLoan;
  amoPayment = { } as AmoPayment;
  @Input() takePaymentDto: TakePaymentDto;
  parentGuid: string;
    constructor(public bsModalRef: BsModalRef,
      private authService: AuthService,
      private loanService: LoanService
      ) { }
  
    ngOnInit() {
      if (this.chartInvoiceTransaction) {
      //  this.loan = this.chartInvoiceTransaction.amoLoans;
      //  this.amoPayment.datePaid = this.now;
         this.authService.currentCompany.subscribe(data => {this.parentGuid = data.parentGuid; });
      }
     console.log(this.takePaymentDto);
    }
    paidDate(e) {
    }
  
    setTwoNumberDecimal($event) {
      console.log('2 decimals');
      $event.target.value = parseFloat($event.target.value).toFixed(2);
    }
  
   async miscFeeLostFocus() {
     await this.doMath();
    }
    miscFeeEntered() {}
  
   async lateFeeLostFocus() {
     await this.doMath();
    }
  async  lateFeeEntered() {}
  
   async paymentDueLostFocus() {
      this.doMath();
    }
    paymentDueEntered() {}
  
   async deferredLostFocus() {
    await  this.doMath();
    }
    deferredEntered() {
  
    }
  
    async doMath() {
      this.amoPayment = this.editForm.value;
      console.log(this.amoPayment);
      this.amoPayment.sumPaidToday =
      this.amoPayment.todayDeferredDown
      + this.amoPayment.todayLateFee
      + this.amoPayment.todayLoanPaid
      + this.amoPayment.todayMisc;
      this.amoPayment.sumPaidToday = parseFloat(this.amoPayment.sumPaidToday.toFixed(2));
    }
    async save() {
     await this.doMath();
      console.log(this.editForm.value);
      this.amoPayment = this.editForm.value;
      this.takePaymentDto.datePaid = this.amoPayment.datePaid;
      this.takePaymentDto.sumPaidToday = this.amoPayment.sumPaidToday;
      this.takePaymentDto.todayDeferredDown = this.amoPayment.todayDeferredDown;
      this.takePaymentDto.todayLateFee = this.amoPayment.todayLateFee;
      this.takePaymentDto.todayMisc = this.amoPayment.todayMisc;
      this.takePaymentDto.todayLoanPaid = this.amoPayment.todayLoanPaid;
      this.takePaymentDto.parentId = this.parentGuid;
    //  this.takePaymentDto.invoiceId = this.chartInvoiceTransaction.saleId;
  
      this.paymentEnetered.emit(this.takePaymentDto);
      this.bsModalRef.hide();
    }
  
  
    // async dueTodayLoad() {
    //   this.amoPayment = this.editForm.value;
    //   this.takePaymentDto.datePaid = this.amoPayment.datePaid;
    //   this.takePaymentDto.sumPaidToday = this.amoPayment.sumPaidToday;
    //   this.takePaymentDto.todayDeferredDown = this.amoPayment.todayDeferredDown;
    //   this.takePaymentDto.todayLateFee = this.amoPayment.todayLateFee;
    //   this.takePaymentDto.todayMisc = this.amoPayment.todayMisc;
    //   this.takePaymentDto.todayLoanPaid = this.amoPayment.todayLoanPaid;
    //   this.takePaymentDto.parentId = this.parentGuid;
    //   this.takePaymentDto.invoiceId = this.chartInvoiceTransaction.id;
    //   this.loanService.getPaymentDueToday(this.takePaymentDto).subscribe(data => {console.log(data); });
    // }
  }
  