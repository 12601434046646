// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  version: '3.002.',
  websiteId: '9CBA251A-AB45-4E64-9FF5-55C59053DD7C',
  masterParentGuid: 'A66B2AE8-C838-41AF-8DDD-BC6EA39F838D',
  // apiUrl: 'https://localhost:5001/api/',
  // // apiUrl1: 'https://cardealersoftware.com/api/',
  // hubUrl: 'https://localhost:5001/hubs/',
  // //supportUrl: 'http://localhost:5010/api/',
  // supportUrl: 'https://chipperdave.com/api/',

  apiUrl: 'api/',
  hubUrl: 'hubs/',
  supportUrl: 'https://chipperdave.com/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
//  git add .
// git commit -m "General Update"
// git push origin master

// identity update
// dotnet ef migrations add IdentityAddress -p Infrastructure -s API -c AppIdentityDbContext

// storecontext ef migrations
// dotnet ef migrations add updateparagrapphsplacementid  -p Infrastructure -s API -c StoreContext

// PUBLISHING
// dotnet publish -c Release -o publish clmnet.sln

// docker-compose up --detach
