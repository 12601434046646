import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import moment from 'moment';
import { CompanyProfile } from 'src/app/_models/companyProfile';
import { PdfSaleFields } from 'src/app/_models/pdfsalefields';
import { Sale } from 'src/app/_models/sale';
@Injectable({ providedIn: 'root' })
export class SaleDataDealDownPayments {
  moment = require('moment');
  cashDown = 0 as number;
  tradeLien = 0 as number;
  convertDate(date) {
      const momentDate = moment().format('L').toString();
      return momentDate;
}
    fnz02 = (num) => num.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
        })

          fnz04 = (num) => num.toLocaleString('en-US', {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
            useGrouping: false,
            })
            fnz0 = (num) => num.toLocaleString('en-US', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
                })

      fillPdfFieldsWithDealDownPayments(sale: Sale, prefix: string, groupByField: string, pdfFields: PdfSaleFields[]): Observable<PdfSaleFields[]>{
        if (pdfFields.length > 0 ) {
        let  field = '';
        let newpdfField = {} as PdfSaleFields;
        const newPdfFields = [] as PdfSaleFields[];
        pdfFields.forEach(element => {
            field = element.field;
            const str = field;
            const matches = str.match(/\d+/g);
            let i;
            if (matches != null ) { i = matches[0]; } else { return of(pdfFields); }
            field = field.replace(i.toString(), '');
            let tradePositive = 0;
            let tradeNegative = 0;
            newpdfField.groupBy = 'cashdowns';
            switch (field) {

              case prefix + 'CashDown': {
                newpdfField  = {
                    field: prefix + 'CashDown' + i,
                    value: sale.cashDown.toFixed(2),
                    calculate: 'false',
                    groupBy: groupByField
                };
                newPdfFields.push(newpdfField);
               break;
            }
            case prefix + 'TradeDifferencePositive': {
              if (sale?.tradeAllow - sale?.tradeLien > 0) {tradePositive = sale?.tradeAllow - sale?.tradeLien; }
              newpdfField  = {
                  field: prefix + 'TradeDifferencePositive' + i,
                  value: tradePositive.toFixed(2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
            }
            case prefix + 'TradeDifferenceNegative': {
              if (sale?.tradeAllow - sale?.tradeLien < 0) {tradeNegative = sale?.tradeAllow - sale?.tradeLien; }
              newpdfField  = {
                  field: prefix + 'TradeDifferenceNegative' + i,
                  value: tradeNegative.toFixed(2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
            }
            case prefix + 'TradeAllow': {
              newpdfField  = {
                  field: prefix + 'TradeAllow' + i,
                  value: sale.cashDown.toFixed(2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
            }
            case prefix + 'TradeLien': {
              newpdfField  = {
                  field: prefix + 'TradeLien' + i,
                  value: sale.cashDown.toFixed(2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
            case prefix + 'PickAmounA': {
              newpdfField  = {
                  field: prefix + 'PickAmountA' + i,
                  value: sale.pickAmount1.toFixed(2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
          case prefix + 'PickAmountB': {
              newpdfField  = {
                  field: prefix + 'PickAmountB' + i,
                  value: sale.pickAmount2.toFixed(2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
          case prefix + 'PickAmountC': {
              newpdfField  = {
                  field: prefix + 'PickAmountC' + i,
                  value: sale.pickAmount3.toFixed(2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
          case prefix + 'PickAmountD': {
              newpdfField  = {
                  field: prefix + 'PickAmountD' + i,
                  value: sale.pickAmount4.toFixed(2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
          case prefix + 'PickAmountE': {
              newpdfField  = {
                  field: prefix + 'PickAmountE' + i,
                  value: sale.pickAmount5.toFixed(2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
          case prefix + 'PickDateA': {
              newpdfField  = {
                  field: prefix + 'PickDateA' + i,
                  value: this.convertDate(sale.pickDate1),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
          case prefix + 'PickDateB': {
              newpdfField  = {
                  field: prefix + 'PickDateB' + i,
                  value: this.convertDate(sale.pickDate2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
          case prefix + 'PickDateC': {
              newpdfField  = {
                  field: prefix + 'PickDateC' + i,
                  value: this.convertDate(sale.pickDate3),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
          case prefix + 'PickDateD': {
              newpdfField  = {
                  field: prefix + 'PickDateD' + i,
                  value: this.convertDate(sale.pickDate4),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
          case prefix + 'PickDateE': {
              newpdfField  = {
                  field: prefix + 'PickDateE' + i,
                  value: this.convertDate(sale?.pickDate5),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
          case prefix + 'PickupsTotal': {
              newpdfField  = {
                  field: prefix + 'PickupsTotal' + i,
                  value: sale.pickupsTotal.toFixed(2),
                  calculate: 'false',
                  groupBy: groupByField
              };
              newPdfFields.push(newpdfField);
             break;
          }
            }
          });
          return of(newPdfFields);
        }
        return of(pdfFields);
     }
 }
