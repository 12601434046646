import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FeatureRequest } from 'src/app/_models/featurereqeustentities/featurerequest';

@Component({
  selector: 'app-feature-create',
  templateUrl: './feature-create.component.html',
  styleUrls: ['./feature-create.component.css']
})
export class FeatureCreateComponent implements OnInit {
@Input() parentGuid: any;
@Output() emittedValues = new EventEmitter();
@ViewChild('editForm', {static: true}) editForm: NgForm;
@Input() featureRequest = {} as FeatureRequest;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {

  }
  onChange(e) {
    console.log(this.editForm);
  }
  update() {
    console.log(this.editForm.value);
    this.featureRequest = this.editForm.value;
    this.featureRequest.parentGuid = this.parentGuid;
    this.emittedValues.emit(this.featureRequest);
    this.bsModalRef.hide();
  }
}
