<div class="row">
  <div class="col-6">
    <div class="wrapper">
    <ag-charts-angular
    class="ag-default-dark"
    [options]="options">
</ag-charts-angular></div>
</div>
  <div class="col-6">
    <ag-charts-angular
    class="ag-theme-alpine"
    style="height: 700px;"
    [options]="options2">
</ag-charts-angular>
  </div>
  </div>
