<div class="row">
    <div class="col-2" *ngFor="let photo of member.photos">
        <img src="{{photo.url}}" alt="{{photo.url}}" class="img-thumbnail p-1">
        <div class="text-center">
            <button 
                [disabled]="photo.isMain"
                (click)="setMainPhoto(photo)"
                [ngClass]='photo.isMain ? "btn-success active" : "btn-outline-success"'
                class="btn btn-sm"
            >Main</button>
            <button 
                [disabled]="photo.isMain"
                (click)="deletePhoto(photo.id)"
                class="btn btn-sm btn-danger"
            ><i class="fa fa-trash"></i></button>
        </div>
    </div>
</div>

<div class="row mt-3">
 
    <div class="col-md-3">

        <h3>Add Photos</h3>

        <div ng2FileDrop
             [ngClass]="{'nv-file-over': hasBaseDropzoneOver}"
             (fileOver)="fileOverBase($event)"
             [uploader]="uploader"
             class="card bg-faded p-3 text-center mb-3 my-drop-zone">
             <i class="fa fa-upload fa-3x"></i>
            Drop photos here
        </div>

        Multiple
        <input type="file" ng2FileSelect [uploader]="uploader" multiple  /><br/>

        Single
        <input type="file" ng2FileSelect [uploader]="uploader" />
    </div>

    <div class="col-md-9" style="margin-bottom: 40px" *ngIf="uploader?.queue?.length">

        <h3>Upload queue</h3>
        <p>Queue length: {{ uploader?.queue?.length }}</p>

        <table class="table">
            <thead>
            <tr>
                <th width="50%">Name</th>
                <th>Size</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of uploader.queue">
                <td><strong>{{ item?.file?.name }}</strong></td>
                <td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
            </tr>
            </tbody>
        </table>

        <div>
            <div>
                Queue progress:
                <div class="progress" style="">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader.progress + '%' }"></div>
                </div>
            </div>
            <button type="button" class="btn btn-success btn-s"
                    (click)="uploader.uploadAll()" [disabled]="!uploader.getNotUploadedItems().length">
                <span class="fa fa-upload"></span> Upload all
            </button>
            <button type="button" class="btn btn-warning btn-s"
                    (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
                <span class="fa fa-ban"></span> Cancel all
            </button>
            <button type="button" class="btn btn-danger btn-s"
                    (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
                <span class="fa fa-trash"></span> Remove all
            </button>
        </div>

    </div>

</div>
