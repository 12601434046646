import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PackageButtonLocations } from 'src/app/_models/PackageButtonLocations';
import { PdfFormPackage } from 'src/app/_models/pdfformpackage';

@Component({
  selector: 'app-add-pdfpackage',
  templateUrl: './add-pdfpackage.component.html',
  styleUrls: ['./add-pdfpackage.component.scss']
})
export class AddPdfpackageComponent implements OnInit {
  @Output() emittedValues = new EventEmitter();
  @ViewChild('editForm', {static: true}) editForm: NgForm;
  @Input() pdfFormPackage: PdfFormPackage;
  @Input() packageButtonLocations = [] as PackageButtonLocations[];
  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit() {
    if (!this.pdfFormPackage) {
      this.pdfFormPackage = {isSale: true, isReport: false, isInventory: false, packageName: '', defaultPack: false };
    }
  }
  onChange(e) {}

  update() {
     console.log(this.pdfFormPackage);
     this.emittedValues.emit(this.pdfFormPackage);
    this.bsModalRef.hide();
  }
  packageButtonLocationClick(e) {
    console.log(e);
    console.log(this.packageButtonLocations);
    this.pdfFormPackage.pdfFormPackagePrintLocationId = e;
  }
  selectChange(e) {

  }
}

