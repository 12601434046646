<div class="container-fluid customer-tabs-sec">
<ul class="nav nav-tabs">
  <li>
    <a
      data-toggle="tab"
      [ngClass] = "isAll ? 'active show' : ''"
      (click)="filter('all')"
      >All Vehicles</a
    >
  </li>
  <li >
    <a
      data-toggle="tab"
      [ngClass] = "isActive ? 'active show' : ''"
      (click)="filter('active')"
      >Active</a
    >
  </li>
  <li >
    <a
      data-toggle="tab"
      [ngClass] = "isNonPublishedActive ? 'active show' : ''"
      (click)="filter('nonPublishedActive')"
      >Non Published Active</a
    >
  </li>
  <li >
    <a
      data-toggle="tab"
      [ngClass] = "isSold ? 'active show' : ''"
      (click)="filter('sold')"
      >Sold</a
    >
  </li>
  <li >
    <a
      data-toggle="tab"
      [ngClass] = "isPublishedNeedsImages ? 'active show' : ''"
      (click)="filter('publishedNeedsImages')"
      >Published Need Images</a
    >
  </li>
  <li>
    <a
      data-toggle="tab"
      [ngClass] = "isPublishedAll ? 'active show' : ''"
      (click)="filter('publishedAll')"
      >All Published</a
    >
  </li>
  <li >
    <a
      data-toggle="tab"
      [ngClass] = "isPublishedSold ? 'active show' : ''"
      (click)="filter('publishedSold')"
      >Published Sold</a
    >
  </li>

<div class="d-flex justify-content-right">

  <pagination
    [boundaryLinks]="true"
    pageBtnClass = "nav nav-tabs"
    [(ngModel)]="pagination.currentPage"
    [totalItems]="pagination.totalItems"
    [maxSize]="maxSize"
    [rotate]="true"
    (pageChanged)="pageChanged($event)"
    [itemsPerPage]="pagination.itemsPerPage"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
  >
  </pagination>
  </div>
  <li>
    <a
      data-toggle="tab"
      [ngClass] = "isQuickBooks ? 'active show' : ''"
      (click)="gridChange('isQuickBooks')"
      >Quick Books
    </a>
  </li>
  <li >
    <a
      data-toggle="tab"
      [ngClass] = "isProfitReport ? 'active show' : ''"
      (click)="gridChange('isProfitReport')"
      >Vehicle Cost List</a
    >
  </li>
  <li >
    <a
      data-toggle="tab"
      [ngClass] = "isWeblist ? 'active show' : ''"
      (click)="gridChange('weblist')"
      >Website List</a
    >
  </li>
</ul>
</div>
