import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { UserAssignedToCompany } from 'src/app/_models/userAssignedToCompany';
import { Pagination, PaginatedResult } from 'src/app/_models/pagination';

import { AlertifyService } from 'src/app/_services/alertify.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { Person } from 'src/app/_models/person';
import { ChartInvoiceTransactionGridTOReturnDto } from 'src/app/_models/DTOS/ChartInvoiceTransactionGridTOReturnDto';
import { Item } from 'src/app/_models/Item';
import { ExcelService } from 'src/app/_services/exportservices/excel.service';
import { ToastrService } from 'ngx-toastr';
import { SharedMonthDateFilterService } from 'src/app/shared/date-filter/date-filter.component';
import { SaleService } from 'src/app/_services/saleservices/sale.service';
import { SaleStatus } from 'src/app/_models/salestatus';
import { SaleType } from 'src/app/_models/saletype';
import { Sale } from 'src/app/_models/sale';
@Component({
  selector: 'app-sales-journal',
  templateUrl: './sales-journal.component.html',
  styleUrls: ['./sales-journal.component.css'],
  animations: [
    trigger('myAnimation', [
            transition(':enter', [
                style({opacity: 0 }),
                animate(
                    '800ms',
                    style({
                        opacity: 1
                    })
                ),
            ]),
            transition(':leave', [
                style({opacity: 1 }),
                animate(
                    '800ms',
                    style({
                        opacity: 0
                    })
                ),
            ]),
        ]),
  ]
})
export class SalesJournalComponent implements OnInit {
  selectedCompany1: UserAssignedToCompany;
//  sales: ChartInvoiceTransactionGridTOReturnDto[];
   sales: Sale[];
  pagination: Pagination;
  chartItem: Item;
  isDateAscending = true;
  isCustomerAscending = true;
  person: Person;
  isIdAscending = true;
  isCountyAscending = true;
  isLoanAmountAscending = true;
  isSaleTypeAscending = true;
  isSaleSTatusAscending = true;
  isVehicleAscending = true;
  isInvoiceAscending = true;
  isAscending = true;
  userParams = {} as any;
  showDates = false;
  selectType;
  selectStatus;
  isAllSales = false;
  isSalesTax = false;
  isSaleJournal = false;
  isVehicleSoldList = false;

  isAmountTaxed1 = false;
  isTaxRate1 = false;
  isTax1 = false;
  isAmountTaxed2 = false;
  isTaxRate2 = false;
  isTax2 = false;
  isAmountTaxed3 = false;
  isTaxRate3 = false;
  isTax3 = false;
  isAmountTaxed4 = false;
  isTaxRate4 = false;
  isTax4 = false;
  isTax = false;

  isSellilngPriceAscending = true;
  isPredeliveryAscending = true;
  isTitleAscending = true;
  isTagAscending = true;
  isRegistrationAscending = true;
  isWarrantyAscending = true;
  isTradeLienAscending = true;
  isSmog1Ascending = true;
  isPurchasePriceAscending = true;
  isSmog2Ascending = true;
  isTradeAllowAscending = true;
  isRepairsAscending = true;
  isTotalCostAscending = true;
  isPrint = false;
  itemId: number;
  isPerson = false;
  isVehicle = false;
  chartInvoiceStatuses: SaleStatus[];
  chartInvoiceSaleTypes: SaleType[];
  constructor(private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private excelService: ExcelService,
    private alertify: ToastrService,
    private chartInvoiceService: SaleService,
    private saleDateService: SharedMonthDateFilterService) { }

  ngOnInit() {
    this.isSaleJournal = true;
    this.authService.currentCompany.subscribe(company => this.selectedCompany1 = company);
    this.route.data.subscribe(data => {
      this.sales = data.sales.result;
      this.pagination = data.sales.pagination;
      this.chartInvoiceStatuses = data.chartInvoiceStatuses;
      this.chartInvoiceSaleTypes = data.chartInvoiceSaleTypes;
    });

  }
  updateDates(event) {
    this.userParams.startDate = event.startDate;
    this.userParams.endDate = event.endDate;
    this.userParams.monthOfYear = event.month;
    this.userParams.yearForDate = event.year;
    this.userParams.isAll = event.isAll;
    this.userParams.isFullYear = event.isFullYear;
    console.log('updateDate');
  this.loadInvoices();
  }
  selectedTypeChanged(e) {
    this.userParams.saleTypeId = '';
    console.log('selectedTypeChanged');
    if (this.selectType === 0) { this.userParams.saleTypeId = undefined; }
    if (this.selectType === null) { this.userParams.saleTypeId = undefined; }
    if (e > 0) {this.userParams.saleTypeId = e; }

    this.loadInvoices();
  }
  selectedStatusChanged(e) {
    this.userParams.saleStatusId = '';
    console.log('selectedStatusChanged');
    if (this.selectStatus === 0) { this.userParams.saleStatusId = undefined; }
    if (this.selectStatus === null) { this.userParams.saleStatusId = undefined; }
    if (e > 0) {this.userParams.saleStatusId = e; }
    this.loadInvoices();
  }
  navigateInvoice(e) {
    this.router.navigate(['saledashboard/editsale/'   + this.selectedCompany1.parentGuid + '/' + e.id]);
  }
  setSearchBy(e, event) {
    setTimeout(() => {
      console.log('search by reached');
      this.userParams.searchBy = e;
      this.userParams.searchFor = event;
      this.loadInvoices();
    }, 2000);
  }
  showDatesTrue() {
    this.showDates = !this.showDates;
  }
  loadInvoices() {
  this.chartInvoiceService.getSaleInvoice2(this.selectedCompany1.parentGuid, this.pagination.currentPage,
         this.pagination.itemsPerPage, this.userParams).subscribe((data: PaginatedResult<any[]>) => {
      this.sales = data.result;
    //  console.log(data.result);
        this.pagination = data.pagination;
     //   console.log(data.result);
      }, error => {
        this.alertify.error(error);
      }, () => {
        this.alertify.success('Your Sales have Loaded.');
      } );
  }
  sortDescription() {
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this. loadInvoices();
  }
  filter(e) {
   // console.log('filter reached');
    this.isAllSales = false;
    this.isSalesTax = false;
    this.isSaleJournal = false;
    this.isVehicleSoldList = false;
    this.userParams.filter = e;
    if (e === 'isAllSales') {
      this.router.navigate(['/saledashboard/saledashboard/' + this.selectedCompany1.parentGuid]);
      this.isAllSales = true;
    }
    if (e === 'isSalesTax') { this.isSalesTax = true;
      this.router.navigate(['/saledashboard/advancetax/' + this.selectedCompany1.parentGuid]);
    }
    if (e === 'isSaleJournal') {
      this.isSaleJournal = true; }
    if (e === 'isVehicleSoldList') { this.isVehicleSoldList = true; }
    this.loadInvoices();
  }
  resetAscending() {
    this.isDateAscending = true;
    this.isCustomerAscending = true;
    this.isIdAscending = true;
    this.isLoanAmountAscending = true;
    this.isSaleTypeAscending = true;
    this.isSaleSTatusAscending = true;
    this.isVehicleAscending = true;
    this.isInvoiceAscending = true;
    this.isCountyAscending = true;
  }
  sort(e) {
    console.log(e);
    this.isDateAscending = true;
    this.isCustomerAscending = true;
    this.isIdAscending = true;
    this.isLoanAmountAscending = true;
    this.isSaleTypeAscending = true;
    this.isSaleSTatusAscending = true;
    this.isVehicleAscending = true;
    this.isInvoiceAscending = true;
    this.isCustomerAscending = true;
  this.isCountyAscending = true;
    this.isTax = false;

    this.isSellilngPriceAscending = true;
    this.isPredeliveryAscending = true;
    this.isTitleAscending = true;
    this.isTagAscending = true;
    this.isRegistrationAscending = true;
    this.isWarrantyAscending = true;
    this.isTradeLienAscending = true;
    this.isSmog1Ascending = true;
    this.isPurchasePriceAscending = true;
    this.isSmog2Ascending = true;
    this.isTradeAllowAscending = true;
    this.isRepairsAscending = true;
    this.isTotalCostAscending = true;

    this.isAscending = !this.isAscending;
    this.userParams.isAscending = this.isAscending;
    this.userParams.itemsPerPage = 15;
    this.userParams.orderBy = e;
    this.loadInvoices();
    if (e === 'id') {this.isIdAscending = this.isAscending; }
    if (e === 'date') {this.isDateAscending = this.isAscending; }
    if (e === 'invoiceNumber') {this.isInvoiceAscending = this.isAscending; }
    if (e === 'customer') {this.isCustomerAscending = this.isAscending; }
    if (e === 'loanAmount') {this.isLoanAmountAscending = this.isAscending; }
    if (e === 'saleType') {this.isSaleTypeAscending = this.isAscending; }
    if (e === 'saleStatus') {this.isSaleSTatusAscending = this.isAscending; }
    if (e === 'personType') {this.isCustomerAscending = this.isAscending; }
    if (e === 'vehicle') {this.isVehicleAscending = this.isAscending; }
    if (e === 'isTax') {this.isTax = this.isAscending; }

    if (e === 'isSmog1Ascending') {this.isSmog1Ascending = this.isAscending; }
    if (e === 'isPurchasePriceAscending') { this.isPurchasePriceAscending = this.isAscending; }
    if (e === 'isSmog2Ascending') {this.isSmog2Ascending = this.isAscending; }
    if (e === 'isTradeLienAscending') {this.isTradeLienAscending = this.isAscending; }
    if (e === 'isWarrantyAscending') { this.isWarrantyAscending = this.isAscending; }
    if (e === 'isRegistrationAscending') {this.isRegistrationAscending = this.isAscending; }
    if (e === 'isTradeAllowAscending') {this.isTradeAllowAscending = this.isAscending; }
    if (e === 'isRepairsAscending') { this.isRepairsAscending = this.isAscending; }
    if (e === 'isTotalCostAscending') {this.isTotalCostAscending = this.isAscending; }

    if (e === 'isSellilngPriceAscending') {this.isSellilngPriceAscending = this.isAscending; }
    if (e === 'isPredeliveryAscending') {this.isPredeliveryAscending = this.isAscending; }
    if (e === 'isTitleAscending') { this.isTitleAscending = this.isAscending; }
    if (e === 'isTagAscending') {this.isTagAscending = this.isAscending; }
  }
  exportAsXLSX(): void {
    this.pagination.itemsPerPage = 1000000;
    this.chartInvoiceService.getSaleInvoices(this.selectedCompany1.parentGuid, this.pagination.currentPage,
      this.pagination.itemsPerPage, this.userParams)
    .subscribe((data: PaginatedResult <ChartInvoiceTransactionGridTOReturnDto[]>) => {
      this.excelService.exportAsExcelFile(data.result, 'Sales Recieved');
  //    this.pagination = data.pagination;
      this.pagination.itemsPerPage = 15;
    }, error => { this.alertify.error(error); }, () => { this.alertify.success('Your Export is Ready'); } );
  }
  async printDiv() {
    await this.hideDiv().then(() => {
     setTimeout(() => {
       this.print();
     }, 2000);
      });
   }
   async hideDiv() {
     this.isPrint = true;
   }
   async print() {
     // tslint:disable-next-line:no-string-literal
     window.frames['print_frame'].document.body.innerHTML = document.getElementById('printableTable').innerHTML;
     // tslint:disable-next-line:no-string-literal
     window.frames['print_frame'].window.focus();
     // tslint:disable-next-line:no-string-literal
     window.frames['print_frame'].window.print();
     this.isPrint = false;
   }

   findDetails(item, type) {
     if (type === 'person') {
      this.itemId = item.id;
      this.person = item.person;
      this.isPerson = !this.isPerson;
      this.isVehicle = false;
     }
     if (type === 'vehicle') {
       this.chartItem = item;
       console.log(item);
      this.itemId = item.id;
      this.isPerson = false;
      this.isVehicle = !this.isVehicle;
     }
   }
   tabelViewClick(){
    this.router.navigate(['saledashboard/saledashboard/' + this.selectedCompany1.parentGuid]);
  }
}
