<br>
  <div class="container-fluid customer-tabs-sec">
    <ul class="nav nav-tabs">
      <li><a [ngClass]="{'active show' : isDashboard}"  (click)="tabClicked('isDashboard')" href="javascript:void(0)" >Dashboard</a></li>
      <li><a  [ngClass]="{'active show' : isAll}" (click)="tabClicked('isAll')"  href="javascript:void(0)" >All Sales</a></li>
      <li><a  [ngClass]="{'active show' : isActive}" (click)="tabClicked('isActive')"  href="javascript:void(0)" >Active Sales</a></li>
      <li><a  [ngClass]="{'active show' : isPaid}" (click)="tabClicked('isPaid')"  href="javascript:void(0)" >Paid Sales</a></li>
      <li><a  [ngClass]="{'active show' : isLease}" (click)="tabClicked('isLease')"  href="javascript:void(0)" >Leases</a></li>
      <li><a  [ngClass]="{'active show' : isRental}" (click)="tabClicked('isRental')"  href="javascript:void(0)" >Rentals</a></li>
    </ul>
  </div>

<app-sale-charts [hidden] = "!isDashboard"></app-sale-charts>
<app-sale-grid  [hidden] = "!isAll"></app-sale-grid>
<app-sale-grid  [hidden] = "!isActive"></app-sale-grid>
<app-sale-grid  [hidden] = "!isPaid"></app-sale-grid>
<app-sale-grid  [hidden] = "!isLease"></app-sale-grid>
<app-sale-grid  [hidden] = "!isRental"></app-sale-grid>


