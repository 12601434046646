import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Observable, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class VehicleoptionsService {
  optionList = new BehaviorSubject<any>(null);
  currentOptionList = this.optionList.asObservable();
  constructor(private http: HttpClient) { }
  baseUrl = environment.apiUrl;

  changeVechicleOptionList(optionList: any) {
    this.optionList.next(optionList);
  }

  getOptions(parentid, chartitemid): Observable<any> {
    return  this.http.get<any>(this.baseUrl + 'vehicleoptions/getvehicleoptions/' + parentid + '/' + chartitemid );
    }

    addOption(vehicleid, optionid, ischecked): Observable<any> {
      console.log('veh: ' + vehicleid + ' optid: ' + optionid + ' isch ' + ischecked);
      return this.http.get<any>(this.baseUrl + 'vehicleoptions/addoption/' + vehicleid + '/' + optionid  + '/' + ischecked );
    }

    addTennantCustomDefaultOption(parentid, newoptionname, chartitemid): Observable<any> {
      return this.http.get<any>(this.baseUrl +
        'vehicleoptions/addtennantcreatedefaultoption/' + parentid + '/' + chartitemid  + '/' + newoptionname );
    }

    deleteDefaultOption(parentid, e, chartitemid): Observable<any> {
      // tslint:disable-next-line:max-line-length
      return this.http.get<any>(this.baseUrl + 'vehicleoptions/deletetennantcreateddefaultoption/' + parentid + '/' + chartitemid  + '/' + e );
    }
}
